import React from "react";
import { DocumentPDF, PDFHeader } from "./DocumentPDF";
import { DocumentImage } from "./DocumentImage";
import { useQuery } from "react-query";
import { useAgent } from "context/agentContext";
import { getKYCFile } from "api/pii";
import { ctxWithIntegrator } from "utils/auth";

export const KycDocument = ({ accountIdentifier = {}, documentId, alt }) => {
  const { accountId, integrator } = accountIdentifier;
  const { agent } = useAgent();

  const { isLoading, data } = useQuery(["getKYCDocument", accountId, documentId], () =>
    getKYCFile(accountId, documentId, ctxWithIntegrator(integrator, agent))
  );
  if (isLoading || !data?.payload?.file) return null;

  const documentData = data?.payload?.file;

  return documentData.startsWith(PDFHeader) ? (
    <DocumentPDF data={documentData} alt={alt} />
  ) : (
    <DocumentImage data={documentData} alt={alt} />
  );
};

export const Document = ({ data, ...props }) => {
  if (!data) return <div {...props}>-</div>;

  return data.startsWith(PDFHeader) ? (
    <DocumentPDF data={data} {...props} />
  ) : (
    <DocumentImage data={data} {...props} />
  );
};
