import { useEffect } from "react";
import "styled-components/macro"; // eslint-disable-line no-unused-vars
import { Box, Switch, Grid } from "@chakra-ui/react";
import * as R from "ramda";
import { PageContent, PageTitle } from "components/Page";
import { DataRow, DataTitleRow } from "components/DataRow";
import { useAgent } from "context/agentContext";
import { formatDistanceToNow } from "utils";
import { DEBUG_MODE, useAgentSettings } from "utils/useAgentSettings";
import { useTitle } from "context/titleContext";
import { Link } from "components/Link";

const DebuggingSwitch = () => {
  const { agent } = useAgent();
  const [settings, setSetting] = useAgentSettings(agent.account_id);
  const debugMode = settings[DEBUG_MODE];

  useEffect(() => {
    agent.debug("debug mode:", debugMode);
  }, [debugMode, agent]);

  return (
    <Switch
      id="debugging"
      isChecked={settings.debugMode}
      onChange={(e) => {
        setSetting(DEBUG_MODE, e.target.checked);
      }}
    />
  );
};

const Agent = ({ agent }) => {
  const expiresIn = formatDistanceToNow(agent.tokenExpiration);

  return (
    <Grid templateColumns="1fr 1fr" gap={4}>
      <Box>
        <DataTitleRow title="Agent" />
        <DataRow title="AccountId" data={agent.account_id} />
        <DataRow title="Username" data={agent.username} />
        <DataRow title="Publisher" data={agent.integrator} />
        <DataRow title="Token Expiration" data={`${expiresIn} / ${agent.tokenExpiration}`} />
        <DataRow title="ClientId">
          <Link to={`/clients/publisher/${agent.integrator}/${agent.client_id}`}>
            {agent.client_id}
          </Link>
        </DataRow>
        <DataRow title="Debugging">
          <DebuggingSwitch />
        </DataRow>
      </Box>
      <Box>
        <DataTitleRow title="Permissions" />
        {R.pipe(
          R.sortBy(R.identity),
          R.map((p) => <DataRow title={p} data=" " key={p} />),
        )(agent.permissions || [])}
      </Box>
    </Grid>
  );
};

export const Diagnostics = () => {
  const { agent } = useAgent();
  useTitle({ page: "Diagnostics", sub1: agent.username });

  return (
    <PageContent>
      <PageTitle>Diagnostics</PageTitle>
      <Agent agent={agent} />
    </PageContent>
  );
};
