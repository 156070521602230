import { TransactionType } from "@tilia-tools/core/transaction";
import { Id } from "components/Copyable";
import { Link } from "components/Link";

function TransactionDetailsLink({ transaction }) {
  let linkId = transaction.id;
  let displayId = transaction.invoiceId;

  switch (transaction.type) {
    case TransactionType.PURCHASE:
      break;
    case TransactionType.ESCROW_PURCHASE:
      linkId = transaction.escrowId;
      displayId = transaction.escrowId;
      break;
    case TransactionType.PAYOUT:
      displayId = transaction.payoutStatusId;
      break;
    case TransactionType.TOKEN_PURCHASE:
      displayId = transaction.tokenPurchaseId;
      break;
    case TransactionType.TOKEN_CONVERSION:
      displayId = transaction.tokenConversionId;
      break;
    case TransactionType.BALANCE_TRANSFER:
      displayId = transaction.balanceTransferId;
      break;
    default:
      // details page not yet available for this transaction type; do not render a link
      linkId = "";
  }

  return (
    <Id id={displayId}>
      {linkId ? <Link to={`/transaction/${linkId}`}>{displayId}</Link> : displayId}
    </Id>
  );
}

export default TransactionDetailsLink;
