import React from "react";
import { AccountProvider } from "context/accountContext";
import { ThemeProvider } from "styles";
import { AlertProvider } from "context/alertContext";
import { AgentProvider } from "context/agentContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { GraphQLProvider } from "context/graphqlContext";
import { IntegratorProvider } from "context/integratorContext";
import { TokenProvider } from "context/tokenContext";
import { AppConfigProvider } from "context/appConfigContext";
import { TitleProvider } from "context/titleContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    },
  },
});

export const Providers = ({ children }) => {
  return (
    <TokenProvider>
      <AppConfigProvider>
        <IntegratorProvider>
          <GraphQLProvider>
            <AlertProvider>
              <QueryClientProvider client={queryClient}>
                <AgentProvider>
                  <ThemeProvider>
                    <TitleProvider>
                      <AccountProvider>{children}</AccountProvider>
                    </TitleProvider>
                  </ThemeProvider>
                </AgentProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </AlertProvider>
          </GraphQLProvider>
        </IntegratorProvider>
      </AppConfigProvider>
    </TokenProvider>
  );
};
