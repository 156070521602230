import React, {useEffect, useState, useCallback} from "react";
import * as R from "ramda";
import { useAccount } from "context/accountContext";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { isNilOrEmpty } from "utils";
import { PanelContent, PanelTitle } from "components/Panel";
import { Table, Thead, Th, Tbody, Tr, Td } from "components/Table";
import { useUploadFileModal } from "components/UploadFileModal";
import { Document } from "components/Document";

const DocumentDisplay = ({data, getFiles, entityId}) => {
  const [docs, setDocs] = useState([]);

  const getData = useCallback(async() => {
    try {
      const fileIds = R.pluck('entity_document_id', R.defaultTo([], data?.documents));
      const stuff = await getFiles(entityId, fileIds);
      setDocs(stuff);
    } catch (error) {
      setDocs([]);
    }
  }, [data, entityId, getFiles])

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      {
        (isNilOrEmpty(docs)) 
          ?  <Tr><Td colSpan={2}>No files found</Td></Tr>
          : docs.map((doc, index) => <Tr pull key={index}>
            <Td>{doc.pii_document_name}</Td>
            <Td> 
              <Document
                data={doc.file}
                alt={`${doc.pii_document_name}`}
              />
            </Td>
          </Tr>
        )
      }
    </>
  )
}

export const KYBDocuments = ({data, entityId, onChange}) => {
  const { getKYBFiles, uploadKYBFile } = useAccount();
  const [UploadFileModal, UploadButton] = useUploadFileModal();

  const uploadKYB = (files) => uploadKYBFile(entityId, files);
  return (
    <>
      <PanelTitle>
        <span>Company Documents</span>
        <span>
          <UploadButton />
          <UploadFileModal submitAction={uploadKYB} onSuccess={onChange} />
        </span>
      </PanelTitle>
      <PanelContent>
        <Table>
          <Thead bordered>
            <Tr pull> 
              <Th>Name</Th>
              <Th>File</Th>
            </Tr>
          </Thead>
          <Tbody bordered>
            <DocumentDisplay data={data} getFiles={getKYBFiles} entityId={entityId}/>
          </Tbody>
        </Table>
      </PanelContent>
    </>
  );
}

