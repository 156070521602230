import React from "react";
import "styled-components/macro";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import { DataRow } from "components/DataRow";
import { ClientSecret } from "./common";

export const ClientDetails = ({ data }) => {
  return (
    <Panel>
      <PanelTitle>Details</PanelTitle>
      <PanelContent>
        <div
          css={`
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 16px;
          `}
        >
          <DataRow title="App Name" path={["payload", "app_name"]} data={data} />
          <DataRow title="Client Id" path={["payload", "client_id"]} data={data} copyable />
          <DataRow title="Callback URL" path={["payload", "auth_callback_url"]} data={data} />
          <DataRow
            title="Client Secret"
            data={<ClientSecret secret={data?.payload?.client_secret} />}
          />

          <DataRow title="Home Page URL" path={["payload", "home_page_url"]} data={data} />
          <DataRow
            title="Account Id"
            path={["payload", "account_id"]}
            data={data}
            route={`/account/${data?.payload?.account_id}`}
          />
          <div></div>
          <DataRow title="Publisher" path={["payload", "integrator"]} data={data} />
        </div>
      </PanelContent>
    </Panel>
  );
};
