/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { useEffect } from "react";
import { useField } from "formik";
import { Box, Heading, Table, Tbody, Td, Tfoot, Tr } from "@chakra-ui/react";
import { Money } from "components/Money";
import { TextField } from "components/Forms";
import useLiveAdjustmentsTotal from "./useLiveAdjustmentsTotal";

/**
 * @param {string} currencyCode
 * @returns {JSX.Element}
 */
function SettlementAdjustmentsFields({ currencyCode }) {
  const [_, { value }, { setValue }] = useField("adjustments");
  const liveAdjustmentsTotal = useLiveAdjustmentsTotal();

  // ensure there is always an empty row to add an additional adjustment as needed
  useEffect(() => {
    const emptyRow = value.find((adjustment) => adjustment.description === "");
    if (!emptyRow) {
      setValue([...value, { description: "", amount: "" }], false);
    }
  }, [value, setValue]);

  return (
    <Box>
      <Heading as="h4" size="sm" mb="2">
        Adjustments
      </Heading>
      <Table size="sm">
        <Tbody>
          {value.map((_, index) => (
            <Tr key={index}>
              <Td>
                <TextField
                  name={`adjustments[${index}].description`}
                  placeholder="Adjustment description"
                  maxLength={80}
                />
              </Td>
              <Td isNumeric>
                <TextField
                  name={`adjustments[${index}].amount`}
                  placeholder="0"
                  maxLength={20}
                  inputCssOverrides={`text-align:right;`}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td>Total Adjustments</Td>
            <Td isNumeric>
              <Money value={liveAdjustmentsTotal} currency={currencyCode} />
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  );
}

export default SettlementAdjustmentsFields;
