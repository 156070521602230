import { debug } from "utils";
import { METHODS, mapProxyFetch, serviceMap } from "../utils";
import buildTiliaApiResponse from "../buildTiliaApiResponse";
import { anyFailed, mapToErrors } from "./utils";

const accountsOpsProxy = mapProxyFetch(serviceMap.ACCOUNTS_OPS_API);

const addPermissionToGroup = async (group, permission, ctx) => {
  debug(ctx, "[addPermissionToGroup]", group, permission);
  const response = await accountsOpsProxy(ctx, `/tools/permission`, METHODS.POST, {
    group_name: group,
    scope: permission,
  });
  const apiResponse = await buildTiliaApiResponse(response);
  if (apiResponse.status !== 200) {
    const error = new Error();
    error.errors = apiResponse.errors;
    error.permission = permission;
    throw error;
  }
  return apiResponse.payload;
};

export const addPermissionsToGroup = async ({ groupName, permissions, agent }) => {
  // return a single promise the resolves / rejects with the synthesis of the mapped results
  return new Promise(async (resolve, reject) => {
    const results = await Promise.allSettled(
      permissions.map((permission) => addPermissionToGroup(groupName, permission, agent))
    );
    return anyFailed(results) ? reject(mapToErrors(results)) : resolve(results);
  });
};
