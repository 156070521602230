import React from "react";
import * as R from "ramda";
import * as Yup from "yup";
import { Box, HStack, useDisclosure } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { AddIcon } from "components/Icons";
import { useAgent } from "context/agentContext";
import { SelectField, SubmitCancelButtonGroup, TextField } from "components/Forms";
import { FormModal } from "components/Modal";
import { addPermissionsToGroup } from "api/account/addPermissionsToGroup";
import { noop } from "utils";

const testIdPrefix = "add-permissions-";

export const useAddPermissions = (
  group,
  permissionsData,
  onAddPermissions = noop,
  addableScopes
) => {
  const { agent } = useAgent();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const scopesForThisGroup = permissionsData.permissionsModel.groupScopeMap[group];
  const alreadyAssigned = (scope) => R.includes(scope, scopesForThisGroup || []);
  const finalScopes = R.pipe(
    R.sortBy(R.identity),
    R.map((s) => ({ value: s, label: s, disabled: alreadyAssigned(s) }))
  )(addableScopes);

  const AddPermissionsModal = () => {
    const addPermissions = useMutation({
      mutationFn: addPermissionsToGroup,
    });

    const handleSubmit = async ({ groupName, permissions }, { setStatus }) => {
      return addPermissions.mutate(
        { groupName, permissions, agent },
        {
          onError: (error) => {
            setStatus({
              error: error.map((e) => (
                <HStack key={e.permission} spacing="8px">
                  <Box>{e.permission}:</Box>
                  <Box>{e.message.join(", ")}</Box>
                </HStack>
              )),
            });
          },
          onSuccess: () => onAddPermissions(),
        }
      );
    };

    return (
      <FormModal
        testIdPrefix={testIdPrefix}
        actions={<SubmitCancelButtonGroup onCancel={onClose} disableOnClean />}
        formikProps={{
          initialValues: { groupName: group, permissions: [] },
          validationSchema: () =>
            Yup.object().shape({
              permissions: Yup.array().min(1),
            }),
          onSubmit: handleSubmit,
        }}
        resetOnOpen={true}
        isOpen={isOpen}
        onClose={onClose}
        title="Add Permissions to Group"
      >
        <TextField
          data-testid={`${testIdPrefix}group-field`}
          name="groupName"
          label="Group"
          readOnly={true}
        />
        <SelectField
          name="permissions"
          label="Permissions"
          required
          isMulti
          options={finalScopes}
          isOptionDisabled={R.prop("disabled")}
        />
      </FormModal>
    );
  };

  const addPermissionsMenuItem = {
    key: `add-perm-${group}`,
    item: {
      label: "Add permissions...",
      icon: <AddIcon size="1rem" />,
      onSelect: onOpen,
    },
    comp: <AddPermissionsModal key={`add-perms-${group}`} />,
  };

  return { AddPermissionsModal, addPermissionsMenuItem };
};
