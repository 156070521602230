import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { noop } from "utils";
import { slug } from "utils/format";
import { Button, Menu, MenuButton as CMenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { ChevronDown } from "components/Icons";

const NoIcon = styled.span`
  display: block;
  width: 1rem;
  height: 1rem;
`;

export const MenuButton = ({ buttonLabel, items = [], ...buttonProps }) => {
  return (
    <Menu>
      <CMenuButton
        as={Button}
        variant="menu"
        size="sm"
        data-testid={`${buttonLabel}-menu-button`}
        {...buttonProps}
        rightIcon={<ChevronDown />}
      >
        {buttonLabel}
      </CMenuButton>
      <MenuList>
        {items.map(({ icon, label, disabled = false, onSelect, right, ...props }) => {
          return (
            <MenuItem
              isDisabled={disabled}
              data-testid={`menuitem-${props.testId || slug(label)}`}
              onClick={disabled ? noop : onSelect}
              key={label}
              icon={icon || <NoIcon />}
            >
              <span
                css={`
                  display: flex;
                  width: 100%;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <span>{label}</span>
                {right && <span>{right}</span>}
              </span>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
