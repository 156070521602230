import React from "react";
import * as R from "ramda";
import "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useModal, ModalFooter } from "components/Modal";
import { theme } from "styled-tools";
import { DownloadLink } from "./DownloadLink";
import { Center, Image } from "@chakra-ui/react";

const r = /data:image\/(.+);base64/;
const getImgType = (data) => R.prop(1)(data.match(r));

export const DocumentImage = ({ data, ...props }) => {
  const [Modal, openModal] = useModal();

  if (!data) return null;
  let imgData = data;
  // medium test
  // imgData = "https://s.driving-tests.org/img/license/washington-drivers-license.jpg";
  // large test
  // imgData = "https://live.staticflickr.com/5310/5694591246_af8117f867_o.jpg";

  const fileExt = getImgType(imgData) || "unknown";
  return (
    <div
      data-testid="document-image"
      css={`
        height: 100%;
        display: flex;
        align-items: center;
      `}
    >
      <img
        onClick={openModal}
        css={`
          &:hover {
            cursor: pointer;
            border: 1px solid ${theme("colors.link.base")};
          }
          border: 1px solid #eee;
          min-width: 10px;
          max-width: 100px;
          min-height: 10px;
          max-height: 100px;
        `}
        src={imgData}
        alt="Document"
        {...props}
      />
      <Modal size="full" titleText={props.alt}>
        <Center>
          <Image {...props} alt="Document" maxHeight="80vh" maxWidth="100%" src={imgData} />
        </Center>
        <ModalFooter>
          <DownloadLink data={data} filename={`${props.alt}.${fileExt}`} />
        </ModalFooter>
      </Modal>
    </div>
  );
};
