import { TransactionType } from "@tilia-tools/core/transaction";
import BalanceTransferHeader from "./BalanceTransfer/BalanceTransferHeader";
import EscrowPurchaseHeader from "./EscrowPurchase/EscrowPurchaseHeader";
import PayoutHeader from "./Payout/PayoutHeader";
import PurchaseHeader from "./UserPurchase/PurchaseHeader";
import TokenPurchaseHeader from "./TokenPurchase/TokenPurchaseHeader";
import TokenConversionHeader from "./TokenConversion/TokenConversionHeader";

function TransactionHeader({ transaction }) {
  switch (transaction.type) {
    case TransactionType.PURCHASE:
      return <PurchaseHeader purchase={transaction} />;
    case TransactionType.ESCROW_PURCHASE:
      return <EscrowPurchaseHeader escrowPurchase={transaction} />;
    case TransactionType.PAYOUT:
      return <PayoutHeader payout={transaction} />;
    case TransactionType.TOKEN_PURCHASE:
      return <TokenPurchaseHeader tokenPurchase={transaction} />;
    case TransactionType.TOKEN_CONVERSION:
      return <TokenConversionHeader tokenConversion={transaction} />;
    case TransactionType.BALANCE_TRANSFER:
      return <BalanceTransferHeader balanceTransfer={transaction} />;
    default:
      return null;
  }
}

export default TransactionHeader;
