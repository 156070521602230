import React from "react";
import useAgentHasPermissions from "context/useAgentHasPermissions";

export const PermissionsMessage = () => (
  <div data-testid="permissions">Apologies, but you do not have permissions to view this data.</div>
);

export const PermissionsActionMessage = () => (
  <div data-testid="permissions">
    Apologies, but you do not have permission to perform this action.
  </div>
);

export const PermissionsCheck = ({ permissions, children }) =>
  useAgentHasPermissions(permissions) ? children : <PermissionsMessage />;
