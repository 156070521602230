import React from "react";
import * as R from "ramda";
import { MenuButton } from "components/Menu";
import { useAddPermissions } from "./AddPermissions";
import { useRemovePermissions } from "./RemovePermissions";

export const GroupMenu = ({ group, data, onChange, addableScopes }) => {
  const { addPermissionsMenuItem } = useAddPermissions(group, data, onChange, addableScopes);
  const { removePermissionsMenuItem } = useRemovePermissions(group, data, onChange);
  const menuItems = [addPermissionsMenuItem, removePermissionsMenuItem];

  return (
    <>
      <MenuButton buttonLabel="Actions" items={[...R.pluck("item")(menuItems)]} />
      {R.pluck("comp")(menuItems)}
    </>
  );
};
