import React from "react";
import * as R from "ramda";
import { SubNavRow, SubNavRowRight, SubNavFilter, SubNavLinkItem } from "containers/Nav/SubNav";
import { Download } from "./Download";
import { AddGroupModalButton } from "./AddGroupModal";

export const PermissionsSubNav = ({ filter, onFilterChange, data, onAddGroup }) => {
  const config = [
    { to: "by-group", name: "By Group" },
    { to: "by-agent", name: "By Agent " },
    { to: "by-permission", name: "By Permission" },
  ];

  return (
    <SubNavRow>
      <div>
        {config.map((c) => (
          <SubNavLinkItem key={c.to} to={c.to} routeSet={R.pluck("to", config)} compat={true}>
            {c.name}
          </SubNavLinkItem>
        ))}
      </div>
      <SubNavRowRight>
        <SubNavFilter
          data-testid="permissions-filter"
          filter={filter}
          onFilterChange={onFilterChange}
        />
        <Download data={data} />
        <AddGroupModalButton onAddGroup={onAddGroup} allGroups={data.permissionsModel.allGroups} />
      </SubNavRowRight>
    </SubNavRow>
  );
};
