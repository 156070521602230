import "styled-components/macro";
import { GlobalStyle } from "styles";
import { TiliaSmall } from "components/Logos";

function FullPageError({ error }) {
  return (
    <div
      css={`
        border: 1px solid red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
      `}
    >
      <GlobalStyle />
      <div style={{ textAlign: "center" }}>
        <TiliaSmall />
        <h1>ERROR</h1>
      </div>
      {process.env.NODE_ENV !== "production" && <p>{error.toString()}</p>}
    </div>
  );
}

export default FullPageError;
