import { Box, Card, CardBody, CardHeader, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { CopyableText } from "components/Copyable";
import DetailList from "components/DetailList/DetailList";
import VerificationBadge from "components/VerificationBadge/VerificationBadge";
import PaymentMethodLogo from "components/PaymentMethod/PaymentMethodLogo";

function PayoutMethod({ payout }) {
  const payoutMethod = payout.credit?.destinationPaymentMethod;
  const gray600 = useColorModeValue("gray.600", "gray.200");

  if (!payoutMethod) {
    return null;
  }
  return (
    <Card>
      <CardHeader>
        <Box fontSize="12px">
          <PaymentMethodLogo methodClass={payoutMethod.methodClass} />
        </Box>
      </CardHeader>
      <CardBody>
        <Stack gap={2}>
          {payoutMethod.providerVerified && <VerificationBadge />}
          <DetailList
            details={[
              ["Name", payoutMethod.fullName],
              ["Email", payoutMethod.providerEmail],
            ]}
          />
          <CopyableText text={payoutMethod.paymentMethodId}>
            <Text color={gray600} fontSize="xs" as={"span"}>
              {payoutMethod.paymentMethodId}
            </Text>
          </CopyableText>
        </Stack>
      </CardBody>
    </Card>
  );
}

export default PayoutMethod;
