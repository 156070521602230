import React, { useState } from "react";
import "styled-components/macro";
import { useModal, ModalFooter, ModalErrors } from "components/Modal";
import {
  ButtonIconWrapper,
  TextLinkButton,
  ButtonSpinner,
  OutlineButton,
  PrimaryButton,
} from "components/Button";
import { EmailVerifiedIcon } from "components/Icons";
import { noop } from "utils";
import { useAccount } from "context/accountContext";

const STATUS = {
  PRE_INIT: "PRE_INIT",
  INITIALIZED: "INITIALIZED",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const useSendKYCEmailModal = () => {
  const { postSendKYCEmail } = useAccount();
  const [state, setState] = useState({ status: STATUS.PRE_INIT });
  const [Modal, openModal, closeModal] = useModal();

  const SendKYCEmailModal = ({ accountId }) => {
    const handleSubmit = async () => {
      setState((state) => ({ ...state, status: STATUS.INITIALIZED }));

      try {
        await postSendKYCEmail(accountId);
        setState((state) => ({
          ...state,
          status: STATUS.SUCCESS,
          error: null,
        }));
        closeModal();
      } catch (error) {
        setState((state) => ({
          ...state,
          status: STATUS.ERROR,
          error,
        }));
      }
    };

    const cancelButton =
      state.status === STATUS.INITIALIZED ? (
        <TextLinkButton disabled={true} onClick={noop}>
          Cancel
        </TextLinkButton>
      ) : (
        <TextLinkButton data-testid="modal-send-kyc-cancel-button" onClick={closeModal}>
          Cancel
        </TextLinkButton>
      );

    const actionButton =
      state.status === STATUS.INITIALIZED ? (
        <PrimaryButton disabled={true} onClick={noop}>
          Send KYC Email
          <ButtonSpinner />
        </PrimaryButton>
      ) : (
        <PrimaryButton data-testid="modal-send-kyc-button" type="submit" onClick={handleSubmit}>
          Send KYC Email
          <ButtonIconWrapper>
            <EmailVerifiedIcon size="1rem" />
          </ButtonIconWrapper>
        </PrimaryButton>
      );

    const errorFooter = state.status === STATUS.ERROR ? <ModalErrors errors={state.error} /> : null;

    return (
      <Modal titleText="Send KYC Email">
        <div>Are you sure you want to send a KYC email?</div>
        <ModalFooter>
          {cancelButton}
          {actionButton}
        </ModalFooter>
        {errorFooter}
      </Modal>
    );
  };

  const SendKYCEmailButton = () => (
    <OutlineButton data-testid="send-kyc-button" onClick={openModal}>
      Send KYC Email
      <ButtonIconWrapper>
        <EmailVerifiedIcon size="1rem" />
      </ButtonIconWrapper>
    </OutlineButton>
  );

  const getSendEmailMenuItem = () => {
    return {
      disabled: false,
      label: `Send KYC email...`,
      icon: <EmailVerifiedIcon size="1rem" />,
      onSelect: openModal,
      "data-displayitem": true,
    };
  };

  return [SendKYCEmailModal, SendKYCEmailButton, getSendEmailMenuItem];
};
