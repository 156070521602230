import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars

export const Center = styled.div`
  height: calc(100vh - 1px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Column = styled.div`
  width: 50%;
  min-width: 300px;
`;
