import { useEffect, useRef, useState } from "react";

export const usePageHeight = () => {
  const boundingRef = useRef(null);
  const [top, setTop] = useState(0);
  useEffect(() => {
    setTop(boundingRef.current.getBoundingClientRect().top);
  }, [boundingRef]);

  return { boundingRef, top };
};
