import { forwardRef } from "react";
import { useFormikContext } from "formik";
import { Button } from "@chakra-ui/react";

const CancelButton = forwardRef(function ({ disabled = false, ...props }, ref) {
  const { isSubmitting } = useFormikContext();

  return (
    <Button
      ref={ref}
      type="button"
      colorScheme="gray"
      isDisabled={isSubmitting || disabled}
      {...props}
    >
      Cancel
    </Button>
  );
});
CancelButton.displayName = "CancelButton";

export default CancelButton;
