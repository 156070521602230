import React from "react";
import { css } from "styled-components/macro";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import { useAgent } from "context/agentContext";
import { appConfigKeys, useAppConfig } from "context/appConfigContext";
import { useQuery } from "react-query";
import { getClientScopes } from "api/account";
import { Panel } from "components/Panel";
import { isNilOrEmpty } from "utils";
import { Box, HighlightFilter, GroupList, PermissionList, AgentLink } from "./common";
import { GroupMenu } from "./GroupMenu";
import { useTitle } from "context/titleContext";

const grid = css`
  display: grid;
  grid-template-columns: repeat(5, 1fr) min-content;
  grid-gap: 4px;
`;

const AgentGroupList = ({ agentMap, groupData, filter }) => (
  <div>
    {R.pipe(
      R.uniqBy(R.prop("element")),
      R.sortBy((d) => agentMap[d.element].username),
      R.map((d) => <AgentLink agent={agentMap[d.element]} key={d.element} filter={filter} />)
    )(groupData)}
  </div>
);

const getPasswordScopeNames = R.pipe(
  R.prop("payload"),
  R.defaultTo([]),
  R.filter(R.propEq("password", "token_type")),
  R.pluck("scope_name"),
  R.uniq
);

export const ByGroup = ({ data, onChange, filter }) => {
  const { agent } = useAgent();
  const toolsClientId = useAppConfig(appConfigKeys.CLIENT_ID);
  const query = useQuery(toolsClientId, () => getClientScopes(toolsClientId, agent));
  const addableScopes = getPasswordScopeNames(query.data || {});

  const { group } = useParams();
  const { groupAccountMap, groupGroupMap, groupScopeMap } = data.permissionsModel;
  const groups = R.sortBy(R.identity, R.keys(groupScopeMap));

  useTitle({ sub1: "By Group", sub2: group });
  return (
    <Panel>
      <div css={grid}>
        <Box>Group</Box>
        <Box>Parents</Box>
        <Box>Children</Box>
        <Box>Permissions</Box>
        <Box>Agents</Box>
        <Box></Box>
        {R.pipe(
          R.filter((g) => isNilOrEmpty(group) || group === g),
          R.filter((group) => {
            if (isNilOrEmpty(filter)) return true;
            const usernames = R.map((g) => data.agentMap[g.element].username)(
              groupAccountMap[group] || []
            );
            const scopes = groupScopeMap[group] || [];

            const filterable = [
              group,
              ...groupGroupMap[group].parents,
              ...groupGroupMap[group].children,
              ...scopes,
              ...usernames,
            ];
            return R.any(R.test(filter))(filterable);
          }),
          R.map((group) => (
            <div key={group} css="display: contents;">
              <Box>
                <HighlightFilter filter={filter}>{group}</HighlightFilter>
              </Box>
              <Box>
                <GroupList data={groupGroupMap[group].parents} filter={filter} />
              </Box>
              <Box>
                <GroupList data={groupGroupMap[group].children} filter={filter} />
              </Box>
              <Box>
                <PermissionList data={groupScopeMap[group]} filter={filter} />
              </Box>
              <Box>
                <AgentGroupList
                  agentMap={data.agentMap}
                  groupData={groupAccountMap[group] || []}
                  filter={filter}
                />
              </Box>
              <Box>
                <GroupMenu
                  group={group}
                  data={data}
                  onChange={onChange}
                  addableScopes={addableScopes}
                />
              </Box>
            </div>
          ))
        )(groups)}
      </div>
    </Panel>
  );
};
