import React, { useState } from "react";
import * as R from "ramda";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useModal, ModalFooter } from "components/Modal";
import { TextLinkButton, PrevButton, NextButton } from "components/Button";
import { DownloadLink } from "./DownloadLink";

import { pdfjs, Page, Document as ReactPDFDoc } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/static/pdf.worker.js`;

export const PDFHeader = "data:application/pdf;base64,";

export const DocumentPDF = ({ data, ...props }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [Modal, openModal, closeModal] = useModal();

  const passwordProtect = (callback) => {
    const pdfPassword = prompt("Enter password");
    // if the password given is 'null' that means cancel was selected,
    // and we should exit out of the function call and close the modal.
    if (R.isNil(pdfPassword)) {
      closeModal();
      return;
    }
    callback(pdfPassword);
  };

  const nextPage = () => setPageNumber((prevPageNumber) => prevPageNumber + 1);
  const prevPage = () => setPageNumber((prevPageNumber) => prevPageNumber - 1);

  const loadingComponent = (
    <div
      css={`
        display: flex;
        height: 68vh;
        justify-content: center;
        align-items: center;
      `}
    >
      Loading...
    </div>
  );

  return (
    <div
      data-testid="document-pdf"
      css={`
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
      `}
    >
      <TextLinkButton onClick={openModal}>View PDF</TextLinkButton>
      <Modal titleText={props.alt}>
        <ReactPDFDoc
          options={{
            cMapUrl: `${process.env.PUBLIC_URL}/static/cmaps/`,
            cMapPacked: true,
          }}
          file={data}
          onPassword={passwordProtect}
          loading={loadingComponent}
          onLoadSuccess={(doc) => setNumPages(doc.numPages)}
          onLoadError={(e) => console.error("onLoadError:", e)} // eslint-disable-line no-console
          onSourceError={(e) => console.eror("onSourceError:", e)} // eslint-disable-line no-console
        >
          <div
            css={`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <PrevButton
              data-testid={"prev-page-button"}
              disabled={pageNumber <= 1}
              onClick={prevPage}
            />
            <Page scale={0.8} pageNumber={pageNumber} />
            <NextButton
              data-testid={"next-page-button"}
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            />
          </div>
          <div
            css={`
              display: flex;
              justify-content: center;
              padding: 8px;
            `}
          >
            Page {pageNumber} of {numPages}
          </div>
        </ReactPDFDoc>
        <ModalFooter>
          <DownloadLink data={data} filename={`${props.alt}.pdf`} />
        </ModalFooter>
      </Modal>
    </div>
  );
};
