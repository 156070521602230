import { READ_SETTLEMENTS } from "context/permissions";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import { FilterContextProvider } from "components/Filter";
import { PageContent, PageTitle } from "components/Page";
import { PermissionsMessage } from "components/PermissionsMessage";
import HistoricalSettlementsFilters from "components/Settlements/HistoricalSettlementsFilters";
import HistoricalSettlementsTable from "components/Settlements/HistoricalSettlementsTable";

const filterConfig = [
  { name: "integrator", multiple: true, defaultValue: [], graphQlName: "integrators" },
  // { name: "date", type: "date", multiple: true, defaultValue: [], graphQlName: "dates" },
];

const Historical = () => {
  const hasPermissions = useAgentHasPermissions(READ_SETTLEMENTS);
  return (
    <PageContent title={{ page: "Settlements", sub1: "History" }} opts={{ clear: true }}>
      <PageTitle>Historical Settlements</PageTitle>
      {!hasPermissions ? (
        <PermissionsMessage />
      ) : (
        <FilterContextProvider config={filterConfig}>
          <HistoricalSettlementsFilters />
          <HistoricalSettlementsTable />
        </FilterContextProvider>
      )}
    </PageContent>
  );
};

export default Historical;
