/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { useField } from "formik";

/**
 * Calculates and returns the total of the adjustment fields in the current form.
 *
 * @returns {number}
 */
function useLiveAdjustmentsTotal() {
  const [_, { value }] = useField("adjustments");

  if (!Array.isArray(value)) {
    return 0;
  }

  return value.reduce((total, adjustment) => {
    const value = parseInt(adjustment.amount, 10);
    if (isNaN(value)) {
      return total;
    }
    return total + value;
  }, 0);
}

export default useLiveAdjustmentsTotal;
