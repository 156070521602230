import React from "react";
import styled from "styled-components";

// can we do better than defining 3 columns when we only need 2?
const ResultViewWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 20px;
  align-items: center;

  & > div {
    display: inline-flex;
  }
`;

const ResultViewMore = ({ length }) => {
  return (
    <ResultViewWrapper>
      <div style={{ color: "#999" }}>{`Displaying ${length} ${
        length === 1 ? "Result" : "Results"
      }`}</div>
    </ResultViewWrapper>
  );
};

export default ResultViewMore;
