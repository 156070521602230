import { getAppConfig } from "api/toolsWeb";
import { FullPageSpinner } from "components/Spinner";
import * as R from "ramda";
import React, { createContext, useContext, useEffect, useState } from "react";
import * as utils from "utils";
import { useToken } from "./tokenContext";

export const appConfigKeys = {
  FF_REFUND: ["feature_flags", "refund"],
  REFUND_REASONS: ["refund_reasons"],
  PRODUCT_TAX_CODES: ["product_tax_codes"],
  CLIENT_ID: ["client_id"],
};

export const AppConfigContext = createContext();

export const AppConfigProvider = (props) => {
  const [appConfig, setAppConfig] = useState({ loaded: false });
  const { sessionJWT } = useToken();
  const integrator = utils.decodeToken(sessionJWT).integrator;

  useEffect(() => {
    const getConfig = async () => {
      const config = await getAppConfig(integrator);
      setAppConfig({ ...config, loaded: true });
    };
    getConfig();
  }, [integrator]);

  if (!appConfig.loaded) {
    return <FullPageSpinner />;
  }

  return <AppConfigContext.Provider value={appConfig} {...props} />;
};

export const useAppConfig = (key) => {
  const appConfig = useContext(AppConfigContext);
  return R.path(key, appConfig);
};
