import { useDisclosure } from "@chakra-ui/react";
import {
  detailsActionMenuKeys,
  useBlockUserActionMenuItem,
  useUnblockUserActionMenuItem,
} from "components/Account";
import { ActionMenu } from "components/ActionMenu";
import { useChangeUsernameModal } from "containers/Pages/AccountDetails/Details/ChangeUsernameModal";
import { useCreateEntityModal } from "containers/Pages/AccountDetails/Details/CreateEntityModal";
import { useAddNoteModal } from "containers/Pages/AccountDetails/Details/Notes";
import EditPayoutSettingsModal from "./EditPayoutSettingsModal";
import { SettingsIcon } from "components/Icons";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import { useIntegrator } from "context/integratorContext";

const DetailsActionMenu = ({ detailsData = {}, onSuccessfulUpdate, refetchAccountDetails }) => {
  const { menuSettings } = useIntegrator(detailsData.integrator);

  const [AddNoteModal, getAddNoteMenuItem] = useAddNoteModal();
  const blockUserActionMenuItem = useBlockUserActionMenuItem(
    detailsActionMenuKeys.BLOCK,
    detailsData
  );
  const unblockUserActionMenuItem = useUnblockUserActionMenuItem(
    detailsActionMenuKeys.UNBLOCK,
    detailsData
  );
  const [CreateEntityModal, getCreateEntityMenuItem] = useCreateEntityModal();
  const [ChangeUsernameModal, getChangeUsernameMenuItem] = useChangeUsernameModal();
  const {
    isOpen: payoutSettingsIsOpen,
    onOpen: payoutSettingsOnOpen,
    onClose: payoutSettingsOnClose,
  } = useDisclosure();
  const canEditPayoutSettings = useAgentHasPermissions(["write_tags"]);

  const menuItems = [
    {
      key: detailsActionMenuKeys.NOTE,
      item: getAddNoteMenuItem(),
      comp: (
        <AddNoteModal
          key={detailsActionMenuKeys.NOTE}
          accountId={detailsData.account_id}
          integrator={detailsData.integrator}
          onSuccessfulUpdate={onSuccessfulUpdate}
        />
      ),
    },
    blockUserActionMenuItem,
    unblockUserActionMenuItem,
    {
      key: detailsActionMenuKeys.USERNAME,
      item: getChangeUsernameMenuItem(),
      comp: (
        <ChangeUsernameModal
          key={detailsActionMenuKeys.USERNAME}
          onSuccessfulUpdate={onSuccessfulUpdate}
          account={detailsData}
        />
      ),
    },
    {
      key: detailsActionMenuKeys.EDIT_PAYOUT_SETTINGS,
      item: {
        label: "Edit payout settings",
        onSelect: payoutSettingsOnOpen,
        "data-displayitem": canEditPayoutSettings,
        icon: <SettingsIcon size="1rem" />,
      },
      comp: (
        <EditPayoutSettingsModal
          key={detailsActionMenuKeys.EDIT_PAYOUT_SETTINGS}
          accountId={detailsData.account_id}
          isOpen={payoutSettingsIsOpen}
          onClose={payoutSettingsOnClose}
          requirePayoutReviewTagId={
            detailsData.tags?.find((tag) => tag.tag === "payout_review_required")?.tag_id
          }
          onSuccessfulUpdate={refetchAccountDetails}
        />
      ),
    },
    {
      key: detailsActionMenuKeys.CREATE_ENTITY,
      item: getCreateEntityMenuItem(),
      comp: <CreateEntityModal key={detailsActionMenuKeys.CREATE_ENTITY} />,
    },
  ];

  return <ActionMenu menuItems={menuItems} menuSettings={menuSettings} />;
};

export default DetailsActionMenu;
