/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import * as Yup from "yup";
import { useAccount } from "context/accountContext";
import { ctxWithIntegrator } from "utils/auth";
import { formatDateTime } from "utils";
import { markSettlementPaid } from "api/settlement";
import { Text } from "@chakra-ui/react";
import { SubmitCancelButtonGroup } from "components/Forms";
import { LoadingIcon } from "components/Icons";
import { FormModal } from "components/Modal";
import { DetailText } from "components/Text";
import useAgentCanMarkSettlementPaid from "./useAgentCanMarkSettlementPaid";
import useSettlement from "./useSettlement";
import MarkSettlementPaidFields from "./MarkSettlementPaidFields";
import SettlementAdjustmentsSummary from "./SettlementAdjustmentsSummary";
import SettlementApprovalsSummary from "./SettlementApprovalsSummary";
import SettlementBalanceSummary from "./SettlementBalanceSummary";
import SettlementDeductionsSummary from "./SettlementDeductionsSummary";
import SettlementRevenueSummary from "./SettlementRevenueSummary";

const validationSchema = Yup.object().shape({
  referenceNumber: Yup.string().trim().required("Reference number is required"),
});

function MarkSettlementPaidModal({
  settlementIdentifier,
  onClose,
  onSettlementMarkedPaid,
  ...modalProps
}) {
  const { agentContext } = useAccount();

  const { loading, data } = useSettlement(settlementIdentifier);
  const canMarkPaid = useAgentCanMarkSettlementPaid(settlementIdentifier);

  const settlement = data?.settlement;

  const handleSubmit = async ({ referenceNumber }, { setStatus }) => {
    const result = await markSettlementPaid(
      settlementIdentifier.settlementId,
      { referenceNumber },
      ctxWithIntegrator(settlement.integrator, agentContext)
    );

    if (result.status !== 200) {
      setStatus({ error: result.errors.join(" ") });
      return;
    }

    onSettlementMarkedPaid(settlementIdentifier);
  };

  return (
    <FormModal
      formikProps={{
        initialValues: {
          referenceNumber: "",
        },
        validationSchema,
        onSubmit: handleSubmit,
      }}
      onClose={canMarkPaid ? undefined : onClose}
      title="Mark Settlement Paid"
      isLoading={loading}
      actions={canMarkPaid ? <SubmitCancelButtonGroup onCancel={onClose} /> : undefined}
      {...modalProps}
    >
      {!settlement && loading && <LoadingIcon size={2} />}
      {!settlement && !loading && <p>Not found</p>}
      {settlement && (
        <>
          <Text mt={0}>
            {settlement.integrator}
            <br />
            <DetailText>
              {formatDateTime(settlement.periodStartDatetime)}
              {" – "}
              {formatDateTime(settlement.periodEndDatetime)}
            </DetailText>
          </Text>
          <SettlementRevenueSummary
            summary={settlement.summary}
            currencyCode={settlement.currencyCode}
          />
          <SettlementDeductionsSummary
            summary={settlement.summary}
            currencyCode={settlement.currencyCode}
          />
          <SettlementAdjustmentsSummary
            summary={settlement.summary}
            currencyCode={settlement.currencyCode}
            adjustments={settlement.adjustments}
          />
          <SettlementBalanceSummary
            summary={settlement.summary}
            currencyCode={settlement.currencyCode}
            balanceTransfer={settlement.balanceTransfer}
            payout={settlement.payout}
          />
          {canMarkPaid && <MarkSettlementPaidFields />}
          <SettlementApprovalsSummary
            approvals={settlement.approvals}
            statusHistory={settlement.statusHistory}
          />
        </>
      )}
    </FormModal>
  );
}

export default MarkSettlementPaidModal;
