import { RefundStatusCode } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the refund status code.
 *
 * @param {RefundStatusCode} refundStatusCode
 * @returns {JSX.Element}
 */
function RefundStatusCodeName({ refundStatusCode }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (refundStatusCode) {
    case RefundStatusCode.OPEN:
      return <>Open</>;
    case RefundStatusCode.PROCESSING:
      return <>Processing</>;
    case RefundStatusCode.SUCCESS:
      return <>Success</>;
    case RefundStatusCode.FAILED:
      return <>Failed</>;
    case RefundStatusCode.SYSTEM_CANCELED:
      return <>System Canceled</>;
    default:
      return <>{refundStatusCode}</>;
  }
}

export default RefundStatusCodeName;
