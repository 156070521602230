/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { useMemo } from "react";
import { isAfter, parseISO } from "date-fns";
import { formatDateTime } from "utils";
import { Box, Heading, ListItem, UnorderedList } from "@chakra-ui/react";
import { DetailText } from "components/Text";
import { SettlementApprovalType, SettlementStatusCode } from "./settlementTypes";

const approvalTypeVerb = {
  [SettlementApprovalType.REVIEW]: "Reviewed",
  [SettlementApprovalType.APPROVE]: "Approved",
  [SettlementApprovalType.SUPER_APPROVE]: "Super Approved",
};

/**
 * @param {SettlementApproval[]} approvals
 * @param {SettlementStatus[]} statusHistory
 * @returns {JSX.Element}
 */
function SettlementApprovalsSummary({ approvals, statusHistory }) {
  const entries = useMemo(() => {
    // find any "returned for re-review" statuses
    return statusHistory
      .filter((status) => {
        return status.settlementStatusCode === SettlementStatusCode.RETURNED;
      })
      .map((status) => {
        return {
          type: "return",
          date: status.startDatetime,
          accountId: status.userAccount.accountId,
          username: status.userAccount.username,
        };
      })
      .concat(
        // combine them with the approvals
        approvals.map((approval) => {
          return {
            type: "approval",
            date: approval.approvalDatetime,
            accountId: approval.userAccount.accountId,
            username: approval.userAccount.username,
            approvalType: approval.approvalType,
          };
        })
      )
      .sort((a, b) => {
        const aDatetime = parseISO(a.date);
        const bDatetime = parseISO(b.date);
        return isAfter(aDatetime, bDatetime) ? -1 : 1; // reverse-chronological
      });
  }, [approvals, statusHistory]);

  if (entries.length < 1) {
    return null;
  }

  return (
    <Box>
      <Heading as="h4" size="sm" mb="2">
        Approvals
      </Heading>
      <UnorderedList spacing={2} ms={0} ps={3}>
        {entries.map((entry, index) => (
          <ListItem key={index}>
            {entry.type === "return" ? (
              <DetailText>
                Returned for re-review by {entry.username} on {formatDateTime(entry.date)}
              </DetailText>
            ) : (
              <>
                {approvalTypeVerb[entry.approvalType]} by {entry.username} on{" "}
                {formatDateTime(entry.date)}
              </>
            )}
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
}

export default SettlementApprovalsSummary;
