import { TransactionTypeScopes } from "./types/transactionTypes.js";

/**
 * Returns a Set containing the transaction types the agent has access to based on
 * their assigned scopes.
 *
 * @param {string[]} agentScopes
 * @returns {Set<TransactionType>}
 */
function calcAgentTransactionTypes(agentScopes) {
  const transactionTypes = new Set();

  for (const [transactionType, typeScopes] of TransactionTypeScopes) {
    for (const scope of typeScopes) {
      if (agentScopes.includes(scope)) {
        transactionTypes.add(transactionType);
      }
    }
  }

  return transactionTypes;
}

export default calcAgentTransactionTypes;
