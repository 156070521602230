import { add, sub } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { getBrowserTimeZone } from "utils/dates";

/**
 * @typedef {Object} DateTimeFilterInput
 * @property {string} [eq] Equal to date
 * @property {string} [before] Before date
 * @property {string} [after] After date
 */

/**
 * Builds and returns a GraphQL DateTimeFilterInput object based on
 * the supplied date value.
 *
 * @param {string} value
 * @returns {DateTimeFilterInput}
 */
function buildDateTimeFilterInput(value) {
  const timeZone = getBrowserTimeZone();

  let after = undefined;
  let before = undefined;

  switch (value) {
    case "today":
      after = new Date();
      before = add(after, { days: 1 });
      break;
    case "last7days":
      before = add(new Date(), { days: 1 });
      after = sub(before, { weeks: 1 });
      break;
    case "last30days":
      before = add(new Date(), { days: 1 });
      after = sub(before, { days: 30 });
      break;
    case "thismonth":
      after = new Date();
      after.setDate(1);
      before = add(after, { months: 1 });
      break;
    case "lastmonth":
      before = new Date();
      before.setDate(1);
      after = sub(before, { months: 1 });
      break;
    case "thisyear":
      after = new Date();
      after.setMonth(0, 1);
      before = add(after, { years: 1 });
      break;
    case "lastyear":
      before = new Date();
      before.setMonth(0, 1);
      after = sub(before, { years: 1 });
      break;
    default:
      if (value.substring(0, 8) === "between:") {
        after = new Date(`${value.substring(8, 18)}T00:00:00`);
        before = add(new Date(`${value.substring(19, 29)}T00:00:00`), { days: 1 });
      } else if (value.substring(0, 7) === "before:") {
        before = new Date(`${value.substring(7, 17)}T00:00:00`);
      } else if (value.substring(0, 6) === "after:") {
        after = add(new Date(`${value.substring(6, 16)}T00:00:00`), { days: 1 });
      } else if (value.substring(0, 3) === "eq:") {
        return { eq: value.substring(3) };
      } else {
        after = new Date(`${value.substring(0, 10)}T00:00:00`);
        before = add(after, { days: 1 });
      }
  }

  if (after) {
    after.setHours(0, 0, 0, 0);
    after = fromZonedTime(after, timeZone);
  }

  if (before) {
    before.setHours(0, 0, 0, 0);
    before = fromZonedTime(before, timeZone);
  }

  return {
    after: after && after.toISOString(),
    before: before && before.toISOString(),
  };
}

export default buildDateTimeFilterInput;
