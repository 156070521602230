/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { gql } from "@apollo/client";
import { useQuery } from "context/graphqlContext";
import { READ_SETTLEMENTS } from "context/permissions";
import buildStringFilterInput from "../Filter/graphql/buildStringFilterInput";
import { SettlementStatusCode } from "./settlementTypes";

const getPendingSettlements = gql`
  query GetPendingSettlements(
    $filter: SettlementFilterInput
    $sort: SettlementFilterSort
    $first: NonNegativeInt
    $after: String
    $last: NonNegativeInt
    $before: String
  ) {
    settlementWorkflowSummary {
      new
      returned
      reviewed
      approved
      settled
    }
    settlements(
      filter: $filter
      sort: $sort
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        node {
          settlementId
          integrator
          periodStartDatetime
          periodEndDatetime
          statusHistory {
            startDatetime
            settlementStatusCode
            userAccount {
              accountId
            }
          }
          approvals {
            approvalDatetime
            userAccount {
              accountId
              username
              firstName
              lastName
            }
            approvalType
          }
          settlementStatusCode
          netSettlement
          currencyCode
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
        offset
      }
      totalCount
    }
  }
`;

const allPendingStatuses = [
  buildStringFilterInput(SettlementStatusCode.NEW),
  buildStringFilterInput(SettlementStatusCode.RETURNED),
  buildStringFilterInput(SettlementStatusCode.REVIEWED),
  buildStringFilterInput(SettlementStatusCode.APPROVED),
  buildStringFilterInput(SettlementStatusCode.SETTLED),
];

const usePendingSettlements = ({ filter, sort, first, after, last, before }) => {
  if (!filter.statuses) {
    filter.statuses = allPendingStatuses;
  } else {
    // Also consider returned for the "NEW" (awaiting review) status
    filter.statuses = filter.statuses.reduce((statuses, status) => {
      statuses.push(status);
      if (status.eq === SettlementStatusCode.NEW) {
        statuses.push(buildStringFilterInput(SettlementStatusCode.RETURNED));
      }
      return statuses;
    }, []);
  }
  return useQuery(getPendingSettlements, {
    permissions: [READ_SETTLEMENTS],
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: filter ?? undefined,
      sort: sort ?? undefined,
      first: first ?? undefined,
      after: after ?? undefined,
      last: last ?? undefined,
      before: before ?? undefined,
    },
  });
};

export default usePendingSettlements;
