/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { Box, Heading } from "@chakra-ui/react";
import { TextField } from "components/Forms";

function MarkSettlementPaidFields() {
  return (
    <Box>
      <Heading as="h4" size="sm" mb="2">
        Manual Payment Details
      </Heading>
      <TextField
        name="referenceNumber"
        label="Journal Entry / Bank Reference Number"
        placeholder="1234-5678"
        maxLength={80}
      />
    </Box>
  );
}

export default MarkSettlementPaidFields;
