import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import LineChart from "components/Chart/Line";
import useCurrencyFormatter from "components/Money/useCurrencyFormatter";
import InfoTooltip from "components/Tooltip/InfoTooltip";
import { format, isYesterday, parseISO } from "date-fns";

export default function ReportSummaryCard({ data, description, title, aggregate = true }) {
  const formatter = useCurrencyFormatter("USD");

  const volume = data.map((i) => ({ x: i.publishDate, y: i.usdAmount }));
  const counts = data.map((i) => ({ x: i.publishDate, y: i.invoiceCount }));
  const startDate = format(parseISO(data[0].publishDate), "MMM d");

  const endDate = parseISO(data[data.length - 1].publishDate);
  const endDateDisplay = isYesterday(endDate) ? "Yesterday" : format(endDate, "MMM d");

  const handleYFormat = (value) => formatter(value);
  const volumeValue = aggregate
    ? data.reduce((sum, daily) => (sum += parseInt(daily.usdAmount)), 0)
    : data[data.length - 1].usdAmount;
  const countValue = aggregate
    ? data.reduce((sum, daily) => (sum += parseInt(daily.invoiceCount)), 0)
    : data[data.length - 1].invoiceCount;
  return (
    <Card data-testid="report-summary-card">
      <CardHeader>
        <Flex alignItems="center">
          <Heading m={0} size="sm" as="h3">
            {title}
          </Heading>
          {description && <InfoTooltip label={description} />}
        </Flex>
      </CardHeader>
      <CardBody display="flex" flexDirection="column" gap={4}>
        <DailyMetric
          title={aggregate ? "Total Volume" : "Balance"}
          value={formatter(volumeValue)}
          leftLabel={startDate}
          rightLabel={endDateDisplay}
        >
          <LineChart
            data={[
              {
                id: `volume-dailies-${title}`,
                data: volume,
              },
            ]}
            yFormat={handleYFormat}
          />
        </DailyMetric>
        {aggregate && (
          <DailyMetric
            title="Count"
            value={countValue}
            leftLabel={startDate}
            rightLabel={endDateDisplay}
          >
            <LineChart
              data={[
                {
                  id: `count-dailies-${title}`,
                  data: counts,
                },
              ]}
            />
          </DailyMetric>
        )}
      </CardBody>
    </Card>
  );
}

function DailyMetric({ title, value, leftLabel, rightLabel, children }) {
  return (
    <Box>
      <Stat variant="brand">
        <StatLabel>{title}</StatLabel>
        <StatNumber>{value}</StatNumber>
      </Stat>
      <Box as="figure" m={0} role="graphics" position="relative" height={48}>
        {children}
      </Box>
      <Flex justifyContent={"space-between"}>
        <Text fontSize="smaller" m={0}>
          {leftLabel}
        </Text>
        <Text fontSize={"smaller"} m={0}>
          {rightLabel}
        </Text>
      </Flex>
    </Box>
  );
}
