import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { ResponsiveLine } from "@nivo/line";
import Tooltip from "components/Chart/Tooltip";

export default function LineChart({ data /* see data tab */, ...rest }) {
  const lineColor = useColorModeValue(
    "var(--chakra-colors-brand-500)",
    "var(--chakra-colors-blue-200)"
  );
  const gridColor = useColorModeValue(
    "var(--chakra-colors-gray-100)",
    "var(--chakra-colors-gray-700)"
  );

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        useUTC: false,
        precision: "day",
      }}
      xFormat="time:%b %d"
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      enablePoints={false}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[]}
      colors={lineColor}
      enableSlices="x"
      theme={{
        grid: {
          line: {
            stroke: gridColor,
          },
        },
      }}
      sliceTooltip={({ slice }) => {
        return (
          <Tooltip>
            {slice.points.map(({ id, data: { xFormatted, yFormatted } }) => (
              <Flex key={id} paddingTop={1} paddingBottom={1} direction="column">
                <Text m={0} color={lineColor} fontSize="md">
                  {yFormatted}
                </Text>
                <Text m={0} fontSize="smaller">
                  {xFormatted}
                </Text>
              </Flex>
            ))}
          </Tooltip>
        );
      }}
      {...rest}
    />
  );
}
