import React from "react";
import styled from "styled-components";
import { ColumnHeader, SelectAllCheckbox } from "components/Grid";
import { FIELDS } from "../constants";
import { strings } from "../strings";
import { row } from "./common";

const Container = styled.div`
  ${row};
  padding-bottom: 8px;
`;

const GridHeader = () => {
  return (
    <Container key="grid-header">
      {[
        { content: <SelectAllCheckbox /> },
        { field: FIELDS.created, content: strings.request_time, sortable: true },
        { field: FIELDS.status, content: strings.status },
        { field: FIELDS.account_id, content: strings.account, sortable: true },
        { field: FIELDS.integrator, content: strings.publisher, sortable: true },
        {
          field: FIELDS.destination_payment_method,
          content: strings.payout_method,
        },
        { field: FIELDS.amount, content: strings.amount, sortable: true },
        {
          /* Space for action column */
        },
      ].map(({ field, content, sortable }, index) => (
        <ColumnHeader key={`header-${field || index}`} field={field} sortable={sortable}>
          {content}
        </ColumnHeader>
      ))}
    </Container>
  );
};

export default GridHeader;
