import React from "react";
import "styled-components/macro";
import * as R from "ramda";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { useAgent } from "context/agentContext";
import { WRITE_SCOPES } from "context/permissions";
import { OutlineButton } from "components/Button";
import { useFormModal } from "components/Modal";
import { SettingsIcon } from "components/Icons";
import { FullPageSpinner } from "components/Spinner";
import { noop } from "utils";
import { addClientScope, getAllScopes } from "api/account";
import { Error, ToggleField, SelectField } from "components/Forms";
import { useFormikContext } from "formik";

const testIdPrefix = "add-client-scope-";

const allTokenTypes = [
  { value: "client_credentials", label: "client credentials", disabled: false },
  { value: "code", label: "code", disabled: false },
  { value: "implicit", label: "implicit", disabled: false },
  { value: "password", label: "password", disabled: false },
  { value: "refresh", label: "refresh", disabled: false },
];

const parseErrors = (results) => {
  let errors = [];
  for (const result of results) {
    if (result.status === "rejected") {
      errors.push(result.reason);
    } else if (result.value?.errors && result.value.errors.length > 0) {
      errors = [...errors, ...result.value.errors];
    }
  }
  return errors;
};

export const useAddClientScopesModal = () => {
  const { agent } = useAgent();
  const [FormModal, openModal] = useFormModal();

  const AddClientScopesModal = ({ clientId, onSuccess = noop, clientScopes = [] }) => {
    const {
      isError,
      isLoading,
      data: allScopes,
    } = useQuery("getAllScopes", () => getAllScopes(agent), { staleTime: 1000 * 60 });

    if (isError) {
      return <Error error="Error retrieving scope list" />;
    }
    if (isLoading) {
      return <FullPageSpinner />;
    }
    const sortedScopes = R.pipe(
      R.path(["payload", "scopes"]),
      R.map((s) => ({ value: s.name, label: s.name, disabled: false }))
    )(allScopes);

    if (!agent.hasPermissions([WRITE_SCOPES])) {
      return <div data-testid={`${testIdPrefix}modal-no-permissions`}></div>;
    }

    const handleSubmit = async ({ scopeName, tokenTypes, isPublic }) => {
      const results = await Promise.allSettled(
        tokenTypes.map((tokenType) =>
          addClientScope(clientId, scopeName, tokenType, isPublic, agent)
        )
      );

      const errors = parseErrors(results);
      if (errors.length > 0) {
        return { status: "Failure", error: errors };
      }
      return { status: "Success" };
    };

    const FilteredTokenTypeSelectField = () => {
      const {
        values: { scopeName, isPublic },
      } = useFormikContext();

      const isTokenDisabled = (token) =>
        clientScopes.some(
          (scope) =>
            scope.scope_name === scopeName &&
            scope.token_type === token.value &&
            scope.public === isPublic
        );

      return (
        <SelectField
          name="tokenTypes"
          label="Token Types"
          isMulti
          options={allTokenTypes}
          isOptionDisabled={isTokenDisabled}
        />
      );
    };

    return (
      <FormModal
        actionIcon={<SettingsIcon size="1rem" />}
        actionText="Save"
        formProps={{
          initialValues: {
            scopeName: "",
            tokenTypes: [],
            isPublic: false,
          },
          validationSchema: () =>
            Yup.object().shape({
              scopeName: Yup.string().required(),
              tokenTypes: Yup.array().of(Yup.string()).min(1),
            }),
        }}
        onSubmitActionSuccess={onSuccess}
        submitAction={handleSubmit}
        testIdPrefix={testIdPrefix}
        titleText="Add Client Scope"
      >
        <div
          css={`
            height: 100%;
            display: flex;
            flex-direction: column;
          `}
        >
          <SelectField
            name="scopeName"
            label="Scope Name"
            options={sortedScopes}
            maxMenuHeight={200}
            isOptionDisabled={R.prop("disabled")}
          />
          <FilteredTokenTypeSelectField />
          <ToggleField name="isPublic" onLabel="Public" offLabel="Public" />
        </div>
      </FormModal>
    );
  };

  const AddClientScopesButton = ({ label, ...props }) => {
    if (!agent.hasPermissions([WRITE_SCOPES])) {
      return null;
    }
    return (
      <OutlineButton data-testid={`${testIdPrefix}button`} {...props} onClick={openModal}>
        {label}
      </OutlineButton>
    );
  };
  return [AddClientScopesModal, AddClientScopesButton];
};
