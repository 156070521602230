import { ButtonGroup } from "@chakra-ui/react";
import CancelButton from "./CancelButton";
import DeleteButton from "./DeleteButton";

function DeleteCancelButtonGroup({ onCancel, ...props }) {
  return (
    <ButtonGroup spacing={3} {...props}>
      <CancelButton onClick={onCancel} />
      <DeleteButton />
    </ButtonGroup>
  );
}

export default DeleteCancelButtonGroup;
