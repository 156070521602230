import { useMemo } from "react";
import { Form, Formik } from "formik";
import {
  Box,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
} from "@chakra-ui/react";
import { CloseButton, FormError } from "components/Forms";
import { LoadingIcon } from "../Icons";

function FormModal({
  formikProps,
  isOpen,
  onClose,
  title,
  size = "xl",
  isLoading = false,
  actions,
  children,
  resetOnOpen = false,
}) {
  const { initialValues, enableReinitialize, ...restFormikProps } = formikProps;

  const finalEnableReinitialize = resetOnOpen ? true : enableReinitialize;
  const finalInitialValues = useMemo(() => {
    if (!resetOnOpen || isOpen) return initialValues;
    return {};
  }, [isOpen, initialValues, resetOnOpen]);

  if (!onClose && !actions) {
    throw new Error("One of `onClose` or `actions` is required");
  }

  return (
    <Formik
      {...restFormikProps}
      enableReinitialize={finalEnableReinitialize}
      initialValues={finalInitialValues}
    >
      <ChakraModal isCentered size={size} isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <Form>
          <ModalContent>
            <ModalHeader>
              {title}
              {isLoading && (
                <Box as="span" ml={3}>
                  <LoadingIcon />
                </Box>
              )}
              <FormError />
            </ModalHeader>
            {onClose && <ModalCloseButton />}
            <ModalBody>{children}</ModalBody>
            <ModalFooter gap={3}>{actions || <CloseButton onClick={onClose} />}</ModalFooter>
          </ModalContent>
        </Form>
      </ChakraModal>
    </Formik>
  );
}

export default FormModal;
