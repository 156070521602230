import { debug } from "utils";
import { METHODS, mapProxyFetch, serviceMap } from "../utils";
import buildTiliaApiResponse from "../buildTiliaApiResponse";

async function deleteAccountTag({ tagId, agent }) {
  const accountsProxy = mapProxyFetch(serviceMap.ACCOUNTS_API);
  debug(agent, "[useDeleteAccountTag]", { tagId });
  const response = await accountsProxy(agent, `/v1/tag/${tagId}`, METHODS.DELETE);
  const apiResponse = await buildTiliaApiResponse(response);
  if (apiResponse.status !== 200) throw apiResponse.errors;
  return apiResponse.payload;
}

export default deleteAccountTag;
