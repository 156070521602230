import { InvoicingApiInvoiceType } from "../../invoicingApi/index.js";

/**
 * Escrow purchase transaction status codes.
 * @enum {string}
 */
export const EscrowPurchaseStatusCode = Object.freeze({
  OPEN: "OPEN",
  IN_ESCROW: "IN_ESCROW",
  ESCROW_FAILED: "ESCROW_FAILED",
  COMMIT_REQUESTED: "COMMIT_REQUESTED",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  CANCEL_REQUESTED: "CANCEL_REQUESTED",
  CANCELED: "CANCELED",
  CANCEL_FAILED: "CANCEL_FAILED",
  SYSTEM_CANCEL_REQUESTED: "SYSTEM_CANCEL_REQUESTED",
  SYSTEM_CANCELED: "SYSTEM_CANCELED",
  SYSTEM_CANCEL_FAILED: "SYSTEM_CANCEL_FAILED",
  CANCEL_INVOICE_TRANSFER_FAILED: "CANCEL_INVOICE_TRANSFER_FAILED",
});

/**
 * Escrow Purchase transaction invoice types.
 * @type {Set<InvoicingApiInvoiceType>}
 */
export const EscrowPurchaseInvoiceTypes = new Set([
  InvoicingApiInvoiceType.EscrowIT,
  InvoicingApiInvoiceType.EscrowVirtualIT,
  InvoicingApiInvoiceType.CommitEscrowIT,
  InvoicingApiInvoiceType.CommitEscrowVirtualIT,
  InvoicingApiInvoiceType.CancelEscrowIT,
  InvoicingApiInvoiceType.CancelEscrowVirtualIT,
]);
