/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import React from "react";
import { Box, Heading, Table, Tbody, Td, Tfoot, Tr } from "@chakra-ui/react";
import { Money } from "components/Money";

/**
 * @param {SettlementSummary} summary
 * @param {string} currencyCode
 * @param {SettlementAdjustment[]} adjustments
 * @returns {JSX.Element}
 */
function SettlementAdjustmentsSummary({ summary, currencyCode, adjustments }) {
  if (adjustments.length < 1) {
    return null;
  }
  return (
    <Box>
      <Heading as="h4" size="sm" mb="2">
        Adjustments
      </Heading>
      <Table size="sm">
        <Tbody>
          {adjustments.map((adjustment) => (
            <Tr key={adjustment.settlementAdjustmentId}>
              <Td>{adjustment.adjustmentDescription}</Td>
              <Td isNumeric>
                <Money value={adjustment.amount} currency={adjustment.currencyCode} />
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td>Total Adjustments</Td>
            <Td isNumeric>
              <Money value={summary.adjustmentsTotal} currency={currencyCode} />
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  );
}

export default SettlementAdjustmentsSummary;
