import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  StackItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { CopyableText } from "components/Copyable";
import { DetailText } from "components/Text";

function TokenPurchaseIDs({ invoiceId }) {
  const gray600 = useColorModeValue("gray.600", "gray.200");

  return (
    <Card>
      <CardHeader>
        <Heading m={0} size="xs" color={gray600} as="h4">
          Token Purchase IDs
        </Heading>
      </CardHeader>
      <CardBody>
        <Stack>
          <StackItem as="dl" margin={0}>
            <Text as="dt">Invoice ID</Text>
            <DetailText>
              <CopyableText text={invoiceId}>{invoiceId}</CopyableText>
            </DetailText>
          </StackItem>
        </Stack>
      </CardBody>
    </Card>
  );
}

export default TokenPurchaseIDs;
