import React from "react";
import * as R from "ramda";
import * as Yup from "yup";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useAgent } from "context/agentContext";
import * as permissions from "context/permissions";
import { noop } from "utils";
import { useFormModal } from "components/Modal";
import { SelectField } from "components/Forms";
import { OutlineButton } from "components/Button";
import { PermissionsIcon } from "components/Icons";
import { useAccount } from "context/accountContext";

export const useAddUserToGroupModal = () => {
  const {
    permissions: { addAccountPermission },
  } = useAccount();

  const testIdPrefix = "add-user-permission-";
  const { agent } = useAgent();
  const [FormModal, openModal] = useFormModal();

  const AddModal = ({ onSuccess = noop, existingGroups = [], allGroups = [], accountId }) => {
    if (!agent.hasPermissions([permissions.WRITE_PERMISSIONS]) || agent.isOwnAccount(accountId)) {
      return <div data-testid={`${testIdPrefix}modal-no-permissions`}></div>;
    }
    const groupOptions = R.map((g) => ({
      value: g,
      label: g,
      disabled: R.includes(g, existingGroups),
    }))(allGroups);

    return (
      <FormModal
        actionIcon={<PermissionsIcon size="1rem" />}
        actionText="Add"
        onSubmitActionSuccess={onSuccess}
        submitAction={({ group }) => addAccountPermission(accountId, group)}
        testIdPrefix={testIdPrefix}
        titleText="Add User To Group"
        formProps={{
          initialValues: {
            group: "",
          },
          validationSchema: () =>
            Yup.object().shape({
              group: Yup.string().required(),
            }),
        }}
      >
        <div
          css={`
            min-height: 285px;
          `}
        >
          <SelectField
            name="group"
            label="Group"
            required
            options={groupOptions}
            maxMenuHeight={200}
            isOptionDisabled={R.prop("disabled")}
          />
        </div>
      </FormModal>
    );
  };

  const AddModalButton = ({ accountId, ...props }) => {
    if (!agent.hasPermissions([permissions.WRITE_PERMISSIONS]) || agent.isOwnAccount(accountId)) {
      return null;
    }
    return (
      <OutlineButton data-testid={`${testIdPrefix}button`} {...props} onClick={openModal}>
        Add
      </OutlineButton>
    );
  };
  return [AddModal, AddModalButton];
};
