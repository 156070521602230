import { css } from "styled-components";
import { theme } from "styles";

export const row = css`
  align-items: center;
  border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
  display: grid;
  overflow-wrap: anywhere;
  grid-template-columns: 1fr 1fr 1fr;
  > div {
    padding-left: 5px;
    padding-right: 5px;
  }
`;
