import { AccountLink } from "components/Account";
import { Money } from "components/Money";
import React from "react";
import styled from "styled-components/macro";
import { DateAndTime } from "components/DataRow";
import { RowCheckbox } from "components/Grid";
import { FIELDS, STATUS_VALUE_LABEL_MAP } from "../constants";
import { row } from "./common";
import GridActions from "./GridActions";
import { getRealNameFromKYCPII } from "utils/format";
import { Link } from "components/Link";

const Container = styled.div`
  ${row};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const GridRow = ({ payout }) => {
  const dpm = payout.credit?.destination_payment_method || {};
  return (
    <Container data-testid="payout-row">
      {[
        <RowCheckbox data-testid={`${payout.account_id}-checkbox`} record={payout} />,
        <div>
          <DateAndTime value={payout[FIELDS.created]} />
          <Link to={`/transaction/${payout.payout_status_id}`}>{payout.payout_status_id}</Link>
        </div>,
        <span>{STATUS_VALUE_LABEL_MAP[payout.status]}</span>,
        <span>
          <div>{getRealNameFromKYCPII(payout?.account?.kyc?.kyc_pii)}</div>
          <AccountLink account={payout}>
            {payout.account?.username || payout.account_id}
          </AccountLink>
        </span>,
        <span>{payout?.integrator}</span>,
        <span>
          {dpm?.full_name && <div>{dpm?.full_name}</div>}
          {dpm?.method_class && <div>{dpm?.method_class}</div>}
          {dpm?.psp_provided_email && <div>{dpm?.psp_provided_email}</div>}
        </span>,
        <Money value={payout.credit.amount} currency={payout.credit.currency} />,
        <GridActions payout={payout} />,
      ].map((item, index) => (
        <div key={`${payout[FIELDS.account_id]}-${index}`}>{item}</div>
      ))}
    </Container>
  );
};

export default GridRow;
