import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { theme } from "../../styles";
import { useLocation } from "react-router-dom";
import { AppTitle } from "./AppTitle";
import useAgentIsTiliaAgent from "context/useAgentIsTiliaAgent";
import TiliaAgentNavItems from "./TiliaAgentNavItems";
import PublisherAgentNavItems from "./PublisherAgentNavItems";

const NavContainer = styled.ul`
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: ${theme("colors.darkBg")};
  color: ${theme("colors.fontInverse")};
  list-style: none;
  margin: 0;
  left: 0;
  padding: 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

const NavWrapper = styled.div`
  position: absolute;
  top: ${theme("pageHeaderHeight")};
  left: 0;
  background-color: ${theme("colors.darkBg")};
  width: 100%;
`;

export const TopNav = () => {
  const isTiliaAgent = useAgentIsTiliaAgent();
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  // close the menu anytime the route changes
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  const handleMenuClick = () => {
    setMenuOpen((menuOpen) => {
      return !menuOpen;
    });
  };

  return (
    <NavContainer>
      <AppTitle showMenu={true} onMenuClick={handleMenuClick} menuOpen={menuOpen} />
      {menuOpen && (
        <NavWrapper data-testid="nav-wrapper">
          {isTiliaAgent ? <TiliaAgentNavItems /> : <PublisherAgentNavItems />}
        </NavWrapper>
      )}
    </NavContainer>
  );
};
