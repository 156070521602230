import React, { useEffect, useRef } from "react";
import { Flex, Button } from "@chakra-ui/react";
import { useAgent } from "context/agentContext";
import { useAgentTimeZone } from "utils/useAgentTimeZone";
import { ButtonAnchor } from "components/Link";
import { useFilter } from "components/Filter/FilterContext";
import { useAccount } from "context/accountContext";
import { exists, formatDateTimeFilename, isNilOrEmpty } from "utils";

import useFeatureFlag from "utils/useFeatureFlag";
import { downloadTransactions } from "api/toolsWeb";
import { useQuery } from "react-query";
import { DownloadIcon } from "components/Icons";
import { CSVLink } from "react-csv";

const ClientDownload = ({ fileName }) => {
  const { agent } = useAgent();
  const timeZone = useAgentTimeZone();
  const { accountId } = useAccount();
  const { gqlFilterParams } = useFilter();
  const csvLinkRef = useRef();

  const { data, refetch, isLoading } = useQuery(
    [accountId, "th", "download"],
    () =>
      downloadTransactions(
        {
          accountId,
          fileName,
          gqlFilterParams,
          timeZone,
        },
        agent
      ),
    { enabled: false }
  );
  useEffect(() => {
    if (exists(data)) {
      csvLinkRef.current.link.click();
    }
  }, [data]);

  return (
    <Button
      rightIcon={<DownloadIcon />}
      size="sm"
      variant="outline"
      isLoading={isLoading}
      isDisabled={isLoading}
      onClick={refetch}
      mr={2}
    >
      DOWNLOAD
      <CSVLink data={data || []} asyncOnClick={true} ref={csvLinkRef} filename={fileName} />
    </Button>
  );
};

export default function Download() {
  const showClientDownload = useFeatureFlag("client_th_download");
  const {
    agent: { jwt },
  } = useAgent();

  const { gqlFilterParams } = useFilter();
  const timeZone = useAgentTimeZone();
  const { accountId } = useAccount();
  const fileName = `${accountId}-${formatDateTimeFilename(new Date())}.csv`;
  const url = `/ui/api/transactions/download/${encodeURIComponent(accountId)}/${encodeURIComponent(
    fileName
  )}?timeZone=${encodeURIComponent(timeZone)}${
    !isNilOrEmpty(gqlFilterParams)
      ? `&filter=${encodeURIComponent(JSON.stringify(gqlFilterParams))}`
      : ""
  }&token=${encodeURIComponent(jwt)}`;

  return (
    <Flex alignItems="center">
      {showClientDownload ? (
        <ClientDownload fileName={fileName} />
      ) : (
        <ButtonAnchor href={url} data-testid="trans-hist-csv-download">
          Download
        </ButtonAnchor>
      )}
    </Flex>
  );
}
