import { Text } from "@chakra-ui/react";
import {
  Section,
  TransactionColumnLeft,
  TransactionColumnRight,
  TransactionLayout,
  TransactionMain,
} from "components/Transaction/Layout";
import Tags from "../Tags";
import BalanceTransferIDs from "./BalanceTransferIDs";
import { DetailText } from "../../Text";
import BalanceTransferDetailCard from "./BalanceTransferDetailCard";
import BalanceTransferItems from "./BalanceTransferItems";

function BalanceTransferDetail({ balanceTransfer }) {
  return (
    <TransactionLayout>
      <TransactionColumnLeft>
        <Tags tags={balanceTransfer.tags} />
        <Section title="Transfer Detail">
          <Text mt="0">
            <DetailText>{balanceTransfer.integrator}</DetailText>
          </Text>
          <BalanceTransferDetailCard balanceTransfer={balanceTransfer} />
        </Section>
      </TransactionColumnLeft>
      <TransactionMain>
        <Section title="Summary">
          <BalanceTransferItems subItems={balanceTransfer.subItems} />
        </Section>
        <Section title="Balance Transfer Details">
          <BalanceTransferIDs invoiceId={balanceTransfer.invoiceId} />
        </Section>
      </TransactionMain>
      <TransactionColumnRight />
    </TransactionLayout>
  );
}

export default BalanceTransferDetail;
