import { PrimaryButton } from "components/Button";
import { format } from "date-fns-tz";
import { forwardRef, useCallback, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import { theme } from "styles";
import { useOutsideClick } from "utils/useOutsideClick";
import FilterSelect from "./FilterSelect";
import { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars

const options = [
  { value: "today", label: "Today" },
  { value: "last7days", label: "Last 7 Days" },
  { value: "last30days", label: "Last 30 Days" },
  { value: "thismonth", label: "This Month" },
  { value: "lastmonth", label: "Last Month" },
  { value: "thisyear", label: "This Year" },
  { value: "lastyear", label: "Last Year" },
  { value: "on", label: "On" },
  { value: "between", label: "Between" },
  { value: "after", label: "After" },
  { value: "before", label: "Before" },
];

const pattern = "yyyy-MM-dd";

const DatePickerPanel = forwardRef(({ pickerMode, onChange }, ref) => {
  const [selected, setSelected] = useState(null);

  const handleSelect = (value) => {
    if (pickerMode === "on") {
      onChange(format(value, pattern));
    } else if (pickerMode === "between") {
      setSelected(value);
    } else {
      onChange(`${pickerMode}:${format(value, pattern)}`);
    }
  };

  const handleRangeDone = () => {
    if (selected?.from && selected?.to) {
      onChange(`between:${format(selected.from, pattern)}|${format(selected.to, pattern)}`);
    }
  };

  let footer;
  switch (pickerMode) {
    case "on":
      footer = <p>Select date…</p>;
      break;
    case "after":
      footer = <p>Select after date…</p>;
      break;
    case "before":
      footer = <p>Select before date…</p>;
      break;
    default:
      footer = (
        <p>
          Select date range…
          <PrimaryButton onClick={handleRangeDone}>Done</PrimaryButton>
        </p>
      );
  }

  return (
    <div
      ref={ref}
      css={`
        background: ${theme("colors.menu.list.bg")};
        border: 1px solid ${theme("colors.forms.border")};
        position: absolute;
        top: 1.8em;
        z-index: 100;
      `}
    >
      <DayPicker
        mode={pickerMode === "between" ? "range" : "single"}
        selected={selected}
        onSelect={handleSelect}
        footer={footer}
      />
    </div>
  );
});

export const getDateLabel = (date) => {
  let label;
  if (date.substring(0, 8) === "between:") {
    label = `Between ${date.substring(8, 18)} and ${date.substring(19)}`;
  } else if (date.substring(0, 7) === "before:") {
    label = `Before ${date.substring(7)}`;
  } else if (date.substring(0, 6) === "after:") {
    label = `After ${date.substring(6)}`;
  } else {
    const dateOption = options.find((option) => {
      return option.value === date;
    });
    label = dateOption?.label || date;
  }
  return label;
};

export const DateFilter = ({
  selectedOptions = [],
  onAddDate,
  value = undefined,
  optionExclusions = [],
}) => {
  const [pickerMode, setPickerMode] = useState(null);
  let availableOptions = options;
  if (optionExclusions.length > 0) {
    availableOptions = availableOptions.filter(
      (option) => !optionExclusions.includes(option.value)
    );
  }
  const ref = useRef();
  useOutsideClick({
    ref,
    handler: () => setPickerMode(null),
  });

  const handleChange = (_, date) => {
    switch (date) {
      case "on":
      case "between":
      case "after":
      case "before":
        setPickerMode(date);
        break;
      default:
        setPickerMode(null);
        onAddDate(date);
    }
  };

  const handlePickerChange = useCallback(
    (date) => {
      setPickerMode(null);
      onAddDate(date);
    },
    [onAddDate]
  );

  return (
    <div
      css={`
        position: relative;
      `}
    >
      <FilterSelect
        data-testid="select-date"
        label="Filter by Date"
        name="date"
        placeholder={value ? getDateLabel(value) : "Select..."}
        options={availableOptions}
        selectedOptions={selectedOptions}
        value={value}
        onChange={handleChange}
      />
      {pickerMode && (
        <DatePickerPanel ref={ref} pickerMode={pickerMode} onChange={handlePickerChange} />
      )}
    </div>
  );
};
