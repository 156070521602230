import { Wrap } from "@chakra-ui/react";
import AccountBlockTag from "./AccountBlockTag";
import useAccountBlocks from "./useAccountBlocks";

const AccountBlockTags = ({ accountId }) => {
  const [blocks] = useAccountBlocks(accountId);

  if (blocks.length < 1) {
    return null;
  }
  return (
    <Wrap>
      {blocks.map((block) => (
        <AccountBlockTag key={block.id} accountBlock={block} />
      ))}
    </Wrap>
  );
};

export default AccountBlockTags;
