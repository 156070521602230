import { Box, Flex } from "@chakra-ui/react";
import { ExternalLink } from "components/Link";
import { exists } from "utils";

// TODO: clean this up
// for now, this has to handle two forms of address:
// one with the older state and zip
// one with the newer region and postalCode
export const Address = ({ address = {} }) => {
  const region = address.region || address.state;
  const postalCode = address.postalCode || address.zip;
  const cityWithPunctuation = exists(address.city) ? `${address.city}, ` : "";
  return (
    <Box>
      <Box>{address.street}</Box>
      {address.street2 && <Box>{address.street2}</Box>}
      <Box>
        {cityWithPunctuation}
        {region} {postalCode}
      </Box>
      <Box>{address.country}</Box>
    </Box>
  );
};

const encodeAddress = (pii = {}) =>
  encodeURI(
    `${pii.street},${pii.city},${pii.region || pii.state},${pii.country},${
      pii.postalCode || pii.zip
    }`
  );

const MaybeMapLinkDataRow = ({ data }) => {
  if (!data?.street) return null;
  const mapSearch = `https://www.google.com/maps/search/?api=1&query=${encodeAddress(data)}`;
  return (
    <ExternalLink newTab href={mapSearch} display="inline-block">
      Map
    </ExternalLink>
  );
};

export const AddressTitleWithMapLink = ({ address = {} }) => {
  return (
    <Flex>
      <Box as="span" marginRight="3">
        Address
      </Box>
      <MaybeMapLinkDataRow data={address} />
    </Flex>
  );
};
