import React from "react";
import { READ_GDPR_DOCUMENTS, GDPR_RESOURCE, withPermissions } from "context/permissions";
import { Panel } from "components/Panel";
import { GDPRDelete } from "./Delete";
import { GDPRDataAccess } from "./DataAccess";
import { PermissionsMessage } from "components/PermissionsMessage";
import { useTitle } from "context/titleContext";

export const GDPR = withPermissions(
  {
    permissions: [READ_GDPR_DOCUMENTS],
    resource: GDPR_RESOURCE,
  },
  ({ permissions }) => {
    useTitle({ sub1: "GDPR" });
    if (!permissions.hasPermission) {
      return <PermissionsMessage />;
    }

    return (
      <Panel>
        <GDPRDelete />
        <GDPRDataAccess />
      </Panel>
    );
  }
);
