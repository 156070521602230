import React from "react";
import { ActionMenu } from "components/ActionMenu";
import { useRefundModal, transactionIsRefundable } from "components/Refund";

export const Keys = {
  REFUND: "REFUND",
};

export const TransactionHistoryActionMenu = ({
  detailsData = { transaction: {} },
  menuSettings,
  refetch,
}) => {
  const [RefundModal, getRefundMenuItems] = useRefundModal();
  const isRefundable = transactionIsRefundable(detailsData.transaction);

  const menuItems = [
    {
      key: Keys.REFUND,
      item: getRefundMenuItems(isRefundable),
      comp: (
        <RefundModal
          key={Keys.REFUND}
          transaction={detailsData.transaction}
          currency={detailsData.currency}
          onSuccess={detailsData.onSuccess}
          refetch={refetch}
        />
      ),
    },
  ];
  return <ActionMenu menuItems={menuItems} menuSettings={menuSettings} />;
};
