import { datadogRum } from "@datadog/browser-rum";
import { ENVIRONMENT_NAMES, getEnvironment } from "../../utils";

if (process.env.NODE_ENV === "production") {
  datadogRum.init({
    applicationId: "bfd6fd74-de31-4a65-b225-03f6466c0c68",
    clientToken: "pub206f6149fd0c8e8a731c610a0880bb3c",
    site: "datadoghq.com",
    service: "tools-web",
    env: ENVIRONMENT_NAMES[getEnvironment()],
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}
