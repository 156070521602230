import { Avatar } from "@chakra-ui/react";

function AccountAvatar({ account, size = "sm", ...props }) {
  let title = account.accountId;
  if (account.fullName) {
    title = account.fullName;
  } else if (account.lastName) {
    title = `${account.firstName || ""} ${account.lastName}`.trim();
  } else if (account.email) {
    title = account.email;
  } else if (account.username) {
    title = account.username;
  }
  return <Avatar name={title} title={title} size={size} {...props} />;
}

export default AccountAvatar;
