import React, { useCallback } from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import * as R from "ramda";
import * as Yup from "yup";
import { getWatchlist } from "api/toolsWeb";
import { toJSONB64, fromB64JSON, isNilOrEmpty } from "utils";
import { queryWatchlist } from "api/fraud";
import { useAgent } from "context/agentContext";
import { useNavigate } from "react-router-dom";
import { Spinner } from "components/Spinner";
import { Formik } from "formik";

import { TextField, SelectField, Error } from "components/Forms";
import { PrimaryButton } from "components/Button";
import { WatchlistEntities } from "components/Watchlist";

const ENTITY = "ENTITY";
const USER = "INDIVIDUAL";

const queryTypeOptions = [
  { value: USER, label: "User" },
  { value: ENTITY, label: "Entity" },
];

const cleanDefault = R.defaultTo("");

export const WatchlistQuery = () => {
  const { agent } = useAgent();
  const navigate = useNavigate();
  const search = new URLSearchParams(document.location.search);
  const qs = fromB64JSON(search.get("qs"));

  const [results, setResults] = React.useState({});
  const [entity_type, setEntityType] = React.useState(cleanDefault(qs.entity_type));
  const [error, setError] = React.useState("");
  const [sanctionListOptions, setSanctionListOptions] = React.useState([]);

  const initialValues = {
    sanction_list: cleanDefault(qs.sanction_list || "ALL"),
    entity_type: cleanDefault(qs.entity_type),
    entity_name: cleanDefault(qs.entity_name),
    dba: cleanDefault(qs.dba),
    first: cleanDefault(qs.first),
    last: cleanDefault(qs.last),
    street: cleanDefault(qs.street),
    city: cleanDefault(qs.city),
    state: cleanDefault(qs.state),
    country: cleanDefault(qs.country),
    zip: cleanDefault(qs.zip),
  };

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setError("");
      setLoading(true);
      const payload = {
        sanction_list: values.sanction_list,
        entity_type: values.entity_type,
        entity_name: values.entity_name,
        dba: values.dba,
        first: values.first,
        last: values.last,
        street: values.street,
        city: values.city,
        state: values.state,
        country: values.country,
        zip: values.zip,
      };
      try {
        const resp = await queryWatchlist(payload, agent);
        // small delay to ensure the UI shows that a query is executing
        setTimeout(() => setResults(resp.payload), 1000);
      } catch (e) {
        setError("There was an issue with that query, please try again.");
      } finally {
        setLoading(false);
      }
      navigate({ pathname: "/watchlist/query", search: "qs=" + toJSONB64(payload) });
    },
    [agent, navigate]
  );

  const getWatchlists = useCallback(async () => {
    try {
      const watchLists = await getWatchlist();
      const defineSanctionListOptions = watchLists?.sanction_lists.map((sl) => ({
        value: sl,
        label: sl,
      }));
      setSanctionListOptions(defineSanctionListOptions);
    } catch {
      setError("There was an issue loading saction lists.");
    }
  }, [setSanctionListOptions]);

  const mount = useCallback(async () => {
    //if at least one field is populated, then issue a query on component mount
    const data = {
      sanction_list: cleanDefault(qs.sanction_list),
      entity_type: cleanDefault(qs.entity_type),
      entity_name: cleanDefault(qs.entity_name),
      dba: cleanDefault(qs.dba),
      first: cleanDefault(qs.first),
      last: cleanDefault(qs.last),
      street: cleanDefault(qs.street),
      city: cleanDefault(qs.city),
      state: cleanDefault(qs.state),
      country: cleanDefault(qs.country),
      zip: cleanDefault(qs.zip),
    };
    const populated = Object.values(data).some((ele) => ele !== "");
    if (populated) {
      await handleSubmit(data);
    }
  }, []); //eslint-disable-line

  React.useEffect(() => {
    mount();
    getWatchlists();
    return () => {
      setSanctionListOptions([]);
    };
  }, [getWatchlists, mount]);

  const addressInfo = (
    <>
      <TextField label="Street" name="street" />
      <TextField label="City" name="city" />
      <TextField label="State" name="state" />
      <TextField label="Country" name="country" />
      <TextField label="Zip Code" name="zip" />
      <Error error={error} touched />
      <PrimaryButton data-testid="query-button" type="submit" disabled={loading}>
        Query
      </PrimaryButton>
    </>
  );

  let piiSearchInputs;

  switch (entity_type) {
    case ENTITY:
      piiSearchInputs = (
        <>
          <SelectField name="sanction_list" label="Sanction List" options={sanctionListOptions} />
          <TextField label="Business Name" name="entity_name" />
          <TextField label="DBA" name="dba" />
          {addressInfo}
        </>
      );
      break;
    case USER:
      piiSearchInputs = (
        <>
          <SelectField name="sanction_list" label="Sanction List" options={sanctionListOptions} />
          <TextField label="First Name" name="first" />
          <TextField label="Last Name" name="last" />
          {addressInfo}
        </>
      );
      break;
    default:
      piiSearchInputs = <> </>;
  }

  // this function resets the values of these fields if the
  // entity value is toggled. There is probably a better way of handling
  // this but this works for the end of year deadline. Please judge me
  // kindly from the future.
  const updateOnType = (setValueField) => (type) => {
    if (type === "ENTITY") {
      setValueField("first", "");
      setValueField("last", "");
    } else {
      setValueField("entity_name", "");
      setValueField("dba", "");
    }
    setEntityType(type);
  };

  const queryTypeLabel = isNilOrEmpty(entity_type) ? "Select a Query Type" : "Query Type";

  return (
    <div data-testid="watchlist-query">
      <div
        id="query"
        css={`
          display: grid;
          grid-template-columns: 1fr 2fr;
          grid-gap: 1.5em;
        `}
      >
        <Formik
          onSubmit={handleSubmit}
          validationSchema={() =>
            Yup.object().shape({
              entity_type: Yup.string().required("Query Type is required."),
              sanction_list: Yup.string().required("Sanction List is required."),
            })
          }
          initialValues={initialValues}
        >
          {(props) => (
            <form onReset={props.handleReset} onSubmit={props.handleSubmit}>
              <SelectField
                label={queryTypeLabel}
                name="entity_type"
                options={queryTypeOptions}
                optionalUpdates={updateOnType(props.setFieldValue)}
              />
              {piiSearchInputs}
            </form>
          )}
        </Formik>

        <div id="results">
          {loading ? (
            <div
              css={`
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
              `}
            >
              <Spinner size={4} />
            </div>
          ) : (
            <WatchlistEntities
              name="query"
              query={qs}
              entities={results.entity_map}
              autoselectFirst
            />
          )}
        </div>
      </div>
    </div>
  );
};
