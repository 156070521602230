import { InvoicingApiInvoiceType } from "../../invoicingApi/index.js";

/**
 * Balance transfer status codes.
 * @enum {string}
 */
export const BalanceTransferStatusCode = Object.freeze({
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
});

/**
 * Balance Transfer transaction invoice types.
 * @type {Set<InvoicingApiInvoiceType>}
 */
export const BalanceTransferInvoiceTypes = new Set([InvoicingApiInvoiceType.BalanceTransferIT]);

/**
 * Currently-known balance transfer types. NOTE: Balance transfer configuration is NOT codified!
 * It is managed via settings, and is subject to change at any time. Consequently, these types and
 * constants are best-effort only.
 * @enum {string}
 */
export const BalanceTransferType = Object.freeze({
  CLAWBACK: "clawback",
  DEPOSIT: "deposit",
  FACILITATOR_CURRENCY_SWAP: "facilitator_currency_swap",
  FUND_INTEGRATOR: "fund_integrator",
  FUND_TILIA: "fund_tilia",
  FUND_USER: "fund_user",
  MERGE_ACCOUNT: "merge_account",
  SETTLEMENT: "settlement",
  TOKEN_CONVERT_PAYOUT_REVERSAL: "token_convert_payout_reversal",
});

/**
 * Currently-known balance transfer item types. NOTE: Balance transfer configuration is NOT codified!
 * It is managed via settings, and is subject to change at any time. Consequently, these types and
 * constants are best-effort only.
 * @enum {string}
 */
export const BalanceTransferItemType = Object.freeze({
  CLAWBACK_FROM_INTEGRATOR: "clawback_from_integrator",
  CLAWBACK_FROM_RECIPIENT: "clawback_from_recipient",
  DEPOSIT: "deposit",
  FACILITATOR_CURRENCY_SWAP_SINK_FIAT: "facilitator_currency_swap_sink_fiat",
  FACILITATOR_CURRENCY_SWAP_SINK_USD: "facilitator_currency_swap_sink_usd",
  FACILITATOR_CURRENCY_SWAP_SINK_VIRTUAL: "facilitator_currency_swap_sink_virtual",
  FACILITATOR_CURRENCY_SWAP_SOURCE_FIAT: "facilitator_currency_swap_source_fiat",
  FACILITATOR_CURRENCY_SWAP_SOURCE_USD: "facilitator_currency_swap_source_usd",
  FACILITATOR_CURRENCY_SWAP_SOURCE_VIRTUAL: "facilitator_currency_swap_source_virtual",
  INTEGRATOR_FACILITATOR_SOURCED: "integrator_facilitator_sourced",
  INTEGRATOR_REVENUE_SOURCED: "integrator_revenue_sourced",
  INTEGRATOR_SOURCED_TO_TILIA: "integrator_sourced_to_tilia",
  INTER_ACCOUNT: "inter_account",
  INTRA_ACCOUNT: "intra_account",
  MERGE_ACCOUNT_CONVERTIBLE_CONVERTIBLE_TRANSFER: "merge_account_convertible_convertible_transfer",
  MERGE_ACCOUNT_STANDARD_STANDARD_TRANSFER: "merge_account_standard_standard_transfer",
  PUBLISHER_SETTLEMENT_CREDIT: "publisher_settlement_credit",
  PUBLISHER_SETTLEMENT_DEBIT: "publisher_settlement_debit",
  PUBLISHER_SOURCED: "publisher_sourced",
  SETTLEMENT_RECONCILIATION: "settlement_reconciliation",
  TILIA_SOURCED: "tilia_sourced",
  TOKEN_CONVERT_INTEGRATOR_FEE_REVERSAL: "token_convert_integrator_fee_reversal",
  TOKEN_CONVERT_TILIA_FEE_REVERSAL: "token_convert_tilia_fee_reversal",
  TOKEN_CONVERT_USD_CREDIT_REVERSAL: "token_convert_usd_credit_reversal",
  TOKEN_CONVERT_VIRTUAL_DEBIT_REVERSAL: "token_convert_virtual_debit_reversal",
  UNEARNED_TOKENS_CREATED: "unearned_tokens_created",
  USER_SOURCED_TO_INTEGRATOR: "user_sourced_to_integrator",
  USER_SOURCED_TO_TILIA: "user_sourced_to_tilia",
});
