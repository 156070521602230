import React from "react";
import * as R from "ramda";
import "styled-components/macro";
import { useAgent } from "context/agentContext";
import { useGetProductTaxCodes } from "./productHooks";
import { useCreateEditProductModal } from "./useCreateEditProductModal";
import { useDeleteModal } from "./useDeleteModal";
import * as Permissions from "context/permissions";
import { PageContent } from "components/Page";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import { Table, Thead, Th, Tbody, Tr, Td } from "components/Table";
import { PermissionsMessage } from "components/PermissionsMessage";
import { FullPageSpinner } from "components/Spinner";
import { useAppConfig, appConfigKeys } from "context/appConfigContext";
import { exists } from "utils";
import { ButtonGroup } from "@chakra-ui/react";
import { useTitle } from "context/titleContext";

const PermissionsRender = ({ agent, perms, children }) => {
  return agent.hasPermissions(perms) && children;
};

const DisplayTaxInfomation = ({ product }) => {
  const productTaxCodes = useAppConfig(appConfigKeys.PRODUCT_TAX_CODES) || {};
  const displayCodeDescription = (code) => productTaxCodes[code]?.product_description ?? "None";
  return displayCodeDescription(product);
};

const ProductRow = ({ product, integrator, agent }) => {
  const [EditModal, EditModalButton] = useCreateEditProductModal(integrator, {
    edit: true,
    label: "edit",
    product: product,
  });

  const [DeleteModal, DeleteButton] = useDeleteModal(integrator, product);

  return (
    <Tr pull>
      <Td>{product.productCode}</Td>
      <Td>
        <DisplayTaxInfomation product={product.tiliaProduct} />
      </Td>
      <Td>{product.description}</Td>
      <Td rightAlign>
        <PermissionsRender agent={agent} perm={Permissions.WRITE_PRODUCT_CATALOG}>
          <ButtonGroup>
            <EditModalButton />
            <EditModal />
            <DeleteButton />
            <DeleteModal />
          </ButtonGroup>
        </PermissionsRender>
      </Td>
    </Tr>
  );
};

export const ProductCatalog = ({ integrator, withPanelTitle = true }) => {
  const { agent } = useAgent();
  if (!agent.hasPermissions(Permissions.READ_PRODUCT_CATALOG)) {
    return (
      <PageContent>
        <Panel
          css={`
            padding: 8px;
          `}
        >
          <PermissionsMessage />
        </Panel>
      </PageContent>
    );
  }

  return (
    <PageContent title={{ page: "Product Catalog" }} opts={{ clear: true }}>
      <PermissionedProductCatalog integrator={integrator} withPanelTitle={withPanelTitle} />
    </PageContent>
  );
};

const PermissionedProductCatalog = ({ integrator, withPanelTitle }) => {
  const { agent } = useAgent();
  const { data, loading } = useGetProductTaxCodes(integrator);
  useTitle({ sub1: "Product Catalog" });

  let defaultProduct = null;
  if (exists(data?.integrator?.productTaxCodes)) {
    defaultProduct = R.filter((product) => product.isDefault, data.integrator.productTaxCodes)[0];
  }

  const [AddModal, AddModalButton] = useCreateEditProductModal(integrator, {
    edit: false,
    label: "add",
  });

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <Panel>
      {withPanelTitle && (
        <PanelTitle
          css={`
            margin-bottom: 0px;
          `}
        >
          Product Catalog
        </PanelTitle>
      )}

      <div
        css={`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        Default Product Category: <DisplayTaxInfomation product={defaultProduct?.tiliaProduct} />
        <PermissionsRender agent={agent} perms={Permissions.WRITE_PRODUCT_CATALOG}>
          <AddModalButton />
          <AddModal />
        </PermissionsRender>
      </div>
      <PanelContent>
        <Table>
          <Thead bordered>
            <Tr pull>
              <Th>Product Code</Th>
              <Th>Product Category</Th>
              <Th>Description</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody bordered>
            {exists(data?.integrator?.productTaxCodes) ? (
              R.map(
                (productTaxCode) => (
                  <ProductRow
                    agent={agent}
                    key={productTaxCode.productCode}
                    product={productTaxCode}
                    integrator={integrator}
                  />
                ),
                data.integrator.productTaxCodes
              )
            ) : (
              <Tr pull>
                <Td colSpan={4}>No Products Defined</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </PanelContent>
    </Panel>
  );
};
