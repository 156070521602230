export default function FormattedAddress({ data }) {
  if (
    !data ||
    !(data.street || data.street2 || data.city || data.region || data.country || data.postalCode)
  ) {
    return <>-</>;
  } else {
    return (
      <div data-testid="formatted-address">
        <div>{data.street}</div>
        {data.street2 && <div>{data.street2}</div>}
        {data.street3 && <div>{data.street3}</div>}
        {data.city && (
          <div>
            {data.city}, {data.region} {data.country} {data.postalCode}
          </div>
        )}
      </div>
    );
  }
}
