import React from "react";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { LoadingIcon } from "../Icons";
import { theme, Desktop, Mobile } from "../../styles";
import { usePageHeight } from "components/PageHeight";

export const Spinner = (props) => (
  <div data-testid="spinner">
    <LoadingIcon {...props} />
  </div>
);

const common = css`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullPageContainer = styled.div`
  ${common}
  top: 0;
  left: 0;
`;
const DesktopContainer = styled.div`
  ${common}
  ${({ top }) => `top: ${top}px`};
  left: ${theme("sidenavWidth")};
`;

const MobileContainer = styled.div`
  ${common}
  ${({ top }) => `top: ${top}px`};
`;

export const FullPageSpinner = (props) => {
  const { boundingRef } = usePageHeight();
  return (
    <div ref={boundingRef} data-testid="spinner" {...props}>
      <FullPageContainer>
        <LoadingIcon size={4} />
      </FullPageContainer>
    </div>
  );
};
export const SectionSpinner = (props) => {
  const { boundingRef, top } = usePageHeight();

  return (
    <div ref={boundingRef} data-testid="spinner" {...props}>
      <Desktop>
        <DesktopContainer top={top}>
          <LoadingIcon size={4} />
        </DesktopContainer>
      </Desktop>
      <Mobile>
        <MobileContainer top={top}>
          <LoadingIcon size={3} />
        </MobileContainer>
      </Mobile>
    </div>
  );
};
