import { Text } from "@chakra-ui/react";
import { DetailText } from "components/Text";
import AccountCard from "components/Transaction/AccountCard";
import {
  Section,
  TransactionColumnLeft,
  TransactionColumnRight,
  TransactionLayout,
  TransactionMain,
} from "components/Transaction/Layout";
import PaymentMethods from "components/Transaction/UserPurchase/PaymentMethods";
import Recipients from "components/Transaction/UserPurchase/Recipients";
import LineItems from "components/Transaction/UserPurchase/LineItems";
import EscrowIDs from "./EscrowIDs";

function EscrowPurchaseDetail({ escrowPurchase }) {
  return (
    <TransactionLayout>
      <TransactionColumnLeft>
        {/* TODO: Add tags https://tiliajira.atlassian.net/browse/DEV-3135 */}
        {/* <Tags /> */}
        <Section title="Account Detail">
          <Text mt="0">
            <DetailText>{escrowPurchase.integrator}</DetailText>
          </Text>
          <AccountCard accountId={escrowPurchase.account.accountId} />
        </Section>
        <Section title="Payment methods">
          <PaymentMethods transaction={escrowPurchase} />
        </Section>
        <Section title="Recipients">
          <Recipients parties={escrowPurchase.parties} />
        </Section>
      </TransactionColumnLeft>
      <TransactionMain>
        <Section title="Summary">
          <LineItems transaction={escrowPurchase} />
        </Section>
        <Section title="Escrow Details">
          <EscrowIDs
            escrowInvoiceId={escrowPurchase.escrowInvoiceId}
            commitInvoiceId={escrowPurchase.commitInvoiceId}
            cancelInvoiceId={escrowPurchase.cancelInvoiceId}
          />
        </Section>
        {/* TODO <Section title="Notes">
            <Notes />
          </Section> */}
      </TransactionMain>
      <TransactionColumnRight>
        {/* TODO: <Section title="Activity Log">
            <ActivityLog />
          </Section> */}
      </TransactionColumnRight>
    </TransactionLayout>
  );
}

export default EscrowPurchaseDetail;
