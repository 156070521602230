import React from "react";
import * as R from "ramda";
import { PageContent, PageTitle } from "components/Page";
import { DataRow } from "components/DataRow";

export const Info = () => {
  return (
    <PageContent>
      <PageTitle>Info</PageTitle>
      <div>
        {R.pipe(
          R.keys,
          R.sortBy(R.identity),
          R.map(key => <DataRow key={key} title={key} data={process.env[key]} />)
        )(process.env)}
      </div>
    </PageContent>
  );
};
