/**
 * @typedef {Object} StringFilterInput
 * @property {string} [eq] Compare entire string for equality
 * @property {string} [beginsWith] Fragment string must start with
 * @property {string} [endWith] Fragment string must end with
 * @property {string} [contains] Fragment string must contain
 * @property {string} [notContains] Fragment string must not contain
 */

/**
 * Builds and returns a GraphQL StringFilterInput object based on
 * the supplied string value.
 *
 * @param {string} value
 * @returns {StringFilterInput}
 */
function buildStringFilterInput(value) {
  // only equality is currently supported
  return { eq: value };
}

export default buildStringFilterInput;
