import { Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { Money } from "components/Money";
import { DateAndTime } from "components/DataRow";
import { Container } from "./common";
import TransactionDetailsLink from "components/Transaction/TransactionDetailsLink";
import TokenConversionStatusCodeName from "components/Transaction/TokenConversion/TokenConversionStatusCodeName";
import TransactionTypeName from "components/Transaction/TransactionTypeName";

function TokenConversionGridRow({ tokenConversion }) {
  return (
    <Container>
      <div>
        <DateAndTime value={tokenConversion.date} />
        <TransactionDetailsLink transaction={tokenConversion} />
      </div>

      <div>
        <div>
          <TransactionTypeName transactionType={tokenConversion.type} />
        </div>
        <div>
          <TokenConversionStatusCodeName
            tokenConversionStatusCode={tokenConversion.tokenConversionStatusCode}
          />
        </div>
      </div>

      <div>
        <Money value={tokenConversion.amount} currency={tokenConversion.currencyCode} />
      </div>

      <div style={{ padding: "2px 12px" }}>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td isNumeric={true}>
                <Money
                  value={tokenConversion.convertedAmount}
                  currency={tokenConversion.convertedCurrencyCode}
                />
              </Td>
              <Td>{tokenConversion.destinationWallet?.description}</Td>
            </Tr>
          </Tbody>
        </Table>
      </div>

      <div style={{ padding: "2px 12px", gridColumn: "5 / span 2" }}>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td>Token Conversion</Td>
              <Td isNumeric={true}>
                <Money
                  value={tokenConversion.amountMinusFees}
                  currency={tokenConversion.currencyCode}
                />
              </Td>
            </Tr>
            {tokenConversion.integratorFeeAmount !== 0n && (
              <Tr>
                <Td>Publisher Fee</Td>
                <Td isNumeric={true}>
                  <Money
                    value={tokenConversion.integratorFeeAmount}
                    currency={tokenConversion.feeCurrencyCode}
                  />
                </Td>
              </Tr>
            )}
            {tokenConversion.tiliaFeeAmount !== 0n && (
              <Tr>
                <Td>Tilia Fee</Td>
                <Td isNumeric={true}>
                  <Money
                    value={tokenConversion.tiliaFeeAmount}
                    currency={tokenConversion.feeCurrencyCode}
                  />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </div>

      <div />
    </Container>
  );
}

export default TokenConversionGridRow;
