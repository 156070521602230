import { useCallback } from "react";
import * as R from "ramda";
import * as toolsWeb from "api/toolsWeb";
import * as Keys from "./settingsKeys";
import { useAgent } from "context/agentContext";

export const useSettings = () => {
  const { agent } = useAgent();

  const getSettings = useCallback(
    async (key) => {
      const res = await toolsWeb.getSettings(key, agent);
      return R.path(["value", key], res);
    },
    [agent]
  );

  return {
    getSettings,
    Keys,
  };
};
