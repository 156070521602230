import { Navigate, Route, Routes } from "react-router-dom";
import ApprovalQueue from "./Settlements/ApprovalQueue";
import Historical from "./Settlements/Historical";

const FinanceRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="settlements/approval-queue" />} />
      <Route path="settlements" element={<Navigate to="settlements/approval-queue" />} />
      <Route path="settlements/approval-queue" element={<ApprovalQueue />} />
      <Route path="settlements/historical" element={<Historical />} />
    </Routes>
  );
};

export default FinanceRoutes;
