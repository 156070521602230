import { useLocalStorage } from "usehooks-ts";

export const DEBUG_MODE = "debugMode";
export const NAV_COLLAPSE = "navCollapse";
export const THEME = "theme";

const defaultSettings = { [THEME]: "Light", [NAV_COLLAPSE]: false, [DEBUG_MODE]: false };

const makeKey = (accountId) => `tools:user:${accountId}`;

export const useAgentSettings = (accountId) => {
  const storageKey = makeKey(accountId);
  const [settings, setSettings] = useLocalStorage(storageKey, defaultSettings);

  const set = (key, value) => {
    setSettings({ ...settings, [key]: value });
  };

  return [settings, set];
};
