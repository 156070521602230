import { useSearchParams } from "react-router-dom";
import buildDateTimeFilterInput from "./graphql/buildDateTimeFilterInput";
import buildStringFilterInput from "./graphql/buildStringFilterInput";

function getGqlFilterValue(value, type) {
  if (value === undefined) {
    return value;
  }
  switch (type) {
    case "value":
      if (Array.isArray(value) && value.length < 1) {
        return undefined;
      }
      return value;
    case "date":
      if (Array.isArray(value)) {
        return value.map((val) => buildDateTimeFilterInput(val));
      }
      return buildDateTimeFilterInput(value);
    case "string":
    default:
      if (Array.isArray(value)) {
        return value.map((val) => buildStringFilterInput(val));
      }
      return buildStringFilterInput(value);
  }
}

function useFilterParams(filterConfig) {
  const [searchParams] = useSearchParams();

  return filterConfig.reduce(
    (params, { name, type, multiple, defaultValue, graphQlName }) => {
      let value;
      if (searchParams.has(name)) {
        value = searchParams.get(name);
        if (multiple) {
          value = value.split(",");
        }
      } else {
        value = defaultValue;
      }

      params.filterParams[name] = value;
      params.gqlFilterParams[graphQlName] = getGqlFilterValue(value, type);

      return params;
    },
    { filterParams: {}, gqlFilterParams: {} }
  );
}

export default useFilterParams;
