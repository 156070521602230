import React from "react";
import * as Yup from "yup";
import { READ_GDPR_DOCUMENTS } from "context/permissions";
import { validationMessages as vm } from "utils/forms";
import { useIntegrator } from "context/integratorContext";
import { useFormModal } from "components/Modal";
import { OutlineButton } from "components/Button";
import { FileLinkIcon } from "components/Icons";
import { TextField, InfoField } from "components/Forms";
import { useAgent } from "context/agentContext";
import { daysToSeconds, isNilOrEmpty, noop } from "utils";
import { generateGDPRDocumentNonce } from "api/nonce";
import { CopyableText } from "components/Copyable";

const testIdPrefix = "gdpr-document-link-";
const DEFAULT_ACTIVE_DAYS = 7;

export const useDataAccessLinkModal = (document, integrator) => {
  const { gdprBaseDomain } = useIntegrator(integrator);
  const { agent } = useAgent();
  const [FormModal, openModal] = useFormModal();

  const LinkModal = () => {
    const [documentLink, setDocumentLink] = React.useState(null);
    if (!agent.hasPermissions([READ_GDPR_DOCUMENTS])) {
      return null;
    }
    const createGDPRDocumentLink = async ({ activeDays }) => {
      // call the api
      const response = await generateGDPRDocumentNonce(
        { pii_id: document.pii_id },
        daysToSeconds(activeDays),
        agent
      );
      const link = `https://accounts-customer.${gdprBaseDomain}/gdpr/${response.nonce}`;
      // set state to render new UI
      setDocumentLink(link);
      // return success
      return { status: "Success" };
    };

    return (
      <FormModal
        actionIcon={<FileLinkIcon size="1rem" />}
        actionText="Generate Link"
        closeOnSubmitSuccess={false}
        formProps={{
          initialValues: {
            activeDays: DEFAULT_ACTIVE_DAYS,
          },
          validationSchema: () =>
            Yup.object().shape({
              activeDays: Yup.number()
                .required(vm.required)
                .positive(vm.positive)
                .max(90)
                .label("Active days")
                .typeError((v) => `${v.label} must be a positive number. `),
            }),
        }}
        onSubmitActionSuccess={noop}
        submitAction={createGDPRDocumentLink}
        testIdPrefix={testIdPrefix}
        titleText="Generate Customer Link"
      >
        {documentLink ? (
          <InfoField data-testid={`${testIdPrefix}link`} name="link" label="Link">
            <CopyableText text={documentLink} />
          </InfoField>
        ) : (
          <TextField
            data-testid={`${testIdPrefix}key-field`}
            name="activeDays"
            label="Active Days"
            focus={true}
            required
          />
        )}
      </FormModal>
    );
  };

  const LinkButton = ({ label, ...props }) => {
    if (!agent.hasPermissions([READ_GDPR_DOCUMENTS])) {
      return null;
    }
    if (isNilOrEmpty(gdprBaseDomain)) {
      return null;
    }
    return (
      <OutlineButton data-testid={`${testIdPrefix}button`} {...props} onClick={openModal}>
        Generate Document Link
      </OutlineButton>
    );
  };

  return [LinkModal, LinkButton];
};
