import Filters from "components/Filter/Filters.js";
import IntegratorSelect from "components/IntegratorSelect/IntegratorSelect";
import { useFilter } from "components/Filter/FilterContext";
import FilterSelect from "components/Filter/inputs/FilterSelect";
import { STATUS_VALUE_LABEL_MAP } from "../constants";

export default function PayoutFilters() {
  const { filterParams, handleChangeFilter } = useFilter();

  const statusOptions = Object.keys(STATUS_VALUE_LABEL_MAP).map((value) => ({
    value,
    label: STATUS_VALUE_LABEL_MAP[value],
  }));
  const filterOptions = [
    {
      key: "f_status",
      Option: (
        <FilterSelect
          label="Filter by Status"
          name="f_status"
          onChange={(_, val) => handleChangeFilter("f_status", val)}
          options={statusOptions}
          value={filterParams.f_status}
        />
      ),
    },
    {
      key: "f_integrator",
      Option: (
        <IntegratorSelect
          includeAll
          onChange={(integrator) => handleChangeFilter("f_integrator", integrator)}
          value={filterParams.f_integrator}
        />
      ),
    },
  ];
  return <Filters filterOptions={filterOptions} />;
}
