import { Flex } from "@chakra-ui/react";
import { ShieldCheckIcon } from "components/Icons";
import { DetailText } from "components/Text";

export default function VerificationBadge() {
  return (
    <Flex alignItems="center" gap={1}>
      <DetailText>Verified</DetailText>
      <Flex color="green.400">
        <ShieldCheckIcon size={0.75} />
      </Flex>
    </Flex>
  );
}
