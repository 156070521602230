import * as R from "ramda";
import { createAction } from "redux-actions";
import { debugReducer } from "../utils"; //eslint-disable-line no-unused-vars
import * as agentPermissions from "context/permissions";
import * as accountApi from "api/account";
import { STATUS } from "utils";

export { STATUS } from "utils";
export { addAccountPermission, deleteAccountPermission } from "api/account";

export const actions = {
  getPermissionsInit: createAction("@tools/account/getPermissionsInit"),
  getPermissionsSuccess: createAction("@tools/account/getPermissionsSuccess"),
  getPermissionsError: createAction("@tools/account/getPermissionsError"),
  getPermissionsPermissionsFail: createAction("@tools/account/getPermissionsPermissionsFail"),
};

export const getPermissions = async (dispatch, accountId, user) => {
  if (!user.hasPermissions([agentPermissions.READ_PERMISSIONS], agentPermissions.PERMISSIONS_RESOURCE)) {
    dispatch(actions.getPermissionsPermissionsFail());
    return;
  }

  dispatch(actions.getPermissionsInit(accountId));
  try {
    const res = await accountApi.getAccountPermissions(accountId, user);
    dispatch(actions.getPermissionsSuccess(res.payload.scopes));
  } catch (err) {
    dispatch(actions.getPermissionsError(err));
  }
};

export const initialState = {
  permissions: {
    accountId: null,
    data: null,
    error: null,
    permissions: true,
    status: STATUS.PRE_INIT,
  },
};

export const handlers = {
  [actions.getPermissionsInit]: debugReducer((state, { payload }) =>
    R.pipe(
      R.assocPath(["permissions", "accountId"], payload),
      R.assocPath(["permissions", "error"], null),
      R.assocPath(["permissions", "status"], STATUS.INITIALIZED)
    )(state)
  ),
  [actions.getPermissionsSuccess]: (state, { payload }) =>
    R.pipe(
      R.assocPath(["permissions", "data"], payload),
      R.assocPath(["permissions", "status"], STATUS.SUCCESS),
      R.assocPath(["permissions", "error"], null)
    )(state),
  [actions.getPermissionsError]: debugReducer((state, { payload }) =>
    R.pipe(
      R.assocPath(["permissions", "error"], payload),
      R.assocPath(["permissions", "status"], STATUS.ERROR),
      R.assocPath(["permissions", "data"], null)
    )(state)
  ),
  [actions.getPermissionsPermissionsFail]: debugReducer((state) =>
    R.pipe(
      R.assocPath(["permissions", "permissions"], false),
      R.assocPath(["permissions", "status"], STATUS.ERROR),
      R.assocPath(["permissions", "data"], null),
      R.assocPath(["permissions", "error"], null)
    )(state)
  ),
};
