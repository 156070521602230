import { READ_SETTLEMENTS } from "context/permissions";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import { FilterContextProvider } from "components/Filter";
import { PageContent, PageTitle } from "components/Page";
import { PermissionsMessage } from "components/PermissionsMessage";
import SettlementApprovalQueue from "components/Settlements/SettlementApprovalQueue";

const filterConfig = [{ name: "status", graphQlName: "statuses", multiple: true }];

const ApprovalQueue = () => {
  const hasPermissions = useAgentHasPermissions(READ_SETTLEMENTS);
  return (
<PageContent title={{ page: "Settlements", sub1: "Approvals" }} opts={{ clear: true }}>
      <PageTitle>Settlement Approval Queue</PageTitle>
      {!hasPermissions ? (
        <PermissionsMessage />
      ) : (
        <FilterContextProvider config={filterConfig}>
          <SettlementApprovalQueue />
        </FilterContextProvider>
      )}
    </PageContent>
  );
};

export default ApprovalQueue;
