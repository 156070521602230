import React from "react";
import styled from "styled-components/macro";
import * as R from "ramda";
import { useAgent } from "context/agentContext";
import { cssHoverPointer, theme, withTheme, isDesktop, useMediaQuery } from "styles";
import { navRow } from "./NavItem";
import { MenuIcon, CloseIcon, ToolsIcon } from "components/Icons";

const AppTitleLi = styled.li`
  ${navRow}
  position: relative;
  background-color: ${theme("colors.appTitleBg")};
  display: flex;
  justify-content: space-between;
`;

const AppIcon = styled(ToolsIcon)`
  width: 22px;
  height: 24px;
  margin-left: 8px;
  margin-right: 8px;
`;

const Title = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding-top: 1px;
`;

const AppLeft = styled.div`
  display: flex;
`;

const AppRight = styled.div`
  padding: 8px;
`;

const makeBurgerIcons = (icon) =>
  withTheme(styled(icon).attrs(({ theme }) => ({ color: theme.colors.fontInverse }))`
    ${cssHoverPointer}
  `);

const MenuI = makeBurgerIcons(MenuIcon);
const CloseI = makeBurgerIcons(CloseIcon);

export const AppTitle = ({ showMenu = false, onMenuClick, menuOpen }) => {
  const { agentSettings } = useAgent();
  const collapsed = R.prop("navCollapse", agentSettings);
  const desktop = useMediaQuery(isDesktop);
  return (
    <AppTitleLi>
      <AppLeft>
        <AppIcon />
        {(!desktop || (desktop && !collapsed)) && <Title>Tilia Tools</Title>}
      </AppLeft>
      {showMenu && (
        <AppRight data-testid="app-right" onClick={onMenuClick}>
          {menuOpen ? <CloseI /> : <MenuI />}
        </AppRight>
      )}
    </AppTitleLi>
  );
};
