import { BalanceTransferStatusCode, TransactionType } from "@tilia-tools/core/transaction";
import { TransactionHeader } from "../Layout";
import BalanceTransferStatusCodeName from "./BalanceTransferStatusCodeName";

const statusColors = {
  [BalanceTransferStatusCode.PROCESSING]: "orange",
  [BalanceTransferStatusCode.SUCCESS]: "green",
  [BalanceTransferStatusCode.FAILED]: "red",
};

function BalanceTransferHeader({ balanceTransfer }) {
  return (
    <TransactionHeader
      transactionType={TransactionType.BALANCE_TRANSFER}
      badge={{
        label: (
          <BalanceTransferStatusCodeName
            balanceTransferStatusCode={balanceTransfer.balanceTransferStatusCode}
          />
        ),
        color: statusColors[balanceTransfer.balanceTransferStatusCode],
      }}
      copyableId={balanceTransfer.balanceTransferId}
      date={balanceTransfer.date}
    />
  );
}

export default BalanceTransferHeader;
