import { Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import BalanceTransferTypeName from "./BalanceTransferTypeName";
import { DetailText } from "../../Text";

function DetailWithLabel({ label, children }) {
  const gray600 = useColorModeValue("gray.600", "gray.200");

  return (
    <Flex flexDir={"column"} gap={"2px"}>
      <Text as="span" color={gray600} fontWeight={700}>
        {label}:
      </Text>
      <DetailText>{children || "-"}</DetailText>
    </Flex>
  );
}

function BalanceTransferDetailCard({ balanceTransfer }) {
  return (
    <Stack gap={2}>
      <DetailWithLabel label="Transfer Type">
        <BalanceTransferTypeName balanceTransferType={balanceTransfer.balanceTransferType} />
      </DetailWithLabel>
      {balanceTransfer.balanceTransferReferenceId?.length > 0 && (
        <DetailWithLabel
          label={
            balanceTransfer.balanceTransferReferenceType?.length > 0
              ? balanceTransfer.balanceTransferReferenceType
              : "Reference ID"
          }
        >
          {balanceTransfer.balanceTransferReferenceId}
        </DetailWithLabel>
      )}
      {balanceTransfer.balanceTransferMetadata && (
        <DetailWithLabel label="Metadata">
          <Text as="span" whiteSpace="pre-wrap">
            {JSON.stringify(balanceTransfer.balanceTransferMetadata, null, 2)}
          </Text>
        </DetailWithLabel>
      )}
    </Stack>
  );
}

export default BalanceTransferDetailCard;
