import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import { RefundSummaryStatusCode } from "@tilia-tools/core/transaction";
import { WRITE_REFUNDS } from "context/permissions";
import { useFormModal } from "components/Modal";
import { RefundIcon } from "components/Icons";
import { useAgent } from "context/agentContext";
import { noop } from "utils";
import useCurrencyDisplayInfo from "components/Money/useCurrencyDisplayInfo";
import { exists, isNilOrEmpty } from "utils";
import getTransactionRefundTypes from "./getTransactionRefundTypes";
import SuccessfulRefundsList from "./SuccessfulRefundsList";
import AfterRefundResults from "./AfterRefundResults";
import FailedRefundsList from "./FailedRefundsList";
import RefundFormBody from "./RefundFormBody";
import calcLineItemRefundableAmount from "./calcLineItemRefundableAmount";
import applyRefund from "./applyRefund";
import getDefaultPaymentMethodOrder from "./getDefaultPaymentMethodOrder";

const testIdPrefix = "refund-transaction-";

const isNumber = (value) => {
  if (exists(value)) {
    return !Number.isNaN(Number(value));
  } else {
    return null;
  }
};

const useRefundModal = () => {
  const { agent } = useAgent();
  const [FormModal, openModal] = useFormModal();

  const RefundModal = ({ onSuccess = noop, transaction, refetch }) => {
    if (exists(transaction.lineItems)) {
      transaction = {
        ...transaction,
        lineItems: [...transaction.lineItems].sort((a, b) => b.amount - a.amount), // order by greatest to least amount
      };
    }

    const [refundData, setRefundData] = useState(null);
    const { display_minor_unit, denominationMultiplier, getValueByLowestDenominationRounded } =
      useCurrencyDisplayInfo(transaction.currencyCode);

    const refundTypes = getTransactionRefundTypes(transaction);

    const handleSubmit = async (formData) => {
      const { successfulRefunds, failedRefunds } = await applyRefund(
        formData,
        getValueByLowestDenominationRounded,
        agent,
        transaction,
        refundTypes
      );

      setRefundData({ successfulRefunds, failedRefunds });

      // display modal footer error if there is no success
      // if there is any success, the refund modal will handle
      // displaying the individual error messages for each line item
      if (exists(successfulRefunds)) {
        return { status: "Success" };
      } else {
        return { status: "Failure", error: failedRefunds[0].errors };
      }
    };

    if (!agent.hasPermissions([WRITE_REFUNDS])) {
      return <div data-testid={`${testIdPrefix}modal-no-permissions`} />;
    }

    const fullyRefunded = transaction.refundSummary.status === RefundSummaryStatusCode.FULL;

    const paymentMethodIds = getDefaultPaymentMethodOrder(transaction);

    const initialLineItems = exists(transaction.lineItems)
      ? transaction.lineItems.map((lineItem) => ({
          line_item_id: lineItem.lineItemId,
          amount: (calcLineItemRefundableAmount(lineItem) / denominationMultiplier).toFixed(
            display_minor_unit
          ),
        }))
      : [];

    return (
      <FormModal
        formProps={{
          initialValues: {
            lineItems: initialLineItems,
            reason: "",
            note: "",
            payment_methods: paymentMethodIds,
          },
          validationSchema: () =>
            Yup.object().shape({
              reason: Yup.string().required("Refund Reason is required."),
              lineItems: Yup.array()
                .of(
                  Yup.object().shape({
                    amount: Yup.string().test("number only", "Input is not a number", isNumber),
                  })
                )
                .test("greater than 0", "Total amount must be greater than 0", (lineItems = []) => {
                  if (lineItems.length < 1) {
                    return true;
                  }
                  const totalAmount = lineItems.reduce((total, lineItem) => {
                    total += getValueByLowestDenominationRounded(lineItem.amount);
                    return total;
                  }, 0);

                  return totalAmount > 0;
                }),
            }),
        }}
        closeOnSubmitSuccess={false}
        actionText="Refund"
        onSubmitActionSuccess={onSuccess}
        submitAction={fullyRefunded ? undefined : handleSubmit}
        testIdPrefix={testIdPrefix}
        titleText={`Refund Transaction`}
        onCloseAction={refetch}
        size={"3xl"}
      >
        {fullyRefunded ? (
          <p>
            This transaction has been fully refunded. The refund details should appear in the
            transaction history momentarily.
          </p>
        ) : isNilOrEmpty(refundData?.successfulRefunds) ? (
          <RefundFormBody transaction={transaction} refundTypes={refundTypes} />
        ) : (
          <AfterRefundResults transaction={transaction}>
            <SuccessfulRefundsList refunds={refundData.successfulRefunds} />
            <FailedRefundsList failures={refundData.failedRefunds} transaction={transaction} />
          </AfterRefundResults>
        )}
      </FormModal>
    );
  };

  const initAndOpen = useCallback(() => {
    openModal();
  }, [openModal]);

  const getRefundMenuItems = useCallback(
    (is_refundable) => {
      const hasPermissions = agent.hasPermissions([WRITE_REFUNDS]);

      return {
        disabled: !(is_refundable && hasPermissions),
        "data-displayitem": hasPermissions,
        label: `Refund`,
        icon: <RefundIcon size="1rem" />,
        "data-testid": "refund-transaction-menu-item",
        onSelect: hasPermissions && initAndOpen,
      };
    },
    [agent, initAndOpen]
  );

  return [RefundModal, getRefundMenuItems, initAndOpen];
};

export default useRefundModal;
