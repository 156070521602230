import { Badge, Card, CardBody, CardHeader } from "@chakra-ui/react";
import CardHeading from "components/Card/CardHeading";
import DetailList from "components/DetailList/DetailList";
import AutoPayoutRuleName from "./AutoPayoutRuleName";

export default function Rules({ autoPayoutDecision }) {
  if (!autoPayoutDecision) {
    return null;
  }
  const passReasons =
    autoPayoutDecision.passReasons?.map((reason) => [
      <AutoPayoutRuleName ruleName={reason} />,
      <Badge colorScheme="green">passed</Badge>,
    ]) || [];
  const failReasons =
    autoPayoutDecision.failReasons?.map((reason) => [
      <AutoPayoutRuleName ruleName={reason} />,
      <Badge colorScheme="red">failed</Badge>,
    ]) || [];
  const reviewReasons =
    autoPayoutDecision.reviewReasons?.map((reason) => [
      <AutoPayoutRuleName ruleName={reason} />,
      <Badge colorScheme="red">failed</Badge>,
    ]) || [];
  return (
    <Card>
      <CardHeader>
        <CardHeading>Rules</CardHeading>
      </CardHeader>
      <CardBody>
        <DetailList details={[...failReasons, ...reviewReasons, ...passReasons]} />
      </CardBody>
    </Card>
  );
}
