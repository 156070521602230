import React, { useState } from "react";
import { Field } from "formik";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { Label } from "components/Forms";
import { CheckboxBlank, CheckboxMarked } from "components/Icons";
import { noop } from "utils";
import { theme } from "styled-tools";

export const Toggle = ({
  offLabel,
  onLabel,
  onIcon: OnIcon = CheckboxMarked,
  offIcon: OffIcon = CheckboxBlank,
  required,
  value,
  setFieldValue,
  readOnly,
  ...props
}) => {
  const icon = value ? <OnIcon /> : <OffIcon />;
  const label = value ? onLabel : offLabel;
  const [focus, setFocus] = useState(false);
  const labelId = `${props.name}--label`;
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        ${!readOnly &&
        `
          cursor: pointer;
        `}
        &  * {
          ${!readOnly &&
          `
          cursor: pointer;
        `}
        }
        position: relative;
      `}
      onClick={() => !readOnly && setFieldValue(props.name, !value)}
      id={props.name}
      aria-labelledby={labelId}
    >
      <span
        css={`
          margin-left: 1px;
          display: flex;
          align-items: center;
          outline: ${focus ? theme("forms.outline") : 0};
        `}
      >
        <input
          css={`
            position: absolute;
            opacity: 0;
          `}
          type="checkbox"
          checked={value}
          onChange={noop}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {icon}
        <Label
          required={required}
          id={labelId}
          css={`
            margin-bottom: 2px;
          `}
        >
          {label}
        </Label>
      </span>
    </div>
  );
};

export const ToggleField = (props) => (
  <Field name={props.name}>
    {({ field, form: { setFieldValue: formSetFieldValue, ...formRest } }) => {
      return (
        <Toggle
          {...field}
          {...formRest}
          {...props}
          setFieldValue={(name, value, shouldValidate) => {
            props.setFieldValue
              ? props.setFieldValue(name, value, shouldValidate, formSetFieldValue)
              : formSetFieldValue(name, value, shouldValidate);
          }}
        />
      );
    }}
  </Field>
);
