/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import React from "react";
import { Box, Heading, Table, Tbody, Td, Tfoot, Tr } from "@chakra-ui/react";
import { Money } from "components/Money";
import { SettlementDetailType } from "./settlementTypes";
import SettlementDetailSourceName from "./SettlementDetailSourceName";

/**
 * @param {SettlementSummary} summary
 * @param {string} currencyCode
 * @returns {JSX.Element}
 */
function SettlementRevenueSummary({ summary, currencyCode }) {
  const revenueSubtotals = summary.subtotals.filter(
    (subtotal) => subtotal.settlementDetailType === SettlementDetailType.GROSS_FUNDED_REVENUE
  );
  return (
    <Box>
      <Heading as="h4" size="sm" mb="2">
        Funded Revenue
      </Heading>
      <Table size="sm">
        <Tbody>
          {revenueSubtotals.map((subtotal) => (
            <Tr key={subtotal.source}>
              <Td>
                <SettlementDetailSourceName source={subtotal.source} />
              </Td>
              <Td isNumeric>
                <Money value={subtotal.amount} currency={subtotal.currencyCode} />
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td>Gross revenue</Td>
            <Td isNumeric>
              <Money value={summary.revenueTotal} currency={currencyCode} />
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  );
}

export default SettlementRevenueSummary;
