import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useAgent } from "context/agentContext";
import * as permissions from "context/permissions";
import { noop } from "utils";
import { useFormModal } from "components/Modal";
import { OutlineButton } from "components/Button";
import { PermissionsIcon } from "components/Icons";
import { useAccount } from "context/accountContext";

export const useRemoveUserFromGroupModal = () => {
  const testIdPrefix = "remove-user-permission-";
  const { agent } = useAgent();
  const {
    permissions: { deleteAccountPermission },
  } = useAccount();
  const [FormModal, openModal] = useFormModal();

  const RemoveModal = ({ onSuccess = noop, group, accountId }) => {
    if (!agent.hasPermissions([permissions.WRITE_PERMISSIONS]) || agent.isOwnAccount(accountId)) {
      return <div data-testid={`${testIdPrefix}modal-no-permissions`}></div>;
    }
    return (
      <div>
        <FormModal
          actionIcon={<PermissionsIcon size="1rem" />}
          actionText="Remove"
          onSubmitActionSuccess={onSuccess}
          submitAction={({ group }) => deleteAccountPermission(accountId, group)}
          testIdPrefix={testIdPrefix}
          titleText="Remove User From Group"
          formProps={{
            initialValues: {
              group,
            },
          }}
        >
          <div>Are you sure you want to remove the user from the {group} group?</div>
        </FormModal>
      </div>
    );
  };

  const RemoveModalButton = ({ accountId, ...props }) => {
    if (!agent.hasPermissions([permissions.WRITE_PERMISSIONS]) || agent.isOwnAccount(accountId)) {
      return null;
    }
    return (
      <OutlineButton data-testid={`${testIdPrefix}button`} {...props} onClick={openModal}>
        Delete
      </OutlineButton>
    );
  };

  return [RemoveModal, RemoveModalButton];
};
