import { useAccount } from "context/accountContext";
import { useIntegrator } from "context/integratorContext";
import { isNilOrEmpty } from "utils";

export default function useFeatureFlag(key) {
  if (isNilOrEmpty(key)) throw new Error("Invalid key");
  const account = useAccount();
  const integratorConfig = useIntegrator(account.integrator);
  return integratorConfig?.feature_flags?.[key] || false;
}
