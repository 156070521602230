import React from "react";
import styled from "styled-components/macro"; //eslint-disable-line no-unused-vars
import { theme } from "styled-tools";
import * as T from "components/Table";
import { Desktop, Mobile } from "styles";
import { useIntegrator } from "context/integratorContext";

const noPersonaMessage = "This user has no personas";

// unfortunately the 2 options are to either hide the bottom border at Tr with !important
// or to give each Td a prop to hide the border
const DesktopTr = styled(T.Tr)`
  & > td {
    border-bottom: ${(props) => (props.hideBottomBorder ? "none" : null)} !important;
  }
`;

const Persona = ({ data, hideBottomBorder }) => {
  const integrator = useIntegrator(data.integrator);
  return (
    <DesktopTr hideBottomBorder={hideBottomBorder} pull>
      <T.Td>{data.handle}</T.Td>
      <T.Td>{data.name}</T.Td>
      <T.Td>{data.id}</T.Td>
      <T.Td>{integrator.displayName}</T.Td>
      <T.Td data-testid={`is-default-${data.id}`} center>
        {data.is_default ? "Y" : "N"}
      </T.Td>
    </DesktopTr>
  );
};

const NoPersona = ({ hideBottomBorder }) => (
  <T.Tr pull>
    <T.Td colSpan="5" style={{ borderBottom: hideBottomBorder ? "none" : null }}>
      {noPersonaMessage}
    </T.Td>
  </T.Tr>
);

const DesktopPersonas = ({ data, hideBottomBorder }) => (
  <Desktop>
    <div
      css={`
        border-top: 0px solid ${theme("colors.dataTable.border")};
      `}
    >
      <T.Table>
        <T.Thead bordered>
          <T.Tr pull>
            <T.Th>Handle</T.Th>
            <T.Th>Name</T.Th>
            <T.Th>ID</T.Th>
            <T.Th>Publisher</T.Th>
            <T.Th center>Default</T.Th>
          </T.Tr>
        </T.Thead>
        <T.Tbody bordered>
          {data.length === 0 && <NoPersona hideBottomBorder={hideBottomBorder} />}
          {data.map((p, i) => (
            <Persona
              key={p.id}
              data={p}
              hideBottomBorder={hideBottomBorder && data.length === i + 1}
            />
          ))}
        </T.Tbody>
      </T.Table>
    </div>
  </Desktop>
);

const MobileDataRow = ({ title, data, hideBottomBorder, dataTestId }) => (
  <T.Tr pull>
    <T.Th style={{ borderBottom: hideBottomBorder ? "none" : null }}>{title}</T.Th>
    <T.Td
      data-testid={dataTestId}
      rightAlign
      style={{ borderBottom: hideBottomBorder ? "none" : null }}
    >
      {data}
    </T.Td>
  </T.Tr>
);

const MobileNoPersona = () => (
  <T.Table>
    <T.Tbody bordered>
      <T.Tr pull>
        <T.Td colSpan="2">{noPersonaMessage}</T.Td>
      </T.Tr>
    </T.Tbody>
  </T.Table>
);

const MobilePersonaTable = ({ p, hideBottomBorder }) => {
  const integrator = useIntegrator(p.integrator);
  return (
    <T.Table
      key={p.id}
      css={`
        margin-bottom: 16px;
      `}
    >
      <T.Tbody bordered>
        <MobileDataRow title="Handle" data={p.handle} />
        <MobileDataRow title="Name" data={p.name} />
        <MobileDataRow title="ID" data={p.id} />
        <MobileDataRow title="Integrator" data={integrator.displayName} />
        <MobileDataRow
          dataTestId={`is-default-${p.id}`}
          title="Default"
          data={p.is_default ? "Y" : "N"}
          hideBottomBorder={hideBottomBorder}
        />
      </T.Tbody>
    </T.Table>
  );
};

const MobilePersonas = ({ data, hideBottomBorder }) => (
  <Mobile>
    {data.length === 0 && <MobileNoPersona hideBottomBorder={hideBottomBorder} />}
    {data.map((p) => (
      <MobilePersonaTable key={p.id} p={p} hideBottomBorder={hideBottomBorder} />
    ))}
  </Mobile>
);

export const Personas = ({ data = [], hideBottomBorder }) => {
  return (
    <>
      <DesktopPersonas data={data} hideBottomBorder={hideBottomBorder} />
      <MobilePersonas data={data} hideBottomBorder={hideBottomBorder} />
    </>
  );
};
