import { InvoicingApiInvoiceType } from "../../invoicingApi/index.js";

/**
 * Payout status codes.
 * @enum {string}
 */
export const PayoutStatusCode = Object.freeze({
  OPEN: "OPEN",
  IN_ESCROW: "IN_ESCROW",
  ESCROW_FAILED: "ESCROW_FAILED",
  TILIA_ON_HOLD: "TILIA_ON_HOLD",
  PROVIDER_ON_HOLD: "PROVIDER_ON_HOLD",
  PAYOUT_REQUESTED: "PAYOUT_REQUESTED",
  CREDIT_EXECUTED: "CREDIT_EXECUTED",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  USER_REVERSE_REQUESTED: "USER_REVERSE_REQUESTED",
  USER_REVERSED: "USER_REVERSED",
  USER_REVERSE_FAILED: "USER_REVERSE_FAILED",
  SUPPORT_REJECT_REQUESTED: "SUPPORT_REJECT_REQUESTED",
  SUPPORT_REJECTED: "SUPPORT_REJECTED",
  SUPPORT_REJECT_FAILED: "SUPPORT_REJECT_FAILED",
  SYSTEM_REJECTED: "SYSTEM_REJECTED",
  SYSTEM_REJECT_FAILED: "SYSTEM_REJECT_FAILED",
});

/**
 * Payout transaction invoice types.
 * @type {Set<InvoicingApiInvoiceType>}
 */
export const PayoutInvoiceTypes = new Set([
  InvoicingApiInvoiceType.PayoutEscrowIT,
  InvoicingApiInvoiceType.PayoutExecuteIT,
  InvoicingApiInvoiceType.PayoutReverseIT,
]);
