import { Box } from "@chakra-ui/react";
import { Link } from "components/Link";
import AccountAvatar from "./AccountAvatar";

function AccountLink({ account, includeAvatar = false, children, ...linkProps }) {
  return (
    <Link to={`/account/${account.accountId || account.account_id}`} {...linkProps}>
      {includeAvatar ? (
        <Box as="span" display="inline-flex" alignItems="center" gap={2}>
          <AccountAvatar size="xs" account={account} />
          {children}
        </Box>
      ) : (
        children
      )}
    </Link>
  );
}

export default AccountLink;
