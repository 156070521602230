import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAgent } from "context/agentContext";
import { useColorMode, Box, useBreakpointValue } from "@chakra-ui/react";
import { TopNav, SideNav } from "../Nav";
import { Alert } from "components/Alert";
import { AppHeader } from "components/AppHeader";

export const AuthLayout = () => {
  const { agentSettings, setAgentSetting } = useAgent();
  const isCollapsed = agentSettings.navCollapse;
  const toggleCollapse = () => setAgentSetting("navCollapse", !isCollapsed);

  const { setColorMode } = useColorMode();
  useEffect(() => {
    setColorMode(agentSettings?.theme?.toLowerCase());
  }, [setColorMode, agentSettings?.theme]);

  const isDesktop = useBreakpointValue({
    base: false,
    md: true,
  });

  return (
    <>
      {isDesktop ? (
        <SideNav toggleCollapse={toggleCollapse} isCollapsed={isCollapsed} />
      ) : (
        <TopNav />
      )}
      <Box
        as="main"
        transition="margin-left 0.25s ease-in-out"
        pos="relative"
        marginLeft={{
          base: "0",
          md: isCollapsed
            ? "var(--chakra-sizes-sidenavWidthCollapsed)"
            : "var(--chakra-sizes-sidenavWidth)",
        }}
        minH="calc(100vh - 1px)"
      >
        <Alert alertIcon displayButton />
        <AppHeader />
        <Outlet />
      </Box>
    </>
  );
};
