import React from "react";
import { Box, Input } from "@chakra-ui/react";
import { Label } from "components/Forms/Label";

const TextFilter = ({ label, value = "", onChange }) => {
  return (
    <Box>
      <Label htmlFor={`${label}--input`}>{label}</Label>
      <Input
        id={`${label}--input`}
        size="sm"
        aria-label="filter"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </Box>
  );
};

export default TextFilter;
