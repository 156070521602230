import { BalanceTransferItemType } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the balance transfer item type.
 *
 * @param {BalanceTransferItemType} balanceTransferItemType
 * @returns {JSX.Element}
 */
function BalanceTransferItemTypeName({ balanceTransferItemType }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (balanceTransferItemType) {
    case BalanceTransferItemType.CLAWBACK_FROM_INTEGRATOR:
      return <>Publisher Clawback</>;
    case BalanceTransferItemType.CLAWBACK_FROM_RECIPIENT:
      return <>Recipient Clawback</>;
    case BalanceTransferItemType.DEPOSIT:
      return <>Deposit</>;
    case BalanceTransferItemType.FACILITATOR_CURRENCY_SWAP_SINK_FIAT:
      return <>Sink Fiat</>;
    case BalanceTransferItemType.FACILITATOR_CURRENCY_SWAP_SINK_USD:
      return <>Sink USD</>;
    case BalanceTransferItemType.FACILITATOR_CURRENCY_SWAP_SINK_VIRTUAL:
      return <>Sink Tokens</>;
    case BalanceTransferItemType.FACILITATOR_CURRENCY_SWAP_SOURCE_FIAT:
      return <>Source Fiat</>;
    case BalanceTransferItemType.FACILITATOR_CURRENCY_SWAP_SOURCE_USD:
      return <>Source USD</>;
    case BalanceTransferItemType.FACILITATOR_CURRENCY_SWAP_SOURCE_VIRTUAL:
      return <>Source Tokens</>;
    case BalanceTransferItemType.INTEGRATOR_FACILITATOR_SOURCED:
      return <>Transfer From Publisher</>;
    case BalanceTransferItemType.INTEGRATOR_REVENUE_SOURCED:
      return <>Transfer From Publisher</>;
    case BalanceTransferItemType.INTEGRATOR_SOURCED_TO_TILIA:
      return <>Transfer From Publisher</>;
    case BalanceTransferItemType.INTER_ACCOUNT:
      return <>User to User Token Transfer</>;
    case BalanceTransferItemType.INTRA_ACCOUNT:
      return <>Ledger Transfer</>;
    case BalanceTransferItemType.MERGE_ACCOUNT_CONVERTIBLE_CONVERTIBLE_TRANSFER:
      return <>Merge Account Transfer</>;
    case BalanceTransferItemType.MERGE_ACCOUNT_STANDARD_STANDARD_TRANSFER:
      return <>Merge Account Transfer</>;
    case BalanceTransferItemType.PUBLISHER_SETTLEMENT_CREDIT:
      return <>Settlement Credit</>;
    case BalanceTransferItemType.PUBLISHER_SETTLEMENT_DEBIT:
      return <>Settlement Debit</>;
    case BalanceTransferItemType.PUBLISHER_SOURCED:
      return <>Publisher Sourced</>;
    case BalanceTransferItemType.SETTLEMENT_RECONCILIATION:
      return <>Settlement Reconciliation</>;
    case BalanceTransferItemType.TILIA_SOURCED:
      return <>Transfer From Tilia</>;
    case BalanceTransferItemType.TOKEN_CONVERT_INTEGRATOR_FEE_REVERSAL:
      return <>Reverse Publisher Fee</>;
    case BalanceTransferItemType.TOKEN_CONVERT_TILIA_FEE_REVERSAL:
      return <>Reverse Tilia Fee</>;
    case BalanceTransferItemType.TOKEN_CONVERT_USD_CREDIT_REVERSAL:
      return <>Reverse USD Credit</>;
    case BalanceTransferItemType.TOKEN_CONVERT_VIRTUAL_DEBIT_REVERSAL:
      return <>Reverse Earnings Debit</>;
    case BalanceTransferItemType.UNEARNED_TOKENS_CREATED:
      return <>Tokens Issued</>;
    case BalanceTransferItemType.USER_SOURCED_TO_INTEGRATOR:
      return <>Transfer From User</>;
    case BalanceTransferItemType.USER_SOURCED_TO_TILIA:
      return <>Transfer From User</>;
    default:
      return <>{balanceTransferItemType}</>;
  }
}

export default BalanceTransferItemTypeName;
