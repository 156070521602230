import { InvoicingApiInvoiceType } from "../../invoicingApi/index.js";

/**
 * Purchase transaction status codes.
 * @enum {string}
 */
export const PurchaseStatusCode = Object.freeze({
  OPEN: "OPEN",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  SYSTEM_CANCELED: "SYSTEM_CANCELED",
});

/**
 * Purchase transaction invoice types.
 * @type {Set<InvoicingApiInvoiceType>}
 */
export const PurchaseInvoiceTypes = new Set([
  InvoicingApiInvoiceType.UserPurchaseIT,
  InvoicingApiInvoiceType.UserPurchaseVirtualIT,
]);
