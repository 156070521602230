import { forwardRef } from "react";
import { Button } from "@chakra-ui/react";

const CloseButton = forwardRef(function ({ disabled = false, ...props }, ref) {
  return (
    <Button ref={ref} type="button" colorScheme="gray" isDisabled={disabled} {...props}>
      Close
    </Button>
  );
});
CloseButton.displayName = "CloseButton";

export default CloseButton;
