import { Card, CardBody, Table, TableContainer, Tbody, Td, Tfoot, Tr } from "@chakra-ui/react";
import { FormattedExchangeRate, Money } from "components/Money";

function TokenPurchaseSummary({ tokenPurchase }) {
  return (
    <Card>
      <CardBody>
        <TableContainer>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Td>
                  Purchase of{" "}
                  <Money
                    value={tokenPurchase.tokenAmount}
                    currency={tokenPurchase.tokenCurrencyCode}
                  />{" "}
                  to {tokenPurchase.destinationWallet?.description}
                </Td>
                <Td isNumeric={true}>
                  <Money
                    value={tokenPurchase.amountMinusFees}
                    currency={tokenPurchase.currencyCode}
                  />
                </Td>
              </Tr>
              {tokenPurchase.integratorFeeAmount !== 0n && (
                <Tr>
                  <Td>Publisher Fee</Td>
                  <Td isNumeric={true}>
                    <Money
                      value={tokenPurchase.integratorFeeAmount}
                      currency={tokenPurchase.feeCurrencyCode}
                    />
                  </Td>
                </Tr>
              )}
              {tokenPurchase.tiliaFeeAmount !== 0n && (
                <Tr>
                  <Td>Tilia Fee</Td>
                  <Td isNumeric={true}>
                    <Money
                      value={tokenPurchase.tiliaFeeAmount}
                      currency={tokenPurchase.feeCurrencyCode}
                    />
                  </Td>
                </Tr>
              )}
            </Tbody>
            <Tfoot>
              <Tr>
                <Td colSpan={2}>
                  Exchange rate:{" "}
                  <FormattedExchangeRate
                    sourceCurrency={tokenPurchase.currencyCode}
                    destinationCurrency={tokenPurchase.tokenCurrencyCode}
                    exchangeRate={tokenPurchase.exchangeRate}
                  />
                </Td>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
}

export default TokenPurchaseSummary;
