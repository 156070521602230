import * as R from "ramda";
import { Differ, Viewer } from "json-diff-kit";
import "json-diff-kit/dist/viewer-monokai.css";
import { Box, VStack } from "@chakra-ui/react";
import { LIGHT, useTheme } from "styles";
import { CopyableText } from "components/Copyable";
import { exists } from "utils";

const INDENT = 1;
const indent = (row) => " ".repeat(row.level * INDENT);
const build = (sign, obj) => `${sign}${indent(obj)}${obj.text}`;

const buildGitDiff = (diff) => {
  const processRows = (before, after) => {
    // always return an array, sometimes with one element, sometimes with two
    // both rows must be consulted to know what to do
    const bType = before.type;
    const aType = after.type;

    if (bType === "equal" && aType === "equal") {
      return [build(" ", before)];
    } else if (bType === "modify" && aType === "modify") {
      return [build("-", before), build("+", after)];
    } else if (bType === "remove" && aType === "equal") {
      return [build("-", before)];
    } else if (bType === "equal" && aType === "add") {
      return [build("+", after)];
    }
    return ["unprocessed???"];
  };

  const zipped = R.zipWith(processRows, diff[0], diff[1]);
  return R.pipe(R.flatten, R.join("\n"))(zipped);
};

export const Confirmation = ({ prefix, settingsKey, original = null, values }) => {
  const { currentTheme } = useTheme();
  const viewerTheme = currentTheme._name === LIGHT ? undefined : { theme: "monokai" };

  const differ = new Differ({
    arrayDiffMethod: "lcs", // default `"normal"`, but `"lcs"` may be more useful
  });

  // if adding, existing settingsKey will match prefix, use the prefix+value instead
  const finalSettingsKey = prefix === settingsKey ? prefix + values.settingsKey : settingsKey;

  const originalOrNull = exists(original) ? original : "null";
  const diff = differ.diff(JSON.parse(originalOrNull), JSON.parse(values.settingsValue));
  const diffText = buildGitDiff(diff);

  const lines = [
    //
    `ENV: ${window.location.host}`,
    `KEY: ${finalSettingsKey}`,
    `DESCRIPTION:`,
    `  <describe what changed>`,
    `REASON:`,
    `  <describe why the change was done>`,
    `DIFF:`,
    diffText,
    `BEFORE:`,
    JSON.stringify(JSON.parse(originalOrNull), null, 2),
    "AFTER:",
    JSON.stringify(JSON.parse(values.settingsValue), null, 2),
  ];
  const copyContent = lines.join("\n");

  return (
    <VStack spacing={2} align="stretch">
      <Box>Are you sure you want to apply the following change to {finalSettingsKey}?</Box>
      <Viewer
        syntaxHighlight={viewerTheme}
        css={`
          &.json-diff-viewer tr.message-line {
            border: 0;
            text-align: initial;
          }
          &.json-diff-viewer pre {
            font-size: 10px;
          }
        `}
        diff={diff || {}}
        highlightInlineDiff={true}
        hideUnchangedLines={false}
      />
      <Box>
        <CopyableText data-testid="copy-button" text={copyContent}>
          Copy details for Jira (remember to paste inside a code block!)
        </CopyableText>
      </Box>
    </VStack>
  );
};
