import {
  Card,
  CardBody,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Money } from "components/Money";

function PayoutSummary({ payout }) {
  return (
    <Card>
      <CardBody>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Payout</Th>
                <Th>Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Requested amount</Td>
                <Td>
                  <Money value={payout.amount} currency={payout.currencyCode} />
                </Td>
              </Tr>
              <Tr>
                <Td>Tilia fee</Td>
                <Td>
                  <Money value={-payout.credit.feeAmount} currency={payout.currencyCode} />
                </Td>
              </Tr>
            </Tbody>
            <Tfoot>
              <Tr>
                <Td>Net payout</Td>
                <Td>
                  <Money value={payout.credit.amount} currency={payout.currencyCode} />
                </Td>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
}

export default PayoutSummary;
