import {
  Box,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Grid,
  GridItem,
  Heading,
  Stack,
  Tag,
  Wrap,
} from "@chakra-ui/react";
import * as R from "ramda";
import { CopyableText } from "components/Copyable";
import DetailList from "components/DetailList/DetailList";
import { DetailText } from "components/Text";
import { exists } from "utils";
import { WalletIcon, ConvertableTokenIcon, StandardTokenIcon } from "components/Icons";
import { IconContext } from "react-icons";
import { WalletMenu } from "./WalletMenu";

const STATUS_COLOR_MAP = {
  critical: "red",
  info: "gray",
  warning: "yellow",
};

const getColorScheme = (status) => R.defaultTo(STATUS_COLOR_MAP["info"], STATUS_COLOR_MAP[status]);

const PMSection = ({ pm }) => {
  return exists(pm) ? (
    <Box>
      <Box>Payment Method:</Box>
      <DetailText>{pm.displayString}</DetailText>
      <CopyableText text={pm.paymentMethodId} buttonSize={"xs"}>
        <DetailText>{pm.paymentMethodId}</DetailText>
      </CopyableText>
    </Box>
  ) : null;
};

const isToken = (wallet) => R.includes("virtual", wallet.tags);
const isConvertible = (wallet) => R.includes("convertible", wallet.tags);

const CardIcon = ({ wallet }) => {
  if (isToken(wallet)) {
    if (isConvertible(wallet)) {
      return <ConvertableTokenIcon />;
    }
    return <StandardTokenIcon />;
  }
  return <WalletIcon size="2.5em" />;
};

export const WalletHeaderSummary = ({ wallet, withMenu = false, onChange }) => {
  return (
    <Grid templateColumns="min-content auto min-content" gap={2}>
      <GridItem>
        <IconContext.Provider value={{ size: "2.5em" }}>
          <CardIcon wallet={wallet} />
        </IconContext.Provider>
      </GridItem>
      <GridItem>
        <Heading as="h3" size="xs" m={0}>
          {wallet.description}
        </Heading>
        <CopyableText text={wallet.id} buttonSize={"xs"}>
          <DetailText>{wallet.id}</DetailText>
        </CopyableText>
      </GridItem>
      <GridItem>{withMenu && <WalletMenu wallet={wallet} onChange={onChange} />}</GridItem>
    </Grid>
  );
};

export const WalletCard = ({ wallet, detailList, onChange }) => {
  return (
    <Card>
      <CardHeader>
        <WalletHeaderSummary wallet={wallet} withMenu={true} onChange={onChange} />
      </CardHeader>
      <CardBody>
        <DetailList details={detailList.map(({ title, detail }) => [title, detail])} />
      </CardBody>
      <CardFooter fontSize="xs">
        <Stack spacing={0}>
          {wallet.tags.length > 0 && (
            <Box>
              <Wrap spacing={1}>
                {R.pipe(
                  R.sortBy(R.identity),
                  R.map((label) => (
                    <Tag size="sm" key={label} colorScheme={getColorScheme("info")}>
                      {label}
                    </Tag>
                  )),
                )(wallet.tags)}
              </Wrap>
            </Box>
          )}

          <PMSection pm={wallet.pm} />
        </Stack>
      </CardFooter>
    </Card>
  );
};
