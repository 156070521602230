import PartySummaryList from "./PartySummaryList";

function CounterPartySummaryList({ accountId, parties }) {
  const counterParties = parties.filter((party) => {
    // roles without an account, such TILIA and TAX, should always be included
    if (!party.account) {
      return true;
    }
    return party.account.accountId !== accountId;
  });
  return <PartySummaryList parties={counterParties} />;
}

export default CounterPartySummaryList;
