import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import * as R from "ramda";
import { Panel, PanelTitle } from "components/Panel";
import { isNilOrEmpty, noop } from "utils";
import { PermissionRow } from "./common";
import { useAddUserToGroupModal } from "./AddUserToGroupModal";
import { useRemoveUserFromGroupModal } from "./RemoveUserFromGroupModal";

const PermissionRowWithDelete = ({ group, onChange, accountId }) => {
  const [RemoveModal, RemoveModalButton] = useRemoveUserFromGroupModal();
  const deleter = (
    <>
      <RemoveModalButton accountId={accountId} />
      <RemoveModal group={group} onSuccess={onChange} accountId={accountId} />
    </>
  );
  return <PermissionRow title={group} data={deleter} />;
};

const filterDirectFromInherited = (direct, inherited) =>
  R.without(R.intersection(direct, inherited), inherited);

const uniqSort = R.pipe(
  R.uniq,
  R.sort((a, b) => a.localeCompare(b))
);

export const GroupsColumn = ({
  accountGroups = {},
  allGroups = [],
  onChange = noop,
  accountId,
}) => {
  const [AddModal, AddModalButton] = useAddUserToGroupModal();
  const { direct = [], inherit = [] } = accountGroups;

  const onlyInherited = filterDirectFromInherited(direct, inherit);
  const uniqSortedInherited = uniqSort(onlyInherited);

  return (
    <Panel>
      <PanelTitle>
        <span>Groups</span>
        <span>
          <AddModalButton accountId={accountId} />
          <AddModal
            allGroups={allGroups}
            existingGroups={direct}
            onSuccess={onChange}
            accountId={accountId}
          />
        </span>
      </PanelTitle>
      {!isNilOrEmpty(direct) ? (
        <div>
          {R.map((p) => (
            <PermissionRowWithDelete key={p} group={p} onChange={onChange} accountId={accountId} />
          ))(direct)}
          {R.map((p) => <PermissionRow key={p} title={p} />)(uniqSortedInherited)}
        </div>
      ) : (
        <PermissionRow title="No Groups"></PermissionRow>
      )}
    </Panel>
  );
};
