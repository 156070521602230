import { EscrowPurchaseStatusCode } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the escrow purchase status code.
 *
 * @param {EscrowPurchaseStatusCode} escrowPurchaseStatusCode
 * @returns {JSX.Element}
 */
function EscrowPurchaseStatusCodeName({ escrowPurchaseStatusCode }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (escrowPurchaseStatusCode) {
    case EscrowPurchaseStatusCode.OPEN:
      return <>Open</>;
    case EscrowPurchaseStatusCode.IN_ESCROW:
      return <>In Escrow</>;
    case EscrowPurchaseStatusCode.ESCROW_FAILED:
      return <>Escrow Failed</>;
    case EscrowPurchaseStatusCode.COMMIT_REQUESTED:
      return <>Commit Requested</>;
    case EscrowPurchaseStatusCode.SUCCESS:
      return <>Success</>;
    case EscrowPurchaseStatusCode.FAILED:
      return <>Failed</>;
    case EscrowPurchaseStatusCode.CANCEL_REQUESTED:
      return <>Cancel Requested</>;
    case EscrowPurchaseStatusCode.CANCELED:
      return <>Canceled</>;
    case EscrowPurchaseStatusCode.CANCEL_FAILED:
      return <>Cancel Failed</>;
    case EscrowPurchaseStatusCode.SYSTEM_CANCEL_REQUESTED:
      return <>System Cancel Requested</>;
    case EscrowPurchaseStatusCode.SYSTEM_CANCELED:
      return <>System Canceled</>;
    case EscrowPurchaseStatusCode.SYSTEM_CANCEL_FAILED:
      return <>System Cancel Failed</>;
    case EscrowPurchaseStatusCode.CANCEL_INVOICE_TRANSFER_FAILED:
      return <>Cancel Invoice Transfer Failed</>;
    default:
      return <>{escrowPurchaseStatusCode}</>;
  }
}

export default EscrowPurchaseStatusCodeName;
