/**
 * @typedef {Object} TransactionIdentifier
 * @property {string} id - The ID of the transaction.
 * @property {string} integrator - The publisher that owns the transaction.
 * @property {string} accountId - The account ID that owns the transaction.
 * @property {TransactionType} type - The type of the transaction.
 */

/**
 * High-level transaction types.
 * @enum {string}
 */
export const TransactionType = Object.freeze({
  PURCHASE: "PURCHASE",
  ESCROW_PURCHASE: "ESCROW_PURCHASE",
  REFUND: "REFUND",
  PAYOUT: "PAYOUT",
  TOKEN_PURCHASE: "TOKEN_PURCHASE",
  TOKEN_CONVERSION: "TOKEN_CONVERSION",
  BALANCE_TRANSFER: "BALANCE_TRANSFER",
  DEPOSIT: "DEPOSIT",
  MAKE_GOOD: "MAKE_GOOD",
});

/**
 * Map of high-level transaction types to a set of possible scopes an authenticated user must
 * possess in order to view transactions of that type.
 * @type {Map<TransactionType, Set<string>>}
 */
export const TransactionTypeScopes = new Map([
  [TransactionType.PURCHASE, new Set(["read_invoice", "read_invoices"])],
  [TransactionType.ESCROW_PURCHASE, new Set(["read_invoice", "read_invoices"])],
  [TransactionType.REFUND, new Set(["read_refunds"])],
  [TransactionType.PAYOUT, new Set(["read_process_credit", "read_process_credits"])],
  [TransactionType.TOKEN_PURCHASE, new Set(["read_tokens"])],
  [TransactionType.TOKEN_CONVERSION, new Set(["read_tokens"])],
  [TransactionType.BALANCE_TRANSFER, new Set(["read_balance_transfers"])],
  [TransactionType.DEPOSIT, new Set(["read_deposit", "read_deposits"])],
  [TransactionType.MAKE_GOOD, new Set(["read_invoice", "read_invoices"])],
]);

/**
 * High-level aggregate transaction status code. Used for filtering, and mapped to one or more
 * type-specific status codes during execution.
 * @enum {string}
 */
export const TransactionStatusCode = Object.freeze({
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
});

/**
 * Role of the party within the transaction.
 * @enum {string}
 */
export const TransactionPartyRole = Object.freeze({
  BUYER: "BUYER",
  SELLER: "SELLER",
  PUBLISHER: "PUBLISHER",
  TILIA: "TILIA",
  TAX: "TAX",
});
