import { forwardRef } from "react";
import { useFormikContext } from "formik";
import { Button } from "@chakra-ui/react";

const SubmitButton = forwardRef(function (
  {
    message = "Submit",
    loadingMessage = "Submitting…",
    colorScheme = "blue",
    type = "submit",
    disabled = false,
    disableOnClean = false,
    ...props
  },
  ref
) {
  const { isSubmitting, isValid, submitCount, dirty } = useFormikContext();

  return (
    <Button
      ref={ref}
      type={type}
      isLoading={isSubmitting}
      loadingText={loadingMessage}
      colorScheme={isValid || submitCount < 1 ? colorScheme : "yellow"}
      isDisabled={isSubmitting || disabled || (disableOnClean && !dirty)}
      {...props}
    >
      {message}
    </Button>
  );
});
SubmitButton.displayName = "SubmitButton";

export default SubmitButton;
