import React from "react";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import * as R from "ramda";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { theme } from "styles";
import { NoteIcon } from "components/Icons";
import { READ_ACCOUNT_NOTES, withPermissions } from "context/permissions";
import { formatDateTime } from "utils";

export { useAddNoteModal } from "./AddNoteModal";

const Cell = styled.div`
  border: 0px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
`;

const Note = ({ note }) => (
  <div
    css={`
      display: grid;
      grid-template-areas:
        "sticky-icon username b c"
        ". n n n";
      grid-template-columns: 40px min-content min-content auto;
      border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
      padding: 8px 0;
    `}
  >
    <Cell
      css={`
        grid-area: sticky-icon;
      `}
    >
      <NoteIcon />
    </Cell>
    <Cell
      css={`
        grid-area: username;
        white-space: nowrap;
      `}
    >
      {note?.author?.username ?? "--"}
    </Cell>
    <Cell
      css={`
        grid-area: b;
        white-space: nowrap;
      `}
    >
      {note.subject}
    </Cell>
    <Cell
      css={`
        grid-area: c;
        white-space: nowrap;
      `}
    >
      Created {formatDateTime(note.created)}
    </Cell>
    <div
      css={`
        grid-area: n;
      `}
    >
      <Cell>{note?.body}</Cell>
    </div>
  </div>
);

export const Notes = withPermissions(
  { permissions: [READ_ACCOUNT_NOTES] },
  ({ accountId, permissions, notes }) => {
    if (!accountId || !permissions.hasPermission) return null;

    const sortedNotes = R.sort(R.descend(R.prop("created")))(notes);

    return (
      <Panel>
        <PanelTitle>Notes</PanelTitle>
        <PanelContent>
          {sortedNotes.map((note) => (
            <Note key={note.id} note={note} />
          ))}
        </PanelContent>
      </Panel>
    );
  }
);
