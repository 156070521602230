import React, { useCallback } from "react";
import "styled-components/macro";
import * as R from "ramda";
import { Route, Routes, useParams } from "react-router-dom";
import { TiliaSmall } from "components/Logos";
import { Spinner } from "components/Spinner";
import { Center, Column } from "./common";
import { mapIndex, isNilOrEmpty } from "utils";
import { useApi } from "utils/useApi";
import { getIntegratorConfig } from "api/toolsWeb";
import { IntegratorPrompt } from "./IntegratorPrompt";
import { UsernamePasswordLoginForm } from "./UsernamePasswordLoginForm";
import { GoogleLogin } from "./GoogleLogin";
import { SAMLLogin } from "./SAMLLogin";

const IntegratorConfigError = () => {
  return <div data-testid="integrator-config-error">ERROR</div>;
};

const LoginForm = () => {
  const { integrator = "" } = useParams();

  const { error, loading, data } = useApi(
    useCallback(() => getIntegratorConfig(integrator), [integrator])
  );

  if (error || (loading === false && isNilOrEmpty(data))) {
    return <IntegratorConfigError />;
  }

  if (loading) {
    return <Spinner size={3} />;
  }

  const loginConfig = R.sortBy(R.identity, R.filter(R.identity, [].concat(R.prop("login", data))));

  return (
    <div
      css={`
        min-width: 300px;
        width: 30%;
      `}
    >
      {mapIndex((c, index) => {
        switch (c) {
          case "google":
            return <GoogleLogin key={c} integrator={integrator} />;
          case "password":
            return <UsernamePasswordLoginForm key={c} index={index} integrator={integrator} />;
          case "saml":
            return <SAMLLogin key={c} integrator={integrator} />;
          default:
            return <IntegratorConfigError key={c} />;
        }
      })(loginConfig)}
    </div>
  );
};

export const Login = () => {
  return (
    <div data-testid="login-v2">
      <Center>
        <Column>
          <TiliaSmall css="margin-bottom: 16px;" />
          <Routes>
            <Route index element={<IntegratorPrompt />} />
            <Route path=":integrator" element={<LoginForm />} />
          </Routes>
        </Column>
      </Center>
    </div>
  );
};
