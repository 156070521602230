import React from "react";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { isNilOrEmpty } from "utils";
import { theme } from "styles";

export const row = css`
  padding 8px 0;
  border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
`;

export const DataOrNo = ({ data, children }) =>
  isNilOrEmpty(data) ? <div css={row}>No Data</div> : <div>{children}</div>;

export const MobileRow = props => (
  <div
    css={`
      ${row};
      display: flex;
      justify-content: space-between;
    `}
    {...props}
  />
);
