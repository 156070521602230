import {
  // NOTE: switching to react-icons. DO NOT ADD TO THIS IMPORT
  mdiAccountBoxOutline,
  mdiAccountCircle,
  mdiAccountCog,
  mdiAccountMultiple,
  mdiAccountOff,
  mdiAccountSearch,
  mdiAlertCircleOutline,
  mdiApps,
  mdiArrowLeftBox,
  mdiArrowRightBox,
  mdiBrightness4,
  mdiCartOutline,
  mdiChartLine,
  mdiCheckBold,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCircle,
  mdiClose,
  mdiCogs,
  mdiContentCopy,
  mdiCreditCard,
  mdiCreditCardRefund,
  mdiCurrencyUsd,
  mdiDotsVertical,
  mdiDownload,
  mdiEmail,
  mdiEmailCheck,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFeatureSearchOutline,
  mdiFileDocumentEditOutline,
  mdiFileLinkOutline,
  mdiFilterVariant,
  mdiGoogle,
  mdiGreenhouse,
  mdiHammerWrench,
  mdiInformation,
  mdiKey,
  mdiLoading,
  mdiLockOutline,
  mdiLogoutVariant,
  mdiMenu,
  mdiMinusBoxOutline,
  mdiOfficeBuildingCog,
  mdiOpenInNew,
  mdiPlusBoxOutline,
  mdiShieldCheckOutline,
  mdiSunglasses,
  mdiTextBox,
  mdiTextBoxOutline,
  mdiUpload,
  mdiViewDashboard,
  mdiWalletBifoldOutline,
  mdiWebhook,
  // NOTE: switching to react-icons. DO NOT ADD TO THIS IMPORT
} from "@mdi/js";
import Icon from "@mdi/react";
import "styled-components/macro";

// ================================ NEW ICONS
// New! switching to react-icons
import {
  //
  RiCopperCoinFill,
  RiTokenSwapFill,
} from "react-icons/ri";
export {
  //
  MdCheck,
  MdContentCopy,
  MdModeEdit,
} from "react-icons/md";

// rename for our use cases
export const StandardTokenIcon = RiCopperCoinFill;
export const ConvertableTokenIcon = RiTokenSwapFill;

//
//
//
//
//
// ================================ LEGACY ICONS
const defaults = {
  size: 1,
  color: "currentColor",
};

const makeIcon =
  (svg, makeProps = {}) =>
  (laterProps) => {
    // pluck off non-DOM attributes
    const { _css, _css2, _css3, _css4, ...finalLaterProps } = laterProps;
    return <Icon path={svg} {...defaults} {...makeProps} {...finalLaterProps} />;
  };

export const AddIcon = makeIcon(mdiPlusBoxOutline);
export const AgentIcon = makeIcon(mdiAccountCircle);
export const AgentCogIcon = makeIcon(mdiAccountCog);
export const AlertIcon = makeIcon(mdiAlertCircleOutline);
export const BlockedIcon = makeIcon(mdiAccountOff);
export const CartIcon = makeIcon(mdiCartOutline);
export const CheckmarkIcon = makeIcon(mdiCheckBold);
export const CheckboxBlank = makeIcon(mdiCheckboxBlankOutline);
export const CheckboxMarked = makeIcon(mdiCheckboxMarkedOutline);
export const ChevronDown = makeIcon(mdiChevronDown);
export const ChevronLeft = makeIcon(mdiChevronLeft);
export const ChevronRight = makeIcon(mdiChevronRight);
export const CircleIcon = makeIcon(mdiCircle);
export const ClientsIcon = makeIcon(mdiApps);
export const CloseIcon = makeIcon(mdiClose);
export const CopyIcon = makeIcon(mdiContentCopy);
export const CreditCardIcon = makeIcon(mdiCreditCard);
export const DeleteIcon = makeIcon(mdiMinusBoxOutline);
export const DotsVerticalIcon = makeIcon(mdiDotsVertical);
export const DownloadIcon = makeIcon(mdiDownload);
export const EditIcon = makeIcon(mdiFileDocumentEditOutline);
export const EntityIcon = makeIcon(mdiGreenhouse);
export const EmailNotVerifiedIcon = makeIcon(mdiEmail);
export const EmailVerifiedIcon = makeIcon(mdiEmailCheck);
export const EmptyPageIcon = makeIcon(mdiFeatureSearchOutline);
export const FileLinkIcon = makeIcon(mdiFileLinkOutline);
export const FilterVariantIcon = makeIcon(mdiFilterVariant);
export const FinanceIcon = makeIcon(mdiCurrencyUsd);
export const GoogleIcon = makeIcon(mdiGoogle);
export const InfoIcon = makeIcon(mdiInformation);
export const KYCIcon = makeIcon(mdiAccountBoxOutline);
export const LineChartIcon = makeIcon(mdiChartLine);
export const LoadingIcon = makeIcon(mdiLoading, { spin: 1, size: "1rem" });
export const LogoutIcon = makeIcon(mdiLogoutVariant);
export const MaskIcon = makeIcon(mdiEyeOffOutline);
export const UnMaskIcon = makeIcon(mdiEyeOutline);
export const MenuIcon = makeIcon(mdiMenu);
export const NavCollapseIcon = makeIcon(mdiArrowLeftBox);
export const NavExpandIcon = makeIcon(mdiArrowRightBox);
export const NextIcon = makeIcon(mdiChevronDoubleRight);
export const NoteIcon = makeIcon(mdiTextBoxOutline);
export const OpenInNewIcon = makeIcon(mdiOpenInNew);
export const PasswordIcon = makeIcon(mdiLockOutline);
export const PermissionsIcon = makeIcon(mdiKey);
export const PrevIcon = makeIcon(mdiChevronDoubleLeft);
export const PublisherIcon = makeIcon(mdiOfficeBuildingCog);
export const RefundIcon = makeIcon(mdiCreditCardRefund);
export const SearchIcon = makeIcon(mdiAccountSearch);
export const SettingsIcon = makeIcon(mdiCogs);
export const ShieldCheckIcon = makeIcon(mdiShieldCheckOutline);
export const StickyNoteIcon = makeIcon(mdiTextBox);
export const ThemeIcon = makeIcon(mdiBrightness4);
export const ToolsIcon = makeIcon(mdiHammerWrench);
export const UploadIcon = makeIcon(mdiUpload);
export const UserProvisioningIcon = makeIcon(mdiAccountMultiple);
export const WatchlistIcon = makeIcon(mdiSunglasses);
export const ViewDashboardIcon = makeIcon(mdiViewDashboard);
export const WalletIcon = makeIcon(mdiWalletBifoldOutline);
export const WebhooksIcon = makeIcon(mdiWebhook);
export const FilterClearIcon = makeIcon(mdiClose, { color: "#ccc", size: "1.5em" });
