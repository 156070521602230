import { useEffect } from "react";
import * as R from "ramda";
import mousetrap from "mousetrap";

const keyMap = {
  __test: ["__test"],
  searchFocus: ["/", "ctrl+s"]
};
export const keys = R.mapObjIndexed((_, k) => k, keyMap);

export const useKeys = (key, handler) => {
  const shortcuts = keyMap[key];
  if (!shortcuts) {
    throw new Error("useKeys: undefined key", key);
  }
  useEffect(() => {
    mousetrap.bind(shortcuts, e => {
      if (R.is(Function, handler)) {
        // the return value of the handler can control
        // event propagation.
        return handler(e);
      }
    });
    return () => {
      mousetrap.unbind(shortcuts);
    };
  }, [shortcuts, handler]);
};
