import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { OutlineButton } from "components/Button";
import { DeleteIcon } from "components/Icons";
import { WRITE_PRODUCT_CATALOG } from "context/permissions";
import { useAgent } from "context/agentContext";
import { useBasicActionModal } from "components/Modal";
import { getProductTaxCodes, useDeleteProductTaxCode } from "./productHooks";

const Section = styled.div`
  margin: 1em 0;
`;

export const useDeleteModal = (integrator, product) => {
  const { agent } = useAgent();
  const [BAModal, openModal] = useBasicActionModal();

  const ProductCodeDeleteModal = () => {
    const { mutateFunction } = useDeleteProductTaxCode();

    const deleteMutation = async () => {
      const { data } = await mutateFunction({
        permissions: [WRITE_PRODUCT_CATALOG],
        variables: {
          key: integrator.integratorId,
          productCode: product.productCode,
        },
        refetchQueries: [getProductTaxCodes],
      });
      return data.deleteProductTaxCode;
    };

    if (!agent.hasPermissions([WRITE_PRODUCT_CATALOG])) {
      return <div data-testid={`product-code-delete-modal-no-permissions`}></div>;
    }

    return (
      <BAModal
        actionIcon={<DeleteIcon />}
        actionText="Delete"
        submitAction={deleteMutation}
        testIdPrefix="product-catalog-delete-"
        titleText="Delete Product Code"
      >
        <Section>Are you sure you want to delete the product code?</Section>
      </BAModal>
    );
  };

  const ProductCodeDeleteButton = (props) => {
    if (!agent.hasPermissions([WRITE_PRODUCT_CATALOG])) {
      return null;
    }
    return (
      <OutlineButton data-testid="product-tax-code-delete-button" {...props} onClick={openModal}>
        Delete
      </OutlineButton>
    );
  };
  return [ProductCodeDeleteModal, ProductCodeDeleteButton];
};
