import { createContext, useContext } from "react";
import { useLocalStorage } from "usehooks-ts";

const TokenContext = createContext();

export const TokenProvider = (props) => {
  const [sessionJWT, setAgentStorage] = useLocalStorage("tools:token");

  const deleteAgentStorage = () => setAgentStorage(null);

  return (
    <TokenContext.Provider
      value={{ sessionJWT, setToken: setAgentStorage, deleteToken: deleteAgentStorage }}
      {...props}
    />
  );
};

export const useToken = () => useContext(TokenContext);
