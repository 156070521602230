import React from "react";
import styled from "styled-components/macro";
import { theme } from "styles";
import { Link } from "components/Link";

const Cell = styled.div`
  padding: 6px 8px;
  &:first-of-type {
    padding-left: 0;
  }
  display: flex;
  align-items: center;
`;

export const MatchedAccounts = ({ matchedAccounts }) => {
  return (
    <>
      <div
        css={`
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding: 8px 0;
          border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
        `}
      >
        <Cell>Username</Cell>
        <div
          css={`
            text-align: right;
          `}
        >
          Match Score
        </div>
      </div>
      <div>
        {matchedAccounts.map(({ matchedAccount, matchScore }) => (
          <div
            key={matchedAccount.accountId}
            css={`
              display: grid;
              grid-template-columns: 1fr 1fr;
              padding: 8px 0;
              border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
            `}
          >
            <Cell>
              <Link to={`/account/${matchedAccount.accountId}`}>{matchedAccount.username}</Link>
            </Cell>
            <div
              css={`
                text-align: right;
              `}
            >
              {matchScore}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
