import React from "react";
import "styled-components/macro";
import { useAgent } from "context/agentContext";
import { useFormModal } from "components/Modal";
import { OutlineButton } from "components/Button";
import { TextField } from "components/Forms";
import { WRITE_SCOPES, CLIENTS_RESOURCE } from "context/permissions";
import { SettingsIcon } from "components/Icons";
import { deleteClientScope } from "api/account";
import { noop } from "utils";

const testIdPrefix = "delete-client-scope-";

export const useDeleteClientScopeModal = () => {
  const { agent } = useAgent();
  const [FormModal, openModal] = useFormModal();

  const DeleteClientScopeModal = ({ onSuccess = noop, data = {} }) => {
    const { scope_id: scopeId } = data;
    if (!agent.hasPermissions([WRITE_SCOPES], CLIENTS_RESOURCE)) {
      return <div data-testid={`${testIdPrefix}modal-no-permissions`}></div>;
    }

    return (
      <FormModal
        actionIcon={<SettingsIcon size="1rem" />}
        actionText="Delete"
        formProps={{
          initialValues: {
            scopeId,
            scopeName: data.scope_name,
            tokenType: data.token_type,
            public: data.public.toString(),
          },
        }}
        onSubmitActionSuccess={onSuccess}
        submitAction={() => deleteClientScope(scopeId, agent)}
        testIdPrefix={testIdPrefix}
        titleText="Delete"
      >
        <div
          css={`
            min-height: 30vh;
            height: 100%;
            display: flex;
            flex-direction: column;
          `}
        >
          <TextField
            data-testid={`${testIdPrefix}-field`}
            name="scopeName"
            label="Scope Name"
            readOnly={true}
          />
          <TextField
            data-testid={`${testIdPrefix}-field`}
            name="tokenType"
            label="Token Type"
            readOnly={true}
          />
          <TextField
            data-testid={`${testIdPrefix}-field`}
            name="public"
            label="Is Public"
            readOnly={true}
          />
          <TextField
            data-testid={`${testIdPrefix}-field`}
            name="scopeId"
            label="Scope Id"
            readOnly={true}
          />
        </div>
      </FormModal>
    );
  };

  const DeleteClientScopeButton = ({ label = "Delete", ...props }) => {
    if (!agent.hasPermissions([WRITE_SCOPES], CLIENTS_RESOURCE)) {
      return null;
    }
    return (
      <OutlineButton data-testid={`${testIdPrefix}-button`} {...props} onClick={openModal}>
        {label}
      </OutlineButton>
    );
  };

  return [DeleteClientScopeModal, DeleteClientScopeButton];
};
