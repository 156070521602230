import { gql } from "@apollo/client";
import { useQuery, useMutation } from "context/graphqlContext";
import * as Permissions from "context/permissions";

export const getProductTaxCodes = gql`
  query getProductTaxCodes($key: String!) {
    integrator(id: $key) {
      displayName
      productTaxCodes {
        productCode
        tiliaProduct
        isDefault
        description
      }
    }
  }
`;

export const createProductTaxCode = gql`
  mutation createProductTaxCode($key: String!, $productTaxCode: ProductTaxCodeInput!) {
    createProductTaxCode(integrator: $key, productTaxCode: $productTaxCode) {
      status
      error
      message
      productTaxCode {
        productCode
        tiliaProduct
        isDefault
        description
      }
    }
  }
`;

export const updateProductTaxCode = gql`
  mutation updateProductTaxCode($key: String!, $productTaxCode: ProductTaxCodeInput!) {
    updateProductTaxCode(integrator: $key, productTaxCode: $productTaxCode) {
      status
      error
      message
      productTaxCode {
        productCode
        tiliaProduct
        isDefault
        description
      }
    }
  }
`;

export const deleteProductTaxCode = gql`
  mutation deleteProductTaxCode($key: String!, $productCode: String!) {
    deleteProductTaxCode(integrator: $key, productCode: $productCode) {
      status
      error
      message
    }
  }
`;

export const useDeleteProductTaxCode = () => {
  return useMutation(deleteProductTaxCode);
};

export const useCreateProductTaxCode = () => {
  return useMutation(createProductTaxCode);
};

export const useUpdateProductTaxCode = () => {
  return useMutation(updateProductTaxCode);
};

export const useGetProductTaxCodes = ({ integratorId }) => {
  return useQuery(getProductTaxCodes, {
    permissions: [Permissions.READ_PRODUCT_CATALOG],
    variables: {
      key: integratorId,
    },
  });
};
