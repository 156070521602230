import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { theme } from "styled-tools";

import AdyenLogo from "assets/adyen-logo.png";
import AmericanExpressLogo from "assets/american-express-logo.png";
import IngenicoLogo from "assets/ingenico-logo.png";
import MasterCardLogo from "assets/master-card-logo.png";
import PaypalLogo from "assets/paypal-logo.svg";
import RebillyLogo from "assets/rebilly-logo.png";
import VisaLogo from "assets/visa-logo.svg";
import WorldpayLogo from "assets/worldpay-logo.png";

import TiliaLargeLogo from "assets/Tilia_TM.png";
import TiliaSmallLogo from "assets/Tilia_TM_small.png";

const Logo = ({ img, height, alt }) => (
  <img
    src={img}
    css={`
      height: ${height};
      border-radius: 2px;
      background: ${theme("colors.logo.bg")};
    `}
    alt={alt}
  />
);

const TiliaLogo = (props) => (
  <img
    src={props.logo}
    alt="Tilia"
    css={`
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      -ms-interpolation-mode: nearest-neighbor;
    `}
    {...props}
  />
);

export const Rebilly = () => <Logo img={RebillyLogo} height="1.2em" alt="Rebilly" />;
export const Adyen = () => <Logo img={AdyenLogo} height="1.2em" alt="Adyen" />;
export const AmericanExpress = () => (
  <Logo img={AmericanExpressLogo} height="1.5em" alt="American Express" />
);
export const Ingenico = () => <Logo img={IngenicoLogo} height="1.4em" alt="Ingenico" />;
export const MasterCard = () => <Logo img={MasterCardLogo} height="1.5em" alt="Master Card" />;
export const Paypal = () => <Logo img={PaypalLogo} height="1.4em" alt="Paypal" />;
export const Visa = () => <Logo img={VisaLogo} height="1em" alt="Visa" />;
export const Worldpay = () => <Logo img={WorldpayLogo} height="1em" alt="Worldpay" />;

export const TiliaLarge = (props) => <TiliaLogo logo={TiliaLargeLogo} {...props} />;
export const TiliaSmall = (props) => <TiliaLogo logo={TiliaSmallLogo} {...props} />;
