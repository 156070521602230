import React from "react";
import styled from "styled-components/macro";
import { Select } from "components/Forms";

export const FilterSelectFrame = styled.div`
  min-width: 10em;
  width: 13em;
  max-width: 20em;
  margin-bottom: -16px;
`;

const FilterSelect = ({
  label,
  placeholder,
  name,
  options,
  value,
  onChange,
  handleChange,
  selectedOptions = [],
  isClearable = false,
}) => {
  if (value && selectedOptions.length > 0) {
    throw new Error(
      "The filter select must be configured as a multi-select or single select. Selected options and value cannot be passed simultaneously."
    );
  }
  let filteredOptions = options;
  if (selectedOptions.length > 0) {
    filteredOptions = filteredOptions.filter((option) => {
      return !selectedOptions.includes(option.value);
    });
  }
  const selectedOption = filteredOptions.find((option) => {
    return option.value === value;
  });

  return (
    <FilterSelectFrame>
      <Select
        label={label}
        placeholder={placeholder}
        name={name}
        maxHeight={"fit-content"}
        options={filteredOptions}
        disabled={filteredOptions.length < 1}
        value={selectedOption || null}
        onChange={onChange}
        handleChange={handleChange}
        isClearable={isClearable}
      />
    </FilterSelectFrame>
  );
};

export default FilterSelect;
