import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  accordionAnatomy.keys
);

const baseStyle = definePartsStyle({
  button: {
    bg: "transparent",
    border: "1px solid",
    borderColor: "gray.100",
    color: "gray.600",
    _dark: {
      borderColor: "gray.800",
      color: "gray.200",
    },
  },
  panel: {
    bg: "gray.50",
    _dark: {
      bg: "gray.700",
    },
  },
});

const accordionTheme = defineMultiStyleConfig({ baseStyle });

export default accordionTheme;
