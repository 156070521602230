import React, { useEffect, useState } from "react";
import "styled-components/macro"; // eslint-disable-line no-unused-vars
import * as R from "ramda";
import { useAgent } from "context/agentContext";
import { READ_KYC_SSNS, WRITE_KYC_PIIS } from "context/permissions";
import { exists } from "utils";
import { MaskIcon, UnMaskIcon } from "components/Icons";

const MASK = "mask";
const CLEAR = "clear";

const MaskToggle = ({ onToggle }) => {
  const [toggleState, setToggleState] = useState(MASK);
  const handleToggle = () => {
    const newState = toggleState === MASK ? CLEAR : MASK;
    onToggle(newState);
    setToggleState(newState);
  };
  return (
    <span
      css={`
        margin-top: 4px;
      `}
      data-testid="ssn-mask-toggle-button"
      onClick={handleToggle}
    >
      {toggleState === MASK ? <UnMaskIcon size={0.9} /> : <MaskIcon size={0.9} />}
    </span>
  );
};

const fullssnregex = /\d{3}-*\d{2}-*(\d{4})/;
const fullssnnodashregex = /(\d{3})(\d{2})(\d{4})/;
const last4Regex = /(\d{4})/;

// handles input of the forms:
// full: 123-45-6789
// full: 123456789
// last4 only: 6789
export const maskValue = (raw) => {
  const match = R.match(fullssnregex, raw);
  if (exists(match[1])) {
    return `***-**-${match[1]}`;
  } else {
    const last4Match = R.match(last4Regex, raw);
    if (exists(last4Match[1])) {
      return `***-**-${last4Match[1]}`;
    }
  }
  return raw;
};

// handle last4, full with dashes, full without dashes
const formatValue = (raw) => {
  const match = R.match(fullssnnodashregex, raw);
  return exists(match[1]) ? `${match[1]}-${match[2]}-${match[3]}` : raw;
};

const ClearableSSN = ({ value }) => {
  const [displayValue, setDisplayValue] = useState(maskValue(value));

  useEffect(() => setDisplayValue(maskValue(value)), [value]);

  const handleToggle = (state) => {
    setDisplayValue(state === MASK ? maskValue(value) : formatValue(value));
  };
  return (
    <span
      css={`
        display: flex;
        align-items: center;
      `}
    >
      <span
        css={`
          display: inline-block;
          margin-right: 4px;
        `}
      >
        {displayValue}
      </span>
      <MaskToggle onToggle={handleToggle} />
    </span>
  );
};

export const SSN = ({ value = "" }) => {
  // if the agent has read_kyc_ssns or write_kyc_piis, they can see the clear ssn - render the option
  // otherwise, just show the masked value
  const { agent } = useAgent();
  const showClearable = agent.hasPermissionsAny([READ_KYC_SSNS, WRITE_KYC_PIIS]);

  if (showClearable) return <ClearableSSN value={value} />;

  // no unmasking
  return <span>***-**-{value}</span>;
};
