/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import useConnectionPaginationOptions from "components/Pager/useConnectionPaginationOptions";
import usePendingSettlements from "./usePendingSettlements";
import { PermissionsMessage } from "components/PermissionsMessage";
// import Pager from "components/Pager";
// import useConnectionPagination from "components/Pager/useConnectionPagination";
import { Money } from "components/Money";
import { formatDate } from "utils";
import {
  Box,
  Button,
  SimpleGrid,
  Table,
  TableContainer,
  Text,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { AccountAvatar } from "components/Account";
import LoadingTbody from "components/Table/LoadingTbody";
import { useIntegrator } from "context/integratorContext";
import { CircleIcon, LoadingIcon } from "../Icons";
import { useFilter } from "components/Filter";
import { forwardRef, useCallback, useState } from "react";
import { SettlementApprovalType, SettlementStatusCode } from "./settlementTypes";
import approvalsForCurrentReviewCycle from "./approvalsForCurrentReviewCycle";
import ApproveSettlementModal from "./ApproveSettlementModal";
import MarkSettlementPaidModal from "./MarkSettlementPaidModal";

function SettlementApprovalQueue() {
  const filter = useFilter();
  const paginationOptions = useConnectionPaginationOptions("NEWEST");

  const { data, loading, permissionsFailed, refetch } = usePendingSettlements({
    filter: filter.gqlFilterParams,
    ...paginationOptions,
  });

  // TODO: enable pagination once API support is in place
  // const { setPageSize, changePage } = useConnectionPagination(data?.settlements);

  const [markPaidSettlementIdentifier, setMarkPaidSettlementIdentifier] = useState(null);

  const handleMarkPaidClick = useCallback(
    (settlementIdentifier) => {
      setMarkPaidSettlementIdentifier(settlementIdentifier);
    },
    [setMarkPaidSettlementIdentifier]
  );
  const handleSettlementMarkedPaid = () => {
    setMarkPaidSettlementIdentifier(null);
    refetch();
  };

  const handleModalClose = () => {
    setMarkPaidSettlementIdentifier(null);
  };

  if (permissionsFailed) {
    return <PermissionsMessage />;
  }

  return (
    <>
      <ApprovalQueueFilterGrid workflowSummary={data?.settlementWorkflowSummary} />
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Publisher</Th>
              <Th>Settlement Period</Th>
              <Th isNumeric={true}>Net Settlement</Th>
              <Th textAlign="center">Reviewer</Th>
              <Th textAlign="center">Approver</Th>
              <Th textAlign="center">Action</Th>
            </Tr>
          </Thead>
          <LoadingTbody columns={6} loading={loading}>
            {data?.settlements?.edges?.map((edge) => (
              <SettlementRow
                key={edge.node.settlementId}
                settlement={edge.node}
                refetch={refetch}
                onMarkPaidClick={handleMarkPaidClick}
              />
            ))}
          </LoadingTbody>
        </Table>
      </TableContainer>
      {/*TODO: Enable <Pager> component once API support is in place*/}
      {/*<Pager*/}
      {/*  setPage={changePage}*/}
      {/*  pageSize={paginationOptions.first || paginationOptions.last}*/}
      {/*  updatePageSize={setPageSize}*/}
      {/*  connection={data?.settlements || {}}*/}
      {/*/>*/}
      <MarkSettlementPaidModal
        settlementIdentifier={markPaidSettlementIdentifier}
        isOpen={!!markPaidSettlementIdentifier}
        onClose={handleModalClose}
        onSettlementMarkedPaid={handleSettlementMarkedPaid}
      />
    </>
  );
}

const statusToColorMap = {
  ALL: "black",
  [SettlementStatusCode.NEW]: "blue",
  [SettlementStatusCode.RETURNED]: "blue",
  [SettlementStatusCode.REVIEWED]: "green",
  [SettlementStatusCode.APPROVED]: "orange",
  [SettlementStatusCode.SETTLED]: "purple",
};

function ApprovalQueueFilterGrid({ workflowSummary }) {
  return (
    <SimpleGrid columns={5} spacing={4} mb={8}>
      <ApprovalQueueStatusFilterPanel workflowSummary={workflowSummary} status="ALL">
        All
      </ApprovalQueueStatusFilterPanel>
      <ApprovalQueueStatusFilterPanel
        workflowSummary={workflowSummary}
        status={SettlementStatusCode.NEW}
      >
        Review
      </ApprovalQueueStatusFilterPanel>
      <ApprovalQueueStatusFilterPanel
        workflowSummary={workflowSummary}
        status={SettlementStatusCode.REVIEWED}
      >
        Approve
      </ApprovalQueueStatusFilterPanel>
      <ApprovalQueueStatusFilterPanel
        workflowSummary={workflowSummary}
        status={SettlementStatusCode.APPROVED}
      >
        Super Approve
      </ApprovalQueueStatusFilterPanel>
      <ApprovalQueueStatusFilterPanel
        workflowSummary={workflowSummary}
        status={SettlementStatusCode.SETTLED}
      >
        Mark Paid
      </ApprovalQueueStatusFilterPanel>
    </SimpleGrid>
  );
}

const CardButton = forwardRef(function ({ children, ...props }, ref) {
  return (
    <Button ref={ref} variant="card" colorScheme="blue" {...props}>
      {children}
    </Button>
  );
});
CardButton.displayName = "CardButton";

function SettlementStatusColorIcon({ status }) {
  const color = useColorModeValue(
    `${statusToColorMap[status]}.500`,
    `${statusToColorMap[status]}.200`
  );
  return (
    <Box as="span" color={color} mr={2}>
      <CircleIcon size="1.2em" />
    </Box>
  );
}

function ApprovalQueueStatusFilterPanel({ workflowSummary, status, children }) {
  const filter = useFilter();

  const handleClick = () => {
    if (status === "ALL") {
      filter.handleChangeFilter("status", "");
    } else if (filter.filterParams.status?.includes(status)) {
      filter.handleRemoveFilter("status", status);
    } else {
      filter.handleAddFilter("status", status);
    }
  };

  let count = undefined;
  if (workflowSummary) {
    switch (status) {
      case "ALL":
        count =
          workflowSummary.new +
          workflowSummary.returned +
          workflowSummary.reviewed +
          workflowSummary.approved +
          workflowSummary.settled;
        break;
      case SettlementStatusCode.NEW:
        count = workflowSummary.new + workflowSummary.returned;
        break;
      default:
        count = workflowSummary[status.toLowerCase()];
    }
  }

  const statuses = filter.filterParams.status || [];
  let isActive = false;
  if (status === "ALL" && statuses.length < 1) {
    isActive = true;
  } else if (statuses.includes(status)) {
    isActive = true;
  }

  return (
    <CardButton isActive={isActive} onClick={handleClick}>
      <Text as="span" mb={3} display="flex">
        <SettlementStatusColorIcon status={status} /> {children}
      </Text>
      <Text as="span" fontSize="3xl" ml="0.85em">
        {count === undefined ? <LoadingIcon /> : count}
      </Text>
    </CardButton>
  );
}

function SettlementRow({ settlement, refetch, onMarkPaidClick }) {
  const integrator = useIntegrator(settlement.integrator);
  const approvals = approvalsForCurrentReviewCycle(settlement.statusHistory, settlement.approvals);

  return (
    <Tr>
      <Td>{integrator?.displayName || settlement.integrator}</Td>
      <Td>
        {formatDate(settlement.periodStartDatetime)} – {formatDate(settlement.periodEndDatetime)}
      </Td>
      <Td isNumeric={true}>
        <Money value={settlement.netSettlement} currency={settlement.currencyCode} />
      </Td>
      <Td textAlign="center">
        {approvals
          .filter((approval) => approval.approvalType === SettlementApprovalType.REVIEW)
          .map((approval) => (
            <AccountAvatar key={approval.userAccount.accountId} account={approval.userAccount} />
          ))}
      </Td>
      <Td textAlign="center">
        {approvals
          .filter((approval) => approval.approvalType !== SettlementApprovalType.REVIEW)
          .map((approval) => (
            <AccountAvatar key={approval.userAccount.accountId} account={approval.userAccount} />
          ))}
      </Td>
      <Td textAlign="center">
        <SettlementActionButton
          settlement={settlement}
          refetch={refetch}
          onMarkPaidClick={onMarkPaidClick}
        />
      </Td>
    </Tr>
  );
}

function SettlementActionButton({ settlement, refetch, onMarkPaidClick }) {
  switch (settlement.settlementStatusCode) {
    case SettlementStatusCode.NEW:
    case SettlementStatusCode.RETURNED:
      return (
        <SettlementApproveButton
          settlement={settlement}
          refetch={refetch}
          approvalType={SettlementApprovalType.REVIEW}
          colorScheme={statusToColorMap.NEW}
        >
          Review
        </SettlementApproveButton>
      );
    case SettlementStatusCode.REVIEWED:
      return (
        <SettlementApproveButton
          settlement={settlement}
          refetch={refetch}
          approvalType={SettlementApprovalType.APPROVE}
          colorScheme={statusToColorMap.REVIEWED}
        >
          Approve
        </SettlementApproveButton>
      );
    case SettlementStatusCode.APPROVED:
      return (
        <SettlementApproveButton
          settlement={settlement}
          refetch={refetch}
          approvalType={SettlementApprovalType.SUPER_APPROVE}
          colorScheme={statusToColorMap.APPROVED}
        >
          Super Approve
        </SettlementApproveButton>
      );
    case SettlementStatusCode.SETTLED:
      return (
        <Button
          type="button"
          size="sm"
          colorScheme={statusToColorMap.SETTLED}
          onClick={() => onMarkPaidClick(settlement)}
        >
          Mark Paid
        </Button>
      );
    default:
      return null;
  }
}

function SettlementApproveButton({ settlement, refetch, approvalType, colorScheme, children }) {
  const { isOpen, onClose, getButtonProps } = useDisclosure();
  const buttonProps = getButtonProps();

  const handleCancel = () => {
    onClose();
  };
  const handleSettlementApproved = () => {
    refetch();
    onClose();
  };

  return (
    <>
      <Button type="button" size="sm" colorScheme={colorScheme} {...buttonProps}>
        {children}
      </Button>
      {isOpen && (
        <ApproveSettlementModal
          settlementId={settlement.settlementId}
          integrator={settlement.integrator}
          approvalType={approvalType}
          onCancel={handleCancel}
          onSettlementApproved={handleSettlementApproved}
        />
      )}
    </>
  );
}

export default SettlementApprovalQueue;
