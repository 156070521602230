import { Grid } from "@chakra-ui/react";
import AccountCard from "../AccountCard";
import {
  Section,
  TransactionColumnLeft,
  TransactionColumnRight,
  TransactionLayout,
  TransactionMain,
} from "../Layout";
import PayoutIDs from "./PayoutIDs";
import PayoutMethod from "./PayoutMethod";
import PayoutSummary from "./PayoutSummary";
import Tags from "components/Transaction/Tags";
import Notes from "./Notes";
import Rules from "./Rules";

const TAG_MAP = {
  "auto-approved": "Auto-Approved",
};

function PayoutDetail({ payout }) {
  return (
    <TransactionLayout alert={payout.failureReason ? { description: payout.failureReason } : null}>
      <TransactionColumnLeft>
        <Tags tags={payout.tags.filter((tag) => TAG_MAP[tag]).map((tag) => TAG_MAP[tag])} />
        <Section title="Account Detail">
          <AccountCard accountId={payout.account.accountId} />
        </Section>
        <PayoutMethod payout={payout} />
      </TransactionColumnLeft>
      <TransactionMain>
        <Section title="Summary">
          <PayoutSummary payout={payout} />
        </Section>
        <Section title="Payout Details">
          <Grid templateColumns={{ base: "1fr", "2xl": "1fr 1fr" }} gap={4}>
            <Notes notes={payout.notes} />
            <PayoutIDs creditId={payout.credit?.id} payoutStatusId={payout.payoutStatusId} />
            <Rules autoPayoutDecision={payout.autoPayoutDecision} />
          </Grid>
        </Section>
        {/* TODO: <Section title="Notes">
            <Notes />
          </Section> */}
      </TransactionMain>
      <TransactionColumnRight>
        {/* TODO: <Section title="Activity Log"> https://tiliajira.atlassian.net/browse/DEV-3215
            <ActivityLog />
          </Section> */}
      </TransactionColumnRight>
    </TransactionLayout>
  );
}

export default PayoutDetail;
