import React, { createContext, useCallback, useContext, useState } from "react";
import { exists } from "utils";

export const AlertContext = createContext({
  message: "",
  displayAlert: false,
  toggleDisplay: () => {},
});

export const AlertProvider = (props) => {
  const [displayAlert, setDisplay] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState("success");
  const [alertDismiss, setAlertDismiss] = useState(false);

  const toggleDisplay = useCallback((status) => {
    if (exists(status)) {
      setDisplay(status);
    } else {
      setDisplay((prevDisplayState) => !prevDisplayState);
    }
  }, []);

  const fireAlert = useCallback((message) => {
    setAlertDismiss(true);
    setAlertMessage(message);
    setDisplay(true);
  }, []);

  const defaultState = {
    message: alertMessage,
    setAlertMessage,
    displayAlert,
    toggleDisplay,
    alertStatus,
    setAlertStatus,
    alertDismiss,
    setAlertDismiss,
    fireAlert,
  };

  return <AlertContext.Provider value={defaultState} {...props} />;
};

export const useAlert = () => useContext(AlertContext);
