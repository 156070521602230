import { isNilOrEmpty } from "utils";

const calcLineItemRefundableAmount = (lineItem) => {
  if (isNilOrEmpty(lineItem)) return 0;

  const lineItemAmount = lineItem.amount;
  const totalRefunded = lineItem.refundSummary?.totalAmount || 0;

  return lineItemAmount - totalRefunded;
};

export default calcLineItemRefundableAmount;
