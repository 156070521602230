import Filters from "components/Filter/Filters.js";
import IntegratorSelect from "components/IntegratorSelect/IntegratorSelect";
import { useFilter } from "components/Filter/FilterContext";

export default function WebhookFilters() {
  const { filterParams, handleChangeFilter } = useFilter();

  const filterOptions = [
    {
      key: "integrator",
      Option: (
        <IntegratorSelect
          onChange={(integrator) => {
            handleChangeFilter("integrator", integrator);
            // TODO: add integrator to title?
          }}
          value={filterParams.integrator}
        />
      ),
    },
  ];
  return <Filters filterOptions={filterOptions} />;
}
