import { BalanceTransferInvoiceTypes } from "./types/balanceTransferTypes.js";
import { DepositInvoiceTypes } from "./types/depositTypes.js";
import { EscrowPurchaseInvoiceTypes } from "./types/escrowPurchaseTypes.js";
import { MakeGoodInvoiceTypes } from "./types/makeGoodTypes.js";
import { PayoutInvoiceTypes } from "./types/payoutTypes.js";
import { PurchaseInvoiceTypes } from "./types/purchaseTypes.js";
import { RefundInvoiceTypes } from "./types/refundTypes.js";
import { TokenConversionInvoiceTypes } from "./types/tokenConversionTypes.js";
import { TokenPurchaseInvoiceTypes } from "./types/tokenPurchaseTypes.js";
import { TransactionType } from "./types/transactionTypes.js";

// build a static map to avoid if/else chain below
/** @type {Map<InvoicingApiInvoiceType, TransactionType>} */
const invoiceTypeToTransactionTypeMap = new Map();
for (const invoiceType of PurchaseInvoiceTypes.values()) {
  invoiceTypeToTransactionTypeMap.set(invoiceType, TransactionType.PURCHASE);
}
for (const invoiceType of EscrowPurchaseInvoiceTypes.values()) {
  invoiceTypeToTransactionTypeMap.set(invoiceType, TransactionType.ESCROW_PURCHASE);
}
for (const invoiceType of RefundInvoiceTypes.values()) {
  invoiceTypeToTransactionTypeMap.set(invoiceType, TransactionType.REFUND);
}
for (const invoiceType of PayoutInvoiceTypes.values()) {
  invoiceTypeToTransactionTypeMap.set(invoiceType, TransactionType.PAYOUT);
}
for (const invoiceType of TokenPurchaseInvoiceTypes.values()) {
  invoiceTypeToTransactionTypeMap.set(invoiceType, TransactionType.TOKEN_PURCHASE);
}
for (const invoiceType of TokenConversionInvoiceTypes.values()) {
  invoiceTypeToTransactionTypeMap.set(invoiceType, TransactionType.TOKEN_CONVERSION);
}
for (const invoiceType of BalanceTransferInvoiceTypes.values()) {
  invoiceTypeToTransactionTypeMap.set(invoiceType, TransactionType.BALANCE_TRANSFER);
}
for (const invoiceType of DepositInvoiceTypes.values()) {
  invoiceTypeToTransactionTypeMap.set(invoiceType, TransactionType.DEPOSIT);
}
for (const invoiceType of MakeGoodInvoiceTypes.values()) {
  invoiceTypeToTransactionTypeMap.set(invoiceType, TransactionType.MAKE_GOOD);
}

/**
 * Returns the transaction type for the given invoice type.
 *
 * @param {InvoicingApiInvoiceType} invoiceType
 * @returns {TransactionType}
 * @throws {Error} if the invoice type is unknown.
 */
function invoiceTypeToTransactionType(invoiceType) {
  const transactionType = invoiceTypeToTransactionTypeMap.get(invoiceType);
  if (!transactionType) {
    throw new Error(`Unknown invoice type: ${invoiceType}`);
  }
  return transactionType;
}

export default invoiceTypeToTransactionType;
