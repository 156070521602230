import React, { useCallback, useEffect, useState } from "react";
import "styled-components/macro";
import * as R from "ramda";
import { useAgent } from "context/agentContext";
import { Panel } from "components/Panel";
import { getSettings } from "api/toolsWeb";
import { noop, validRegexOrNull, isNilOrEmpty, exists } from "utils";
import { stripSettingsPrefix } from "utils/format";
import { Box } from "components/Box";
import { useAddEditSettingModal } from "./AddEditSettingModal";
import { SettingsEntry } from "./SettingsEntry";
import { useIntegrator } from "context/integratorContext";
import { useFilter } from "components/Filter/FilterContext.js";
import * as SettingsKeys from "utils/settingsKeys";

const useGlobalOrIntegrator = () => {
  const { filterParams } = useFilter();
  const maybeIntegrator = useIntegrator(filterParams.integrator);

  let addEnabled = false;
  let deleteEnabled = false;
  let validParams = false;
  let isGlobal = false;
  let keyPrefix;

  if (isNilOrEmpty(filterParams.integrator)) {
    keyPrefix = SettingsKeys.GLOBAL;
    addEnabled = true;
    deleteEnabled = true;
    validParams = true;
    isGlobal = true;
  } else {
    validParams = exists(maybeIntegrator);
    keyPrefix = `JSON::${filterParams.integrator}::`;
  }
  return { isGlobal, validParams, keyPrefix, addEnabled, deleteEnabled };
};

export const SettingsData = ({ onLoad = noop }) => {
  const { agent } = useAgent();
  const { filterParams } = useFilter();

  const { validParams, isGlobal, keyPrefix, addEnabled, deleteEnabled } = useGlobalOrIntegrator();

  const [settings, setIntegratorSettings] = useState(null);
  const [error, setError] = useState();
  const [AddEditSettingModal, AddEditSettingButton] = useAddEditSettingModal();

  // call the onLoad callback every time settings is changed
  useEffect(() => {
    onLoad(settings);
  }, [onLoad, settings]);

  const getSettingsData = useCallback(async () => {
    try {
      const { value } = await getSettings(`${keyPrefix}*`, agent);
      if (isGlobal) {
        setIntegratorSettings(value);
        return;
      }

      const globalIntKeys = await getSettings(SettingsKeys.GLOBAL_INTEGRATOR_JSON_KEYS, agent);
      const defaultKeys = globalIntKeys.value[SettingsKeys.GLOBAL_INTEGRATOR_JSON_KEYS];

      const mergedSettings = R.pipe(
        R.map((k) => `${keyPrefix}${k}`),
        R.reduce((acc, k) => ({ ...acc, [k]: null }), {}),
        R.mergeDeepLeft(value)
      )(defaultKeys || []);

      setIntegratorSettings(mergedSettings);
    } catch (error) {
      setError(error);
    }
  }, [isGlobal, keyPrefix, agent]);

  useEffect(() => {
    if (validParams) {
      getSettingsData();
    }
  }, [validParams, getSettingsData]);

  if (error) {
    return <div>{error.message}</div>;
  }
  if (!validParams) {
    return <div>Invalid Publisher: {filterParams.integrator}</div>;
  }

  const filterRegex = validRegexOrNull(filterParams.f);
  const filterByText = (key) => {
    if (isNilOrEmpty(filterRegex)) return true;
    return R.any(R.test(filterRegex))([key, JSON.stringify(settings[key])]);
  };

  const filterSettingsByKey = (settingsValue) =>
    isNilOrEmpty(filterParams.settingKey) ||
    stripSettingsPrefix(settingsValue) === filterParams.settingKey;

  const allKeys = R.keys(settings);

  return (
    <Panel>
      {addEnabled && (
        <Box
          css={`
            justify-content: flex-end;
          `}
        >
          <AddEditSettingButton label="Add" />
          <AddEditSettingModal
            existingKeys={allKeys}
            settingsKey={keyPrefix}
            prefix={keyPrefix}
            onSuccess={getSettingsData}
          />
        </Box>
      )}
      {R.pipe(
        R.filter(filterSettingsByKey),
        R.filter(filterByText),
        R.sortBy(R.identity),
        R.map((k) => (
          <SettingsEntry
            key={k}
            prefix={keyPrefix}
            settingsKey={k}
            value={settings[k]}
            existingKeys={allKeys}
            onChange={getSettingsData}
            filter={filterParams.f}
            deleteEnabled={deleteEnabled}
          />
        ))
      )(allKeys)}
    </Panel>
  );
};
