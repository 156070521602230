import styled from "styled-components/macro";
import { theme } from "styled-tools";
import { debug, title } from "styles";

/**
 * all Panel components will be wrapped in this
 * think of it as sections within a page
 */
export const PanelWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
`;

export const Panel = styled.div`
  border: 1px solid ${theme("colors.panelBorder")};
  background-color: ${theme("colors.panelBg")};
  margin-bottom: 16px;
  ${debug};
`;

export const PanelTitle = styled.div`
  ${title};
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0px solid ${theme("colors.panelTitleBorder")};
  margin-bottom: 8px;
  ${debug};
`;

export const PanelContent = styled.div`
  padding: 0px;
`;

export const CardPanel = styled(Panel)`
  border-radius: 5px;
  box-shadow: ${theme("colors.panelBoxShadow")};
  max-width: 768px;
  padding: 8px 16px;
  display: grid;
  grid-gap: 8px;
`;

export const CardTitle = styled.div`
  font-weight: 700;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
`;
