import React, { useEffect, useRef } from "react";
import { Field } from "formik";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { theme } from "styles";
import { Label } from "../Label";
import { Error } from "../Error";

export const Note = ({
  error,
  focus,
  label,
  maxHeight,
  name,
  readOnly = false,
  required,
  touched,
  ...rest
}) => {
  const noteRef = useRef();
  useEffect(() => {
    if (focus) {
      noteRef.current.focus();
    }
  }, [focus]);

  return (
    <div
      css={`
        flex: 1;
        display: flex;
        flex-direction: column;
      `}
    >
      <Label required={required} htmlFor={name}>
        {label}
      </Label>
      <textarea
        disabled={readOnly}
        ref={noteRef}
        id={name}
        css={`
          box-sizing: border-box;
          width: 100%;
          color: ${theme("colors.forms.color")};
          border-radius: 2px;
          height: ${maxHeight ? "100%" : "100px"};
          line-height: 1.5em;
          padding: 8px;
          background-color: ${theme("colors.forms.bg")};
          border: 1px solid ${theme("colors.forms.border")};
          flex: 1;
          ${readOnly &&
          css`
            background-color: transparent;
            border: 0;
            resize: none;
          `}
        `}
        {...rest}
      />
      <Error error={error} touched={touched} />
    </div>
  );
};

export const NoteField = (props) => (
  <Field name={props.name}>
    {({ field, form: { errors, touched, ...formRest } }) => (
      <Note
        {...field}
        error={errors[field.name]}
        touched={touched[field.name]}
        {...formRest}
        {...props}
      />
    )}
  </Field>
);
