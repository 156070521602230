import React from "react";
import { HStack, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { Money } from "components/Money";
import { WalletIcon } from "components/Icons";
import { isNilOrEmpty } from "utils";
import { theme } from "styles";

export const WalletBalance = ({ balance, currency }) => {
  if (isNilOrEmpty(balance) || isNilOrEmpty(currency)) return;
  return (
    <HStack>
      <WalletIcon
        css={`
          padding-bottom: 12px;
          color: ${theme("colors.brand.700")};
        `}
        size="3rem"
      />
      <Stat size="md" variant="wallet">
        <StatLabel>Current Balance</StatLabel>
        <StatNumber>
          <Money value={balance} currency={currency} />
        </StatNumber>
      </Stat>
    </HStack>
  );
};
