import { useFilter } from "components/Filter/FilterContext";
import Filters from "components/Filter/Filters.js";
import { DateFilter, getDateLabel } from "components/Filter/inputs/DateFilter.js";
import FilterSelect from "components/Filter/inputs/FilterSelect";
import useConnectionPagination from "components/Pager/useConnectionPagination";
import { useSearchParams } from "react-router-dom";
import { exists } from "utils";
import {
  BalanceTransferStatusCode,
  DepositStatusCode,
  EscrowPurchaseStatusCode,
  MakeGoodStatusCode,
  PayoutStatusCode,
  PurchaseStatusCode,
  RefundStatusCode,
  TokenConversionStatusCode,
  TokenPurchaseStatusCode,
  TransactionStatusCode,
  TransactionType,
} from "@tilia-tools/core/transaction";
import TransactionTypeName from "components/Transaction/TransactionTypeName";
import TransactionStatusCodeName from "components/Transaction/TransactionStatusCodeName";
import PurchaseStatusCodeName from "components/Transaction/UserPurchase/PurchaseStatusCodeName";
import EscrowPurchaseStatusCodeName from "components/Transaction/EscrowPurchase/EscrowPurchaseStatusCodeName";
import RefundStatusCodeName from "components/Transaction/Refund/RefundStatusCodeName";
import PayoutStatusCodeName from "components/Transaction/Payout/PayoutStatusCodeName";
import TokenPurchaseStatusCodeName from "components/Transaction/TokenPurchase/TokenPurchaseStatusCodeName";
import TokenConversionStatusCodeName from "components/Transaction/TokenConversion/TokenConversionStatusCodeName";
import BalanceTransferStatusCodeName from "components/Transaction/BalanceTransfer/BalanceTransferStatusCodeName";
import DepositStatusCodeName from "components/Transaction/Deposit/DepositStatusCodeName";
import MakeGoodStatusCodeName from "components/Transaction/MakeGood/MakeGoodStatusCodeName";

const TRANSACTION_TYPE_OPTIONS = Object.values(TransactionType).map((value) => {
  return { value, label: <TransactionTypeName transactionType={value} /> };
});

const TRANSACTION_STATUS_OPTIONS = [
  {
    label: "Any Status",
    options: Object.values(TransactionStatusCode).map((value) => {
      return {
        key: "status",
        value,
        label: <TransactionStatusCodeName transactionStatusCode={value} />,
      };
    }),
  },
  {
    label: "Purchase Status",
    options: Object.values(PurchaseStatusCode).map((value) => {
      return {
        key: "purchaseStatus",
        value,
        label: <PurchaseStatusCodeName purchaseStatusCode={value} />,
      };
    }),
  },
  {
    label: "Escrow Purchase Status",
    options: Object.values(EscrowPurchaseStatusCode).map((value) => {
      return {
        key: "escrowPurchaseStatus",
        value,
        label: <EscrowPurchaseStatusCodeName escrowPurchaseStatusCode={value} />,
      };
    }),
  },
  {
    label: "Refund Status",
    options: Object.values(RefundStatusCode).map((value) => {
      return {
        key: "refundStatus",
        value,
        label: <RefundStatusCodeName refundStatusCode={value} />,
      };
    }),
  },
  {
    label: "Payout Status",
    options: Object.values(PayoutStatusCode).map((value) => {
      return {
        key: "payoutStatus",
        value,
        label: <PayoutStatusCodeName payoutStatusCode={value} />,
      };
    }),
  },
  {
    label: "Token Purchase Status",
    options: Object.values(TokenPurchaseStatusCode).map((value) => {
      return {
        key: "tokenPurchaseStatus",
        value,
        label: <TokenPurchaseStatusCodeName tokenPurchaseStatusCode={value} />,
      };
    }),
  },
  {
    label: "Token Conversion Status",
    options: Object.values(TokenConversionStatusCode).map((value) => {
      return {
        key: "tokenConversionStatus",
        value,
        label: <TokenConversionStatusCodeName tokenConversionStatusCode={value} />,
      };
    }),
  },
  {
    label: "Balance Transfer Status",
    options: Object.values(BalanceTransferStatusCode).map((value) => {
      return {
        key: "balanceTransferStatus",
        value,
        label: <BalanceTransferStatusCodeName balanceTransferStatusCode={value} />,
      };
    }),
  },
  {
    label: "Deposit Status",
    options: Object.values(DepositStatusCode).map((value) => {
      return {
        key: "depositStatus",
        value,
        label: <DepositStatusCodeName depositStatusCode={value} />,
      };
    }),
  },
  {
    label: "Make Good Status",
    options: Object.values(MakeGoodStatusCode).map((value) => {
      return {
        key: "makeGoodStatus",
        value,
        label: <MakeGoodStatusCodeName makeGoodStatusCode={value} />,
      };
    }),
  },
];

const SORT_OPTIONS = [
  { value: "NEWEST", label: "Newest to Oldest" },
  { value: "OLDEST", label: "Oldest to Newest" },
  { value: "SMALLEST", label: "Amount Low to High" },
  { value: "LARGEST", label: "Amount High to Low" },
];

export default function TransactionHistoryFilters() {
  const { filterParams, handleRemoveFilter, handleAddFilter } = useFilter();
  const { changeSort } = useConnectionPagination();
  const [searchParams] = useSearchParams();

  const handleStatusFilterChange = (option) => {
    handleAddFilter(option.key, option.value);
  };

  const filterOptions = [
    {
      key: "sort",
      primary: true,
      Option: (
        <FilterSelect
          label="Sort"
          name="sort"
          value={searchParams.has("sort") ? searchParams.get("sort") : "NEWEST"}
          onChange={(_, val) => changeSort(val)}
          options={SORT_OPTIONS}
        />
      ),
    },
    {
      key: "date",
      Option: (
        <DateFilter
          selectedOptions={filterParams.date}
          onAddDate={(date) => handleAddFilter("date", date)}
        />
      ),
    },
    {
      key: "type",
      Option: (
        <FilterSelect
          label="Filter by Type"
          name="type"
          onChange={(_, val) => handleAddFilter("type", val)}
          options={TRANSACTION_TYPE_OPTIONS}
          selectedOptions={filterParams.type}
        />
      ),
    },
    {
      key: "status",
      Option: (
        <FilterSelect
          label="Filter by Status"
          name="status"
          handleChange={handleStatusFilterChange}
          options={TRANSACTION_STATUS_OPTIONS}
        />
      ),
    },
  ];

  let appliedFilters = [];

  if (exists(filterParams.date)) {
    filterParams.date.forEach((val) => {
      appliedFilters.push({
        key: "date",
        label: getDateLabel(val),
        value: val,
      });
    });
  }

  for (const value of filterParams.type) {
    appliedFilters.push({
      key: "type",
      label: <TransactionTypeName transactionType={value} />,
      value,
    });
  }

  for (const value of filterParams.status) {
    appliedFilters.push({
      key: "status",
      label: (
        <>
          Any <TransactionStatusCodeName transactionStatusCode={value} />
        </>
      ),
      value,
    });
  }

  for (const value of filterParams.purchaseStatus) {
    appliedFilters.push({
      key: "purchaseStatus",
      label: (
        <>
          <TransactionTypeName transactionType={TransactionType.PURCHASE} />:{" "}
          <PurchaseStatusCodeName purchaseStatusCode={value} />
        </>
      ),
      value,
    });
  }

  for (const value of filterParams.escrowPurchaseStatus) {
    appliedFilters.push({
      key: "escrowPurchaseStatus",
      label: (
        <>
          <TransactionTypeName transactionType={TransactionType.ESCROW_PURCHASE} />:{" "}
          <EscrowPurchaseStatusCodeName escrowPurchaseStatusCode={value} />
        </>
      ),
      value,
    });
  }

  for (const value of filterParams.refundStatus) {
    appliedFilters.push({
      key: "refundStatus",
      label: (
        <>
          <TransactionTypeName transactionType={TransactionType.REFUND} />:{" "}
          <RefundStatusCodeName refundStatusCode={value} />
        </>
      ),
      value,
    });
  }

  for (const value of filterParams.payoutStatus) {
    appliedFilters.push({
      key: "payoutStatus",
      label: (
        <>
          <TransactionTypeName transactionType={TransactionType.PAYOUT} />:{" "}
          <PayoutStatusCodeName payoutStatusCode={value} />
        </>
      ),
      value,
    });
  }

  for (const value of filterParams.tokenPurchaseStatus) {
    appliedFilters.push({
      key: "tokenPurchaseStatus",
      label: (
        <>
          <TransactionTypeName transactionType={TransactionType.TOKEN_PURCHASE} />:{" "}
          <TokenPurchaseStatusCodeName tokenPurchaseStatusCode={value} />
        </>
      ),
      value,
    });
  }

  for (const value of filterParams.tokenConversionStatus) {
    appliedFilters.push({
      key: "tokenConversionStatus",
      label: (
        <>
          <TransactionTypeName transactionType={TransactionType.TOKEN_CONVERSION} />:{" "}
          <TokenConversionStatusCodeName tokenConversionStatusCode={value} />
        </>
      ),
      value,
    });
  }

  for (const value of filterParams.balanceTransferStatus) {
    appliedFilters.push({
      key: "balanceTransferStatus",
      label: (
        <>
          <TransactionTypeName transactionType={TransactionType.BALANCE_TRANSFER} />:{" "}
          <BalanceTransferStatusCodeName balanceTransferStatusCode={value} />
        </>
      ),
      value,
    });
  }

  for (const value of filterParams.depositStatus) {
    appliedFilters.push({
      key: "depositStatus",
      label: (
        <>
          <TransactionTypeName transactionType={TransactionType.DEPOSIT} />:{" "}
          <DepositStatusCodeName depositStatusCode={value} />
        </>
      ),
      value,
    });
  }

  for (const value of filterParams.makeGoodStatus) {
    appliedFilters.push({
      key: "makeGoodStatus",
      label: (
        <>
          <TransactionTypeName transactionType={TransactionType.MAKE_GOOD} />:{" "}
          <MakeGoodStatusCodeName makeGoodStatusCode={value} />
        </>
      ),
      value,
    });
  }

  return (
    <Filters
      appliedFilters={appliedFilters}
      onRemoveFilter={handleRemoveFilter}
      filterOptions={filterOptions}
    />
  );
}
