import React, { useState, useEffect, useMemo } from "react";
import * as R from "ramda";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useAccount } from "context/accountContext";
import { SectionSpinner } from "components/Spinner";
import { PermissionsMessage } from "components/PermissionsMessage";
import { STATUS, isNilOrEmpty } from "utils";
import { AccountWatchlist } from "./AccountWatchlist";
import { WatchlistChecks } from "./WatchlistChecks";
import { useSorting } from "utils/useSorting";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
// import { usePaging } from "utils/usePaging";
// import { Pager } from "components/Pager";
import { fetchKYCPii } from "./utils";
import { useTitle } from "context/titleContext";

const getAccountWatchlist = R.path(["watchlist"]);
const getAccountId = R.path(["accountState", "details", "accountId"]);

export const Watchlist = () => {
  useTitle({ sub1: "Watchlist" });
  const accountContext = useAccount();
  const accountId = getAccountId(accountContext);
  const { agentContext } = accountContext;

  const accountWatchlist = getAccountWatchlist(accountContext);
  const [checks, setChecks] = useState([]);
  const [entities, setEntities] = useState({});
  const [kycPii, setKYCPii] = useState({});

  const { sorter, sortingProps } = useSorting("created");
  const sortedData = useMemo(() => sorter(checks), [checks, sorter]);

  // const paging = usePaging(sortedData);
  // const [pagedData] = paging;

  useEffect(() => {
    if (accountWatchlist.status === STATUS.PRE_INIT) {
      accountWatchlist.getAccountWatchlist(accountId);
    }
    if (accountWatchlist.data) {
      setChecks(accountWatchlist.data.checks);
      setEntities(accountWatchlist.data.entity_map);
    }
  }, [accountId, accountWatchlist]);

  useEffect(() => {
    fetchKYCPii(setKYCPii, accountId, agentContext);
  }, [accountId, agentContext]);

  if (
    accountWatchlist.status === STATUS.PRE_INIT ||
    accountWatchlist.status === STATUS.INITIALIZED
  ) {
    return <SectionSpinner />;
  }

  if (!accountWatchlist.permissions) {
    return <PermissionsMessage />;
  }

  return (
    <Panel>
      <PanelTitle>
        <AccountWatchlist
          accountWatchlist={accountWatchlist.data}
          checks={checks}
          setChecks={setChecks}
          kycPii={kycPii}
        />
      </PanelTitle>
      <PanelTitle>
        {`Watchlist Checks (${isNilOrEmpty(sortedData) ? "0" : R.keys(sortedData).length})`}
      </PanelTitle>
      <PanelContent>
        <WatchlistChecks entities={entities} kycPii={kycPii} data={sortedData} {...sortingProps} />
      </PanelContent>
      <PanelTitle>
        <div />
      </PanelTitle>
    </Panel>
  );
};
