import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  StackItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { DetailText } from "components/Text";

function Notes({ notes }) {
  const gray600 = useColorModeValue("gray.600", "gray.200");

  if (notes.length < 1) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <Heading m={0} size="xs" color={gray600} as="h4">
          Notes
        </Heading>
      </CardHeader>
      <CardBody>
        <Stack>
          {notes.map((note) => (
            <StackItem key={note.id} as="dl" margin={0}>
              <Text as="dt">{note.subject}</Text>
              <DetailText>{note.body}</DetailText>
            </StackItem>
          ))}
        </Stack>
      </CardBody>
    </Card>
  );
}

export default Notes;
