import { debug } from "utils";
import { METHODS, assertIntegrator, generateOpts, mapProxyFetch, serviceMap } from "./utils";
import buildTiliaApiResponse from "./buildTiliaApiResponse";
import { ROUTE_BASENAME } from "../utils/environment";

const settlementProxy = mapProxyFetch(serviceMap.SETTLEMENT_API);

/**
 * @typedef {Object} SettlementPatchData
 * @property {string} action
 * @property {SettlementAdjustment[]} [adjustments]
 * @property {string} [payoutStatusId]
 */
/**
 * Updates the given settlement.
 * @param {string} settlementId
 * @param {SettlementPatchData} data
 * @param {Object} ctx
 * @returns {Promise<TiliaApiResponse<T>>}
 */
export const patchSettlement = async (settlementId, data, ctx) => {
  debug(ctx, "[patchSettlement]", settlementId, data);
  assertIntegrator(ctx);
  const requestBody = { action: data.action };
  if (data.adjustments && data.adjustments.length > 0) {
    requestBody.adjustments = data.adjustments.map((adjustment) => {
      return {
        adjustment_description: adjustment.adjustmentDescription,
        amount: adjustment.amount,
        currency_code: adjustment.currencyCode,
      };
    });
  }
  if (data.payoutStatusId) {
    requestBody.payout_status_id = data.payoutStatusId;
  }
  const response = await settlementProxy(
    ctx,
    `/v1/settlement/${encodeURIComponent(settlementId)}`,
    METHODS.PATCH,
    requestBody
  );
  return buildTiliaApiResponse(response);
};

/**
 * @typedef {Object} MarkSettlementPaidData
 * @property {string} referenceNumber
 */
/**
 * Marks the settlement as paid.
 * @param {string} settlementId
 * @param {MarkSettlementPaidData} data
 * @param {Object} ctx
 * @returns {Promise<TiliaApiResponse<T>>}
 */
export const markSettlementPaid = async (settlementId, data, ctx) => {
  debug(ctx, "[markSettlementPaid]", settlementId, data);
  assertIntegrator(ctx);
  const response = await fetch(
    `${ROUTE_BASENAME}/api/settlement/mark-paid`,
    generateOpts(ctx, {
      method: METHODS.POST,
      body: JSON.stringify({
        settlementId,
        referenceNumber: data.referenceNumber,
      }),
    })
  );
  return buildTiliaApiResponse(response);
};
