import React, { useState } from "react";
import * as R from "ramda";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useModal, ModalFooter, ModalErrors } from "components/Modal";
import { OutlineButton, PrimaryButton, ErrorButton, TextLinkButton } from "components/Button";
import { Note } from "components/Forms/Note";
import { useAccount } from "context/accountContext";

export const useManualReviewModal = () => {
  const {
    watchlist: { submitManualReview },
  } = useAccount();
  const [Modal, openModal, closeModal] = useModal();
  const [modalError, setModalError] = useState("");

  const ManualReviewModal = ({ accountWatchlist, setManualReviewStatus, checks, setChecks }) => {
    const [reviewNotes, setReviewNotes] = useState(R.path([0, "review_notes"], checks));

    const handleReview = async (status) => {
      setModalError("");

      const body = {
        review_notes: reviewNotes,
        status,
      };

      try {
        await submitManualReview(accountWatchlist.account_id, body);
        setManualReviewStatus(status);

        if (checks.length > 0) {
          const nextChecks = [...checks];
          nextChecks[0].review_notes = reviewNotes;
          nextChecks[0].status = status;
          nextChecks[0].updated = "Just now..";
          setChecks(nextChecks);
        }

        closeModal();
      } catch (error) {
        setModalError("An error occurred submitting the manual review.");
      }
    };

    return (
      <Modal titleText="Manual Review">
        <div>
          Submit a manual review. If you click 'False Positive', this user will be allowed to
          execute financial actions, such as cashing out. If you click 'True Positive', it is
          recorded that user is a true positive, but their account will remain unblocked unless you
          take further action.
          <br />
          <br />
          <Note
            id="review-notes"
            name="review-notes"
            label="Review Notes"
            data-testid="review-notes"
            value={reviewNotes}
            onChange={(e) => setReviewNotes(e.target.value)}
          />
        </div>
        <ModalFooter>
          <TextLinkButton data-testid="cancel-button" onClick={closeModal}>
            Cancel
          </TextLinkButton>

          <ErrorButton data-testid="reject-button" onClick={() => handleReview("TRUE_POSITIVE")}>
            True Positive
          </ErrorButton>

          <PrimaryButton
            data-testid="approve-button"
            onClick={() => handleReview("FALSE_POSITIVE")}
          >
            False Positive
          </PrimaryButton>
          <OutlineButton
            data-testid="pending-button"
            onClick={() => handleReview("PENDING_DOCUMENTATION")}
          >
            Pending Documentation
          </OutlineButton>
        </ModalFooter>
        {modalError && <ModalErrors errors={[{ message: modalError }]} />}
      </Modal>
    );
  };

  const ManualReviewButton = () => (
    <OutlineButton data-testid="manual-review-button" onClick={openModal}>
      Submit Manual Review
    </OutlineButton>
  );

  return [ManualReviewModal, ManualReviewButton];
};
