import { TransactionStatusCode } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the transaction status code.
 *
 * @param {TransactionStatusCode} transactionStatusCode
 * @returns {JSX.Element}
 */
function TransactionStatusCodeName({ transactionStatusCode }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (transactionStatusCode) {
    case TransactionStatusCode.PENDING:
      return <>Pending</>;
    case TransactionStatusCode.SUCCESS:
      return <>Success</>;
    case TransactionStatusCode.FAILED:
      return <>Failed</>;
    case TransactionStatusCode.CANCELED:
      return <>Canceled</>;
    default:
      return <>{transactionStatusCode}</>;
  }
}

export default TransactionStatusCodeName;
