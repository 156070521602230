import { WRITE_ACCOUNTS } from "context/permissions";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import useAgentIsTiliaAgent from "context/useAgentIsTiliaAgent";
import useAccountBlocks from "./useAccountBlocks";

const tiliaBlockCapabilities = ["LOGIN", "PAYOUTS", "TRANSACTIONS"];
const publisherBlockCapabilities = ["LOGIN"];

const useUnblockCapabilitiesForAccount = (accountId) => {
  const hasPermission = useAgentHasPermissions([WRITE_ACCOUNTS]);
  const isTiliaAgent = useAgentIsTiliaAgent();
  const [blocks] = useAccountBlocks(accountId);

  if (!hasPermission || blocks.length < 1) {
    return [];
  }

  const blockCapabilities = isTiliaAgent ? tiliaBlockCapabilities : publisherBlockCapabilities;

  // only return blocks that the agent can still remove from the account
  return blockCapabilities.filter((blockCapability) => {
    for (const block of blocks) {
      // publisher agents cannot remove blocks placed by Tilia agents
      if (!isTiliaAgent && block.issuer === "TILIA") {
        return false;
      }
      if (block.capability === blockCapability) {
        return true;
      }
    }
    return false;
  });
};

export default useUnblockCapabilitiesForAccount;
