import React, { useMemo } from "react";
import { Stack, Flex, FormControl, Grid, Switch, Text } from "@chakra-ui/react";
import "styled-components/macro";
import * as R from "ramda";
import { PageContent, PageTitle, StickyPageHeader } from "components/Page";
import { Panel } from "components/Panel";
import { PermissionsCheck } from "components/PermissionsMessage";
import { READ_SETTINGS } from "context/permissions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isNilOrEmpty } from "utils";
import "json-diff-kit/dist/viewer.css";
import { TopBox, useIntegratorSettings } from "../common";
import { ComparinatorRow } from "./ComparinatorRow";
import IntegratorSelect from "components/IntegratorSelect/IntegratorSelect";

const trimKeys = (keys) => keys.map((k) => k.replace(/.*::/, ""));

const extractKeys = (settings1, settings2) => {
  const s1keys = settings1 ? Object.keys(settings1) : [];
  const s2keys = settings2 ? Object.keys(settings2) : [];
  const keys = new Set([...trimKeys(s1keys), ...trimKeys(s2keys)]);
  return keys;
};

const PermissionedComparinator = () => {
  const navigate = useNavigate();
  const { p1, p2 } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const diffOnly = searchParams.get("diff") === "true";

  const p1Settings = useIntegratorSettings(p1);
  const p2Settings = useIntegratorSettings(p2);

  const handlePublisherUpdate = () => {
    p1Settings.refresh();
    p2Settings.refresh();
  };
  const toggleDiffOnly = () => setSearchParams({ diff: !diffOnly });

  const settingsMap = useMemo(() => {
    if (isNilOrEmpty(p1Settings) || isNilOrEmpty(p2Settings)) {
      return {};
    }
    // extract union of keys from both settings
    const keys = extractKeys(p1Settings.settings, p2Settings.settings);

    const buildValue = (k) => {
      // prefix: "JSON::<integrator>::"
      const s1Prefix = R.replace("*", "", p1Settings.keyPrefix);
      const s1Key = R.replace("*", k, p1Settings.keyPrefix);
      const s1Value = R.path(["settings", s1Key], p1Settings);

      const s2Prefix = R.replace("*", "", p2Settings.keyPrefix);
      const s2Key = R.replace("*", k, p2Settings.keyPrefix);
      const s2Value = R.path(["settings", s2Key], p2Settings);
      return {
        key: k,
        s1Prefix,
        s1Key,
        s1Value,
        s2Prefix,
        s2Key,
        s2Value,
      };
    };

    return R.pipe(
      Array.from,
      R.reduce((acc, k) => ({ ...acc, [k]: buildValue(k) }), {})
    )(keys);
  }, [p1Settings, p2Settings]);

  const setPublisher1 = (pub) => {
    navigate({
      pathname: `/settings/compare/${pub}/${isNilOrEmpty(p2) ? "" : p2}`,
      search: searchParams.toString(),
    });
  };

  const setPublisher2 = (pub) => {
    navigate({
      pathname: `/settings/compare/${isNilOrEmpty(p1) ? "" : p1}/${pub}`,
      search: searchParams.toString(),
    });
  };

  return (
    <PageContent title={{ page: "Comparinator", sub1: p1, sub2: p2 }} opts={{ clear: true }}>
      <Panel>
        <StickyPageHeader>
          <PageTitle>Settings Comparinator</PageTitle>
          <Panel>
            <Grid templateColumns="minmax(225px, max-content) 1fr">
              <TopBox>Key</TopBox>
              <TopBox>
                <table
                  css={`
                    width: 100%;
                    table-layout: fixed;
                  `}
                >
                  <tbody>
                    <tr>
                      <td>
                        <IntegratorSelect onChange={setPublisher1} value={p1} />
                      </td>
                      <td>
                        <Flex justifyContent="space-between" alignItems="center">
                          <IntegratorSelect onChange={setPublisher2} value={p2} />
                          <FormControl display="flex" justifyContent="end">
                            <Stack align="center" direction="row">
                              <Text>Diffs only</Text>
                              <Switch
                                id="diffOnly"
                                onChange={toggleDiffOnly}
                                isChecked={diffOnly}
                              />
                            </Stack>
                          </FormControl>
                        </Flex>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TopBox>
            </Grid>
          </Panel>
        </StickyPageHeader>
        <Panel>
          <Grid templateColumns="minmax(225px, max-content) 1fr">
            {settingsMap &&
              R.pipe(
                R.keys,
                R.sortBy(R.identity),
                R.map((k) => (
                  <ComparinatorRow
                    k={k}
                    key={k}
                    data={settingsMap[k]}
                    onUpdate={handlePublisherUpdate}
                    diffOnly={diffOnly}
                  />
                ))
              )(settingsMap)}
          </Grid>
        </Panel>
      </Panel>
    </PageContent>
  );
};

export const Comparinator = () => {
  return (
    <PermissionsCheck permissions={READ_SETTINGS}>
      <PermissionedComparinator />
    </PermissionsCheck>
  );
};
