import { gql, useQuery } from "@apollo/client";

const getAccountWallets = gql`
  query GetAccountWalletLedger($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      wallets {
        account {
          accountId
          username
        }
        id
        integrator
        description
        currency
        currentBalance
        active
        tags
      }
    }
  }
`;

const useAccountWallets = (accountId) => {
  return useQuery(getAccountWallets, {
    errorPolicy: "all",
    variables: {
      accountId,
    },
  });
};

export default useAccountWallets;
