import { debug } from "utils";
import * as R from "ramda";
import { METHODS, assertIntegrator, assertOk, mapProxyFetch, serviceMap } from "./utils";

const piiPubProxy = mapProxyFetch(serviceMap.PII_API);
const debugApi = (ctx, fn, ...args) => debug(ctx, `[api/pii/${fn}]`, ...args);

export const getEntity = async (accountId, ctx) => {
  debugApi(ctx, "getEntity", accountId);
  assertIntegrator(ctx);
  const response = await piiPubProxy(ctx, `/v1/entity/account/${accountId}`);
  await assertOk(response);
  return response.json();
};

export const getEntityById = async (entityId, ctx) => {
  debugApi(ctx, "getEntityById", entityId);
  const response = await piiPubProxy(ctx, `/v1/entity/${entityId}`);
  await assertOk(response);
  return response.json();
};

export const updateEntity = async (entityId, entityData, ctx) => {
  debugApi(ctx, "updateEntity", entityId);
  assertIntegrator(ctx);
  const response = await piiPubProxy(ctx, `/v1/entity/${entityId}`, METHODS.PUT, entityData);
  await assertOk(response);
  return response.json();
};

export const createEntityAccount = async (accountId, entityData, ctx) => {
  debugApi(ctx, "createEntityAccount", accountId);
  assertIntegrator(ctx);
  const response = await piiPubProxy(ctx, `/v1/entity`, METHODS.POST, entityData);
  await assertOk(response);
  return response.json();
};

export const createEntityAgent = async (agentData, ctx) => {
  debugApi(ctx, "createEntityAgent", agentData.entity_id);
  assertIntegrator(ctx);
  const response = await piiPubProxy(ctx, `/v1/entity/agent`, METHODS.POST, agentData);
  await assertOk(response);
  return response.json();
};

export const updateEntityAgent = async (agentData, ctx) => {
  debugApi(ctx, "updateEntityAgent", agentData.entity_agent_id);
  assertIntegrator(ctx);
  const response = await piiPubProxy(
    ctx,
    `/v1/entity/agent/${agentData.entity_agent_id}`,
    METHODS.PUT,
    agentData,
  );
  await assertOk(response);
  return response.json();
};

export const uploadKYBFile = async (entityId, file, ctx) => {
  debugApi(ctx, "postKYBFile", entityId);
  assertIntegrator(ctx);
  const formData = new FormData();
  formData.append("file", file);
  const response = await piiPubProxy(
    ctx,
    `v1/entity/${entityId}/document`,
    METHODS.POST,
    null,
    R.pipe(R.dissocPath(["headers", "Content-Type"]), R.assocPath(["body"], formData)),
  );
  await assertOk(response);
  return response.json();
};

export const getKYBFile = async (entityId, fileId, ctx) => {
  debugApi(ctx, "getKYBFile", entityId, fileId);
  assertIntegrator(ctx);
  const response = await piiPubProxy(ctx, `v1/entity/document/${fileId}`);
  await assertOk(response);
  return response.json();
};
