import { createContext } from "react";
const GridContext = createContext({
  data: [],
  setData: () => {},
  // For Selection
  isSelected: false,
  isAllPageSelected: false,
  pageSelection: [],
  selectDeselect: () => {},
  deselectPage: () => {},
  selectDeselectPage: () => {},
  canBeSelected: () => {},
  // For Sorting
  sortField: "",
  sortDir: "",
  setSortDir: () => {},
  setSortField: () => {},
  // For Filtering
  filterMap: {},
  addUpdateFilter: () => {},
  removeFilter: () => {},
  // For Pagination
  totalCount: 0,
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  setPage: () => {},
  setTotalCount: () => {},
  setPageSize: () => {},
  // Triggers
  update: 0,
  refresh: () => {},
});
export default GridContext;
