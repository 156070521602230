import { Grid } from "@chakra-ui/react";
import { TRANSACTION_PAGE_MAX_WIDTH } from ".";
import { Alert, AlertTitle, AlertDescription } from "@chakra-ui/react";
export const ASIDE_STACK_GAP = 5;

function TransactionLayout({ children, alert }) {
  return (
    <>
      {alert && (
        <Alert maxW={TRANSACTION_PAGE_MAX_WIDTH} boxSizing="border-box" status="error" mb={2}>
          {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
          {alert.description && <AlertDescription>{alert.description}</AlertDescription>}
        </Alert>
      )}
      <Grid
        maxW={TRANSACTION_PAGE_MAX_WIDTH}
        templateColumns={{ base: "1fr", lg: "330px 1fr", xl: "330px 1fr 200px" }}
        gap={5}
      >
        {children}
      </Grid>
    </>
  );
}

export default TransactionLayout;
