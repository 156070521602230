import { getAllPayouts } from "api/invoicing";
import { useFilter } from "components/Filter";
import { GridContext, GridProvider, NoData } from "components/Grid";
import { GridLoading } from "components/Grid/utils";
import { Panel } from "components/Panel";
import { useAgent } from "context/agentContext";
import { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import GridHeader from "./components/GridHeader";
import GridRow from "./components/GridRow";
import GridToolbar from "./components/GridToolbar";
import PayoutFilters from "./components/PayoutFilters";
import { defaultGridProps } from "./constants";
import { strings } from "./strings";
import { enhancePayout } from "./utils";
import { exists } from "utils";

const PayoutGrid = () => {
  // @Variables
  const { agent } = useAgent();
  const { openModal, setData, setModalConfig, setTotalCount, update } = useContext(GridContext);

  const { filterParams } = useFilter();
  const { isLoading, data, isFetching, refetch } = useQuery(
    ["getPayouts", filterParams],
    async () => {
      const data = await getAllPayouts({
        currentPage: filterParams.currentPage,
        pageSize: filterParams.pageSize,
        sortField: filterParams.sortField,
        sortDir: filterParams.sortDir,
        filterMap: {
          ...(filterParams.f_status && { status: filterParams.f_status }),
          ...(filterParams.f_integrator && { integrator: filterParams.f_integrator }),
        },
        ctx: agent,
      });
      data.payload.payouts = await Promise.all(
        data.payload.payouts.map((payout) => enhancePayout(payout, agent))
      );
      return data;
    },
    {
      refetchOnMount: "always",
      keepPreviousData: true,
      onSuccess: (data) => {
        setData(data.payload.payouts);
        setTotalCount(data.payload.total);
      },
      onError: (error) => {
        setModalConfig({
          content: error.message,
          titleText: strings.error_title,
        });
        openModal();
      },
    }
  );

  useEffect(() => {
    if (update > 0) {
      refetch();
    }
  }, [update, refetch]);

  return (
    <Panel>
      <PayoutFilters />
      {isFetching && <GridLoading key="grid-loading" />}

      {!exists(data?.payload?.payouts) && !isLoading && <NoData key="grid-no-data" />}
      {exists(data?.payload?.payouts) && (
        <>
          <GridToolbar />
          <GridHeader />
          <div>
            {data?.payload?.payouts.map((record) => (
              <GridRow key={record.payout_status_id} payout={record} />
            ))}
          </div>
        </>
      )}
    </Panel>
  );
};

function PayoutGridWithProvider() {
  return (
    <GridProvider {...defaultGridProps}>
      <PayoutGrid />
    </GridProvider>
  );
}

export default PayoutGridWithProvider;
