import React from "react";
import * as R from "ramda";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { Link } from "components/Link";
import { theme } from "styles";
import { exists } from "utils";
import { TopBox } from "components/Box";

export const Box = styled(TopBox)`
  padding: 4px;
`;

const shouldHighlight = (filter, children) => exists(filter) && R.test(filter, children);

const InternalLink = ({ item, filter, to, ...props }) => {
  return (
    <Link
      to={to}
      highlight={(shouldHighlight(filter, item) || "").toString()}
      display="block"
      {...props}
    >
      <span
        css={`
          display: inline-block;
          padding: 2px 3px;
          margin: 0 -3px;
          border-radius: 2px;
        `}
      >
        {item}
      </span>
    </Link>
  );
};

export const AgentLink = ({ agent, ...props }) => {
  return (
    <InternalLink
      item={`${agent.integrator} / ${agent.username}`}
      to={`/account/${agent.account_id}`}
      {...props}
    />
  );
};

export const PermissionList = ({ data = [], filter }) => (
  <div>
    {R.pipe(
      R.sortBy(R.identity),
      R.map((perm) => (
        <InternalLink
          key={perm}
          item={perm}
          to={`/permissions/by-permission/${perm}`}
          filter={filter}
        />
      ))
    )(data)}
  </div>
);

export const GroupList = ({ data = [], filter }) => (
  <div>
    {R.pipe(
      R.sortBy(R.identity),
      R.map((group) => (
        <InternalLink
          key={group}
          item={group}
          to={`/permissions/by-group/${group}`}
          filter={filter}
        />
      ))
    )(data)}
  </div>
);

export const HighlightFilter = ({ children, filter }) => {
  const addHighlight = shouldHighlight(filter, children);
  return (
    <span
      css={`
        ${addHighlight &&
        css`
          color: ${theme("colors.highlight.color")};
          background: ${theme("colors.highlight.bg")};
        `}
        padding: 2px 3px;
        margin: 0 -3px;
        border-radius: 2px;
      `}
    >
      {children}
    </span>
  );
};
