import { PayoutStatusCode } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the payout status code.
 *
 * @param {PayoutStatusCode} payoutStatusCode
 * @returns {JSX.Element}
 */
function PayoutStatusCodeName({ payoutStatusCode }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (payoutStatusCode) {
    case PayoutStatusCode.OPEN:
      return <>Open</>;
    case PayoutStatusCode.IN_ESCROW:
      return <>In Escrow</>;
    case PayoutStatusCode.ESCROW_FAILED:
      return <>Escrow Failed</>;
    case PayoutStatusCode.TILIA_ON_HOLD:
      return <>Tilia On-Hold</>;
    case PayoutStatusCode.PROVIDER_ON_HOLD:
      return <>Provider On-Hold</>;
    case PayoutStatusCode.PAYOUT_REQUESTED:
      return <>Payout Requested</>;
    case PayoutStatusCode.CREDIT_EXECUTED:
      return <>Credit Executed</>;
    case PayoutStatusCode.SUCCESS:
      return <>Success</>;
    case PayoutStatusCode.FAILED:
      return <>Failed</>;
    case PayoutStatusCode.USER_REVERSE_REQUESTED:
      return <>User Reverse Requested</>;
    case PayoutStatusCode.USER_REVERSED:
      return <>User Reversed</>;
    case PayoutStatusCode.USER_REVERSE_FAILED:
      return <>User Reverse Failed</>;
    case PayoutStatusCode.SUPPORT_REJECT_REQUESTED:
      return <>Support Reject Requested</>;
    case PayoutStatusCode.SUPPORT_REJECTED:
      return <>Support Rejected</>;
    case PayoutStatusCode.SUPPORT_REJECT_FAILED:
      return <>Support Reject Failed</>;
    case PayoutStatusCode.SYSTEM_REJECTED:
      return <>System Rejected</>;
    case PayoutStatusCode.SYSTEM_REJECT_FAILED:
      return <>System Reject Failed</>;
    default:
      return <>{payoutStatusCode}</>;
  }
}

export default PayoutStatusCodeName;
