import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { theme } from "styles";
import { ErrorMessage as FormikErrorMessage } from "formik";

const Filler = styled.div`
  height: 25px;
`;

// this is the way
export const ErrorMessage = ({ name }) => {
  return (
    <div
      css={`
        color: ${theme("colors.error")};
      `}
      data-testid="error"
    >
      <FormikErrorMessage name={name} />
    </div>
  );
};

// legacy - use the version above.
// This one unnecessarily reimplements the touched logic
export const Error = ({ error, touched, ...rest }) => {
  if (!error || !touched) return <Filler />;
  return (
    <div
      css={`
        margin-top: 4px;
        color: ${theme("colors.error")};
      `}
      data-testid="error"
      {...rest}
    >
      {error}
    </div>
  );
};
