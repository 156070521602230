import * as R from "ramda";

export const ROUTE_BASENAME = "/ui";
export const TOOLS_URL = window.location.origin;

export const ENVIRONMENT = {
  PROD: 0,
  STAGING: 1,
  STAGING_TEST: 2,
  DEV: 3,
};
export const ENVIRONMENT_NAMES = {
  0: "production",
  1: "staging",
  2: "staging-test",
  3: "dev",
};

export const getEnvironment = (host = window.location.host) => {
  if (/dev.tilia-inc/.test(host)) {
    return ENVIRONMENT.DEV;
  } else if (/test.staging.tilia-inc/.test(host)) {
    return ENVIRONMENT.STAGING_TEST;
  } else if (/staging.tilia-inc/.test(host)) {
    return ENVIRONMENT.STAGING;
  } else {
    return ENVIRONMENT.PROD;
  }
};

export const isProd = (host = window.location.host) =>
  R.equals(getEnvironment(host), ENVIRONMENT.PROD);
