import useAgentAccountId from "context/useAgentAccountId";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import {
  READ_SETTLEMENTS,
  WRITE_SETTLEMENTS_APPROVE,
  WRITE_SETTLEMENTS_REVIEW,
  WRITE_SETTLEMENTS_SUPER_APPROVE,
} from "context/permissions";
import { SettlementApprovalType, SettlementStatusCode } from "./settlementTypes";
import useSettlementStatusAndApprovalHistory from "./useSettlementStatusAndApprovalHistory";
import agentApprovedSettlement from "./agentApprovedSettlement";

const typeMap = {
  [SettlementApprovalType.REVIEW]: {
    permissions: [READ_SETTLEMENTS, WRITE_SETTLEMENTS_REVIEW],
    validStatuses: [SettlementStatusCode.NEW, SettlementStatusCode.RETURNED],
  },
  [SettlementApprovalType.APPROVE]: {
    permissions: [READ_SETTLEMENTS, WRITE_SETTLEMENTS_APPROVE],
    validStatuses: [SettlementStatusCode.REVIEWED],
  },
  [SettlementApprovalType.SUPER_APPROVE]: {
    permissions: [READ_SETTLEMENTS, WRITE_SETTLEMENTS_SUPER_APPROVE],
    validStatuses: [SettlementStatusCode.APPROVED],
  },
};

/**
 * Returns true if the authenticated agent can submit the given approval type for
 * the settlement.
 *
 * @param {string} settlementId
 * @param {string} integrator
 * @param {SettlementApprovalType} approvalType
 * @returns {boolean}
 */
function useAgentCanApproveSettlement(settlementId, integrator, approvalType) {
  const agentAccountId = useAgentAccountId();
  const hasPermission = useAgentHasPermissions(typeMap[approvalType].permissions);
  const { loading, data, error } = useSettlementStatusAndApprovalHistory({
    settlementId,
    integrator,
  });

  if (!hasPermission || loading || error) {
    return false;
  }

  if (
    !data.settlement ||
    !typeMap[approvalType].validStatuses.includes(data.settlement.settlementStatusCode)
  ) {
    return false;
  }

  // one agent cannot submit multiple approvals during the same review cycle
  return !agentApprovedSettlement(
    agentAccountId,
    data.settlement.statusHistory,
    data.settlement.approvals
  );
}

export default useAgentCanApproveSettlement;
