import * as R from "ramda";
import { isNilOrEmpty } from "utils";

const byGroupName = R.groupBy(R.prop("group_name"));
const sortByName = R.sortBy(R.identity);

const isGroup = R.propEq("group_name", "element_type");
const isSelfReferential = (g) => g.element === g.group_name;

// Recursively walks the toolsGroups to find all the accounts
// that belong to the group, either directly of through a child group.
const buildGroupAccountMap = (toolsGroups) => {
  const groupsByGroupName = byGroupName(toolsGroups);

  const groupToAccounts = R.pipe(
    R.map((g) => {
      if (!isGroup(g)) {
        return g;
      }
      if (isSelfReferential(g)) return {};
      // recurse to related group
      return groupToAccounts(groupsByGroupName[g.element] || {});
    }),
    R.flatten,
    R.filter((g) => !isNilOrEmpty(g))
  );
  return R.map(groupToAccounts)(groupsByGroupName);
};

const findAllGroups = (groupAccountMap) => (account_id) =>
  R.pipe(
    R.filter((a) => !!R.find(R.propEq(account_id, "element"))(a)),
    R.keys,
    R.uniq
  )(groupAccountMap);

const buildAccountGroupMap = (groupAccountMap) => {
  const findGroupsForAccount = findAllGroups(groupAccountMap);
  return R.pipe(
    R.values,
    R.flatten,
    R.pluck("element"),
    R.uniq,
    R.reduce((acc, id) => {
      return {
        ...acc,
        [id]: findGroupsForAccount(id),
      };
    }, {})
  )(groupAccountMap);
};

const buildGroupScopeMap = (allGroups, toolsPermissions) => {
  const groupsWithPermissions = R.pipe(
    R.groupBy(R.prop("group_name")),
    R.map(R.pluck("scope"))
  )(toolsPermissions);

  return R.reduce(
    (acc, group) => R.assoc(group, groupsWithPermissions[group] || [])(acc),
    {},
    allGroups
  );
};

const getAllGroups = (toolsGroups) => {
  const parentGroups = R.map(R.prop("group_name"))(toolsGroups);
  const childGroups = R.pipe(
    R.filter(R.propEq("group_name", "element_type")),
    R.map(R.prop("element"))
  )(toolsGroups);
  return R.union(parentGroups, childGroups);
};

// get all the (parent) group_names where group is the element
const getGroupParents = (group, toolsGroups) =>
  R.pipe(R.filter(R.propEq(group, "element")), R.map(R.prop("group_name")))(toolsGroups);

// get all the elements where element_type is group_name
const getGroupChildren = (group, toolsGroups) =>
  R.pipe(
    R.filter(R.propEq(group, "group_name")),
    R.filter(R.propEq("group_name", "element_type")),
    R.map(R.prop("element"))
  )(toolsGroups);

const buildGroupGroupMap = (toolsGroups) => {
  return R.reduce(
    (acc, group) => ({
      ...acc,
      [group]: {
        parents: getGroupParents(group, toolsGroups),
        children: getGroupChildren(group, toolsGroups),
      },
    }),
    {},
    getAllGroups(toolsGroups)
  );
};

const buildAccountScopeMap = (accountGroupMap, groupScopeMap) =>
  R.map((groups) =>
    R.pipe(
      R.map((group) => groupScopeMap[group]),
      R.flatten,
      R.filter(R.identity),
      R.uniq
    )(groups)
  )(accountGroupMap);

const buildScopeAccountMap = (accountScopeMap) => {
  // pull out each scope, uniq, then aggregate all accounts with that scope
  const findAllAccountsWithScope = (scope) =>
    R.pipe(R.filter(R.includes(scope)), R.keys, R.uniq)(accountScopeMap);

  return R.pipe(
    R.values,
    R.flatten,
    R.uniq,
    R.filter(R.identity),
    R.sort(R.prop("id")),
    R.reduce(
      (acc, scope) => ({
        ...acc,
        [scope]: findAllAccountsWithScope(scope),
      }),
      {}
    )
  )(accountScopeMap);
};

const buildScopeGroupMap = (groupScopeMap) => {
  const findAllGroupsWithScope = (scope) =>
    R.pipe(R.filter(R.includes(scope)), R.keys, R.uniq)(groupScopeMap);

  return R.pipe(
    R.values,
    R.flatten,
    R.uniq,
    R.filter(R.identity),
    R.reduce(
      (acc, scope) => ({
        ...acc,
        [scope]: findAllGroupsWithScope(scope),
      }),
      {}
    )
  )(groupScopeMap);
};

const buildAccountGroupDirectMap = R.pipe(
  R.filter(R.propEq("account_id", "element_type")),
  R.groupBy(R.prop("element")),
  R.map(R.pluck("group_name"))
);

const getAllAccountIds = R.pipe(
  R.filter(R.propEq("account_id", "element_type")),
  R.pluck("element"),
  R.uniq
);

const getGroupsByAccount = (accountId, toolsGroups) => {
  // element can be an accountId or a groupName
  const getGroupsForElement = (element) =>
    R.pipe(
      R.filter(R.propEq(element, "element")),
      R.filter(R.complement(isSelfReferential)),
      R.pluck("group_name")
    )(toolsGroups);

  const getInheritedGroups = (groups, acc = []) => {
    const ancestors = R.pipe(R.map(getGroupsForElement), R.flatten)(groups);
    if (isNilOrEmpty(ancestors)) return acc;
    return getInheritedGroups(ancestors, acc.concat(ancestors));
  };

  const direct = getGroupsForElement(accountId);
  const inherit = getInheritedGroups(direct);
  return { direct, inherit };
};

const getScopesWithGroup = (groups, groupScopeMap) => {
  const getScopes = (group) => R.defaultTo([], R.prop(group, groupScopeMap));

  const buildScopesWithGroup = R.map((group) =>
    R.map((scope) => ({ scope, group }))(getScopes(group))
  );

  const combinedGroups = R.concat(groups.direct, groups.inherit);

  return R.pipe(buildScopesWithGroup, R.flatten, R.sortBy(R.prop("scope")))(combinedGroups);
};

/*
* by account:
*
*
keyed by account_id
{
  <account_id>: {
    scopes: [
      {scope, group}
    ],
    groups: {
      direct: []
      inherit: []
    }
  },
}

*/
const buildByAccount = (toolsGroups, toolsPermissions, groupScopeMap) =>
  R.pipe(
    getAllAccountIds,
    R.reduce((acc, accountId) => {
      const groups = getGroupsByAccount(accountId, toolsGroups);
      return R.assoc(
        accountId,
        {
          groups,
          scopes: getScopesWithGroup(groups, groupScopeMap),
        },
        acc
      );
    }, {})
  )(toolsGroups);

const buildAllGroups = (toolsGroups) => {
  const allByGroupName = R.pluck("group_name", toolsGroups);
  const allByElement = R.pipe(
    R.filter(R.propEq("group_name", "element_type")),
    R.pluck("element")
  )(toolsGroups);
  return R.pipe(R.uniq, sortByName)([...allByGroupName, ...allByElement]);
};

export const buildPermissionsModel = (
  toolsGroups = [],
  toolsPermissions = [],
  allScopes = { scopes: [] }
) => {
  const allGroups = buildAllGroups(toolsGroups);
  const groupAccountMap = buildGroupAccountMap(toolsGroups);
  const accountGroupDirectMap = buildAccountGroupDirectMap(toolsGroups);
  const accountGroupMap = buildAccountGroupMap(groupAccountMap);
  const groupScopeMap = buildGroupScopeMap(allGroups, toolsPermissions);
  const groupGroupMap = buildGroupGroupMap(toolsGroups);
  const accountScopeMap = buildAccountScopeMap(accountGroupMap, groupScopeMap);
  const scopeAccountMap = buildScopeAccountMap(accountScopeMap);
  const scopeGroupMap = buildScopeGroupMap(groupScopeMap);

  const byAccount = buildByAccount(toolsGroups, toolsPermissions, groupScopeMap);
  return {
    // by account
    byAccount,
    accountGroupMap,
    accountGroupDirectMap,
    accountScopeMap,
    // bygroup
    groupAccountMap,
    groupScopeMap,
    groupGroupMap,
    //by scope
    scopeAccountMap,
    scopeGroupMap,
    scopes: allScopes.scopes,
    // lists
    allGroups,
  };
};
