import dayjs from "dayjs";
import * as R from "ramda";
import qs from "qs";

export const getSessionJWT = (queryString) => {
  return R.prop("jwt", qs.parse(queryString, { ignoreQueryPrefix: true }));
};

export const ctxWithIntegrator = (integrator, ctx) =>
  R.assocPath(["integratorContext", "integrator"], integrator, ctx);

export const isTokenExpired = (token) => {
  if (!token || !token.exp) return true;
  const expiration = dayjs().unix();
  return token.exp <= expiration;
};
