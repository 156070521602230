import { TokenPurchaseStatusCode } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the token purchase status code.
 *
 * @param {TokenPurchaseStatusCode} tokenPurchaseStatusCode
 * @returns {JSX.Element}
 */
function TokenPurchaseStatusCodeName({ tokenPurchaseStatusCode }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (tokenPurchaseStatusCode) {
    case TokenPurchaseStatusCode.OPEN:
      return <>Open</>;
    case TokenPurchaseStatusCode.PROCESSING:
      return <>Processing</>;
    case TokenPurchaseStatusCode.SUCCESS:
      return <>Success</>;
    case TokenPurchaseStatusCode.FAILED:
      return <>Failed</>;
    default:
      return <>{tokenPurchaseStatusCode}</>;
  }
}

export default TokenPurchaseStatusCodeName;
