import { Tag } from "@chakra-ui/react";

function AccountTag({ tagName }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  const getTagName = (tag) => {
    switch (tag) {
      case "entity":
        return <>Entity</>;
      case "entity_agent":
        return <>Entity Agent</>;
      case "payout_review_required":
        return <>Payout Review Required</>;
      default:
        return null;
    }
  };
  if (getTagName(tagName)) {
    return <Tag variant="solid">{getTagName(tagName)}</Tag>;
  } else {
    return null;
  }
}

export default AccountTag;
