import * as R from "ramda";
import { debug, isNilOrEmpty } from "utils";
import { METHODS, assertIntegrator, assertOk, mapProxyFetch, serviceMap } from "./utils";

const piiPubProxy = mapProxyFetch(serviceMap.PII_API);
const piiOpsProxy = mapProxyFetch(serviceMap.PII_OPS_API);
const debugApi = (ctx, fn, ...args) => debug(ctx, `[api/pii/${fn}]`, ...args);

export const PIITypes = {
  DOB: "dob",
  Name: "name",
  Address: "address",
};

export const getPII = async (accountId, piiType, ctx) => {
  debugApi(ctx, "getPII", accountId, piiType);
  assertIntegrator(ctx);
  const response = await piiOpsProxy(ctx, `/${accountId}/${piiType}`);
  await assertOk(response);
  return response.json();
};

export const getPIIByID = async (accountId, piiType, piiID, ctx) => {
  debugApi(ctx, "getPIIByID", accountId, piiType, piiID);
  assertIntegrator(ctx);
  const response = await piiOpsProxy(ctx, `/${accountId}/${piiType}/${piiID}`);
  await assertOk(response);
  return response.json();
};
export const getKYC = async (accountId, ctx) => {
  debugApi(ctx, "getKYC", accountId);
  assertIntegrator(ctx);
  const response = await piiOpsProxy(ctx, `/kyc/${accountId}`);
  await assertOk(response);
  return response.json();
};

export const getKYCPII = async (accountId, ctx) => {
  debugApi(ctx, "getKYCPII", accountId);
  assertIntegrator(ctx);
  const response = await piiOpsProxy(ctx, `/kyc/${accountId}/current`);
  await assertOk(response);
  return response.json();
};

export const getKYCPIICollectionUri = async (accountId, params, ctx) => {
  debugApi(ctx, "getKYCPIICollectionUri", accountId, params);
  assertIntegrator(ctx);
  const response = await piiOpsProxy(ctx, `/kyc/${accountId}`, METHODS.POST, {
    form_type: params.form_type,
    initiator: params.agentId,
    note: params.note,
  });

  await assertOk(response);
  return response.json();
};

// no header needed?
export const lookupApplicationId = async (applicationId, ctx) => {
  debugApi(ctx, "lookupApplicationId", applicationId);
  const response = await piiOpsProxy(ctx, `/kyc/${applicationId}/lookup`);
  await assertOk(response);
  return response.json();
};

export const getKYCFile = async (accountId, fileId, ctx) => {
  debugApi(ctx, "getKYCFile", accountId, fileId);
  assertIntegrator(ctx);
  const response = await piiOpsProxy(ctx, `/kyc/${accountId}/file/${fileId}`);
  await assertOk(response);
  return response.json();
};

export const postKYCFile = async (kycId, file, ctx) => {
  debugApi(ctx, "postKYCFile", kycId);
  assertIntegrator(ctx);
  const formData = new FormData();
  formData.append("file", file);

  const response = await piiOpsProxy(
    ctx,
    `/kyc/${kycId}/file`,
    METHODS.POST,
    null,
    R.pipe(R.dissocPath(["headers", "Content-Type"]), R.assocPath(["body"], formData)),
  );
  await assertOk(response);
  return response.json();
};

export const postSendKYCEmail = async (accountId, ctx) => {
  debugApi(ctx, "postSendKYCEmail", accountId);
  assertIntegrator(ctx);
  /* The reason for these exclusions is not well documented. The primary reason is the client is
   sensitive to UI changes. */
  const V2_PUBLISHER_EXCLUSIONS = ["sansar", "sl"];
  const useV1Endpoint = V2_PUBLISHER_EXCLUSIONS.includes(ctx.integratorContext.integrator);
  let response;
  if (useV1Endpoint) {
    response = await piiOpsProxy(ctx, `/kyc/${accountId}/email`, METHODS.POST);
  } else {
    response = await piiPubProxy(ctx, `/v2/kyc/account/${accountId}/email`, METHODS.POST);
  }
  await assertOk(response);
  return response.json();
};

export const updateKYCPII = async (accountId, piiData, ctx) => {
  debugApi(ctx, "updateKYCPII", accountId, piiData);
  assertIntegrator(ctx);
  const response = await piiPubProxy(ctx, `/kyc/account/${accountId}`, METHODS.PUT, {
    first_name: piiData.first,
    last_name: piiData.last,
    street: piiData.street,
    city: piiData.city,
    state: piiData.state,
    country: piiData.country,
    zip: piiData.zip,
    phone_code: piiData.phoneCode,
    phone: piiData.phone,
    date_of_birth: piiData.dob,
    ...(!isNilOrEmpty(piiData.ssn) && { ssn: piiData.ssn.replace(/-/g, "") }),
  });
  await assertOk(response);
  return response.json();
};

export const getGDPRDocuments = async (accountId, ctx) => {
  debugApi(ctx, "getGDPRDocuments", accountId);
  assertIntegrator(ctx);
  const response = await piiOpsProxy(ctx, `/${accountId}/gdpr-document`);
  await assertOk(response);
  return response.json();
};
