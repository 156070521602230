import React, { useContext } from "react";
import { ColumnHeader, GridContext, withGrid, NoData } from "components/Grid";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { SORT_DIRECTION, sortBy } from "components/Grid/utils";
import { theme, ifProp } from "styles";
import { formatDate, formatDateTime, isNilOrEmpty } from "utils";
import { formatEmptyData } from "utils/format";
import { AccountLink } from "components/Account";
import { Panel } from "components/Panel";

export const row = css`
  padding 8px 0;
  border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
`;

const Grid = styled.div`
  padding-top: 16px;
  overflow: auto;
`;

export const commonRow = css`
  align-items: center;
  border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
  display: grid;
  overflow-wrap: anywhere;
  grid-template-columns: 2fr 2fr 3fr 1fr 1fr 1fr;
  > div,
  > span {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

const RowContainer = styled.div`
  ${commonRow};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: ${ifProp("isExpandable", "pointer")};
  background-color: ${ifProp("isExpanded", "#ebf4fd")};
  :hover {
    background-color: ${ifProp("isExpandable", "#ebf4fd")};
  }
`;

const Container = styled.div`
  ${commonRow};
  padding-bottom: 8px;
`;

export const WatchlistResults = withGrid(
  ({ results }) => {
    const gridApi = useContext(GridContext);
    return (
      <Panel>
        {isNilOrEmpty(results) ? (
          <NoData />
        ) : (
          <Grid key="grid" data-testid="grid">
            <GridHeader key="grid-header" />
            <div data-testid="grid-body" key="grid-body">
              {sortBy(results, gridApi.sortField, gridApi.sortDir).map((record, index) => (
                <GridRow key={index} record={record} />
              ))}
            </div>
          </Grid>
        )}
      </Panel>
    );
  },
  { sortField: "created", sortDir: SORT_DIRECTION.DESC }
);

const GridHeader = () => {
  return (
    <Container data-testid="grid-header">
      {[
        { field: "created", content: "Open Type", sortable: true },
        { field: "status", content: "Status", sortable: true },
        { field: "account_id", content: "Account Id" },
        { field: "integrator", content: "Publisher", sortable: true },
        { field: "sanction_list", content: "Sanction List" },
        { field: "list_version", content: "List Version" },
      ].map(({ field, content, sortable }, index) => (
        <ColumnHeader key={`header-${field || index}`} field={field} sortable={sortable}>
          {content}
        </ColumnHeader>
      ))}
    </Container>
  );
};

const GridRow = ({ record }) => {
  const displayInfo = (data) => <span>{formatEmptyData(data)}</span>;
  return (
    <RowContainer data-testid="transaction-row">
      <div>{displayInfo(formatDateTime(record.updated))}</div>
      <div>{displayInfo(record.status)}</div>
      <div>
        <AccountLink account={record} newTab={true}>
          {record.account_id}
        </AccountLink>
      </div>
      <div>{displayInfo(record.integrator)}</div>
      <div>{displayInfo(record.sanction_list)}</div>
      <div>{displayInfo(formatDate(record.list_version))}</div>
    </RowContainer>
  );
};
