import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  StackItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { CopyableText } from "components/Copyable";
import { DetailText } from "components/Text";

function PayoutIDs({ payoutStatusId, creditId }) {
  const gray600 = useColorModeValue("gray.600", "gray.200");

  return (
    <Card>
      <CardHeader>
        <Heading m={0} size="xs" color={gray600} as="h4">
          PAYOUT IDs
        </Heading>
      </CardHeader>
      <CardBody>
        <Stack>
          {payoutStatusId && (
            <StackItem as="dl" margin={0}>
              <Text as="dt">Payout status ID</Text>
              <DetailText>
                <CopyableText text={payoutStatusId}>{payoutStatusId}</CopyableText>
              </DetailText>
            </StackItem>
          )}
          {creditId && (
            <StackItem as="dl" margin={0}>
              <Text as="dt">Credit ID</Text>
              <DetailText>
                <CopyableText text={creditId}>{creditId}</CopyableText>
              </DetailText>
            </StackItem>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
}

export default PayoutIDs;
