import { BalanceTransferType } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the balance transfer type.
 *
 * @param {BalanceTransferType} balanceTransferType
 * @returns {JSX.Element}
 */
function BalanceTransferTypeName({ balanceTransferType }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (balanceTransferType) {
    case BalanceTransferType.CLAWBACK:
      return <>Clawback</>;
    case BalanceTransferType.DEPOSIT:
      return <>Deposit</>;
    case BalanceTransferType.FACILITATOR_CURRENCY_SWAP:
      return <>Token Exchange</>;
    case BalanceTransferType.FUND_INTEGRATOR:
      return <>Transfer to Publisher</>;
    case BalanceTransferType.FUND_TILIA:
      return <>Transfer to Tilia</>;
    case BalanceTransferType.FUND_USER:
      return <>Ledger Transfer</>;
    case BalanceTransferType.MERGE_ACCOUNT:
      return <>Merge Account Token Exchange</>;
    case BalanceTransferType.SETTLEMENT:
      return <>Settlement</>;
    case BalanceTransferType.TOKEN_CONVERT_PAYOUT_REVERSAL:
      return <>Canceled Cash Out Credit</>;
    default:
      return <>{balanceTransferType}</>;
  }
}

export default BalanceTransferTypeName;
