/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { SettlementDetailType } from "./settlementTypes";

/**
 * Renders the localized human-readable name of the settlement detail type.
 *
 * @param {SettlementDetailType} type
 * @returns {JSX.Element}
 */
function SettlementDetailTypeName({ type }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (type) {
    case SettlementDetailType.GROSS_FUNDED_REVENUE:
      return <>Gross Funded Revenue</>;
    case SettlementDetailType.GROSS_FUNDED_REVENUE_CORRECTION:
      return <>Gross Funded Revenue Correction</>;
    case SettlementDetailType.MINIMUM_MONTHLY_FEE:
      return <>Minimum Monthly Fee</>;
    case SettlementDetailType.TAX_SERVICE_FEE:
      return <>Tax Service Fee</>;
    case SettlementDetailType.TAX_SERVICE_FEE_CORRECTION:
      return <>Tax Service Fee Correction</>;
    case SettlementDetailType.TRANSACTION_PROCESSING_FEE:
      return <>Transaction Processing Fee</>;
    case SettlementDetailType.TRANSACTION_PROCESSING_FEE_CORRECTION:
      return <>Transaction Processing Fee Correction</>;
    case SettlementDetailType.FIAT_LEDGER_TRANSACTION_FEE:
      return <>Fiat Ledger Transaction Fee</>;
    case SettlementDetailType.FIAT_LEDGER_TRANSACTION_FEE_CORRECTION:
      return <>Fiat Ledger Transaction Correction</>;
    case SettlementDetailType.VIRTUAL_LEDGER_TRANSACTION_FEE:
      return <>Virtual Ledger Transaction Fee</>;
    case SettlementDetailType.VIRTUAL_LEDGER_TRANSACTION_FEE_CORRECTION:
      return <>Virtual Ledger Transaction Correction</>;
    case SettlementDetailType.REFUND:
      return <>Refund</>;
    case SettlementDetailType.REFUND_CORRECTION:
      return <>Refund Correction</>;
    case SettlementDetailType.REFUND_FEE:
      return <>Refund Fee</>;
    case SettlementDetailType.REFUND_FEE_CORRECTION:
      return <>Refund Fee Correction</>;
    case SettlementDetailType.CHARGEBACK:
      return <>Chargeback</>;
    case SettlementDetailType.CHARGEBACK_CORRECTION:
      return <>Chargeback Correction</>;
    case SettlementDetailType.CHARGEBACK_FEE:
      return <>Chargeback Fee</>;
    case SettlementDetailType.CHARGEBACK_FEE_CORRECTION:
      return <>Chargeback Fee Correction</>;
    default:
      return <>{type}</>;
  }
}

export default SettlementDetailTypeName;
