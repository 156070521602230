import React from "react";
import * as R from "ramda";
import "styled-components/macro";
import { useParams } from "react-router-dom";
import { Panel } from "components/Panel";
import { isNilOrEmpty } from "utils";
import { Box, PermissionList, AgentLink, GroupList } from "./common";
import { useTitle } from "context/titleContext";

export const ByAgent = ({ data, filter }) => {
  const { agentId } = useParams();
  const { agentMap, permissionsModel } = data;
  // sort by the username, not the id
  const agentIds = R.sortBy((k) => agentMap[k].username, R.keys(agentMap));

  useTitle({ sub1: "By Agent", sub2: agentId });
  return (
    <Panel>
      <div
        css={`
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 4px;
        `}
      >
        <Box>Agent</Box>
        <Box>Permissions</Box>
        <Box>Groups (Direct)</Box>
        {R.pipe(
          R.filter((id) => isNilOrEmpty(agentId) || agentId === id),
          R.filter((agentId) => {
            if (isNilOrEmpty(filter)) return true;
            const scopes = permissionsModel.accountScopeMap[agentId] || [];
            const groups = permissionsModel.accountGroupDirectMap[agentId] || [];
            return R.any(R.test(filter))([agentMap[agentId].username, ...scopes, ...groups]);
          }),
          R.map((agentId) => (
            <div key={agentId} css="display: contents;">
              <Box>
                <AgentLink agent={agentMap[agentId]} filter={filter} />
              </Box>
              <Box>
                <PermissionList data={permissionsModel.accountScopeMap[agentId]} filter={filter} />
              </Box>
              <Box>
                <GroupList data={permissionsModel.accountGroupDirectMap[agentId]} filter={filter} />
              </Box>
            </div>
          ))
        )(agentIds)}
      </div>
    </Panel>
  );
};
