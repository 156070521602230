import { Text } from "@chakra-ui/react";
import { AmericanExpress, MasterCard, Paypal, Visa } from "components/Logos";

function PaymentMethodLogo({ methodClass }) {
  switch (methodClass) {
    case "visa":
      return <Visa />;
    case "paypal":
      return <Paypal />;
    case "american-express":
      return <AmericanExpress />;
    case "master-card":
      return <MasterCard />;
    default:
      return <Text as="span">{methodClass}</Text>;
  }
}

export default PaymentMethodLogo;
