import { PayoutStatusCode, TransactionType } from "@tilia-tools/core/transaction";
import { TransactionHeader } from "../Layout";
import PayoutStatusCodeName from "./PayoutStatusCodeName";

const statusColors = {
  [PayoutStatusCode.OPEN]: "orange",
  [PayoutStatusCode.IN_ESCROW]: "orange",
  [PayoutStatusCode.ESCROW_FAILED]: "red",
  [PayoutStatusCode.TILIA_ON_HOLD]: "orange",
  [PayoutStatusCode.PROVIDER_ON_HOLD]: "orange",
  [PayoutStatusCode.PAYOUT_REQUESTED]: "orange",
  [PayoutStatusCode.CREDIT_EXECUTED]: "orange",
  [PayoutStatusCode.SUCCESS]: "green",
  [PayoutStatusCode.FAILED]: "red",
  [PayoutStatusCode.USER_REVERSE_REQUESTED]: "orange",
  [PayoutStatusCode.USER_REVERSED]: "gray",
  [PayoutStatusCode.USER_REVERSE_FAILED]: "red",
  [PayoutStatusCode.SUPPORT_REJECT_REQUESTED]: "orange",
  [PayoutStatusCode.SUPPORT_REJECTED]: "red",
  [PayoutStatusCode.SUPPORT_REJECT_FAILED]: "red",
  [PayoutStatusCode.SYSTEM_REJECTED]: "red",
  [PayoutStatusCode.SYSTEM_REJECT_FAILED]: "red",
};

function PayoutHeader({ payout }) {
  return (
    <TransactionHeader
      title="Payout"
      transactionType={TransactionType.PAYOUT}
      badge={{
        label: <PayoutStatusCodeName payoutStatusCode={payout.statusCode} />,
        color: statusColors[payout.statusCode],
      }}
      copyableId={payout.payoutStatusId}
      date={payout.date}
      updatedDate={payout.updatedDate}
    />
  );
}

export default PayoutHeader;
