import { debug } from "utils";
import { METHODS, assertIntegrator, mapProxyFetch, serviceMap } from "./utils";
import buildTiliaApiResponse from "./buildTiliaApiResponse";
import { READ_ACCOUNT_NOTES } from "../context/permissions";

const notesProxy = mapProxyFetch(serviceMap.NOTES_API);

export const createAccountNote = async (noteData, ctx) => {
  debug(ctx, "[createAccountNote]");
  assertIntegrator(ctx);

  // scopes for a note are done on a note by note basis
  // for now it seems safe to assume that any note created
  // via this function will have the "read_account_notes" scope
  const scopes = {
    tilia: {
      any: [READ_ACCOUNT_NOTES],
    },
    integrator: {
      any: [READ_ACCOUNT_NOTES],
    },
  };

  const response = await notesProxy(ctx, `/v1/note`, METHODS.POST, {
    author_username: ctx.username,
    author_account_id: ctx.account_id,
    account_id: noteData.accountId,
    reference_type: "lindenaccounts.accounts_v2.account_id",
    reference_id: noteData.accountId,
    subject: noteData.subject,
    body: noteData.note,
    scopes: scopes,
  });
  return buildTiliaApiResponse(response);
};
