import React, { useState } from "react";
import "styled-components/macro";
import { useNavigate, useLocation } from "react-router-dom";
import { isNilOrEmpty } from "utils";
import { Text, Button } from "components/Forms";

export const IntegratorPrompt = () => {
  const [int, setInt] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (!isNilOrEmpty(int)) {
      const sanitizedInt = int.trim();
      navigate(`/login/${sanitizedInt}${location.search}`);
    }
  };
  const handleKeyPress = (e) => {
    if (e?.key === "Enter") handleClick();
  };
  return (
    <div>
      <div
        css={`
          font-size: 1em;
          margin-bottom: 8px;
        `}
      >
        Please navigate to your company-specific login url or enter the name of your company below.
      </div>
      <div>
        <Text
          autoFocus
          data-testid="input-integrator"
          css={`
            width: 50%;
            min-width: 200px;
          `}
          value={int}
          onChange={(e) => {
            setInt(e.target.value);
          }}
          onKeyPress={handleKeyPress}
        />
        <Button
          disabled={isNilOrEmpty(int)}
          css={`
            margin: 0;
          `}
          onClick={handleClick}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
