import { isAfter, parseISO } from "date-fns";
import { SettlementStatusCode } from "./settlementTypes";

/**
 * Filters and returns the approvals array to only include approvals recorded during
 * the current (most recent) review cycle.
 *
 * @param {SettlementStatus[]} statusHistory
 * @param {SettlementApproval[]} approvals
 * @returns {SettlementApproval[]}
 */
function approvalsForCurrentReviewCycle(statusHistory, approvals) {
  if (approvals.length < 1 || statusHistory.length < 1) {
    return [];
  }

  // find the most recent new or returned point to determine which review cycle to consider
  const history = statusHistory
    .filter((status) => {
      return (
        status.settlementStatusCode === SettlementStatusCode.NEW ||
        status.settlementStatusCode === SettlementStatusCode.RETURNED
      );
    })
    .sort((a, b) => {
      const aDatetime = parseISO(a.startDatetime);
      const bDatetime = parseISO(b.startDatetime);
      return isAfter(aDatetime, bDatetime) ? -1 : 1; // reverse-chronological
    });
  if (history.length < 1) {
    return [];
  }
  const cycleStartDatetime = parseISO(history[0].startDatetime);

  return approvals.filter((approval) => {
    const approvalDatetime = parseISO(approval.approvalDatetime);
    return isAfter(approvalDatetime, cycleStartDatetime);
  });
}

export default approvalsForCurrentReviewCycle;
