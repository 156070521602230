import React from "react";
import styled from "styled-components/macro";
import * as R from "ramda";
import { Link, useLocation } from "react-router-dom";
import { css, theme, withTheme, cssHoverPointer, title, isDesktop, useMediaQuery } from "styles";
import { useAgent } from "context/agentContext";
import * as Icons from "components/Icons";
import * as P from "context/permissions";
import { Tooltip } from "components/Tooltip";
import { isString } from "utils";

export const navRow = css`
  box-sizing: border-box;
  user-select: none;
  width: 100%;
  height: ${theme("pageHeaderHeight")};
  padding: 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const NavLink = styled(Link)`
  ${navRow}
  ${cssHoverPointer};
  color: ${theme("colors.fontInverse")};
  &:hover {
    background-color: ${theme("colors.darkHoverBg")};
  }
  &.active,
  &:active {
    background-color: ${theme("colors.darkActiveBg")};
  }
`;

const NavSubLink = styled(NavLink)`
  padding-left: 48px;
  height: auto;
`;

function NavSubItem({ subpath }) {
  const location = useLocation();
  const active = location.pathname.substring(0, subpath.path.length) === subpath.path;
  return (
    <li>
      <NavSubLink to={subpath.path} className={active ? "active" : ""}>
        {subpath.label}
      </NavSubLink>
    </li>
  );
}

function NavSubItems({ subpaths }) {
  if (subpaths.length < 1) {
    return null;
  }
  return (
    <ul>
      {subpaths.map((subpath) => (
        <NavSubItem key={subpath.path} subpath={subpath} />
      ))}
    </ul>
  );
}

const makeNavItem = (permissions, path, Icon, labelSetting, resource, subpaths = []) =>
  P.withPermissions(
    { permissions, resource },
    withTheme(({ theme, permissions }) => {
      const { agentSettings, agent } = useAgent();
      const location = useLocation();
      const desktop = useMediaQuery(isDesktop);

      if (!permissions.hasPermission) return null;

      const collapsed = R.prop("navCollapse", agentSettings);

      const showLabel = !desktop || !collapsed;
      const showTooltip = !showLabel;
      const label = isString(labelSetting) ? labelSetting : labelSetting(agent);

      let active = false;
      if (path === "/") {
        active = location.pathname === "/";
      } else if (location.pathname.substring(0, path.length) === path) {
        active = true;
      }

      return (
        <li data-testid={`sidenav-${label}`}>
          <NavLink to={path} className={active ? "active" : ""}>
            <span
              css={`
                display: flex;
                align-items: center;
                margin: 0 8px;
              `}
            >
              {showTooltip ? (
                <Tooltip data-place="right" data-effect="solid" data-tip={label}>
                  <Icon color={theme.colors.fontInverse} />
                </Tooltip>
              ) : (
                <Icon color={theme.colors.fontInverse} />
              )}
            </span>
            {showLabel && (
              <span
                css={`
                  ${title}
                `}
              >
                {label}
              </span>
            )}
          </NavLink>
          {active && !collapsed && <NavSubItems subpaths={subpaths} />}
        </li>
      );
    })
  );

export const DashboardNavItem = makeNavItem(
  [P.READ_DASHBOARD],
  "/",
  Icons.ViewDashboardIcon,
  "Dashboard"
);
export const PayoutsNavItem = makeNavItem(
  [P.READ_PROCESS_CREDITS, P.WRITE_PROCESS_CREDITS],
  "/payouts",
  Icons.CreditCardIcon,
  "Payouts",
  P.PAYOUTS_RESOURCE
);
export const KYCNavItem = makeNavItem(
  [P.READ_KYCS],
  "/kyc",
  Icons.KYCIcon,
  "KYC",
  P.KYC_DETAILS_RESOURCE
);
export const WatchlistNavItem = makeNavItem(
  [P.READ_WATCHLISTS],
  "/watchlist",
  Icons.WatchlistIcon,
  "Watchlist",
  P.WATCHLIST_RESOURCE
);
export const PermissionsNavItem = makeNavItem(
  [P.READ_PERMISSIONS],
  "/permissions",
  Icons.PermissionsIcon,
  "Permissions",
  P.PERMISSIONS_RESOURCE
);
export const PublisherNavItem = makeNavItem(
  [P.READ_INTEGRATORS],
  "/publisher",
  Icons.PublisherIcon,
  (agent) => (agent.isTilia() ? "Publishers" : "Admin"),
  P.PUBLISHER_RESOURCE
);
export const ClientsNavItem = makeNavItem(
  [P.MANAGE_CLIENTS],
  "/clients",
  Icons.ClientsIcon,
  "Clients",
  P.CLIENTS_RESOURCE
);
export const SettingsNavItem = makeNavItem(
  [P.READ_SETTINGS],
  "/settings",
  Icons.SettingsIcon,
  "Settings",
  undefined,
  [{ path: "/settings/compare", label: "Comparinator" }]
);
export const WebhooksNavItem = makeNavItem(
  [P.WRITE_WEBHOOKS],
  "/webhooks",
  Icons.WebhooksIcon,
  "Webhooks",
  P.WEBHOOKS_RESOURCE
);
export const ProductCatalogNavItem = makeNavItem(
  [P.READ_PRODUCT_CATALOG],
  "/product-catalog",
  Icons.CartIcon,
  "Product Catalog"
);
export const UserProvisioningNavItem = makeNavItem(
  [P.READ_PERMISSIONS],
  "/user-provisioning",
  Icons.UserProvisioningIcon,
  "User Provisioning"
);
export const FinanceNavItem = makeNavItem(
  [P.READ_SETTLEMENTS],
  "/finance",
  Icons.FinanceIcon,
  "Finance",
  undefined,
  [
    { path: "/finance/settlements/approval-queue", label: "Settlements Queue" },
    { path: "/finance/settlements/historical", label: "Historical Settlements" },
  ]
);
