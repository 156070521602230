import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { theme } from "styles";
import { formatDateTime } from "utils";
import { useUnbounceModal } from "./unbounceModal";
import { useAgent } from "context/agentContext";
import { WRITE_EMAILS_RESOURCE } from "context/permissions";

const Cell = styled.div`
  padding: 6px 8px;
  &:first-of-type {
    padding-left: 0;
  }
  display: flex;
  align-items: center;
`;

const Email = ({ data = [], agentHasWriteEmailPermission }) => {
  const [UnbounceModal, UnbounceButton] = useUnbounceModal();
  return (
    <div
      css={`
        display: grid;
        grid-template-columns: ${agentHasWriteEmailPermission
          ? "repeat(7, 1fr)"
          : "repeat(6, 1fr)"};
        padding: 8px 0;
        border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
      `}
    >
      <Cell>{data.email}</Cell>
      <Cell>{data.emailTypes?.join(", ")}</Cell>
      <Cell>{data.isVerified ? "V" : "NV"}</Cell>
      <Cell>{formatDateTime(data.bounceTime)}</Cell>
      <Cell>{data.bounceSource}</Cell>
      <Cell>{data.bounceReason}</Cell>
      {agentHasWriteEmailPermission && (
        <Cell data-testid={`email-action-value-cell-${data.emailId}`}>
          {data.bounceSource && (
            <>
              <UnbounceButton />
              <UnbounceModal email={data.email} />
            </>
          )}
        </Cell>
      )}
    </div>
  );
};

export const Emails = ({ data }) => {
  const { agent } = useAgent();
  const agentHasWriteEmailPermission = agent.hasPermissions([], WRITE_EMAILS_RESOURCE);
  return (
    <>
      <div
        css={`
          display: grid;
          grid-template-areas: "header-address header-type header-verified header-bounce-time header-bounce-source header-bounce-reason header-actions";
          grid-template-columns: ${agentHasWriteEmailPermission
            ? "repeat(7, 1fr)"
            : "repeat(6, 1fr)"};
          padding: 8px 0;
          border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
        `}
      >
        <Cell
          css={`
            grid-area: header-address;
          `}
        >
          Address
        </Cell>
        <Cell
          css={`
            grid-area: header-type;
          `}
        >
          Type
        </Cell>
        <Cell
          css={`
            grid-area: header-verified;
          `}
        >
          Verified
        </Cell>

        <Cell
          css={`
            grid-area: header-bounce-source;
          `}
        >
          Bounce Source
        </Cell>
        <Cell
          css={`
            grid-area: header-bounce-reason;
          `}
        >
          Bounce Reason
        </Cell>
        <Cell
          css={`
            grid-area: header-bounce-time;
          `}
        >
          Bounce Time
        </Cell>
        {agentHasWriteEmailPermission && (
          <Cell
            data-testid="email-action-header-cell"
            css={`
              grid-area: header-actions;
            `}
          >
            Actions
          </Cell>
        )}
      </div>
      <div>
        {data &&
          data.map((email) => (
            <Email
              key={email.emailId}
              data={email}
              agentHasWriteEmailPermission={agentHasWriteEmailPermission}
            />
          ))}
      </div>
    </>
  );
};
