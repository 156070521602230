import {
  InvoicingApiDepositCanceledStatusCodes,
  InvoicingApiDepositFailedStatusCodes,
  InvoicingApiDepositPendingStatusCodes,
  InvoicingApiDepositSuccessStatusCodes,
  InvoicingApiEscrowCanceledStatusCodes,
  InvoicingApiEscrowFailedStatusCodes,
  InvoicingApiEscrowPendingStatusCodes,
  InvoicingApiEscrowSuccessStatusCodes,
  InvoicingApiInvoiceCanceledStatusCodes,
  InvoicingApiInvoiceFailedStatusCodes,
  InvoicingApiInvoicePendingStatusCodes,
  InvoicingApiInvoiceSuccessStatusCodes,
  InvoicingApiPayoutCanceledStatusCodes,
  InvoicingApiPayoutFailedStatusCodes,
  InvoicingApiPayoutPendingStatusCodes,
  InvoicingApiPayoutSuccessStatusCodes,
  InvoicingApiTokenExchangeCanceledStatusCodes,
  InvoicingApiTokenExchangeFailedStatusCodes,
  InvoicingApiTokenExchangePendingStatusCodes,
  InvoicingApiTokenExchangeSuccessStatusCodes,
} from "../invoicingApi/index.js";
import {
  BalanceTransferApiCanceledStatusCodes,
  BalanceTransferApiFailedStatusCodes,
  BalanceTransferApiPendingStatusCodes,
  BalanceTransferApiSuccessStatusCodes,
} from "../balanceTransferApi/index.js";
import { TransactionStatusCode, TransactionType } from "./types/transactionTypes.js";
import calcAgentTransactionTypes from "./calcAgentTransactionTypes.js";

const filterStatusCodeFieldNames = new Map([
  [TransactionType.PURCHASE, "purchaseStatusCodes"],
  [TransactionType.ESCROW_PURCHASE, "escrowPurchaseStatusCodes"],
  [TransactionType.REFUND, "refundStatusCodes"],
  [TransactionType.PAYOUT, "payoutStatusCodes"],
  [TransactionType.TOKEN_PURCHASE, "tokenPurchaseStatusCodes"],
  [TransactionType.TOKEN_CONVERSION, "tokenConversionStatusCodes"],
  [TransactionType.BALANCE_TRANSFER, "balanceTransferStatusCodes"],
  [TransactionType.DEPOSIT, "depositStatusCodes"],
  [TransactionType.MAKE_GOOD, "makeGoodStatusCodes"],
]);

const pendingStatusCodes = new Map([
  [TransactionType.PURCHASE, InvoicingApiInvoicePendingStatusCodes],
  [TransactionType.ESCROW_PURCHASE, InvoicingApiEscrowPendingStatusCodes],
  [TransactionType.REFUND, InvoicingApiInvoicePendingStatusCodes],
  [TransactionType.PAYOUT, InvoicingApiPayoutPendingStatusCodes],
  [TransactionType.TOKEN_PURCHASE, InvoicingApiTokenExchangePendingStatusCodes],
  [TransactionType.TOKEN_CONVERSION, InvoicingApiTokenExchangePendingStatusCodes],
  [TransactionType.BALANCE_TRANSFER, BalanceTransferApiPendingStatusCodes],
  [TransactionType.DEPOSIT, InvoicingApiDepositPendingStatusCodes],
  [TransactionType.MAKE_GOOD, InvoicingApiInvoicePendingStatusCodes],
]);

const successStatusCodes = new Map([
  [TransactionType.PURCHASE, InvoicingApiInvoiceSuccessStatusCodes],
  [TransactionType.ESCROW_PURCHASE, InvoicingApiEscrowSuccessStatusCodes],
  [TransactionType.REFUND, InvoicingApiInvoiceSuccessStatusCodes],
  [TransactionType.PAYOUT, InvoicingApiPayoutSuccessStatusCodes],
  [TransactionType.TOKEN_PURCHASE, InvoicingApiTokenExchangeSuccessStatusCodes],
  [TransactionType.TOKEN_CONVERSION, InvoicingApiTokenExchangeSuccessStatusCodes],
  [TransactionType.BALANCE_TRANSFER, BalanceTransferApiSuccessStatusCodes],
  [TransactionType.DEPOSIT, InvoicingApiDepositSuccessStatusCodes],
  [TransactionType.MAKE_GOOD, InvoicingApiInvoiceSuccessStatusCodes],
]);

const failedStatusCodes = new Map([
  [TransactionType.PURCHASE, InvoicingApiInvoiceFailedStatusCodes],
  [TransactionType.ESCROW_PURCHASE, InvoicingApiEscrowFailedStatusCodes],
  [TransactionType.REFUND, InvoicingApiInvoiceFailedStatusCodes],
  [TransactionType.PAYOUT, InvoicingApiPayoutFailedStatusCodes],
  [TransactionType.TOKEN_PURCHASE, InvoicingApiTokenExchangeFailedStatusCodes],
  [TransactionType.TOKEN_CONVERSION, InvoicingApiTokenExchangeFailedStatusCodes],
  [TransactionType.BALANCE_TRANSFER, BalanceTransferApiFailedStatusCodes],
  [TransactionType.DEPOSIT, InvoicingApiDepositFailedStatusCodes],
  [TransactionType.MAKE_GOOD, InvoicingApiInvoiceFailedStatusCodes],
]);

const canceledStatusCodes = new Map([
  [TransactionType.PURCHASE, InvoicingApiInvoiceCanceledStatusCodes],
  [TransactionType.ESCROW_PURCHASE, InvoicingApiEscrowCanceledStatusCodes],
  [TransactionType.REFUND, InvoicingApiInvoiceCanceledStatusCodes],
  [TransactionType.PAYOUT, InvoicingApiPayoutCanceledStatusCodes],
  [TransactionType.TOKEN_PURCHASE, InvoicingApiTokenExchangeCanceledStatusCodes],
  [TransactionType.TOKEN_CONVERSION, InvoicingApiTokenExchangeCanceledStatusCodes],
  [TransactionType.BALANCE_TRANSFER, BalanceTransferApiCanceledStatusCodes],
  [TransactionType.DEPOSIT, InvoicingApiDepositCanceledStatusCodes],
  [TransactionType.MAKE_GOOD, InvoicingApiInvoiceCanceledStatusCodes],
]);

/**
 * Inspects the given transaction types and status codes in the filter parameters and returns
 * a Map of valid transaction type and status code combinations to use in the filter query.
 *
 * @param {TransactionFilterInput} filter
 * @param {string[]} agentScopes
 * @returns {Map<TransactionType, Set<string>>}
 */
function calcEffectiveFilterTypesAndStatuses(filter, agentScopes) {
  const transactionTypes = new Set(Object.values(TransactionType));

  if (filter.types?.length > 0) {
    // if specific types were given, remove all other types from the set
    for (const transactionType of transactionTypes) {
      if (!filter.types.includes(transactionType)) {
        transactionTypes.delete(transactionType);
      }
    }
  } else if (filter.statusCodes?.length > 0) {
    // aggregate status codes are given, add them to all types
  } else {
    let hasOneTypeSpecificStatus = false;
    for (const filterFieldName of filterStatusCodeFieldNames.values()) {
      if (filter[filterFieldName]?.length > 0) {
        hasOneTypeSpecificStatus = true;
        break;
      }
    }
    if (hasOneTypeSpecificStatus) {
      // at least one type-specific status is present; only consider those types and statuses
      // in the loop below
      transactionTypes.clear();
    }
  }

  const typeToStatusCodesMap = new Map();

  // for the given types, begin with an empty set of status codes, which means "any status" for that type
  for (const transactionType of transactionTypes) {
    typeToStatusCodesMap.set(transactionType, new Set());
  }

  // if given, explode the aggregate status codes into the type-specific lists
  if (filter.statusCodes?.length > 0) {
    for (const transactionType of transactionTypes) {
      const typeStatusCodes = typeToStatusCodesMap.get(transactionType);
      if (filter.statusCodes.includes(TransactionStatusCode.PENDING)) {
        for (const statusCode of pendingStatusCodes.get(transactionType)) {
          typeStatusCodes.add(statusCode);
        }
      }
      if (filter.statusCodes.includes(TransactionStatusCode.SUCCESS)) {
        for (const statusCode of successStatusCodes.get(transactionType)) {
          typeStatusCodes.add(statusCode);
        }
      }
      if (filter.statusCodes.includes(TransactionStatusCode.FAILED)) {
        for (const statusCode of failedStatusCodes.get(transactionType)) {
          typeStatusCodes.add(statusCode);
        }
      }
      if (filter.statusCodes.includes(TransactionStatusCode.CANCELED)) {
        for (const statusCode of canceledStatusCodes.get(transactionType)) {
          typeStatusCodes.add(statusCode);
        }
      }
    }
  }

  // check for type-specific status codes and add the individual status codes to the corresponding type map
  for (const [transactionType, filterFieldName] of filterStatusCodeFieldNames) {
    if (filter[filterFieldName]?.length > 0) {
      const typeStatusCodes = typeToStatusCodesMap.has(transactionType)
        ? typeToStatusCodesMap.get(transactionType)
        : new Set();
      for (const statusCode of filter[filterFieldName]) {
        typeStatusCodes.add(statusCode);
      }
      typeToStatusCodesMap.set(transactionType, typeStatusCodes);
    }
  }

  // finally, remove any types the authenticated agent does not actually have access to. this is done
  // last to simplify the interplay between the type, aggregate status, and type-specific filters.
  const agentTransactionTypes = calcAgentTransactionTypes(agentScopes);
  for (const transactionType of typeToStatusCodesMap.keys()) {
    if (!agentTransactionTypes.has(transactionType)) {
      typeToStatusCodesMap.delete(transactionType);
    }
  }

  return typeToStatusCodesMap;
}

export default calcEffectiveFilterTypesAndStatuses;
