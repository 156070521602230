import React from "react";
import { Table, Thead, Td, Tr } from "../Table";

const AfterRefundResults = ({ transaction, children }) => {
  return (
    <div>
      <h3>
        Refund Successful! It may take a few hours for this to be reflected in transaction history.
      </h3>
      <Table>
        <Thead>
          <Tr>
            <Td>Transaction ID: </Td>
            <Td>{transaction.invoiceId ?? "--"}</Td>
          </Tr>
        </Thead>
        {children}
      </Table>
    </div>
  );
};

export default AfterRefundResults;
