import { useMemo } from "react";

/**
 * @typedef {Object} FilterConfigFieldArg
 * @property {string} name Name of the filter.
 * @property {string} [type="string] Data type of the filter value.
 * @property {boolean} [multiple=false] Whether multiple values are allowed.
 * @property {(string|string[])} [defaultValue] Default value if the user has not an explicit value.
 *   Must be an array if `multiple` is true.
 * @property {string} [graphQlName] Field name to use when building a GraphQL input filter.
 */

/**
 * @typedef {Object} FilterConfigField
 * @property {string} name Name of the filter.
 * @property {string} type Data type of the filter value.
 * @property {boolean} multiple Whether multiple values are allowed.
 * @property {(undefined|string|string[])} defaultValue Default value if the user has not an explicit value.
 *   An array if `multiple` is true.
 * @property {string} graphQlName Field name to use when building a GraphQL input filter.
 */

/**
 * Validates and sets default values for each field in the config array. Returns the
 * validated config array.
 *
 * @param {FilterConfigFieldArg[]} config
 * @returns {FilterConfigField[]}
 * @throws {Error} if any field has a validation error.
 */
function useFilterConfig(config) {
  return useMemo(() => {
    if (config.length < 1) {
      throw new Error("Filter config is empty");
    }

    const uniqueFieldNames = config.reduce((names, field) => {
      names.add(field.name);
      return names;
    }, new Set());
    if (uniqueFieldNames.size !== config.length) {
      throw new Error("Filter config contains a duplicate field name");
    }

    return config.map((field) => {
      if (!field.name) {
        throw new Error(`Field is missing 'name' key: ${field}`);
      }

      switch (field.type) {
        case undefined:
        case "value":
        case "string":
        case "date":
          break;
        default:
          throw new Error(`Unsupported field type: ${field}`);
      }

      if (field.defaultValue !== undefined) {
        if (field.multiple && !Array.isArray(field.defaultValue)) {
          throw new Error(`Default value must be an array when multiple is true: ${field}`);
        }
      }

      return {
        name: field.name,
        type: field.type ?? "string",
        multiple: field.multiple || false,
        defaultValue: field.defaultValue ?? undefined,
        graphQlName: field.graphQlName ?? field.name,
      };
    });
  }, [config]);
}

export default useFilterConfig;
