import { BalanceTransferStatusCode } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the balance transfer status code.
 *
 * @param {BalanceTransferStatusCode} balanceTransferStatusCode
 * @returns {JSX.Element}
 */
function BalanceTransferStatusCodeName({ balanceTransferStatusCode }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (balanceTransferStatusCode) {
    case BalanceTransferStatusCode.PROCESSING:
      return <>Processing</>;
    case BalanceTransferStatusCode.SUCCESS:
      return <>Success</>;
    case BalanceTransferStatusCode.FAILED:
      return <>Failed</>;
    default:
      return <>{balanceTransferStatusCode}</>;
  }
}

export default BalanceTransferStatusCodeName;
