import * as R from "ramda";
import { createAction } from "redux-actions";
import { debugReducer } from "../utils"; //eslint-disable-line no-unused-vars
import * as permissions from "context/permissions";
import * as fraudApi from "api/fraud";
import { STATUS } from "utils";
export { STATUS } from "utils";

const { submitManualReview } = fraudApi;
export { submitManualReview };

export const getAccountWatchlist = async (dispatch, accountId, user) => {
  if (
    !user.hasPermissions([
      permissions.READ_PAYMENT_METHODS,
      permissions.READ_ADDRESSES,
      permissions.READ_NAMES,
      permissions.READ_WATCHLISTS,
      permissions.WRITE_WATCHLISTS,
    ], permissions.WATCHLIST_RESOURCE)
  ) {
    dispatch(actions.getAccountWatchlistPermissionsFail());
    return;
  }
  dispatch(actions.getAccountWatchlistInit(accountId));
  try {
    const res = await fraudApi.getAccountWatchlist(accountId, user);
    dispatch(actions.getAccountWatchlistSuccess(res.payload));
  } catch (err) {
    dispatch(actions.getAccountWatchlistError(err));
  }
};

export const actions = {
  getAccountWatchlistInit: createAction("@tools/account/getAccountWatchlistInit"),
  getAccountWatchlistSuccess: createAction("@tools/account/getAccountWatchlistSuccess"),
  getAccountWatchlistError: createAction("@tools/account/getAccountWatchlistError"),
  getAccountWatchlistPermissionsFail: createAction(
    "@tools/account/getAccountWatchlistPermissionsFail"
  ),
};

export const initialState = {
  watchlist: {
    accountId: null,
    data: null,
    error: null,
    permissions: true,
    status: STATUS.PRE_INIT,
  },
};

export const handlers = {
  [actions.getAccountWatchlistInit]: debugReducer((state, { payload }) =>
    R.pipe(
      R.assocPath(["watchlist", "accountId"], payload),
      R.assocPath(["watchlist", "error"], null),
      R.assocPath(["watchlist", "status"], STATUS.INITIALIZED)
    )(state)
  ),
  [actions.getAccountWatchlistSuccess]: debugReducer((state, { payload }) =>
    R.pipe(
      R.assocPath(["watchlist", "data"], payload),
      R.assocPath(["watchlist", "status"], STATUS.SUCCESS),
      R.assocPath(["watchlist", "error"], null)
    )(state)
  ),
  [actions.getAccountWatchlistError]: debugReducer((state, { payload }) =>
    R.pipe(
      R.assocPath(["watchlist", "error"], payload),
      R.assocPath(["watchlist", "status"], STATUS.ERROR),
      R.assocPath(["watchlist", "data"], null)
    )(state)
  ),
  [actions.getAccountWatchlistPermissionsFail]: debugReducer((state) =>
    R.pipe(
      R.assocPath(["watchlist", "permissions"], false),
      R.assocPath(["watchlist", "status"], STATUS.ERROR),
      R.assocPath(["watchlist", "data"], null),
      R.assocPath(["watchlist", "error"], null)
    )(state)
  ),
};
