import { debug } from "utils";
import buildTiliaApiResponse from "./buildTiliaApiResponse";
import { mapProxyFetch, serviceMap } from "./utils";

const jpmBinProxy = mapProxyFetch(serviceMap.JPM_BIN_API);

export const getBin = async (id, ctx) => {
  debug(ctx, "[getBin]", id);
  // A BIN is always 6 numbers. The API expects 19 digts, so we pad with zeroes.
  const response = await jpmBinProxy(ctx, `/bin/${encodeURIComponent(id)}0000000000000`);
  return buildTiliaApiResponse(response);
};
