import { InvoicingApiInvoiceType } from "../../invoicingApi/index.js";

/**
 * Token purchase transaction status codes.
 * @enum {string}
 */
export const TokenPurchaseStatusCode = Object.freeze({
  OPEN: "OPEN",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
});

/**
 * Token Purchase transaction invoice types.
 * @type {Set<InvoicingApiInvoiceType>}
 */
export const TokenPurchaseInvoiceTypes = new Set([InvoicingApiInvoiceType.TokenPurchaseIT]);
