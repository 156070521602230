/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import React, { useMemo } from "react";
import * as Yup from "yup";
import { useAccount } from "context/accountContext";
import { patchSettlement } from "api/settlement";
import { ctxWithIntegrator } from "utils/auth";
import { FormModal } from "components/Modal";
import { DetailText } from "components/Text";
import useAgentCanApproveSettlement from "./useAgentCanApproveSettlement";
import { SettlementApprovalType } from "./settlementTypes";
import useSettlement from "./useSettlement";
import SettlementDeductionsSummary from "./SettlementDeductionsSummary";
import SettlementRevenueSummary from "./SettlementRevenueSummary";
import SettlementAdjustmentsSummary from "./SettlementAdjustmentsSummary";
import SettlementAdjustmentsFields from "./SettlementAdjustmentsFields";
import SettlementBalanceSummary from "./SettlementBalanceSummary";
import SettlementBalanceSummaryWithLiveAdjustments from "./SettlementBalanceSummaryWithLiveAdjustments";
import { SubmitCancelButtonGroup } from "../Forms";
import { LoadingIcon } from "../Icons";
import { Text } from "@chakra-ui/react";
import { formatDateTime } from "utils";
import SettlementApprovalsSummary from "./SettlementApprovalsSummary";
import ReturnForReReviewButton from "./ReturnForReReviewButton";

const validationSchema = Yup.object().shape({
  adjustments: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().when("amount", {
        is: (amount) => {
          amount = parseInt(amount, 10);
          if (isNaN(amount)) {
            return false;
          }
          return amount !== 0;
        },
        then: (schema) => schema.trim().required("Description is required"),
      }),
      amount: Yup.number(),
    }),
  ),
});

function ApproveSettlementModal({
  settlementId,
  integrator,
  approvalType,
  onCancel,
  onSettlementApproved,
}) {
  let action = "";
  let title = "";
  switch (approvalType) {
    case SettlementApprovalType.REVIEW:
      action = "review";
      title = "Review Settlement";
      break;
    case SettlementApprovalType.APPROVE:
      action = "approve";
      title = "Approve Settlement";
      break;
    case SettlementApprovalType.SUPER_APPROVE:
      action = "supapprove";
      title = "Super Approve Settlement";
      break;
    default:
      throw new Error(`Invalid approval type: ${approvalType}`);
  }

  const { agentContext } = useAccount();

  const { loading, data } = useSettlement({ settlementId, integrator });
  const canApprove = useAgentCanApproveSettlement(settlementId, integrator, approvalType);

  const handleSubmit = async ({ adjustments }, { setStatus }) => {
    const formAdjustments = [];
    for (const adjustment of adjustments) {
      const amount = parseInt(adjustment.amount, 10);
      if (!isNaN(amount) && amount !== 0) {
        formAdjustments.push({
          adjustmentDescription: adjustment.description,
          amount,
          currencyCode: data.settlement.currencyCode,
        });
      }
    }

    const result = await patchSettlement(
      settlementId,
      {
        action,
        adjustments: formAdjustments,
      },
      ctxWithIntegrator(integrator, agentContext),
    );

    if (result.status !== 200) {
      setStatus({ error: result.errors.join(" ") });
      return;
    }

    onSettlementApproved(settlementId, integrator);
  };

  const settlement = data?.settlement;

  const initialValues = useMemo(() => {
    const adjustments = [];
    if (settlement) {
      for (const adjustment of settlement.adjustments) {
        adjustments.push({
          description: adjustment.adjustmentDescription,
          amount: adjustment.amount,
        });
      }
    }
    return { adjustments };
  }, [settlement]);

  const canChangeAdjustments = canApprove && approvalType !== SettlementApprovalType.SUPER_APPROVE;
  const canReturn = canApprove && approvalType !== SettlementApprovalType.REVIEW;

  return (
    <FormModal
      formikProps={{
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
      }}
      isOpen={true}
      onClose={canApprove ? undefined : onCancel}
      title={title}
      isLoading={loading}
      actions={canApprove ? <SubmitCancelButtonGroup onCancel={onCancel} /> : undefined}
    >
      {!settlement && loading && <LoadingIcon size={2} />}
      {!settlement && !loading && <p>Not found</p>}
      {settlement && (
        <>
          <Text mt={0}>
            {settlement.integrator}
            <br />
            <DetailText>
              {formatDateTime(settlement.periodStartDatetime)}
              {" – "}
              {formatDateTime(settlement.periodEndDatetime)}
            </DetailText>
          </Text>
          <SettlementRevenueSummary
            summary={settlement.summary}
            currencyCode={settlement.currencyCode}
          />
          <SettlementDeductionsSummary
            summary={settlement.summary}
            currencyCode={settlement.currencyCode}
          />
          {canChangeAdjustments ? (
            <>
              <SettlementAdjustmentsFields currencyCode={settlement.currencyCode} />
              <SettlementBalanceSummaryWithLiveAdjustments
                summary={settlement.summary}
                currencyCode={settlement.currencyCode}
              />
            </>
          ) : (
            <>
              <SettlementAdjustmentsSummary
                summary={settlement.summary}
                currencyCode={settlement.currencyCode}
                adjustments={settlement.adjustments}
              />
              <SettlementBalanceSummary
                summary={settlement.summary}
                currencyCode={settlement.currencyCode}
              />
            </>
          )}
          {canReturn && (
            <ReturnForReReviewButton
              settlementId={settlementId}
              integrator={integrator}
              onSettlementApproved={onSettlementApproved}
            />
          )}
          <SettlementApprovalsSummary
            approvals={settlement.approvals}
            statusHistory={settlement.statusHistory}
          />
        </>
      )}
    </FormModal>
  );
}

export default ApproveSettlementModal;
