import React from "react";
import * as Yup from "yup";
import { useAccount } from "context/accountContext";
import { useIntegrator } from "context/integratorContext";
import { WRITE_PERMISSIONS, WRITE_REGISTRATIONS } from "context/permissions";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import { useAlert } from "context/alertContext";
import { addAccountPermission, registerAccount } from "api/account";
import { useFormModal } from "../Modal";
import { OutlineButton } from "../Button";
import { SelectField, TextField } from "../Forms";
import { ensureArray } from "utils";
import { ctxWithIntegrator } from "utils/auth";
import { ErrorAlert } from "components/Error";

const roleOptions = [
  { value: "publisher_agent", label: "Agent" },
  { value: "publisher_developer", label: "Developer" },
  { value: "publisher_admin", label: "Admin" },
  { value: "publisher_read_only", label: "Read Only" },
];

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid Email Address")
    .required("Email Address is required"),
  role: Yup.string().required("Role is required"),
});

const AddIntegratorToolsUserForm = ({ integrator, onAddUser }) => {
  const { agentContext } = useAccount();

  const [FormModal, openModal] = useFormModal();
  const { fireAlert } = useAlert();

  const handleSubmit = async ({ email, role }) => {
    const updateCtx = ctxWithIntegrator(integrator, agentContext);
    let response = await registerAccount(email, updateCtx);
    await addAccountPermission(response.payload.account_id, role, updateCtx);
    return { status: "Success" };
  };

  const handleSubmitSuccess = () => {
    fireAlert("New user successfully added!");
    onAddUser();
  };

  return (
    <>
      <OutlineButton onClick={openModal}>Add User</OutlineButton>
      <FormModal
        titleText="Add User"
        actionText="Save"
        submitAction={handleSubmit}
        onSubmitActionSuccess={handleSubmitSuccess}
        formProps={{
          initialValues: {
            email: "",
            role: "publisher_agent",
          },
          validationSchema,
        }}
      >
        <TextField
          name="email"
          label="Email Address"
          placeholder="user@example.com"
          required={true}
        />
        <SelectField name="role" label="Role" options={roleOptions} required={true} />
      </FormModal>
    </>
  );
};

const AddIntegratorToolsUser = ({ integrator, onAddUser }) => {
  const integratorData = useIntegrator(integrator);
  const hasPermissions = useAgentHasPermissions([WRITE_PERMISSIONS, WRITE_REGISTRATIONS]);

  if (!hasPermissions) {
    return null;
  }

  // integratorData or integratorData.login may be undefined
  const loginMethods = ensureArray(integratorData?.login).filter(
    (method) => method && method !== "password"
  );
  if (loginMethods.length < 1) {
    return (
      <ErrorAlert title="Login method error!">
        Please check settings.tools-web.login for this publisher.
      </ErrorAlert>
    );
  }

  return <AddIntegratorToolsUserForm integrator={integrator} onAddUser={onAddUser} />;
};

export default AddIntegratorToolsUser;
