import {
  ClientsNavItem,
  DashboardNavItem,
  FinanceNavItem,
  KYCNavItem,
  PayoutsNavItem,
  PermissionsNavItem,
  PublisherNavItem,
  SettingsNavItem,
  WatchlistNavItem,
  WebhooksNavItem,
} from "./NavItem";

const TiliaAgentNavItems = () => {
  return (
    <>
      <DashboardNavItem />
      <PayoutsNavItem />
      <KYCNavItem />
      <WatchlistNavItem />
      <PermissionsNavItem />
      <SettingsNavItem />
      <PublisherNavItem />
      <WebhooksNavItem />
      <ClientsNavItem />
      <FinanceNavItem />
    </>
  );
};

export default TiliaAgentNavItems;
