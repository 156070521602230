import { InvoicingApiInvoiceType } from "../../invoicingApi/index.js";

/**
 * Refund transaction status codes.
 * @enum {string}
 */
export const RefundStatusCode = Object.freeze({
  OPEN: "OPEN",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  SYSTEM_CANCELED: "SYSTEM_CANCELED",
});

/**
 * Refund summary status codes.
 * @enum {string}
 */
export const RefundSummaryStatusCode = Object.freeze({
  NONE: "NONE",
  PARTIAL: "PARTIAL",
  FULL: "FULL",
});

/**
 * Refund transaction invoice types.
 * @type {Set<InvoicingApiInvoiceType>}
 */
export const RefundInvoiceTypes = new Set([
  InvoicingApiInvoiceType.RefundIT,
  InvoicingApiInvoiceType.RefundLineItemIT,
  InvoicingApiInvoiceType.RefundInvoiceVirtualIT,
  InvoicingApiInvoiceType.RefundLineItemInvoiceVirtualIT,
]);
