import { SectionSpinner } from "components/Spinner";
import { useAlert } from "context/alertContext";
import * as R from "ramda";
import { Payments } from "./Payments";
import { Wallets } from "./Wallets";

import { getAccountPaymentMethods, useQuery } from "context/graphqlContext";

const METHOD_TYPES = {
  WALLET: "WALLET",
  PAYMENT: "PAYMENT",
  CREDIT: "CREDIT",
};

const groupByType = R.groupBy((pm) =>
  R.propEq("wallet", "provider")(pm) ? METHOD_TYPES.WALLET : METHOD_TYPES.PAYMENT,
);

export const PaymentMethodList = ({ onSelectPayments }) => {
  const { fireAlert } = useAlert();
  const { data, error, loading, refetch, ...rest } = useQuery(getAccountPaymentMethods); //eslint-disable-line no-unused-vars

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (loading) {
    return <SectionSpinner />;
  }

  const methodsByType = groupByType(data?.account?.paymentMethods || []);
  const onUpdate = () => {
    fireAlert("Payment method updated");
    refetch();
  };

  return (
    <>
      <Wallets walletPaymentMethods={methodsByType[METHOD_TYPES.WALLET]} onChange={refetch} />
      <Payments
        data={methodsByType[METHOD_TYPES.PAYMENT]}
        onUpdate={onUpdate}
        onSelectPayments={onSelectPayments}
      />
    </>
  );
};
