import { Text } from "@chakra-ui/react";
import { DetailText } from "components/Text";
import AccountCard from "components/Transaction/AccountCard";
import {
  Section,
  TransactionColumnLeft,
  TransactionColumnRight,
  TransactionLayout,
  TransactionMain,
} from "components/Transaction/Layout";
import PaymentMethods from "../UserPurchase/PaymentMethods";
import TokenPurchaseSummary from "./TokenPurchaseSummary";
import TokenPurchaseIDs from "./TokenPurchaseIDs";

function TokenPurchaseDetail({ tokenPurchase }) {
  return (
    <TransactionLayout>
      <TransactionColumnLeft>
        {/* TODO: Add tags https://tiliajira.atlassian.net/browse/DEV-3135 */}
        {/* <Tags /> */}
        <Section title="Account Detail">
          <Text mt="0">
            <DetailText>{tokenPurchase.integrator}</DetailText>
          </Text>
          <AccountCard accountId={tokenPurchase.account.accountId} />
        </Section>
        {tokenPurchase.payment && (
          <Section title="Payment methods">
            <PaymentMethods transaction={{ payments: [tokenPurchase.payment] }} />
          </Section>
        )}
      </TransactionColumnLeft>
      <TransactionMain>
        <Section title="Summary">
          <TokenPurchaseSummary tokenPurchase={tokenPurchase} />
        </Section>
        <Section title="Token Purchase Details">
          <TokenPurchaseIDs invoiceId={tokenPurchase.invoiceId} />
        </Section>
      </TransactionMain>
      <TransactionColumnRight />
    </TransactionLayout>
  );
}

export default TokenPurchaseDetail;
