import React from "react";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import ReactSelect from "react-select";
import { Field } from "formik";
import { theme, prop, ifProp } from "styles";
import { noop } from "utils";
import { Label } from "../Label";
import { Error } from "../Error";
import { isNilOrEmpty } from "utils";

const ENTRY_HEIGHT = 37;

const calcMaxHeight = (entries, px) => {
  if (px) return px;
  if (entries) return `${entries * ENTRY_HEIGHT}px`;
  return undefined;
};

export const SELECT_MENU_PORTAL_ID = "select-menu-portal";

export const globalSelectPortalStyle = css`
  #${SELECT_MENU_PORTAL_ID} {
    color: yellow;
  }
  #${SELECT_MENU_PORTAL_ID} .rs__menu {
    border-radius: ${theme("forms.borderRadius")};
    border: 1px solid ${theme("colors.focus")};
    box-shadow: none;
    background-color: ${theme("colors.menu.list.bg")};
    color: ${theme("colors.forms.color")};
  }
  #${SELECT_MENU_PORTAL_ID} .rs__menu-list {
    ${ifProp(
      "maxHeight",
      css`
        max-height: ${prop("maxHeight")};
      `
    )}
  }
  #${SELECT_MENU_PORTAL_ID} .rs__option {
    color: ${theme("colors.menu.fg")};
    background-color: ${theme("colors.menu.bg")};
  }
  #${SELECT_MENU_PORTAL_ID} .rs__option--is-disabled {
    color: ${theme("colors.menu.disabled")};
  }
  #${SELECT_MENU_PORTAL_ID} .rs__option--is-selected {
    background: ${theme("colors.menu.selectedBg")};
  }
  #${SELECT_MENU_PORTAL_ID} .rs__option--is-focused {
    &:hover {
      background: ${theme("colors.menu.focusedBg")};
    }
  }
`;

export const SelectMenuPortal = () => <div id={SELECT_MENU_PORTAL_ID} />;

export const Select = ({
  label,
  required,
  onChange,
  handleChange,
  onBlur = noop,
  value,
  error,
  touched,
  maxHeightEntries,
  maxHeight,
  disabled,
  ...props
}) => {
  if (!handleChange) {
    handleChange = (option) => {
      if (Array.isArray(option)) {
        const options = option.map((option) => option.value);
        onChange(props.name, options);
      } else {
        onChange(props.name, option?.value);
      }
    };
  }

  const handleBlur = () => onBlur(props.name, true);
  const maybeMaxHeight = calcMaxHeight(maxHeightEntries, maxHeight);

  return (
    <div
      css={`
        margin-bottom: 16px;
      `}
      data-testid={`select--${label}`}
    >
      <Label required={required} htmlFor={`${props.name}--input`}>
        {label}
      </Label>
      <ReactSelect
        menuPortalTarget={document.getElementById(SELECT_MENU_PORTAL_ID)}
        id={props.name}
        inputId={`${props.name}--input`}
        maxHeight={maybeMaxHeight}
        styles={{
          // menuPortal appears to NOT have a classname addressable via
          // the css prop below, so the styles API it is...
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        css={`
          width: 100%;
          & .rs__control {
            border-color: ${theme("colors.forms.border")};
            border-radius: ${theme("forms.borderRadius")};
            background-color: ${theme("colors.forms.bg")};
            color: ${theme("colors.forms.color")};
            &:hover {
              border-color: ${theme("colors.forms.border")};
            }
          }
          & .rs__control--is-focused {
            box-shadow: 0 0 0 1px ${theme("colors.menu.border")};
          }
          & .rs__indicator-separator {
            background-color: ${theme("colors.forms.bg")};
            color: ${theme("colors.forms.color")};
          }
          & .rs__indicator {
            background-color: ${theme("colors.forms.bg")};
            color: ${theme("colors.forms.color")};
          }
          & .rs__placeholder {
            background-color: ${theme("colors.forms.bg")};
            color: ${theme("colors.forms.color")};
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          & .rs__multi-value {
            background-color: ${theme("colors.forms.multi.bg")};
            color: ${theme("colors.forms.color")};
          }
          & .rs__multi-value__label {
            color: ${theme("colors.forms.color")};
          }
          & .rs__single-value {
            background-color: ${theme("colors.forms.bg")};
            color: ${theme("colors.forms.color")};
          }
          & .rs__input {
            color: ${theme("colors.menu.fg")};
          }
          & .rs__input-container {
            color: ${theme("colors.menu.fg")};
          }
        `}
        classNamePrefix="rs"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        isDisabled={disabled}
        {...props}
      />
      <Error error={error} touched={touched} />
    </div>
  );
};

export const SelectField = (props) => {
  return (
    <Field name={props.name}>
      {({
        field,
        form: { errors, touched, setFieldValue, handleChange: _handleChange, ...formRest },
      }) => {
        const onChange = (name, value) => {
          setFieldValue(name, value);
          if (!isNilOrEmpty(props.optionalUpdates)) props.optionalUpdates(value);
        };
        return (
          <Select
            {...field}
            error={errors[field.name]}
            touched={touched[field.name]}
            value={
              props.options ? props.options.find((option) => option.value === field.value) : ""
            }
            onChange={(name, value) => onChange(field.name, value)}
            {...formRest}
            {...props}
          />
        );
      }}
    </Field>
  );
};
