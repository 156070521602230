import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  tab: {
    background: "inherit",
  },
  tabpanel: {
    border: ".2px solid",
    borderColor: "gray.200",
  },
});

// export the component theme
export default defineMultiStyleConfig({ baseStyle });
