import React from "react";
import * as R from "ramda";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { DataRow } from "components/DataRow";
import { OutlineButton } from "components/Button";
import { theme } from "styles";
import { useToggle } from "utils/useToggle";
import { formatDateTime, isNilOrEmpty } from "utils";
import { formatEmptyData } from "utils/format";
import { Cell, SortingHeaderCell } from "components/Sorter";
import { WatchlistEntities } from "components/Watchlist";
import { useAccount } from "context/accountContext";
import { useNavigate } from "react-router-dom";
import { fetchPMPii, queryWatchlist } from "./utils";

export const row = css`
  padding 8px 0;
  border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
`;
const header = css`
  ${row}
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;

const dataRowCSS = `
  padding-left: 8px;
`;

const PIISourceField = ({ source, piiData }) => {
  const navigate = useNavigate();
  return (
    <span css="display:contents">
      {source}
      <OutlineButton
        data-testid="query-account-button"
        onClick={() => queryWatchlist(navigate, piiData)}
        css="padding-left: 8px"
      >
        Query
      </OutlineButton>
    </span>
  );
};

const WatchlistCheckPII = ({ piiData }) => {
  return (
    <div css="min-width:50%; display: grid; grid-template-columns: 1fr 4fr;">
      <div>First:</div>
      <div>{piiData.first}</div>
      <div>Last:</div>
      <div>{piiData.last}</div>
      <div>Street:</div>
      <div>{piiData.street}</div>
      <div>City:</div>
      <div>{piiData.city}</div>
      <div>State:</div>
      <div>{piiData.state}</div>
      <div>Country:</div>
      <div>{piiData.country}</div>
      <div>Zip:</div>
      <div>{piiData.zip}</div>
    </div>
  );
};

const WatchlistCheck = ({ data, entities, kycPii }) => {
  const [expanded, toggleExpanded] = useToggle();
  const thTestId = (field) => `watchlist-check-${data.check_id}-${field}`;
  const [loaded, setLoaded] = React.useState(false);
  const [piiData, setPiiData] = React.useState({});
  const { accountState, agentContext } = useAccount();
  const {
    details: { accountId },
  } = accountState;

  React.useEffect(() => setPiiData(kycPii), [kycPii]);
  React.useEffect(() => {
    /*
      this should only run the first time a given
      pmpii row is expanded, lazy loading the pmpii
    */
    if (!expanded || loaded) {
      return;
    }
    setLoaded(true);
    if (data.source_pii_type === "pmpii") {
      fetchPMPii(setPiiData, data.source_pii_id, accountId, agentContext);
    }
  }, [data, expanded, loaded, piiData, accountId, agentContext]);

  const piiSource =
    data.source_pii_type === "kyc" ? "KYC Data" : `Payment Method: ${data.source_pii_id}`;
  const closeTime = data.status === "MANUAL_REVIEW" ? "-" : formatDateTime(data.updated);

  return (
    <div
      data-testid="watchlist-check-row"
      css={`
        cursor: pointer;
        &:hover {
          background-color: ${theme("colors.dataTable.hoverBg")};
        }
        border: 1px solid ${expanded ? theme("colors.dataTable.rowBorder") : "transparent"};
        background-color: ${expanded ? theme("colors.dataTable.expandedBg") : "transparent"};
        border-radius: ${expanded ? "2px" : "0"};
        margin-top: ${expanded ? "8px" : "0"};
        margin-bottom: ${expanded ? "8px" : "0"};
      `}
    >
      <div
        css={`
          ${header}
          &:hover {
            background-color: ${theme("colors.dataTable.hoverBg")};
          }
        `}
        onClick={toggleExpanded}
      >
        <Cell data-testid={thTestId("status")}>{data.status}</Cell>
        <Cell data-testid={thTestId("score")}>{data.check_score.toFixed(2)}</Cell>
        <Cell data-testid={thTestId("created")}>{formatDateTime(data.created)}</Cell>
        <Cell data-testid={thTestId("updated")}>{closeTime}</Cell>
        <Cell data-testid={thTestId("Publisher")}>{data.integrator}</Cell>
        <Cell data-testid={thTestId("sanction_list")}>{formatEmptyData(data?.sanction_list)}</Cell>
        <Cell data-testid={thTestId("version_list")}>{formatEmptyData(data?.list_version)}</Cell>
      </div>
      {expanded && (
        <div
          css={`
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
              "a b"
              "c c";
            grid-column-gap: 1.5em;
          `}
        >
          <div css="grid-area: a;">
            <DataRow title="Score" data={data.check_score.toFixed(2)} titleCSS={dataRowCSS} />
            <DataRow
              title="Score threshold when check occurred"
              data={data.score_threshold}
              titleCSS={dataRowCSS}
            />
            <DataRow title="User Action" data={data.user_action} titleCSS={dataRowCSS} />
            <DataRow title="List Version" data={data.list_version} titleCSS={dataRowCSS} />
          </div>
          <div css="grid-area: b;">
            <DataRow title="Check ID" data={data.check_id} titleCSS={dataRowCSS} />
            <DataRow
              title="PII Source"
              data={<PIISourceField source={piiSource} piiData={piiData} />}
              titleCSS={dataRowCSS}
            />
            <Cell data-testid={thTestId("check_pii")}>
              <div css="display:flex; justify-content:space-between">
                Watchlist Check PII
                <WatchlistCheckPII piiData={piiData} />
              </div>
            </Cell>
            <Cell data-testid={thTestId("review_notes")}>
              Notes
              <div css="padding:20px; white-space: pre-line">{data.review_notes}</div>
            </Cell>
          </div>
          <div css="grid-area: c;">
            <WatchlistEntities hideDownload name={data.check_id} entities={entities} />
          </div>
        </div>
      )}
    </div>
  );
};

export const WatchlistChecks = ({ data, entities, kycPii, ...sortingProps }) => {
  return (
    <>
      <div
        css={`
          ${header}
        `}
      >
        <SortingHeaderCell {...sortingProps} field="status">
          Check Status
        </SortingHeaderCell>
        <SortingHeaderCell {...sortingProps} field="check_score">
          Score
        </SortingHeaderCell>
        <SortingHeaderCell {...sortingProps} field="created">
          Open Time
        </SortingHeaderCell>
        <SortingHeaderCell {...sortingProps} field="updated">
          Close Time
        </SortingHeaderCell>
        <SortingHeaderCell {...sortingProps} field="integrator">
          Publisher
        </SortingHeaderCell>
        <SortingHeaderCell {...sortingProps} field="sanction_list">
          Sanction List
        </SortingHeaderCell>
        <SortingHeaderCell {...sortingProps} field="list_verion_date">
          List Version
        </SortingHeaderCell>
      </div>
      {isNilOrEmpty(data) ? (
        <div css={row}>No Data</div>
      ) : (
        R.map((c) => {
          //filter down the entities for this check
          let checkEntities = {};
          if (c.wl_entity_ids && !isNilOrEmpty(entities)) {
            c.wl_entity_ids.split(",").map((wleID) => {
              checkEntities[wleID] = entities[wleID];
              return true;
            });
          }
          return (
            <WatchlistCheck key={c.check_id} data={c} kycPii={kycPii} entities={checkEntities} />
          );
        })(data)
      )}
    </>
  );
};
