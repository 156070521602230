import { DepositStatusCode } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the deposit status code.
 *
 * @param {DepositStatusCode} depositStatusCode
 * @returns {JSX.Element}
 */
function DepositStatusCodeName({ depositStatusCode }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (depositStatusCode) {
    case DepositStatusCode.OPEN:
      return <>Open</>;
    case DepositStatusCode.PROCESSING:
      return <>Processing</>;
    case DepositStatusCode.SUCCESS:
      return <>Success</>;
    case DepositStatusCode.FAILED:
      return <>Failed</>;
    case DepositStatusCode.CANCELED:
      return <>Canceled</>;
    case DepositStatusCode.USER_REVERSED:
      return <>User Reversed</>;
    case DepositStatusCode.SYSTEM_CANCELED:
      return <>System Canceled</>;
    default:
      return <>{depositStatusCode}</>;
  }
}

export default DepositStatusCodeName;
