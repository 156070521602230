import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { DownloadButton } from "components/Button";
import { slug } from "utils/format";
import { LoadingIcon } from "components/Icons";

export const DownloadLink = ({ data, filename }) => {
  const [objectURL, setObjectURL] = useState(null);
  useEffect(() => {
    let objURL = null;
    (async () => {
      // calling fetch here converts the base64 encoded data to a blob
      // suitable for download, without size limitations
      const blob = await (await fetch(data)).blob();
      objURL = URL.createObjectURL(blob);
      setObjectURL(objURL);
    })();
    return () => {
      if (objURL) {
        URL.revokeObjectURL(objURL);
      }
    };
  }, [data]);

  const downloadRef = useRef(null);
  const handleDownloadClick = () => {
    downloadRef.current.click();
  };

  if (!objectURL) return <LoadingIcon />;
  return (
    <>
      <a
        data-testid="download-link"
        css={`
          visibility: hidden;
        `}
        ref={downloadRef}
        href={objectURL}
        download={slug(filename)}
      >
        _
      </a>
      <DownloadButton onClick={handleDownloadClick} />
    </>
  );
};
