import { useEffect, useState, useCallback } from "react";
import { useAgent } from "context/agentContext";
import { useIntegrator } from "context/integratorContext";
import { exists } from "utils";
import { getSettings } from "api/toolsWeb";
import { Box } from "components/Box";

export const useIntegratorSettings = (integrator) => {
  const keyPrefix = `JSON::${integrator}::*`;
  const { agent } = useAgent();
  const maybeIntegrator = useIntegrator(integrator);

  const [error, setError] = useState();
  useEffect(() => {
    if (!exists(maybeIntegrator)) {
      setError(`Integrator ${integrator} not found`);
    }
  }, [integrator, maybeIntegrator]);

  const [settings, setSettings] = useState(null);
  const loadSettings = useCallback(async () => {
    setSettings(await getSettings(`${keyPrefix}*`, agent));
  }, [agent, keyPrefix]);

  useEffect(() => {
    if (exists(maybeIntegrator)) {
      loadSettings();
    }
  }, [agent, keyPrefix, maybeIntegrator, error, loadSettings]);

  return {
    integrator,
    keyPrefix,
    exists: exists(maybeIntegrator),
    settings: settings?.value,
    error,
    refresh: loadSettings,
  };
};

export const TopBox = (props) => <Box top {...props} />;
