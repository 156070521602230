import React from "react";
import * as R from "ramda";
import * as Yup from "yup";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useAgent } from "context/agentContext";
import * as permissions from "context/permissions";
import { noop } from "utils";
import { isNilOrEmpty } from "utils";
import { useFormModal } from "components/Modal";
import { SelectField, TextField } from "components/Forms";
import { entityAgentTypes, entityAgentStatus } from 'context/accountContext/entity';
import { kycStatus } from 'context/accountContext/kyc';
import { OutlineButton } from "components/Button";
import { PermissionsIcon } from "components/Icons";

const capitalizeFirstLetter = (word) => {
  return R.toUpper(word[0]) + R.toLower(R.slice(1, Infinity, word))
};

export const useAddEditAgentModal = (options) => {
  const label = options?.edit ? "edit" : "add";
  const cLabel = capitalizeFirstLetter(label);
  const testIdPrefix = `${label}-agent-`;
  const { agent } = useAgent();
  const [FormModal, openModal] = useFormModal();

  const AddModal = ({ onSuccess = noop, agentInfo, onSubmit }) => {
    if (!agent.hasPermissions([permissions.WRITE_KYB ])) {
      return <div data-testid={`${testIdPrefix}modal-no-permissions`}></div>;
    }

    const optionsSetup = (data) => R.values(
      R.mapObjIndexed(
        (value, index) => ({value: index, label: value})
        , data)
    );

    const roleOptions = optionsSetup(entityAgentTypes);
    const statusOptions = optionsSetup(entityAgentStatus);
    const kycOptions = optionsSetup(kycStatus);

    const defaultValue = (roles, options) => R.filter(
        (option) => R.includes(option.value, roles)
        , options);

    const initialValues = isNilOrEmpty(agentInfo) 
      ? {
        agent_name: "",
        account_id: "",
        kyc_status: "",
        roles: [],
      }
      : {
        agent_name: agentInfo.agent_name,
        account_id: agentInfo?.account_id,
        kyc_status: agentInfo?.kyc_status,
        roles: agentInfo.roles,
        status: agentInfo.status,
      };

    const defaultKycStatus = defaultValue(initialValues.kyc_status, kycOptions);
    const defaultRoles = defaultValue(initialValues.roles, roleOptions);
    const defaultStatus = defaultValue([initialValues.status], statusOptions);
    const actionButtonText = options?.edit ? 'Submit' : 'Add Agent';

    return (
      <FormModal
        actionIcon={<PermissionsIcon size="1rem" />}
        actionText={`${actionButtonText}`}
        onSubmitActionSuccess={onSuccess}
        submitAction={(v) => onSubmit(v)}
        testIdPrefix={testIdPrefix}
        titleText={`${cLabel} Company Agent`}
        formProps={{
          initialValues: initialValues,
          validationSchema: () =>
          Yup.object().shape({
            agent_name: Yup.string().required(),
          }),
        }}
        disableTillEdit
      >
        <div
          css={`
            min-height: 400px;
          `}
        >
          <TextField
            name="agent_name"
            label="Agent Name"
            required
          />
          <SelectField
            name="roles"
            label="Role"
            isMulti
            defaultValue={defaultRoles}
            options={roleOptions}
            maxMenuHeight={200}
          />
          { (options?.edit)
            ? <SelectField
                name="status"
                label="Agent Status"
                defaultValue={defaultStatus}
                options={statusOptions}
                maxMenuHeight={200}
              />
            : <></>
          }
         <SelectField
            name="kyc_status"
            label="KYC Status"
            defaultValue={defaultKycStatus}
            options={kycOptions}
            maxMenuHeight={200}
          />
          <TextField
            name="account_id"
            label="Account Id"
          />
          </div>
      </FormModal>
    );
  };

  const AddModalButton = ({ ...props }) => {
    if (!agent.hasPermissions([permissions.WRITE_KYB])) {
        return null;
    }
    return (
      <OutlineButton data-testid={`${testIdPrefix}button`} {...props} onClick={openModal}>
        {cLabel}
      </OutlineButton>
    );
  };
  return [AddModal, AddModalButton];
};
