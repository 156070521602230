import approvalsForCurrentReviewCycle from "./approvalsForCurrentReviewCycle";

/**
 * Returns true if the agent account ID has recorded an approval during the current
 * review cycle for the settlement.
 *
 * @param {string} agentAccountId
 * @param {SettlementStatus[]} statusHistory
 * @param {SettlementApproval[]} approvals
 * @returns {boolean}
 */
function agentApprovedSettlement(agentAccountId, statusHistory, approvals) {
  const currentApprovals = approvalsForCurrentReviewCycle(statusHistory, approvals);
  return currentApprovals.some((approval) => agentAccountId === approval.userAccount.accountId);
}

export default agentApprovedSettlement;
