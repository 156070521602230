import { METHODS, assertOk, generateOpts, generateUnauthenticatedOpts } from "./utils";
import { exists } from "utils";
import { ROUTE_BASENAME } from "utils/environment";

const f = (ctx = {}, path, method = METHODS.GET, payload) => {
  return fetch(
    `${ROUTE_BASENAME}${path}`,
    generateOpts(ctx, {
      method,
      body: JSON.stringify(payload || undefined),
    }),
  );
};

export const getWebhooksClientToken = async (integrator, ctx) => {
  const response = await f(ctx, "/api/webhooks/client-token", METHODS.POST, { integrator });
  await assertOk(response);
  return response.json();
};

export const getSettings = async (key, ctx) => {
  const response = await f(ctx, `/api/settings/${key}`);
  await assertOk(response);
  return response.json();
};

export const setSetting = async (key, value, ctx) => {
  const response = await f(ctx, `/api/settings/${key}`, METHODS.POST, { value });
  await assertOk(response);
  return response.json();
};

export const deleteSetting = async (key, ctx) => {
  const response = await f(ctx, `/api/settings/${key}`, METHODS.DELETE);
  await assertOk(response);
  return response.json();
};

export const getAppConfig = async (integrator) => {
  const response = await fetch(`${ROUTE_BASENAME}/api/appconfig?integrator=${integrator}`);
  await assertOk(response);
  return response.json();
};

export const getIntegrators = async () => {
  const response = await fetch(
    `${ROUTE_BASENAME}/api/appconfig/integrators`,
    generateUnauthenticatedOpts(),
  );
  await assertOk(response);
  return response.json();
};

export const getWatchlist = async () => {
  const response = await fetch(
    `${ROUTE_BASENAME}/api/appconfig/watchlists`,
    generateUnauthenticatedOpts(),
  );
  await assertOk(response);
  return response.json();
};

export const downloadWatchlistPDF = async (body, ctx, entityId) => {
  const query = exists(entityId) ? `?entityId=${entityId}` : "";
  const response = await fetch(
    `${ROUTE_BASENAME}/api/watchlist/download${query}`,
    generateOpts(ctx, {
      method: "POST",
      body: JSON.stringify(body),
    }),
  );
  await assertOk(response);
  return response.blob();
};

export const getSSOProviders = async (ctx, integrator, sso_provider) => {
  const ssoProvider = exists(sso_provider) ? `/${sso_provider}` : "";
  const response = await fetch(
    `${ROUTE_BASENAME}/api/integrator/${integrator}/sso-credentials${ssoProvider}`,
    generateOpts(ctx),
  );
  await assertOk(response);
  return response.json();
};

export const postSSOProviders = async (ctx, integrator, sso_provider, creds) => {
  const response = await fetch(
    `${ROUTE_BASENAME}/api/integrator/${integrator}/sso-credentials`,
    generateOpts(ctx, {
      method: METHODS.POST,
      body: JSON.stringify(creds),
    }),
  );
  await assertOk(response);
  return response.json();
};

export const deleteSSOProviders = async (ctx, integrator, sso_provider) => {
  const response = await fetch(
    `${ROUTE_BASENAME}/api/integrator/${integrator}/sso-credentials/${sso_provider}`,
    generateOpts(ctx, {
      method: METHODS.DELETE,
    }),
  );
  await assertOk(response);
  return response.json();
};

// returns the tools-web settings for a given integrator
export const getIntegratorConfig = async (integrator) => {
  const response = await fetch(
    `${ROUTE_BASENAME}/api/appconfig/integrator/${integrator}`,
    generateUnauthenticatedOpts(),
  );
  await assertOk(response);
  return response.json();
};

export const login = async (username, password, integrator) => {
  const response = await fetch(
    `${ROUTE_BASENAME}/api/login`,
    generateUnauthenticatedOpts({
      method: METHODS.POST,
      body: JSON.stringify({ username, password, integrator }),
    }),
  );
  if (!response.ok) throw new Error(response.status);
  return response.json();
};

export const logout = (ctx) => {
  fetch(
    `${ROUTE_BASENAME}/api/logout`,
    generateOpts(ctx, {
      method: METHODS.POST,
    }),
  );
};

export const gdprDelete = async (accountId, ctx) => {
  const response = await f(ctx, "/api/gdpr/delete", METHODS.POST, { accountId });
  await assertOk(response);
  return response.json();
};

export const downloadTransactions = async (
  { accountId, fileName, gqlFilterParams, timeZone },
  ctx,
) => {
  const url = `/api/transactions/download/${encodeURIComponent(accountId)}/${encodeURIComponent(
    fileName,
  )}?timeZone=${encodeURIComponent(timeZone)}${
    exists(gqlFilterParams) ? `&filter=${encodeURIComponent(JSON.stringify(gqlFilterParams))}` : ""
  }`;

  const response = await f(ctx, url);
  await assertOk(response);
  return response.text();
};
