import React, { useCallback } from "react";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { WRITE_KYB, ENTITY_BASIC_RESOURCE } from "context/permissions";
import * as Yup from "yup";
import { useAccount } from "context/accountContext";
import { useFormModal } from "components/Modal";
import { EntityIcon } from "components/Icons";
import { TextField, SelectField } from "components/Forms";
import { useAgent } from "context/agentContext";
import { prop } from "styles";
import * as R from "ramda";
import { noop, isNilOrEmpty } from "utils";
import { toRegionOptions, regions } from "utils/regions";

const testIdPrefix = "create-entity-";

const FormRowGrid = styled.div`
  display: grid;
  grid-template-columns: ${prop(
    "cols",
    css`
      ${({ children }) => `repeat(${children.length}, 1fr)`}
    `
  )};
  grid-gap: 8px;
`;

export const useCreateEntityModal = () => {
  const { agent } = useAgent();
  const [FormModal, openModal] = useFormModal();
  const account = useAccount();

  const CreateEntityModal = ({ onSuccess = noop }) => {
    if (!agent.hasPermissions([WRITE_KYB], ENTITY_BASIC_RESOURCE)) {
      return <div data-testid={`${testIdPrefix}modal-no-permissions`}></div>;
    }

    const entityTypes = [
      { value: "corporation", label: "Corporation" },
      { value: "non_profit_org", label: "Non Profit Organization" },
    ];

    const createEntityAccount = (formData) => {
      const accountDetails = R.path(["accountState", "details", "data"], account);
      return account.createEntity(formData, accountDetails);
    };

    return (
      <FormModal
        actionText="Create Account"
        formProps={{
          initialValues: {
            entity_name: "",
            dba: "",
            entity_type: "",
            tax_id: "",
            naics_numbers: "",
            duns_numbers: "",
            street: "",
            city: "",
            state: "",
            country_iso: "",
            zip: "",
          },
          validationSchema: () =>
            Yup.object().shape({
              entity_name: Yup.string().required("Entity name is required."),
              entity_type: Yup.string().required("Entity type is required."),
            }),
        }}
        onSubmitActionSuccess={onSuccess}
        submitAction={(formData) => createEntityAccount(formData)}
        testIdPrefix={testIdPrefix}
        titleText={`Create Entity Account`}
      >
        <div
          css={`
            min-height: 30vh;
            height: 100%;
            display: flex;
            flex-direction: column;
          `}
        >
          <FormRowGrid>
            <TextField
              data-testid={"entity-name-key-field"}
              name="entity_name"
              label="Entity Name"
              required
            />
            <TextField data-testid={"dba-key-field"} name="dba" label="DBA" required />
          </FormRowGrid>
          <FormRowGrid>
            <SelectField
              data-testid="entity-type-value-field"
              name="entity_type"
              label="Entity Type"
              required
              options={entityTypes}
            />
            <TextField data-testid={"entity-tin-key-field"} name="tax_id" label="TIN" />
          </FormRowGrid>
          <FormRowGrid>
            <TextField data-testid={"entity-nacis-key-field"} name="naics_numbers" label="NAICS" />
            <TextField data-testid={"entity-duns-key-field"} name="duns_numbers" label="DUNS" />
          </FormRowGrid>
          <FormRowGrid>
            <TextField name="street" label="Street" />
          </FormRowGrid>
          <FormRowGrid>
            <TextField name="city" label="City" />
            <TextField name="state" label="State" />
          </FormRowGrid>
          <FormRowGrid>
            <SelectField
              name="country_iso"
              label="Country"
              required
              options={toRegionOptions(regions)}
              maxHeightEntries={3}
            />
            <TextField name="zip" label="Postal Code" />
          </FormRowGrid>
        </div>
      </FormModal>
    );
  };

  const initAndOpen = useCallback(() => {
    openModal();
  }, [openModal]);

  const createEntityMenuItem = useCallback(() => {
    const hasPermissions = agent.hasPermissions([WRITE_KYB], ENTITY_BASIC_RESOURCE);
    const kybAlreadyCreated = !isNilOrEmpty(
      R.path(["accountState", "entity", "entity_id"], account)
    );

    return {
      disabled: kybAlreadyCreated,
      "data-displayitem": hasPermissions,
      label: `Create Entity Account...`,
      icon: <EntityIcon size="1rem" />,
      "data-testid": "create-entity-menu-item",
      onSelect: hasPermissions && initAndOpen,
    };
  }, [agent, initAndOpen, account]);

  return [CreateEntityModal, createEntityMenuItem, initAndOpen];
};
