import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { theme } from "styles";
import { noop } from "utils";

const StyledLink = styled(NavLink)`
  font-size: 1.1em;
  padding: 12px 8px;
  margin-right: 0;
  margin-bottom: -2px;
  display: inline-block;
  text-decoration: none;

  color: ${theme("colors.subNav.inactiveFg")};
  border-bottom: 2px solid ${theme("colors.subNav.inactiveBorder")};
  &.active {
    color: ${theme("colors.subNav.activeFg")};
    border-bottom: 2px solid ${theme("colors.subNav.activeBorder")};
  }
  &:hover {
    color: ${theme("colors.subNav.hoverInactiveFg")};
    border-bottom: 2px solid ${theme("colors.subNav.hoverInactiveBorder")};
  }
`;

export const SubNavLinkItem = ({ to, children }) => {
  return (
    <StyledLink to={to} end>
      {children}
    </StyledLink>
  );
};

export const SubNavRow = styled.div`
  border-bottom: 2px solid ${theme("colors.subNav.rowBorder")};
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SubNavRowRight = styled.div`
  margin-right: 8px;
  display: flex;
  & > * {
    margin-left: 4px;
  }
`;

export const SubNavFilter = ({ filter = "", onFilterChange = noop, ...props }) => {
  const [filterState, setFilterState] = useState(filter);

  return (
    <input
      css={`
        border: 1px solid ${theme("colors.forms.border")};
        background-color: ${theme("colors.forms.bg")};
        color: ${theme("colors.forms.color")};
        padding: 8px;
        border-radius: 2px;
        box-sizing: border-box;
      `}
      placeholder="Filter"
      aria-label="filter"
      data-testid="sub-nav-filter"
      {...props}
      value={filterState}
      onChange={(e) => {
        setFilterState(e.target.value);
        onFilterChange(e.target.value);
      }}
    />
  );
};
