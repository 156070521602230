import { useCallback, useReducer } from "react";
import * as R from "ramda";
import { isNilOrEmpty } from "utils";

export const DESC = 1;
export const ASC = 0;

const toggleDirection = (d) => (d === ASC ? DESC : ASC);

const reducer = (state, { payload }) => ({
  field: payload.field,
  direction: state.field === payload.field ? toggleDirection(state.direction) : ASC,
});

const defaultOpts = {
  initialDirection: DESC,
  valueMap: {},
};

export const useSorting = (initialField, opts = {}) => {
  const options = R.mergeDeepRight(defaultOpts, opts);
  const [state, dispatch] = useReducer(reducer, {
    field: initialField,
    direction: options.initialDirection,
  });

  const setSort = (field) => dispatch({ type: "UPDATE", payload: { field } });
  const sorter = useCallback(
    (data = []) => {
      if (isNilOrEmpty(data)) return data;
      const valueExpr = options.valueMap[state.field] || R.prop(state.field);
      const direction = state.direction === DESC ? R.descend : R.ascend;
      return R.sort(direction(valueExpr))(data);
    },
    [state, options]
  );
  return {
    sorter,
    sortingProps: { sortField: state.field, sortDirection: state.direction, onSortChange: setSort },
  };
};
