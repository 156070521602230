import React from "react";
import "styled-components/macro";
import { Table, TableContainer, Td, Th, Thead, Tr } from "@chakra-ui/react";
import LoadingTbody from "components/Table/LoadingTbody";
import Empty from "components/Empty/Empty";
import { Money } from "components/Money";
import { formatDateTime, isNilOrEmpty } from "utils";
import TransactionTypeName from "components/Transaction/TransactionTypeName";
import TransactionDetailsLink from "components/Transaction/TransactionDetailsLink";
import useWalletLedger from "./useWalletLedger";

const WalletTableRow = ({ ledgerItem }) => {
  return (
    <Tr>
      <Td>{formatDateTime(ledgerItem.date)}</Td>
      <Td>
        {ledgerItem.transaction && (
          <TransactionTypeName transactionType={ledgerItem.transaction.type} />
        )}
      </Td>
      <Td>
        {ledgerItem.transaction && <TransactionDetailsLink transaction={ledgerItem.transaction} />}
      </Td>
      <Td>
        <Money value={ledgerItem.amount} currency={ledgerItem.currency} />
      </Td>
      <Td>
        <Money value={ledgerItem.balanceAfter} currency={ledgerItem.currency} />
      </Td>
    </Tr>
  );
};

export const WalletTable = ({ wallet }) => {
  const { loading, data } = useWalletLedger(wallet);
  const ledger = data?.wallet?.ledger;

  if (isNilOrEmpty(wallet)) return null;

  return <WalletLedgerTable ledger={ledger} loading={loading} />;
};

const WalletLedgerTable = ({ ledger, loading }) => {
  if (isNilOrEmpty(ledger))
    return <Empty title={"Wallet Empty"} subheader={"This wallet has no transactions."} />;

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Transaction Type</Th>
            <Th>Transaction ID</Th>
            <Th>Amount</Th>
            <Th>Balance</Th>
          </Tr>
        </Thead>
        <LoadingTbody columns={5} loading={loading}>
          {ledger.edges?.map?.(({ node: ledgerItem }) => (
            <WalletTableRow ledgerItem={ledgerItem} key={ledgerItem.transaction?.id} />
          ))}
        </LoadingTbody>
      </Table>
    </TableContainer>
  );
};
