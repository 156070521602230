import { Text } from "@chakra-ui/react";
import { TransactionType } from "@tilia-tools/core/transaction";
import BalanceTransferDetail from "./BalanceTransfer/BalanceTransferDetail";
import EscrowPurchaseDetail from "./EscrowPurchase/EscrowPurchaseDetail";
import PurchaseDetail from "./UserPurchase/PurchaseDetail";
import PayoutDetail from "./Payout/PayoutDetail";
import TokenPurchaseDetail from "./TokenPurchase/TokenPurchaseDetail";
import TokenConversionDetail from "./TokenConversion/TokenConversionDetail";

function TransactionDetail({ transaction }) {
  switch (transaction.type) {
    case TransactionType.PURCHASE:
      return <PurchaseDetail purchase={transaction} />;
    case TransactionType.ESCROW_PURCHASE:
      return <EscrowPurchaseDetail escrowPurchase={transaction} />;
    case TransactionType.PAYOUT:
      return <PayoutDetail payout={transaction} />;
    case TransactionType.TOKEN_PURCHASE:
      return <TokenPurchaseDetail tokenPurchase={transaction} />;
    case TransactionType.TOKEN_CONVERSION:
      return <TokenConversionDetail tokenConversion={transaction} />;
    case TransactionType.BALANCE_TRANSFER:
      return <BalanceTransferDetail balanceTransfer={transaction} />;
    default:
      return <Text>Details for this transaction type are not yet available in Tools.</Text>;
  }
}

export default TransactionDetail;
