import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import * as R from "ramda";
import React, { useCallback, useState } from "react";
import { isFunction } from "utils";

const isModalFooter = R.pathEq(["props", "modalFooter"], true);
const isModalErrors = R.pathEq(["props", "modalErrors"], true);

export default function useModal() {
  const [modalState, setModalState] = useState(false);
  const closeModal = () => setModalState(false);
  const openModal = useCallback((openCallback) => {
    setModalState(true);
    if (isFunction(openCallback)) openCallback();
  }, []);

  const ModalWrapper = ({ titleText, children, showFooter = true, size = "xl" }) => {
    // if there is a footer amongst the children, extract it and use. Otherwise, use a default empty footer
    const [footerArr, minusFooter] = R.partition(isModalFooter, React.Children.toArray(children));
    const footer = R.prop(0, footerArr);
    const [errorFooterArr, restChildren] = R.partition(isModalErrors, minusFooter);
    const errorFooter = R.prop(0, errorFooterArr);

    return (
      <Modal size={size} isOpen={modalState} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{titleText}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {errorFooter}
            {restChildren}
          </ModalBody>
          {showFooter && <>{footer}</>}
        </ModalContent>
      </Modal>
    );
  };

  return [ModalWrapper, openModal, closeModal];
}
