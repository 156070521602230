import * as R from "ramda";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { Money } from "components/Money";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import { DataOrNo } from "./common";
import { WalletCard } from "components/Wallet";

import { useAccount } from "context/accountContext";
import useAccountWallets from "../WalletLedger/useAccountWallets";
import { exists, noop } from "utils";

const Wallet = ({ wallet, onChange }) => {
  const { id } = wallet;
  return (
    <WalletCard
      onChange={onChange}
      wallet={wallet}
      key={id}
      detailList={[
        {
          title: "Status",
          detail: wallet.active ? "Active" : "Inactive",
        },
        {
          title: "Balance",
          detail: <Money value={wallet.currentBalance} currency={wallet.currency} />,
        },
      ]}
      tags={wallet.tags.map((tag) => ({
        label: tag.tag,
        status: "info",
      }))}
    />
  );
};

export const Wallets = ({ walletPaymentMethods = [], onChange = noop }) => {
  const accountContext = useAccount();
  const accountId = accountContext?.accountState?.details?.accountId;
  const { data, refetch } = useAccountWallets(accountId);

  // should always have equal or more wallets than payment methods
  // should always have a wallet for each payment method
  const wallets = data?.account?.wallets || [];

  const finalWallets = R.pipe(
    R.map((wallet) => {
      // try to find a matching payment method
      const matchingPaymentMethod = R.find(
        (pm) => pm.providerWalletId === wallet.id,
        walletPaymentMethods,
      );

      // if found, add it to the wallet
      return exists(matchingPaymentMethod) ? { ...wallet, pm: matchingPaymentMethod } : wallet;
    }),
    R.sortBy(R.prop("description")),
  )(wallets);

  const handleWalletChange = () => {
    refetch();
    onChange();
  };

  return <WalletsPanel wallets={finalWallets} onChange={handleWalletChange} />;
};

export const WalletsPanel = ({ wallets = [], onChange }) => {
  return (
    <Panel>
      <PanelTitle>Wallets ({wallets.length})</PanelTitle>
      <PanelContent>
        <DataOrNo data={wallets}>
          <Box maxW={"1600px"}>
            <SimpleGrid
              columns={{
                base: 1,
                lg: 2,
                xl: 3,
                "2xl": 3,
              }}
              spacing={8}
            >
              {wallets.map((wallet) => (
                <Wallet key={wallet.id} wallet={wallet} onChange={onChange} />
              ))}
            </SimpleGrid>
          </Box>
        </DataOrNo>
      </PanelContent>
    </Panel>
  );
};
