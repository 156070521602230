import {
  Box,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
} from "@chakra-ui/react";
import CloseButton from "components/Forms/Button/CloseButton";
import { LoadingIcon } from "components/Icons";

function Modal({ isOpen, onClose, title, size = "xl", isLoading = false, children }) {
  if (!onClose) {
    throw new Error("`onClose` is required");
  }
  return (
    <ChakraModal isCentered size={size} isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
          {isLoading && (
            <Box as="span" ml={3}>
              <LoadingIcon />
            </Box>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <CloseButton onClick={onClose} />
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}

export default Modal;
