import { Box, Flex, Heading, Text, Wrap } from "@chakra-ui/react";
import { AccountBlockTags } from "components/Account";
import { CopyableText } from "components/Copyable";
import IntegratorLogo from "components/IntegratorLogo/IntegratorLogo";
import { exists } from "utils";
import AccountTag from "./AccountTag";
import { useAccount } from "context/accountContext";
import { useTitle } from "context/titleContext";

export default function AccountHeader() {
  const account = useAccount();
  const { state: details } = account.details;

  const emailOrUsername = details.data?.email || details.data?.username;
  useTitle({ page: emailOrUsername }, { clear: true });

  return (
    <Flex as="header" alignItems="center" gap={3}>
      <IntegratorLogo integratorName={details.data?.integrator} />
      <Box>
        <Flex alignItems="center" gap={3}>
          <Heading as="h1" m={0} size="lg">
            {emailOrUsername}
          </Heading>
          <AccountBlockTags accountId={details.accountId} />
        </Flex>
        <CopyableText text={details.accountId}>
          <Text as="span" fontWeight={600} size="xs">
            {details.accountId}
          </Text>
        </CopyableText>
        {exists(details.data?.tags) && (
          <Wrap mt={2}>
            {details.data.tags.map((tag) => (
              <AccountTag key={tag.tag} tagName={tag.tag} />
            ))}
          </Wrap>
        )}
      </Box>
    </Flex>
  );
}
