import * as R from "ramda";
import { createAction } from "redux-actions";
import { debugReducer } from "../utils"; //eslint-disable-line no-unused-vars
import * as permissions from "context/permissions";
import * as invoicingApi from "api/invoicing";
import { STATUS } from "utils";
export { STATUS } from "utils";

// Careful with the distinction between 'transactions' and 'invoices' here.
// There is an expectation that transactions will eventually be comprised of
// more than invoices and this is where the transition from the public API,
// transactions' to the loading of invoices take place.

export const getTransactions = async (dispatch, accountId, user) => {
  if (!user.hasPermissions([permissions.READ_INVOICES], permissions.TRANSACTIONS_RESOURCE)) {
    dispatch(actions.getTransactionsPermissionsFail());
    return;
  }

  dispatch(actions.getInvoicesInit(accountId));
  try {
    const res = await invoicingApi.getInvoices(accountId, user);
    dispatch(actions.getInvoicesSuccess(res.payload));
  } catch (err) {
    dispatch(actions.getInvoicesError(err));
  }
};

export const actions = {
  getInvoicesInit: createAction("@tools/account/getInvoicesInit"),
  getInvoicesSuccess: createAction("@tools/account/getInvoicesSuccess"),
  getInvoicesError: createAction("@tools/account/getInvoicesError"),
  getTransactionsPermissionsFail: createAction("@tools/account/getInvoicesPermissionsFail"),
};

export const initialState = {
  transactions: {
    accountId: null,
    data: null,
    error: null,
    permissions: true,
    status: STATUS.PRE_INIT,
  },
};

export const handlers = {
  [actions.getInvoicesInit]: debugReducer((state, { payload }) =>
    R.pipe(
      R.assocPath(["transactions", "accountId"], payload),
      R.assocPath(["transactions", "error"], null),
      R.assocPath(["transactions", "status"], STATUS.INITIALIZED)
    )(state)
  ),
  [actions.getInvoicesSuccess]: (state, { payload }) =>
    R.pipe(
      R.assocPath(["transactions", "data"], payload),
      R.assocPath(["transactions", "status"], STATUS.SUCCESS),
      R.assocPath(["transactions", "error"], null)
    )(state),
  [actions.getInvoicesError]: debugReducer((state, { payload }) =>
    R.pipe(
      R.assocPath(["transactions", "error"], payload),
      R.assocPath(["transactions", "status"], STATUS.ERROR),
      R.assocPath(["transactions", "data"], null)
    )(state)
  ),
  [actions.getTransactionsPermissionsFail]: debugReducer((state) =>
    R.pipe(
      R.assocPath(["transactions", "permissions"], false),
      R.assocPath(["transactions", "status"], STATUS.ERROR),
      R.assocPath(["transactions", "data"], null),
      R.assocPath(["transactions", "error"], null)
    )(state)
  ),
};
