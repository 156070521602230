import { Box } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { theme } from "styled-tools";
import { useTitle } from "context/titleContext";

export const Page = styled.div`
  min-height: calc(100vh - 1px);
`;

export const PageContent = ({ title = {}, opts = {}, ...props }) => {
  useTitle(title, opts);
  return <Box data-testid="pagecontent" p="16px" {...props} />;
};

export const PageTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 0.33em;
`;

export const BadgePageTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
`;

export const StickyPageHeader = styled.div`
  position: sticky;
  top: 61px;
  background: ${theme("colors.pageBg")};
`;
