import React from "react";
import styled from "styled-components/macro";
import { theme } from "styles";
import { Panel, PanelTitle } from "components/Panel";
import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";

const GENERIC_ERROR_MESSAGE = "Apologies, there has been an error. Please try again.";

export const ErrorDiv = styled.div`
  color: ${theme("colors.error")};
`;

export const SectionError = ({ children = GENERIC_ERROR_MESSAGE }) => (
  <Panel>
    <PanelTitle>Error</PanelTitle>
    <ErrorDiv>{children}</ErrorDiv>
  </Panel>
);

export const ErrorAlert = ({ title, children }) => {
  return (
    <Alert status="error">
      <AlertIcon />
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </Alert>
  );
};
