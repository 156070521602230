import "react-day-picker/dist/style.css";

export const getBrowserTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (timeZone) {
    return timeZone;
  }
  // IANA named time zone is not available, best guess an equivalent Etc zone
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const hours = -Math.floor(offset / 60);
  return `Etc/GMT${hours < 0 ? "" : "+"}${hours}`;
};
