import React from "react";
import styled from "styled-components";
import { ifProp } from "styles";
import { container } from "./common";

const ColumnHeader = styled.div`
  padding: 8px;
  text-align: ${ifProp("right", "right")};
`;

const Container = styled.div`
  ${container}
`;

const GridHeader = () => {
  return (
    <Container data-testid="grid-header">
      <ColumnHeader>Date / ID</ColumnHeader>
      <ColumnHeader>Type/ Status</ColumnHeader>
      <ColumnHeader>Description</ColumnHeader>
      <ColumnHeader>Amount</ColumnHeader>
      <ColumnHeader>Counter Party</ColumnHeader>
      <ColumnHeader right>CP Amount</ColumnHeader>
      <div />
    </Container>
  );
};

export default GridHeader;
