import { Box, Card, CardBody, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import { CopyableText } from "components/Copyable";
import { Money } from "components/Money";
import { AccountLink } from "components/Account";
import { DetailText } from "components/Text";
import BalanceTransferItemTypeName from "./BalanceTransferItemTypeName";
import { isNilOrEmpty } from "utils";

const BlockTitle = ({ children = "" }) => (
  <Box size="xs" mb={1} sx={{ "font-variant": "small-caps" }}>
    {children.toLowerCase()}
  </Box>
);

const WalletBlock = ({ wallet }) => {
  if (isNilOrEmpty(wallet)) {
    return null;
  }
  return (
    <Box m={1} p={1}>
      <BlockTitle>Wallet</BlockTitle>
      <DetailText>
        <Box>{wallet.description}</Box>
        <CopyableText text={wallet.id} />
      </DetailText>
    </Box>
  );
};

function BalanceTransferItems({ subItems }) {
  const bg = useColorModeValue("gray.50", "gray.800");
  if (subItems.length < 1) {
    return null;
  }

  return (
    <Card>
      <CardBody>
        {subItems.map((subItem) => {
          return (
            <SimpleGrid columns={{ base: 1, xl: 3 }} spacing={2} p={2} mb={2} bg={bg}>
              <Box display={{ base: "none", xl: "block" }}>
                <BlockTitle>Source</BlockTitle>
              </Box>
              <Box display={{ base: "none", xl: "block" }}>
                <BlockTitle>Type</BlockTitle>
              </Box>
              <Box display={{ base: "none", xl: "block" }}>
                <BlockTitle>Destination</BlockTitle>
              </Box>
              <Box>
                <AccountLink account={subItem.sourceAccount} includeAvatar={true}>
                  {subItem.sourceAccount.username || subItem.sourceAccount.accountId}
                </AccountLink>
                <Box pl={6}>
                  <WalletBlock wallet={subItem.sourceWallet} />
                </Box>
              </Box>
              <Box pl={8}>
                <BalanceTransferItemTypeName balanceTransferItemType={subItem.subItemType} />
                <br />
                <Money value={subItem.amount} currency={subItem.currency} />
              </Box>
              <Box>
                <AccountLink account={subItem.destinationAccount} includeAvatar={true}>
                  {subItem.destinationAccount.username || subItem.destinationAccount.accountId}
                </AccountLink>
                <Box pl={6}>
                  <WalletBlock wallet={subItem.destinationWallet} />
                </Box>
              </Box>
            </SimpleGrid>
          );
        })}
      </CardBody>
    </Card>
  );
}

export default BalanceTransferItems;
