import { InvoicingApiInvoiceType } from "../../invoicingApi/index.js";

/**
 * Token conversion transaction status codes.
 * @enum {string}
 */
export const TokenConversionStatusCode = Object.freeze({
  OPEN: "OPEN",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
});

/**
 * Token Conversion transaction invoice types.
 * @type {Set<InvoicingApiInvoiceType>}
 */
export const TokenConversionInvoiceTypes = new Set([InvoicingApiInvoiceType.TokenConvertIT]);
