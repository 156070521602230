import { Flex, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { InfoIcon } from "components/Icons";

export default function InfoTooltip({ label }) {
  const icon = useColorModeValue("gray.500", "gray.200");

  return (
    <Tooltip placement="top" hasArrow label={label} fontSize={"md"}>
      <Flex ml={1.5} role="tooltip" color={icon}>
        <InfoIcon size={0.8} />
      </Flex>
    </Tooltip>
  );
}
