import { css } from "styled-components";
import { theme } from "styles";

export const row = css`
  align-items: center;
  border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
  display: grid;
  overflow-wrap: anywhere;
  grid-template-columns: 30px 1fr 1fr 1.5fr 1fr 1fr 1fr 120px;
  > div {
    padding-left: 5px;
    padding-right: 5px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      padding-left: 0;
      * {
        float: right;
      }
    }
  }
`;
