import React from "react";
import "styled-components/macro";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import { Panel } from "components/Panel";
import { isNilOrEmpty } from "utils";
import { Box, HighlightFilter, GroupList, AgentLink } from "./common";
import { useTitle } from "context/titleContext";

const AgentList = ({ agentMap, data, filter }) => (
  <div>
    {R.pipe(
      R.sortBy((d) => agentMap[d].username),
      R.map((d) => <AgentLink agent={agentMap[d]} key={d} filter={filter} />)
    )(data)}
  </div>
);

export const ByPermission = ({ data, filter }) => {
  const { scope } = useParams();
  const { scopeAccountMap, scopeGroupMap = {} } = data.permissionsModel;
  const scopes = R.sortBy(R.identity, R.keys(scopeAccountMap));

  useTitle({ sub1: "By Permission", sub2: scope });
  return (
    <Panel>
      <div
        css={`
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 4px;
        `}
      >
        <Box>Permission</Box>
        <Box>Groups</Box>
        <Box>Agents</Box>
        {R.pipe(
          R.filter((s) => isNilOrEmpty(scope) || scope === s),
          R.filter((scope) => {
            if (isNilOrEmpty(filter)) return true;
            const usernames = R.map((g) => data.agentMap[g].username)(scopeAccountMap[scope]);
            return R.any(R.test(filter))([scope, ...scopeGroupMap[scope], ...usernames]);
          }),
          R.map((scope) => (
            <div key={scope} css="display: contents;">
              <Box>
                <HighlightFilter filter={filter}>{scope}</HighlightFilter>
              </Box>
              <Box>
                <GroupList data={scopeGroupMap[scope]} filter={filter} />
              </Box>
              <Box>
                <AgentList agentMap={data.agentMap} data={scopeAccountMap[scope]} filter={filter} />
              </Box>
            </div>
          ))
        )(scopes)}
      </div>
    </Panel>
  );
};
