import React, { useCallback, useMemo, useEffect, useReducer } from "react";
import * as accountApi from "api/account";
import { buildPermissionsModel } from "./permissionsModel";
import { STATUS } from "utils";
import * as R from "ramda";
import { useAgent } from "./agentContext";
import { isNilOrEmpty } from "utils";

export const CHANGE_ACCOUNTS = "change_accounts";
export const CHANGE_ANY_PERSONAS = "change_any_personas";
export const CHANGE_EMAILS = "change_emails";
export const CREATE_NONCE = "create_nonce";
export const DELETE_EMAILS = "delete_emails";
export const DELETE_GDPR_DOCUMENTS = "delete_gdpr_documents";
export const DELETE_SQAS = "delete_sqas";
export const GDPR_DELETE_EMAILS = "gdpr_delete_emails";
export const MANAGE_CLIENTS = "manage_clients";
export const MANAGE_APPS = "manage_apps";
export const PERSONA_INFO = "persona_info";
export const READ_ACCOUNT_NOTES = "read_account_notes";
export const READ_ACCOUNTS = "read_accounts";
export const READ_ADDRESSES = "read_addresses";
export const READ_ANY_PERSONAS = "read_any_personas";
export const READ_AUDIT_LOGS = "read_audit_logs";
export const READ_CARD_DETAILS = "read_card_details";
export const READ_DASHBOARD = "read_dashboard";
export const READ_DOBS = "read_dobs";
export const READ_EMAILS = "read_emails";
export const READ_GDPR_DOCUMENTS = "read_gdpr_documents";
export const READ_INTEGRATORS = "read_integrators";
export const READ_INVOICE = "read_invoice";
export const READ_INVOICES = "read_invoices";
export const READ_KYCS = "read_kycs";
export const READ_KYC_SSNS = "read_kyc_ssns";
export const READ_KYC_PIIS = "read_kyc_piis";
export const READ_KYB = "read_kyb";
export const WRITE_KYB = "write_kyb";
export const READ_NAMES = "read_names";
export const READ_PAYMENT_METHODS = "read_payment_methods";
export const READ_PERMISSIONS = "read_permissions";
export const READ_PROCESS_CREDITS = "read_process_credits";
export const READ_PRODUCT_CATALOG = "read_tax_code";
export const READ_SCOPES = "read_scopes";
export const READ_SETTINGS = "read_settings";
export const READ_SETTLEMENTS = "read_settlements";
export const READ_SSO_CREDENTIALS = "read_sso_credentials";
export const READ_WALLETS = "read_wallets";
export const READ_WATCHLISTS = "read_watchlists";
export const READ_WEBHOOKS = "read_webhooks";
export const SEARCH_ACCOUNTS = "search_accounts";
export const VERIFY_KYCS = "verify_kycs";
export const WRITE_ACCOUNTS = "write_accounts";
export const WRITE_DEPOSITS = "write_deposits";
export const WRITE_KYC_PIIS = "write_kyc_piis";
export const WRITE_KYCS = "write_kycs";
export const WRITE_NOTES = "write_notes";
export const WRITE_PAYMENT_METHODS = "write_payment_methods";
export const WRITE_PERMISSIONS = "write_permissions";
export const WRITE_PROCESS_CREDITS = "write_process_credits";
export const WRITE_PRODUCT_CATALOG = "write_tax_code";
export const WRITE_REGISTRATIONS = "write_registrations";
export const WRITE_REFUNDS = "write_refunds";
export const WRITE_SCOPES = "write_scopes";
export const WRITE_SETTINGS = "write_settings";
export const WRITE_SETTLEMENTS_REVIEW = "write_settlements_review";
export const WRITE_SETTLEMENTS_APPROVE = "write_settlements_approve";
export const WRITE_SETTLEMENTS_SUPER_APPROVE = "write_settlements_super_approve";
export const WRITE_SETTLEMENTS_PAY = "write_settlements_pay";
export const WRITE_SSO_CREDENTIALS = "write_sso_credentials";
export const WRITE_WATCHLISTS = "write_watchlists";
export const WRITE_WEBHOOKS = "write_webhooks";

// Resource Constants
export const ALL_WEBHOOKS_RESOURCE = "all_webhooks_resource";
export const ACTIONS_RESOURCE = "actions_resource";
export const CLIENTS_RESOURCE = "clients_resource";
export const DETAILS_RESOURCE = "details_resource";
export const GDPR_RESOURCE = "gdpr_resource";
export const ENTITY_BASIC_RESOURCE = "entity_basic_resource";
export const ENTITY_DETAILS_RESOURCE = "entity_basic_resource";
export const KYC_BASIC_RESOURCE = "kyc_basic_resource";
export const KYC_DETAILS_RESOURCE = "kyc_details_resource";
export const PAYMENTS_RESOURCE = "payments_resource";
export const PAYOUTS_RESOURCE = "payouts_resource";
export const PERMISSIONS_RESOURCE = "permissions_resource";
export const PUBLISHER_RESOURCE = "publisher_resource";
export const SEARCH_RESOURCE = "search_resource";
export const TRANSACTIONS_RESOURCE = "transaction_resource";
export const WATCHLIST_RESOURCE = "watchlist_resource";
export const WEBHOOKS_RESOURCE = "webhooks_resource";
export const WRITE_DETAILS_RESOURCE = "write_details_resource";
export const WRITE_DETAILS_ACTIONS_RESOURCE = "write_details_actions_resource";
export const WRITE_EMAILS_RESOURCE = "write_emails_resource";

export const _NO_ = "__no_permissions__";

// the list of permissions required to log in to the tools ui
// a user with ANY of these permissions will be considered authorized
export const toolsPermissions = [
  READ_ACCOUNT_NOTES,
  READ_DOBS,
  READ_EMAILS,
  READ_INVOICES,
  READ_KYC_PIIS,
  READ_KYCS,
  READ_NAMES,
  READ_ADDRESSES,
  READ_PERMISSIONS,
  READ_PROCESS_CREDITS,
  WRITE_PROCESS_CREDITS,
  READ_SETTINGS,
  READ_WEBHOOKS,
  SEARCH_ACCOUNTS,
  VERIFY_KYCS,
  READ_DASHBOARD,
];

export const permissionsSatisfy = (requiredPermissions = [], userPermissions = []) => {
  // if we're asked for nothing => true
  if (isNilOrEmpty(requiredPermissions)) {
    return true;
  }

  // ensure array
  const permissionsList = [].concat(requiredPermissions);

  // all requred must be satisfied, otherwise false
  const userIncludes = R.includes(R.__, userPermissions);
  return R.all(userIncludes)(permissionsList);
};

export const permissionsSatisfyAny = (requiredPermissions = [], userPerms = []) => {
  const userPermissions = userPerms ?? [];
  // if we're asked for nothing => true
  if (isNilOrEmpty(requiredPermissions)) {
    return true;
  }

  // ensure array
  const permissionsList = [].concat(requiredPermissions);

  // any required permission is sufficient
  const userIncludes = R.includes(R.__, userPermissions);
  return R.any(userIncludes)(permissionsList);
};

export const withPermissions = ({ permissions: requiredPermissions, resource }, Component) => {
  const C = (props) => {
    const { agent } = useAgent();
    const permissionsData = useMemo(
      () => ({
        hasPermission: agent.hasPermissions(requiredPermissions, resource),
        permissions: requiredPermissions,
      }),
      [agent],
    );
    return <Component {...props} permissions={permissionsData} user={agent} />;
  };
  return C;
};

export const useSystemPermissionsModel = () => {
  const { agent } = useAgent();
  const reducer = (state, action) => {
    switch (action.type) {
      case "PERMISSIONS_INIT":
        return {
          ...state,
          data: {},
          status: STATUS.INITIALIZED,
        };
      case "PERMISSIONS_SUCCESS":
        return {
          ...state,
          data: action.payload,
          status: STATUS.SUCCESS,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    data: {},
    status: STATUS.PRE_INIT,
  });

  const getPermissionData = useCallback(async () => {
    dispatch({ type: "PERMISSIONS_INIT" });
    const allScopes = await accountApi.getAllScopes(agent);
    const toolsGroupData = await accountApi.getToolsGroups(agent);
    const toolsPermissionsData = await accountApi.getToolsPermissions(agent);
    const permissionsModel = buildPermissionsModel(
      toolsGroupData.payload,
      toolsPermissionsData.payload,
      allScopes.payload,
    );

    const agentIds = R.keys(permissionsModel.accountScopeMap);
    const responses = await Promise.all(
      agentIds.map((id) => accountApi.getAccountUserInfo(id, agent)),
    );
    const agentMap = R.pipe(R.pluck("payload"), R.indexBy(R.prop("account_id")))(responses);
    dispatch({ type: "PERMISSIONS_SUCCESS", payload: { agentMap, permissionsModel } });
  }, [agent]);

  useEffect(() => {
    if (agent.hasPermissions([READ_PERMISSIONS], PERMISSIONS_RESOURCE)) {
      getPermissionData();
    }
  }, [agent, getPermissionData]);

  const refreshData = useCallback(() => {
    getPermissionData();
  }, [getPermissionData]);

  return { state, refreshData };
};
