import { CurrencyDisplay } from "./types.js";

/**
 * Converts the value to a BigInt if necessary.
 *
 * @todo This function can go away after the app-wide conversion to using bigint
 *   to represent all currency values.
 *
 * @param {bigint|Number|string} value
 * @returns {bigint}
 * @throws {Error} if the value is not a bigint, number, or string, or if the
 *   number or string value is not integral
 */
function ensureBigInt(value) {
  switch (typeof value) {
    case "bigint":
      return value; // already the correct type

    case "number":
    case "string":
      return BigInt(value); // will throw if value is not integral

    default:
      throw new Error(`Error: Invalid type (${typeof value}) for value`);
  }
}

/**
 * Some virtual currencies use one value for fractional precision (minorUnit), but
 * round to a smaller number of digits for display (displayMinorUnit). Returns the
 * rounded integerPart and fractionPart values.
 *
 * @param {bigint} value Absolute value
 * @param {bigint} minorUnit
 * @param {bigint} displayMinorUnit
 * @returns {Object<{integerPart: bigint, fractionPart: bigint}>}
 */
function roundValue(value, minorUnit, displayMinorUnit) {
  let integerPart = value / 10n ** minorUnit;

  // truncate the fraction part to the display unit length
  const truncateDigits = minorUnit - displayMinorUnit;
  let fractionPart = (value / 10n ** truncateDigits) % 10n ** displayMinorUnit;

  // retrieve the digit just after the truncated digit
  const roundingDigit = (value / 10n ** (truncateDigits - 1n)) % 10n;

  // Tilia uses the "away from zero" rounding rule, which means that positive numbers
  // round up and negative numbers round down. Rounding up is equivalent to away from
  // zero in this function because it is called with an absolute value.
  if (roundingDigit >= 5n) {
    if (displayMinorUnit > 0n) {
      fractionPart++;
    } else {
      integerPart++;
    }
  }
  // round down case is handled inherently due to truncation

  return { integerPart, fractionPart };
}

/**
 * Returns a formatter function which accepts a Tilia currency value (the value in the
 * lowest-common denomination of that currency, for example cents for "USD") as a BigInt,
 * Number, or string, and returns a formatted string according to the specified locale.
 *
 * @param {string} locale
 * @param {string} currency
 * @param {CurrencyDisplay} currencyDisplay
 * @param {CurrencyInfo} currencyInfo
 * @returns {function(value: bigint|Number|string): string}
 */
function createCurrencyFormatter(
  locale,
  currency,
  currencyDisplay = CurrencyDisplay.Symbol,
  currencyInfo
) {
  let minorUnit; // fraction digits
  let displayMinorUnit; // fraction digits for display
  let formatter;
  let virtualCurrencySymbol = undefined;

  if (Object.hasOwn(currencyInfo, currency)) {
    const virtualCurrency = currencyInfo[currency];

    // The settings values always presume US-English locale for API responses, and use basic string
    // concatenation for formatting, so the defined symbol sometimes includes a trailing space.
    // Strip it here for proper localized handling. Use the currency code as the symbol if one is
    // not configured.
    virtualCurrencySymbol = virtualCurrency.symbol?.trim() || virtualCurrency.currency;

    // TODO: New tests here
    // If the requested currencyDisplay is to use a symbol, but the virtual currency's "symbol" is
    // the same as its currency code, switch the currencyDisplay to CurrencyDisplay.Code instead
    // for proper localized handling of spacing.
    if (
      (currencyDisplay === CurrencyDisplay.Symbol ||
        currencyDisplay === CurrencyDisplay.NarrowSymbol) &&
      virtualCurrencySymbol === virtualCurrency.currency
    ) {
      currencyDisplay = CurrencyDisplay.Code;
    }

    minorUnit = virtualCurrency.minor_unit;
    displayMinorUnit = virtualCurrency.display_minor_unit ?? virtualCurrency.minor_unit;
    formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      signDisplay: "always", // workaround for BigInt and negative zero; see below
      currency: "USD", // use USD to get the correct localized number format; symbol is swapped below
      currencyDisplay,
      minimumFractionDigits: displayMinorUnit,
      maximumFractionDigits: displayMinorUnit,
    });

    switch (currencyDisplay) {
      case CurrencyDisplay.Symbol: // no distinction between these two for Tilia virtual currencies
      case CurrencyDisplay.NarrowSymbol:
        break;

      case CurrencyDisplay.Name:
        // no current equivalent (i.e., "US dollars") in settings; use currency code for now
        virtualCurrencySymbol = virtualCurrency.currency;
        break;

      case CurrencyDisplay.Code:
      default:
        virtualCurrencySymbol = virtualCurrency.currency;
    }
  } else {
    // Fiat currency, or unknown virtual currency: use the formatting settings from the Intl library.
    formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      signDisplay: "always",
      currency,
      currencyDisplay,
    });
    const options = formatter.resolvedOptions();
    minorUnit = options.minimumFractionDigits;
    displayMinorUnit = options.minimumFractionDigits;
  }

  return (value) => {
    value = ensureBigInt(value);

    const isNegative = value < 0n;
    if (isNegative) {
      value *= -1n;
    }

    let integerPart;
    let fractionPart;

    if (displayMinorUnit < minorUnit) {
      const roundedValue = roundValue(value, BigInt(minorUnit), BigInt(displayMinorUnit));
      integerPart = roundedValue.integerPart;
      fractionPart = roundedValue.fractionPart;
    } else {
      const fractionDivisor = 10n ** BigInt(minorUnit);
      integerPart = value / fractionDivisor;
      fractionPart = value % fractionDivisor;
    }

    if (isNegative) {
      integerPart *= -1n;
    }

    // Only the integer part is initially formatted to ensure the value is always interpreted
    // as a BigInt (some implementations will interpret strings as lossy Number values). Because
    // we configure the formatter with minimumFractionDigits, there will always be a formatted
    // fractional part, all zeros. We format to parts to easily swap out this fractional part
    // and set the appropriate currency symbol when needed for a Tilia virtual currency, then
    // re-assemble into a contiguous string.

    return formatter
      .formatToParts(integerPart)
      .map(({ type, value }) => {
        switch (type) {
          case "plusSign":
            // NumberFormat is configured to always include the sign to work around a limitation
            // where BigInt doesn't have a negative zero, so the integer part may lose its sign
            // for values that are smaller than one unit
            return isNegative ? "-" : "";
          case "fraction":
            return fractionPart.toString().padStart(displayMinorUnit, "0");
          case "currency":
            return virtualCurrencySymbol ?? value;
          default:
            return value;
        }
      })
      .join("");
  };
}

export default createCurrencyFormatter;
