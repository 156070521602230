import React, { useEffect } from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import * as R from "ramda";
import {
  READ_PERMISSIONS,
  PERMISSIONS_RESOURCE,
  withPermissions,
  useSystemPermissionsModel,
} from "context/permissions";
import { useAccount } from "context/accountContext";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import { SectionSpinner } from "components/Spinner";
import { PermissionsMessage } from "components/PermissionsMessage";
import { isNilOrEmpty, STATUS, statusLoading } from "utils";
import { GroupsColumn } from "./Groups";
import { PermissionRow } from "./common";
import { useTitle } from "context/titleContext";

const getAccountId = R.path(["accountState", "details", "accountId"]);
const getPermissions = R.path(["permissions"]);

const PermsColumn = ({ scopesWithGroup = [] }) => (
  <Panel>
    {isNilOrEmpty(scopesWithGroup) && <PermissionRow title="No Permissions"></PermissionRow>}
    <PanelTitle>Permissions</PanelTitle>
    {R.pipe(
      R.mapObjIndexed((value, key) => (
        <PermissionRow
          key={key}
          title={key}
          data={R.pipe(R.pluck("group"), R.uniq, R.sortBy(R.identity), R.join(", "))(value)}
        />
      )),
      R.values
    )(scopesWithGroup)}
  </Panel>
);

export const Permissions = withPermissions(
  {
    permissions: [READ_PERMISSIONS],
    resource: PERMISSIONS_RESOURCE,
  },
  ({ permissions: agentPermissions }) => {
    useTitle({ sub1: "Permissions" });
    const { state: systemPermissions, refreshData } = useSystemPermissionsModel();
    const accountContext = useAccount();
    const accountId = getAccountId(accountContext);
    const permissionsData = getPermissions(accountContext);

    const groupScopeMap = R.path(["data", "permissionsModel", "groupScopeMap"])(systemPermissions);
    const groupGroupMap = R.path(["data", "permissionsModel", "groupGroupMap"])(systemPermissions);

    const allGroups = R.pipe(
      R.defaultTo({}),
      R.keys,
      R.concat(R.keys(groupGroupMap)),
      R.uniq,
      R.sortBy(R.identity)
    )(groupScopeMap);

    const accountPerms = R.defaultTo(
      {},
      R.path(["data", "permissionsModel", "byAccount", accountId])(systemPermissions)
    );

    const groupedByScope = R.groupBy(R.prop("scope"))(accountPerms?.scopes || []);

    useEffect(() => {
      if (permissionsData.status === STATUS.PRE_INIT) {
        permissionsData.getPermissions(accountId);
      }
    }, [accountId, permissionsData]);

    if (!agentPermissions.hasPermission) {
      return <PermissionsMessage />;
    }
    if (statusLoading(permissionsData.status) || statusLoading(systemPermissions.status)) {
      return <SectionSpinner />;
    }

    const handlePermissionsChange = () => {
      refreshData();
      permissionsData.getPermissions(accountId);
    };

    return (
      <Panel>
        <PanelContent>
          <div
            css={`
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
              grid-column-gap: 16px;
            `}
          >
            <PermsColumn scopesWithGroup={groupedByScope} />
            <GroupsColumn
              accountId={accountId}
              accountGroups={accountPerms.groups}
              allGroups={allGroups}
              onChange={handlePermissionsChange}
            />
          </div>
        </PanelContent>
      </Panel>
    );
  }
);
