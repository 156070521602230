import React from "react";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { ifProp, theme } from "styles";
import {
  ChevronLeft,
  CloseIcon,
  DownloadIcon,
  FilterClearIcon,
  LoadingIcon,
  NextIcon,
  PrevIcon,
} from "components/Icons";

export const button = css`
  background-color: transparent;
  border: 1px solid transparent;
  border-collapse: separate;
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  font-size: 0.875em;
  margin: 0;
  min-height: 32px;
  min-width: 32px;
  overflow: hidden;
  padding: 4px 16px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  transition: 0.2s all ease-in-out;
  vertical-align: middle;
  width: auto;
  user-select: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const textButton = css`
  background: ${theme("colors.button.text.bg")};
  border-color: ${theme("colors.button.text.border")};
  color: ${theme("colors.button.text.color")};
  &:hover {
    background: ${theme("colors.button.text.hover.bg")};
    border-color: ${theme("colors.button.text.hover.border")};
    color: ${theme("colors.button.text.hover.color")};
  }
`;

export const TextLinkButton = styled.button`
  ${button};
  ${textButton};
`;

export const PrimaryButton = styled.button`
  ${button};
  background: ${theme("colors.button.primary.bg")};
  border-color: ${theme("colors.button.primary.border")};
  color: ${theme("colors.button.primary.color")};
  &:hover {
    background: ${theme("colors.button.primary.hover.bg")};
    border-color: ${theme("colors.button.primary.hover.border")};
  }
  ${ifProp(
    "disabled",
    css`
      border-color: ${theme("colors.button.disabled.border")};
      color: ${theme("colors.button.disabled.color")};
      background-color: ${theme("colors.button.disabled.bg")};
      &:hover {
        cursor: initial;
        background-color: ${theme("colors.button.disabled.bg")};
      }
    `
  )};
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const ErrorButton = styled.button`
  ${button};
  background: ${theme("colors.button.error.bg")};
  border-color: ${theme("colors.button.error.border")};
  color: ${theme("colors.button.error.color")};
  &:hover {
    background: ${theme("colors.button.error.hover.bg")};
    border-color: ${theme("colors.button.error.hover.border")};
  }
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const outlineButton = css`
  ${button};
  background: ${theme("colors.button.outline.bg")};
  border-color: ${theme("colors.button.outline.border")};
  color: ${theme("colors.button.outline.color")};
  &:hover {
    background: ${theme("colors.button.outline.hover.bg")};
    color: ${theme("colors.button.outline.hover.color")};
  }
`;

export const OutlineButton = styled.button`
  ${outlineButton};
`;

export const ButtonIconWrapper = styled.span`
  margin: 4px 0 0 8px;
`;

export const ButtonSpinner = () => (
  <ButtonIconWrapper data-testid="spinner">
    <LoadingIcon />
  </ButtonIconWrapper>
);

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  background: ${theme("colors.button.icon.bg")};
  color: ${theme("colors.button.icon.color")};
  &:hover {
    cursor: pointer;
    background: ${theme("colors.button.icon.hover.bg")};
  }
  &:active {
    background: #eee;
    background: ${theme("colors.button.icon.active.bg")};
  }

  ${ifProp(
    "disabled",
    css`
      border-color: ${theme("colors.button.disabled.border")};
      color: ${theme("colors.button.disabled.color")};
      background-color: ${theme("colors.button.disabled.bg")};
      &:hover {
        cursor: initial;
        background-color: ${theme("colors.button.disabled.bg")};
      }
    `
  )};
`;

export const PrevButton = (props) => (
  <IconButton {...props}>
    <PrevIcon />
  </IconButton>
);
export const NextButton = (props) => (
  <IconButton {...props}>
    <NextIcon />
  </IconButton>
);

export const CloseButton = (props) => (
  <IconButton {...props}>
    <CloseIcon />
  </IconButton>
);

export const DownloadButton = (props) => (
  <IconButton {...props}>
    <DownloadIcon />
  </IconButton>
);

export const BackButton = (props) => (
  <TextLinkButton {...props}>
    <ChevronLeft />
    back
  </TextLinkButton>
);

export const FilterItemButton = ({ children, ...props }) => (
  <OutlineButton
    css={`
      ${outlineButton};
      min-height: auto;
      min-width: auto;
      border-radius: 2em;
      padding: 0.5em 0.5em 0.5em 1em;
      margin: 0.2em 0.7em 0 0;
      text-transform: none;
      display: inline-flex;
      > svg {
        margin-left: 0.3em;
      }
    `}
    {...props}
  >
    {children}
    <FilterClearIcon />
  </OutlineButton>
);

export const ButtonSet = styled.div`
  display: flex;
  justify-content: flex-end;
  & > * {
    margin-left: 8px;
  }
`;
