import { Grid, GridItem, Text, useColorModeValue } from "@chakra-ui/react";

const GRID_TEMPLATE_COLUMNS = {
  base: "1fr 200px 5px",
  "2xl": "1fr 200px 70px",
};

export const TransactionItemGrid = ({ children, ...rest }) => {
  return (
    <Grid textAlign="left" width="100%" templateColumns={GRID_TEMPLATE_COLUMNS} gap={4} {...rest}>
      {children}
    </Grid>
  );
};

export const TransactionItemGridRight = (props) => (
  <GridItem textAlign="right" paddingRight={6} {...props} />
);

export const TransactionItemGridAmount = (props) => {
  const gray700 = useColorModeValue("gray.700", "gray.200");
  return <Text as="span" color={gray700} fontWeight={700} {...props} />;
};
