import { gql, useQuery } from "@apollo/client";
import { Flex, Stack, Text } from "@chakra-ui/react";
import { AccountLink } from "components/Account";
import AccountAvatar from "components/Account/AccountAvatar";
import { Spinner } from "components/Spinner";
import { DetailText } from "components/Text";

export const getAccountCardDetails = gql`
  query GetAccountCardDetails($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      username
      fullName
      email
    }
  }
`;

function AccountCard({ accountId }) {
  const { loading, data } = useQuery(getAccountCardDetails, {
    variables: {
      accountId,
    },
    errorPolicy: "all",
  });

  if (loading) {
    return <Spinner />;
  }

  if (!data?.account) {
    return <Text>Missing account informaton</Text>;
  }

  const name = data.account.fullName || data.account.username;
  const email = data.account.email !== name ? data.account.email : undefined;

  return (
    <Stack>
      <AccountLink account={data.account}>
        <Flex as="span" alignItems={"center"} gap={2}>
          <AccountAvatar account={data.account} />

          <DetailText>
            <Flex as="span" direction="column" gap="2px">
              {name && <span>{name}</span>}
              {email && <span>{email}</span>}
            </Flex>
          </DetailText>
        </Flex>
      </AccountLink>
    </Stack>
  );
}

export default AccountCard;
