import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import * as R from "ramda";
import { DownloadButton } from "components/Button";
import { exists, formatDateTimeFilename } from "utils";

const getScopesWithData = (data) => (agent) =>
  data.permissionsModel.accountScopeMap[agent.account_id];

const toDownloadData = (data) => {
  const getScopes = getScopesWithData(data);

  return R.pipe(
    R.prop("agentMap"),
    R.values,
    R.map((agent) => R.assoc("scopes", getScopes(agent), agent)),
    R.groupBy(R.prop("integrator"))
  )(data);
};

export const Download = ({ data }) => {
  const downloadRef = useRef(null);
  const [downloadData, setDownloadData] = useState(null);
  useEffect(() => {
    if (exists(downloadData)) {
      downloadRef.current.click();
    }
  }, [downloadData]);

  const handleDownload = () => {
    const downloadData = URL.createObjectURL(
      new Blob([JSON.stringify(toDownloadData(data), null, 2)], {
        type: "application/json",
      })
    );
    setDownloadData(downloadData);
  };
  return (
    <>
      <a
        ref={downloadRef}
        css={`
          visibility: hidden;
          width: 0;
        `}
        href={downloadData}
        download={`permissions-${formatDateTimeFilename(new Date())}.json`}
      >
        .
      </a>
      <DownloadButton data-testid="permissions-download-button" onClick={handleDownload} />
    </>
  );
};
