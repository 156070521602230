import * as R from "ramda";
import { createAction } from "redux-actions";
import { debugReducer } from "../utils"; //eslint-disable-line no-unused-vars
import * as permissions from "context/permissions";
import * as accountApi from "api/account";

export const getEmails = async (dispatch, accountId, user) => {
  if (!user.hasPermissions([permissions.READ_EMAILS])) {
    dispatch(actions.getEmailsPermissionsFail());
    return;
  }
  try {
    dispatch(actions.getEmailsInit(accountId));
    const { payload } = await accountApi.getAccountEmails(accountId, user);
    dispatch(actions.getEmailsSuccess(payload));
  } catch (err) {
    dispatch(actions.getEmailsError(err));
  }
};

export const actions = {
  getEmailsInit: createAction("@tools/account/getEmailsInit"),
  getEmailsSuccess: createAction("@tools/account/getEmailsSuccess"),
  getEmailsError: createAction("@tools/account/getEmailsError"),
  getEmailsPermissionsFail: createAction("@tools/account/getEmailsPermissionsFail"),
};

export const initialState = {
  emails: {
    accountId: null,
    data: null,
    error: null,
    permissions: true,
    ready: false,
  },
};

export const handlers = {
  [actions.getEmailsInit]: debugReducer((state, { payload }) =>
    R.pipe(R.assocPath(["emails", "accountId"], payload), R.dissocPath(["emails", "error"]))(state)
  ),
  [actions.getEmailsSuccess]: (state, { payload }) =>
    R.pipe(
      R.assocPath(["emails", "data"], payload),
      R.assocPath(["emails", "ready"], true),
      R.dissocPath(["emails", "error"])
    )(state),
  [actions.getEmailsError]: debugReducer((state, { payload }) =>
    R.pipe(
      R.assocPath(["emails", "error"], payload),
      R.assocPath(["emails", "ready"], true),
      R.dissocPath(["emails", "data"])
    )(state)
  ),
  [actions.getEmailsPermissionsFail]: debugReducer((state) =>
    R.pipe(
      R.assocPath(["emails", "permissions"], false),
      R.assocPath(["emails", "ready"], true),
      R.dissocPath(["emails", "data"]),
      R.dissocPath(["emails", "error"])
    )(state)
  ),
};
