/* eslint-disable no-console */
const GLOBAL_REDUCER_DEBUG = false;

export const A = 0x01;
export const S = 0x10;

// console logger for actions and state in reducers
export const debugReducer =
  (fn, printFlags = 0x00) =>
  (state, action) => {
    const printAction = GLOBAL_REDUCER_DEBUG || printFlags & A;
    if (printAction) {
      console.log("[ACTION]", action);
    }
    const newState = fn(state, action);
    if (printFlags & S) {
      console.log("[STATE]", action.type, newState);
    }
    return newState;
  };
