import * as R from "ramda";
import qs from "qs";
import { noop, isNilOrEmpty } from "@tilia-tools/utils";
export {
  TILIA,
  daysToSeconds,
  ensureArray,
  exists,
  formatDate,
  formatDateTime,
  formatDateTimeFilename,
  formatDiffDuration,
  formatDistanceToNow,
  formatRelativePast,
  formatTime,
  fromB64JSON,
  getMillis,
  isBeforeNow,
  isBooleanTrue,
  isFalse,
  isFunction,
  isNilOrEmpty,
  isNotArray,
  isString,
  isTrue,
  isTrueStringIgnoreCase,
  mapIndex,
  noop,
  toJSONB64,
} from "@tilia-tools/utils";

export { ENVIRONMENT, ENVIRONMENT_NAMES, getEnvironment, isProd } from "./environment";

// function keyword required to get `arguments` property
export const debugFn = function () {
  // eslint-disable-next-line no-console
  console.debug.apply(console, [`[TOOLS]`].concat(Array.prototype.slice.call(arguments)));
};
export const debug = (ctx, ...args) => ctx && ctx.debug && ctx.debug(...args);
export const debugPre =
  (prefix) =>
  (ctx, ...args) =>
    ctx && ctx.debug && ctx.debug(prefix, ...args);
export const debugOff = noop;

const parse = (search) => qs.parse(search, { ignoreQueryPrefix: true });

export const getSearchQueryString = (search) => {
  const qsQuery = R.prop("q", parse(search));
  return qsQuery ? qsQuery.trim() : null;
};

export const getQueryString = (search) => parse(search);
export const getLocQueryString = (loc) => parse(loc.search);

export const getTokenFromJWT = (sessionJWT) => {
  if (isNilOrEmpty(sessionJWT)) return {};
  return JSON.parse(atob(sessionJWT.split(".")[1]));
};
export const decodeToken = getTokenFromJWT;

export const debounce = (func, timeout = 50) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const STATUS = {
  PRE_INIT: "PRE_INIT",
  INITIALIZED: "INITIALIZED",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};
export const statusLoading = (s) => s === STATUS.PRE_INIT || s === STATUS.INITIALIZED;

export const validRegexOrNull = (str) =>
  isNilOrEmpty(str) || !isValidRegExpString(str) ? null : new RegExp(str, "gi");

export const isValidRegExpString = (input) => {
  try {
    new RegExp(input);
    return true;
  } catch (e) {
    return false;
  }
};
