import React, { useEffect } from "react";
import "styled-components/macro";
import { useParams, Route, Routes } from "react-router-dom";
import { PageContent } from "components/Page";
import { SectionSpinner } from "components/Spinner";
import { AccountNav } from "./AccountNav";
import { useAccount } from "context/accountContext";
import { Details } from "./Details";
import { KYC } from "./KYC";
import { PaymentMethods } from "./PaymentMethods";
import { Transactions } from "./Transactions";
import { Watchlist } from "./Watchlist";
import { Permissions } from "./Permissions";
import { GDPR } from "./GDPR";
import { WalletLedger } from "./WalletLedger";
import AccountHeader from "./AccountHeader";

export const AccountDetails = () => {
  const { accountId } = useParams();
  const account = useAccount();
  const { getAccountDetails: getAccountDetailsOld, accountDetailsReady } = account.details;

  // Assuming that many child routes will need account details, load them here, until graphql is used throughout the tree
  useEffect(() => {
    getAccountDetailsOld(accountId);
  }, [accountId, getAccountDetailsOld]);

  return (
    <PageContent opts={{ touch: false }}>
      {!accountDetailsReady ? (
        <SectionSpinner />
      ) : (
        <>
          <AccountHeader />
          <AccountNav />
          <Routes>
            <Route index element={<Details refetchAccountDetails={getAccountDetailsOld} />} />
            <Route path="kyc/*" element={<KYC />} />
            <Route path="payment-methods/*" element={<PaymentMethods />} />
            <Route path="transaction-history" element={<Transactions />} />
            <Route path="watchlist" element={<Watchlist />} />
            <Route path="gdpr" element={<GDPR />} />
            <Route path="permissions" element={<Permissions />} />
            <Route path="wallet-ledger/*" element={<WalletLedger />} />
          </Routes>
        </>
      )}
    </PageContent>
  );
};
