import React from "react";
import { READ_PERMISSIONS } from "context/permissions";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import useAgentIntegrator from "context/useAgentIntegrator";
import { PageContent, PageTitle } from "components/Page";
import { PermissionsMessage } from "components/PermissionsMessage";
import IntegratorToolsUsers from "components/User/IntegratorToolsUsers";

const UserProvisioning = () => {
  const hasPermissions = useAgentHasPermissions(READ_PERMISSIONS);
  const integrator = useAgentIntegrator();
  return (
    <PageContent title={{ sub1: "User Provisioning" }}>
      <PageTitle>User Provisioning</PageTitle>
      {!hasPermissions ? <PermissionsMessage /> : <IntegratorToolsUsers integrator={integrator} />}
    </PageContent>
  );
};

export default UserProvisioning;
