import React, { createContext, useCallback, useContext, useMemo, useReducer } from "react";
import * as R from "ramda";
import { handleActions, createAction } from "redux-actions";
import { debugReducer } from "context/utils";
import { isNilOrEmpty } from "utils";
import { ctxWithIntegrator } from "utils/auth";
import { useAgent } from "context/agentContext";
import * as userInfo from "./userInfo";
import * as kyc from "./kyc";
import * as entity from "./entity";
import * as email from "./email";
import * as paymentMethods from "./paymentMethods";
import * as permissions from "./permissions";
import * as transactions from "./transactions";
import * as watchlist from "./watchlist";
import * as toolsWebApi from "api/toolsWeb";
import * as paymentsApi from "api/payments";

const actions = {
  setIntegratorContext: createAction("@tools/account/setIntegratorContext"),
};

const integratorContext = {
  initialState: { integratorContext: { integrator: undefined } },
  actions,
  handlers: {
    [actions.setIntegratorContext]: debugReducer((state, { payload }) =>
      ctxWithIntegrator(payload.integrator, state),
    ),
  },
};

const initialState = {
  ...integratorContext.initialState,
  ...userInfo.initialState,
  ...kyc.initialState,
  ...entity.initialState,
  ...email.initialState,
  ...paymentMethods.initialState,
  ...permissions.initialState,
  ...transactions.initialState,
  ...watchlist.initialState,
};

const resetAccountStateAction = createAction("@tools/account/resetAccountState");

const reducer = handleActions(
  {
    [resetAccountStateAction]: () => initialState,
    ...integratorContext.handlers,
    ...userInfo.handlers,
    ...kyc.handlers,
    ...entity.handlers,
    ...email.handlers,
    ...paymentMethods.handlers,
    ...permissions.handlers,
    ...transactions.handlers,
    ...watchlist.handlers,
  },
  initialState,
);

const buildUserContext = (agent, integratorContext, debug) =>
  R.mergeDeepRight(agent, { integratorContext, debug });

const AccountContext = createContext();
const useAccount = () => useContext(AccountContext);

const AccountProvider = (props) => {
  const { agent, debug } = useAgent();
  const [state, d] = useReducer(reducer, initialState);
  const { accountId } = state.details;

  const { integratorContext } = state;

  const agentContext = useMemo(
    () => buildUserContext(agent, integratorContext, debug),
    [integratorContext, agent, debug],
  );

  const dispatch = useCallback(
    (action) => {
      debug("[AccountContext/DISPATCH]", action);
      return d(action);
    },
    [debug],
  );

  const getEntityById = useCallback(
    async (entityId) => {
      const recievedEntity = await entity.getEntityById(entityId, { ...agent, debug });
      const entityIntegratorContext = { integrator: recievedEntity.payload.integrator };
      dispatch(actions.setIntegratorContext(entityIntegratorContext));
      return recievedEntity;
    },
    [agent, debug, dispatch],
  );

  const resetAccountState = useCallback(() => dispatch(resetAccountStateAction()), [dispatch]);
  const getAccountDetails = useCallback(
    async (accountId) => {
      if (isNilOrEmpty(accountId)) return;
      try {
        resetAccountState();
        const accountUserInfo = await userInfo.getUserInfo(dispatch, accountId, {
          ...agent,
          debug,
        });
        debug("getAccountDetails::userinfo:", accountUserInfo);
        dispatch(createAction("@tools/account/setIntegratorContext")(accountUserInfo));
        const agentContext = buildUserContext(
          agent,
          { integrator: accountUserInfo?.integrator },
          debug,
        );

        kyc.getKYC(dispatch, accountId, agentContext);
        kyc.getKYCPII(dispatch, accountId, agentContext);
        entity.getEntity(dispatch, accountId, agentContext);
        email.getEmails(dispatch, accountId, agentContext);
      } catch (error) {
        console.error("getAccountDetails::error:", error); // eslint-disable-line no-console
      }
    },
    [dispatch, agent, resetAccountState, debug],
  );

  const accountDetailsReady =
    state.details.ready && state.kyc.ready && state.entity.ready && state.emails.ready;

  const getKYCPII = useCallback(
    (accountId) => {
      kyc.getKYCPII(dispatch, accountId, agentContext);
    },
    [dispatch, agentContext],
  );

  const kycReady = kyc.isReady(state);

  const getKYCCollectionUri = useCallback(
    (accountId, params) => {
      kyc.getKYCCollectionUri(
        dispatch,
        accountId,
        {
          ...params,
          agentId: agentContext.account_id,
        },
        agentContext,
      );
    },
    [dispatch, agentContext],
  );
  const kycCollectionUriReady = state.kycCollectionUri.ready;
  const uploadKYCFileSuccess = useCallback(
    (newFileData) => {
      kyc.uploadKYCFileSuccess(dispatch, newFileData);
    },
    [dispatch],
  );

  const postKYCFile = useCallback(
    (...args) => kyc.postKYCFile(...args, agentContext),
    [agentContext],
  );

  const postSendKYCEmail = useCallback(
    (...args) => kyc.postSendKYCEmail(...args, agentContext),
    [agentContext],
  );

  const createEntityAgent = useCallback(
    (agentObject, integrator) => {
      return entity.createEntityAgent(agentObject, integrator, agentContext);
    },
    [agentContext],
  );

  const updateEntityAgent = useCallback(
    (agentObject, integrator) => {
      return entity.updateEntityAgent(agentObject, integrator, agentContext);
    },
    [agentContext],
  );

  const createEntity = useCallback(
    (...args) => {
      return entity.createEntity(dispatch, ...args, agentContext);
    },
    [dispatch, agentContext],
  );

  const updateEntity = useCallback(
    (...args) => {
      return entity.updateEntity(...args, agentContext);
    },
    [agentContext],
  );

  const uploadKYBFile = useCallback(
    (...args) => {
      return entity.uploadKYBFile(...args, agentContext);
    },
    [agentContext],
  );

  const getKYBFiles = useCallback(
    (...args) => {
      return entity.getKYBFiles(...args, agentContext);
    },
    [agentContext],
  );

  const updateKYCPII = useCallback(
    (...args) => {
      return kyc.updateKYCPII(accountId, ...args, agentContext);
    },
    [agentContext, accountId],
  );

  const getPaymentMethods = useCallback(
    (accountId) => paymentMethods.getPaymentMethods(dispatch, accountId, agentContext),
    [dispatch, agentContext],
  );

  const getPermissions = useCallback(
    (accountId) => permissions.getPermissions(dispatch, accountId, agentContext),
    [dispatch, agentContext],
  );
  const getTransactions = useCallback(
    (accountId) => transactions.getTransactions(dispatch, accountId, agentContext),
    [dispatch, agentContext],
  );

  const getAccountWatchlist = useCallback(
    (accountId) => watchlist.getAccountWatchlist(dispatch, accountId, agentContext),
    [dispatch, agentContext],
  );

  const submitManualReview = useCallback(
    (...args) => watchlist.submitManualReview(...args, agentContext),
    [agentContext],
  );

  const changeUsername = useCallback(
    (...args) => userInfo.changeUsername(...args, agentContext),
    [agentContext],
  );
  const changeEmail = useCallback(
    (...args) => userInfo.changeEmail(...args, agentContext),
    [agentContext],
  );

  const addAccountPermission = useCallback(
    (...args) => permissions.addAccountPermission(...args, agentContext),
    [agentContext],
  );
  const deleteAccountPermission = useCallback(
    (...args) => permissions.deleteAccountPermission(...args, agentContext),
    [agentContext],
  );
  const deactivatePaymentMethod = useCallback(
    (...args) => paymentsApi.deactivatePaymentMethod(...args, agentContext),
    [agentContext],
  );
  const gdprDelete = useCallback(
    () => toolsWebApi.gdprDelete(accountId, agentContext),
    [agentContext, accountId],
  );

  const value = useMemo(
    () => ({
      accountState: {
        ...state,
        // a ready state per getter, giving callers a pair to pull from context
        accountDetailsReady,
        kycReady,
        kycCollectionUriReady,
      },
      integrator: state?.details?.data?.integrator,
      changeUsername,
      changeEmail,
      resetAccountState,
      getAccountDetails,
      getKYCCollectionUri,
      uploadKYCFileSuccess,
      uploadKYBFile,
      getKYBFiles,
      postKYCFile,
      postSendKYCEmail,
      updateKYCPII,
      getKYCPII,
      createEntity,
      updateEntity,
      createEntityAgent,
      updateEntityAgent,
      getEntityById,
      gdprDelete,
      deactivatePaymentMethod,
      accountId: state.details.accountId,
      details: {
        state: state.details,
        getAccountDetails,
        accountDetailsReady,
      },
      paymentMethods: {
        ...state.paymentMethods,
        getPaymentMethods,
      },
      permissions: {
        ...state.permissions,
        getPermissions,
        addAccountPermission,
        deleteAccountPermission,
      },
      transactions: {
        ...state.transactions,
        getTransactions,
      },
      watchlist: {
        ...state.watchlist,
        getAccountWatchlist,
        submitManualReview,
      },
      agentContext,
    }),
    [
      state,
      agentContext,
      resetAccountState,
      getAccountDetails,
      changeUsername,
      changeEmail,
      accountDetailsReady,
      getEntityById,
      updateEntity,
      createEntityAgent,
      updateEntityAgent,
      createEntity,
      getKYCPII,
      kycReady,
      getKYCCollectionUri,
      postKYCFile,
      postSendKYCEmail,
      updateKYCPII,
      uploadKYCFileSuccess,
      uploadKYBFile,
      getKYBFiles,
      kycCollectionUriReady,
      getPaymentMethods,
      getPermissions,
      addAccountPermission,
      deleteAccountPermission,
      getTransactions,
      getAccountWatchlist,
      submitManualReview,
      gdprDelete,
      deactivatePaymentMethod,
    ],
  );
  return <AccountContext.Provider value={value} {...props} />;
};

export { useAccount, AccountProvider };
