import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { PageContent, PageTitle } from "../../../components/Page";
import { Panel } from "../../../components/Panel";

export const NotFound = () => (
  <PageContent title={{ page: "Not Found" }} opts={{ clear: true }}>
    <PageTitle>D'oh! Page not found</PageTitle>
    <Panel
      css={`
        padding: 8px;
      `}
    >
      <div>Apologies, but we can't seem to find this one!</div>
    </Panel>
  </PageContent>
);
