import React from "react";
import { isNilOrEmpty } from "utils";
import { theme } from "styles";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useAgent } from "context/agentContext";
import { downloadWatchlistPDF } from "api/toolsWeb";
import * as R from "ramda";
import { Radio } from "components/Forms";
import { AlertIcon } from "components/Icons";
import { Download } from "./PDFDownload";

export const EntityList = ({ name, entities, setEntityID, autoselectFirst }) => {
  let i = 0;
  const options = Object.values(entities).map((e) => {
    if (!e) {
      i = i + 1;
      return { value: "error" + i, text: "error: Try querying this check instead." };
    }

    const score = e.entity_data?.score ? `, score (${e.entity_data.score.toFixed(2)})` : "";
    return {
      value: e.wl_entity_id,
      text: `${e.sanction_list} entity: ${e.entity_id}${score}`,
    };
  });

  React.useEffect(() => {
    if (autoselectFirst) {
      setEntityID(options[0].value);
    }
  }, []); // eslint-disable-line

  const selectedValue = autoselectFirst ? options[0].value : "";
  return (
    <div>
      <div css="padding-bottom:10px">Matched Entities: ({options.length})</div>

      <div css="margin-left:4px">
        <Radio
          name={`entity-radio-${name}`}
          options={options}
          onChange={setEntityID}
          optionCSS="margin-bottom:8px"
          selectedValue={selectedValue}
        />
      </div>
    </div>
  );
};

const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme("colors.alert")};
  border-radius: 5px;
  background-color: ${theme("colors.alertBg")};
  padding: 8px;
  max-width: 600px;
`;

const ErrorMessage = styled.div`
  display: inherit;
  align-items: center;
  color: ${theme("colors.alert")};
`;

const EntityErrorWarning = ({entityError}) => <ErrorBox>
          <ErrorMessage>
            <AlertIcon size={1} />
            <span css={"padding-left: 5px;"}>The raw data below has the following error:</span>
          </ErrorMessage>
          <div css={"margin: 5px; padding-left: 26px;"}>{`${entityError}`}</div>
        </ErrorBox>;

export const EntityData = ({ entityID, entities }) => {

  if (!R.path([entityID, "entity_data"], entities)) {
    return null;
  }
  const entityData = JSON.stringify(entities[entityID].entity_data, undefined, 4);
  const entityError = entities[entityID].error_info;
  return (
    <div>
      {!isNilOrEmpty(entityError) && <EntityErrorWarning entityError={entityError} />}
      <pre css="white-space:pre-wrap">{entityData}</pre>
    </div>
  );
};

export const WatchlistEntities = ({
  name,
  entities,
  autoselectFirst,
  hideDownload = false,
  query,
}) => {
  const [entityID, setEntityID] = React.useState("");
  const [entityIDDownload, setEntityIDDownlaod] = React.useState("");
  const { agent } = useAgent();

  const entitiesDropdown = isNilOrEmpty(entities)
    ? []
    : R.pipe(
        R.map((e) => ({ value: e?.entity_id, label: `${e?.entity_id}` })),
        R.values,
        R.prepend({ value: "", label: "All" })
      )(entities);

  const downloadRequest = React.useCallback(
    async () => await downloadWatchlistPDF(query, agent, entityIDDownload),
    [query, entityIDDownload, agent]
  );

  if (Object.keys(entities || {}).length === 0) {
    return <div>No Matches</div>;
  }

  return (
    <div
      css={`
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-gap: 1.5em;
        padding: 0 8px;
      `}
    >
      <EntityList
        name={name}
        autoselectFirst={autoselectFirst}
        setEntityID={setEntityID}
        entities={entities}
      />
      <EntityData entityID={entityID} entities={entities} />
      {!hideDownload && (
        <Download
          setEntityId={setEntityIDDownlaod}
          query={downloadRequest}
          options={entitiesDropdown}
        />
      )}
    </div>
  );
};
