import React from "react";
import { useParams, Outlet, Routes, Route, useNavigate } from "react-router-dom";
import { PageContent, PageTitle } from "components/Page";
import { MANAGE_CLIENTS, withPermissions, CLIENTS_RESOURCE } from "context/permissions";
import { PermissionsMessage } from "components/PermissionsMessage";
import { ClientsByPublisher } from "./ClientsByPublisher";
import IntegratorSelect from "components/IntegratorSelect/IntegratorSelect";
import { useIntegrator } from "context/integratorContext";
import { Client } from "./Client";

const PublisherList = () => {
  const navigate = useNavigate();
  const handleSelect = (selected) => navigate(`publisher/${selected}`);
  return <IntegratorSelect onChange={handleSelect} />;
};

export const ClientPageTitle = () => {};

const ClientsLayout = () => {
  const { publisherId, clientId } = useParams();
  const integrator = useIntegrator(publisherId);
  const integratorTitle = integrator ? ` / ${integrator.displayName}` : "";
  return (
    <PageContent
      title={{ page: "Clients", sub1: integrator?.displayName, sub2: clientId }}
      opts={{ clear: true }}
    >
      <PageTitle>Clients{integratorTitle}</PageTitle>
      <Outlet />
    </PageContent>
  );
};

export const Clients = withPermissions(
  { permissions: [MANAGE_CLIENTS], resource: CLIENTS_RESOURCE },
  ({ permissions }) => {
    if (!permissions.hasPermission) {
      return (
        <PageContent>
          <PermissionsMessage />
        </PageContent>
      );
    }
    return (
      <Routes>
        <Route element={<ClientsLayout />}>
          <Route index element={<PublisherList />} />
          <Route path="publisher/:publisherId" element={<ClientsByPublisher />} />
          <Route path="publisher/:publisherId/:clientId" element={<Client />} />
        </Route>
      </Routes>
    );
  }
);
