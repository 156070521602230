import {
  Accordion,
  AccordionIcon,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Address, AddressTitleWithMapLink } from "components/Address";
import DateAndTime from "components/DateAndTime/DateAndTime";
import { KycDocument } from "components/Document";
import { SSN } from "components/SSN";
import { useAccount } from "context/accountContext";
import { useUploadFileModal } from "components/UploadFileModal";
import { isNilOrEmpty, exists } from "utils";

const BadgeMap = {
  ACCEPT: "green",
  UNKNOWN: "gray",
};
const getStateBadgeColor = (state) => BadgeMap[state] || BadgeMap.UNKNOWN;

const ApplicationGrid = (props) => <Grid {...props} templateColumns="1fr 1fr 1fr" gap={2} />;

const NameAndAddress = ({ pii = {} }) => {
  const fullName = [pii.firstName, pii.middleName, pii.lastName].join(" ");

  return (
    <ApplicationGrid>
      <Text>Name</Text>
      <GridItem colSpan={2}>
        <Text>{fullName}</Text>
      </GridItem>

      <Text>Date of Birth</Text>
      <GridItem colSpan={2}>
        <Text>{pii.dateOfBirth}</Text>
      </GridItem>

      <Text>SSN</Text>
      <GridItem colSpan={2}>
        <SSN value={pii.ssn} />
      </GridItem>

      <AddressTitleWithMapLink address={pii.primaryAddress} />
      <GridItem colSpan={2}>
        <Address address={pii.primaryAddress} />
      </GridItem>
    </ApplicationGrid>
  );
};

const Identifiers = ({ application }) => {
  return (
    <ApplicationGrid>
      <Text>KYC ID</Text>
      <GridItem colSpan={2}>
        <Text>{application.applicationId}</Text>
      </GridItem>
      <Text>Application ID</Text>
      <GridItem colSpan={2}>
        <Text>{application.vendorReferenceId}</Text>
      </GridItem>
    </ApplicationGrid>
  );
};

const KYCIdentityDocument = ({ accountIdentifier, documentId, alt }) => {
  return exists(documentId) ? (
    <KycDocument accountIdentifier={accountIdentifier} documentId={documentId} alt={alt} />
  ) : (
    <Text>-</Text>
  );
};

const ApplicationDocuments = ({ application, accountIdentifier, username }) => {
  const { postKYCFile, uploadKYCFileSuccess } = useAccount();
  const [UploadFileModal, UploadButton] = useUploadFileModal();

  const uploadKYC = async (files) => {
    const response = await postKYCFile(application.applicationId, files);
    uploadKYCFileSuccess(response);
  };

  const documentType = application.pii.identityDocument?.type
    ? `${application.pii.identityDocument?.type} - ${application.pii.identityDocument?.country}`
    : "No Documents";

  return (
    <ApplicationGrid>
      <Text>{documentType}</Text>
      <KYCIdentityDocument
        accountIdentifier={accountIdentifier}
        documentId={application.pii.identityDocument?.frontId}
        alt={`${username} primary front`}
      />
      <KYCIdentityDocument
        accountIdentifier={accountIdentifier}
        documentId={application.pii.identityDocument?.backId}
        alt={`${username} primary back`}
      />
      <Flex alignItems="start">
        <Flex alignItems="center">
          Additional Documents
          <Box ml="8px">
            <UploadButton compact={true} />
          </Box>
        </Flex>
      </Flex>
      {application.pii.additionalDocumentIds.map((fileId) => {
        return (
          <KycDocument
            key={fileId}
            documentId={fileId}
            accountIdentifier={accountIdentifier}
            alt={`${username} additional file: ${fileId}`}
          />
        );
      })}
      <UploadFileModal submitAction={uploadKYC} />
    </ApplicationGrid>
  );
};

export const KYCApplication = ({ application, accountIdentifier, username }) => {
  if (isNilOrEmpty(application)) return null;

  return (
    <AccordionItem>
      <AccordionButton>
        <Flex width="100%" alignItems="center">
          <Text>
            <DateAndTime value={application.created} />
          </Text>
          <Spacer />
          <Badge colorScheme={getStateBadgeColor(application.state)} mr="4" p="2">
            {application.state}
          </Badge>
          <AccordionIcon />
        </Flex>
      </AccordionButton>
      <AccordionPanel>
        <NameAndAddress pii={application.pii} application={application} />
        <Divider />
        <Identifiers application={application} />
        <Divider />
        <ApplicationDocuments
          application={application}
          accountIdentifier={accountIdentifier}
          username={username}
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export const KYCApplications = ({
  accountIdentifier,
  currentApplication,
  historicalApplications,
  username,
}) => {
  return (
    <Accordion defaultIndex={[0]} allowMultiple={true} width="75%">
      <KYCApplication
        application={currentApplication}
        accountIdentifier={accountIdentifier}
        username={username}
      />
      {historicalApplications?.map((application) => (
        <KYCApplication
          key={application.applicationId}
          application={application}
          accountIdentifier={accountIdentifier}
          username={username}
        />
      ))}
    </Accordion>
  );
};
