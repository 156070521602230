import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getFilterMap } from "./utils";

export const useGridStates = (setSelection, initialSortField, initialSortDir) => {
  const location = useLocation();

  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const [filterMap, setFilterMap] = useState(getFilterMap(params));

  useEffect(() => {
    const filterMap = getFilterMap(params);
    setFilterMap(filterMap);
    setSelection([]);
  }, [params, setSelection, setFilterMap]);

  return {
    currentPage: parseInt(params.get("currentPage"), 10) || 0,
    pageSize: parseInt(params.get("pageSize"), 10) || 10,
    filterMap,
    sortField: params.get("sortField") ?? initialSortField,
    sortDir: params.get("sortDir") ?? initialSortDir,
  };
};
