import {
  DashboardNavItem,
  ProductCatalogNavItem,
  UserProvisioningNavItem,
  WebhooksNavItem,
} from "./NavItem";

const PublisherAgentNavItems = () => {
  return (
    <>
      <DashboardNavItem />
      <ProductCatalogNavItem />
      <UserProvisioningNavItem />
      <WebhooksNavItem />
    </>
  );
};

export default PublisherAgentNavItems;
