import React from "react";
import * as R from "ramda";
import * as Yup from "yup";
import "styled-components/macro";
import { useAgent } from "context/agentContext";
import * as Permissions from "context/permissions";
import { noop } from "utils";
import { useFormModal } from "components/Modal";
import { OutlineButton } from "components/Button";
import { useAppConfig, appConfigKeys } from "context/appConfigContext";
import { SelectField, TextField, NoteField } from "components/Forms";
import {
  getProductTaxCodes,
  useCreateProductTaxCode,
  useUpdateProductTaxCode,
} from "./productHooks";

const capitalizeFirstLetter = (word) => {
  return R.toUpper(word[0]) + R.toLower(R.slice(1, Infinity, word));
};

export const useCreateEditProductModal = (integrator, options) => {
  const label = options?.edit ? "edit" : "add";
  const testIdPrefix = `${label}-tax-code-`;
  const capLabel = capitalizeFirstLetter(label);
  const [FormModal, openModal] = useFormModal();
  const { agent } = useAgent();

  const Modal = ({ onSuccess = noop }) => {
    const productTaxCodes = useAppConfig(appConfigKeys.PRODUCT_TAX_CODES) || {};

    const { mutateFunction: createMutate } = useCreateProductTaxCode(integrator.integratorId);
    const { mutateFunction: updateMutate } = useUpdateProductTaxCode(integrator.integratorId);

    // the mutation MUST be defined in the modal
    // or upon any error calls, the modal will unmount prematurely
    const createMutation = async (formData) => {
      const { data } = await createMutate({
        permissions: [Permissions.WRITE_PRODUCT_CATALOG],
        variables: {
          key: integrator.integratorId,
          productTaxCode: formData,
        },
        refetchQueries: [getProductTaxCodes],
      });
      return data.createProductTaxCode;
    };

    const updateMutation = async (formData) => {
      const { data } = await updateMutate({
        permissions: [Permissions.WRITE_PRODUCT_CATALOG],
        variables: {
          key: integrator.integratorId,
          productTaxCode: formData,
        },
        refetchQueries: [getProductTaxCodes],
      });
      return data.updateProductTaxCode;
    };

    if (!agent.hasPermissions([Permissions.WRITE_PRODUCT_CATALOG])) {
      return <div data-testid={`product-catalog-modal-no-permissions`}></div>;
    }

    const initialValues = {
      productCode: options?.product?.productCode ?? "",
      tiliaProduct: options?.product?.tiliaProduct ?? [],
      description: options?.product?.description ?? "",
      isDefault: false,
    };

    const tiliaProductOptions = R.values(
      R.mapObjIndexed(
        (taxCodeInfo, taxCode) => ({ label: taxCodeInfo.product_description, value: taxCode }),
        productTaxCodes
      )
    );

    const onSubmit = options?.edit ? updateMutation : createMutation;

    return (
      <FormModal
        actionText={capLabel}
        onSubmitActionSuccess={onSuccess}
        submitAction={onSubmit}
        titleText={`Create Product Code`}
        testIdPrefix={testIdPrefix}
        formProps={{
          initialValues: initialValues,
          validationSchema: () =>
            Yup.object().shape({
              productCode: Yup.string().required("Product Code required.").max(100),
              tiliaProduct: Yup.string().required("Product Category required."),
            }),
        }}
        disableTillEdit
      >
        <div
          css={`
            min-height: 450px;
          `}
        >
          <TextField name="productCode" label="Product Code" required disabled={options?.edit} />
          <SelectField name="tiliaProduct" label="Product Category" options={tiliaProductOptions} />
          <NoteField name="description" label="Description" maxHeight />
        </div>
      </FormModal>
    );
  };

  const ModalButton = ({ ...props }) => {
    if (!agent.hasPermissions([Permissions.WRITE_PRODUCT_CATALOG])) {
      return null;
    }
    return (
      <OutlineButton data-testid={`${testIdPrefix}-button`} {...props} onClick={openModal}>
        {capLabel}
      </OutlineButton>
    );
  };
  return [Modal, ModalButton];
};
