import { getWebhooksClientToken } from "api/toolsWeb";
import { useFilter } from "components/Filter/FilterContext";
import { useAgent } from "context/agentContext";
import { useEffect, useRef, useState } from "react";
import { isNilOrEmpty } from "utils";

export default function WebhookDisplay() {
  const { agent } = useAgent();
  const webhookRef = useRef();
  const [webhookDisplay, setWebhookDisplay] = useState(false);
  const { filterParams } = useFilter();

  useEffect(() => {
    const getWebhookToken = async () => {
      const { payload } = await getWebhooksClientToken(filterParams.integrator, agent);

      const { token, ...payloadOpts } = payload;
      const opts = {
        ...payloadOpts,
        element_id: "webhook-content",
        consumer_id: filterParams.integrator,
        show_introduction: "never",
      };

      webhookRef.current.innerHTML = "";
      window.wh.display(token, opts);
      setWebhookDisplay(true);
    };
    if (!isNilOrEmpty(filterParams.integrator)) {
      getWebhookToken();
    }
  }, [filterParams.integrator, agent]);

  return (
    <div>
      <div ref={webhookRef} id="webhook-content"></div>
      {webhookDisplay && <div data-testid="webhookDisplay"></div>}
    </div>
  );
}
