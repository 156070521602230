import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { ifProp, theme } from "styles";

export const Box = styled.div`
  padding: 8px 0;
  border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
  min-height: 32px;
  display: flex;
  align-items: ${ifProp("top", "flex-start", "center")};
  justify-content: ${ifProp("right", "flex-end", "flex-start")};
`;

export const TopBox = props => <Box {...props} top />;
