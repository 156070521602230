import React from "react";
import { useFormikContext } from "formik";
import { exists } from "utils";
import { TransactionType } from "@tilia-tools/core/transaction";
import { Money } from "../Money";
import useCurrencyDisplayInfo from "components/Money/useCurrencyDisplayInfo";

const getTotalRefundAmount = (lineItems, getValueByLowestDenominationRounded) => {
  return lineItems
    .filter((lineItem) => exists(lineItem?.amount))
    .reduce((acc, lineItem) => {
      const refundableAmount = getValueByLowestDenominationRounded(lineItem.amount);
      acc += refundableAmount;
      return acc;
    }, 0);
};

const PurchaseRefundAmount = ({ transaction }) => {
  const { values } = useFormikContext();

  const { getValueByLowestDenominationRounded } = useCurrencyDisplayInfo(transaction.currencyCode);

  return (
    <Money
      value={getTotalRefundAmount(values.lineItems, getValueByLowestDenominationRounded)}
      currency={transaction.currencyCode}
    />
  );
};

const TotalRefundAmount = ({ transaction }) => {
  if (transaction.type === TransactionType.TOKEN_PURCHASE) {
    return <Money value={-transaction.amount} currency={transaction.currencyCode} />;
  }
  return <PurchaseRefundAmount transaction={transaction} />;
};

export default TotalRefundAmount;
