import React from "react";
import { READ_PERMISSIONS } from "context/permissions";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import { Panel, PanelTitle, PanelContent } from "components/Panel";
import { PermissionsMessage } from "components/PermissionsMessage";
import IntegratorToolsUsers from "components/User/IntegratorToolsUsers";

const TiliaUserProvisioning = ({ integrator }) => {
  const hasPermissions = useAgentHasPermissions(READ_PERMISSIONS);
  return (
    <Panel>
      <PanelTitle>
        <span>User Provisioning</span>
      </PanelTitle>
      <PanelContent>
        {!hasPermissions ? (
          <PermissionsMessage />
        ) : (
          <IntegratorToolsUsers integrator={integrator} />
        )}
      </PanelContent>
    </Panel>
  );
};

export default TiliaUserProvisioning;
