import React from "react";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { noop } from "utils";

export const Radio = ({ ...props }) => {
  const { name, options, onChange = noop, optionCSS, selectedValue } = props;
  const [val, setVal] = React.useState(selectedValue);

  return (
    <div>
      {options.map((o) => (
        <div key={o.value} css={optionCSS}>
          <label>
            <input
              onChange={() => {
                onChange(o.value);
                setVal(o.value);
              }}
              type="radio"
              name={name}
              value={o.value}
              checked={o.value === val}
            />
            <span css="margin-left:8px">{o.text}</span>
          </label>
        </div>
      ))}
    </div>
  );
};
