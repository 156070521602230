/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import React from "react";
import { Box, Heading, Table, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import { Money } from "components/Money";
import {
  ChargebackSettlementDetailTypes,
  CorrectionSettlementDetailTypes,
  RefundSettlementDetailTypes,
  ReversalFeeSettlementDetailTypes,
  TiliaFeeSettlementDetailTypes,
} from "./settlementTypes";
import SettlementDetailSourceName from "./SettlementDetailSourceName";
import SettlementDetailTypeName from "./SettlementDetailTypeName";

/**
 * @param {SettlementSummary} summary
 * @param {string} currencyCode
 * @returns {JSX.Element}
 */
function SettlementDeductionsSummary({ summary, currencyCode }) {
  const tiliaFeeSubtotals = summary.subtotals.filter((subtotal) =>
    TiliaFeeSettlementDetailTypes.has(subtotal.settlementDetailType)
  );
  const refundSubtotals = summary.subtotals.filter((subtotal) =>
    RefundSettlementDetailTypes.has(subtotal.settlementDetailType)
  );
  const chargebackSubtotals = summary.subtotals.filter((subtotal) =>
    ChargebackSettlementDetailTypes.has(subtotal.settlementDetailType)
  );
  const reversalFeeSubtotals = summary.subtotals.filter((subtotal) =>
    ReversalFeeSettlementDetailTypes.has(subtotal.settlementDetailType)
  );
  return (
    <Box>
      <Heading as="h4" size="sm" mb="2">
        Deductions
      </Heading>
      <Table size="sm">
        {tiliaFeeSubtotals.length > 0 && (
          <>
            <Thead>
              <Tr>
                <Th colSpan={2}>Tilia Fees</Th>
              </Tr>
            </Thead>
            <Tbody>
              {tiliaFeeSubtotals.map((subtotal) => (
                <Tr key={`${subtotal.settlementDetailType}-${subtotal.source}`}>
                  <Td>
                    <SettlementDetailTypeName type={subtotal.settlementDetailType} />
                  </Td>
                  <Td isNumeric>
                    <Money value={subtotal.amount} currency={subtotal.currencyCode} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </>
        )}
        {refundSubtotals.length > 0 && (
          <>
            <Thead>
              <Tr>
                <Th colSpan={2}>Refunds</Th>
              </Tr>
            </Thead>
            <Tbody>
              {refundSubtotals.map((subtotal) => (
                <Tr key={subtotal.source}>
                  <Td>
                    {CorrectionSettlementDetailTypes.has(subtotal.settlementDetailType) ? (
                      <SettlementDetailTypeName type={subtotal.settlementDetailType} />
                    ) : (
                      <SettlementDetailSourceName source={subtotal.source} />
                    )}
                  </Td>
                  <Td isNumeric>
                    <Money value={subtotal.amount} currency={subtotal.currencyCode} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </>
        )}
        {chargebackSubtotals.length > 0 && (
          <>
            <Thead>
              <Tr>
                <Th colSpan={2}>Chargebacks</Th>
              </Tr>
            </Thead>
            <Tbody>
              {chargebackSubtotals.map((subtotal) => (
                <Tr key={subtotal.source}>
                  <Td>
                    {CorrectionSettlementDetailTypes.has(subtotal.settlementDetailType) ? (
                      <SettlementDetailTypeName type={subtotal.settlementDetailType} />
                    ) : (
                      <SettlementDetailSourceName source={subtotal.source} />
                    )}
                  </Td>
                  <Td isNumeric>
                    <Money value={subtotal.amount} currency={subtotal.currencyCode} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </>
        )}
        {reversalFeeSubtotals.length > 0 && (
          <>
            <Thead>
              <Tr>
                <Th colSpan={2}>Reversal Fees</Th>
              </Tr>
            </Thead>
            <Tbody>
              {reversalFeeSubtotals.map((subtotal) => (
                <Tr key={`${subtotal.settlementDetailType}-${subtotal.source}`}>
                  <Td>
                    <SettlementDetailTypeName type={subtotal.settlementDetailType} />
                    {!CorrectionSettlementDetailTypes.has(subtotal.settlementDetailType) && (
                      <>
                        {" "}
                        (<SettlementDetailSourceName source={subtotal.source} />)
                      </>
                    )}
                  </Td>
                  <Td isNumeric>
                    <Money value={subtotal.amount} currency={subtotal.currencyCode} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </>
        )}
        <Tfoot>
          <Tr>
            <Td>Total Deductions</Td>
            <Td isNumeric>
              <Money value={summary.deductionsTotal} currency={currencyCode} />
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  );
}

export default SettlementDeductionsSummary;
