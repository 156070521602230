// NOTE: The names of most exported objects exactly match the equivalent constant strings in the
// Go services code -- DO NOT RENAME THEM!
//
// This helps to ensure this file, and any usages of these constants in our own code, appear in
// full-code searches for those strings, so that Tools isn't excluded from critical updates that
// modify the underlying functionality.

/**
 * Payout status codes.
 * @enum {string}
 */
export const InvoicingApiPayoutStatusCode = Object.freeze({
  PayoutEscrowInitiated: "ESCROW-TRANSFER-INITIATED",
  PayoutInEscrow: "FUNDS-IN-ESCROW",
  PayoutEscrowTransferFailed: "ESCROW-TRANSFER-FAILED",
  PayoutTiliaOnHold: "TILIA-ONHOLD",
  PayoutStatusProviderOnHold: "PROVIDER-ONHOLD",
  PayoutInitiated: "CREDIT-TRANSFER-INITIATED",
  PayoutCreditExecuted: "CREDIT-EXECUTED",
  PayoutSuccess: "SUCCESS",
  PayoutFailed: "FAILED",
  PayoutUserReverseInitiated: "USER-REVERSE-TRANSFER-INITIATED",
  PayoutUserReversed: "USER-REVERSED",
  PayoutUserReverseFailed: "USER-REVERSE-FAILED",
  PayoutSupportRejectInitiated: "SUPPORT-REJECT-TRANSFER-INITIATED",
  PayoutSupportRejected: "SUPPORT-REJECTED",
  PayoutSupportRejectFailed: "SUPPORT-REJECT-FAILED",
  PayoutSystemRejected: "SYSTEM-REJECTED",
  PayoutSystemRejectFailed: "SYSTEM-REJECT-FAILED",
});

/**
 * Payout status codes representing a "pending" status.
 * @type {Set<InvoicingApiPayoutStatusCode>}
 */
export const InvoicingApiPayoutPendingStatusCodes = new Set([
  InvoicingApiPayoutStatusCode.PayoutEscrowInitiated,
  InvoicingApiPayoutStatusCode.PayoutInEscrow,
  InvoicingApiPayoutStatusCode.PayoutTiliaOnHold,
  InvoicingApiPayoutStatusCode.PayoutStatusProviderOnHold,
  InvoicingApiPayoutStatusCode.PayoutInitiated,
  InvoicingApiPayoutStatusCode.PayoutCreditExecuted,
  InvoicingApiPayoutStatusCode.PayoutUserReverseInitiated,
  InvoicingApiPayoutStatusCode.PayoutSupportRejectInitiated,
]);

/**
 * Payout status codes representing a "success" status.
 * @type {Set<InvoicingApiPayoutStatusCode>}
 */
export const InvoicingApiPayoutSuccessStatusCodes = new Set([
  InvoicingApiPayoutStatusCode.PayoutSuccess,
]);

/**
 * Payout status codes representing a "failed" status.
 * @type {Set<InvoicingApiPayoutStatusCode>}
 */
export const InvoicingApiPayoutFailedStatusCodes = new Set([
  InvoicingApiPayoutStatusCode.PayoutEscrowTransferFailed,
  InvoicingApiPayoutStatusCode.PayoutFailed,
  InvoicingApiPayoutStatusCode.PayoutUserReverseFailed,
  InvoicingApiPayoutStatusCode.PayoutSupportRejectFailed,
  InvoicingApiPayoutStatusCode.PayoutSystemRejectFailed,
]);

/**
 * Payout status codes representing a "canceled" status.
 * @type {Set<InvoicingApiPayoutStatusCode>}
 */
export const InvoicingApiPayoutCanceledStatusCodes = new Set([
  InvoicingApiPayoutStatusCode.PayoutUserReversed,
  InvoicingApiPayoutStatusCode.PayoutSupportRejected,
  InvoicingApiPayoutStatusCode.PayoutSystemRejected,
]);

/**
 * Payout flow types.
 * @enum {string}
 */
export const InvoicingApiPayoutFlow = Object.freeze({
  PayoutFlowStandard: "STANDARD",
  PayoutFlowDisbursement: "DISBURSEMENT",
  PayoutFlowSettlement: "SETTLEMENT",
});

/**
 * Auto-payout rule decision types.
 * @enum {string}
 */
export const InvoicingApiPayoutRuleDecision = Object.freeze({
  decisionFail: "fail",
  decisionManualReview: "manual-review",
  decisionAutoApprove: "auto-approve",
});
