import * as R from "ramda";
import { refundLineItem, refundInvoice } from "api/invoicing";
import { exists } from "utils";
import { TransactionType } from "@tilia-tools/core/transaction";
import { ctxWithIntegrator } from "utils/auth";
import { REFUND_TYPES } from "./getTransactionRefundTypes";

const getPaymentMethod = (transaction, paymentMethodId) => {
  let paymentMethod;
  if (transaction.type === TransactionType.TOKEN_PURCHASE) {
    if (transaction.payment.paymentMethod.paymentMethodId === paymentMethodId) {
      paymentMethod = transaction.payment.paymentMethod;
    }
  } else {
    const payment = transaction.payments.find(
      (payment) => payment.paymentMethod.paymentMethodId === paymentMethodId,
    );
    if (payment) {
      paymentMethod = payment.paymentMethod;
    }
  }
  if (!paymentMethod) {
    throw new Error(`Unable to find payment for paymentMethodId: ${paymentMethodId}`);
  }
  return paymentMethod;
};

const applyTotalRefund = async (agent, transaction, formData) => {
  const { status, payload, errors } = await refundInvoice(
    transaction.invoiceId,
    formData.reason,
    formData.note,
    ctxWithIntegrator(transaction.integrator, agent),
  );

  let successfulRefunds = [];
  let failedRefunds = [];

  if (status === 200) {
    payload.successful_refunds.forEach((successfulRefund) => {
      const paymentMethod = getPaymentMethod(transaction, successfulRefund.payment_method_id);
      successfulRefunds.push({
        reason: formData.reason,
        note: formData.note,
        refundInvoiceId: payload.refund_invoice_id,
        paymentBreakdown: [
          {
            amount: successfulRefund.amount,
            currency: successfulRefund.currency,
            paymentDisplay: paymentMethod.displayString,
            paymentMethod: paymentMethod,
          },
        ],
      });
    });
  } else {
    failedRefunds.push({ id: transaction.invoiceId, errors });
  }

  return { successfulRefunds, failedRefunds };
};

const applyPartialRefund = async (
  agent,
  getValueByLowestDenominationRounded,
  transaction,
  formData,
) => {
  const { lineItems, reason, note, payment_methods } = formData;

  const refundableLineItems = lineItems.filter(
    (lineItem) =>
      exists(lineItem.amount) && getValueByLowestDenominationRounded(lineItem.amount) !== 0,
  );

  const refundPromises = refundableLineItems.map((lineItem) => {
    const amount = getValueByLowestDenominationRounded(lineItem.amount);
    return refundLineItem(
      lineItem.line_item_id,
      amount,
      payment_methods,
      reason,
      note,
      ctxWithIntegrator(transaction.integrator, agent),
    );
  });

  const refundResponses = await Promise.allSettled(refundPromises);

  let successfulRefunds = [];
  let failedRefunds = [];

  refundResponses.forEach((result, index) => {
    if (result.status === "rejected") {
      const lineItem = refundableLineItems[index];
      failedRefunds.push({ lineItem, errors: [result.reason] });
      return;
    }

    const { status, payload, errors } = result.value;
    if (status !== 200) {
      const lineItem = refundableLineItems[index];
      failedRefunds.push({ lineItem, errors });
      return;
    }

    const paymentBreakdown = R.pipe(
      R.filter((paymentMethod) => paymentMethod.is_successful && paymentMethod.amount !== 0),
      R.mapObjIndexed((method, key) => {
        return {
          amount: method.amount,
          currency: method.currency,
          paymentDisplay: method.display_string,
          paymentMethod: getPaymentMethod(transaction, key),
        };
      }),
      R.values(),
    )(payload.payment_methods);

    successfulRefunds.push({
      reason: payload.reason,
      note: payload.note,
      refundInvoiceId: payload.refund_invoice_id,
      paymentBreakdown,
    });
  });

  return { successfulRefunds, failedRefunds };
};

const applyRefund = async (
  formData,
  getValueByLowestDenominationRounded,
  agent,
  transaction,
  refundTypes,
) => {
  if (transaction.type === TransactionType.TOKEN_PURCHASE) {
    return applyTotalRefund(agent, transaction, formData);
  }

  // TransactionType.PURCHASE or TransactionType.ESCROW_PURCHASE
  const totalRefundAmount = formData.lineItems.reduce((total, lineItem) => {
    const amount = getValueByLowestDenominationRounded(lineItem.amount);
    return total + amount;
  }, 0);

  if (totalRefundAmount === -transaction.amount && refundTypes.includes(REFUND_TYPES.full_refund)) {
    return applyTotalRefund(agent, transaction, formData);
  } else if (refundTypes.includes(REFUND_TYPES.partial_refund)) {
    return applyPartialRefund(agent, getValueByLowestDenominationRounded, transaction, formData);
  } else {
    throw new Error("Unable to refund this transaction");
  }
};

export default applyRefund;
