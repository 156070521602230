import React from "react";
import { PageContent, PageTitle } from "components/Page";
import { READ_WATCHLISTS, withPermissions, WATCHLIST_RESOURCE } from "context/permissions";
import { PermissionsMessage } from "components/PermissionsMessage";
import { Route, Routes } from "react-router-dom";
import { WatchlistSubNav } from "./SubNav";
import { WatchlistAccounts } from "./WatchlistAccounts";
import { WatchlistQuery } from "components/Watchlist";

export const Watchlist = withPermissions(
  { permissions: [READ_WATCHLISTS], resource: WATCHLIST_RESOURCE },
  ({ permissions }) => {
    if (!permissions.hasPermission) {
      return (
        <PageContent>
          <PermissionsMessage />
        </PageContent>
      );
    }

    return (
      <PageContent title={{ page: "Watchlist" }} opts={{ clear: true }}>
        <PageTitle>Watchlist</PageTitle>
        <WatchlistSubNav />
        <Routes>
          <Route index element={<WatchlistAccounts />} />
          <Route path="query" element={<WatchlistQuery />} />
        </Routes>
      </PageContent>
    );
  }
);
