// NOTE: The names of most exported objects exactly match the equivalent constant strings in the
// Go services code -- DO NOT RENAME THEM!
//
// This helps to ensure this file, and any usages of these constants in our own code, appear in
// full-code searches for those strings, so that Tools isn't excluded from critical updates that
// modify the underlying functionality.

/**
 * Escrow status codes (escrowTransactionStatus).
 * @enum {string}
 */
export const InvoicingApiEscrowStatusCode = Object.freeze({
  Open: "OPEN",
  Escrowed: "ESCROWED",
  EscrowFailed: "ESCROW-FAILED",
  CommitRequested: "COMMIT-REQUESTED",
  Committed: "COMMITTED",
  CommitFailed: "COMMIT-FAILED",
  CancelRequested: "CANCEL-REQUESTED",
  Cancelled: "CANCELLED",
  CancelFailed: "CANCEL-FAILED",
  SystemCancelRequested: "SYSTEM-CANCEL-REQUESTED",
  SystemCancelled: "SYSTEM-CANCELLED",
  SystemCancelFailed: "SYSTEM-CANCEL-FAILED",
  CancelInvoiceTransferFailed: "CANCEL-INVOICE-TRANSFER-FAILED",
});

/**
 * Escrow status codes representing a "pending" status.
 * @type {Set<InvoicingApiEscrowStatusCode>}
 */
export const InvoicingApiEscrowPendingStatusCodes = new Set([
  InvoicingApiEscrowStatusCode.Open,
  InvoicingApiEscrowStatusCode.Escrowed,
  InvoicingApiEscrowStatusCode.CommitRequested,
  InvoicingApiEscrowStatusCode.CancelRequested,
  InvoicingApiEscrowStatusCode.SystemCancelRequested,
]);

/**
 * Escrow status codes representing a "success" status.
 * @type {Set<InvoicingApiEscrowStatusCode>}
 */
export const InvoicingApiEscrowSuccessStatusCodes = new Set([
  InvoicingApiEscrowStatusCode.Committed,
]);

/**
 * Escrow status codes representing a "failed" status.
 * @type {Set<InvoicingApiEscrowStatusCode>}
 */
export const InvoicingApiEscrowFailedStatusCodes = new Set([
  InvoicingApiEscrowStatusCode.EscrowFailed,
  InvoicingApiEscrowStatusCode.CommitFailed,
  InvoicingApiEscrowStatusCode.CancelFailed,
  InvoicingApiEscrowStatusCode.SystemCancelFailed,
  InvoicingApiEscrowStatusCode.CancelInvoiceTransferFailed,
]);

/**
 * Escrow status codes representing a "canceled" status.
 * @type {Set<InvoicingApiEscrowStatusCode>}
 */
export const InvoicingApiEscrowCanceledStatusCodes = new Set([
  InvoicingApiEscrowStatusCode.Cancelled,
  InvoicingApiEscrowStatusCode.SystemCancelled,
]);
