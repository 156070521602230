const Keys = {
  NOTE: "NOTE",
  BLOCK: "BLOCK",
  UNBLOCK: "UNBLOCK",
  USERNAME: "USERNAME",
  CREATE_ENTITY: "CREATE_ENTITY",
  EDIT_PAYOUT_SETTINGS: "EDIT_PAYOUT_SETTINGS",
};

export default Keys;
