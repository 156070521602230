import { Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { Money } from "components/Money";
import { DateAndTime } from "components/DataRow";
import { Container } from "./common";
import PayoutStatusCodeName from "components/Transaction/Payout/PayoutStatusCodeName";
import TransactionDetailsLink from "components/Transaction/TransactionDetailsLink";
import TransactionTypeName from "components/Transaction/TransactionTypeName";

const displayStringMap = {
  payout_reverse: "Payout Reverse",
  payout_escrow: "Payout Escrow",
  payout_execute: " ",
};
const toDisplayString = (invoiceType = "") => displayStringMap[invoiceType] || invoiceType;

function PayoutGridRow({ payout }) {
  return (
    <Container data-testid="transaction-payout-row">
      <div>
        <DateAndTime value={payout.date} />
        <TransactionDetailsLink transaction={payout} />
      </div>

      <div>
        <div>
          <TransactionTypeName transactionType={payout.type} />
        </div>
        <div>
          <PayoutStatusCodeName payoutStatusCode={payout.statusCode} />
        </div>
      </div>
      <div>{toDisplayString(payout.invoiceType)}</div>
      <div>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td isNumeric={true}>
                <Money value={payout.amount} currency={payout.currencyCode} />
              </Td>
              <Td>{payout.credit?.destinationPaymentMethod?.displayString || "---"}</Td>
            </Tr>
          </Tbody>
        </Table>
      </div>

      <div />
      <div />
      <div />
    </Container>
  );
}

export default PayoutGridRow;
