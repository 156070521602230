// NOTE: The names of most exported objects exactly match the equivalent constant strings in the
// Go services code -- DO NOT RENAME THEM!
//
// This helps to ensure this file, and any usages of these constants in our own code, appear in
// full-code searches for those strings, so that Tools isn't excluded from critical updates that
// modify the underlying functionality.

/**
 * @typedef {Object} SubItemIdentifier
 * @property {string} invoiceId - The ID of the invoice.
 * @property {string} subItemId - The ID of the invoice sub item.
 * @property {string} integrator - The publisher that the invoice belongs to.
 */

/**
 * Invoice sub items record the itemized details of movements of funds between wallets and payment methods during the processing of an invoice.
 * @typedef {Object} InvoicingApiSubItem
 * @property {string} subitem_id Sub Item UUID
 * @property {string} invoice_id Parent invoice UUID
 * @property {number} amount Amount of the sub item in its own currency.
 * @property {number} amount_usd Equivalent amount of the sub item in USD, using the exchange rate in force at the time of creation.
 * @property {string} currency Sub item currency.
 * @property {string} display_amount Pre-formatted string of the amount+currency in US-English locale.
 * @property {string} invoice_reference Reference back to invoice-specific object (payment method, line item, etc.) associated with the sub item.
 * @property {string} reference_type Type of the object associated with the sub item, may be customer-generated.
 * @property {string} reference_id ID of the object associated with the sub item, may be customer-generated.
 * @property {InvoicingApiSubItemType} subitem_type Type of subitem: describes the purpose of the fund movement.
 * @property {string} source_account_id Account UUID that owns the source (debit) side of the fund movement.
 * @property {string} source_payment_method_id Source payment method UUID, or an empty string if the source is a wallet.
 * @property {string} source_wallet_id Source wallet UUID, or an empty string if the source is a payment method.
 * @property {string} destination_account_id Account UUID that owns the destination (credit) side of the fund movement.
 * @property {string} destination_payment_method_id Destination payment method UUID, or an empty string if the destination is a wallet.
 * @property {string} destination_wallet_id Destination wallet UUID, or an empty string if the destination is a payment method.
 * @property {string} description Generated description of the effect of the fund movement.
 * @property {string} [metadata] JSON string of sub item metadata that doesn't fit in any of the existing reference fields.
 * @property {string} exchange_id Foreign currency exchange UUID, or an empty string if no currency exchange was performed while processing this subitem.
 * @property {Object} tags Tags attached to this sub item.
 * @property {string} created - ISO-8601 date/time string when the sub item was created
 * @property {string} updated - ISO-8601 date/time string when the sub item was last updated
 */

/**
 * Invoice sub item types.
 * @enum {string}
 */
export const InvoicingApiSubItemType = Object.freeze({
  ARToAssetSI: "ar_to_asset",
  AssetToAPSI: "asset_to_ap",
  CancelEscrowRefundSI: "cancel_escrow_refund",
  CurrencyConversionSI: "currency_conversion",
  DepositSI: "deposit",
  DepositTiliaFeeSI: "deposit_tilia_fee",
  EarnedVirtualDebitSI: "earned_virtual_debit",
  EarnedVirtualPaymentSI: "earned_virtual_payment",
  EarnedVirtualRefundSI: "earned_virtual_refund",
  FxRefundSI: "fx_refund",
  FxSI: "fx",
  IntegratorCreditSI: "integrator_credit",
  IntegratorCreditVirtualSI: "integrator_credit_virtual",
  IntegratorDebitSI: "integrator_debit",
  IntegratorDebitVirtualSI: "integrator_debit_virtual",
  IntegratorDepositSubitemSI: "integrator_deposit",
  IntegratorRevenueCancelEscrowSI: "integrator_revenue_cancel_escrow",
  IntegratorRevenueCancelEscrowVirtualSI: "integrator_revenue_cancel_escrow_virtual",
  IntegratorRevenueCommitEscrowSI: "integrator_revenue_commit_escrow",
  IntegratorRevenueCommitEscrowVirtualSI: "integrator_revenue_commit_escrow_virtual",
  IntegratorRevenueEscrowSI: "integrator_revenue_escrow",
  IntegratorRevenueEscrowVirtualSI: "integrator_revenue_escrow_virtual",
  IntegratorRevenueSI: "integrator_revenue",
  IntegratorRevenueViaRecipientCancelEscrowSI: "integrator_revenue_via_recipient_cancel_escrow",
  IntegratorRevenueViaRecipientCancelEscrowVirtualSI:
    "integrator_revenue_via_recipient_cancel_escrow_virtual",
  IntegratorRevenueViaRecipientCommitEscrowSI: "integrator_revenue_via_recipient_commit_escrow",
  IntegratorRevenueViaRecipientCommitEscrowVirtualSI:
    "integrator_revenue_via_recipient_commit_escrow_virtual",
  IntegratorRevenueViaRecipientEscrowSI: "integrator_revenue_via_recipient_escrow",
  IntegratorRevenueViaRecipientEscrowVirtualSI: "integrator_revenue_via_recipient_escrow_virtual",
  IntegratorRevenueViaRecipientSI: "integrator_revenue_via_recipient",
  IntegratorRevenueViaRecipientVirtualClawbackSI:
    "integrator_revenue_via_recipient_virtual_clawback",
  IntegratorRevenueViaRecipientVirtualSI: "integrator_revenue_via_recipient_virtual",
  IntegratorRevenueVirtualClawbackSI: "integrator_revenue_virtual_clawback",
  IntegratorRevenueVirtualSI: "integrator_revenue_virtual",
  PaymentFacilitatorSI: "payment_facilitator",
  PaymentSI: "payment",
  PaymentVirtualSI: "payment_virtual",
  PayoutEscrowReverseSI: "payout_escrow_reverse",
  PayoutEscrowSI: "payout_escrow",
  PayoutExecuteSI: "payout_execute",
  PayoutSI: "payout",
  PayoutTiliaFeeSI: "payout_tilia_fee",
  RefundFacilitatorSI: "refund_facilitator",
  RefundSI: "refund",
  RefundTokenSpendFeeSI: "refund_token_spend_fee",
  RefundVirtualSI: "refund_virtual",
  StandardVirtualDebitSI: "standard_virtual_debit",
  StandardVirtualPaymentSI: "standard_virtual_payment",
  StandardVirtualRefundSI: "standard_virtual_refund",
  TaxRefundSI: "tax_refund",
  TaxSI: "tax",
  TokenConvertAssetDebitSI: "token_convert_asset_debit",
  TokenConvertFeeSI: "token_convert_fee",
  TokenConvertIntegratorFeeSI: "token_convert_integrator_fee",
  TokenConvertTiliaFeeSI: "token_convert_tilia_fee",
  TokenConvertUSDCreditSI: "token_convert_usd_credit",
  TokenPurchaseAssetDebitSI: "token_purchase_asset_debit",
  TokenPurchaseFulfillmentSI: "token_purchase_fulfillment",
  TokenPurchaseIntegratorFeeSI: "token_purchase_integrator_fee",
  TokenPurchaseRefundAssetCreditSI: "token_purchase_refund_asset_credit",
  TokenPurchaseTiliaFeeSI: "token_purchase_tilia_fee",
  TokenPurchaseVirtualCreditSI: "token_purchase_virtual_credit",
  TokenSpendFeeSI: "token_spend_fee",
  UserToUserMerchantFeeSI: "user_to_user_merchant_fee",
  UserToUserRecipientCancelEscrowSI: "user_recipient_cancel_escrow",
  UserToUserRecipientCancelEscrowVirtualSI: "user_recipient_cancel_escrow_virtual",
  UserToUserRecipientCommitEscrowSI: "user_recipient_commit_escrow",
  UserToUserRecipientCommitEscrowVirtualSI: "user_recipient_commit_escrow_virtual",
  UserToUserRecipientEscrowSI: "user_recipient_escrow",
  UserToUserRecipientEscrowVirtualSI: "user_recipient_escrow_virtual",
  UserToUserRecipientSI: "user_recipient",
  UserToUserRecipientVirtualClawbackSI: "user_recipient_virtual_clawback",
  UserToUserRecipientVirtualSI: "user_recipient_virtual",
  UserToUserTiliaFeeSI: "user_to_user_tilia_fee",
  UserVirtualDebitSI: "user_virtual_debit",
});

/**
 * Sub items representing the "buyer", the source funding for the transaction.
 * @type {Set<InvoicingApiSubItemType>}
 */
export const BuyerPaymentSubItemTypes = new Set([
  InvoicingApiSubItemType.PaymentSI,
  InvoicingApiSubItemType.PaymentVirtualSI,
  InvoicingApiSubItemType.EarnedVirtualPaymentSI,
  InvoicingApiSubItemType.StandardVirtualPaymentSI,
]);

/**
 * Sub items representing refunds to the "buyer" back to their original payment methods.
 * @type {Set<InvoicingApiSubItemType>}
 */
export const BuyerRefundSubItemTypes = new Set([
  InvoicingApiSubItemType.RefundSI,
  InvoicingApiSubItemType.RefundVirtualSI,
  InvoicingApiSubItemType.EarnedVirtualRefundSI,
  InvoicingApiSubItemType.StandardVirtualRefundSI,
  InvoicingApiSubItemType.CancelEscrowRefundSI,
]);

/**
 * Sub items representing the "sellers", who receive some portion of the buyer's funds.
 * @type {Set<InvoicingApiSubItemType>}
 */
export const SellerRevenueSubItemTypes = new Set([
  InvoicingApiSubItemType.UserToUserRecipientSI,
  InvoicingApiSubItemType.UserToUserRecipientVirtualSI,
  InvoicingApiSubItemType.UserToUserRecipientEscrowSI,
  InvoicingApiSubItemType.UserToUserRecipientEscrowVirtualSI,
]);

/**
 * Sub items representing the "sellers" in an escrow transaction specifically.
 * @type {Set<InvoicingApiSubItemType>}
 */
export const SellerRevenueEscrowSubItemTypes = new Set([
  InvoicingApiSubItemType.UserToUserRecipientEscrowSI,
  InvoicingApiSubItemType.UserToUserRecipientEscrowVirtualSI,
]);

export const ClawbackSubItemTypes = new Set([
  InvoicingApiSubItemType.EarnedVirtualDebitSI,
  InvoicingApiSubItemType.StandardVirtualDebitSI,
  InvoicingApiSubItemType.UserVirtualDebitSI,
]);

/**
 * Sub items representing the "publisher", who may receive some portion of the buyer's funds either
 * through a direct purchase, or the assessment of fees.
 * @type {Set<InvoicingApiSubItemType>}
 */
export const PublisherRevenueSubItemTypes = new Set([
  InvoicingApiSubItemType.IntegratorRevenueSI,
  InvoicingApiSubItemType.IntegratorRevenueVirtualSI,
  InvoicingApiSubItemType.IntegratorRevenueEscrowSI,
  InvoicingApiSubItemType.IntegratorRevenueEscrowVirtualSI,
  InvoicingApiSubItemType.IntegratorRevenueViaRecipientSI,
  InvoicingApiSubItemType.IntegratorRevenueViaRecipientVirtualSI,
  InvoicingApiSubItemType.IntegratorRevenueViaRecipientEscrowSI,
  InvoicingApiSubItemType.IntegratorRevenueViaRecipientEscrowVirtualSI,
  InvoicingApiSubItemType.UserToUserMerchantFeeSI,
  InvoicingApiSubItemType.TokenPurchaseIntegratorFeeSI,
  InvoicingApiSubItemType.TokenConvertIntegratorFeeSI,
]);

/**
 * Sub items representing either the commit or cancel side of an escrow transaction.
 * @type {Set<InvoicingApiSubItemType>}
 */
export const EscrowCommitCancelSubItemTypes = new Set([
  InvoicingApiSubItemType.UserToUserRecipientCommitEscrowSI,
  InvoicingApiSubItemType.UserToUserRecipientCommitEscrowVirtualSI,
  InvoicingApiSubItemType.UserToUserRecipientCancelEscrowSI,
  InvoicingApiSubItemType.UserToUserRecipientCancelEscrowVirtualSI,
  InvoicingApiSubItemType.IntegratorRevenueCommitEscrowSI,
  InvoicingApiSubItemType.IntegratorRevenueCommitEscrowVirtualSI,
  InvoicingApiSubItemType.IntegratorRevenueViaRecipientCommitEscrowSI,
  InvoicingApiSubItemType.IntegratorRevenueViaRecipientCommitEscrowVirtualSI,
  InvoicingApiSubItemType.IntegratorRevenueCancelEscrowSI,
  InvoicingApiSubItemType.IntegratorRevenueCancelEscrowVirtualSI,
  InvoicingApiSubItemType.IntegratorRevenueViaRecipientCancelEscrowSI,
  InvoicingApiSubItemType.IntegratorRevenueViaRecipientCancelEscrowVirtualSI,
]);

/**
 * Sub items representing fees assessed directly upon the buyer by Tilia.
 * @type {Set<InvoicingApiSubItemType>}
 */
export const TiliaRevenueSubItemTypes = new Set([
  InvoicingApiSubItemType.UserToUserTiliaFeeSI,
  InvoicingApiSubItemType.TokenSpendFeeSI,
  InvoicingApiSubItemType.PayoutTiliaFeeSI,
  InvoicingApiSubItemType.TokenPurchaseTiliaFeeSI,
  InvoicingApiSubItemType.TokenConvertTiliaFeeSI,
  InvoicingApiSubItemType.DepositTiliaFeeSI,
  InvoicingApiSubItemType.FxUpliftSI,
]);

/**
 * Sub items representing assessment of taxes on the transaction, where funds land in a publisher's
 * designated head-of-state wallet, but are not classified as revenue.
 * @type {Set<InvoicingApiSubItemType>}
 */
export const TaxSubItemTypes = new Set([
  InvoicingApiSubItemType.TaxSI,
  InvoicingApiSubItemType.TaxRefundSI,
]);

/**
 * Sub items that exist only for accounting purposes, temporarily moving funds between wallets to
 * facilitate the transaction.
 * @type {Set<InvoicingApiSubItemType>}
 */
export const AccountingSubItemTypes = new Set([
  InvoicingApiSubItemType.ARToAssetSI,
  InvoicingApiSubItemType.AssetToAPSI,
  InvoicingApiSubItemType.IntegratorCreditSI,
  InvoicingApiSubItemType.IntegratorCreditVirtualSI,
  InvoicingApiSubItemType.IntegratorDebitSI,
  InvoicingApiSubItemType.IntegratorDebitVirtualSI,
  InvoicingApiSubItemType.PaymentFacilitatorSI,
  InvoicingApiSubItemType.RefundFacilitatorSI,
  InvoicingApiSubItemType.TokenPurchaseRefundAssetCreditSI,
  InvoicingApiSubItemType.UserToUserRecipientVirtualClawbackSI,
  InvoicingApiSubItemType.IntegratorRevenueViaRecipientVirtualClawbackSI,
  InvoicingApiSubItemType.IntegratorRevenueVirtualClawbackSI,
  InvoicingApiSubItemType.FxSI,
  InvoicingApiSubItemType.FxRefundSI,
]);

/**
 * Payout-specific sub items.
 * @type {Set<InvoicingApiSubItemType>}
 */
export const PayoutSubItemTypes = new Set([
  InvoicingApiSubItemType.PayoutEscrowSI,
  InvoicingApiSubItemType.PayoutExecuteSI,
  InvoicingApiSubItemType.PayoutSI,
  InvoicingApiSubItemType.PayoutEscrowReverseSI,
]);

/**
 * Token purchase/conversion-specific sub items.
 * @type {Set<InvoicingApiSubItemType>}
 */
export const TokenExchangeSubItemTypes = new Set([
  InvoicingApiSubItemType.TokenPurchaseAssetDebitSI,
  InvoicingApiSubItemType.TokenPurchaseVirtualCreditSI,
  InvoicingApiSubItemType.TokenConvertAssetDebitSI,
  InvoicingApiSubItemType.TokenConvertUSDCreditSI,
  InvoicingApiSubItemType.TokenPurchaseFulfillmentSI,
]);

/**
 * Deposit-specific sub items.
 * @type {Set<InvoicingApiSubItemType>}
 */
export const DepositSubItemTypes = new Set([
  InvoicingApiSubItemType.DepositSI,
  InvoicingApiSubItemType.IntegratorDepositSubitemSI,
]);

/**
 * Currently-known balance transfer-specific sub items. NOTE: Balance transfer configuration
 * is NOT codified! It is managed via settings, and is subject to change at any time. Consequently,
 * these sub item types do not currently have associated constants and this list is best-effort only.
 * @type {Set<string>}
 */
export const BalanceTransferSubItemTypes = new Set([
  "clawback_from_integrator",
  "clawback_from_recipient",
  "deposit", // WARNING: This overlaps with InvoicingApiSubItemType.DepositSI!!
  "facilitator_currency_swap_sink_fiat",
  "facilitator_currency_swap_sink_usd",
  "facilitator_currency_swap_sink_virtual",
  "facilitator_currency_swap_source_fiat",
  "facilitator_currency_swap_source_usd",
  "facilitator_currency_swap_source_virtual",
  "integrator_facilitator_sourced",
  "integrator_revenue_sourced",
  "integrator_sourced_to_tilia",
  "inter_account",
  "intra_account",
  "merge_account_convertible_convertible_transfer",
  "merge_account_standard_standard_transfer",
  "publisher_settlement_credit",
  "publisher_settlement_debit",
  "publisher_sourced",
  "settlement_reconciliation",
  "tilia_sourced",
  "token_convert_integrator_fee_reversal",
  "token_convert_tilia_fee_reversal",
  "token_convert_usd_credit_reversal",
  "token_convert_virtual_debit_reversal",
  "unearned_tokens_created",
  "user_sourced_to_integrator",
  "user_sourced_to_tilia",
]);
