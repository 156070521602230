/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { SettlementDetailSource } from "./settlementTypes";

/**
 * Renders the localized human-readable name of the settlement detail source.
 *
 * @param {SettlementDetailSource} source
 * @returns {JSX.Element}
 */
function SettlementDetailSourceName({ source }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (source) {
    case SettlementDetailSource.TILIA:
      return <>Tilia</>;
    case SettlementDetailSource.WALLET:
      return <>Wallet</>;
    case SettlementDetailSource.PAYPAL:
      return <>PayPal</>;
    case SettlementDetailSource.STRIPE:
      return <>Stripe</>;
    default:
      return <>{source}</>;
  }
}

export default SettlementDetailSourceName;
