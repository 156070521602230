import { useIntegrator } from "context/integratorContext";
import * as R from "ramda";
import { isNilOrEmpty } from "utils";
import { Tooltip, Circle, Box } from "@chakra-ui/react";

export default function IntegratorLogo({ integratorName, size = "48px", fontSize = "1.8em" }) {
  const integrator = useIntegrator(integratorName);
  const logoFile = integrator?.logoFile;
  const displayName = integrator?.displayName || integratorName;

  if (isNilOrEmpty(logoFile)) {
    return (
      <Box role="tooltip" data-testid="integrator-badge">
        <Tooltip hasArrow label={displayName} placement="top">
          <Circle bg="brand.500" color="white" fontSize={fontSize} fontWeight="bold" size={size}>
            {R.toUpper(displayName?.substring(0, 1) || "")}
          </Circle>
        </Tooltip>
      </Box>
    );
  }
  const path = `${process.env.PUBLIC_URL}/static/assets/integrator/${logoFile}`;
  return (
    <Box role="tooltip" data-testid="integrator-badge">
      <Tooltip hasArrow label={displayName} placement="top">
        <img
          alt={`${displayName} logo`}
          style={{
            height: size,
            maxHeight: "48px",
          }}
          src={path}
        />
      </Tooltip>
    </Box>
  );
}
