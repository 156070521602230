import React from "react";
import * as R from "ramda";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { theme } from "styles";
import { isNilOrEmpty, mapIndex } from "utils";

const HighlightSpan = styled.span`
  display: inline-block;
  color: ${theme("colors.highlight.color")};
  background: ${theme("colors.highlight.bg")};
`;

export const FilterHighlighter = ({ children, filter }) => {
  if (isNilOrEmpty(filter)) return children;
  const filterReg = new RegExp(filter.replace(/[#-.]|[[-^]|[?|{}]/g, "\\$&"), "gi");

  const matches = [];
  let arr;
  let nextStart = 0;
  while ((arr = filterReg.exec(children)) !== null) {
    matches.push({
      match: arr,
      // substring from nextStart to index. this is the part between the end
      // of the last match and start of this match.
      partBeforeMatch: children.substring(nextStart, arr.index),
    });
    nextStart = arr.index + arr[0].length;
  }

  return R.pipe(
    R.map((m) => [
      m.partBeforeMatch,
      <HighlightSpan data-testid={`highlight`}>{m.match[0]}</HighlightSpan>,
    ]),
    R.append(children.substring(nextStart)),
    R.flatten,
    mapIndex((p, idx) => <span key={idx}>{p}</span>)
  )(matches);
};
