import { useMutation } from "react-query";
import { debug } from "utils";
import { METHODS, mapProxyFetch, serviceMap } from "../utils";
import buildTiliaApiResponse from "../buildTiliaApiResponse";

const accountsOpsProxy = mapProxyFetch(serviceMap.ACCOUNTS_OPS_API);

const ROOT_GROUP = "root";

const addPermissionGroup = async (groupName, parentGroupName, ctx) => {
  debug(ctx, "[useAddPermissionGroup]", { groupName, parentGroupName });
  const response = await accountsOpsProxy(ctx, `/tools/group`, METHODS.POST, {
    element: groupName,
    element_type: "group_name",
    group_name: parentGroupName,
  });
  const apiResponse = await buildTiliaApiResponse(response);
  if (apiResponse.status !== 200) throw apiResponse.errors;
  return apiResponse.payload;
};

export const useAddPermissionGroup = () => {
  return useMutation({
    mutationFn: ({ groupName, parentGroupName, agent }) => {
      return addPermissionGroup(groupName, parentGroupName || ROOT_GROUP, agent);
    },
  });
};
