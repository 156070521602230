import { MenuButton } from "components/Menu";
import React from "react";
import styled from "styled-components/macro";
import { getRangeWithDots } from "./utils";

const defaultPageSizeOptions = [10, 25, 50];

const Container = styled.div`
  padding: 0;
  display: flex;
  margin-top: 1rem;
  align-items: center;
`;

const ActionBar = styled.ul`
  padding: 0;
  display: flex;
  align-items: center;
  user-select: none;
  list-style-type: none;
  > li {
    margin-right: 8px;
    > span {
      letter-spacing: 1px;
      padding: 0 1px;
    }
    > button {
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      padding: 0px 10px;
      font-weight: 400;
      color: #007bff;
      background-color: transparent;
      border: solid 1px #007bff;
      border-radius: 4px;
      cursor: pointer;
      &:disabled {
        border: solid 1px #6c757d;
        color: #6c757d;
        cursor: default;
      }
      &.active {
        color: white;
        cursor: pointer;
        border: solid 1px #007bff;
        background-color: #007bff;
      }
    }
  }
`;

const PageLabel = styled.li`
  padding-left: 0.5em;
  padding-right: 0.5em;
  color: #333;
`;

const PageSizeSelector = styled(MenuButton)`
  margin-left: auto;
  width: 80px;
`;

// TODO: Convert to TypeScript Enum
export const ConnectionPageNumber = {
  First: 0,
  Previous: 1,
  Next: 2,
  Last: 3,
};

const Pager = ({
  pageSize,
  currentPage,
  totalPages,
  setPage,
  updatePageSize,
  hidePageSizeSelector = false,
  connection,
}) => {
  let isFirstDisabled;
  let isLastDisabled;

  // if a GraphQL connection object is provided, use different behaviors
  if (connection) {
    isFirstDisabled = !connection.pageInfo?.hasPreviousPage;
    isLastDisabled = !connection.pageInfo?.hasNextPage;
  } else {
    if (typeof currentPage !== "number") {
      currentPage = parseInt(currentPage, 10) || 0;
    }
    isFirstDisabled = currentPage === 0;
    isLastDisabled = currentPage === totalPages - 1;
  }

  const renderNumberButtons = () => {
    if (connection) {
      if (!connection.pageInfo) {
        return null;
      }
      if (connection.totalCount < 1) {
        return <PageLabel>No Data</PageLabel>;
      }
      const label = `${connection.pageInfo.offset}–${
        connection.pageInfo.offset + connection.edges.length - 1
      } of ${connection.totalCount}`;
      return <PageLabel>{label}</PageLabel>;
    }

    const items = [];
    const rangeWithDots = getRangeWithDots(currentPage + 1, totalPages);

    for (let i = 0; i < rangeWithDots.length; i += 1) {
      const label = rangeWithDots[i];

      if (isNaN(label)) {
        items.push(
          <li key={i}>
            <span data-testid="dots">{label}</span>
          </li>
        );
      } else {
        const value = rangeWithDots[i] - 1;
        let isDisabled = currentPage === value;

        if (i === 0) isDisabled = isFirstDisabled;
        if (i === totalPages - 1) isDisabled = isLastDisabled;
        items.push(
          <li key={i}>
            <button
              disabled={isDisabled}
              onClick={() => setPage(value)}
              data-testid={`pager-number-${value}`}
              className={currentPage === value ? "active" : ""}
            >
              {label}
            </button>
          </li>
        );
      }
    }
    return <>{items}</>;
  };

  const rengerPageChangers = () => {
    if (!connection && totalPages < 2) {
      return null;
    }

    return (
      <ActionBar key="action-bar">
        <li>
          <button
            data-testid="pager-go-first-button"
            disabled={isFirstDisabled}
            onClick={() => setPage(connection ? ConnectionPageNumber.First : 0)}
          >
            {"<<"}
          </button>
        </li>
        <li>
          <button
            data-testid="pager-prev-button"
            disabled={isFirstDisabled}
            onClick={() => setPage(connection ? ConnectionPageNumber.Previous : currentPage - 1)}
          >
            {"<"}
          </button>
        </li>
        {renderNumberButtons()}
        <li>
          <button
            data-testid="pager-next-button"
            disabled={isLastDisabled}
            onClick={() => setPage(connection ? ConnectionPageNumber.Next : currentPage + 1)}
          >
            {">"}
          </button>
        </li>
        <li>
          <button
            data-testid="pager-go-last-button"
            disabled={isLastDisabled}
            onClick={() => setPage(connection ? ConnectionPageNumber.Last : totalPages - 1)}
          >
            {">>"}
          </button>
        </li>
      </ActionBar>
    );
  };

  const renderPageSizeSelector = () => (
    <PageSizeSelector
      key="page-size-selector"
      data-testid="page-size-selector"
      buttonLabel={pageSize}
      items={defaultPageSizeOptions.map((number) => ({
        label: number,
        disabled: pageSize === number,
        onSelect: () => updatePageSize(number),
      }))}
    />
  );

  return (
    <Container>
      {[rengerPageChangers(), !hidePageSizeSelector && renderPageSizeSelector()].filter(Boolean)}
    </Container>
  );
};

export default Pager;
