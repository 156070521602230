import { Alert, AlertIcon, Text, Box, AlertDescription } from "@chakra-ui/react";
import { ensureArray, exists } from "utils";

export default function ModalErrors({ errors = [], footnote }) {
  if (!exists(errors)) return null;
  const errorList = ensureArray(errors);
  return (
    <Alert width={"auto"} status="error" mb={3}>
      <AlertIcon />
      <Box>
        {errorList.map((e, index) => (
          <AlertDescription key={index}>{exists(e.message) ? e.message : e}</AlertDescription>
        ))}
      </Box>
      {footnote && <Text>{footnote}</Text>}
    </Alert>
  );
}
