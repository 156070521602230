import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { SEARCH_ACCOUNTS, SEARCH_RESOURCE, withPermissions } from "context/permissions";
import { SearchIcon } from "../Icons";
import { getSearchQueryString } from "utils";
import { theme } from "styles";
import { keys, useKeys } from "utils/keys";
import { validate as uuidValidate } from "uuid";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 16px;
`;

const Input = styled.input`
  padding: 8px;
  border: 0;
  border-radius: 2px;
  flex: 1;
  background-color: ${theme("colors.forms.bg")};
  color: ${theme("colors.forms.color")};
`;

const transformInput = (input) => {
  if (uuidValidate(input)) {
    return input.toLowerCase();
  } else {
    return input;
  }
};

export const Search = withPermissions(
  { permissions: [SEARCH_ACCOUNTS], resource: SEARCH_RESOURCE },
  ({ permissions }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const inputRef = useRef(null);
    const [inputState, setInputState] = useState("");

    useEffect(() => {
      const qsQuery = getSearchQueryString(location.search);
      setInputState(transformInput(qsQuery || ""));
    }, [location]);

    useKeys(keys.searchFocus, () => {
      inputRef.current.focus();
      return false;
    });

    const handleChange = (e) => {
      setInputState(e.target.value);
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        if (inputState.length > 0) {
          inputRef.current.blur();
          setInputState(transformInput(inputState));
          const params = new URLSearchParams();
          params.set("q", transformInput(inputState));
          params.set("currentPage", 0);
          params.set("pageSize", 10);
          params.set("sortField", "account_id");
          params.set("sortDir", "asc");
          navigate(`/search?${params.toString()}`);
        }
      }
    };
    const handleFocus = (e) => e.target.select();

    return (
      <Container>
        {permissions.hasPermission ? (
          <>
            <SearchIcon
              css={`
                margin-right: 8px;
              `}
            />
            <Input
              ref={inputRef}
              data-testid="search-input"
              value={inputState}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              onFocus={handleFocus}
              placeholder="Search: Username | Email | First-Last Name | Account ID | Invoice ID"
            />
          </>
        ) : (
          <div data-testid="search-no-permission" />
        )}
      </Container>
    );
  }
);
