import React, { useState, useCallback, useEffect } from "react";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { theme } from "styles";
import { useAgent } from "context/agentContext";
import { useAlert } from "context/alertContext";
import * as Permissions from "context/permissions";
import { PageContent } from "components/Page";
import { SectionError as Error } from "components/Error";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import { PermissionsMessage } from "components/PermissionsMessage";
import { useAddCredentialModal } from "./useAddCredentialModal";
import { useDeleteCredentialModal } from "./useDeleteCredentialModal";
import { FullPageSpinner } from "components/Spinner";
import { getSSOProviders, postSSOProviders, deleteSSOProviders } from "api/toolsWeb";
import { exists } from "utils";

const TextContainer = styled.div`
  width: fit-content;
  min-width: clamp(25%, 30%, 75%);
  margin-right: 16px;
`;

const TextDisplay = styled.div`
  padding: 8px;
  color: ${theme("colors.textArea.inactive.color")};
  background: ${theme("colors.textArea.inactive.bg")};
  border-radius: 3px;
  margin: 8px 0px;
`;

const ModalWrapper = styled.div`
  float: right;
`;

export const SSO_PROVIDERS = [{ id: "google", label: "Google" }];

export const SSOManagement = ({ integrator }) => {
  const { agent } = useAgent();
  const [credentials, setCredentials] = useState({});
  const [error, setError] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(false);
  const { integratorId } = integrator;
  const { toggleDisplay, setAlertMessage, setAlertDismiss } = useAlert();
  setAlertDismiss(true);

  const selectGoogleCreds = (creds) => creds.filter((x) => x?.sso_provider === SSO_PROVIDERS[0].id);

  const getCredentials = useCallback(async () => {
    try {
      setLoading(true);
      const creds = await getSSOProviders(agent, integratorId);
      let justGoogleSSOCreds = [];
      if (exists(creds.value)) justGoogleSSOCreds = selectGoogleCreds(creds.value);
      setCredentials(justGoogleSSOCreds[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError({
        error: true,
        message: error?.message ?? "An error occured while retrieving sso data.",
      });
    }
  }, [agent, integratorId]);

  const onSubmit = useCallback(
    async (creds) => postSSOProviders(agent, integratorId, creds.sso_provider, creds),
    [agent, integratorId]
  );

  const successfulSubmit = useCallback(
    async (message) => {
      getCredentials();
      toggleDisplay(true);
      setAlertMessage(message);
    },
    [getCredentials, setAlertMessage, toggleDisplay]
  );

  const onDelete = useCallback(
    async (sso_provider) => deleteSSOProviders(agent, integratorId, sso_provider),
    [agent, integratorId]
  );

  useEffect(() => {
    getCredentials();
  }, [getCredentials, onDelete]);

  const [AddModal, AddModalButton] = useAddCredentialModal();
  const [DeleteModal, DeleteModalButton] = useDeleteCredentialModal(integrator);

  if (!agent.hasPermissions(Permissions.READ_SSO_CREDENTIALS)) {
    return (
      <PageContent title={{ page: "SSO" }} opts={{ clear: true }}>
        <Panel
          css={`
            padding: 8px;
          `}
        >
          <PermissionsMessage />
        </Panel>
      </PageContent>
    );
  }

  if (loading) {
    return <FullPageSpinner />;
  }

  if (error?.error) {
    return (
      <PageContent>
        <Error>
          <div>Apologies, there has been an error.</div>
          <div>{error?.message}</div>
        </Error>
      </PageContent>
    );
  }

  const providerLabel = (value) => {
    const provider = SSO_PROVIDERS.filter((provider) => provider.id === value);
    return provider[0].label;
  };

  const displayAddButton = agent.hasPermissions(PermissionsMessage.WRITE_SSO_CREDENTIALS) ? (
    <>
      <AddModalButton />
      <AddModal
        onSubmit={onSubmit}
        onSuccess={() => successfulSubmit("SSO credentials successfully saved!")}
      />
    </>
  ) : (
    <PermissionsMessage />
  );

  return (
    <Panel>
      <PanelTitle
        css={`
          margin-bottom: 0px;
        `}
      >
        <span>SSO Managment</span>
      </PanelTitle>
      <PanelContent>
        {exists(credentials) ? (
          <TextContainer>
            <label>Provider</label>
            <TextDisplay>{providerLabel(credentials?.sso_provider)}</TextDisplay>
            <label>Client ID</label>
            <TextDisplay>{credentials?.client_id}</TextDisplay>
            <label>Client Secret</label>
            <TextDisplay>{credentials?.client_secret}</TextDisplay>
            <ModalWrapper>
              <DeleteModalButton />
              <DeleteModal
                onSubmit={() => onDelete(credentials?.sso_provider)}
                onSuccess={() => successfulSubmit("SSO credentials successfully deleted!")}
              />
            </ModalWrapper>
          </TextContainer>
        ) : (
          displayAddButton
        )}
      </PanelContent>
    </Panel>
  );
};
