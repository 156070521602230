import React from "react";
import * as R from "ramda";
import "styled-components/macro";
import { Flex, ButtonGroup } from "@chakra-ui/react";
import { Differ, Viewer } from "json-diff-kit";
import "json-diff-kit/dist/viewer-monokai.css";
import { LIGHT, useTheme } from "styles";
import { CopyButton } from "components/Copyable";
import { useAddEditSettingModal } from "../AddEditSettingModal";
import { TopBox } from "../common";

// returns true is the diff object contains any actual differences
const hasDiff = (diff) => {
  const allEqual0 = R.all(R.propEq("equal", "type"), diff[0]);
  const allEqual1 = R.all(R.propEq("equal", "type"), diff[1]);
  return !(allEqual0 && allEqual1);
};

export const ComparinatorRow = ({ data = {}, onUpdate, diffOnly = false }) => {
  const { currentTheme } = useTheme();
  const viewerTheme = currentTheme._name === LIGHT ? undefined : { theme: "monokai" };

  const differ = new Differ({
    arrayDiffMethod: "lcs", // default `"normal"`, but `"lcs"` may be more useful
  });
  const diff = differ.diff(data.s1Value, data.s2Value);
  const hasDifferences = hasDiff(diff);

  const [S1EditModal, S1EditButton] = useAddEditSettingModal();
  const [S2EditModal, S2EditButton] = useAddEditSettingModal();

  const s1String = data.s1Value ? JSON.stringify(data.s1Value, null, 2) : "";
  const s2String = data.s2Value ? JSON.stringify(data.s2Value, null, 2) : "";

  return (
    <React.Fragment>
      <TopBox>{data.key}</TopBox>
      <TopBox>
        <div>
          <Viewer
            syntaxHighlight={viewerTheme}
            css={`
              &.json-diff-viewer tr.message-line {
                border: 0;
                text-align: initial;
              }
            `}
            diff={diff}
            highlightInlineDiff={true}
            hideUnchangedLines={diffOnly}
          />
          {hasDifferences && (
            <Flex justifyContent={"space-between"} mt={2}>
              <ButtonGroup variant="outline" size="xs">
                <CopyButton text={s1String} />
                <S1EditButton label="Edit" />
                <S1EditModal
                  prefix={data.s1Prefix}
                  settingsKey={data.s1Key}
                  settingsValue={s1String}
                  onSuccess={onUpdate}
                />
              </ButtonGroup>

              <ButtonGroup variant="outline" size="xs">
                <CopyButton text={s2String} />
                <S2EditButton label="Edit" />
                <S2EditModal
                  prefix={data.s2Prefix}
                  settingsKey={data.s2Key}
                  settingsValue={s2String}
                  onSuccess={onUpdate}
                />
              </ButtonGroup>
            </Flex>
          )}
        </div>
      </TopBox>
    </React.Fragment>
  );
};
