import React, { useCallback } from "react";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import * as Yup from "yup";
import { createAccountNote } from "api/notes";
import { useAgent } from "context/agentContext";
import { useFormModal } from "components/Modal";
import { NoteIcon } from "components/Icons";
import { NoteField } from "components/Forms";
import { ctxWithIntegrator } from "utils/auth";
import { validationMessages as vm } from "utils/forms";
import useAgentCanWriteAccountNotes from "./useAgentCanWriteAccountNotes";
import { useAlert } from "context/alertContext";

export const useAddNoteModal = () => {
  const { agent } = useAgent();
  const canWriteAccountNotes = useAgentCanWriteAccountNotes();
  const [FormModal, openModal] = useFormModal();
  const { fireAlert } = useAlert();

  const initAndOpen = useCallback(() => {
    openModal();
  }, [openModal]);

  const AddNoteModal = ({ accountId, integrator, onSuccessfulUpdate }) => {
    const handleAddNote = async ({ note }) => {
      const response = await createAccountNote(
        {
          accountId,
          subject: "",
          note,
        },
        ctxWithIntegrator(integrator, agent)
      );
      if (response.status === 201) {
        return { status: "Success" };
      }
      return response;
    };

    const handleSubmitSuccess = () => {
      onSuccessfulUpdate();
      fireAlert("New account note successfully added!");
    };

    const validationSchema = Yup.object().shape({
      note: Yup.string().required(vm.required),
    });

    return (
      <FormModal
        titleText="Add Account Note"
        actionText="Add Note"
        submitAction={handleAddNote}
        onSubmitActionSuccess={handleSubmitSuccess}
        formProps={{
          initialValues: {
            note: "",
          },
          validationSchema,
        }}
      >
        <NoteField name="note" label="Note" />
      </FormModal>
    );
  };

  const getMenuItem = useCallback(() => {
    return {
      disabled: !canWriteAccountNotes,
      "data-displayitem": canWriteAccountNotes,
      label: `Add Note...`,
      icon: <NoteIcon size="1rem" />,
      onSelect: canWriteAccountNotes && initAndOpen,
    };
  }, [initAndOpen, canWriteAccountNotes]);

  return [AddNoteModal, getMenuItem, initAndOpen];
};
