/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { gql, useQuery } from "@apollo/client";

const getWalletLedger = gql`
  query GetWalletLedger($id: ID!, $integrator: String!) {
    wallet(id: $id, integrator: $integrator) {
      ledger {
        edges {
          node {
            amount
            date
            balanceAfter
            currency
            transaction {
              ... on LegacyTransaction {
                id
                type
                invoiceId
                invoiceType
              }
              ... on Payout {
                id
                type
                payoutStatusId
              }
              ... on TokenPurchase {
                id
                type
                tokenPurchaseId
              }
              ... on TokenConversion {
                id
                type
                tokenConversionId
              }
            }
          }
        }
      }
    }
  }
`;

const useWalletLedger = (walletIndentifier) => {
  return useQuery(getWalletLedger, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    variables: {
      id: walletIndentifier.id,
      integrator: walletIndentifier.integrator,
    },
  });
};

export default useWalletLedger;
