import { GridItem, Stack } from "@chakra-ui/react";
import { ASIDE_STACK_GAP } from "./TransactionLayout";

function TransactionColumnLeft({ children }) {
  return (
    <GridItem colSpan={{ base: "auto", lg: 1 }}>
      {/* Content for the first column */}
      <Stack gap={ASIDE_STACK_GAP}>{children}</Stack>
    </GridItem>
  );
}

export default TransactionColumnLeft;
