import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { ifProp, theme } from "styles";
import { ASC, DESC } from "utils/useSorting";

export const Cell = styled.div`
  padding: 8px;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  user-select: none;
  ${ifProp("right", "display: flex; justify-content: flex-end;", "")}
  ${ifProp("disabled", "color: grey;", "")}
  &:first-of-type {
    padding-left: 4px;
  }
  &:last-of-type {
    padding-right: 4px;
  }
`;

export const HeaderCell = styled(Cell)`
  user-select: none;
  white-space: nowrap;
`;

export const SortingHeaderCell = ({
  children,
  field,
  sortField,
  sortDirection,
  onSortChange,
  ...cellProps
}) => {
  return (
    <HeaderCell
      {...cellProps}
      css={`
        &:hover {
          background-color: ${theme("colors.sorter.hoverBg")};
        }
        user-select: none;
        cursor: pointer;
        border-bottom: 4px solid;
        border-bottom-color: ${field === sortField && sortDirection === DESC
          ? "#ddd"
          : "transparent"};
        border-top: 4px solid;
        border-top-color: ${field === sortField && sortDirection === ASC ? "#ddd" : "transparent"};
      `}
      onClick={() => onSortChange(field)}
    >
      {children}
    </HeaderCell>
  );
};
