import { useContext, useMemo } from "react";
import { AppConfigContext } from "context/appConfigContext";
import { CurrencyDisplay } from "./types.js";
import createCurrencyFormatter from "./createCurrencyFormatter.js";

/**
 * Returns a formatter function which accepts a Tilia currency value (the value in the
 * lowest-common denomination of that currency, for example: cents for "USD") as a BigInt,
 * Number, or string, and returns a localized formatted string.
 *
 * @param {string} currency
 * @param {CurrencyDisplay} currencyDisplay
 * @returns {function(value: bigint|Number|string): string}
 */
function useCurrencyFormatter(currency, currencyDisplay = CurrencyDisplay.Symbol) {
  const appConfig = useContext(AppConfigContext);

  // TODO: Obtain locale from IntlProvider context instead
  const locale = navigator.language;

  return useMemo(() => {
    return createCurrencyFormatter(
      locale,
      currency,
      currencyDisplay,
      appConfig?.currencyInfo || {} // primarily for tests; use default rules if no AppConfigContext
    );
  }, [locale, currency, currencyDisplay, appConfig?.currencyInfo]);
}

export default useCurrencyFormatter;
