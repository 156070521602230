import React, { useEffect, useState } from "react";
import * as R from "ramda";
import "styled-components/macro";
import { Navigate, useLocation, useNavigate, Routes, Route } from "react-router-dom";
import { PageContent, PageTitle } from "components/Page";
import { PermissionsMessage } from "components/PermissionsMessage";
import {
  READ_PERMISSIONS,
  PERMISSIONS_RESOURCE,
  withPermissions,
  useSystemPermissionsModel,
} from "context/permissions";
import { SectionSpinner } from "components/Spinner";
import { isNilOrEmpty, validRegexOrNull, STATUS, getLocQueryString } from "utils";
import { ByGroup } from "./ByGroup";
import { ByPermission } from "./ByPermission";
import { ByAgent } from "./ByAgent";
import { PermissionsSubNav } from "./SubNav";

const filterQueryStringKey = "filter";

const getFilterFromQuery = R.pipe(getLocQueryString, R.prop(filterQueryStringKey));

const updateSearch = (search, newFilter) => {
  const params = new URLSearchParams(search);
  isNilOrEmpty(newFilter)
    ? params.delete(filterQueryStringKey)
    : params.set(filterQueryStringKey, newFilter);
  return params.toString();
};

const useFilterSync = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const filterFromQuery = getFilterFromQuery(location);

  const [filterString, setFilter] = useState(filterFromQuery || "");
  const [pendingQsFilter, setPending] = useState(undefined);

  useEffect(() => {
    if (filterString !== filterFromQuery) {
      setPending(filterString);
    }
  }, [filterString, filterFromQuery]);

  useEffect(() => {
    // if the filter is cleared out, pending will be empty string instead of null
    // 1 treat null as a special case
    if (pendingQsFilter === undefined) return;
    // 2 treat empty pending vs null/undefined query as equal
    if (pendingQsFilter === "" && isNilOrEmpty(filterFromQuery)) {
      return;
    }

    // 3 otherwise if they differ, update the query string
    if (pendingQsFilter !== filterFromQuery) {
      navigate({
        ...location,
        search: updateSearch(location.search, pendingQsFilter),
      });
    }
  }, [pendingQsFilter, filterFromQuery, navigate, location]);

  return { filterFromQuery, filterString, setFilter };
};

export const Permissions = withPermissions(
  {
    permissions: [READ_PERMISSIONS],
    resource: PERMISSIONS_RESOURCE,
  },
  ({ permissions: pagePermissions }) => {
    const { state, refreshData } = useSystemPermissionsModel();
    const { filterString, setFilter } = useFilterSync();

    if (!pagePermissions.hasPermission) {
      return <PermissionsMessage />;
    }

    if (state.status === STATUS.PRE_INIT || state.status === STATUS.INITIALIZED) {
      return <SectionSpinner />;
    }
    const filterRegex = validRegexOrNull(filterString);

    return (
      <PageContent title={{ page: "Permissions" }}>
        <PageTitle>Permissions</PageTitle>
        <PermissionsSubNav
          filter={filterString}
          onFilterChange={setFilter}
          data={state.data}
          onAddGroup={refreshData}
        />
        <Routes>
          <Route
            path="by-group/:group?"
            element={<ByGroup data={state.data} onChange={refreshData} filter={filterRegex} />}
          />
          <Route
            path="by-agent/:agentId?"
            element={<ByAgent data={state.data} filter={filterRegex} />}
          />
          <Route
            path="by-permission/:scope?"
            element={<ByPermission data={state.data} filter={filterRegex} />}
          />
          <Route exact path="/" element={<Navigate to="by-group" />} />
        </Routes>
      </PageContent>
    );
  }
);
