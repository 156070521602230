import React, { useState, useEffect, useCallback } from "react";
import "styled-components/macro";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import { useAccount } from "context/accountContext";
import { DataRow } from "components/DataRow";
import { PermissionsMessage } from "components/PermissionsMessage";
import { isNilOrEmpty } from "utils";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import { PageContent, PageTitle } from "components/Page";
import { WRITE_KYB, READ_KYB, ENTITY_DETAILS_RESOURCE, withPermissions } from "context/permissions";
import { AgentDetails } from "./AgentDetails";
import { KYBDocuments } from "./KYBDocuments";
import { useEditEntityDetailsModal } from "./EditEntityDetailsModal";

const capitalizeFirstLetter = (word) => {
  return R.toUpper(word[0]) + R.toLower(R.slice(1, Infinity, word));
};

const EntityDetails = ({ data = {}, onChange }) => {
  const [EditModal, EditModalButton] = useEditEntityDetailsModal({ edit: true });
  const { updateEntity } = useAccount();
  const onSubmit = (args) =>
    updateEntity(data.entity_id, {
      account_id: data.account_id,
      integrator: data.integrator,
      ...args,
    });
  if (isNilOrEmpty(data)) {
    return (
      <>
        <PanelTitle>Entity Details</PanelTitle>
        <PanelContent data-testid="entity-not-found">
          Please check to see if the entity ID is correct.
        </PanelContent>
      </>
    );
  }
  return (
    <>
      <PanelTitle>
        <span>Entity Details</span>
        <span>
          <EditModalButton />
          <EditModal entityInfo={data} onSubmit={onSubmit} onSuccess={onChange} />
        </span>
      </PanelTitle>
      <PanelContent>
        <div
          css={`
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
            grid-column-gap: 16px;
          `}
        >
          <div>
            <DataRow title="Entity Name" data={data?.entity_name} />
            <DataRow title="Status" data={data?.status} />
            <DataRow title="DBA" data={data?.dba} tooltip={"Does Business As"} />
            <DataRow title="Entity Type" data={data?.entity_type} />
            <DataRow title="TIN" data={data?.tax_id} tooltip={"Tax ID Number"} />
            <DataRow
              title="NAICS"
              data={data?.naics_numbers}
              tooltip={"North American Industry Classification System"}
            />
            <DataRow title="DUNS" data={data?.duns_numbers} tooltip={"Dun and Bradstreet Number"} />
          </div>
          <div>
            <DataRow title="Integrator" data={capitalizeFirstLetter(data?.integrator)} />
            <DataRow title="Street" data={data?.street} />
            <DataRow title="City" data={data?.city} />
            <DataRow title="State" data={data?.state} />
            <DataRow title="Zip" data={data?.zip} />
            <DataRow title="Country" data={data?.country_iso} />
          </div>
        </div>
      </PanelContent>
    </>
  );
};

export const Entity = withPermissions(
  { permissions: [WRITE_KYB, READ_KYB], resource: ENTITY_DETAILS_RESOURCE },
  ({ permissions }) => {
    const { entityId } = useParams();
    const { getEntityById } = useAccount();

    const [entityData, setEntityData] = useState({});

    const getEntityData = useCallback(async () => {
      try {
        const { payload } = await getEntityById(entityId);
        setEntityData(payload);
      } catch (error) {
        setEntityData({});
      }
    }, [entityId, setEntityData, getEntityById]);

    useEffect(() => {
      getEntityData();
    }, [getEntityData]);

    const name = R.defaultTo("Entity Not Found", entityData?.entity_name);

    return (
      <PageContent>
        <PageTitle>{name}</PageTitle>
        {permissions.hasPermission ? (
          <>
            <Panel>
              <EntityDetails data={entityData} onChange={getEntityData} />
            </Panel>
            <Panel>
              <AgentDetails data={entityData} onChange={getEntityData} />
            </Panel>
            <Panel>
              <KYBDocuments data={entityData} entityId={entityId} onChange={getEntityData} />
            </Panel>
          </>
        ) : (
          <PermissionsMessage />
        )}
      </PageContent>
    );
  }
);
