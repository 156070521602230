/**
 * Renders the localized human-readable name of the auto-payout rule.
 *
 * @param {string} ruleName
 * @returns {JSX.Element}
 */
function AutoPayoutRuleName({ ruleName }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  // Rule definitions are found here: https://github.com/lindenlab/ecom-auto-payout-rules#current-rules
  switch (ruleName) {
    case "ACCOUNT_MANUAL_REVIEW":
      return <>Account tagged for manual review.</>;
    case "PAYOUT_AMOUNT":
      return <>Payout amount &lt; $10,000.</>;
    case "FIRST_PAYOUT":
      return <>Lifetime payout amount &gt; $0.</>;
    case "MANUAL_REVIEW_PAYOUT_AMOUNT":
      return <>Payout amount does not exceed review threshold.</>;
    case "KYC_NAME_EQUAL_PM_NAME":
      return <>KYC name matches payment method name.</>;
    case "ACCTS_ASSOC_WITH_PM":
      return <>Payment method does not appear in &gt; one account.</>;
    case "PAYOUTS_ASSOC_WITH_PM":
      return <>Payment method associated with other payouts.</>;
    case "FIRST_PAYOUT_AMOUNT_THRESHOLD":
      return (
        <>
          First payout & payout amount &lt; integrator-defined threshold & KYC name matches payment
          method name.
        </>
      );
    case "NUM_ACCT_NUM_PAYOUT":
      return <>Payment method received &gt; one payout.</>;
    case "NUM_ACCT_NUM_PAYOUT_KYC_NAME":
      return <>Payment method associated with one account & received &gt; one payout.</>;
    default:
      return <>{ruleName}</>;
  }
}

export default AutoPayoutRuleName;
