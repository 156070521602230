import { MultiError, getErrorFromResponse } from "api/utils";
import { ButtonIconWrapper, ButtonSpinner, PrimaryButton, TextLinkButton } from "components/Button";
import * as R from "ramda";
import { useState } from "react";
import { STATUS, noop } from "utils";
import { useModal, ModalErrors, ModalFooter } from ".";

export default function useBasicActionModal() {
  const [ModalWrapper, openModal, closeModal] = useModal();

  const BasicActionModal = ({
    submitAction,
    onSubmitActionSuccess,
    testIdPrefix,
    actionText,
    actionIcon,
    ActionButtonType = PrimaryButton,
    titleText,
    children,
    ...props
  }) => {
    const [actionState, setActionState] = useState({ status: STATUS.PRE_INIT });

    const handleAction = async () => {
      try {
        setActionState(R.assoc("status", STATUS.INITIALIZED));
        const response = await submitAction();
        if (response.status === "Success") {
          onSubmitActionSuccess();
          closeModal();
        } else {
          setActionState(R.assoc("status", STATUS.ERROR));
          setActionState((state) => ({
            ...state,
            status: STATUS.ERROR,
            error: getErrorFromResponse(response),
          }));
        }
      } catch (err) {
        const error = err instanceof MultiError ? err.errors : err;
        setActionState(R.pipe(R.assoc("status", STATUS.ERROR), R.assoc("error", error)));
      }
    };

    const cancelButton =
      actionState.status === STATUS.INITIALIZED ? (
        <TextLinkButton disabled={true} onClick={noop}>
          Cancel
        </TextLinkButton>
      ) : (
        <TextLinkButton data-testid={`${testIdPrefix}cancel-button`} onClick={closeModal}>
          Cancel
        </TextLinkButton>
      );

    let actionButton = null;
    if (submitAction) {
      actionButton =
        actionState.status === STATUS.INITIALIZED ? (
          <ActionButtonType disabled={true} onClick={noop}>
            {actionText}
            <ButtonSpinner />
          </ActionButtonType>
        ) : (
          <ActionButtonType
            data-testid={`${testIdPrefix}submit-button`}
            type="submit"
            onClick={handleAction}
          >
            {actionText}
            {actionIcon && <ButtonIconWrapper>{actionIcon}</ButtonIconWrapper>}
          </ActionButtonType>
        );
    }

    const errorFooter =
      actionState.status === STATUS.ERROR ? <ModalErrors errors={actionState.error} /> : null;

    return (
      <ModalWrapper titleText={titleText} {...props}>
        {children}
        <ModalFooter gap={3}>
          {cancelButton}
          {actionButton}
        </ModalFooter>
        {errorFooter}
      </ModalWrapper>
    );
  };

  return [BasicActionModal, openModal, closeModal];
}
