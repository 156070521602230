import React from "react";
import useIntegratorToolsUsers from "./useIntegratorToolsUsers";
import { GridLoading, NoData } from "components/Grid";
import { PermissionsMessage } from "components/PermissionsMessage";
import AddIntegratorToolsUser from "./AddIntegratorToolsUser";
import UsersTable from "./UsersTable";

const IntegratorToolsUsers = ({ integrator }) => {
  const { data, loading, permissionsFailed, refetch } = useIntegratorToolsUsers(
    integrator.integratorId
  );

  if (loading) {
    return <GridLoading />;
  }

  if (permissionsFailed) {
    return <PermissionsMessage />;
  }

  if (!data?.integrator?.toolsUsers) {
    return <NoData />;
  }

  const handleDeactivateUser = (_user) => {
    refetch();
  };

  return (
    <>
      <AddIntegratorToolsUser integrator={integrator.integratorId} onAddUser={refetch} />
      <UsersTable users={data.integrator.toolsUsers} onDeactivateUser={handleDeactivateUser} />
    </>
  );
};

export default IntegratorToolsUsers;
