import React from "react";
import styled, { css } from "styled-components/macro";
import { withProp, ifProp, prop, theme } from "styled-tools";
import { title, debug } from "styles";

const min = ifProp(
  "min",
  css`
    width: 1%;
  `
);

const textAlign = css`
  ${withProp(["center", "rightAlign"], (center, rightAlign) => {
    if (rightAlign) return "text-align: right";
    if (center) return "text-align: center;";
    return "text-align: left";
  })};
`;

const bordered = css`
  ${ifProp(
    "bordered",
    css`
      & > tr > td,
      & > tr > th {
        border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
      }
    `
  )}
`;

const TablePure = ({ min, debug, ...rest }) => <table {...rest} />;
export const Table = styled(TablePure)`
  border-collapse: collapse;
  ${debug};
  & th,
  & td {
    ${debug};
  }
  width: ${prop("w", "100%")};
  ${min}
`;

export const Thead = styled.thead`
  font-size: 14px;
  ${debug};
  ${ifProp(
    "bordered",
    css`
      & > tr:last-child > td,
      & > tr:last-child > th {
        border-bottom: 2px solid ${theme("colors.dataTable.rowBorder")};
      }
    `
  )}
`;

export const Tbody = styled.tbody`
  ${debug};
  ${bordered};
`;

export const Tfoot = styled.tfoot`
  ${debug};
  ${ifProp(
    "bordered",
    css`
      & > tr:first-child > td,
      & > tr:first-child > th {
        border-top: 2px solid ${theme("colors.dataTable.rowBorder")};
      }
      & > tr:last-child > td,
      & > tr:last-child > th {
        border-bottom: 2px solid ${theme("colors.dataTable.rowBorder")};
      }
    `
  )}
`;

export const Tr = styled.tr`
  ${debug};
  ${ifProp(
    "pull",
    css`
      & > *:first-child {
        padding-left: 0;
      }
      & > *:last-child {
        padding-right: 0;
      }
    `
  )}
`;

const cell = css`
  ${textAlign};
  ${debug};
  ${ifProp(
    "title",
    css`
      ${title};
      border-bottom: 0px solid ${theme("colors.dataTable.border")};
    `,
    ""
  )};
  padding: 6px 8px;
  white-space: nowrap;
  width: ${prop("w", "auto")};
  ${min}
  height: 32px;
`;

const LinkCell = styled.div`
  display: inline-flex;
  cursor: pointer;
  color: ${theme("colors.link.base")};
`;

const ThPure = ({ center, rightAlign, title, min, ...rest }) => <th {...rest} />;
export const Th = styled(ThPure)`
  ${cell}
  font-weight: normal;
`;

const TdPure = ({ center, rightAlign, title, min, ...rest }) => <td {...rest} />;

const StyledTd = styled(TdPure)`
  ${cell}
`;

// want to use LinkCell for hyperlinks so the entire row isn't clickable, only the value
// lots of misclicks happen from end users
export const Td = ({ children, ...props }) => {
  return props.onClick ? (
    <StyledTd>
      <LinkCell {...props}>{children}</LinkCell>
    </StyledTd>
  ) : (
    <StyledTd {...props}>{children}</StyledTd>
  );
};
