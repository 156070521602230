import { Grid, GridItem, Heading } from "@chakra-ui/react";
import { CopyableText } from "components/Copyable";
import { DetailText } from "components/Text";
import IntegratorLogo from "components/IntegratorLogo/IntegratorLogo";

//
// expects account to have username, accountId, integrator
//
export default function AccountHeaderSummary({ account }) {
  return (
    <Grid templateColumns="min-content auto min-content" gap={2}>
      <GridItem>
        <IntegratorLogo integratorName={account.integrator} size="36px" fontSize="1.4em" />
      </GridItem>
      <GridItem>
        <Heading as="h3" size="xs" m={0}>
          {account.username}
        </Heading>
        <CopyableText text={account.accountId} buttonSize={"xs"}>
          <DetailText>{account.accountId}</DetailText>
        </CopyableText>
      </GridItem>
      <GridItem></GridItem>
    </Grid>
  );
}
