import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import { READ_PAYMENT_METHODS } from "context/permissions";
import { PaymentMethodDetails } from "./PaymentMethodDetails";
import { PaymentMethodList } from "./PaymentMethodList";
import { PermissionsMessage } from "components/PermissionsMessage";
import { useTitle } from "context/titleContext";

export const PaymentMethods = () => {
  const hasPermissions = useAgentHasPermissions(READ_PAYMENT_METHODS);
  const navigate = useNavigate();
  const { accountId } = useParams();

  const navigateToPayments = (paymentMethodId) => {
    navigate(`/account/${accountId}/payment-methods/${paymentMethodId}`);
  };
  useTitle({ sub1: "Payment Methods" });
  return !hasPermissions ? (
    <PermissionsMessage />
  ) : (
    <Routes>
      <Route>
        <Route index element={<PaymentMethodList onSelectPayments={navigateToPayments} />}></Route>
        <Route path=":paymentMethodId?" element={<PaymentMethodDetails />} />
      </Route>
    </Routes>
  );
};
