import { Box, Tag, Wrap } from "@chakra-ui/react";
import { exists } from "utils";

function Tags({ tags }) {
  if (!exists(tags)) {
    return null;
  }
  return (
    <Box>
      <Wrap>
        {tags.map((tag) => (
          <Tag as="li" key={tag} variant="solid">
            {tag}
          </Tag>
        ))}
      </Wrap>
    </Box>
  );
}

export default Tags;
