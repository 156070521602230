import React from "react";
import "styled-components/macro";
import { useParams } from "react-router-dom";
import { useAgent } from "context/agentContext";
import { useQuery } from "react-query";
import { getClient } from "api/account";
import { ClientScopes } from "./ClientScopes";
import { ClientDetails } from "./ClientDetails";
import { SectionSpinner } from "components/Spinner";
import { SectionError } from "components/Error";

export const Client = () => {
  const { agent } = useAgent();
  const { clientId } = useParams();

  const { isLoading, isError, error, data } = useQuery(["getClient", clientId], () =>
    getClient(clientId, agent)
  );

  if (isError) {
    return <SectionError>{error.message}</SectionError>;
  }
  if (isLoading) {
    return <SectionSpinner />;
  }
  return (
    <div data-testid="client">
      <ClientDetails data={data} />
      <ClientScopes />
    </div>
  );
};
