/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { useFormikContext } from "formik";
import { patchSettlement } from "api/settlement";
import { ctxWithIntegrator } from "utils/auth";
import { useAccount } from "context/accountContext";
import { Button, Text } from "@chakra-ui/react";

function ReturnForReReviewButton({ settlementId, integrator, onSettlementApproved }) {
  const { agentContext } = useAccount();
  const { setStatus, setSubmitting } = useFormikContext();

  const handleReReviewClick = async () => {
    setSubmitting(true);

    const result = await patchSettlement(
      settlementId,
      { action: "return" },
      ctxWithIntegrator(integrator, agentContext)
    );

    if (result.status !== 200) {
      setStatus({ error: result.errors.join(" ") });
      setSubmitting(false);
      return;
    }

    onSettlementApproved(settlementId, integrator);
  };

  return (
    <Text>
      <Button type="button" colorScheme="gray" variant="outline" onClick={handleReReviewClick}>
        Return for Re-Review
      </Button>
    </Text>
  );
}

export default ReturnForReReviewButton;
