import React, { useCallback } from "react";
import * as R from "ramda";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { fetchAccountWatchlistData } from "api/fraud";
import { getWatchlist } from "api/toolsWeb";
import { Panel } from "components/Panel";
import { useAgent } from "context/agentContext";
import { useSorting } from "utils/useSorting";
import { useSettings } from "utils/useSettings";
import { WatchlistResults } from "./WatchlistResults";
import { Select } from "components/Forms";

const wlStatusOptions = [
  { value: "ALL", label: "All" },
  { value: "MANUAL_REVIEW", label: "Manual Review" },
  { value: "FALSE_POSITIVE", label: "False Positive" },
  { value: "TRUE_POSITIVE", label: "True Positive" },
  { value: "PENDING_DOCUMENTATION", label: "Pending Documentation" },
];

const defaultStatusOption = wlStatusOptions[1];

const selectDefaultValue = (value, list) => R.filter(R.propEq(value, "value"), list);

export const StatusQuery = ({ setValue, defaultValue }) => {
  const selectDefault = selectDefaultValue(defaultValue, wlStatusOptions);

  return (
    <div
      data-testid="watchlist-status-select"
      css={`
        min-width: 150px;
        width: 200px;
        max-width: 250px;
      `}
    >
      <Select
        required
        label="Watchlist Status"
        name="watchlist-status-select"
        id="watchlist-status-select"
        options={wlStatusOptions}
        defaultValue={selectDefault}
        onBlur={() => {}}
        onChange={(name, val) => setValue(val)}
      />
    </div>
  );
};

const SanctionListQuery = ({ setValue, sanctionList, defaultValue }) => {
  const selectDefault = selectDefaultValue(defaultValue, sanctionList);
  return (
    <div
      data-testid="sanction-list-select"
      css={`
        min-width: 150px;
        width: 200px;
        max-width: 250px;
      `}
    >
      <Select
        label="Sanction List"
        name="sanction-list-select"
        id="sanction-list-select"
        options={sanctionList}
        defaultValue={selectDefault}
        value={selectDefault}
        onChange={(name, val) => setValue(val)}
      />
    </div>
  );
};

const IntegratorQuery = ({ setValue, integratorList, defaultValue }) => {
  const selectDefault = selectDefaultValue(defaultValue, integratorList);
  return (
    <div
      data-testid="integrator-list-select"
      css={`
        min-width: 150px;
        width: 200px;
        max-width: 250px;
      `}
    >
      <Select
        required
        label="Publisher"
        name="integrator-list-select"
        id="integrator-list-select"
        options={integratorList}
        defaultValue={selectDefault}
        value={selectDefault}
        onBlur={() => {}}
        onChange={(name, val) => setValue(val)}
      />
    </div>
  );
};

const toOptions = R.pipe(
  R.sortBy(R.identity),
  R.map((i) => (R.equals("ALL", R.toUpper(i)) ? { value: "", label: i } : { value: i, label: i }))
);

export const WatchlistAccounts = () => {
  const { agent } = useAgent();
  const [results, setResults] = React.useState([]);
  const { sorter, sortingProps } = useSorting("updated");
  const sortedResults = React.useMemo(() => sorter(results), [results, sorter]);
  const fetchResults = useCallback(
    async (queryParameters) => {
      try {
        const resp = await fetchAccountWatchlistData(queryParameters, agent);
        setResults(resp.payload.records);
      } catch (e) {
        console.log("failed to fetch account watchlist data: " + e.message); // eslint-disable-line no-console
      }
    },
    [agent, setResults]
  );

  return (
    <Panel>
      <WatchlistFilters fetchResults={fetchResults} />
      <WatchlistResults results={sortedResults} {...sortingProps} />
    </Panel>
  );
};

const WatchlistFilters = ({ fetchResults }) => {
  const { getSettings, Keys } = useSettings();
  const [sanctionListOptions, setSanctionListOptions] = React.useState([]);
  const [sanction_list, setSanctionList] = React.useState("");
  const [integratorListOptions, setIntegratorListOptions] = React.useState([]);
  const [integrator, setIntegrator] = React.useState("");
  const [status, setStatus] = React.useState(defaultStatusOption.value);

  const getWatchlists = useCallback(async () => {
    try {
      const watchLists = await getWatchlist();
      const defineSanctionListOptions = toOptions(watchLists?.sanction_lists);
      setSanctionListOptions(defineSanctionListOptions);
    } catch {
      console.log("There was an issue loading watchlist."); // eslint-disable-line no-console
    }
  }, [setSanctionListOptions]);

  const getIntegrators = useCallback(async () => {
    try {
      const integrators = await getSettings(Keys.GLOBAL_INTEGRATORS);
      integrators.push("All");
      const defineIntegratorOptions = toOptions(integrators);
      setIntegratorListOptions(defineIntegratorOptions);
    } catch {
      console.log("There was an issue loading integrators."); // eslint-disable-line no-console
    }
  }, [getSettings, Keys, setIntegratorListOptions]);

  React.useEffect(() => {
    getWatchlists();
    getIntegrators();
  }, [getIntegrators, getWatchlists]);

  React.useEffect(() => {
    const queryParameters = {
      status,
      integrator,
      sanction_list,
      offset: 0,
      limit: 100,
    };
    fetchResults(queryParameters);
  }, [integrator, sanction_list, status, fetchResults]);

  return (
    <div
      css={`
        display: flex;
        gap: 10px;
      `}
    >
      <StatusQuery setValue={setStatus} defaultValue={status} />
      <SanctionListQuery
        setValue={setSanctionList}
        sanctionList={sanctionListOptions}
        defaultValue={sanction_list}
      />
      <IntegratorQuery
        setValue={setIntegrator}
        integratorList={integratorListOptions}
        defaultValue={integrator}
      />
    </div>
  );
};
