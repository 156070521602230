import React from "react";
import { Select, Button } from "components/Forms";
import { formatDateTimeFilename } from "utils";

const showFile = (blob, name) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], { type: "application/pdf" });

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;
  link.download = name;
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};

export const Download = ({ query, options, setEntityId }) => {
  const [error, setError] = React.useState();
  const handleDownload = async () => {
    try {
      const resp2 = await query();
      const filename = `watchlist-query-${formatDateTimeFilename(new Date())}`;
      showFile(resp2, filename);
    } catch (e) {
      setError(e.toString());
    }
  };

  return (
    <div>
      <Select
        defaultValue={options[0]}
        label={"Select Entity"}
        onChange={(name, value) => setEntityId(value)}
        options={options}
      />
      <Button onClick={handleDownload}>Download</Button>
      <div>{error}</div>
    </div>
  );
};
