import { useNavigate } from "react-router-dom";
import { useAccount } from "context/accountContext";
import { useAgent } from "context/agentContext";
import { ActionMenu } from "components/ActionMenu";
import { useSendKYCEmailModal } from "./SendKYCEmailModal";
import { useEditKYCDataModal } from "./EditKYCDataModal";
import { EditIcon } from "components/Icons";
import { WRITE_KYC_PIIS } from "context/permissions";

export const KYCActionMenu = ({ onEditSuccess, currentApplication }) => {
  const navigate = useNavigate();
  const { agent } = useAgent();

  const updateKycMenuItem = {
    icon: <EditIcon size="1rem" />,
    label: "Update KYC...",
    onSelect: () => navigate(`/account/${account.accountId}/kyc/update`),
    "data-displayitem": agent.hasPermissions([WRITE_KYC_PIIS]),
    disabled: false,
    comp: null,
  };

  const account = useAccount();
  const [SendKYCEmailModal, _button, getSendEmailMenuItem] = useSendKYCEmailModal();
  const [EditKYCDataModal, editKYCDataMenuItem] = useEditKYCDataModal();

  const menuItems = [
    { key: "update_kyc", item: updateKycMenuItem },
    {
      key: "edit_kyc_data",
      item: { key: "edit_kyc_data", ...editKYCDataMenuItem },
      comp: (
        <EditKYCDataModal
          key="edit_kyc_data"
          currentApplication={currentApplication}
          onSuccess={onEditSuccess}
        />
      ),
    },
    {
      key: "send_email",
      item: getSendEmailMenuItem(),
      comp: <SendKYCEmailModal accountId={account.accountId} key="send_email" />,
    },
  ];

  const menuSettings = ["update_kyc", "edit_kyc_data", "send_email"];
  return <ActionMenu menuItems={menuItems} menuSettings={menuSettings} />;
};
