import React from "react";
import * as R from "ramda";
import * as Yup from "yup";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { prop } from "styles";
import { useAgent } from "context/agentContext";
import * as permissions from "context/permissions";
import { noop } from "utils";
import { toRegionOptions, regions } from "utils/regions";
import { useFormModal } from "components/Modal";
import { SelectField, TextField } from "components/Forms";
import { OutlineButton } from "components/Button";
import { PermissionsIcon } from "components/Icons";

const FormRowGrid = styled.div`
  display: grid;
  grid-template-columns: ${prop(
    "cols",
    css`
      ${({ children }) => `repeat(${children.length}, 1fr)`}
    `
  )};
  grid-gap: 8px;
`;

export const useEditEntityDetailsModal = () => {
  const testIdPrefix = `edit-entity-details-`;
  const { agent } = useAgent();
  const [FormModal, openModal] = useFormModal();

  const EditModal = ({ onSuccess = noop, entityInfo, onSubmit }) => {
    if (!agent.hasPermissions([permissions.WRITE_KYB ])) {
      return <div data-testid={`${testIdPrefix}modal-no-permissions`}></div>;
    }

    const entityTypes = [
      {value: "corporation", label: "Corporation" },
      {value: "non_profit_org", label: "Non Profit Organization" },
    ];

    const emptyCheck = R.defaultTo('');

    const initialValues =  {
          entity_name: emptyCheck(entityInfo?.entity_name),
          entity_type: emptyCheck(entityInfo?.entity_type),
          tax_id: emptyCheck(entityInfo?.tax_id),
          naics_numbers: emptyCheck(entityInfo?.naics_numbers),
          duns_numbers: emptyCheck(entityInfo?.duns_numbers),
          dba: emptyCheck(entityInfo?.dba),
          street: emptyCheck(entityInfo?.street),
          city: emptyCheck(entityInfo?.city),
          state: emptyCheck(entityInfo?.state),
          country_iso: emptyCheck(entityInfo?.country_iso),
          zip: emptyCheck(entityInfo?.zip),
      };

    const actionButtonText = 'Submit';

    return (
      <FormModal
        actionIcon={<PermissionsIcon size="1rem" />}
        actionText={`${actionButtonText}`}
        onSubmitActionSuccess={onSuccess}
        submitAction={(v) => onSubmit(v)}
        testIdPrefix={testIdPrefix}
        titleText={`Edit Entity Details`}
        formProps={{
          initialValues: initialValues,
          validationSchema: () =>
          Yup.object().shape({
            entity_name: Yup.string().required(),
            entity_type: Yup.string().required(),
          }),
        }}
        disableTillEdit
      >
        <div
          css={`
            min-height: 30vh;
            height: 100%;
            display: flex;
            flex-direction: column;
          `}
        >
          <FormRowGrid>
            <TextField
              data-testid={"entity-name-key-field"}
              name="entity_name"
              label="Entity Name"
              required
            />
            <TextField
              data-testid={"dba-key-field"}
              name="dba"
              label="DBA"
              required
            />
          </FormRowGrid>
          <FormRowGrid>
            <SelectField
              data-testid="entity-type-value-field"
              name="entity_type"
              label="Entity Type"
              required
              options={entityTypes}
            />
            <TextField
              data-testid={"entity-tin-key-field"}
              name="tax_id"
              label="TIN"
            />
          </FormRowGrid>
          <FormRowGrid>
            <TextField
              data-testid={"entity-nacis-key-field"}
              name="naics_numbers"
              label="NAICS"
            />
            <TextField
              data-testid={"entity-duns-key-field"}
              name="duns_numbers"
              label="DUNS"
            />
          </FormRowGrid>
          <FormRowGrid>
            <TextField name="street" label="Street" />
          </FormRowGrid>
          <FormRowGrid>
            <TextField name="city" label="City" />
            <TextField name="state" label="State" />
          </FormRowGrid>
          <FormRowGrid>
            <SelectField
              name="country_iso"
              label="Country"
              required
              options={toRegionOptions(regions)}
              maxHeightEntries={3}
            />
            <TextField name="zip" label="Postal Code" />
          </FormRowGrid>
          </div>
      </FormModal>
    );
  };

  const EditModalButton = ({ ...props }) => {
    if (!agent.hasPermissions([permissions.WRITE_KYB])) {
        return null;
    }
    return (
      <OutlineButton data-testid={`${testIdPrefix}button`} {...props} onClick={openModal}>
        Edit
      </OutlineButton>
    );
  };
  return [EditModal, EditModalButton];
};
