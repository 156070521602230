import { Text } from "@chakra-ui/react";
import { DetailText } from "components/Text";
import AccountCard from "components/Transaction/AccountCard";
import {
  Section,
  TransactionColumnLeft,
  TransactionColumnRight,
  TransactionLayout,
  TransactionMain,
} from "components/Transaction/Layout";
import PaymentMethods from "./PaymentMethods";
import Recipients from "./Recipients";
import LineItems from "./LineItems";

function PurchaseDetail({ purchase }) {
  return (
    <TransactionLayout>
      <TransactionColumnLeft>
        {/* TODO: Add tags https://tiliajira.atlassian.net/browse/DEV-3135 */}
        {/* <Tags /> */}
        <Section title="Account Detail">
          <Text mt="0">
            <DetailText>{purchase.integrator}</DetailText>
          </Text>
          <AccountCard accountId={purchase.account.accountId} />
        </Section>
        <Section title="Payment methods">
          <PaymentMethods transaction={purchase} />
        </Section>
        <Section title="Recipients">
          <Recipients parties={purchase.parties} />
        </Section>
      </TransactionColumnLeft>
      <TransactionMain>
        <Section title="Summary">
          <LineItems transaction={purchase} />
        </Section>
        {/* TODO <Section title="Notes">
            <Notes />
          </Section> */}
      </TransactionMain>
      <TransactionColumnRight>
        {/* TODO: <Section title="Activity Log">
            <ActivityLog />
          </Section> */}
      </TransactionColumnRight>
    </TransactionLayout>
  );
}

export default PurchaseDetail;
