import { gql } from "@apollo/client";
import { useQuery } from "context/graphqlContext";
import { READ_ACCOUNTS } from "context/permissions";

const getAccountBlocks = gql`
  query GetAccountBlocks($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      blocks {
        id
        issuer
        issuerAccount {
          accountId
          username
        }
        capability
        reason
        notes {
          body
        }
      }
    }
  }
`;

const useAccountBlocks = (accountId) => {
  const { data, loading, permissionsFailed, refetch } = useQuery(getAccountBlocks, {
    permissions: [READ_ACCOUNTS],
    errorPolicy: "all",
    variables: {
      accountId,
    },
  });

  return [
    loading || permissionsFailed || !data?.account?.blocks ? [] : data.account.blocks,
    refetch,
  ];
};

export default useAccountBlocks;
