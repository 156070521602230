import React from "react";
import { ActionMenu } from "components/ActionMenu";
import { DeleteIcon } from "components/Icons";
import useAgentCanDeactivateIntegratorToolsUser from "./useAgentCanDeactivateIntegratorToolsUser";
import useDeactivateIntegratorToolsUserModal from "./useDeactivateIntegratorToolsUserModal";

const UsersTableActionMenu = ({ user, onDeactivateUser }) => {
  const canDeactivateUser = useAgentCanDeactivateIntegratorToolsUser();
  const [DeactivateIntegratorToolsUserModal, openModal] = useDeactivateIntegratorToolsUserModal();

  const menuItems = [
    {
      key: "deactivate_user",
      item: {
        icon: <DeleteIcon size="1rem" />,
        disabled: !canDeactivateUser,
        label: `Deactivate User`,
        onSelect: openModal,
        "data-displayitem": canDeactivateUser,
      },
      comp: (
        <DeactivateIntegratorToolsUserModal
          key="deactivate_user_component"
          user={user}
          onDeactivateUser={onDeactivateUser}
        />
      ),
    },
  ];

  return <ActionMenu menuItems={menuItems} menuSettings={["deactivate_user"]} />;
};

export default UsersTableActionMenu;
