/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { gql, useQuery } from "@apollo/client";

const getSettlement = gql`
  query GetSettlement($settlementId: ID!, $integrator: NonEmptyString!) {
    settlement(settlementId: $settlementId, integrator: $integrator) {
      settlementId
      integrator
      periodStartDatetime
      periodEndDatetime
      statusHistory {
        startDatetime
        settlementStatusCode
        userAccount {
          accountId
          username
          firstName
          lastName
        }
      }
      approvals {
        approvalDatetime
        userAccount {
          accountId
          username
          firstName
          lastName
        }
        approvalType
      }
      details {
        settlementDetailType
        source
        amount
        currencyCode
      }
      adjustments {
        settlementAdjustmentId
        adjustmentDescription
        amount
        currencyCode
        userAccount {
          accountId
          username
          firstName
          lastName
        }
      }
      settlementStatusCode
      netSettlement
      currencyCode
      balanceTransfer {
        id
      }
      payout {
        id
      }
      summary {
        revenueTotal
        deductionsTotal
        adjustmentsTotal
        subtotals {
          settlementDetailType
          source
          amount
          currencyCode
        }
      }
    }
  }
`;

const useSettlement = (settlementIdentifier) => {
  return useQuery(getSettlement, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    skip: !settlementIdentifier,
    variables: {
      settlementId: settlementIdentifier?.settlementId,
      integrator: settlementIdentifier?.integrator,
    },
  });
};

export default useSettlement;
