import React, { useState } from "react";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useManualReviewModal } from "./ManualReviewModal";
import { PanelTitle } from "components/Panel";
import { OutlineButton } from "components/Button";
import { useNavigate } from "react-router-dom";
import { queryWatchlist } from "./utils";

const Status = styled.span`
  margin: 0 8px;
`;

export const QueryAccountButton = ({ kycPii }) => {
  const navigate = useNavigate();
  if (!kycPii.first) {
    return null;
  }

  return (
    <OutlineButton
      data-testid="query-account-button"
      onClick={() => queryWatchlist(navigate, kycPii)}
    >
      Query Account KYC Data
    </OutlineButton>
  );
};

export const AccountWatchlist = ({ ...props }) => {
  let { accountWatchlist, kycPii } = props;
  accountWatchlist = accountWatchlist || {};
  const [ManualReviewModal, ManualReviewButton] = useManualReviewModal();
  const [manualReviewStatus, setManualReviewStatus] = useState(accountWatchlist.status);

  return (
    <PanelTitle css="width:100%">
      <span>
        <span>Watchlist Status: </span>
        <Status>{manualReviewStatus}</Status>
      </span>
      <span
        css={`
          display: block;
          float: right;
        `}
      >
        <span css="display: flex">
          <QueryAccountButton kycPii={kycPii} />
          {accountWatchlist.status !== "NO_CHECKS" && <ManualReviewButton />}
        </span>
        <ManualReviewModal
          accountWatchlist={accountWatchlist}
          setManualReviewStatus={setManualReviewStatus}
          {...props}
        />
      </span>
    </PanelTitle>
  );
};
