// NOTE: The names of most exported objects exactly match the equivalent constant strings in the
// Go services code -- DO NOT RENAME THEM!
//
// This helps to ensure this file, and any usages of these constants in our own code, appear in
// full-code searches for those strings, so that Tools isn't excluded from critical updates that
// modify the underlying functionality.

/**
 * Deposit status codes.
 * @enum {string}
 */
export const InvoicingApiDepositStatusCode = Object.freeze({
  DepositNew: "NEW",
  DepositInitiated: "INITIATED",
  DepositSuccess: "SUCCESS",
  DepositFailed: "FAILED",
  DepositCanceled: "CANCELED",
  DepositUserReversed: "USER-REVERSED",
  DepositSystemCanceled: "SYSTEM-CANCELED",
});

/**
 * Deposit status codes representing a "pending" status.
 * @type {Set<InvoicingApiDepositStatusCode>}
 */
export const InvoicingApiDepositPendingStatusCodes = new Set([
  InvoicingApiDepositStatusCode.DepositNew,
  InvoicingApiDepositStatusCode.DepositInitiated,
]);

/**
 * Deposit status codes representing a "success" status.
 * @type {Set<InvoicingApiDepositStatusCode>}
 */
export const InvoicingApiDepositSuccessStatusCodes = new Set([
  InvoicingApiDepositStatusCode.DepositSuccess,
]);

/**
 * Deposit status codes representing a "failed" status.
 * @type {Set<InvoicingApiDepositStatusCode>}
 */
export const InvoicingApiDepositFailedStatusCodes = new Set([
  InvoicingApiDepositStatusCode.DepositFailed,
]);

/**
 * Deposit status codes representing a "canceled" status.
 * @type {Set<InvoicingApiDepositStatusCode>}
 */
export const InvoicingApiDepositCanceledStatusCodes = new Set([
  InvoicingApiDepositStatusCode.DepositCanceled,
  InvoicingApiDepositStatusCode.DepositUserReversed,
  InvoicingApiDepositStatusCode.DepositSystemCanceled,
]);
