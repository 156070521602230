import {
  EscrowPurchaseStatusCode,
  PurchaseStatusCode,
  RefundSummaryStatusCode,
  TokenPurchaseStatusCode,
  TransactionType,
} from "@tilia-tools/core/transaction";

/**
 * Returns true if the transaction's type and current status allow it to be refunded.
 *
 * @param {Transaction} transaction
 * @returns {boolean}
 * @throws {Error} if the transaction does not include a refundSummary.status value.
 */
function transactionIsRefundable(transaction) {
  let typeIsRefundable;
  switch (transaction.type) {
    case TransactionType.PURCHASE:
      typeIsRefundable = transaction.purchaseStatusCode === PurchaseStatusCode.SUCCESS;
      break;

    case TransactionType.ESCROW_PURCHASE:
      typeIsRefundable = transaction.escrowPurchaseStatusCode === EscrowPurchaseStatusCode.SUCCESS;
      break;

    case TransactionType.TOKEN_PURCHASE:
      typeIsRefundable = transaction.tokenPurchaseStatusCode === TokenPurchaseStatusCode.SUCCESS;
      break;

    default:
      typeIsRefundable = false;
  }

  if (!typeIsRefundable) {
    return false;
  }

  if (!transaction.refundSummary?.status) {
    throw new Error("transaction is missing refundSummary.status");
  }
  return transaction.refundSummary.status !== RefundSummaryStatusCode.FULL;
}

export default transactionIsRefundable;
