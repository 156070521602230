export const strings = {
  actions: "Actions",
  view: "View",
  payout: "Payout",
  payouts: "Payouts",
  approve_payout: "Approve Payout",
  on_hold_payout: "On Hold Payout",
  reject_payout: "Reject Payout",
  publisher: "Publisher",
  request_time: "Request Time",
  status: "Status",
  account: "Account",
  amount: "Amount",
  no_results: "No Results",
  all: "All",
  confirm: "Confirm",
  on_hold: "On Hold",
  provider_on_hold: "Provider On Hold",
  approve: "Approve",
  reject: "Reject",
  pending_review: "Pending Review",
  approved: "Approved",
  rejected: "Rejected",
  user_canceled: "User Canceled",
  failed: "Failed",
  on_hold_confirmation: "You have selected {0} payment{1} to set to on hold",
  reject_confirmation: "You have selected {0} payment{1} to reject",
  approve_confirmation: "You have selected {0} payment{1} to approve",
  view_message: "This payout's status was updated to {0} on {1}",
  error_title: "Something went wrong",
  cancel: "Cancel",
  payout_method: "Payout Method",
};
