import { MakeGoodStatusCode } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the make good status code.
 *
 * @param {MakeGoodStatusCode} makeGoodStatusCode
 * @returns {JSX.Element}
 */
function MakeGoodStatusCodeName({ makeGoodStatusCode }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (makeGoodStatusCode) {
    case MakeGoodStatusCode.OPEN:
      return <>Open</>;
    case MakeGoodStatusCode.PROCESSING:
      return <>Processing</>;
    case MakeGoodStatusCode.SUCCESS:
      return <>Success</>;
    case MakeGoodStatusCode.FAILED:
      return <>Failed</>;
    case MakeGoodStatusCode.SYSTEM_CANCELED:
      return <>System Canceled</>;
    default:
      return <>{makeGoodStatusCode}</>;
  }
}

export default MakeGoodStatusCodeName;
