import { useSearchParams } from "react-router-dom";

const useConnectionPaginationOptions = (defaultSort, defaultPageSize = 10) => {
  const [searchParams] = useSearchParams();

  let first = searchParams.has("first") ? parseInt(searchParams.get("first"), 10) : undefined;
  let last = searchParams.has("last") ? parseInt(searchParams.get("last"), 10) : undefined;
  if (!first && !last) {
    first = defaultPageSize;
  }

  return {
    sort: searchParams.has("sort") ? searchParams.get("sort") : defaultSort,
    first,
    after: searchParams.has("after") ? searchParams.get("after") : undefined,
    last,
    before: searchParams.has("before") ? searchParams.get("before") : undefined,
  };
};

export default useConnectionPaginationOptions;
