import React from "react";
import * as R from "ramda";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { SubNavRow, SubNavLinkItem } from "containers/Nav/SubNav";

export const WatchlistSubNav = () => {
  const config = [
    { to: "", name: "Accounts" },
    { to: "query", name: "Query" },
  ];
  return (
    <SubNavRow>
      <div>
        {config.map((c) => (
          <SubNavLinkItem key={c.to} to={c.to} routeSet={R.pluck("to", config)} compat={true}>
            {c.name}
          </SubNavLinkItem>
        ))}
      </div>
    </SubNavRow>
  );
};
