import React from "react";
import * as R from "ramda";
import { exists } from "utils";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { PrimaryButton } from "components/Button";
import { DeleteIcon } from "components/Icons";
import { Panel } from "components/Panel";
import * as Perms from "context/permissions";
import { useAgent } from "context/agentContext";
import { useBasicActionModal } from "components/Modal";
import { useAccount } from "context/accountContext";

const Section = styled.div`
  margin: 1em 0;
`;

const keyDisplayMap = {
  blockAccount: "Block Account:",
  anonymizePersonas: "Anonymize Personas:",
  deleteEmail: "Delete Emails:",
  deleteSecurityQuestions: "Delete PII Security Questions",
  deletePIIEmail: "Delete PII Emails",
};

const useGDPRDeleteModal = () => {
  const [BAModal, openModal] = useBasicActionModal();

  const GDPRDeleteModal = () => {
    const account = useAccount();

    const handleSubmit = async () => {
      const results = await account.gdprDelete();

      const errorsByAction = R.reduce(
        (acc, { value }) => {
          if (!value) {
            return acc;
          }
          const { name, result } = value;
          if (Array.isArray(result)) {
            //pluck out the nested errors
            const allNestedErrors = R.flatten(result.map((r) => r.value?.errors));
            return exists(allNestedErrors) ? { ...acc, [name]: allNestedErrors } : acc;
          } else {
            return exists(result.errors) ? { ...acc, [name]: result.errors } : acc;
          }
        },
        [],
        results.results
      );

      const displayErrors = R.pipe(
        R.keys,
        R.map((key) => [keyDisplayMap[key] || key, errorsByAction[key]]),
        R.flatten
      )(errorsByAction);

      if (exists(displayErrors)) {
        return { status: "Failure", error: displayErrors };
      }
      return { status: "Success" };
    };

    return (
      <BAModal
        actionIcon={<DeleteIcon />}
        actionText="Delete"
        onSubmitActionSuccess={() => account.getAccountDetails(account.accountId)}
        submitAction={handleSubmit}
        testIdPrefix="gdpr-delete-"
        titleText="GDPR Delete"
      >
        <Section>Are you sure you want to GDPR Delete this account?</Section>
        <Section>
          This action is irreversible, and much of the account's information will be deleted or
          anonymized. This utility performs the following tasks:
          <Section
            css={`
              display: grid;
              grid-template-columns: 1fr 1fr;
            `}
          >
            <div>Block Account</div>
            <div>Scramble Username</div>
            <div>Anonymize Personas</div>
            <div>Delete Emails</div>
            <div>Delete PII Email Addresses</div>
            <div>Delete PII Security Questions</div>
          </Section>
        </Section>
      </BAModal>
    );
  };

  const GDPRDeleteButton = (props) => {
    return (
      <PrimaryButton data-testid="gdpr-delete-button" {...props} onClick={openModal}>
        GDPR Delete
      </PrimaryButton>
    );
  };
  return [GDPRDeleteModal, GDPRDeleteButton];
};

export const GDPRDelete = () => {
  const [Modal, Button] = useGDPRDeleteModal();
  const { agent } = useAgent();
  if (
    !agent.hasPermissions(
      [
        Perms.CHANGE_ACCOUNTS,
        Perms.CHANGE_ANY_PERSONAS,
        Perms.DELETE_EMAILS,
        Perms.DELETE_SQAS,
        Perms.GDPR_DELETE_EMAILS,
        Perms.READ_ANY_PERSONAS,
      ],
      Perms.GDPR_RESOURCE
    )
  ) {
    return <div data-testid="gdpr-delete-modal-no-permissions" />;
  }
  return (
    <Panel>
      <Button />
      <Modal />
    </Panel>
  );
};
