import { createContext, useCallback, useContext } from "react";
import useFilterConfig from "./useFilterConfig";
import useFilterParams from "./useFilterParams";
import useUpdateFilterSearchParams from "./useUpdateFilterSearchParams";

/**
 * @typedef {Object} FilterContextValue
 * @property {FilterConfigField[]} filterConfig Array of fields configurations for this filter
 * @property {Object.<string, *>} filterParams An object containing the filter parameters, with values converted to the correct type.
 * @property {Object.<string, *>} gqlFilterParams An object containing the filter parameters, formatted for use in a GraphQL query.
 * @property {function} handleChangeFilter A function for setting the value of a filter parameter.
 * @property {function} handleAddFilter A function for adding the value of a filter parameter.
 * @property {function} handleRemoveFilter A function for removing the value of a filter parameter.
 */

/** @type {React.Context<FilterContextValue>} */
const FilterContext = createContext(undefined);

export function useFilter() {
  return useContext(FilterContext);
}

function FilterContextProvider({ config, children }) {
  const filterConfig = useFilterConfig(config);
  const { filterParams, gqlFilterParams } = useFilterParams(filterConfig);

  const updateFilterSearchParams = useUpdateFilterSearchParams();

  const handleChangeFilter = useCallback(
    (field, value) => {
      updateFilterSearchParams(field, value);
    },
    [updateFilterSearchParams]
  );

  const handleAddFilter = useCallback(
    (field, value) => {
      if (filterParams[field] === undefined) {
        updateFilterSearchParams(field, value);
      } else if (!filterParams[field].includes(value)) {
        const newValues = [...filterParams[field], value];
        updateFilterSearchParams(field, newValues.join(","));
      }
    },
    [filterParams, updateFilterSearchParams]
  );

  const handleRemoveFilter = useCallback(
    (field, value) => {
      if (filterParams[field] === undefined) {
        // nothing to do
      } else if (filterParams[field].includes(value)) {
        const newValues = filterParams[field].filter((s) => s !== value);
        updateFilterSearchParams(field, newValues.join(","));
      }
    },
    [filterParams, updateFilterSearchParams]
  );

  const value = {
    filterConfig,
    filterParams,
    gqlFilterParams,
    handleChangeFilter,
    handleAddFilter,
    handleRemoveFilter,
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
}

export default FilterContextProvider;
