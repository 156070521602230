import { PageContent } from "components/Page";
import * as R from "ramda";
import useConnectionPaginationOptions from "components/Pager/useConnectionPaginationOptions";
// import { useFilter } from "components/Filter";
import { Table, TableContainer, Td, Th, Thead, Tr } from "@chakra-ui/react";
import LoadingTbody from "components/Table/LoadingTbody";
import { useIntegrator } from "context/integratorContext";
import { Money } from "components/Money";
import { formatDate, formatTime } from "utils";

import usePayouts from "./usePayouts";

const PayoutRow = ({ payout }) => {
  const integrator = useIntegrator(payout.integrator);

  return (
    <Tr>
      <Td>{integrator?.displayName || payout.integrator}</Td>
      <Td isNumeric={true}>
        <Money value={payout.credit.amount} currency={payout.credit.currencyCode} />
      </Td>
      <Td>
        {formatDate(payout.updatedDate)} {formatTime(payout.updatedDate)}
      </Td>
      <Td>kyc name</Td>
      <Td>pm name</Td>
      <Td>{payout.payoutStatusId}</Td>
    </Tr>
  );
};

const filterToUniquePayouts = R.pipe(
  R.path(["transactions", "edges"]),
  R.defaultTo([]),
  R.map(R.prop("node")),
  R.uniqBy(R.prop("id"))
);

export const Payouts = () => {
  // TODO
  // const filter = useFilter();
  // console.log({ filter });
  const paginationOptions = useConnectionPaginationOptions("NEWEST");

  const { data, loading } = usePayouts({
    // TODO
    // filter: filter.gqlFilterParams,
    filter: {},
    ...paginationOptions,
  });

  const tmpUniquePayouts = filterToUniquePayouts(data);

  return (
    <PageContent title={{ page: "Payouts" }} opts={{ clear: true }}>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Publisher</Th>
              <Th isNumeric={true}>Amount</Th>
              <Th>Updated</Th>
              <Th>KYC Name</Th>
              <Th>PM Name</Th>
              <Th>Payout Status Id</Th>
            </Tr>
          </Thead>
          <LoadingTbody columns={6} loading={loading}>
            {tmpUniquePayouts.map((payout) => (
              <PayoutRow key={payout.id} payout={payout} />
            ))}
          </LoadingTbody>
        </Table>
      </TableContainer>
    </PageContent>
  );
};
