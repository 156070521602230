import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { TransactionPartyRole } from "@tilia-tools/core/transaction";
import { Money } from "components/Money";
import { DetailText } from "components/Text";
import PartyRoleName from "../PartyRoleName";
import AccountCard from "../AccountCard";

function DetailItem({ label, children }) {
  const gray600 = useColorModeValue("gray.600", "gray.200");

  return (
    <Flex flexDir="column" gap="2px" mt="2">
      <Text as="span" color={gray600} fontWeight={700}>
        {label}:
      </Text>
      <DetailText>{children}</DetailText>
    </Flex>
  );
}

function Recipients({ parties }) {
  const recipients = parties.filter((party) => {
    if (!party.account) {
      return false;
    }
    return (
      party.role === TransactionPartyRole.SELLER || party.role === TransactionPartyRole.PUBLISHER
    );
  });
  // render all recipients initially open
  const defaultIndex = Array.from(new Array(recipients.length), (_, index) => index);
  return (
    <Accordion defaultIndex={defaultIndex} allowMultiple={true}>
      {recipients.map((party) => (
        <AccordionItem key={`${party.role}-${party.account.accountId || ""}-${party.currencyCode}`}>
          <h3>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <PartyRoleName role={party.role} />
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h3>
          <AccordionPanel>
            <AccountCard accountId={party.account.accountId} />
            <DetailItem label="Amount">
              <Money value={party.amount} currency={party.currencyCode} />
            </DetailItem>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export default Recipients;
