import React from "react";
import styled from "styled-components/macro";
import { theme } from "styles";
import useAgentIsTiliaAgent from "context/useAgentIsTiliaAgent";
import { AppTitle } from "./AppTitle";
import { NavCollapseIcon, NavExpandIcon } from "components/Icons";
import { Tooltip } from "components/Tooltip";
import PublisherAgentNavItems from "./PublisherAgentNavItems";
import TiliaAgentNavItems from "./TiliaAgentNavItems";

const NavContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

const Side = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  height: 100%;
  width: ${({ collapsed }) => (collapsed ? theme("sidenavWidthCollapsed") : theme("sidenavWidth"))};
  background-color: ${theme("colors.darkBg")};
  color: ${theme("colors.fontInverse")};
  transition: width 0.25s ease-in-out;
  z-index: 1;
`;

const ExpandCollapseButton = ({ isCollapsed, onClick }) => {
  return (
    <div
      css={`
        position: absolute;
        left: 16px;
        bottom: 1vh;
        user-select: none;
      `}
      onClick={onClick}
    >
      {isCollapsed ? (
        <Tooltip data-place="right" data-tip="Expand">
          <NavExpandIcon
            css={`
              color: ${theme("sidenavIconFg")};
              &:hover {
                color: currentcolor;
              }
            `}
          />
        </Tooltip>
      ) : (
        <NavCollapseIcon
          css={`
            color: ${theme("sidenavIconFg")};
            &:hover {
              color: currentcolor;
            }
          `}
        />
      )}
    </div>
  );
};

export const SideNav = ({ toggleCollapse, isCollapsed }) => {
  const isTiliaAgent = useAgentIsTiliaAgent();
  return (
    <Side collapsed={isCollapsed}>
      <NavContainer>
        <AppTitle />
        {isTiliaAgent ? <TiliaAgentNavItems /> : <PublisherAgentNavItems />}
      </NavContainer>
      <ExpandCollapseButton isCollapsed={isCollapsed} onClick={toggleCollapse} />
    </Side>
  );
};
