import { Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { Money } from "components/Money";
import { DateAndTime } from "components/DataRow";
import { Container } from "./common";
import BalanceTransferStatusCodeName from "components/Transaction/BalanceTransfer/BalanceTransferStatusCodeName";
import BalanceTransferTypeName from "components/Transaction/BalanceTransfer/BalanceTransferTypeName";
import TransactionDetailsLink from "components/Transaction/TransactionDetailsLink";
import TransactionTypeName from "components/Transaction/TransactionTypeName";

function BalanceTransferGridRow({ balanceTransfer }) {
  return (
    <Container>
      <div>
        <DateAndTime value={balanceTransfer.date} />
        <TransactionDetailsLink transaction={balanceTransfer} />
      </div>

      <div>
        <div>
          <TransactionTypeName transactionType={balanceTransfer.type} />
        </div>
        <div>
          <BalanceTransferStatusCodeName
            balanceTransferStatusCode={balanceTransfer.balanceTransferStatusCode}
          />
        </div>
      </div>

      <div>
        <BalanceTransferTypeName balanceTransferType={balanceTransfer.balanceTransferType} />
      </div>

      <div>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td isNumeric={true}>
                <Money value={balanceTransfer.amount} currency={balanceTransfer.currencyCode} />
              </Td>
              <Td>---</Td>
            </Tr>
          </Tbody>
        </Table>
      </div>

      <div />
      <div />
      <div />
    </Container>
  );
}

export default BalanceTransferGridRow;
