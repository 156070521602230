import { Flex, Heading } from "@chakra-ui/layout";
import IntegratorLogo from "components/IntegratorLogo/IntegratorLogo";
import { PageContent } from "components/Page";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import { PermissionsMessage } from "components/PermissionsMessage";
import { ProductCatalog } from "components/ProductCatalog";
import { useIntegrator } from "context/integratorContext";
import { Outlet, Route, Routes, useParams } from "react-router-dom";
import "styled-components/macro";
import { exists } from "utils";
import { PublisherNav } from "./PublisherNav";
import { SSOManagement } from "./SSOManagement";
import TiliaUserProvisioning from "./UserProvisioning/TiliaUserProvisioning";

const PermissionsDenied = () => (
  <Panel>
    <PanelTitle>Access Denied</PanelTitle>
    <PanelContent>
      <PermissionsMessage />
    </PanelContent>
  </Panel>
);

const PublisherDetailsLayout = () => {
  return (
    <>
      <PublisherNav />
      <Outlet />
    </>
  );
};

export const PublisherDetails = () => {
  const { publisherId } = useParams();
  const normalizedPublisherId = publisherId.toLowerCase();

  // useIntegrator has logic in it which will determine
  // if the given integrator is accessible for the user token
  const integrator = useIntegrator(normalizedPublisherId);
  const allowedAccess = exists(integrator);
  return (
    <PageContent title={{ page: integrator?.displayName }}>
      {allowedAccess ? (
        <>
          <Flex as="header" alignItems="center" gap={3}>
            <IntegratorLogo integratorName={integrator.integratorId} />
            <Heading as="h1" m={0} size="lg">
              {integrator.displayName}
            </Heading>
          </Flex>
          <Routes>
            <Route element={<PublisherDetailsLayout />}>
              <Route index element={<ProductCatalog integrator={integrator} />} />
              <Route path="sso" element={<SSOManagement integrator={integrator} />} />
              <Route
                path="user-provisioning"
                element={<TiliaUserProvisioning integrator={integrator} />}
              />
            </Route>
          </Routes>
        </>
      ) : (
        <PermissionsDenied />
      )}
    </PageContent>
  );
};
