import { Box, Heading, Text } from "@chakra-ui/react";
import { EmptyPageIcon } from "components/Icons";
export default function EmptyPage({ title, subheader, Icon = EmptyPageIcon }) {
  return (
    <Box maxW="container.md" mx="auto" textAlign="center" py={8}>
      <Box color={"gray.300"} mb={4}>
        <Icon size={4} />
      </Box>
      <Heading as="h2" size="md" mb={2}>
        {title}
      </Heading>
      <Text fontSize="lg">{subheader}</Text>
    </Box>
  );
}
