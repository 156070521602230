import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useAgent } from "context/agentContext";
import { useNavigate } from "react-router-dom";
import { TiliaSmall } from "components/Logos";
import { PrimaryButton } from "components/Button";

const Center = styled.div`
  height: calc(100vh - 1px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 75%;
`;

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

export const Unauth = ({ children }) => {
  const navigate = useNavigate();
  const { logoutAgent } = useAgent();

  const handleClick = () => {
    logoutAgent();
    navigate("/login");
  };

  return (
    <Center>
      <Column>
        <TiliaSmall css="margin-bottom: 16px;" />
        {children && <Wrapper>{children}</Wrapper>}
        <PrimaryButton id="login-button" onClick={handleClick}>
          Login
        </PrimaryButton>
      </Column>
    </Center>
  );
};
