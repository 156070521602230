import {
  ModalFooter as ChakraModalFooter,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MultiError, getErrorFromResponse } from "api/utils";
import { ButtonIconWrapper, ButtonSpinner, PrimaryButton, TextLinkButton } from "components/Button";
import { Formik } from "formik";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { STATUS, exists, noop } from "utils";
import ModalErrors from "./ModalErrors";

const InnerFormModal = ({ validateForm, children }) => {
  useEffect(() => {
    (() => validateForm())();
  }, [validateForm]);
  return children;
};

const DefaultConfirmBody = () => {
  return <Text>Are you sure you want to proceed?</Text>;
};
const defaultRenderConfirmation = () => <DefaultConfirmBody />;

export default function useFormModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const FormModal = ({
    actionIcon,
    actionText,
    children,
    formProps,
    onSubmitActionSuccess,
    onSubmitActionFailed,
    submitAction,
    testIdPrefix = "form-modal-",
    titleText,
    disableActionButton = false,
    disableTillEdit,
    closeOnSubmitSuccess = true,
    onCloseAction,
    size = "xl",
    withConfirmation = false,
    renderConfirmation = defaultRenderConfirmation,
  }) => {
    const [actionState, setActionState] = useState({ status: STATUS.PRE_INIT });

    const handleSubmit = async (...args) => {
      setActionState(R.assoc("status", STATUS.INITIALIZED));

      try {
        const response = await submitAction(...args);
        if (response.status === "Success") {
          setActionState(R.assoc("status", STATUS.SUCCESS));
          onSubmitActionSuccess(response);
          closeOnSubmitSuccess && onClose();
        } else {
          setActionState(R.assoc("status", STATUS.ERROR));
          onSubmitActionFailed && onSubmitActionFailed(response);
          setActionState((state) => ({
            ...state,
            status: STATUS.ERROR,
            error: getErrorFromResponse(response),
          }));
        }
      } catch (err) {
        const error = err instanceof MultiError ? err.errors : err;
        setActionState(R.assoc("status", STATUS.ERROR));
        onSubmitActionFailed && onSubmitActionFailed();
        setActionState((state) => ({
          ...state,
          status: STATUS.ERROR,
          error,
        }));
      }
    };

    const closeAll = () => {
      onClose();
      exists(onCloseAction) && onCloseAction();
    };

    const [confirmMode, setConfirm] = useState(false);
    const handleConfirm = async () => setConfirm(true);

    const buildActionButton = (isValid, dirty, formikHandleSubmit) => {
      let actionButtonDisabled = true;
      let actionButton = null;

      const handleClick = withConfirmation
        ? confirmMode
          ? formikHandleSubmit
          : handleConfirm
        : formikHandleSubmit;

      if (submitAction) {
        actionButtonDisabled = disableTillEdit ? !(isValid && dirty) : !isValid;
        actionButton =
          actionState.status === STATUS.INITIALIZED ? (
            <PrimaryButton disabled={true} onClick={noop}>
              {actionText}
              <ButtonSpinner />
            </PrimaryButton>
          ) : actionState.status === STATUS.SUCCESS ? null : (
            <PrimaryButton
              data-testid={`${testIdPrefix}submit-button`}
              type="submit"
              onClick={handleClick}
              disabled={actionButtonDisabled}
            >
              {actionText}
              {actionIcon && <ButtonIconWrapper>{actionIcon}</ButtonIconWrapper>}
            </PrimaryButton>
          );
      }
      return actionButton;
    };

    const handleConfirmCancel = () => setConfirm(false);

    const buildCancelButton = () => {
      if (confirmMode) {
        return <TextLinkButton onClick={handleConfirmCancel}>Back To Edit</TextLinkButton>;
      }
      return actionState.status === STATUS.INITIALIZED ? (
        <TextLinkButton disabled={true} onClick={noop}>
          Cancel
        </TextLinkButton>
      ) : actionState.status === STATUS.SUCCESS ? (
        <PrimaryButton data-testid={`${testIdPrefix}cancel-button`} onClick={closeAll}>
          Close
        </PrimaryButton>
      ) : (
        <TextLinkButton data-testid={`${testIdPrefix}cancel-button`} onClick={closeAll}>
          Cancel
        </TextLinkButton>
      );
    };

    return (
      <Formik {...formProps} onSubmit={handleSubmit}>
        {({ handleSubmit, isValid, validateForm, _isSubmitting, dirty, values }) => {
          const errorFooter =
            actionState.status === STATUS.ERROR ? <ModalErrors errors={actionState.error} /> : null;
          const cancelButton = buildCancelButton();
          const actionButton = buildActionButton(isValid, dirty, handleSubmit);

          return (
            <Modal size={size} isOpen={isOpen} onClose={closeAll}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{titleText}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {errorFooter}
                  {confirmMode ? (
                    renderConfirmation(values)
                  ) : (
                    <InnerFormModal validateForm={validateForm}>{children}</InnerFormModal>
                  )}
                </ModalBody>
                <ChakraModalFooter gap={3}>
                  {cancelButton}
                  {!disableActionButton && actionButton}
                </ChakraModalFooter>
              </ModalContent>
            </Modal>
          );
        }}
      </Formik>
    );
  };
  return [FormModal, onOpen, onClose];
}
