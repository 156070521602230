/**
 * @typedef {Object} SettlementIdentifier
 * @property {string} settlementId TUID uniquely identifying the settlement.
 * @property {string} integrator Publisher that owns the settlement.
 */

/**
 * Funds to transfer to a publisher to account for transactions, chargebacks, fees, etc. during a specified period of time.
 * @typedef {Object} Settlement
 * @property {string} settlementId TUID uniquely identifying the settlement.
 * @property {string} integrator Publisher that owns the settlement.
 * @property {string} periodStartDatetime Date and time when the settlement period starts.
 * @property {string} periodEndDatetime Date and time when the settlement period ends.
 * @property {SettlementStatus[]} statusHistory Status transitions as the settlement progresses through the approval workflow.
 * @property {SettlementApproval[]} approvals Approvals recorded by Tilia agents as the settlement progresses through the approval workflow.
 * @property {SettlementDetail[]} details Gross amounts by type included in the total settlement amount.
 * @property {SettlementAdjustment[]} adjustments Non-transactional debits or credits manually recorded by Tilia finance agents.
 * @property {SettlementStatusCode} settlementStatusCode Current status of the settlement.
 * @property {number} netSettlement Total amount of the settlement.
 * @property {string} currencyCode Settlement currency.
 * @property {LegacyTransaction} [balanceTransfer] If the settlement has been settled, the balance transfer transaction moving the settlement amount into the publisher's wallet.
 * @property {Payout} [payout] If the settlement has been manually paid by a Tilia finance agent, the Payout transaction describing the wire transfer.
 * @property {SettlementSummary} summary Subtotals of the settlement's details and adjustments.
 */

/**
 * @typedef {Object} SettlementStatusIdentifier
 * @property {string} settlementStatusId TUID uniquely identifying the settlement status.
 * @property {string} integrator Publisher that owns the settlement.
 */

/**
 * Status transition as the settlement progresses through the approval workflow.
 * @typedef {Object} SettlementStatus
 * @property {string} settlementStatusId TUID uniquely identifying the settlement status.
 * @property {string} startDatetime Date and time when the status was effective.
 * @property {string|null} endDatetime Date and time when the status was superseded by a different status, or null if this is the current status of the settlement.
 * @property {SettlementStatusCode} settlementStatusCode Status of the settlement at the effective date.
 * @property {Account|null} userAccount Account of the user who set this status. May be null as not every status transition is attributed to a user.
 */

/**
 * @readonly
 * @enum {string}
 */
export const SettlementStatusCode = {
  /** Settlement has been created and is awaiting initial review by a finance analyst. */
  NEW: "NEW",
  /** Returned by any user to the start of the approval workflow for further review. */
  RETURNED: "RETURNED",
  /** Settlement has been reviewed and adjustments recorded by all required analysts. The settlement is awaiting approval by a finance manager. */
  REVIEWED: "REVIEWED",
  /** Settlement has been approved and adjustments recorded by all required managers, but the amount of the settlement exceeds the automatic settlement threshold. The settlement is awaiting super approval by an executive team member. */
  APPROVED: "APPROVED",
  /** Last required approval or super approval in the workflow has been received. The settlement is awaiting manual payment by a finance agent. */
  SETTLED: "SETTLED",
  /** Manual payment for the settlement has been recorded by the finance agent. */
  PAID: "PAID",
};

/**
 * @typedef {Object} SettlementDetailIdentifier
 * @property {string} settlementDetailId TUID uniquely identifying the settlement detail.
 * @property {string} integrator Publisher that owns the settlement.
 */

/**
 * Revenue, refunds, fees, etc. that comprise the net total amount for the settlement.
 * @typedef {Object} SettlementDetail
 * @property {string} settlementDetailId TUID uniquely identifying the settlement detail.
 * @property {SettlementDetailType} settlementDetailType Type of the settlement detail.
 * @property {SettlementDetailSource} source Underlying source of the settlement detail.
 * @property {number} amount Calculated amount.
 * @property {string} currencyCode Amount currency.
 */

/**
 * @readonly
 * @enum {string}
 */
export const SettlementDetailType = {
  /** Total gross revenue. */
  GROSS_FUNDED_REVENUE: "GROSS_FUNDED_REVENUE",
  /** Correction to gross revenue for a previous settlement period. */
  GROSS_FUNDED_REVENUE_CORRECTION: "GROSS_FUNDED_REVENUE_CORRECTION",
  /** Tilia minimum monthly fee. */
  MINIMUM_MONTHLY_FEE: "MINIMUM_MONTHLY_FEE",
  /** Total fees associated with tax calculations. */
  TAX_SERVICE_FEE: "TAX_SERVICE_FEE",
  /** Correction to tax service fee for a previous settlement period. */
  TAX_SERVICE_FEE_CORRECTION: "TAX_SERVICE_FEE_CORRECTION",
  /** Total fees associated with processing transactions. */
  TRANSACTION_PROCESSING_FEE: "TRANSACTION_PROCESSING_FEE",
  /** Correction to transaction processing fee for a previous settlement period. */
  TRANSACTION_PROCESSING_FEE_CORRECTION: "TRANSACTION_PROCESSING_FEE_CORRECTION",
  /** Total fees associated with processing fiat currency transactions, collected directly from sellers. */
  FIAT_LEDGER_TRANSACTION_FEE: "FIAT_LEDGER_TRANSACTION_FEE",
  /** Correction to fiat ledger transaction fee for a previous settlement period. */
  FIAT_LEDGER_TRANSACTION_FEE_CORRECTION: "FIAT_LEDGER_TRANSACTION_FEE_CORRECTION",
  /** Total fees associated with processing virtual currency transactions, collected directly from sellers. */
  VIRTUAL_LEDGER_TRANSACTION_FEE: "VIRTUAL_LEDGER_TRANSACTION_FEE",
  /** Correction to virtual ledger transaction fee for a previous settlement period. */
  VIRTUAL_LEDGER_TRANSACTION_FEE_CORRECTION: "VIRTUAL_LEDGER_TRANSACTION_FEE_CORRECTION",
  /** Total refunds. */
  REFUND: "REFUND",
  /** Correction to total refunds for a previous settlement period. */
  REFUND_CORRECTION: "REFUND_CORRECTION",
  /** Total fees associated with processing refunds. */
  REFUND_FEE: "REFUND_FEE",
  /** Correction to refund fee for a previous settlement period. */
  REFUND_FEE_CORRECTION: "REFUND_FEE_CORRECTION",
  /** Total chargebacks. */
  CHARGEBACK: "CHARGEBACK",
  /** Correction to total chargebacks for a previous settlement period. */
  CHARGEBACK_CORRECTION: "CHARGEBACK_CORRECTION",
  /** Total fees associated with processing chargebacks. */
  CHARGEBACK_FEE: "CHARGEBACK_FEE",
  /** Correction to chargeback fee for a previous settlement period. */
  CHARGEBACK_FEE_CORRECTION: "CHARGEBACK_FEE_CORRECTION",
};

/**
 * Settlement detail types considered "Tilia fees".
 * @readonly
 * @type {Set<SettlementDetailType>}
 */
export const TiliaFeeSettlementDetailTypes = new Set([
  SettlementDetailType.GROSS_FUNDED_REVENUE_CORRECTION,
  SettlementDetailType.MINIMUM_MONTHLY_FEE,
  SettlementDetailType.TAX_SERVICE_FEE,
  SettlementDetailType.TAX_SERVICE_FEE_CORRECTION,
  SettlementDetailType.TRANSACTION_PROCESSING_FEE,
  SettlementDetailType.TRANSACTION_PROCESSING_FEE_CORRECTION,
  SettlementDetailType.FIAT_LEDGER_TRANSACTION_FEE,
  SettlementDetailType.FIAT_LEDGER_TRANSACTION_FEE_CORRECTION,
  SettlementDetailType.VIRTUAL_LEDGER_TRANSACTION_FEE,
  SettlementDetailType.VIRTUAL_LEDGER_TRANSACTION_FEE_CORRECTION,
]);

/**
 * Settlement detail types considered "refunds".
 * @readonly
 * @type {Set<SettlementDetailType>}
 */
export const RefundSettlementDetailTypes = new Set([
  SettlementDetailType.REFUND,
  SettlementDetailType.REFUND_CORRECTION,
]);

/**
 * Settlement detail types considered "chargebacks".
 * @readonly
 * @type {Set<SettlementDetailType>}
 */
export const ChargebackSettlementDetailTypes = new Set([
  SettlementDetailType.CHARGEBACK,
  SettlementDetailType.CHARGEBACK_CORRECTION,
]);

/**
 * Settlement detail types considered "reversal fees".
 * @readonly
 * @type {Set<SettlementDetailType>}
 */
export const ReversalFeeSettlementDetailTypes = new Set([
  SettlementDetailType.REFUND_FEE,
  SettlementDetailType.REFUND_FEE_CORRECTION,
  SettlementDetailType.CHARGEBACK_FEE,
  SettlementDetailType.CHARGEBACK_FEE_CORRECTION,
]);

/**
 * Settlement detail types considered "corrections".
 * @readonly
 * @type {Set<SettlementDetailType>}
 */
export const CorrectionSettlementDetailTypes = new Set([
  SettlementDetailType.GROSS_FUNDED_REVENUE_CORRECTION,
  SettlementDetailType.TAX_SERVICE_FEE_CORRECTION,
  SettlementDetailType.TRANSACTION_PROCESSING_FEE_CORRECTION,
  SettlementDetailType.FIAT_LEDGER_TRANSACTION_FEE_CORRECTION,
  SettlementDetailType.VIRTUAL_LEDGER_TRANSACTION_FEE_CORRECTION,
  SettlementDetailType.REFUND_CORRECTION,
  SettlementDetailType.REFUND_FEE_CORRECTION,
  SettlementDetailType.CHARGEBACK_CORRECTION,
  SettlementDetailType.CHARGEBACK_FEE_CORRECTION,
]);

/**
 * @readonly
 * @enum {string}
 */
export const SettlementDetailSource = {
  /* Tilia-assessed fee. */
  TILIA: "TILIA",
  /** Tilia wallet. */
  WALLET: "WALLET",
  /** Stripe payment processor. */
  STRIPE: "STRIPE",
  /** PayPal payment processor. */
  PAYPAL: "PAYPAL",
};

/**
 * Individual review or approval of the settlement as it progresses through the approval workflow.
 * @typedef {Object} SettlementApproval
 * @property {string} approvalDatetime Date and time when the approval was submitted.
 * @property {Account} userAccount Account of the user who submitted this approval.
 * @property {SettlementApprovalType} approvalType Type of approval.
 */

/**
 * @readonly
 * @enum {string}
 */
export const SettlementApprovalType = {
  /** Initial review by a finance analyst. */
  REVIEW: "REVIEW",
  /** Approval by a finance manager. */
  APPROVE: "APPROVE",
  /** Super approval by an executive team member. */
  SUPER_APPROVE: "SUPER_APPROVE",
};

/**
 * Calculated subtotals for the SettlementDetail objects that make up the overall net settlement amount.
 * @typedef {Object} SettlementSummary
 * @property {number} revenueTotal Net total of all revenue detail items.
 * @property {number} deductionsTotal Net total of all deduction detail items.
 * @property {number} adjustmentsTotal Net total of all non-transactional debits or credits manually recorded by Tilia finance agents.
 * @property {SettlementDetailSubtotal[]} subtotals Array of subtotals of each distinct detail type + source + currency combination.
 */

/**
 * Calculated subtotal of the SettlementDetail objects for a given detail type + source + currency combination for the settlement.
 * @typedef {Object} SettlementDetailSubtotal
 * @property {SettlementDetailType} settlementDetailType Type of the settlement detail.
 * @property {SettlementDetailSource} source Underlying source of the settlement detail.
 * @property {number} amount Total amount.
 * @property {string} currencyCode Currency.
 */

/**
 * @typedef {Object} SettlementAdjustmentIdentifier
 * @property {string} settlementAdjustmentId TUID uniquely identifying the settlement adjustment.
 * @property {string} integrator Publisher that owns the settlement.
 */

/**
 * Non-transactional debit or credit manually recorded by a Tilia finance agent.
 * @typedef {Object} SettlementAdjustment
 * @property {string} settlementAdjustmentId TUID uniquely identifying the settlement adjustment.
 * @property {string} adjustmentDescription Description of the reason for the settlement adjustment.
 * @property {number} amount Adjustment amount.
 * @property {string} currencyCode Adjustment currency.
 * @property {Account} userAccount Account of the user who recorded this adjustment.
 */

/**
 * Summarizes the overall state of settlements as they progress through the approval workflow.
 * @typedef {Object} SettlementWorkflowSummary
 * @property {number} new Total number of settlements currently in the NEW status.
 * @property {number} returned Total number of settlements currently in the RETURNED status.
 * @property {number} reviewed Total number of settlements currently in the REVIEWED status.
 * @property {number} approved Total number of settlements currently in the APPROVED status.
 * @property {number} settled Total number of settlements currently in the SETTLED status.
 */

/**
 * @readonly
 * @enum {string}
 */
export const SettlementFilterSort = {
  /** Reverse-chronological order: newest first. */
  NEWEST: "NEWEST",
  /** Chronological order: oldest first. */
  OLDEST: "OLDEST",
  /** By amount: largest first. */
  LARGEST: "LARGEST",
  /** By amount: smallest first. */
  SMALLEST: "SMALLEST",
};
