import { ButtonGroup } from "@chakra-ui/react";
import CancelButton from "./CancelButton";
import SubmitButton from "./SubmitButton";

function SubmitCancelButtonGroup({ onCancel, disableOnClean, ...props }) {
  return (
    <ButtonGroup spacing={3} {...props}>
      <CancelButton onClick={onCancel} />
      <SubmitButton disableOnClean={disableOnClean} />
    </ButtonGroup>
  );
}

export default SubmitCancelButtonGroup;
