/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { gql, useQuery } from "@apollo/client";
import buildStringFilterInput from "../Filter/graphql/buildStringFilterInput";
import { SettlementStatusCode } from "./settlementTypes";

const getHistoricalSettlements = gql`
  query GetHistoricalSettlements(
    $filter: SettlementFilterInput
    $sort: SettlementFilterSort
    $first: NonNegativeInt
    $after: String
    $last: NonNegativeInt
    $before: String
  ) {
    settlements(
      filter: $filter
      sort: $sort
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        node {
          settlementId
          integrator
          periodStartDatetime
          periodEndDatetime
          statusHistory {
            startDatetime
            settlementStatusCode
          }
          approvals {
            approvalDatetime
            userAccount {
              accountId
              username
              firstName
              lastName
            }
            approvalType
          }
          netSettlement
          currencyCode
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
        offset
      }
      totalCount
    }
  }
`;

const useHistoricalSettlements = ({ filter, sort, first, after, last, before }) => {
  filter.statuses = [
    // TODO: Enable in Phase 2 once publisher-initiated payouts is live
    // buildStringFilterInput(SettlementStatusCode.SETTLED),
    buildStringFilterInput(SettlementStatusCode.PAID),
  ];
  return useQuery(getHistoricalSettlements, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    variables: {
      filter: filter ?? undefined,
      sort: sort ?? undefined,
      first: first ?? undefined,
      after: after ?? undefined,
      last: last ?? undefined,
      before: before ?? undefined,
    },
  });
};

export default useHistoricalSettlements;
