import { InvoicingApiInvoiceType } from "@tilia-tools/core/invoicingApi";
import { RefundSummaryStatusCode } from "@tilia-tools/core/transaction";
import transactionIsRefundable from "./transactionIsRefundable";

const partial_refund = "PARTIAL_REFUND";
const full_refund = "FULL_REFUND";

export const REFUND_TYPES = {
  partial_refund,
  full_refund,
};

const partialRefundInvoiceTypesWhitelist = [
  InvoicingApiInvoiceType.UserPurchaseIT,
  // TODO: should InvoicingApiInvoiceType.UserPurchaseVirtualIT be here, since it is for escrow?
  InvoicingApiInvoiceType.EscrowIT,
  InvoicingApiInvoiceType.EscrowVirtualIT,
];

function getTransactionRefundTypes(transaction) {
  // if transaction is not refundable at all, return an empty array
  if (!transactionIsRefundable(transaction)) {
    return [];
  }

  const listOfRefundTypes = [];

  // if transaction has already been partially refunded, allow only for partial refund
  if (transaction.refundSummary.status === RefundSummaryStatusCode.PARTIAL) {
    listOfRefundTypes.push(partial_refund);
    return listOfRefundTypes;
  } else {
    listOfRefundTypes.push(full_refund);
  }

  // if transaction has not been refunded but can allow partial refund
  // add partial_refund to array and return
  if (partialRefundInvoiceTypesWhitelist.includes(transaction.invoiceType)) {
    listOfRefundTypes.push(partial_refund);
  }

  return listOfRefundTypes;
}

export default getTransactionRefundTypes;
