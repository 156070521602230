import { gql, useQuery } from "@apollo/client";
import { TransactionType } from "@tilia-tools/core/transaction";
// import buildStringFilterInput from "components/Filter/graphql/buildStringFilterInput";

const getPayouts = gql`
  query GetPayouts(
    $filter: TransactionFilterInput
    $sort: TransactionFilterSort
    $first: NonNegativeInt
    $after: String
    $last: NonNegativeInt
    $before: String
  ) {
    transactions(
      filter: $filter
      sort: $sort
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        node {
          __typename
          ... on Payout {
            id
            type
            integrator
            date
            amount
            currencyCode
            updatedDate
            payoutStatusId
            statusCode
            credit {
              id
              integrator
              amount
              currencyCode
              feeAmount
              statusCode
              referenceId
              sourcePaymentMethod {
                paymentMethodId
                displayString
                provider
              }
              destinationPaymentMethod {
                paymentMethodId
                displayString
                provider
              }
            }
          }
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
        offset
      }
      totalCount
    }
  }
`;

const usePayouts = ({ filter, sort, first, after, last, before }) => {
  filter.types = [TransactionType.PAYOUT];
  return useQuery(getPayouts, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    variables: {
      filter: filter ?? undefined,
      sort: sort ?? undefined,
      first: first ?? undefined,
      after: after ?? undefined,
      last: last ?? undefined,
      before: before ?? undefined,
    },
  });
};

export default usePayouts;
