import React from "react";
import * as R from "ramda";
import { MenuButton } from "components/Menu";

export const ActionMenu = ({ menuItems = [], menuSettings = [] }) => {
  const filteredMenuItems = R.filter(
    (i) => R.includes(i.key, menuSettings) && i.item["data-displayitem"],
  )(menuItems);

  if (!filteredMenuItems.length) {
    return <span />;
  }

  return (
    <span>
      <MenuButton
        buttonLabel="Actions"
        items={[...R.pluck("item")(filteredMenuItems)]}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      {R.pipe(R.pluck("comp"), R.filter(R.identity))(filteredMenuItems)}
    </span>
  );
};
