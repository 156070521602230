import React from "react";
import { useAccount } from "context/accountContext";
import { blockAccount, deleteAccountPermission } from "api/account";
import { DeleteIcon } from "components/Icons";
import { useBasicActionModal } from "components/Modal";
import { PermissionsActionMessage } from "components/PermissionsMessage";
import useAgentCanDeactivateIntegratorToolsUser from "./useAgentCanDeactivateIntegratorToolsUser";
import useUserToolsGroups from "./useUserToolsGroups";
import { ctxWithIntegrator } from "utils/auth";

const useDeactivateIntegratorToolsUserModal = () => {
  const [BasicActionModal, openModal] = useBasicActionModal();

  const DeactivateIntegratorToolsUserModal = ({ user, onDeactivateUser }) => {
    const { agentContext } = useAccount();
    let canDeactivateUser = useAgentCanDeactivateIntegratorToolsUser();
    const { data, loading, permissionsFailed } = useUserToolsGroups(user.accountId);

    if (permissionsFailed || (!loading && !permissionsFailed && !data?.account?.permissions)) {
      canDeactivateUser = false;
    }

    const handleSubmit = async () => {
      const updatedCtx = ctxWithIntegrator(user.integrator, agentContext);

      const promises = [blockAccount(user.accountId, "login", "account_close", "", updatedCtx)];
      for (const group of data.account.permissions.groups.direct) {
        promises.push(deleteAccountPermission(user.accountId, group, updatedCtx));
      }
      const results = await Promise.allSettled(promises);

      let errors = [];
      for (const result of results) {
        if (result.status === "rejected") {
          errors.push(result.reason);
        } else if (result.value?.errors && result.value.errors.length > 0) {
          errors = [...errors, ...result.value.errors];
        }
      }

      if (errors.length > 0) {
        return { status: "Failure", error: errors };
      }
      return { status: "Success" };
    };

    const handleSubmitActionSuccess = () => {
      onDeactivateUser(user);
    };

    return (
      <BasicActionModal
        actionIcon={<DeleteIcon size="1rem" />}
        actionText="Deactivate"
        submitAction={canDeactivateUser ? handleSubmit : undefined}
        onSubmitActionSuccess={handleSubmitActionSuccess}
        titleText="Deactivate User"
      >
        {canDeactivateUser ? (
          <>
            <p>Are you sure you want to deactivate the user “{user.username}”?</p>
            <p>You can reactivate this user at any point.</p>
          </>
        ) : (
          <PermissionsActionMessage />
        )}
      </BasicActionModal>
    );
  };

  return [DeactivateIntegratorToolsUserModal, openModal];
};

export default useDeactivateIntegratorToolsUserModal;
