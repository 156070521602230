import React, { useContext, useEffect, useState } from "react";
import * as R from "ramda";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { READ_GDPR_DOCUMENTS, GDPR_RESOURCE } from "context/permissions";
import { Panel, PanelTitle } from "components/Panel";
import { formatDateTime } from "utils";
import {
  GridContainer,
  GridContext,
  ColumnHeader,
  GridRow as GridRowComponent,
  withGrid,
  SORT_DIRECTION,
} from "components/Grid";
import { getAccountGDPR, useQuery } from "context/graphqlContext";
import { useDataAccessLinkModal } from "./GDPRDataAccessLinkModal";

const GridRow = styled(GridRowComponent)`
  grid-template-columns: repeat(4, 1fr);
`;

const DocumentRow = ({ document, integrator }) => {
  const [LinkModal, LinkButton] = useDataAccessLinkModal(document, integrator);
  return (
    <GridRow>
      <div>{document.file_name}</div>
      <div>{document.file_mime_type}</div>
      <div>{formatDateTime(document.ts)}</div>
      <div>
        <LinkButton />
        <LinkModal />
      </div>
    </GridRow>
  );
};

const GridHeader = () => (
  <GridRow>
    <ColumnHeader field="file_name" sortable={true}>
      Filename
    </ColumnHeader>
    <ColumnHeader field="file_mime_type" sortable={true}>
      File Type
    </ColumnHeader>
    <ColumnHeader field="ts" sortable={true}>
      Date
    </ColumnHeader>
  </GridRow>
);

const byTextProp = (prop) => R.compose(R.toLower, R.prop(prop));

const sortMap = {
  file_name: byTextProp("file_name"),
  file_mime_type: byTextProp("file_mime_type"),
  ts: R.prop("ts"),
};

const sortDocuments = (documents = [], sortDir, sortField) => {
  const sorted = R.sortBy(sortMap[sortField], documents);
  return sortDir === SORT_DIRECTION.ASC ? sorted : R.reverse(sorted);
};

const useSortedDocumentGrid = (documents) => {
  const [sortedDocs, setSortedDocs] = useState([]);
  const g = useContext(GridContext);
  useEffect(() => {
    setSortedDocs(sortDocuments(documents, g.sortDir, g.sortField));
  }, [g.sortDir, g.sortField, documents]);
  return [sortedDocs];
};

const DocumentGrid = withGrid(
  ({ account, isLoading }) => {
    const documents = account?.gdpr?.documents;
    const integrator = account?.integrator;

    const [sortedDocs] = useSortedDocumentGrid(documents);
    return (
      <GridContainer isLoading={isLoading}>
        <GridHeader />
        {sortedDocs.map((d) => (
          <DocumentRow key={d.pii_id} document={d} integrator={integrator} />
        ))}
      </GridContainer>
    );
  },
  {
    keyField: "file_name",
    sortDir: SORT_DIRECTION.ASC,
    sortField: "file_name",
  }
);

export const GDPRDataAccess = () => {
  const q = useQuery(getAccountGDPR, {
    permissions: [READ_GDPR_DOCUMENTS],
    resource: GDPR_RESOURCE,
  });

  return (
    <Panel>
      <PanelTitle>GDPR Documents</PanelTitle>
      <DocumentGrid
        account={q.data?.account}
        documents={q.data?.account?.gdpr?.documents}
        isLoading={q.loading}
      />
    </Panel>
  );
};
