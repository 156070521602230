import React from "react";
import "styled-components/macro";
import { Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { mapKYCStatus } from "context/accountContext/kyc";
import { SectionSpinner } from "components/Spinner";
import { PanelTitle } from "components/Panel";
import { KYCApplications } from "./KYCApplication";
import { KYCActionMenu } from "./KYCActionMenu";
import { gql } from "@apollo/client";
import { useQuery } from "context/graphqlContext";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import { PermissionsMessage } from "components/PermissionsMessage";
import { READ_KYC_PIIS } from "context/permissions";
import { exists } from "utils";

const GET_KYC_APPLICATIONS = gql`
  fragment Application on KycApplication {
    applicationId
    created
    state
    vendorReferenceId
    pii {
      additionalDocumentIds
      dateOfBirth
      ssn
      firstName
      identityDocument {
        type
        country
        frontId
        backId
      }
      lastName
      middleName
      primaryAddress {
        street
        street2
        city
        region
        country
        postalCode
      }
    }
  }

  query getKYCApplications($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      integrator
      username
      kyc {
        currentApplication: applications(status: CURRENT) {
          ...Application
        }
        historicalApplications: applications(status: HISTORICAL) {
          ...Application
        }
      }
    }
  }
`;

export const Details = () => {
  const hasPermissions = useAgentHasPermissions(READ_KYC_PIIS);
  const { accountId } = useParams();
  const { loading, data, refetch } = useQuery(GET_KYC_APPLICATIONS, {
    errorPolicy: "all",
    variables: { accountId },
  });

  const integrator = data?.account?.integrator;
  const username = data?.account?.username;
  const currentApplication = data?.account?.kyc?.currentApplication?.[0];
  const historicalApplications = data?.account?.kyc?.historicalApplications;

  if (!hasPermissions) {
    return <PermissionsMessage />;
  }

  if (loading) {
    return <SectionSpinner />;
  }

  const kycStatus = exists(currentApplication) ? mapKYCStatus(currentApplication.state) : "No Data";

  return (
    <>
      <PanelTitle>
        <Text>KYC STATUS: {kycStatus}</Text>
        <KYCActionMenu onEditSuccess={refetch} currentApplication={currentApplication} />
      </PanelTitle>
      <KYCApplications
        accountIdentifier={{ accountId, integrator }}
        username={username}
        currentApplication={currentApplication}
        historicalApplications={historicalApplications}
      />
    </>
  );
};
