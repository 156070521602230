import { PurchaseStatusCode, TransactionType } from "@tilia-tools/core/transaction";
import { TransactionHeader } from "../Layout";
import PurchaseStatusCodeName from "./PurchaseStatusCodeName";

const statusColors = {
  [PurchaseStatusCode.OPEN]: "orange",
  [PurchaseStatusCode.PROCESSING]: "orange",
  [PurchaseStatusCode.SUCCESS]: "green",
  [PurchaseStatusCode.FAILED]: "red",
  [PurchaseStatusCode.SYSTEM_CANCELED]: "gray",
};

function PurchaseHeader({ purchase }) {
  return (
    <TransactionHeader
      title="Purchase"
      transactionType={TransactionType.PURCHASE}
      badge={{
        label: <PurchaseStatusCodeName purchaseStatusCode={purchase.purchaseStatusCode} />,
        color: statusColors[purchase.purchaseStatusCode],
      }}
      copyableId={purchase.invoiceId}
      date={purchase.date}
    />
  );
}

export default PurchaseHeader;
