import React from "react";
import { BlockedIcon } from "components/Icons";
import useAgentCanBlockAccount from "./useAgentCanBlockAccount";
import useAccountBlocks from "./useAccountBlocks";
import useBlockAccountModal from "./useBlockAccountModal";

const useBlockUserActionMenuItem = (key, account) => {
  const agentCanBlockAccount = useAgentCanBlockAccount(account.account_id);
  const [, refetch] = useAccountBlocks(account.account_id);
  const [BlockAccountModal, openModal] = useBlockAccountModal();

  const handleAccountBlocked = () => {
    refetch();
  };

  return {
    key,
    item: {
      disabled: !agentCanBlockAccount,
      "data-displayitem": agentCanBlockAccount,
      label: "Block…",
      icon: <BlockedIcon size="1rem" />,
      onSelect: openModal,
    },
    comp: <BlockAccountModal key={key} account={account} onAccountBlocked={handleAccountBlocked} />,
  };
};

export default useBlockUserActionMenuItem;
