import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  CardBody,
  Divider,
  Flex,
  Grid,
  GridItem,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { CopyableText } from "components/Copyable";
import { Money } from "components/Money";
import { exists } from "utils";
import {
  TransactionItemGrid,
  TransactionItemGridRight,
  TransactionItemGridAmount,
} from "components/Transaction/Layout/TransactionItemGrid";
import { AccountLink } from "components/Account";
import PartyRoleName from "../PartyRoleName";

function LineItemDetail({ label, children }) {
  const gray600 = useColorModeValue("gray.600", "gray.200");

  return (
    <Box display="flex" gap={2} alignItems="center">
      <Text as="span" color={gray600} fontWeight={700}>
        {label}:
      </Text>
      <Text as="span" color={gray600} fontWeight={400}>
        {children || "-"}
      </Text>
    </Box>
  );
}

function LineItemsSummary({ transaction }) {
  const gray100 = useColorModeValue("gray.100", "gray.600");
  return (
    <Card background={gray100}>
      <CardBody>
        <Box p={4}>
          <TransactionItemGrid>
            <GridItem>
              <Box marginLeft="32px">Line Items</Box>
            </GridItem>
            <TransactionItemGridRight fontSize="md">
              <TransactionItemGridAmount>
                <Money
                  value={transaction.summarySubtotalAmount}
                  currency={transaction.summarySubtotalCurrency}
                />
              </TransactionItemGridAmount>
            </TransactionItemGridRight>
          </TransactionItemGrid>

          {exists(transaction.summaryTaxAmount) && (
            <TransactionItemGrid>
              <GridItem>
                <Box marginLeft="32px">Tax</Box>
              </GridItem>
              <TransactionItemGridRight>
                <TransactionItemGridAmount>
                  <Money
                    value={transaction.summaryTaxAmount}
                    currency={transaction.summaryTaxCurrency}
                  />
                </TransactionItemGridAmount>
              </TransactionItemGridRight>
            </TransactionItemGrid>
          )}

          <Divider />
          <TransactionItemGrid fontSize="lg">
            <GridItem>
              <Box marginLeft="32px">Total Purchase</Box>
            </GridItem>
            <TransactionItemGridRight>
              <TransactionItemGridAmount>
                <Money value={transaction.amount} currency={transaction.currencyCode} />
              </TransactionItemGridAmount>
            </TransactionItemGridRight>
          </TransactionItemGrid>
        </Box>
      </CardBody>
    </Card>
  );
}

function LineItems({ transaction }) {
  let lineItems = [...transaction.lineItems].sort((a, b) => b.amount - a.amount); // order by greatest to least amount

  const GRID_TEMPLATE_COLUMNS = {
    base: "1fr 200px 5px",
    "2xl": "1fr 200px 70px",
  };
  const gray600 = useColorModeValue("gray.600", "gray.200");
  const gray700 = useColorModeValue("gray.700", "gray.200");
  const styles = useMultiStyleConfig("Table");
  const headerStyles = { ...styles.th, padding: 0, borderBottom: "none", textAlign: "inherit" };

  return (
    <Flex direction="column" gap="2">
      <Card>
        <CardBody>
          <Grid templateColumns={GRID_TEMPLATE_COLUMNS} gap={4}>
            <GridItem pl={4}>
              <Text as="div" sx={headerStyles}>
                Line Item
              </Text>
            </GridItem>
            <GridItem paddingRight={9} textAlign="right">
              <Text as="div" sx={headerStyles}>
                Amounts
              </Text>
            </GridItem>
          </Grid>

          <Accordion defaultIndex={[0]} allowMultiple={true}>
            {lineItems.map((lineItem) => (
              <AccordionItem key={lineItem.lineItemId}>
                <h3>
                  <AccordionButton>
                    <Grid
                      textAlign="left"
                      width="100%"
                      templateColumns={GRID_TEMPLATE_COLUMNS}
                      gap={4}
                      alignItems="center"
                    >
                      <GridItem>
                        <Text as="span">{lineItem.description}</Text>
                      </GridItem>
                      <GridItem textAlign="right" pl={2}>
                        <Text as="span" color={gray700} textTransform="uppercase" fontWeight={700}>
                          <Money value={lineItem.amount} currency={lineItem.currency} />
                        </Text>
                      </GridItem>
                    </Grid>

                    <AccordionIcon />
                  </AccordionButton>
                </h3>
                <AccordionPanel pb={4}>
                  <Flex direction="column" gap={0}>
                    <LineItemDetail label="Reference ID">
                      <CopyableText text={lineItem.referenceId}>
                        {lineItem.referenceId}
                      </CopyableText>
                    </LineItemDetail>
                    <LineItemDetail label="Product SKU">
                      <CopyableText text={lineItem.productSku}>{lineItem.productSku}</CopyableText>
                    </LineItemDetail>
                    <LineItemDetail label="Line Item ID">
                      <CopyableText text={lineItem.lineItemId}>{lineItem.lineItemId}</CopyableText>
                    </LineItemDetail>
                    <LineItemDetail label="Transaction Type">
                      {lineItem.transactionType}
                    </LineItemDetail>
                  </Flex>
                  {lineItem.parties.length > 0 && (
                    <>
                      <Text color={gray600} fontWeight={700}>
                        Recipients
                      </Text>
                      <Stack>
                        {lineItem.parties.map((party) => (
                          <TransactionItemGrid
                            key={`${party.role}-${party.account?.accountId || ""}-${
                              party.currencyCode
                            }`}
                          >
                            <GridItem>
                              {party.account ? (
                                <AccountLink account={party.account} includeAvatar={true}>
                                  {party.account.username || party.account.accountId}
                                </AccountLink>
                              ) : (
                                <Box as="span" paddingLeft="32px">
                                  <PartyRoleName role={party.role} />
                                </Box>
                              )}
                            </GridItem>
                            <TransactionItemGridRight>
                              <TransactionItemGridAmount>
                                <Money value={party.amount} currency={party.currencyCode} />
                              </TransactionItemGridAmount>
                            </TransactionItemGridRight>
                          </TransactionItemGrid>
                        ))}
                      </Stack>
                    </>
                  )}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </CardBody>
      </Card>
      <Spacer />
      <LineItemsSummary transaction={transaction} />
    </Flex>
  );
}

export default LineItems;
