import React from "react";
import * as R from "ramda";
import "styled-components/macro";
import { useParams } from "react-router-dom";
import { theme } from "styles";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import { SectionSpinner } from "components/Spinner";
import { Personas } from "components/Personas";
import { OpenInNewIcon } from "components/Icons";
import { SSN } from "components/SSN";
import { DataRow } from "components/DataRow";
import { Address, AddressTitleWithMapLink } from "components/Address";
import { getFinalKYCStatus } from "context/accountContext/kyc";
import { useAccount } from "context/accountContext";
import { useAgent } from "context/agentContext";
import { getAccountDetails, useQuery } from "context/graphqlContext";
import { PERSONA_INFO, READ_EMAILS, WRITE_DETAILS_ACTIONS_RESOURCE } from "context/permissions";
import { useIntegrator } from "context/integratorContext";
import { exists, formatDate, isNilOrEmpty } from "utils";
import { getRealNameFromKYCPII } from "utils/format";
import { Notes } from "./Notes";
import { Emails } from "./Emails";
import { MatchedAccounts } from "./MatchedAccounts";
import DetailsActionMenu from "./DetailsActionMenu";
import { useTitle } from "context/titleContext";

const OpenInNewButton = ({ href }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <OpenInNewIcon
        css={`
          margin-left: 4px;
          & path {
            fill: ${theme("colors.iconLink.base")};
          }
          &:hover path {
            fill: ${theme("colors.iconLink.hover")};
          }
          &:active path {
            fill: ${theme("colors.iconLink.active")};
          }
        `}
        size={0.6}
      />
    </a>
  );
};

const Integrator = ({ integratorName }) => {
  const integrator = useIntegrator(integratorName);
  if (isNilOrEmpty(integrator)) return null;

  const adminLink = integrator.getAdminLink();
  return (
    <>
      {integrator.displayName}
      {adminLink && <OpenInNewButton href={adminLink} />}
    </>
  );
};

const getPIILevel = (kycPII) => kycPII?.data?.kyc_pii?.pii_level;
const getDOB = R.path(["data", "kyc_pii", "date_of_birth"]);
const getRelatedCompany = R.defaultTo("", R.path(["entity_name"]));

export const Details = ({ refetchAccountDetails }) => {
  const { agent } = useAgent();
  const { accountId } = useParams();

  //=========================================================
  // TODO shrink this to nothing
  const { accountState } = useAccount();
  const detailsData = accountState?.details?.data || {};

  const { details, kyc, kycPII, entity } = accountState;

  const dob = getDOB(kycPII);
  const realName = getRealNameFromKYCPII(kycPII?.data?.kyc_pii);
  const relatedCompany = getRelatedCompany(entity);
  const piiLevel = getPIILevel(kycPII);

  //=========================================================
  // TODO everything through data
  const { data, loading, refetch } = useQuery(getAccountDetails, {
    permissions: [],
    errorPolicy: "all",
    variables: {
      accountId,
    },
  });

  // TODO clean up
  const kycNew = data?.account?.kyc || { kycMatchChecks: [] };
  const personas = data?.account?.personas || [];
  const notes = data?.account?.notes || [];
  const emailData = data?.account?.emails;

  //=========================================================
  useTitle({ sub1: "Details" });

  if (!accountState.accountDetailsReady || loading) {
    return <SectionSpinner />;
  }

  return (
    <>
      <Panel>
        {agent.hasPermissions([], WRITE_DETAILS_ACTIONS_RESOURCE) && (
          <PanelTitle data-testid="details-action-menu">
            <span />
            <DetailsActionMenu
              detailsData={detailsData}
              onSuccessfulUpdate={refetch}
              refetchAccountDetails={refetchAccountDetails}
            />
          </PanelTitle>
        )}
        <PanelContent>
          <div
            css={`
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
              grid-column-gap: 16px;
            `}
          >
            <div>
              <DataRow title="Username" path={["account", "username"]} data={data} copyable />
              <DataRow title="Personal Information" data={piiLevel} />
              <DataRow title="Real Name" data={realName} permissions={kycPII.permissions} />
              <DataRow title="Date of Birth" data={dob} permissions={kycPII.permissions} />
              <DataRow
                title={<AddressTitleWithMapLink address={kycPII?.data?.kyc_pii} />}
                alignItems="top"
              >
                <Address address={kycPII?.data?.kyc_pii} />
              </DataRow>
              <DataRow title="SSN">
                <SSN value={kycPII?.data?.kyc_pii?.ssn_last4} />
              </DataRow>
              {!isNilOrEmpty(relatedCompany) && (
                <DataRow
                  title="Related Company"
                  data={relatedCompany}
                  route={`/entity/${entity.entity_id}`}
                  permissions={entity.permissions}
                />
              )}
            </div>
            <div>
              <DataRow
                title="Publisher"
                data={<Integrator integratorName={data?.account?.integrator} />}
              />
              <DataRow
                title="Account Created"
                path={["data", "created"]}
                data={details}
                formatter={formatDate}
              />
              <DataRow
                title="TOS Signed"
                path={["account", "tos", "signed"]}
                data={data}
                formatter={String}
              />
              <DataRow
                data={getFinalKYCStatus(kyc)}
                permissions={kyc.permissions}
                title="KYC Status"
              />
              <DataRow
                data={kyc}
                formatter={formatDate}
                path={["data", "created"]}
                permissions={kyc.permissions}
                title="KYC Created"
              />
              <DataRow
                data={kyc}
                formatter={formatDate}
                path={["data", "updated"]}
                permissions={kyc.permissions}
                title="KYC Updated"
              />
            </div>
          </div>
        </PanelContent>
      </Panel>
      {exists(kycNew?.kycMatchChecks) && (
        <Panel>
          <PanelTitle>MATCHED ACCOUNTS</PanelTitle>
          <PanelContent>
            <MatchedAccounts matchedAccounts={kycNew.kycMatchChecks} />
          </PanelContent>
        </Panel>
      )}
      {agent.hasPermissions([READ_EMAILS]) && (
        <Panel>
          <PanelTitle>Email</PanelTitle>
          <PanelContent>
            <Emails data={emailData} />
          </PanelContent>
        </Panel>
      )}
      {agent.hasPermissions([PERSONA_INFO]) && (
        <Panel>
          <PanelTitle>Personas</PanelTitle>
          <PanelContent>
            <Personas data={personas} />
          </PanelContent>
        </Panel>
      )}
      <Notes accountId={accountId} notes={notes} />
    </>
  );
};
