import { ModalFooter as ChakraModalFooter } from "@chakra-ui/react";

export { default as Modal } from "./Modal.js";
export { default as FormModal } from "./FormModal.js";
export { default as ModalErrors } from "./ModalErrors.js";
export { default as useBasicActionModal } from "./useBasicActionModal.js";
export { default as useFormModal } from "./useFormModal.js";
export { default as useModal } from "./useModal.js";

// eslint-disable-next-line no-unused-vars
export const ModalFooter = ({ modalFooter = true, ...props }) => {
  return <ChakraModalFooter gap={3} {...props} />;
};
