import React from "react";
import * as R from "ramda";
import "styled-components/macro";
import { theme } from "styles";
import { getLocQueryString } from "utils";
import { useLocation } from "react-router-dom";

export const SAMLLogin = () => {
  const location = useLocation();
  const info = R.prop("info", getLocQueryString(location));

  return (
    <>
      <div
        css={`
          margin-bottom: 16px;
          color: ${theme("colors.error")};
        `}
      >
        {info}
      </div>
      <a
        href="/ui/api/login/saml"
        css={`
          text-decoration: none;
        `}
      >
        <div
          data-testid="saml-login"
          css={`
            display: flex;
            color: ${theme("colors.button.primary.border")};
            border: 1px solid ${theme("colors.button.primary.border")};
            border-radius: 3px;
            padding: 8px;

            &:hover {
              background: ${theme("colors.button.primary.hover.bg")};
              color: ${theme("colors.button.primary.color")};
            }
          `}
        >
          <div
            css={`
              width: 100%;
              margin-right: 8px;
              text-align: center;
            `}
          >
            Log in with SAML
          </div>
        </div>
      </a>
    </>
  );
};
