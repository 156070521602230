import React from "react";
import "styled-components/macro";
import { theme } from "styles";
import { exists } from "utils";
import { useAddEditSettingModal } from "./AddEditSettingModal";
import { useDeleteSettingModal } from "./DeleteSettingModal";
import { FilterHighlighter } from "components/Highlight";

export const SettingsEntry = ({
  deleteEnabled,
  existingKeys,
  filter,
  onChange,
  prefix = "",
  settingsKey = "",
  value = "",
}) => {
  const keyWithoutPrefix = settingsKey.substring(prefix.length);
  const [AddEditSettingModal, AddEditSettingButton] = useAddEditSettingModal();
  const [DeleteSettingModal, DeleteSettingButton] = useDeleteSettingModal();
  const valueString = value ? JSON.stringify(value, null, 2) : "";
  const isInvalid = exists(value?.parsingError) && exists(value?.rawValue);

  return (
    <div
      css={`
        padding-bottom: 4px;
        margin-bottom: 4px;
        &:hover .settings-value {
          background-color: ${theme("colors.dataTable.hoverBg")};
        }
        border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
      `}
    >
      <div
        css={`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 4px;
          margin-bottom: 4px;
        `}
      >
        <div data-testid={`settings-key-${settingsKey}`}>
          <FilterHighlighter filter={filter}>{keyWithoutPrefix}</FilterHighlighter>
        </div>
        <div
          css={`
            display: flex;
          `}
        >
          <AddEditSettingButton label="Edit" data-testid={`add-edit-button-${settingsKey}`} />
          {deleteEnabled && (
            <div
              css={`
                margin-left: 4px;
              `}
            >
              <DeleteSettingButton data-testid={`delete-button-${settingsKey}`} />
            </div>
          )}
          <AddEditSettingModal
            existingKeys={existingKeys}
            onSuccess={onChange}
            prefix={prefix}
            settingsKey={settingsKey}
            settingsValue={valueString}
          />
          {deleteEnabled && (
            <DeleteSettingModal
              onSuccess={onChange}
              prefix={prefix}
              settingsKey={settingsKey}
              settingsValue={valueString}
            />
          )}
        </div>
      </div>
      <div
        css={`
          max-width: 100%;
          overflow-y: hidden;
        `}
      >
        {value ? (
          <pre
            className="settings-value"
            data-testid={`settings-value-${settingsKey}`}
            isInvalid={isInvalid}
            css={`
              margin-top: 0;
              white-space: pre-wrap;
              padding: 8px;
              color: ${({ isInvalid }) => (isInvalid ? theme("colors.warning.color") : "inherit")}
              };
            `}
          >
            <FilterHighlighter filter={filter}>{valueString}</FilterHighlighter>
          </pre>
        ) : (
          <div
            css={`
              color: ${theme("colors.warning.color")};
            `}
          >
            NO VALUE
          </div>
        )}
      </div>
    </div>
  );
};
