/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { Box, Heading, Table, Tbody, Td, Tfoot, Tr } from "@chakra-ui/react";
import { Link } from "components/Link";
import { Money } from "components/Money";

/**
 * @param {SettlementSummary} summary
 * @param {string} currencyCode
 * @param {LegacyTransaction} balanceTransfer
 * @param {Payout} payout
 * @returns {JSX.Element}
 */
function SettlementBalanceSummary({ summary, currencyCode, balanceTransfer, payout }) {
  const total =
    BigInt(summary.revenueTotal) +
    BigInt(summary.deductionsTotal) +
    BigInt(summary.adjustmentsTotal);

  return (
    <Box>
      <Heading as="h4" size="sm" mb="2">
        Settlement Balance
      </Heading>
      <Table size="sm">
        <Tbody>
          {balanceTransfer && (
            <Tr>
              <Td>Balance Transfer</Td>
              <Td isNumeric>{balanceTransfer.id}</Td>
            </Tr>
          )}
          {payout && (
            <Tr>
              <Td>Payout</Td>
              <Td isNumeric>
                <Link to={`/transaction/${payout.id}`} display="inline">
                  {payout.id}
                </Link>
              </Td>
            </Tr>
          )}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td>Total Settlement</Td>
            <Td isNumeric>
              <Money value={total} currency={currencyCode} />
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  );
}

export default SettlementBalanceSummary;
