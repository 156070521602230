import React from "react";
import { PrimaryButton } from "components/Button";

export const Button = ({ children, ...props }) => (
  <PrimaryButton {...props}>{children}</PrimaryButton>
);

export { default as CancelButton } from "./CancelButton.js";
export { default as CloseButton } from "./CloseButton.js";
export { default as DeleteButton } from "./DeleteButton.js";
export { default as DeleteCancelButtonGroup } from "./DeleteCancelButtonGroup.js";
export { default as SubmitButton } from "./SubmitButton.js";
export { default as SubmitCancelButtonGroup } from "./SubmitCancelButtonGroup.js";
