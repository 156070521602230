import React, { useState } from "react";
import * as R from "ramda";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useDropzone } from "react-dropzone";
import { useModal, ModalFooter, ModalErrors } from "components/Modal";
import { ButtonIconWrapper, IconButton, OutlineButton } from "components/Button";
import { PrimaryButton, TextLinkButton, ButtonSpinner } from "components/Button";
import { UploadIcon } from "components/Icons";
import { noop, isNilOrEmpty } from "utils";

const STATUS = {
  PRE_INIT: "PRE_INIT",
  INITIALIZED: "INITIALIZED",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const DropContainer = styled.div`
  padding: 3em;
  cursor: pointer;
  border-width: 2px;
  border-style: dashed;
  border-color: ${(props) => getColor(props)};
`;

export const useUploadFileModal = () => {
  const [Modal, openModal, closeModal] = useModal();
  const [state, setState] = useState({ status: STATUS.PRE_INIT });

  const UploadFileModal = ({ onSuccess, submitAction }) => {
    const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
      useDropzone({ accept: ["image/*", ".pdf"] });

    const handleSubmit = async () => {
      if (R.isEmpty(acceptedFiles)) {
        return;
      }
      setState((state) => ({ ...state, status: STATUS.INITIALIZED }));

      try {
        await submitAction(acceptedFiles[0]);
        setState((state) => ({
          ...state,
          status: STATUS.SUCCESS,
          error: null,
        }));
        closeModal();
        onSuccess();
      } catch (error) {
        setState((state) => ({
          ...state,
          status: STATUS.ERROR,
          error,
        }));
      }
    };

    const cancelButton =
      state.status === STATUS.INITIALIZED ? (
        <TextLinkButton data-testid="upload-cancel-button" disabled={true} onClick={noop}>
          Cancel
        </TextLinkButton>
      ) : (
        <TextLinkButton data-testid="upload-cancel-button" onClick={closeModal}>
          Cancel
        </TextLinkButton>
      );

    const actionButton =
      state.status === STATUS.INITIALIZED ? (
        <PrimaryButton data-testid="upload-action-button" disabled={true} onClick={noop}>
          Upload
          <ButtonSpinner />
        </PrimaryButton>
      ) : (
        <PrimaryButton
          data-testid="upload-action-button"
          disabled={isNilOrEmpty(acceptedFiles)}
          type="submit"
          onClick={handleSubmit}
        >
          Upload
          <ButtonIconWrapper>
            <UploadIcon size="1rem" />
          </ButtonIconWrapper>
        </PrimaryButton>
      );

    const errorFooter = state.status === STATUS.ERROR ? <ModalErrors errors={state.error} /> : null;

    return (
      <Modal titleText="Upload File">
        <DropContainer
          data-testid="drop-container"
          multiple={false}
          {...getRootProps({
            isDragActive,
            isDragAccept,
            isDragReject,
          })}
        >
          <input {...getInputProps()} />
          <div>Drop a file or click here</div>
          {acceptedFiles.map((f) => (
            <div key={f.path} data-file="accepted">
              {f.path} - {f.size} bytes
            </div>
          ))}
        </DropContainer>
        <ModalFooter>
          {cancelButton}
          {actionButton}
        </ModalFooter>
        {errorFooter}
      </Modal>
    );
  };

  const UploadButton = ({ compact = false }) => {
    return compact ? (
      <IconButton data-testid="upload-file-button" onClick={openModal}>
        <UploadIcon size="1rem" />
      </IconButton>
    ) : (
      <OutlineButton data-testid="upload-file-button" onClick={openModal}>
        Upload
        <ButtonIconWrapper>
          <UploadIcon size="1rem" />
        </ButtonIconWrapper>
      </OutlineButton>
    );
  };

  return [UploadFileModal, UploadButton];
};
