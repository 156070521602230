// NOTE: The names of most exported objects exactly match the equivalent constant strings in the
// Go services code -- DO NOT RENAME THEM!
//
// This helps to ensure this file, and any usages of these constants in our own code, appear in
// full-code searches for those strings, so that Tools isn't excluded from critical updates that
// modify the underlying functionality.

/**
 * @typedef {Object} BalanceTransferIdentifier
 * @property {string} balanceTransferId Balance transfer TUID.
 * @property {string} integrator The publisher that the balance transfer belongs to.
 */

/**
 * Balance transfer status codes.
 * @enum {string}
 */
export const BalanceTransferApiStatusCode = Object.freeze({
  StatusExecuting: "EXECUTING",
  StatusExecuted: "EXECUTED",
  StatusFailed: "FAILED",
});

/**
 * Balance transfer status codes representing a "pending" status.
 * @type {Set<BalanceTransferApiStatusCode>}
 */
export const BalanceTransferApiPendingStatusCodes = new Set([BalanceTransferApiStatusCode.StatusExecuting]);

/**
 * Balance transfer status codes representing a "success" status.
 * @type {Set<BalanceTransferApiStatusCode>}
 */
export const BalanceTransferApiSuccessStatusCodes = new Set([BalanceTransferApiStatusCode.StatusExecuted]);

/**
 * Balance transfer status codes representing a "failed" status.
 * @type {Set<BalanceTransferApiStatusCode>}
 */
export const BalanceTransferApiFailedStatusCodes = new Set([BalanceTransferApiStatusCode.StatusFailed]);

/**
 * Balance transfer status codes representing a "canceled" status.
 * @type {Set<BalanceTransferApiStatusCode>}
 */
export const BalanceTransferApiCanceledStatusCodes = new Set();

/**
 * Transfer of funds between Tilia wallets that doesn't imply payment processing.
 * @typedef {Object} BalanceTransferApiBalanceTransfer
 * @property {string} account_id Primary source or destination account ID.
 * @property {string} balance_transfer_type Type of the balance transfer.
 * @property {string} balance_transfer_id Balance transfer TUID.
 * @property {string} invoice_id Underlying invoice ID that executed the transfer of funds.
 * @property {string} reference_type Type of the object associated with the balance transfer, may be customer-generated.
 * @property {string} reference_id ID of the object associated with the balance transfer, may be customer-generated.
 * @property {string} status Current status of the balance transfer.
 * @property {string} [metadata] JSON string of arbitrary metadata.
 * @property {string} failure_reason String of error codes indicating the reason(s) the balance transfer failed.
 */
