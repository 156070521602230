import React from "react";
import styled from "styled-components/macro";
import { theme, Desktop, Mobile } from "../../styles";
import { Search } from "../Search";
import { AgentMenu } from "../AgentMenu";

const Container = styled.div`
  background: ${theme("colors.appHeaderBg")};
  min-height: ${theme("pageHeaderHeight")};
  border-bottom: 1px solid ${theme("colors.panelBorder")};
  box-shadow: 1px 2px 4px 0 ${theme("colors.appHeaderBoxShadow")};
  color: ${theme("colors.pageFg")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const MobileContainer = styled(Container)`
  top: 60px;
`;

export const AppHeader = () => (
  <>
    <Desktop>
      <Container>
        <Search />
        <AgentMenu />
      </Container>
    </Desktop>
    <Mobile>
      <MobileContainer>
        <Search />
        <AgentMenu />
      </MobileContainer>
    </Mobile>
  </>
);
