import React from "react";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { Link as RRLink } from "react-router-dom";
import { theme, ifProp, prop } from "styles";
import { OpenInNewIcon } from "components/Icons";
import { outlineButton } from "components/Button";

export const NavLink = styled(RRLink)`
  color: ${theme("colors.fontInverse")};
  display: inline-block;
  text-decoration: none;
`;

const linkStyle = css`
  ${ifProp(
    "highlight",
    css`
      color: ${theme("colors.highlight.color")};
      background: ${theme("colors.highlight.bg")};
      padding: 0 3px;
      margin: 0 -3px;
      border-radius: 2px;
    `,
    css`
      color: ${theme("colors.link.base")};
    `
  )}
  display: ${prop("display", "flex")};
  align-items: baseline;
  text-decoration: none;
`;

const OpenIcon = styled(OpenInNewIcon).attrs({ size: 0.5 })`
  margin-left: 4px;
`;

export const ExternalLink = ({ children, newTab, ...props }) => {
  let newTabIcon = null;
  if (newTab) {
    props.target = "_blank";
    newTabIcon = <OpenIcon />;
  }

  return (
    <a {...props} css={linkStyle}>
      {children}
      {newTabIcon}
    </a>
  );
};

export const Link = ({ newTab, children, ...props }) => {
  let newTabIcon = null;
  if (newTab) {
    props.target = "_blank";
    newTabIcon = <OpenIcon />;
  }

  return (
    <RRLink {...props} css={linkStyle}>
      {children}
      {newTabIcon}
    </RRLink>
  );
};

export const ButtonAnchor = styled.a`
  ${outlineButton};
  min-height: 24px;
  text-decoration: none;
  color: currentColor;
`;

export const ButtonLink = styled(RRLink)`
  ${outlineButton};
  min-height: 24px;
  text-decoration: none;
  color: currentColor;
`;
