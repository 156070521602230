import React from "react";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { DataRow } from "components/DataRow";
import { Mobile, theme } from "styles";
import { formatDateTime, isNilOrEmpty } from "utils";

export const row = css`
  padding 8px 0;
  border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
`;

const MobilePaymentMethod = ({ data }) => {
  return <DataRow title={data.description} data={data.symbol_amount} />;
};

const MobileLineItem = ({ data }) => {
  return <DataRow title={data.note} data={data.symbol_amount} />;
};

const MobileTransaction = ({ data }) => {
  return (
    <div
      css={`
        margin-bottom: 24px;
      `}
    >
      <DataRow title="Id" data={data.invoice_id} />
      <DataRow title="Date" data={formatDateTime(data.created)} />
      <DataRow title="Status" data={data.state} />
      {data.line_items.map((item) => (
        <MobileLineItem key={item.line_item_id} data={item} />
      ))}
      <DataRow title="Total Amount" data={data.symbol_total} />
      {data.payment_methods.map((pm) => (
        <MobilePaymentMethod key={pm.payment_method_id} data={pm} />
      ))}
    </div>
  );
};

export const MobileTransactions = ({ data }) => (
  <Mobile>
    {isNilOrEmpty(data) ? (
      <div css={row}>No Data</div>
    ) : (
      data.map((t) => <MobileTransaction key={t.invoice_id} data={t} />)
    )}
  </Mobile>
);
