import { Select } from "components/Forms/Select";
import { useIntegrators } from "context/integratorContext";
import { isNilOrEmpty } from "utils";
import { toTitleCase } from "utils/format";
import { FilterSelectFrame } from "components/Filter/inputs/FilterSelect";

export default function IntegratorSelect({
  allLabel = "All",
  includeAll = false,
  onChange,
  selectedOptions = undefined,
  value,
}) {
  const { integrators } = useIntegrators();

  if (isNilOrEmpty(integrators) || Object.keys(integrators).length === 1) {
    return null;
  }

  let options = Object.keys(integrators);

  if (selectedOptions) {
    options = options.filter((integrator) => !selectedOptions.includes(integrator));
  }

  options = options.sort().map((integrator) => ({
    value: integrator,
    label: toTitleCase(integrators[integrator].displayName),
  }));
  if (includeAll) {
    options = [{ value: "", label: allLabel }].concat(options);
  }

  let current = null;
  if (!selectedOptions) {
    current = options.find((o) => o.value === value);
  }

  return (
    <div data-testid="select-integrator">
      <FilterSelectFrame>
        <Select
          value={current}
          label="Publisher"
          name="integrator"
          onChange={(_name, integrator) => onChange(integrator)}
          options={options}
        />
      </FilterSelectFrame>
    </div>
  );
}
