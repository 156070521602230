import React from "react";
import { AlertContext } from "context/alertContext";
import {
  Alert as ChakraAlert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Flex,
} from "@chakra-ui/react";

export const Alert = ({
  alertIcon,
  alertTitle,
  alertDescription,
  children,
  displayButton,
  ...props
}) => {
  return (
    <AlertContext.Consumer>
      {({ displayAlert, toggleDisplay, message, alertStatus, alertDismiss }) => {
        if (alertDismiss) {
          setTimeout(() => toggleDisplay(false), 2500);
        }

        return (
          displayAlert && (
            <ChakraAlert
              color="initial"
              position="absolute"
              boxSizing="border-box"
              top="0"
              height="60px"
              zIndex={2}
              status={alertStatus}
              justifyContent={"space-between"}
              {...props}
            >
              <Flex alignItems="center">
                <>{alertIcon && <AlertIcon />}</>
                <>
                  {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
                  {message && <AlertDescription>{message}</AlertDescription>}
                  {children}
                </>
              </Flex>
              {displayButton && (
                <CloseButton
                  backgroundColor="inherit"
                  border="none"
                  onClick={() => toggleDisplay(false)}
                />
              )}
            </ChakraAlert>
          )
        );
      }}
    </AlertContext.Consumer>
  );
};
