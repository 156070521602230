import { debug } from "utils";
import buildTiliaApiResponse from "../buildTiliaApiResponse";
import { METHODS, mapProxyFetch, serviceMap } from "../utils";

async function addAccountTag({ tag, agent }) {
  debug(agent, "[addAccountTag]", { tag });
  const accountsProxy = mapProxyFetch(serviceMap.ACCOUNTS_API);
  const response = await accountsProxy(agent, `/v1/tag`, METHODS.POST, {
    resource_type: "lindenaccounts.accounts_v2.account_id",
    account_id: tag.accountId,
    resource_id: tag.accountId,
    namespace: tag.namespace,
    tag: tag.label,
  });
  const apiResponse = await buildTiliaApiResponse(response);
  if (![200, 201].includes(apiResponse.status)) throw apiResponse.errors;
  return apiResponse.payload;
}

export default addAccountTag;
