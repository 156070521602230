import { forwardRef } from "react";
import { useFormikContext } from "formik";
import SubmitButton from "./SubmitButton.js";

const DeleteButton = forwardRef(function (
  { message = "Delete", loadingMessage = "Deleting…", ...props },
  ref
) {
  const { submitForm } = useFormikContext();

  // As a destructive action, the DeleteButton sets the button type to "button" instead
  // of "submit" to defeat the auto-submit behavior when pressing enter in a text field;
  // for safety, the button must be explicitly clicked.
  const handleClick = () => {
    submitForm();
  };

  return (
    <SubmitButton
      ref={ref}
      message={message}
      loadingMessage={loadingMessage}
      colorScheme="red"
      type="button"
      onClick={handleClick}
      {...props}
    />
  );
});
DeleteButton.displayName = "DeleteButton";

export default DeleteButton;
