import React from "react";
import styled from "styled-components";
import { isNilOrEmpty } from "utils";
import { Tbody, Td, Tr } from "../Table";
import { Money } from "../Money";
import RefundReason from "./RefundReason";

const SkinnyTd = styled(Td)`
  padding-top: 0px;
  height: 16px;
`;

const SuccessfulRefundsList = ({ refunds }) => {
  if (isNilOrEmpty(refunds)) {
    return null;
  }
  return (
    <>
      {refunds.map((refund) => {
        return (
          <Tbody key={refund.refundInvoiceId}>
            <Tr>
              <SkinnyTd>Refund Invoice ID: </SkinnyTd>
              <SkinnyTd>{refund.refundInvoiceId}</SkinnyTd>
            </Tr>
            <Tr>
              <SkinnyTd>Amount: </SkinnyTd>
              <SkinnyTd>
                {refund.paymentBreakdown?.map(({ amount, currency, paymentDisplay }) => (
                  <p key={paymentDisplay}>
                    <Money value={amount} currency={currency} /> to {paymentDisplay}
                  </p>
                ))}
              </SkinnyTd>
            </Tr>
            {refund.reason && (
              <Tr>
                <SkinnyTd>Reason:</SkinnyTd>
                <SkinnyTd>
                  <RefundReason reason={refund.reason} />
                </SkinnyTd>
              </Tr>
            )}
            {refund.note && (
              <Tr>
                <SkinnyTd>Note: </SkinnyTd>
                <SkinnyTd>{refund.note}</SkinnyTd>
              </Tr>
            )}
          </Tbody>
        );
      })}
    </>
  );
};

export default SuccessfulRefundsList;
