import { exists } from "utils";

export const anyFailed = (results = []) =>
  exists(results) && results.some((result) => result.status === "rejected");

export const mapToErrors = (results = []) => {
  if (!exists(results)) return [];
  return results
    .filter((result) => result.status === "rejected")
    .map((r) => ({ permission: r.reason?.permission, message: r.reason?.errors }));
};
