import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { usePageHeight } from "components/PageHeight";

const PageHeightWrapper = (props) => {
  const { boundingRef, top } = usePageHeight();
  return (
    <div
      ref={boundingRef}
      css={`
        display: flex;
        flex-direction: column;
        height: calc(100vh - ${top}px - 2em);
        min-height: 600px;
      `}
      {...props}
    />
  );
};

export const KYCIframe = ({ url }) => (
  <PageHeightWrapper>
    <iframe
      data-testid="idmind-iframe"
      title="idmind form"
      css={`
        width: 100%;
        height: 100%;
        border: 1px solid #eee;
      `}
      src={url}
    />
  </PageHeightWrapper>
);
