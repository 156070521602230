import { InvoicingApiInvoiceType } from "../../invoicingApi/index.js";

/**
 * Deposit status codes.
 * @enum {string}
 */
export const DepositStatusCode = Object.freeze({
  OPEN: "OPEN",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
  USER_REVERSED: "USER_REVERSED",
  SYSTEM_CANCELED: "SYSTEM_CANCELED",
});

/**
 * Deposit transaction invoice types.
 * @type {Set<InvoicingApiInvoiceType>}
 */
export const DepositInvoiceTypes = new Set([InvoicingApiInvoiceType.DepositIT]);
