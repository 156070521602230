import { useParams } from "react-router-dom";
import LoadingTbody from "components/Table/LoadingTbody";
import { formatDateTime } from "utils";
import { NullableMoney } from "components/Money";
import { Box, Flex, Heading, Table, TableContainer, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { ExternalLink } from "components/Link";
import { usePaymentMethodPayments } from "./usePaymentMethodPayments";
import { useTitle } from "context/titleContext";

const PaymentRow = ({ payment }) => {
  let pspLink = payment.provider || "--";
  if (payment.externalLink) {
    pspLink = (
      <ExternalLink href={payment.externalLink} newTab="true">
        {payment.provider} / {payment.providerTransactionId}
      </ExternalLink>
    );
  } else if (payment.providerTransactionId) {
    pspLink = `${payment.provider} / ${payment.providerTransactionId}`;
  }

  return (
    <Tr>
      <Td>{formatDateTime(payment.created)}</Td>
      <Td>{payment.state}</Td>
      <Td>{payment.paymentId}</Td>
      <Td>{payment.invoiceId}</Td>
      <Td>{pspLink}</Td>
      <Td isNumeric={true}>
        <NullableMoney value={payment.requestAmount} currency={payment.requestCurrency} />
      </Td>
      <Td isNumeric={true}>
        <NullableMoney value={payment.processingAmount} currency={payment.processingCurrency} />
      </Td>
      <Td isNumeric={true}>
        <NullableMoney value={payment.receivedAmount} currency={payment.receivedCurrency} />
      </Td>
    </Tr>
  );
};

const PaymentMethodHeader = () => {
  const { paymentMethodId } = useParams();
  useTitle({ sub2: paymentMethodId });
  return (
    <Flex as="header" alignItems="center" gap={3}>
      <Box>
        <Heading as="h3" size="md">
          {paymentMethodId}
        </Heading>
      </Box>
    </Flex>
  );
};

const PaymentTable = () => {
  const { accountId, paymentMethodId } = useParams();
  const { data, loading } = usePaymentMethodPayments({
    accountId,
    paymentMethodId,
    // filter: filter.gqlFilterParams,
    // ...paginationOptions,
  });

  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Created Date</Th>
            <Th>State</Th>
            <Th>Payment ID</Th>
            <Th>Invoice ID</Th>
            <Th>Provider ID</Th>
            <Th isNumeric={true}>Requested</Th>
            <Th isNumeric={true}>Processing</Th>
            <Th isNumeric={true}>Received</Th>
          </Tr>
        </Thead>
        <LoadingTbody columns={8} loading={loading}>
          {data?.payments?.map((edge) => (
            <PaymentRow key={edge.paymentId} payment={edge} />
          ))}
        </LoadingTbody>
      </Table>
    </TableContainer>
  );
};

export const PaymentMethodDetails = () => {
  return (
    <>
      <PaymentMethodHeader />
      <PaymentTable />
    </>
  );
};
