import React from "react";
import styled from "styled-components";
import { theme } from "styles";

const NoDataContainer = styled.div`
  align-items: center;
  border: solid 1px ${theme("colors.dataTable.rowBorder")};
  border-radius: 4px;
  color: grey;
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  h1 {
    font-size: 4rem;
    margin: 0;
  }
  p {
    margin-top: 0px;
    margin-bottom: 30px;
  }
`;

export const NoData = ({ message }) => {
  return (
    <NoDataContainer data-testid="grid-no-data">
      <h1>No Data</h1>
      {message && <div>{message}</div>}
    </NoDataContainer>
  );
};
