import { gql, useQuery } from "@apollo/client";
import { Alert, AlertIcon, Box, Heading, SimpleGrid } from "@chakra-ui/react";
import EmptyPage from "components/Empty/Empty";
import { useFilter } from "components/Filter/FilterContext";
import { LineChartIcon } from "components/Icons";
import { FullPageSpinner } from "components/Spinner";
import ReportSummaryCard from "containers/Pages/Home/ReportSummaryCard";
import useAgentIntegrator from "context/useAgentIntegrator";
import useAgentIsTiliaAgent from "context/useAgentIsTiliaAgent";

export const EMPTY_STATE = "View 30 day charts for publishers.";
export const NO_REPORTS_MESSAGE = "This chart is empty for now.";
export const NO_REPORTS_MESSAGE_SUBHEADER =
  "There is no data yet and it will show up as soon as it’s available.";

const REPORT_MAP = {
  payoutDailyReports: {
    title: "Payouts",
    description:
      "Daily total funds transferred from a user's wallet to a supported external payment method.",
  },
  tokenPurchaseDailyReports: {
    title: "Token Purchase",
    description: "USD value of tokens purchased (all token purchases happen in USD)",
  },
  userPaymentsDailyReports: {
    title: "User Purchase - Fiat",
    description: "Daily total value of all USD payments.",
  },
  tokenSpendDailyReports: {
    title: "User Purchase - Virtual",
    description: "USD equivalent value of all purchases made with tokens",
  },
  walletBalance: {
    admin: true,
    aggregate: false,
    title: "Stored Value Balance",
    description:
      "Total end user liability; the sum of USD across convertible/earned token wallets and USD wallets.",
  },
  facwalBalance: {
    aggregate: false,
    title: "Facilitator Wallet Balance",
    description:
      "Holds USD funds that are used in Publisher-funded transactions, including POBO transactions",
  },
  virtualFacwalBalance: {
    aggregate: false,
    title: "Virtual Facilitator Wallet Balance",
    description:
      "Holds virtual funds that are used in Publisher-funded transactions, including POBO transactions",
  },
};

export default function DashboardDisplay() {
  const GET_DASHBOARD = gql`
    query getDashboard($integratorId: String!, $filter: PublisherSummaryFilterInput) {
      integrator(id: $integratorId) {
        payoutDailyReports: dailyReports(invoiceTypeGroup: PAYOUT, filter: $filter) {
          ...ReportFields
        }
        tokenPurchaseDailyReports: dailyReports(invoiceTypeGroup: TOKEN_PURCHASE, filter: $filter) {
          ...ReportFields
        }
        userPaymentsDailyReports: dailyReports(invoiceTypeGroup: USER_PAYMENTS, filter: $filter) {
          ...ReportFields
        }
        tokenSpendDailyReports: dailyReports(invoiceTypeGroup: TOKEN_SPEND, filter: $filter) {
          ...ReportFields
        }
        walletBalance: dailyReports(invoiceTypeGroup: WALLET_BALANCE, filter: $filter) {
          ...ReportFields
        }
        facwalBalance: dailyReports(invoiceTypeGroup: FACWAL_BALANCE, filter: $filter) {
          ...ReportFields
        }
        virtualFacwalBalance: dailyReports(
          invoiceTypeGroup: VIRTUAL_FACWAL_BALANCE
          filter: $filter
        ) {
          ...ReportFields
        }
      }
    }
    fragment ReportFields on PublisherInvoiceSummary {
      publishDate
      invoiceCount
      amount
      usdAmount
      currency
    }
  `;

  const isTiliaAgent = useAgentIsTiliaAgent();
  const contextIntegrator = useAgentIntegrator();
  const { gqlFilterParams } = useFilter();

  const { loading, data, error } = useQuery(GET_DASHBOARD, {
    skip: isTiliaAgent && !gqlFilterParams?.integrator,
    variables: {
      integratorId: contextIntegrator.integratorId,
      filter: gqlFilterParams,
    },
  });
  if (error) {
    return (
      <Alert maxWidth="container.md" status="error">
        <AlertIcon />
        Something went wrong.
      </Alert>
    );
  }
  if (!loading && !data) {
    return (
      <Heading size="sm" as="h3">
        {EMPTY_STATE}
      </Heading>
    );
  }

  if (loading || !data) {
    return <FullPageSpinner />;
  }
  const { integrator } = data;

  const reports = Object.keys(REPORT_MAP).filter((r) => {
    if (integrator[r].length < 1) {
      return false;
    }
    if (REPORT_MAP[r].admin && !isTiliaAgent) {
      return false;
    }
    return true;
  });

  const columns = {
    base: 1,
    lg: 2,
    xl: 3,
    "2xl": 4,
  };
  switch (reports.length) {
    case 1:
      columns["lg"] = 1;
      columns["xl"] = 1;
      columns["2xl"] = 1;
      break;
    case 2:
      columns["xl"] = 2;
      columns["2xl"] = 2;
      break;
    case 3:
      columns["xl"] = 3;
      columns["2xl"] = 3;
      break;
    default:
  }

  if (reports.length === 0) {
    return (
      <EmptyPage
        Icon={LineChartIcon}
        title={NO_REPORTS_MESSAGE}
        subheader={NO_REPORTS_MESSAGE_SUBHEADER}
      />
    );
  }
  return (
    <Box maxW={"1600px"}>
      <SimpleGrid columns={columns} spacing={8}>
        {reports.map((r) => (
          <ReportSummaryCard
            key={r}
            data={integrator[r]}
            title={REPORT_MAP[r].title}
            description={REPORT_MAP[r].description}
            aggregate={REPORT_MAP[r].aggregate}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
}
