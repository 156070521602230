import { Badge, Tooltip } from "@chakra-ui/react";
import useAgentIsTiliaAgent from "context/useAgentIsTiliaAgent";

// TODO: These label functions are a localization concern
const issuerLabel = (issuer) => {
  switch (issuer) {
    case "PUBLISHER":
      return "publisher";
    case "TILIA":
      return "Tilia";
    default:
      return issuer;
  }
};
const capabilityLabel = (capability) => {
  switch (capability) {
    case "LOGIN":
      return "Account Blocked";
    case "TRANSACTIONS":
      return "Txn Blocked";
    case "PAYOUTS":
      return "Payout Blocked";
    default:
      return capability;
  }
};
const reasonLabel = (reason) => {
  switch (reason) {
    case "ACCOUNT_CLOSE":
      return "Account Closed";
    case "COMPLIANCE":
      return "Compliance";
    case "MERGE_ACCOUNT":
      return "Merged Account";
    default:
      return reason;
  }
};

const AccountBlockIssuerName = ({ accountBlock }) => {
  const isTiliaAgent = useAgentIsTiliaAgent();
  if (!accountBlock.issuerAccount) {
    return null; // agent doesn't have permission to see the name
  }
  if (!isTiliaAgent && accountBlock.issuer === "TILIA") {
    return null; // only show Tilia agent usernames to other Tilia agents
  }
  return ` (${accountBlock.issuerAccount.username})`;
};

const AccountBlockTooltipLabel = ({ accountBlock }) => {
  return (
    <>
      {reasonLabel(accountBlock.reason)}
      <br />
      Blocked by {issuerLabel(accountBlock.issuer)}
      <AccountBlockIssuerName accountBlock={accountBlock} />
      {/* TODO: add block date when API supports it */}
    </>
  );
};

const AccountBlockTag = ({ accountBlock }) => {
  return (
    <Tooltip
      hasArrow
      placement="top"
      label={<AccountBlockTooltipLabel accountBlock={accountBlock} />}
    >
      <Badge colorScheme="red" variant="solid">
        {capabilityLabel(accountBlock.capability)}
      </Badge>
    </Tooltip>
  );
};

export default AccountBlockTag;
