import * as R from "ramda";
import { createAction } from "redux-actions";
import { debugReducer } from "../utils"; //eslint-disable-line no-unused-vars
import * as permissions from "context/permissions";
import * as paymentsApi from "api/payments";
import { STATUS } from "utils";
export { STATUS } from "utils";

export const getPaymentMethods = async (dispatch, accountId, user) => {
  if (!user.hasPermissions([permissions.READ_PAYMENT_METHODS],permissions.PAYMENTS_RESOURCE)) {
    dispatch(actions.getPaymentMethodsPermissionsFail());
    return;
  }
  dispatch(actions.getPaymentMethodsInit(accountId));
  try {
    const res = await paymentsApi.getPaymentMethods(accountId, user);
    dispatch(actions.getPaymentMethodsSuccess(res.payload));
  } catch (err) {
    dispatch(actions.getPaymentMethodsError(err));
  }
};

export const actions = {
  getPaymentMethodsInit: createAction("@tools/account/getPaymentMethodsInit"),
  getPaymentMethodsSuccess: createAction("@tools/account/getPaymentMethodsSuccess"),
  getPaymentMethodsError: createAction("@tools/account/getPaymentMethodsError"),
  getPaymentMethodsPermissionsFail: createAction("@tools/account/getPaymentMethodsPermissionsFail")
};

export const initialState = {
  paymentMethods: {
    accountId: null,
    data: null,
    error: null,
    permissions: true,
    status: STATUS.PRE_INIT
  }
};

export const handlers = {
  [actions.getPaymentMethodsInit]: debugReducer((state, { payload }) =>
    R.pipe(
      R.assocPath(["paymentMethods", "accountId"], payload),
      R.assocPath(["paymentMethods", "error"], null),
      R.assocPath(["paymentMethods", "status"], STATUS.INITIALIZED)
    )(state)
  ),
  [actions.getPaymentMethodsSuccess]: (state, { payload }) =>
    R.pipe(
      R.assocPath(["paymentMethods", "data"], payload),
      R.assocPath(["paymentMethods", "status"], STATUS.SUCCESS),
      R.assocPath(["paymentMethods", "error"], null)
    )(state),
  [actions.getPaymentMethodsError]: debugReducer((state, { payload }) =>
    R.pipe(
      R.assocPath(["paymentMethods", "error"], payload),
      R.assocPath(["paymentMethods", "status"], STATUS.ERROR),
      R.assocPath(["paymentMethods", "data"], null)
    )(state)
  ),
  [actions.getPaymentMethodsPermissionsFail]: debugReducer(state =>
    R.pipe(
      R.assocPath(["paymentMethods", "permissions"], false),
      R.assocPath(["paymentMethods", "status"], STATUS.ERROR),
      R.assocPath(["paymentMethods", "data"], null),
      R.assocPath(["paymentMethods", "error"], null)
    )(state)
  )
};
