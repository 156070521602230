import React from "react";
import { useAccount } from "context/accountContext";
import * as R from "ramda";
import { AccountLink } from "components/Account";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { isNilOrEmpty } from "utils";
import { capitalizeFirstLetter } from "utils/format";
import { entityAgentTypes } from "context/accountContext/entity";
import { kycStatus } from "context/accountContext/kyc";
import { PanelContent, PanelTitle } from "components/Panel";
import { Table, Thead, Th, Tbody, Tr, Td } from "components/Table";
import { useAddEditAgentModal } from "./AddEditAgentModal";

const formatData = (data) => (!isNilOrEmpty(data) ? data : "--");

const AgentRow = ({ agent, entityId, onChange, integrator }) => {
  const [EditModal, EditModalButton] = useAddEditAgentModal({ edit: true });
  const { updateEntityAgent } = useAccount();

  const updateAgent = (values) =>
    updateEntityAgent(
      {
        entity_id: entityId,
        ...agent,
        ...values,
      },
      integrator
    );

  return (
    <Tr pull>
      <Td>{agent.agent_name}</Td>
      <Td>
        {R.join(
          ", ",
          R.map((role) => entityAgentTypes[role], agent.roles)
        )}
      </Td>
      <Td>{capitalizeFirstLetter(agent.status)}</Td>
      <Td>{formatData(kycStatus[agent.kyc_status])}</Td>
      <Td>
        {isNilOrEmpty(agent.account_id) ? (
          ""
        ) : (
          <AccountLink account={agent}>{agent.account_id}</AccountLink>
        )}
      </Td>
      <Td rightAlign>
        <EditModalButton label={"Edit"} />
      </Td>
      <EditModal onSubmit={updateAgent} onSuccess={onChange} agentInfo={agent} />
    </Tr>
  );
};

export const AgentDetails = ({ data = {}, onChange }) => {
  const { agents, entity_id, integrator } = data;
  const [AddModal, AddModalButton] = useAddEditAgentModal();
  const { createEntityAgent } = useAccount();

  const createAgent = (values) =>
    createEntityAgent(
      {
        entity_id,
        ...values,
      },
      integrator
    );

  return (
    <>
      <PanelTitle>
        <span>Company Agents</span>
        <span>
          <AddModalButton />
          <AddModal onSubmit={createAgent} onSuccess={onChange} />
        </span>
      </PanelTitle>
      <PanelContent>
        <Table>
          <Thead bordered>
            <Tr pull>
              <Th>Person</Th>
              <Th>Role</Th>
              <Th>Agent Status</Th>
              <Th>KYC Status</Th>
              <Th>Account ID</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody bordered>
            {isNilOrEmpty(agents) ? (
              <Tr colSpan={5}>
                <Td> No Agents Found</Td>
              </Tr>
            ) : (
              agents.map((agent, index) => (
                <AgentRow
                  entityId={entity_id}
                  integrator={integrator}
                  onChange={onChange}
                  agent={agent}
                  key={index}
                />
              ))
            )}
          </Tbody>
        </Table>
      </PanelContent>
    </>
  );
};
