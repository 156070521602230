import { EscrowPurchaseStatusCode, TransactionType } from "@tilia-tools/core/transaction";
import { TransactionHeader } from "../Layout";
import EscrowPurchaseStatusCodeName from "./EscrowPurchaseStatusCodeName";

const statusColors = {
  [EscrowPurchaseStatusCode.OPEN]: "orange",
  [EscrowPurchaseStatusCode.IN_ESCROW]: "orange",
  [EscrowPurchaseStatusCode.ESCROW_FAILED]: "red",
  [EscrowPurchaseStatusCode.COMMIT_REQUESTED]: "orange",
  [EscrowPurchaseStatusCode.SUCCESS]: "green",
  [EscrowPurchaseStatusCode.FAILED]: "red",
  [EscrowPurchaseStatusCode.CANCEL_REQUESTED]: "orange",
  [EscrowPurchaseStatusCode.CANCELED]: "gray",
  [EscrowPurchaseStatusCode.CANCEL_FAILED]: "red",
  [EscrowPurchaseStatusCode.SYSTEM_CANCEL_REQUESTED]: "orange",
  [EscrowPurchaseStatusCode.SYSTEM_CANCELED]: "gray",
  [EscrowPurchaseStatusCode.SYSTEM_CANCEL_FAILED]: "red",
  [EscrowPurchaseStatusCode.CANCEL_INVOICE_TRANSFER_FAILED]: "red",
};

function EscrowPurchaseHeader({ escrowPurchase }) {
  return (
    <TransactionHeader
      transactionType={TransactionType.ESCROW_PURCHASE}
      badge={{
        label: (
          <EscrowPurchaseStatusCodeName
            escrowPurchaseStatusCode={escrowPurchase.escrowPurchaseStatusCode}
          />
        ),
        color: statusColors[escrowPurchase.escrowPurchaseStatusCode],
      }}
      copyableId={escrowPurchase.escrowId}
      date={escrowPurchase.date}
      updatedDate={escrowPurchase.updatedDate}
    />
  );
}

export default EscrowPurchaseHeader;
