import { debug } from "utils";
import * as R from "ramda";
import { isNilOrEmpty } from "utils";
import { METHODS, assertIntegrator, assertOk, mapProxyFetch, serviceMap } from "./utils";

const fraudProxy = mapProxyFetch(serviceMap.FRAUD_OPS_API);

const parseEntityMap = (entityMap) => {
  if (!entityMap) return entityMap;
  Object.entries(entityMap).map(([k, e]) => {
    try {
      entityMap[k].entity_data = JSON.parse(e.entity_data);
    } catch (e) {
      entityMap[k].raw_data = e.entity_data;
      entityMap[k].error_info = e;
    }
    return true;
  });
  return entityMap;
};

export const getAccountWatchlist = async (accountId, ctx) => {
  debug(ctx, "[getAccountWatchlist]", accountId);
  assertIntegrator(ctx);
  const response = await fraudProxy(ctx, `/v1/watchlist/${accountId}?limit=1000`);
  await assertOk(response);
  let resp = await response.json();
  resp.payload.entity_map = parseEntityMap(resp.payload.entity_map);
  return resp;
};

export const submitManualReview = async (accountId, body, ctx) => {
  debug(ctx, "[submitManualReview]", accountId);
  assertIntegrator(ctx);
  const response = await fraudProxy(ctx, `/v1/watchlist/${accountId}/status`, METHODS.PATCH, body);
  await assertOk(response);
  return response.json();
};

// no header required
export const fetchAccountWatchlistData = async (queryParameters, ctx) => {
  debug(ctx, "[fetchAccountWatchlistData]", queryParameters);
  const createQueryString = R.pipe(
    R.filter((x) => !isNilOrEmpty(x)),
    R.toPairs,
    R.map(R.join("=")),
    R.join("&"),
  );
  const queryString = createQueryString(queryParameters);
  const response = await fraudProxy(ctx, `/v1/watchlists?${queryString}`);
  await assertOk(response);
  return response.json();
};

// no header required
export const queryWatchlist = async (body, ctx) => {
  debug(ctx, "[queryWatchlist]", body);
  const response = await fraudProxy(ctx, `/v1/watchlist/query`, METHODS.POST, body);
  await assertOk(response);

  let resp = await response.json();
  resp.payload.entity_map = parseEntityMap(resp.payload.entity_map);
  return resp;
};
