import * as R from "ramda";
import urlJoin from "url-join";
import { debug, exists, isNilOrEmpty, ResponseError } from "@tilia-tools/utils";

export const serviceMap = {
  ACCOUNTS_API: "accounts-api",
  ACCOUNTS_OPS_API: "accounts-ops",
  EMAIL_BOUNCELIST_API: "email-bouncelist",
  FRAUD_OPS_API: "fraud-ops",
  INVOICING_OPS_API: "invoicing-ops",
  INVOICING_API: "invoicing",
  JPM_BIN_API: "jpm-bin-lookup",
  NONCE_API: "nonce",
  NOTES_API: "notes",
  PAYMENTS_OPS_API: "payments-ops",
  PII_API: "pii",
  PII_OPS_API: "pii-ops",
  SETTLEMENT_API: "settlement",
};

export const METHODS = {
  DELETE: "DELETE",
  GET: "GET",
  PATCH: "PATCH",
  POST: "POST",
  PUT: "PUT",
};

export const generateProxyUri = (baseUrl, route) =>
  `/ui/proxy?url=${encodeURIComponent(urlJoin(baseUrl, route))}`;

const defaultOpts = {
  credentials: "include",
  method: "GET",
  mode: "cors",
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json;charset=UTF-8",
  },
};

export const X_TILIA_INTEGRATOR = "X-Tilia-Integrator";

const genDefaultOptsWithToken = (ctx) => {
  const headers = {
    Authorization: `Bearer ${ctx.jwt}`,
  };

  debug(ctx, "API::INTEGRATOR", ctx.integratorContext?.integrator);
  if (exists(ctx.integratorContext?.integrator)) {
    headers[X_TILIA_INTEGRATOR] = ctx.integratorContext.integrator;
  }
  return R.mergeDeepRight(defaultOpts, { headers });
};

export const generateOpts = (ctx, opts = {}) =>
  R.mergeDeepRight(genDefaultOptsWithToken(ctx), opts);

const generateMapProxyUri = (serviceKey, route) => {
  return `/ui/p/${urlJoin(serviceKey, route)}`;
};

export const mapProxyFetch = (serviceKey) => proxyFetch(serviceKey, generateMapProxyUri);

export const proxyFetch =
  (destination, genUri = generateProxyUri) =>
  async (ctx, path, method = METHODS.GET, payload, overrideOpts = R.identity) => {
    if (isNilOrEmpty(destination)) throw new Error("No destination specified");
    if (isNilOrEmpty(ctx)) throw new Error("Invalid context");
    const dest = genUri(destination, path);
    const opts = overrideOpts(
      generateOpts(ctx, {
        method,
        body: JSON.stringify(payload || undefined),
      }),
    );
    const response = await fetch(dest, opts);
    // fresh token may be in the response
    if (exists(ctx.processResponse)) ctx.processResponse(response);
    return response;
  };

// throws if context does not meet requirements
const assertContext = (ctx = {}, requirements = {}) => {
  debug(ctx, "assertContext", { ctx, requirements });
  if (R.propEq(true, "integrator", requirements)) {
    if (isNilOrEmpty(R.path(["integratorContext", "integrator"], ctx))) {
      throw new Error("Cannot call API without integrator specified");
    }
  }
};

export const assertIntegrator = (ctx) => assertContext(ctx, { integrator: true });

export const assertOk = async (response, oks = []) => {
  if (!response.ok) {
    if (!R.includes(response.status, oks)) {
      throw new ResponseError(
        response.status,
        // loggingMesasge
        response.statusText || `There was an error (${response.status})`,
        // base Error message
        response.statusText || `There was an error (${response.status})`,
      );
    }
  }
};

const getErrorMessage = async (response) => {
  if (response.body) {
    const body = await response.json();
    if (exists(body?.payload?.error)) {
      return body.payload.error;
    }
    if (exists(body?.payload?.errors)) {
      return body.payload.errors;
    }
    if (exists(body?.message)) {
      return body.message;
    }
  }
  // fallback
  return `There was an error (${response.status})`;
};

export const assertOkWithMessage = async (response, oks = []) => {
  if (!response.ok) {
    if (!R.includes(response.status, oks)) {
      throw new Error(await getErrorMessage(response));
    }
  }
};

// assumes the errorMessage is a string, not an object
export const assertNoErrorString = async (response) => {
  if (isNilOrEmpty(response)) throw new Error("Invalid response");
  if (!response.ok) {
    if (!response.body) throw new Error(response.statusText);

    const responseJson = await response.json();
    const errorMessage = R.path(["payload", "errors"], responseJson);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
  }
};

export const generateUnauthenticatedOpts = (opts = {}) => R.mergeDeepRight(defaultOpts, opts);

export class MultiError extends Error {
  constructor(errors = []) {
    super("MultiError");
    this.name = "MultiError";
    this.errors = errors;
  }
}

export const getFilterMapQueryParams = (filterMap) =>
  Object.keys(filterMap).reduce((result, key) => result + `${key}=${filterMap[key]}&`, "");
export const getPagingQueryParams = ({ currentPage, pageSize }) =>
  `offset=${currentPage * pageSize}&limit=${pageSize}`;
export const getSortQueryParams = ({ sortField, sortDir }) =>
  `sort_field=${sortField}&sort=${sortDir}`;

export const getErrorFromResponse = (response) => {
  if (exists(response?.payload?.errors)) return response.payload.errors;
  return isNilOrEmpty(response?.message) ? response.error : response;
};
