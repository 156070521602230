import React from "react";
import normalize from "./normalize";
import { ifProp, theme } from "styled-tools";
import { generateMedia } from "styled-media-query";
import Responsive from "react-responsive";
// eslint-disable-next-line no-unused-vars
import styled, { css, createGlobalStyle, withTheme } from "styled-components/macro";
import { globalSelectPortalStyle } from "components/Forms/Select";

export { useMediaQuery } from "react-responsive";
export { theme, ifProp, prop } from "styled-tools";
export { css, withTheme };
export { LIGHT, DARK, useTheme, ThemeProvider } from "./theme";

export const GlobalStyle = createGlobalStyle`
    ${normalize}
  html {
      height: 100vh;
  }
    body {
      background: ${theme("colors.pageBg")};
      height: 100vh;
      margin: 0;
      line-height: 1.5;
      font-family: Lato, sans-serif;
      font-size: 14px;
      -webkit-font-smoothing: anitaliased;
      -moz-osx-font-smoothing: grayscale;
    }
    #root {
    height: 100%;
  }
    :focus {
      outline: 1px dotted ${theme("colors.focus")};
    }
    h1 {
      font-size: 1.8em;
    }
    [data-reach-menu] {
      border: 1px solid ${theme("colors.menu.border")};
      border-radius: 6px;
      box-shadow:
        3px 5px 5px -3px ${theme("colors.appHeaderBoxShadow")};
      z-index: 1;
    }
    // override colors in react-day-picker https://react-day-picker.js.org/basics/styling
    .rdp {
      --rdp-accent-color: var(--chakra-colors-brand-500);
      --rdp-background-color: var(--chakra-colors-gray-100);
    }
    :where(button, [role="button"]) {
      cursor: pointer;
      border: none;
    }
  ${globalSelectPortalStyle}
`;

export const mq = generateMedia({
  desktop: "768px",
  mobile: "767px",
});

export const Mobile = (props) => <Responsive {...props} maxWidth={767} />;
export const Desktop = (props) => <Responsive {...props} minWidth={768} />;
export const isDesktop = { query: "(min-width: 768px)" };

export const cssHoverPointer = css`
  &:hover {
    cursor: pointer;
  }
`;

export const title = css`
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

export const debug = css`
  ${ifProp(
    "debug",
    css`
      border: 1px solid ${theme("colors._debug")};
    `
  )};
`;

// debugging wrapper
export const D = styled.div.attrs({ debug: true })`
  ${debug};
`;
