import { debug } from "utils";
import {
  METHODS,
  assertIntegrator,
  assertOk,
  getFilterMapQueryParams,
  getPagingQueryParams,
  getSortQueryParams,
  mapProxyFetch,
  serviceMap,
} from "./utils";
import { ACTIONS } from "../containers/Pages/Payouts/constants";
import buildTiliaApiResponse from "./buildTiliaApiResponse";

const invoicingOpsProxy = mapProxyFetch(serviceMap.INVOICING_OPS_API);

const invoicingPubsProxy = mapProxyFetch(serviceMap.INVOICING_API);

export const getInvoices = async (accountId, ctx) => {
  debug(ctx, "[getInvoices]", accountId);
  assertIntegrator(ctx);
  const response = await invoicingOpsProxy(ctx, `/invoices/${accountId}`);
  await assertOk(response);
  return response.json();
};

export const getInvoiceById = async (invoiceId, ctx) => {
  debug(ctx, "[getInvoiceById]", invoiceId);
  const response = await invoicingPubsProxy(ctx, `/v2/invoice/${invoiceId}`);
  await assertOk(response);
  return response.json();
};

export const refundLineItem = async (lineItemId, amount, paymentMethods, reason, note, ctx) => {
  debug(ctx, "[refundLineItem]", lineItemId, amount, paymentMethods, reason, note);
  assertIntegrator(ctx);
  const response = await invoicingPubsProxy(ctx, `/v2/line-item/refund`, METHODS.POST, {
    line_item_id: lineItemId,
    amount,
    payment_methods: paymentMethods,
    reason,
    note,
  });
  return buildTiliaApiResponse(response);
};

export const refundInvoice = async (invoiceId, reason, note, ctx) => {
  debug(ctx, "[refundInvoice]", invoiceId, reason, note);
  assertIntegrator(ctx);
  const response = await invoicingPubsProxy(ctx, `/v2/refund`, METHODS.POST, {
    invoice_id: invoiceId,
    reason,
    note,
  });
  return buildTiliaApiResponse(response);
};

// no header required here, integrator is explicitly passed
// TODO document the behavior:
// if none is passed and the agent is tilia, ....
// if none is passed and the agent is not tilia, ....
export const getAllPayouts = async ({
  ctx,
  currentPage,
  pageSize,
  filterMap,
  sortField,
  sortDir,
}) => {
  debug(ctx, "[getAllPayouts]", currentPage, pageSize, filterMap, sortField, sortDir);
  const response = await invoicingOpsProxy(
    ctx,
    `/v3/all-payouts?${getFilterMapQueryParams(filterMap)}${getPagingQueryParams({
      currentPage,
      pageSize,
    })}&${getSortQueryParams({ sortField, sortDir })}`,
  );
  await assertOk(response);
  return response.json();
};

export const getPayout = async (accountId, payoutId, ctx) => {
  debug(ctx, "[getPayout]", accountId, payoutId);
  assertIntegrator(ctx);
  const response = await invoicingOpsProxy(ctx, `/v2/${accountId}/payout/${payoutId}`);
  await assertOk(response);
  return response.json();
};

export const finalizePayout = async (action, payout, ctx) => {
  debug(ctx, "[finalizePayout]", payout.payout_status_id);
  assertIntegrator(ctx);
  const method = action === ACTIONS.APPROVE ? METHODS.PATCH : METHODS.DELETE;
  const response = await invoicingOpsProxy(
    ctx,
    `/v2/${payout.account_id}/payout/${payout.payout_status_id}`,
    method,
  );
  return buildTiliaApiResponse(response);
};

export const changePayoutStatus = async (action, payout, ctx) => {
  debug(ctx, "[changePayoutStatus]", payout.payout_status_id);
  assertIntegrator(ctx);
  const status = action === ACTIONS.ON_HOLD && "hold";
  const response = await invoicingOpsProxy(
    ctx,
    `/v2/${payout.account_id}/payout/${payout.payout_status_id}/${status}`,
    METHODS.POST,
  );
  return buildTiliaApiResponse(response);
};

export const createDepositIntent = async (
  accountId,
  depositProvider,
  depositType,
  walletId,
  paymentMethodId,
  requestAmountCents,
  requestCurrency,
  ctx,
) => {
  debug(
    ctx,
    "[createDepositIntent]",
    accountId,
    walletId,
    paymentMethodId,
    requestAmountCents,
    requestCurrency,
  );
  assertIntegrator(ctx);
  const response = await invoicingPubsProxy(
    //
    ctx,
    `/v2/deposit`,
    METHODS.POST,
    {
      account_id: accountId,
      deposit_payment_method_id: paymentMethodId,
      deposit_provider: depositProvider,
      deposit_type: depositType,
      destination_wallet_id: walletId,
      request_amount: requestAmountCents,
      request_currency: requestCurrency,
    },
  );
  return buildTiliaApiResponse(response);
};

export const executeDeposit = async (
  depositId,
  externalTransactionType,
  externalTransactionId,
  receivedAmountCents,
  receivedCurrency,
  ctx,
) => {
  debug(
    ctx,
    "[executeDeposit]",
    depositId,
    externalTransactionType,
    externalTransactionId,
    receivedAmountCents,
    receivedCurrency,
  );
  assertIntegrator(ctx);
  const response = await invoicingPubsProxy(ctx, `/v2/deposit/${depositId}/execute`, METHODS.POST, {
    external_transaction_type: externalTransactionType,
    external_transaction_id: externalTransactionId,
    received_amount: receivedAmountCents,
    received_currency: receivedCurrency,
  });
  return buildTiliaApiResponse(response);
};
