/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { formatDate } from "utils";
import { useIntegrator } from "context/integratorContext";
import { Button, Table, TableContainer, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { AccountAvatar } from "components/Account";
import { useFilter } from "components/Filter";
// import useConnectionPagination from "components/Pager/useConnectionPagination";
import useConnectionPaginationOptions from "components/Pager/useConnectionPaginationOptions";
import { Money } from "components/Money";
// import Pager from "components/Pager";
import LoadingTbody from "components/Table/LoadingTbody";
import approvalsForCurrentReviewCycle from "./approvalsForCurrentReviewCycle";
import { SettlementApprovalType } from "./settlementTypes";
import useHistoricalSettlements from "./useHistoricalSettlements";
import SettlementDetailsModal from "./SettlementDetailsModal";
import { useCallback, useState } from "react";

function HistoricalSettlementsTableRow({ settlement, onDetailsClick }) {
  const integrator = useIntegrator(settlement.integrator);
  const approvals = approvalsForCurrentReviewCycle(settlement.statusHistory, settlement.approvals);

  return (
    <Tr>
      <Td>{integrator?.displayName || settlement.integrator}</Td>
      <Td>
        {formatDate(settlement.periodStartDatetime)} – {formatDate(settlement.periodEndDatetime)}
      </Td>
      <Td isNumeric={true}>
        <Money value={settlement.netSettlement} currency={settlement.currencyCode} />
      </Td>
      <Td textAlign="center">
        {approvals
          .filter((approval) => approval.approvalType === SettlementApprovalType.REVIEW)
          .map((approval) => (
            <AccountAvatar key={approval.userAccount.accountId} account={approval.userAccount} />
          ))}
      </Td>
      <Td textAlign="center">
        {approvals
          .filter((approval) => approval.approvalType !== SettlementApprovalType.REVIEW)
          .map((approval) => (
            <AccountAvatar key={approval.userAccount.accountId} account={approval.userAccount} />
          ))}
      </Td>
      <Td textAlign="center">
        <Button
          type="button"
          size="sm"
          colorScheme="gray"
          onClick={() => onDetailsClick(settlement)}
        >
          Details
        </Button>
      </Td>
    </Tr>
  );
}

function HistoricalSettlementsTable() {
  const filter = useFilter();
  const paginationOptions = useConnectionPaginationOptions("NEWEST");

  const { data, loading } = useHistoricalSettlements({
    filter: filter.gqlFilterParams,
    ...paginationOptions,
  });

  // TODO: enable pagination once API support is in place
  // const { setPageSize, changePage } = useConnectionPagination(data?.settlements);

  const [settlementIdentifier, setSettlementIdentifier] = useState(null);

  const handleDetailsClick = useCallback(
    (settlement) => {
      setSettlementIdentifier({
        settlementId: settlement.settlementId,
        integrator: settlement.integrator,
      });
    },
    [setSettlementIdentifier]
  );

  const handleModalClose = () => {
    setSettlementIdentifier(null);
  };

  return (
    <>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Publisher</Th>
              <Th>Settlement Period</Th>
              <Th isNumeric={true}>Net Settlement</Th>
              <Th textAlign="center">Reviewer</Th>
              <Th textAlign="center">Approver</Th>
              <Th textAlign="center">Details</Th>
            </Tr>
          </Thead>
          <LoadingTbody columns={6} loading={loading}>
            {data?.settlements?.edges?.map((edge) => (
              <HistoricalSettlementsTableRow
                key={edge.node.settlementId}
                settlement={edge.node}
                onDetailsClick={handleDetailsClick}
              />
            ))}
          </LoadingTbody>
        </Table>
      </TableContainer>
      {/*TODO: Enable <Pager> component once API support is in place*/}
      {/*<Pager*/}
      {/*  setPage={changePage}*/}
      {/*  pageSize={paginationOptions.first || paginationOptions.last}*/}
      {/*  updatePageSize={setPageSize}*/}
      {/*  connection={data?.settlements || {}}*/}
      {/*/>*/}
      <SettlementDetailsModal
        settlementIdentifier={settlementIdentifier}
        isOpen={!!settlementIdentifier}
        onClose={handleModalClose}
      />
    </>
  );
}

export default HistoricalSettlementsTable;
