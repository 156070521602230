import React, { useState } from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useModal, ModalFooter, ModalErrors } from "components/Modal";
import {
  ButtonIconWrapper,
  TextLinkButton,
  ButtonSpinner,
  OutlineButton,
  PrimaryButton,
} from "components/Button";
import { noop } from "utils";
import { EmailVerifiedIcon } from "components/Icons";
import { unbounceEmail } from "api/emailBouncelist";
import { useAgent } from "context/agentContext";

const STATUS = {
  PRE_INIT: "PRE_INIT",
  INITIALIZED: "INITIALIZED",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const useUnbounceModal = () => {
  const { agent } = useAgent();
  const [state, setState] = useState({ status: STATUS.PRE_INIT });
  const [Modal, openModal, closeModal] = useModal();

  const UnbounceModal = ({ email }) => {
    const handleSubmit = async () => {
      setState((state) => ({ ...state, status: STATUS.INITIALIZED }));

      try {
        await unbounceEmail(email, agent);
        setState((state) => ({
          ...state,
          status: STATUS.SUCCESS,
          error: null,
        }));
        //  requery email data
        closeModal();
      } catch (error) {
        setState((state) => ({
          ...state,
          status: STATUS.ERROR,
          error,
        }));
      }
    };
    const cancelButton =
      state.status === STATUS.INITIALIZED ? (
        <TextLinkButton disabled={true} onClick={noop}>
          Cancel
        </TextLinkButton>
      ) : (
        <TextLinkButton data-testid="modal-unbounce-email-cancel-button" onClick={closeModal}>
          Cancel
        </TextLinkButton>
      );

    const actionButton =
      state.status === STATUS.INITIALIZED ? (
        <PrimaryButton disabled={true} onClick={noop}>
          Unbounce Email
          <ButtonSpinner />
        </PrimaryButton>
      ) : (
        <PrimaryButton
          data-testid="modal-unbounce-email-button"
          type="submit"
          onClick={handleSubmit}
        >
          Unbounce Email
          <ButtonIconWrapper>
            <EmailVerifiedIcon size="1rem" />
          </ButtonIconWrapper>
        </PrimaryButton>
      );

    const errorFooter = state.status === STATUS.ERROR ? <ModalErrors errors={state.error} /> : null;

    return (
      <Modal titleText="Unbounce Email">
        <div>Are you sure you want to unbounce {email}?</div>
        <ModalFooter>
          {cancelButton}
          {actionButton}
        </ModalFooter>
        {errorFooter}
      </Modal>
    );
  };

  const UnbounceButton = () => (
    <OutlineButton
      data-testid="unbounce-button"
      onClick={openModal}
      css={`
        white-space: nowrap;
      `}
    >
      Unbounce Email
      <ButtonIconWrapper>
        <EmailVerifiedIcon size="1rem" />
      </ButtonIconWrapper>
    </OutlineButton>
  );

  return [UnbounceModal, UnbounceButton];
};
