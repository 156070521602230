import useCurrencyFormatter from "./useCurrencyFormatter";
import { isNilOrEmpty } from "utils";
import { Text } from "@chakra-ui/react";

/**
 * @typedef {Object} MoneyProps
 * @property {(bigint|Number|string)} value Amount in the lowest denomination for the currency
 * @property {string} currency
 * @property {CurrencyDisplay} [currencyDisplay] Optional format for the display
 * @property {...any} [textProps]
 */

/**
 * Formats and renders the currency value as a localized string inside an HTML <data> element,
 * adding the value and currency code as attributes, and accepting Chakra text props for
 * optional styling.
 *
 * @param {MoneyProps} props
 * @returns {JSX.Element}
 */
const Money = ({ value, currency, currencyDisplay, ...textProps }) => {
  const formatter = useCurrencyFormatter(currency, currencyDisplay);

  return (
    <Text as="data" value={value} data-currency={currency} {...textProps}>
      {formatter(value)}
    </Text>
  );
};

export default Money;

export const NullableMoney = (props) => {
  // if we don't have a currency, show a currency-less value
  if (isNilOrEmpty(props.currency)) {
    return (
      <Text as="data" value={props.value} data-currency={props.currency} {...props.textProps}>
        {props.value}
      </Text>
    );
  }
  return <Money {...props} />;
};
