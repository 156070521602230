import { FilterContextProvider } from "components/Filter";
import { PageContent } from "components/Page";
import { PermissionsMessage } from "components/PermissionsMessage";
import { READ_PROCESS_CREDITS, WRITE_PROCESS_CREDITS } from "context/permissions";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import PayoutGrid from "./PayoutGrid";
import { STATUS } from "./constants";
import { useSearchParams } from "react-router-dom";
import { Payouts as NewPayouts } from "./v2";

function useNewPayouts() {
  const [params] = useSearchParams();
  return params.get("v") === "2";
}

export const Payouts = () => {
  const useNewVersion = useNewPayouts();
  const hasPermission = useAgentHasPermissions([READ_PROCESS_CREDITS, WRITE_PROCESS_CREDITS]);
  if (!hasPermission) {
    return (
      <PageContent>
        <PermissionsMessage />
      </PageContent>
    );
  }

  if (useNewVersion) return <NewPayouts />;

  // else legacy payouts...
  return (
    <FilterContextProvider
      config={[
        {
          name: "f_status",
          defaultValue: STATUS.escrowed,
        },
        {
          name: "f_integrator",
          defaultValue: "",
        },
        {
          name: "currentPage",
          defaultValue: 0,
        },
        {
          name: "pageSize",
          defaultValue: 10,
        },
        {
          name: "sortField",
          defaultValue: "created",
        },
        {
          name: "sortDir",
          defaultValue: "desc",
        },
      ]}
    >
      <PageContent title={{ page: "Payouts" }} opts={{ clear: true }}>
        <PayoutGrid />
      </PageContent>
    </FilterContextProvider>
  );
};
