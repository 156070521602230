import React from "react";
import "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAccount } from "context/accountContext";
import { Panel, PanelTitle } from "components/Panel";
import { Button, NoteField, SelectField } from "components/Forms";
import { BackButton, ButtonSet, TextLinkButton } from "components/Button";
import { KYCActionMenu } from "./KYCActionMenu";
import { KYCIframe } from "./Iframe";
import { validationMessages as vm } from "utils/forms";

const formTypeOptions = [
  { value: "domestic-dv", label: "Domestic / DV" },
  { value: "domestic-nodv", label: "Domestic / No DV" },
  { value: "international", label: "International" },
];

const Form = ({ onCancel }) => {
  const { accountState, getKYCCollectionUri } = useAccount();
  const { details } = accountState;

  return (
    <Formik
      initialValues={{
        formType: "",
        note: "",
      }}
      validationSchema={Yup.object().shape({
        formType: Yup.string().required(vm.required),
      })}
      onSubmit={(values) => {
        getKYCCollectionUri(details.accountId, {
          form_type: values.formType,
          note: values.note,
        });
      }}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <div
            css={`
              width: 50%;
              margin-top: 8px;
            `}
          >
            <form onSubmit={handleSubmit}>
              <SelectField label="Form Type" name="formType" required options={formTypeOptions} />
              <NoteField label="Note" name="note" />
              <ButtonSet
                css={`
                  margin-top: 16px;
                `}
              >
                <TextLinkButton onClick={onCancel}>Cancel</TextLinkButton>
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </ButtonSet>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export const Update = () => {
  const navigate = useNavigate();
  const account = useAccount();
  const iframeUrl = account?.accountState?.kycCollectionUri?.data?.pii_collection_uri;
  const showForm = account?.accountState?.kycCollectionUriReady === false;

  const navigateToDetails = () => navigate(`/account/${account.accountId}/kyc`);

  return (
    <Panel>
      <PanelTitle>
        <BackButton onClick={navigateToDetails} />
        <KYCActionMenu />
      </PanelTitle>
      <span>Update KYC</span>
      {showForm ? <Form onCancel={navigateToDetails} /> : <KYCIframe url={iframeUrl} />}
    </Panel>
  );
};
