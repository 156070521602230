import * as R from "ramda";
import { getKYCPII, getPIIByID, PIITypes } from "api/pii";
import { getPaymentMethods } from "api/payments";
import { toJSONB64 } from "utils";

export const fetchPMPii = async (setPiiData, paymentMethodID, accountId, agentContext) => {
  let payload = {};
  /*
   this is a temporary hack because getPaymentMethod doesn't return state=INACTIVE PMs
   whereas getPaymentMethods does
  */
  const { payload: pms } = await getPaymentMethods(accountId, agentContext);
  const pm = R.find(R.propEq(paymentMethodID, "id"), pms);
  if (pm === undefined) {
    return;
  }

  //process name
  if (pm.name_id) {
    const namePII = await getPIIByID(pm.account_id, PIITypes.Name, pm.name_id, agentContext);
    const name = R.path(["payload", "name", "revisions", 0], namePII);
    if (name.first || name.last) {
      payload.first = name.first;
      payload.last = name.last;
    } else {
      payload.last = name.full; //this is what the watchlist service does too
    }
  } else {
    if (pm.first_name || pm.last_name) {
      payload.first = pm.first_name;
      payload.last = pm.last_name;
    } else {
      payload.last = pm.full_name; //this is what the watchlist service does too
    }
  }
  //name might still be empty and thats okay

  //process address
  if (pm.address_id) {
    const addrPII = await getPIIByID(pm.account_id, PIITypes.Address, pm.address_id, agentContext);
    var addr = R.path(["payload", "address", "revisions", 0], addrPII);
    payload.street = addr.street_address_1;
    payload.city = addr.city;
    payload.state = addr.state_province;
    payload.country = addr.country;
    payload.zip = addr.zip_postal_code;
  } else {
    payload.street = pm.address1;
    payload.street2 = pm.address2;
    payload.city = pm.city;
    payload.state = pm.state;
    payload.country = pm.country_iso;
    payload.zip = pm.zip;
  }

  const populated = Object.values(payload).some((ele) => ele !== undefined);
  if (populated) {
    payload.entity_type = "INDIVIDUAL";
  }

  setPiiData(payload);
};

export const fetchKYCPii = async (setPiiData, accountId, agentContext) => {
  const {
    payload: { kyc_pii: p },
  } = await getKYCPII(accountId, agentContext);
  // p is undefined if this user has not gone thru kyc
  if (!p) {
    return;
  }

  const payload = {
    first: p.first,
    last: p.last,
    street: p.street,
    city: p.city,
    state: p.state,
    country: p.country,
    zip: p.zip,
    dob: p.date_of_birth,
    entity_type: "INDIVIDUAL",
  };
  setPiiData(payload);
};

export const queryWatchlist = (navigate, data) =>
  navigate({ pathname: "/watchlist/query", search: "qs=" + toJSONB64(data) });
