import React, { useCallback } from "react";
import * as R from "ramda";
import { useFormikContext } from "formik";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import * as Yup from "yup";
import { useAgent } from "context/agentContext";
import { useAccount } from "context/accountContext";
import { createAccountNote } from "api/notes";
import { ctxWithIntegrator } from "utils/auth";
import { useFormModal } from "components/Modal";
import { Text, TextField, NoteField, ToggleField } from "components/Forms";
import { EmailNotVerifiedIcon, EmailVerifiedIcon } from "components/Icons";
import { validationMessages as vm } from "utils/forms";
import { useAlert } from "context/alertContext";
import useAgentCanChangeUsername from "./useAgentCanChangeUsername";

const errorPrefixes = [
  "Could not save username change",
  "Could not save email change",
  "Could not save note",
];

const allSuccess = R.all(R.propEq("fulfilled", "status"));
const isError = R.complement(R.propEq("fulfilled", "status"));
const buildErrorMessage = ([prefix, { reason }]) => ({ message: `${prefix}: ${reason.message}` });
const mapResponsesToErrors = (responseArray) =>
  R.pipe(
    R.zip(errorPrefixes),
    R.filter(([_, r]) => isError(r)),
    R.map(buildErrorMessage)
  )(responseArray);

const FormBody = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <div
      css={`
        min-width: 20vw;
      `}
    >
      <TextField name="username" label="Username" focus={true} />
      <ToggleField
        name="sync"
        onLabel="Sync"
        offLabel="No Sync"
        setFieldValue={(name, value, shouldValidate) => {
          setFieldValue("email", values.username, true);
          setFieldValue(name, value, shouldValidate);
        }}
      />
      {values.sync ? (
        <Text name="email-disabled" label="Email" value={values.username} disabled={true} />
      ) : (
        <TextField name="email" label="Email" />
      )}
      <ToggleField
        name="verified"
        onIcon={EmailVerifiedIcon}
        offIcon={EmailNotVerifiedIcon}
        onLabel="Set Email Verified"
        offLabel="Set Email Not Verified"
      />
      <NoteField name="noteText" label="Note" required />
    </div>
  );
};

export const useChangeUsernameModal = () => {
  const { agent } = useAgent();
  const [FormModal, openModal] = useFormModal();
  const { fireAlert } = useAlert();
  const canChangeUsername = useAgentCanChangeUsername();

  const ChangeUsernameModal = ({ account, onSuccessfulUpdate }) => {
    const {
      details: { getAccountDetails },
      changeUsername,
      changeEmail,
    } = useAccount();

    const { account_id, integrator } = account;

    const handleChangeUsername = async (values) => {
      const { username, email, sync, verified, noteText } = values;
      const updateEmail = sync ? username : email;

      const responses = await Promise.allSettled([
        changeUsername(account_id, username),
        changeEmail(account_id, updateEmail, verified),
        createAccountNote(
          { accountId: account_id, subject: "", note: noteText },
          ctxWithIntegrator(integrator, agent)
        ),
      ]);

      if (allSuccess(responses)) {
        return { status: "Success" };
      }

      return { status: "Error", error: mapResponsesToErrors(responses) };
    };

    const handleSubmitSuccess = () => {
      getAccountDetails(account.account_id);
      onSuccessfulUpdate();
      fireAlert("Successfully updated!");
    };

    const validationSchema = Yup.object().shape({
      sync: Yup.boolean(),
      username: Yup.string().required(vm.required),
      email: Yup.string().required(vm.required),
      noteText: Yup.string().required(vm.required),
    });

    return (
      <FormModal
        titleText="Change Username / Email"
        actionText="Submit"
        submitAction={handleChangeUsername}
        onSubmitActionSuccess={handleSubmitSuccess}
        formProps={{
          initialValues: {
            verified: true,
            sync: account.username === account.email,
            username: account.username,
            email: account.email,
            noteText: "",
          },
          validationSchema,
        }}
      >
        <FormBody />
      </FormModal>
    );
  };

  const initAndOpen = useCallback(() => {
    openModal();
  }, [openModal]);

  const getMenuItem = useCallback(() => {
    return {
      disabled: !canChangeUsername,
      "data-displayitem": canChangeUsername,
      label: "Change Username...",
      onSelect: canChangeUsername && initAndOpen,
    };
  }, [initAndOpen, canChangeUsername]);

  return [ChangeUsernameModal, getMenuItem, initAndOpen];
};
