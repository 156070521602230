import { Flex } from "@chakra-ui/react";
import FilterContextProvider from "components/Filter/FilterContext";
import { GridProvider, SORT_DIRECTION } from "components/Grid";
import { Panel } from "components/Panel";
import Download from "./Download";
import TransactionHistoryDisplay from "./TransactionHistoryDisplay";
import TransactionHistoryFilters from "./TransactionHistoryFilters";

const filterConfig = [
  {
    name: "date",
    type: "date",
    multiple: true,
    graphQlName: "dates",
  },
  {
    name: "type",
    type: "value",
    multiple: true,
    defaultValue: [],
    graphQlName: "types",
  },
  {
    name: "status",
    type: "value",
    multiple: true,
    defaultValue: [],
    graphQlName: "statusCodes",
  },
  {
    name: "purchaseStatus",
    type: "value",
    multiple: true,
    defaultValue: [],
    graphQlName: "purchaseStatusCodes",
  },
  {
    name: "escrowPurchaseStatus",
    type: "value",
    multiple: true,
    defaultValue: [],
    graphQlName: "escrowPurchaseStatusCodes",
  },
  {
    name: "refundStatus",
    type: "value",
    multiple: true,
    defaultValue: [],
    graphQlName: "refundStatusCodes",
  },
  {
    name: "payoutStatus",
    type: "value",
    multiple: true,
    defaultValue: [],
    graphQlName: "payoutStatusCodes",
  },
  {
    name: "tokenPurchaseStatus",
    type: "value",
    multiple: true,
    defaultValue: [],
    graphQlName: "tokenPurchaseStatusCodes",
  },
  {
    name: "tokenConversionStatus",
    type: "value",
    multiple: true,
    defaultValue: [],
    graphQlName: "tokenConversionStatusCodes",
  },
  {
    name: "balanceTransferStatus",
    type: "value",
    multiple: true,
    defaultValue: [],
    graphQlName: "balanceTransferStatusCodes",
  },
  {
    name: "depositStatus",
    type: "value",
    multiple: true,
    defaultValue: [],
    graphQlName: "depositStatusCodes",
  },
  {
    name: "makeGoodStatus",
    type: "value",
    multiple: true,
    defaultValue: [],
    graphQlName: "makeGoodStatusCodes",
  },
];

const TransactionHistory = () => {
  return (
    <Panel>
      <FilterContextProvider config={filterConfig}>
        <Flex>
          <Flex grow={1}>
            <TransactionHistoryFilters />
          </Flex>
          <Flex align={"start"}>
            <Download />
          </Flex>
        </Flex>
        <TransactionHistoryDisplay />
      </FilterContextProvider>
    </Panel>
  );
};

const TransactionHistoryV2 = () => {
  return (
    <GridProvider
      usesConnection={true}
      initialSortField="date"
      initialSortDir={SORT_DIRECTION.DESC}
    >
      <TransactionHistory />
    </GridProvider>
  );
};

export { TransactionHistoryV2 };
