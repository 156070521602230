import React, { useEffect } from "react";
import "styled-components/macro";
import { useFilter } from "components/Filter/FilterContext.js";
import IntegratorSelect from "components/IntegratorSelect/IntegratorSelect";
import Filters from "components/Filter/Filters.js";
import FilterSelect from "components/Filter/inputs/FilterSelect";
import TextFilter from "components/Filter/inputs/TextFilter";
import { debounce, isNilOrEmpty, exists } from "utils";
import { stripSettingsPrefix } from "utils/format";

const keyInSettings = (key, settings) => {
  if (isNilOrEmpty(key)) return false;
  if (isNilOrEmpty(settings)) return false;
  return Object.keys(settings).map(stripSettingsPrefix).includes(key);
};

const KeyFilter = ({ currentSettings = {} }) => {
  const { filterParams, handleChangeFilter } = useFilter();
  const allOptions = [
    ...Object.keys(currentSettings || {})
      .sort()
      .map((key) => ({ value: stripSettingsPrefix(key), label: stripSettingsPrefix(key) })),
  ];

  // reset the key filter if the current settings do not have the filter key
  useEffect(() => {
    if (
      exists(filterParams.settingKey) &&
      !keyInSettings(filterParams.settingKey, currentSettings)
    ) {
      handleChangeFilter("settingKey", undefined);
    }
  }, [currentSettings, filterParams.settingKey, handleChangeFilter]);

  return (
    <FilterSelect
      label="Key"
      name="settingKey"
      isClearable={true}
      onChange={(_, val) => handleChangeFilter("settingKey", val)}
      options={allOptions}
      value={filterParams.settingKey}
    />
  );
};

export const SettingsFilters = ({ currentSettings = {} }) => {
  const { filterParams, handleChangeFilter } = useFilter();

  const filterOptions = [
    {
      key: "integrator",
      Option: (
        <IntegratorSelect
          includeAll
          allLabel="Global"
          onChange={(integrator) => handleChangeFilter("integrator", integrator)}
          value={filterParams.integrator || ""}
        />
      ),
    },
    {
      key: "settingKey",
      Option: <KeyFilter currentSettings={currentSettings} />,
    },
    {
      key: "f",
      Option: (
        <TextFilter
          label="Text"
          onChange={(value) => debounce(handleChangeFilter("f", value))}
          value={filterParams.f}
        />
      ),
    },
  ];
  return <Filters filterOptions={filterOptions} />;
};
