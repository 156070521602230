import React from "react";
import { isNilOrEmpty } from "utils";
import { Tbody, Td, Tr } from "../Table";
import { Money } from "../Money";
import useCurrencyDisplayInfo from "../Money/useCurrencyDisplayInfo";

const FailedRefundsList = ({ failures, transaction }) => {
  const { getValueByLowestDenominationRounded } = useCurrencyDisplayInfo(transaction.currencyCode);

  if (isNilOrEmpty(failures)) {
    return null;
  }

  return (
    <>
      <Tbody>
        <Tr>
          <Td colSpan={2}>
            <h3>Failed to refund the following:</h3>
          </Td>
        </Tr>
      </Tbody>
      {failures.map((failure) => {
        const lineItem = transaction.lineItems.find(
          (lineItem) => lineItem.lineItemId === failure.lineItem.line_item_id
        );

        const amount = getValueByLowestDenominationRounded(failure.lineItem.amount);

        return (
          <Tbody key={lineItem.lineItemId}>
            <Tr>
              <Td>Line Item: </Td>
              <Td>{lineItem.description}</Td>
            </Tr>
            <Tr>
              <Td>Attempted Amount: </Td>
              <Td>
                <Money value={amount} currency={transaction.currencyCode} />
              </Td>
            </Tr>
            <Tr>
              <Td>Failure Reason: </Td>
              <Td>{JSON.stringify(failure.errors)}</Td>
            </Tr>
          </Tbody>
        );
      })}
    </>
  );
};

export default FailedRefundsList;
