import { CurrencyDisplay } from "./types";
import Money from "./Money";

/**
 * @typedef {Object} ExchangeRateProps
 * @property {string} sourceCurrency
 * @property {string} destinationCurrency
 * @property {number} exchangeRate Fractional exchange rate multiplier from one unit of the source
 *   currency to one unit of the destination currency.
 * @property {CurrencyDisplay} [currencyDisplay] Optional format for the currency amount display.
 *   Default is `CurrencyDisplay.Code`.
 */

/**
 * Formats and renders the fractional exchange rate as an integer ratio value between the source
 * and destination currencies.
 *
 * @param {ExchangeRateProps} props
 * @returns {JSX.Element}
 */
function FormattedExchangeRate({
  sourceCurrency,
  destinationCurrency,
  exchangeRate,
  currencyDisplay = CurrencyDisplay.Code,
}) {
  let multiplier = 100;
  if (sourceCurrency !== "USD" && destinationCurrency !== "USD") {
    multiplier = 1;
  }

  let sourceUnit = multiplier;
  let destinationUnit = exchangeRate * multiplier;

  while (destinationUnit < 1) {
    sourceUnit *= 10;
    destinationUnit *= 10;
  }

  destinationUnit = Math.round(destinationUnit);

  return (
    <>
      <Money value={sourceUnit} currency={sourceCurrency} currencyDisplay={currencyDisplay} />
      {" = "}
      <Money
        value={destinationUnit}
        currency={destinationCurrency}
        currencyDisplay={currencyDisplay}
      />
    </>
  );
}

export default FormattedExchangeRate;
