import React from "react";
import { BlockedIcon } from "components/Icons";
import useAgentCanUnblockAccount from "./useAgentCanUnblockAccount";
import useAccountBlocks from "./useAccountBlocks";
import useUnblockAccountModal from "./useUnblockAccountModal";

const useUnblockUserActionMenuItem = (key, account) => {
  const agentCanUnblockAccount = useAgentCanUnblockAccount(account.account_id);
  const [, refetch] = useAccountBlocks(account.account_id);
  const [UnblockAccountModal, openModal] = useUnblockAccountModal();

  const handleAccountUnblocked = () => {
    refetch();
  };

  return {
    key,
    item: {
      disabled: !agentCanUnblockAccount,
      "data-displayitem": agentCanUnblockAccount,
      label: "Unblock…",
      icon: <BlockedIcon size="1rem" />,
      onSelect: openModal,
    },
    comp: (
      <UnblockAccountModal
        key={key}
        account={account}
        onAccountUnblocked={handleAccountUnblocked}
      />
    ),
  };
};

export default useUnblockUserActionMenuItem;
