import { useState } from "react";
import * as Yup from "yup";
import { useFormModal } from "components/Modal";
import { FinanceIcon } from "components/Icons";
import { validationMessages as vm } from "utils/forms";
import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DataRow } from "components/DataRow";
import { Money } from "components/Money";
import { Field } from "formik";
import { WalletHeaderSummary } from "components/Wallet";
import { AccountHeaderSummary } from "components/Account";
import { Select } from "components/Forms";
import { useAgent } from "context/agentContext";
import { exists, isNilOrEmpty } from "utils";
import * as invoicingApi from "api/invoicing";
import * as paymentsApi from "api/payments";
import { getAccountPaymentMethods, useQuery } from "context/graphqlContext";

const depositTypeOptions = [
  { value: "ACH", label: "ACH" },
  {
    value: "wire",
    label: "Wire",
  },
];

export const useWalletDepositModal = (wallet) => {
  const { agent, withIntegratorContext } = useAgent();
  agent.debug("useWalletDepositModal", wallet);

  const { data } = useQuery(getAccountPaymentMethods); //eslint-disable-line no-unused-vars
  const existingExternalPM = data?.account?.paymentMethods?.find(
    (pm) => pm.provider === "external" && pm.pmState === "ACTIVE",
  );
  agent.debug({ data, existingExternalPM });

  // ===============================================
  //
  const [FormModal, openModal] = useFormModal();

  const createExternalPaymentMethod = async (formData) => {
    agent.debug("createExternalPaymentMethod", formData);
    return paymentsApi.createExternalPaymentMethod(
      wallet.account.accountId,
      "USD",
      withIntegratorContext(wallet.integrator, agent),
    );
  };

  const createDepositIntent = async (formData, paymentMethod) => {
    agent.debug("createDepositIntent", formData, paymentMethod);
    const requestAmountCents = Math.floor(Number(formData.requestAmount) * 100);
    return invoicingApi.createDepositIntent(
      wallet.account.accountId,
      formData.depositProvider,
      formData.depositType,
      wallet.id,
      paymentMethod.paymentMethodId,
      requestAmountCents,
      formData.requestCurrency,
      withIntegratorContext(wallet.integrator, agent),
    );
  };

  const executeDeposit = async (depositIntent, formData, paymentMethod) => {
    agent.debug("executeDeposit", depositIntent, formData, paymentMethod);
    const receivedAmountCents = Math.floor(Number(formData.receivedAmount) * 100);
    return invoicingApi.executeDeposit(
      depositIntent.payload.deposit_id,
      formData.externalTransactionType,
      formData.externalTransactionId,
      receivedAmountCents,
      formData.receivedCurrency,
      withIntegratorContext(wallet.integrator, agent),
    );
  };

  // Performs the following steps:
  // 1. Create external payment method if one does not exist
  // 2. Create deposit intent with new or existing payment method
  // 3. Execute deposit with intent
  //
  const handleSubmit = async (formData) => {
    agent.debug("handleSubmit", formData);

    // aggregate results for post-submit summary
    const result = {};
    try {
      // ===========================================================
      // if not existing external PM, create one
      //
      let paymentMethod = existingExternalPM;
      try {
        if (isNilOrEmpty(existingExternalPM)) {
          agent.debug("creating new external payment method");
          const paymentMethodResponse = await createExternalPaymentMethod(formData);
          agent.debug({ paymentMethodResponse });
          if (paymentMethodResponse.status !== 200) {
            result.errors = paymentMethodResponse.errors?.join(" ");
            result.status = "Error";
          } else {
            paymentMethod = paymentMethodResponse.payload;
            result.newPM = paymentMethod;
          }
        } else {
          agent.debug("using existing external payment method", existingExternalPM);
          result.existingPM = paymentMethod;
        }
        agent.debug("final PM", { paymentMethod });
      } catch (error) {
        agent.debug({ error });
        result.error = error;
      }

      // if error creating above, pm should be empty to avoid further processing
      if (exists(paymentMethod)) {
        try {
          const depositIntentResult = await createDepositIntent(formData, paymentMethod);
          agent.debug({ depositIntentResult });
          if (depositIntentResult.status !== 201) {
            result.errors = depositIntentResult.errors?.join(" ");
            result.status = "Error";
          } else {
            result.depositIntent = depositIntentResult;

            // successful intent, execute deposit
            const executeDepositResult = await executeDeposit(
              depositIntentResult,
              formData,
              paymentMethod,
            );
            agent.debug({ executeDepositResult });
            result.executeDepositResult = executeDepositResult;
            if (executeDepositResult.status !== 200) {
              result.errors = executeDepositResult.errors.join(" ");
              result.status = "Error";
            } else {
              // success
              result.status = "Success";
            }
          }
        } catch (error) {
          // TODO get error to screen
          agent.debug({ error });
          result.error = error;
        }
      }
    } finally {
      return result;
    }
  };

  // TODO process error result
  const DepositResult = ({ result }) => {
    if (isNilOrEmpty(result)) {
      return null;
    }

    const newPMCreated = exists(result.newPM);
    const pm = newPMCreated ? result.newPM : result.existingPM;
    const paymentMethod = pm
      ? `${pm?.display_string || pm?.provider} / ${pm?.paymentMethodId}`
      : "No payment method?!?!?!";
    const deposit = result.executeDepositResult?.payload;

    return (
      <>
        <Divider mt={4} />
        <VStack align="left" spacing={1} data-testid="result">
          <DataRow title={result.status} data=" " />
          {result.status === "Error" ? (
            <DataRow title={result.errors} data=" " />
          ) : (
            <>
              <DataRow title="Deposit Id" copyable>
                {deposit?.deposit_id}
              </DataRow>
              <DataRow title="Payment Method">{paymentMethod}</DataRow>
              <DataRow title="Amount">
                <Money value={deposit?.received_amount} currency={deposit?.received_currency} />
              </DataRow>
            </>
          )}
        </VStack>
      </>
    );
  };

  const DepositModal = ({ onComplete }) => {
    const [submitResult, setSubmitResult] = useState(null);

    const handleSubmitAction = (result) => {
      agent.debug("handleSubmitAction", result);
      setSubmitResult(result);
    };

    const handleClose = () => {
      agent.debug("handleClose");
      onComplete();
    };

    return (
      <FormModal
        actionIcon={<FinanceIcon size="1rem" />}
        actionText="Deposit"
        closeOnSubmitSuccess={false}
        onCloseAction={handleClose}
        onSubmitActionSuccess={handleSubmitAction}
        onSubmitActionFailed={handleSubmitAction}
        submitAction={handleSubmit}
        titleText="Deposit"
        withConfirmation={false} // for now
        formProps={{
          initialValues: {
            depositProvider: "External",
            depositType: "wire",
            depositPaymentMethod: existingExternalPM?.id,
            requestCurrency: "USD",
            receivedCurrency: "USD",
          },
          validationSchema: () =>
            Yup.object().shape({
              depositProvider: Yup.string().required(vm.required),
              depositType: Yup.string().required(vm.required),
              externalTransactionType: Yup.string().required(vm.required),
              externalTransactionId: Yup.string().required(vm.required),
              requestAmount: Yup.number()
                .required(vm.required)
                .positive(vm.positive)
                .typeError(() => `Amount must be a positive number. `),
              requestCurrency: Yup.string().required(vm.required),
              receivedAmount: Yup.number()
                .required(vm.required)
                .positive(vm.positive)
                .typeError(() => `Amount must be a positive number. `),
              receivedCurrency: Yup.string().required(vm.required),
            }),
        }}
      >
        <VStack align="left" spacing={4}>
          <AccountHeaderSummary account={{ ...wallet.account, integrator: wallet.integrator }} />
          <WalletHeaderSummary wallet={wallet} withMenu={false} />
        </VStack>

        <Divider />

        <VStack align="left" spacing={2}>
          <Field name="depositProvider">
            {({ field, form }) => (
              <FormControl isRequired>
                <FormLabel>Deposit Provider</FormLabel>
                <Text>{field.value}</Text>
                <FormErrorMessage>{form.errors.depositProvider}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="depositType">
            {({ field, form }) => (
              <FormControl isRequired>
                <FormLabel>Deposit Type</FormLabel>
                <Select
                  //
                  {...field}
                  options={depositTypeOptions}
                  value={depositTypeOptions.find((option) => option.value === field.value)}
                  onChange={(name, value) => form.setFieldValue(name, value)}
                  touched={form.touched[field.name]}
                  error={form.errors[field.name]}
                />
                <FormErrorMessage>{form.errors.depositType}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="depositPaymentMethod">
            {() => {
              return (
                <FormControl isRequired>
                  <FormLabel>Deposit Payment Method</FormLabel>
                  <Text>
                    {existingExternalPM
                      ? `${existingExternalPM.display_string} / ${existingExternalPM.id}`
                      : "No active external payment method exists for this account. A new one will be created."}
                  </Text>
                </FormControl>
              );
            }}
          </Field>

          <FormLabel>Request Amount</FormLabel>
          <HStack align="start" spacing={1}>
            <Field name="requestAmount">
              {({ field, form }) => {
                return (
                  <FormControl
                    isRequired
                    isInvalid={form.errors.requestAmount && form.touched.requestAmount}
                    width="auto"
                  >
                    <Input
                      {...field}
                      variant="outline"
                      placeholder="Amount"
                      size="sm"
                      width="auto"
                      data-testid="request-amount"
                    />
                    <FormErrorMessage>{form.errors.requestAmount}</FormErrorMessage>
                  </FormControl>
                );
              }}
            </Field>
            <Field name="requestCurrency">
              {({ field, form }) => {
                return (
                  <FormControl
                    isInvalid={form.errors.requestCurrency && form.touched.requestCurrency}
                  >
                    <Input
                      {...field}
                      variant="outline"
                      placeholder="Currency"
                      size="sm"
                      width="auto"
                      isDisabled
                    />
                    <FormErrorMessage>{form.errors.requestCurrency}</FormErrorMessage>
                  </FormControl>
                );
              }}
            </Field>
          </HStack>

          <Field name="externalTransactionType">
            {({ field, form }) => (
              <FormControl isRequired>
                <FormLabel>External Transaction Type</FormLabel>
                <Input
                  {...field}
                  variant="outline"
                  size="sm"
                  paddingInlineStart={0}
                  paddingInlineEnd={0}
                  pl={2}
                />
                <FormErrorMessage>{form.errors.externalTransactionType}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="externalTransactionId">
            {({ field, form }) => {
              return (
                <FormControl isRequired>
                  <FormLabel>External Transaction Id</FormLabel>
                  <Input
                    {...field}
                    variant="outline"
                    size="sm"
                    paddingInlineStart={0}
                    paddingInlineEnd={0}
                    pl={2}
                  />
                  <FormErrorMessage>{form.errors.externalTransactionId}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>

          <Box>
            <FormLabel>Received Amount</FormLabel>
            <HStack align="start" spacing={1}>
              <Field name="receivedAmount">
                {({ field, form }) => {
                  return (
                    <FormControl
                      isInvalid={form.errors.receivedAmount && form.touched.receivedAmount}
                      width="auto"
                    >
                      <Input
                        {...field}
                        variant="outline"
                        placeholder="Amount"
                        size="sm"
                        width="auto"
                        data-testid="received-amount"
                      />
                      <FormErrorMessage>{form.errors.receivedAmount}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>
              <Field name="receivedCurrency">
                {({ field, form }) => {
                  return (
                    <FormControl
                      isInvalid={form.errors.receivedCurrency && form.touched.receivedCurrency}
                    >
                      <Input
                        {...field}
                        variant="outline"
                        placeholder="Currency"
                        size="sm"
                        width="auto"
                        isDisabled
                      />
                      <FormErrorMessage>{form.errors.receivedCurrency}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>
            </HStack>
          </Box>
        </VStack>
        <DepositResult result={submitResult} />
      </FormModal>
    );
  };

  return [DepositModal, openModal];
};
