import { ifProp, theme } from "styles";
import { css } from "styled-components";
import styled from "styled-components/macro";

export const container = css`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: contents;
  & > * {
    border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
    padding: 8px 12px;
  }
  & > *:first-child {
    padding-left: 4px;
  }
  & > *:last-child {
    padding-right: 4px;
  }
`;

export const Container = styled.div`
  ${container}
  cursor: ${ifProp("isExpandable", "pointer")};
  background-color: ${ifProp("isExpanded", theme("colors.menu.selectedBg"))};
  &:hover * {
    background-color: ${ifProp("isExpandable", theme("colors.menu.selectedBg"))};
  }
`;

export const ExpandibleContent = styled.div`
  display: contents;
  background-color: ${theme("colors.menu.selectedBg")};
`;

export const MoneyCell = styled.div`
  text-align: right;
  white-space: nowrap;
`;
