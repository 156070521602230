import { Box, Heading, Tbody, Td, Tr } from "@chakra-ui/react";
import { LoadingIcon } from "components/Icons";

function LoadingTbody({ columns, loading, children, ...tbodyProps }) {
  const noData =
    children !== undefined && (!children || (Array.isArray(children) && children.length < 1));

  return (
    <Tbody position="relative" {...tbodyProps}>
      {children}
      {loading && (
        <>
          {(!children || noData) && (
            <Tr>
              <Td colSpan={columns} py={10}>
                &nbsp;
              </Td>
            </Tr>
          )}
          <Tr>
            <Td
              colSpan={columns}
              background="whiteAlpha.800"
              p={0}
              textAlign="center"
              position="absolute"
              top={0}
              width="100%"
              height="100%"
            >
              <Box py={1}>
                <LoadingIcon size={2} />
              </Box>
            </Td>
          </Tr>
        </>
      )}
      {!loading && noData && (
        <Tr>
          <Td colSpan={columns} textAlign="center">
            <Heading as="h4" color="gray" size="2xl">
              No Data
            </Heading>
          </Td>
        </Tr>
      )}
    </Tbody>
  );
}

export default LoadingTbody;
