import React, { useMemo } from "react";
import * as Yup from "yup";
import { useAccount } from "context/accountContext";
import { unblockAccount } from "api/account";
import { ctxWithIntegrator } from "utils/auth";
import { BlockedIcon } from "components/Icons";
import { useFormModal } from "components/Modal";
import { SelectField } from "components/Forms";
import { PermissionsActionMessage } from "components/PermissionsMessage";
import useUnblockCapabilitiesForAccount from "./useUnblockCapabilitiesForAccount";

const capabilityOptions = [
  { value: "LOGIN", label: "Login: accessing the platform, payouts, and transactions" },
  { value: "PAYOUTS", label: "Payouts: requesting or executing a payout" },
  { value: "TRANSACTIONS", label: "Transactions: buying or selling items" },
];

const validationSchema = Yup.object().shape({
  capability: Yup.string().required("Capability is required"),
});

const useUnblockAccountModal = () => {
  const [FormModal, openModal] = useFormModal();

  const UnblockAccountModal = ({ account, onAccountUnblocked }) => {
    const { agentContext } = useAccount();

    const unblockCapabilities = useUnblockCapabilitiesForAccount(account.account_id);

    const availableCapabilityOptions = useMemo(() => {
      return capabilityOptions.filter((option) => {
        return unblockCapabilities.includes(option.value);
      });
    }, [unblockCapabilities]);

    const handleSubmit = async ({ capability }) => {
      const ctx = ctxWithIntegrator(account.integrator, agentContext);

      const result = await unblockAccount(account.account_id, capability.toLowerCase(), ctx);

      if (result.status !== 200) {
        return { status: "Failure", error: result.errors };
      }
      return { status: "Success" };
    };

    const handleSubmitActionSuccess = () => {
      if (onAccountUnblocked) {
        onAccountUnblocked(account);
      }
    };

    return (
      <FormModal
        formProps={{
          initialValues: { capability: "" },
          validationSchema,
        }}
        actionIcon={<BlockedIcon size="1rem" />}
        actionText="Unblock"
        submitAction={availableCapabilityOptions.length > 0 ? handleSubmit : undefined}
        onSubmitActionSuccess={handleSubmitActionSuccess}
        titleText={`Unblock User: ${account.username}`}
      >
        {availableCapabilityOptions.length > 0 ? (
          <>
            <SelectField
              name="capability"
              label="Capability"
              required={true}
              options={availableCapabilityOptions}
            />
          </>
        ) : (
          <PermissionsActionMessage />
        )}
      </FormModal>
    );
  };

  return [UnblockAccountModal, openModal];
};

export default useUnblockAccountModal;
