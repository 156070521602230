import { debug } from "utils";
import {
  assertIntegrator,
  assertOk,
  assertOkWithMessage,
  mapProxyFetch,
  METHODS,
  serviceMap,
} from "./utils";
import buildTiliaApiResponse from "./buildTiliaApiResponse";

const paymentsOpsProxy = mapProxyFetch(serviceMap.PAYMENTS_OPS_API);

export const getPaymentMethods = async (accountId, ctx) => {
  debug(ctx, "[getPaymentMethods]", accountId);
  assertIntegrator(ctx);
  const response = await paymentsOpsProxy(ctx, `/${accountId}/payment_methods?state=all`);
  await assertOk(response);
  return response.json();
};

export const getPaymentMethod = async (pmid, ctx) => {
  debug(ctx, "[getPaymentMethod]", pmid);
  assertIntegrator(ctx);
  const response = await paymentsOpsProxy(ctx, `/payment-method/${pmid}?state=all`);
  await assertOk(response);
  return response.json();
};

export const deactivatePaymentMethod = async (pmid, ctx) => {
  debug(ctx, "[deactivePaymentMethod]", pmid);
  assertIntegrator(ctx);
  const response = await paymentsOpsProxy(ctx, `/payment-method/${pmid}`, METHODS.DELETE);
  await assertOkWithMessage(response);
  return response.json();
};

export const createExternalPaymentMethod = async (accountId, currency, ctx) => {
  debug(ctx, "[createExternalPaymentMethod]", accountId, currency);
  assertIntegrator(ctx);
  const response = await paymentsOpsProxy(ctx, `/payment-method/external`, METHODS.PATCH, {
    account_id: accountId,
    currency,
  });
  return buildTiliaApiResponse(response);
};
