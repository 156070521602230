import { FormControl, FormLabel, Text, useToast } from "@chakra-ui/react";
import addAccountTag from "api/account/addAccountTag";
import deleteAccountTag from "api/account/deleteAccountTag";
import FormikSwitch from "components/Formik/FormikSwitch";
import { SubmitCancelButtonGroup } from "components/Forms";
import { FormModal } from "components/Modal";
import { useAgent } from "context/agentContext";
import { useMutation } from "react-query";

function EditPayoutSettingsModal({
  isOpen,
  onClose,
  accountId,
  requirePayoutReviewTagId,
  onSuccessfulUpdate,
}) {
  const toast = useToast();
  const useAddAccountTag = useMutation({
    mutationFn: addAccountTag,
  });
  const useDeleteAccountTag = useMutation({
    mutationFn: deleteAccountTag,
  });
  const { agent } = useAgent();
  const handleSuccess = () => {
    toast({
      title: "Account payout settings saved.",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
    // we don't need to call onClose because refetching remounts everything
    // This is a bug, but changing how the refetch works is out of scope of when this component was built
    // Normally we would need to call onClose because the modal wouldn't be remounted.
    onSuccessfulUpdate(accountId);
  };
  return (
    <FormModal
      actions={<SubmitCancelButtonGroup disableOnClean onCancel={onClose} />}
      formikProps={{
        initialValues: {
          requirePayoutReview: requirePayoutReviewTagId ? true : false,
        },

        onSubmit: ({ requirePayoutReview }, { setStatus, setSubmitting }) => {
          const responseSettings = {
            onError: (error) => {
              setStatus({ error: error.join(", ") });
            },
            onSuccess: handleSuccess,
            onSettled: () => {
              setSubmitting(false);
            },
          };
          if (requirePayoutReview) {
            useAddAccountTag.mutate(
              {
                tag: { namespace: "payouts", label: "payout_review_required", accountId },
                agent,
              },
              responseSettings
            );
          } else {
            useDeleteAccountTag.mutate(
              {
                tagId: requirePayoutReviewTagId,
                agent,
              },
              responseSettings
            );
          }
        },
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Edit payout settings"
    >
      <Text>
        Requiring payout review will force all payouts for this account to be manually reviewed by
        an agent.
      </Text>
      <FormControl display="flex" alignItems="center">
        <FormikSwitch name="requirePayoutReview" />
        <FormLabel htmlFor="requirePayoutReview" mb="0" ml={3}>
          Require payout review
        </FormLabel>
      </FormControl>
    </FormModal>
  );
}

export default EditPayoutSettingsModal;
