import React from "react";
import * as Yup from "yup";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useAgent } from "context/agentContext";
import * as Permissions from "context/permissions";
import { noop } from "utils";
import { useFormModal } from "components/Modal";
import { OutlineButton } from "components/Button";
import { SelectField, TextField } from "components/Forms";
import { SSO_PROVIDERS as ssoProviders } from "./index";

export const useAddCredentialModal = () => {
  const testIdPrefix = `add-sso-credential-`;
  const [FormModal, openModal] = useFormModal();
  const { agent } = useAgent();

  const Modal = ({ onSuccess = noop, onSubmit }) => {
    if (!agent.hasPermissions([Permissions.WRITE_SSO_CREDENTIALS])) {
      return <div data-testid={`add-sso-credential-modal-no-permissions`}></div>;
    }

    const initialValues = {
      sso_provider: "",
      client_id: "",
      client_secret: "",
    };

    const ssoProviderOptions = ssoProviders.map((ssoProvider) => ({
      label: ssoProvider.label,
      value: ssoProvider.id,
    }));

    return (
      <FormModal
        actionText={"Save"}
        onSubmitActionSuccess={onSuccess}
        submitAction={onSubmit}
        titleText={`Add Credentials`}
        testIdPrefix={testIdPrefix}
        formProps={{
          initialValues: initialValues,
          validationSchema: () =>
            Yup.object().shape({
              sso_provider: Yup.string().required("SSO Provider required."),
              client_id: Yup.string()
                .strict()
                .trim("Please remove any trailing spaces at the end of your ID.")
                .required("Client ID required."),
              client_secret: Yup.string()
                .strict()
                .trim("Please remove any trailing spaces at the end of your secret.")
                .required("Client Secret required."),
            }),
        }}
        disableTillEdit
      >
        <SelectField
          name="sso_provider"
          label="SSO Provider"
          options={ssoProviderOptions}
          required
        />
        <TextField name="client_id" label="Client ID" required />
        <TextField name="client_secret" label="Client Secret" required />
      </FormModal>
    );
  };

  const ModalButton = ({ ...props }) => {
    if (!agent.hasPermissions([Permissions.WRITE_PRODUCT_CATALOG])) {
      return null;
    }
    return (
      <OutlineButton data-testid={`${testIdPrefix}button`} {...props} onClick={openModal}>
        Add Credential
      </OutlineButton>
    );
  };
  return [Modal, ModalButton];
};
