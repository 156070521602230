import { useParams } from "react-router-dom";
import { Stack } from "@chakra-ui/react";
import { isNilOrEmpty } from "utils";
import { NoData } from "components/NoData";
import { PageContent } from "components/Page";
import { SectionSpinner } from "components/Spinner";
import { PermissionsMessage } from "components/PermissionsMessage";
import TransactionHeader from "components/Transaction/TransactionHeader";
import TransactionDetail from "components/Transaction/TransactionDetail";
import useGetTransaction from "./useGetTransaction";

export function TransactionDetails() {
  const { transactionId } = useParams();
  const { data, loading, errors } = useGetTransaction({
    variables: {
      id: transactionId,
    },
    errorPolicy: "all",
  });

  if (loading) {
    return <SectionSpinner />;
  }

  if (isNilOrEmpty(data?.transaction)) {
    /*
    Different transactions have different permissions, so we can't do a UI permissions check
    because we don't know what Type of transaction we are going to get. Instead we have to wait for the
    server to reject the request.
     */
    if (errors) {
      if (errors.some((error) => error?.extensions?.code === "FORBIDDEN")) {
        return <PermissionsMessage />;
      }
      return <div>Something went wrong</div>;
    }
    return (
      <PageContent>
        <NoData message={"Transaction Not Found"} />
      </PageContent>
    );
  }

  return (
    <PageContent title={{ page: "Transaction" }} opts={{ clear: true }}>
      <Stack gap={2}>
        <TransactionHeader transaction={data.transaction} />
        <TransactionDetail transaction={data.transaction} />
      </Stack>
    </PageContent>
  );
}
