import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { ErrorButton } from "components/Button";
import { WRITE_SSO_CREDENTIALS } from "context/permissions";
import { useAgent } from "context/agentContext";
import { useBasicActionModal } from "components/Modal";

export const useDeleteCredentialModal = () => {
  const { agent } = useAgent();
  const [BAModal, openModal] = useBasicActionModal();

  const SSOCredentialsDeleteModal = ({ onSuccess, onSubmit }) => {
    if (!agent.hasPermissions([WRITE_SSO_CREDENTIALS])) {
      return <div data-testid={`client-credentials-delete-modal-no-permissions`} />;
    }

    return (
      <BAModal
        onSubmitActionSuccess={onSuccess}
        actionText="Delete"
        submitAction={onSubmit}
        testIdPrefix="sso-credentials-delete-"
        titleText="Delete SSO Credentials"
      >
        <p>
          Are you sure? You can’t undo this action. This will remove all user’s access to Tilia
          Tools.
        </p>
      </BAModal>
    );
  };

  const SSOCredentialsDeleteButton = (props) => {
    if (!agent.hasPermissions([WRITE_SSO_CREDENTIALS])) {
      return <div data-testid={`client-credentials-delete-button-no-permissions`} />;
    }

    return (
      <ErrorButton data-testid="sso-credentials-delete-button" {...props} onClick={openModal}>
        Delete
      </ErrorButton>
    );
  };
  return [SSOCredentialsDeleteModal, SSOCredentialsDeleteButton];
};
