import { gql } from "@apollo/client";
import { useQuery } from "context/graphqlContext";
import { READ_PERMISSIONS } from "context/permissions";

const getUserToolsGroups = gql`
  query GetUserToolsGroups($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      permissions {
        groups {
          direct
          inherited
        }
      }
    }
  }
`;

const useUserToolsGroups = (accountId) => {
  return useQuery(getUserToolsGroups, {
    permissions: [READ_PERMISSIONS],
    variables: {
      accountId,
    },
  });
};

export default useUserToolsGroups;
