import { gql } from "@apollo/client";
import { useQuery } from "context/graphqlContext";
import { READ_PAYMENT_METHODS } from "context/permissions";

const getPaymentMethodPayments = gql`
  query GetPaymentMethodPayments($accountId: ID!, $paymentMethodId: String!) {
    payments(accountId: $accountId, paymentMethodId: $paymentMethodId) {
      paymentId
      invoiceId
      state
      provider
      providerTransactionId
      requestAmount
      requestCurrency
      processingAmount
      processingCurrency
      receivedAmount
      receivedCurrency
      created
      updated
      externalLink
    }
  }
`;

export const usePaymentMethodPayments = ({ accountId, paymentMethodId }) => {
  return useQuery(getPaymentMethodPayments, {
    permissions: [READ_PAYMENT_METHODS],
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      accountId,
      paymentMethodId,
    },
  });
};
