/**
 * @readonly
 * @enum {string}
 */
export const CurrencyDisplay = {
  /** Use a localized currency symbol such as €. */
  Symbol: "symbol",
  /** Use a narrow format symbol ("$100" rather than "US$100"). */
  NarrowSymbol: "narrowSymbol",
  /** Use the currency code. */
  Code: "code",
  /** Use a localized currency name such as "dollar". */
  Name: "name",
};

/**
 * Tilia virtual currency definition.
 * @typedef {Object} VirtualCurrency
 * @property {string} currency Virtual currency code: analogous to an ISO 4217 alphabetic code
 * @property {number} usd_cents_to_curr Multiplier to convert one US cent into the equivalent quantity of virtual currency units
 * @property {number} curr_to_usd_cents Multiplier to convert one unit of virtual currency into the equivalent quantity of US cents
 * @property {bool} enabled Whether or not this virtual currency can be transacted in the Tilia platform
 * @property {string} symbol The US-English localized symbol for the currency
 * @property {number} minor_unit The number of digits that represent the fractional virtual currency value, when stored as an integer
 * @property {number} display_minor_unit The number of digits a fractional virtual currency value should be rounded to for display only
 * @todo Add and document fee properties
 */

/**
 * @typedef {Object.<string, VirtualCurrency>} CurrencyInfo
 */
