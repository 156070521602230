import { TokenPurchaseStatusCode, TransactionType } from "@tilia-tools/core/transaction";
import { TransactionHeader } from "../Layout";
import TokenPurchaseStatusCodeName from "./TokenPurchaseStatusCodeName";

const statusColors = {
  [TokenPurchaseStatusCode.OPEN]: "orange",
  [TokenPurchaseStatusCode.PROCESSING]: "orange",
  [TokenPurchaseStatusCode.SUCCESS]: "green",
  [TokenPurchaseStatusCode.FAILED]: "red",
};

function TokenPurchaseHeader({ tokenPurchase }) {
  return (
    <TransactionHeader
      title="Token Purchase"
      transactionType={TransactionType.TOKEN_PURCHASE}
      badge={{
        label: (
          <TokenPurchaseStatusCodeName
            tokenPurchaseStatusCode={tokenPurchase.tokenPurchaseStatusCode}
          />
        ),
        color: statusColors[tokenPurchase.tokenPurchaseStatusCode],
      }}
      copyableId={tokenPurchase.tokenPurchaseId}
      date={tokenPurchase.date}
    />
  );
}

export default TokenPurchaseHeader;
