import { Box, useMultiStyleConfig } from "@chakra-ui/react";

function CardHeading({ children }) {
  const styles = useMultiStyleConfig("Card");

  return (
    <Box m={0} size="xs" as="h4" __css={styles.heading}>
      {children}
    </Box>
  );
}

export default CardHeading;
