import { OutlineButton } from "components/Button";
import { MenuButton } from "components/Menu";
import { useAgent } from "context/agentContext";
import { PAYOUTS_RESOURCE, WRITE_PROCESS_CREDITS } from "context/permissions";
import React from "react";
import { ACTIONS, STATUS } from "../constants";
import { strings } from "../strings";
import useAction from "../useAction";
import { canChangeStatus } from "../utils";
import { useViewPayout } from "./PayoutListModals";

const GridActions = ({ payout }) => {
  const action = useAction();
  const { agent } = useAgent();
  const { viewPayoutMenuItem } = useViewPayout(payout);
  const canWrite = agent.hasPermissions(WRITE_PROCESS_CREDITS, PAYOUTS_RESOURCE);

  if (canChangeStatus(payout) && canWrite) {
    return (
      <MenuButton
        data-testid="row-actions"
        buttonLabel={strings.actions}
        items={[
          {
            label: strings.approve,
            onSelect: () => action({ type: ACTIONS.APPROVE, payload: [payout] }),
          },
          {
            label: strings.on_hold,
            disabled: payout.status === STATUS.tiliaOnHold,
            onSelect: () => action({ type: ACTIONS.ON_HOLD, payload: [payout] }),
          },
          {
            label: strings.reject,
            onSelect: () => action({ type: ACTIONS.REJECT, payload: [payout] }),
          },
        ]}
      />
    );
  }

  return (
    <>
      <OutlineButton onClick={viewPayoutMenuItem.item.onSelect}>{strings.view}</OutlineButton>
      {viewPayoutMenuItem.comp}
    </>
  );
};

export default GridActions;
