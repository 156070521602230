// import { useSearchParams } from "react-router-dom";
import useAgentIsTiliaAgent from "context/useAgentIsTiliaAgent";
import { useFilter } from "components/Filter/FilterContext";
import Filters from "components/Filter/Filters.js";
// import { DateFilter, getDateLabel } from "components/Filter/inputs/DateFilter.js";
// import FilterSelect from "components/Filter/inputs/FilterSelect";
// import useConnectionPagination from "components/Pager/useConnectionPagination";
import IntegratorSelect from "components/IntegratorSelect/IntegratorSelect";

// const sortOptions = [
//   { value: "NEWEST", label: "Newest to Oldest" },
//   { value: "OLDEST", label: "Oldest to Newest" },
//   { value: "SMALLEST", label: "Amount Low to High" },
//   { value: "LARGEST", label: "Amount High to Low" },
// ];
//
function HistoricalSettlementsFilters() {
  const { filterParams, handleRemoveFilter, handleAddFilter } = useFilter();
  // const { changeSort } = useConnectionPagination();
  // const [searchParams] = useSearchParams();
  const isTiliaAgent = useAgentIsTiliaAgent();

  const filterOptions = [
    // TODO: enable sorting once API support is in place
    // {
    //   key: "sort",
    //   primary: true,
    //   Option: (
    //     <FilterSelect
    //       label="Sort"
    //       name="sort"
    //       value={searchParams.has("sort") ? searchParams.get("sort") : "NEWEST"}
    //       onChange={(_, val) => changeSort(val)}
    //       options={sortOptions}
    //     />
    //   ),
    // },
  ];

  if (isTiliaAgent) {
    filterOptions.push({
      key: "integrator",
      Option: (
        <IntegratorSelect
          selectedOptions={filterParams.integrator}
          onChange={(integrator) => handleAddFilter("integrator", integrator)}
        />
      ),
    });
  }

  // TODO: enable date filter once API support is in place
  // filterOptions.push({
  //   key: "date",
  //   Option: (
  //     <DateFilter
  //       selectedOptions={filterParams.date}
  //       onAddDate={(date) => handleAddFilter("date", date)}
  //     />
  //   ),
  // });

  let appliedFilters = [];

  filterParams.integrator.forEach((val) => {
    appliedFilters.push({
      key: "integrator",
      label: val,
      value: val,
    });
  });

  // filterParams.date.forEach((val) => {
  //   appliedFilters.push({
  //     key: "date",
  //     label: getDateLabel(val),
  //     value: val,
  //   });
  // });

  return (
    <Filters
      appliedFilters={appliedFilters}
      onRemoveFilter={handleRemoveFilter}
      filterOptions={filterOptions}
    />
  );
}

export default HistoricalSettlementsFilters;
