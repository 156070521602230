import { Table, Tbody, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { ErrorBoundary } from "components/ErrorBoundary";
import { Money } from "components/Money";
import { isNilOrEmpty } from "utils";
import { snakeToTitleCase } from "utils/format";
import { DateAndTime } from "../../../../../../components/DataRow/index";
import { TransactionHistoryActionMenu } from "../TransactionHistoryActionMenu";
import { useAccount } from "context/accountContext";
import { useAgent } from "context/agentContext";
import { WRITE_REFUNDS } from "context/permissions";
import { Container, ExpandibleContent, MoneyCell } from "./common";
import ErrorGridRow from "./ErrorGridRow";
import PayoutGridRow from "./PayoutGridRow";
import TokenPurchaseGridRow from "./TokenPurchaseGridRow";
import TokenConversionGridRow from "./TokenConversionGridRow";
import BalanceTransferGridRow from "./BalanceTransferGridRow";
import CounterPartySummaryList from "components/Transaction/CounterPartySummaryList";
import PartyRoleName from "components/Transaction/PartyRoleName";
import TransactionTypeName from "components/Transaction/TransactionTypeName";
import EscrowPurchaseStatusCodeName from "components/Transaction/EscrowPurchase/EscrowPurchaseStatusCodeName";
import PurchaseStatusCodeName from "components/Transaction/UserPurchase/PurchaseStatusCodeName";
import { TransactionPartyRole, TransactionType } from "@tilia-tools/core/transaction";
import TransactionDetailsLink from "components/Transaction/TransactionDetailsLink";
import DepositStatusCodeName from "components/Transaction/Deposit/DepositStatusCodeName";
import RefundStatusCodeName from "components/Transaction/Refund/RefundStatusCodeName";

const TransactionStatus = ({ transaction }) => {
  switch (transaction.type) {
    case TransactionType.ESCROW_PURCHASE:
      return (
        <EscrowPurchaseStatusCodeName
          escrowPurchaseStatusCode={transaction.escrowPurchaseStatusCode}
        />
      );
    case TransactionType.DEPOSIT:
      return <DepositStatusCodeName depositStatusCode={transaction.purchaseStatusCode} />;
    case TransactionType.REFUND:
      return <RefundStatusCodeName refundStatusCode={transaction.purchaseStatusCode} />;
    case TransactionType.PURCHASE:
    case TransactionType.MAKE_GOOD:
    default:
      return <PurchaseStatusCodeName purchaseStatusCode={transaction.purchaseStatusCode} />;
  }
};

const Description = ({ transaction }) => {
  if (isNilOrEmpty(transaction.invoiceReferenceType)) return <span> --- </span>;
  return (
    <span>
      {snakeToTitleCase(transaction.invoiceReferenceType)} - {transaction.invoiceReferenceId}
    </span>
  );
};

const FirstPartyAmounts = ({ accountId, parties }) => {
  const firstParties = parties.filter((party) => {
    return party.account?.accountId === accountId;
  });

  const amounts = [];
  for (const party of firstParties) {
    if (party.payments) {
      for (const payment of party.payments) {
        amounts.push({
          key: `payment-${payment.paymentId}`,
          amount: -payment.processingAmount,
          currencyCode: payment.processingCurrency,
          description: payment.paymentMethod.displayString,
        });
      }
    } else {
      amounts.push({
        key: `${party.role}-${accountId}-${party.currencyCode}`,
        amount: party.amount,
        currencyCode: party.currencyCode,
        description: null,
      });
    }
  }

  return (
    <Table size="sm">
      <Tbody>
        {amounts.map((amount) => (
          <Tr key={amount.key}>
            <Td isNumeric={true}>
              <Money value={amount.amount} currency={amount.currencyCode} />
            </Td>
            <Td>{amount.description}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

const CounterPartyExpandibleContent = ({ accountId, parties }) => {
  let counterParties = parties.filter((party) => {
    // TILIA and TAX roles do not have an account
    if (!party.account) {
      return true;
    }
    return party.account.accountId !== accountId;
  });

  // if the displayed account has the SELLER role, extract and show their line items on the BUYER row
  const sellerLineItems = new Map();
  for (const party of parties) {
    if (party.account?.accountId === accountId && party.role === TransactionPartyRole.SELLER) {
      sellerLineItems.set(party.currencyCode, party.lineItems);
    }
  }
  if (sellerLineItems.size > 0) {
    counterParties = counterParties.map((party) => {
      if (party.role === TransactionPartyRole.BUYER && sellerLineItems.has(party.currencyCode)) {
        return { ...party, lineItems: sellerLineItems.get(party.currencyCode) };
      }
      return party;
    });
  }

  return (
    <ExpandibleContent>
      {counterParties.map((party) => (
        <Container key={`${party.role}-${party.account?.accountId || ""}-${party.currencyCode}`}>
          <div />
          <div>
            <PartyRoleName role={party.role} />
          </div>
          <div style={{ gridColumn: "3 / span 2" }}>
            {party.lineItems?.map((lineItem) => lineItem.description).join(", ")}
          </div>
          <div>{party.account?.username || party.account?.accountId}</div>
          <MoneyCell>
            <Money value={party.amount} currency={party.currencyCode} />
          </MoneyCell>
          <div />
        </Container>
      ))}
    </ExpandibleContent>
  );
};

const TransactionGridRow = ({ transaction, refetch }) => {
  switch (transaction.type) {
    case TransactionType.PAYOUT:
      return <PayoutGridRow payout={transaction} refetch={refetch} />;
    case TransactionType.TOKEN_PURCHASE:
      return <TokenPurchaseGridRow tokenPurchase={transaction} refetch={refetch} />;
    case TransactionType.TOKEN_CONVERSION:
      return <TokenConversionGridRow tokenConversion={transaction} />;
    case TransactionType.BALANCE_TRANSFER:
      return <BalanceTransferGridRow balanceTransfer={transaction} />;
    default:
      return <LegacyTransactionGridRow transaction={transaction} refetch={refetch} />;
  }
};

const LegacyTransactionGridRow = ({ transaction, refetch }) => {
  const { isOpen, onToggle } = useDisclosure();

  const { agent } = useAgent();

  const { accountId, accountState } = useAccount();
  const detailsData = accountState?.details?.data || {};

  const isExpandable = transaction.parties.length > 1;

  let menuSettings = [];

  if (agent.hasPermissions([WRITE_REFUNDS])) menuSettings.push("REFUND");

  return (
    <>
      <Container
        isExpanded={isOpen}
        isExpandable={isExpandable}
        data-testid="transaction-row"
        onClick={onToggle}
      >
        <div>
          <DateAndTime value={transaction.date} />
          <TransactionDetailsLink transaction={transaction} />
        </div>

        <div>
          <div>
            <TransactionTypeName transactionType={transaction.type} />
          </div>
          <div>
            <TransactionStatus transaction={transaction} />
          </div>
        </div>

        <Description transaction={transaction} />

        <div style={{ padding: "2px 12px" }}>
          <FirstPartyAmounts accountId={accountId} parties={transaction.parties} />
        </div>

        <div style={{ padding: "2px 12px", gridColumn: "5 / span 2" }}>
          <CounterPartySummaryList accountId={accountId} parties={transaction.parties} />
        </div>

        <div>
          {menuSettings && (
            <TransactionHistoryActionMenu
              detailsData={{
                ...detailsData,
                transaction,
              }}
              refetch={refetch}
              menuSettings={menuSettings}
            />
          )}
        </div>
        {isOpen && (
          <CounterPartyExpandibleContent accountId={accountId} parties={transaction.parties} />
        )}
      </Container>
    </>
  );
};

const GridRow = ({ transaction, cursor, refetch }) => {
  return (
    <ErrorBoundary fallback={<ErrorGridRow transaction={transaction} cursor={cursor} />}>
      <TransactionGridRow transaction={transaction} refetch={refetch} />
    </ErrorBoundary>
  );
};

export default GridRow;
