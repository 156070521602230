import { AccountLink } from "components/Account";
import IntegratorLogo from "components/IntegratorLogo/IntegratorLogo";
import styled from "styled-components";
import { row } from "./common";

const Container = styled.div`
  ${row};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const GridRow = ({ account }) => {
  return (
    <Container data-testid="account-row">
      <div>
        <AccountLink account={account}>{account.account_id}</AccountLink>
      </div>
      <div>
        <AccountLink account={account}>{account.username}</AccountLink>
      </div>
      <div>
        <IntegratorLogo integratorName={account.integrator} />
      </div>
    </Container>
  );
};

export default GridRow;
