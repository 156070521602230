import { gql, useQuery } from "@apollo/client";

const getCurrentSettlementStatus = gql`
  query GetCurrentSettlementStatus($settlementId: ID!, $integrator: NonEmptyString!) {
    settlement(settlementId: $settlementId, integrator: $integrator) {
      settlementId
      integrator
      settlementStatusCode
    }
  }
`;

function useCurrentSettlementStatus(settlementIdentifier) {
  // in most use cases, this query will be satisfied by the local query cache
  return useQuery(getCurrentSettlementStatus, {
    errorPolicy: "all",
    skip: !settlementIdentifier,
    variables: {
      settlementId: settlementIdentifier?.settlementId,
      integrator: settlementIdentifier?.integrator,
    },
  });
}

export default useCurrentSettlementStatus;
