import React from "react";
import * as R from "ramda";
import "styled-components/macro";
import { useAgent } from "context/agentContext";
import * as Permissions from "context/permissions";
import { SubNavRow, SubNavLinkItem } from "containers/Nav/SubNav";

export const PublisherNav = () => {
  const { agent } = useAgent();
  // order here is the order displayed in the UI
  const config = [
    {
      to: "",
      name: "Product",
      requiredPermissions: [Permissions.READ_PRODUCT_CATALOG],
      activeFn: R.equals,
    },
    {
      to: "sso",
      name: "SSO Management",
      requiredPermissions: [Permissions.READ_SSO_CREDENTIALS],
    },
    {
      to: "user-provisioning",
      name: "User Provisioning",
      requiredPermissions: [Permissions.READ_PERMISSIONS],
    },
  ];
  return (
    <SubNavRow>
      <div>
        {R.pipe(
          R.filter((c) => agent.hasPermissions(c.requiredPermissions || [])),
          R.map((c) => (
            <SubNavLinkItem
              compat={true}
              key={c.to}
              to={c.to}
              activeFn={c.activeFn}
              routeSet={R.pluck("to", config)}
            >
              {c.name}
            </SubNavLinkItem>
          ))
        )(config)}
      </div>
    </SubNavRow>
  );
};
