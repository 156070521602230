import React from "react";
import styled from "styled-components";
import { RefundSummaryStatusCode } from "@tilia-tools/core/transaction";
import { Table, Thead, Tbody, Td, Tr, Th, Tfoot } from "../Table";
import { Money } from "../Money";
import { NoteField, SelectField, TextField } from "components/Forms";
import RefundReason from "./RefundReason";
import useGetRefundReasons from "./useGetRefundReasons";
import calcLineItemRefundableAmount from "./calcLineItemRefundableAmount";
import TotalRefundAmount from "./TotalRefundAmount";
import { REFUND_TYPES } from "./getTransactionRefundTypes";
import { DetailText } from "components/Text";

const LineItemWrapper = styled.div`
  justify-self: start;
  font-size: 11px;
  font-weight: 500;
`;

const LineItemDetails = styled.div`
  margin-top: 8px;
`;

const LineItemDescription = styled.div`
  font-size: 14px;
`;

const RefundFormBody = ({ transaction, refundTypes }) => {
  const REFUND_REASONS = useGetRefundReasons();
  const refundReasonOptions = REFUND_REASONS.map((reason) => ({
    label: <RefundReason reason={reason} />,
    value: reason,
  }));

  return (
    <>
      <Table>
        <Thead bordered={true}>
          <Tr>
            <Th />
            {refundTypes.includes(REFUND_TYPES.partial_refund) && (
              <Th rightAlign={true}>Refundable Amount</Th>
            )}
            <Th rightAlign={true}>Refund Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {transaction.lineItems &&
            transaction.lineItems.map((lineItem, index) => (
              <Tr key={lineItem.lineItemId} style={{ verticalAlign: "baseline" }} pull={true}>
                <Td>
                  <LineItemWrapper>
                    <LineItemDescription>{lineItem.description}</LineItemDescription>
                    <LineItemDetails>
                      <div>
                        Reference: <DetailText>{lineItem.referenceId}</DetailText>
                      </div>
                      <div>
                        SKU: <DetailText>{lineItem.productSku}</DetailText>
                      </div>
                    </LineItemDetails>
                  </LineItemWrapper>
                </Td>
                <Td rightAlign={true}>
                  <Money
                    value={calcLineItemRefundableAmount(lineItem)}
                    currency={lineItem.currency}
                  />
                </Td>
                {refundTypes.includes(REFUND_TYPES.partial_refund) && (
                  <Td>
                    <TextField
                      disabled={lineItem.refundSummary.status === RefundSummaryStatusCode.FULL}
                      name={`lineItems[${index}].amount`}
                      inputCssOverrides={`text-align:right;`}
                    />
                  </Td>
                )}
              </Tr>
            ))}
        </Tbody>
        <Tfoot bordered={true}>
          <Tr pull={true}>
            <Td>
              Total Refund Amount
              <br />
              <DetailText>
                Wallet balance will be refunded before any other payment methods.
              </DetailText>
            </Td>
            {refundTypes.includes(REFUND_TYPES.partial_refund) && <Td />}
            <Td rightAlign={true}>
              <TotalRefundAmount transaction={transaction} />
            </Td>
          </Tr>
        </Tfoot>
      </Table>

      <div>
        <SelectField name="reason" label="Reason" required options={refundReasonOptions} />
      </div>
      <div>
        <NoteField data-testid={"refund-note"} name="note" label="Note" />
      </div>
    </>
  );
};
export default RefundFormBody;
