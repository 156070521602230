import { ColumnHeader } from "components/Grid";
import React from "react";
import styled from "styled-components";
import { FIELDS } from "../constants";
import { strings } from "../strings";
import { row } from "./common";

const Container = styled.div`
  ${row};
  padding-bottom: 8px;
`;

const GridHeader = () => {
  return (
    <Container data-testid="grid-header">
      {[
        { field: FIELDS.account_id, content: strings.id, sortable: true },
        { field: FIELDS.username, content: strings.username, sortable: true },
        { field: FIELDS.integrator, content: strings.publisher },
      ].map(({ field, content, sortable }) => (
        <ColumnHeader key={`header-${field}`} field={field} sortable={sortable}>
          {content}
        </ColumnHeader>
      ))}
    </Container>
  );
};

export default GridHeader;
