import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import { FilterItemButton } from "components/Button";

export default function Filter({ filterOptions = [], onRemoveFilter, appliedFilters }) {
  const primaryOptions = filterOptions.filter(({ primary }) => primary);
  const secondaryOptions = filterOptions.filter(({ primary }) => !primary);

  return (
    <Box mb={5}>
      <Flex>
        <Flex flexGrow={1} gap={4}>
          {/* We use an array of options so that we can make it easy for mobile in the future */}
          {primaryOptions.map(({ key, Option }) => {
            return <React.Fragment key={key}>{Option}</React.Fragment>;
          })}
          {primaryOptions.length !== 0 && secondaryOptions.length !== 0 && (
            <Box height={20} w={"1px"} backgroundColor="gray.300" />
          )}
          {secondaryOptions.map(({ key, Option }) => {
            return <React.Fragment key={key}>{Option}</React.Fragment>;
          })}
        </Flex>
      </Flex>

      {appliedFilters &&
        appliedFilters.map((filter) => (
          <FilterItemButton
            onClick={() => onRemoveFilter(filter.key, filter.value)}
            key={`${filter.key} - ${filter.value}`}
          >
            {filter.label}
          </FilterItemButton>
        ))}
    </Box>
  );
}
