import GridContext from "components/Grid/GridContext";
import { MenuButton } from "components/Menu";
import React, { useContext } from "react";
import styled from "styled-components";
import { ACTIONS } from "../constants";
import { strings } from "../strings";
import useAction from "../useAction";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > h1 {
    margin: 0 0 1rem 0;
  }
`;

const GridToolbar = () => {
  const action = useAction();
  const { pageSelection } = useContext(GridContext);
  const hasSelection = pageSelection?.length > 0;

  return (
    <Container>
      <h1>{strings.payouts}</h1>

      <MenuButton
        data-testid="multi-actions"
        disabled={!hasSelection}
        buttonLabel={strings.actions}
        items={[
          {
            label: strings.approve,
            onSelect: () => action({ type: ACTIONS.APPROVE, payload: pageSelection }),
          },
          {
            label: strings.on_hold,
            onSelect: () => action({ type: ACTIONS.ON_HOLD, payload: pageSelection }),
          },
          {
            label: strings.reject,
            onSelect: () => action({ type: ACTIONS.REJECT, payload: pageSelection }),
          },
        ]}
      />
    </Container>
  );
};

export default GridToolbar;
