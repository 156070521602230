import { useQuery } from "@apollo/client";
import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Link } from "components/Link";
import { NoData } from "components/NoData";
import { PageContent, PageTitle } from "components/Page";
import { Panel } from "components/Panel";
import { PermissionsMessage } from "components/PermissionsMessage";
import { FullPageSpinner } from "components/Spinner";
import { getIntegrators } from "context/graphqlContext";
import { READ_INTEGRATORS } from "context/permissions";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { exists } from "utils";

export const Publisher = () => {
  const hasPermission = useAgentHasPermissions(READ_INTEGRATORS);
  const navigate = useNavigate();

  const [filteredIntegrators, setFilteredIntegrators] = useState([]);
  const { loading } = useQuery(getIntegrators, {
    skip: !hasPermission,
    onCompleted: (data) => {
      if (data.integrators.length === 1) {
        navigate(`${data.integrators[0].id}`);
      }
      const integrators = data.integrators.filter(({ id }) => id !== "tilia");
      integrators.sort((a, b) => a.displayName.localeCompare(b.displayName));
      setFilteredIntegrators(integrators);
    },
  });

  if (!hasPermission) {
    return (
      <PageContent>
        <Panel
          css={`
            padding: 8px;
          `}
        >
          <PermissionsMessage />
        </Panel>
      </PageContent>
    );
  }

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <PageContent title={{ page: "Publishers" }} opts={{ clear: true }}>
      <PageTitle>Publishers</PageTitle>

      {exists(filteredIntegrators) ? (
        <TableContainer>
          <Table size="md" variant="simple" data-testid="publisher-list">
            <Thead>
              <Tr>
                <Th>Name</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredIntegrators.map(({ displayName, id }) => (
                <Tr key={displayName}>
                  <Td>
                    <Link to={`${id}`}>
                      {displayName}
                      {displayName.toLowerCase() !== id.toLowerCase() && <Box ml={1}>({id}) </Box>}
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <NoData />
      )}
    </PageContent>
  );
};
