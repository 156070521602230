import React from "react";
import "styled-components/macro";
import { Table, Thead, Th, Tbody, Tr, Td } from "components/Table";
import { AccountLink } from "components/Account";
import UsersTableActionMenu from "./UsersTableActionMenu";

const UserRow = ({ user, onDeactivateUser }) => {
  const directGroups = [...user.permissions.groups.direct].sort();
  const inheritedGroups = [...user.permissions.groups.inherited].sort();
  return (
    <Tr key={user.accountId} pull={true}>
      <Td>{user.fullName}</Td>
      <Td>
        <AccountLink account={user}>{user.username}</AccountLink>
      </Td>
      <Td>
        {directGroups.join(", ")}
        {inheritedGroups.length > 0 && (
          <>
            <br />
            <span
              css={`
                font-size: 0.8em;
              `}
            >
              {inheritedGroups.join(", ")}
            </span>
          </>
        )}
      </Td>
      <Td>
        <UsersTableActionMenu user={user} onDeactivateUser={onDeactivateUser} />
      </Td>
    </Tr>
  );
};

const UsersTable = ({ users, onDeactivateUser }) => {
  return (
    <Table>
      <Thead bordered={true}>
        <Tr pull={true}>
          <Th>Name</Th>
          <Th>Email</Th>
          <Th>Role</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody bordered={true}>
        {users.map((user) => (
          <UserRow key={user.accountId} user={user} onDeactivateUser={onDeactivateUser} />
        ))}
      </Tbody>
    </Table>
  );
};

export default UsersTable;
