import { useLocation } from "react-router-dom";
import { NoData, withGrid } from "components/Grid";
import { GridLoading, SORT_DIRECTION } from "components/Grid/utils";
import { PageContent } from "components/Page";
import { Panel } from "components/Panel";
import { PermissionsMessage } from "components/PermissionsMessage";
import { useAccount } from "context/accountContext";
import { getSearchResults, useQuery } from "context/graphqlContext";
import { SEARCH_ACCOUNTS, SEARCH_RESOURCE, withPermissions } from "context/permissions";
import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components/macro"; //eslint-disable-line no-unused-vars
import { getSearchQueryString } from "utils";
import GridHeader from "./components/GridHeader";
import GridRow from "./components/GridRow";
import { FIELDS } from "./constants";
import ResultViewMore from "./ResultViewMore";
import { strings } from "./strings";
import { GridContext } from "components/Grid";

const GridFooter = styled.div`
  margin-top: 1rem;
`;

const ResultFor = styled.p`
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
`;

let Search = ({ permissions }) => {
  // @Variables
  const { hasPermission } = permissions;
  const location = useLocation();

  const { currentPage, pageSize, setData, setTotalCount, sortDir, sortField } =
    useContext(GridContext);
  const { resetAccountState } = useAccount();
  const qsQuery = getSearchQueryString(location.search);
  const [searchCriteria, setSearchCriteria] = useState(qsQuery);
  const { data, loading } = useQuery(getSearchResults, {
    permissions: [SEARCH_ACCOUNTS],
    resource: SEARCH_RESOURCE,
    variables: {
      searchCriteria,
      currentPage,
      pageSize,
      sortDir,
      sortField,
    },
  });

  useEffect(() => {
    if (data?.ToolsSearch?.accounts) {
      setData(data.ToolsSearch.accounts);
    }
    if (data?.ToolsSearch?.meta?.total) {
      setTotalCount(data.ToolsSearch.meta.total);
    }
  }, [setData, setTotalCount, data]);

  useEffect(() => {
    setSearchCriteria(qsQuery);
  }, [qsQuery, setSearchCriteria]); // eslint-disable-line

  const accounts = data?.ToolsSearch?.accounts;
  const hasData = accounts?.length > 0;

  // @Effects
  useEffect(() => {
    if (hasPermission && qsQuery) {
      resetAccountState();
    }
  }, [qsQuery, resetAccountState, hasPermission]);

  const getMainContent = () => {
    return (
      <Panel>
        <ResultFor data-testid="result-for" key="result-for">
          {strings.results_for.format(qsQuery)}
        </ResultFor>
        <h1 data-testid="grid-title" key="grid-title">
          {strings.accounts}
        </h1>
        {!hasData && !loading && <NoData key="grid-no-data" />}
        {hasData && (
          <>
            <GridHeader key="grid-header" />

            <div data-testid="grid-body" key="grid-body">
              {accounts.map((account) => (
                <GridRow key={account[FIELDS.account_id]} account={account} />
              ))}
            </div>

            <GridFooter key="grid-footer">
              <ResultViewMore length={accounts.length} />
            </GridFooter>
          </>
        )}
        {loading && (
          <div
            css={`
              min-height: calc(100vh - 61px);
            `}
          >
            <GridLoading key="grid-loading" />
          </div>
        )}
      </Panel>
    );
  };

  return (
    <PageContent title={{ page: "Search", sub1: searchCriteria }} opts={{ clear: true }}>
      {!hasPermission && <PermissionsMessage />}
      {hasPermission && getMainContent()}
    </PageContent>
  );
};

Search = withPermissions({ permissions: [SEARCH_ACCOUNTS], resource: SEARCH_RESOURCE }, Search);

Search = withGrid(Search, {
  keyField: FIELDS.account_id, // Useful when selection is enabled
  sortDir: SORT_DIRECTION.ASC,
  sortField: FIELDS.account_id,
});

export { Search };
