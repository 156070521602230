import { InvoicingApiInvoiceType } from "../../invoicingApi/index.js";

/**
 * Make good transaction status codes.
 * @enum {string}
 */
export const MakeGoodStatusCode = Object.freeze({
  OPEN: "OPEN",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  SYSTEM_CANCELED: "SYSTEM_CANCELED",
});

/**
 * Make Good transaction invoice types.
 * @type {Set<InvoicingApiInvoiceType>}
 */
export const MakeGoodInvoiceTypes = new Set([InvoicingApiInvoiceType.MakeGoodIT]);
