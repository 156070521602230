import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { exists } from "utils";

function useUpdateFilterSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  return useCallback(
    (name, value) => {
      if (exists(value)) {
        searchParams.set(name, value);
      } else {
        searchParams.delete(name);
      }

      // reset pagination any time the filter changes
      if (searchParams.has("first")) {
        // leave it as is
      } else if (searchParams.has("last")) {
        searchParams.set("first", searchParams.get("last"));
      }
      searchParams.delete("after");
      searchParams.delete("last");
      searchParams.delete("before");

      // do not adjust "limit" if it exists
      searchParams.delete("offset");

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );
}

export default useUpdateFilterSearchParams;
