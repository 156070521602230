import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import { Container } from "./common";

function ErrorGridRow({ transaction, cursor }) {
  return (
    <Container>
      <div style={{ padding: "6px 0", gridColumn: "1 / span 7" }}>
        <Alert status="error" width="auto">
          <AlertIcon />
          <AlertDescription>
            There was an error attempting to process{" "}
            {transaction
              ? `${transaction.type} transaction ID ${transaction.id}`
              : `transaction cursor ${cursor}`}
          </AlertDescription>
        </Alert>
      </div>
    </Container>
  );
}

export default ErrorGridRow;
