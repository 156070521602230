import React from "react";
import { useAgent } from "context/agentContext";
import { useIntegrator } from "context/integratorContext";
import { PageContent, PageTitle } from "components/Page";
import { ProductCatalog } from "components/ProductCatalog";

export const ProductCatalogPage = () => {
  const { agent } = useAgent();
  const integrator = useIntegrator(agent.integrator);
  return (
    <PageContent title={{ sub1: "Product Catalog" }}>
      <PageTitle>Product Catalog</PageTitle>
      <ProductCatalog integrator={integrator} withPanelTitle={false} />
    </PageContent>
  );
};
