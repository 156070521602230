import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { CopyableText } from "components/Copyable";
import { Money } from "components/Money";
import { DetailText } from "components/Text";

function PaymentMethodDetail({ label, children }) {
  const gray600 = useColorModeValue("gray.600", "gray.200");

  return (
    <Flex flexDir={"column"} gap={"2px"}>
      <Text as="span" color={gray600} fontWeight={700}>
        {label}:
      </Text>
      <DetailText>{children || "-"}</DetailText>
    </Flex>
  );
}

function TokenConversionRecipient({ tokenConversion }) {
  return (
    <Accordion defaultIndex={0}>
      <AccordionItem>
        <h3>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              {tokenConversion.destinationWallet.description}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h3>
        <AccordionPanel>
          <PaymentMethodDetail label="Amount">
            <Money
              value={tokenConversion.convertedAmount}
              currency={tokenConversion.convertedCurrencyCode}
            />
          </PaymentMethodDetail>
          <PaymentMethodDetail label="Wallet ID">
            <CopyableText text={tokenConversion.destinationWallet.id}>
              {tokenConversion.destinationWallet.id}
            </CopyableText>
          </PaymentMethodDetail>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default TokenConversionRecipient;
