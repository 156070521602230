import { useMemo } from "react";
import { useAgent } from "context/agentContext";
import { getBrowserTimeZone } from "utils/dates";

export const useAgentTimeZone = () => {
  const { agentSettings } = useAgent();

  return useMemo(() => {
    if (agentSettings.timeZone) {
      return agentSettings.timeZone;
    }
    return getBrowserTimeZone();
  }, [agentSettings.timeZone]);
};
