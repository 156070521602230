import * as R from "ramda";
import { useContext } from "react";
import { AppConfigContext } from "context/appConfigContext";

// on the chance that the currency is not defined,
// display raw values with the following configuration
const rawCurrencyDisplayInfo = (currency) => ({
  symbol: currency,
  minor_unit: 0,
  display_minor_unit: 0,
});

const usdDisplayInfo = {
  symbol: "USD",
  curr_to_usd_cents: 1,
  usd_cents_to_curr: 1,
  minor_unit: 2,
  display_minor_unit: 2,
};

const getCurrencyDisplayInfo = (appConfig, currency) => {
  // we check to see if the currency given is a known fiat value and
  // set the configuration information for that
  // else use the raw display info defined above
  const fiatOrDefault = currency === "USD" ? usdDisplayInfo : rawCurrencyDisplayInfo(currency);

  // here we check to see if the currency is a
  // defined virtual currency given to us by the appConfig
  // if not, we return the fiat/default value above
  const info = R.pathOr(fiatOrDefault, ["currencyInfo", currency], appConfig);
  info.denominationMultiplier = Math.pow(10, info.minor_unit);
  info.getValueByLowestDenominationRounded = (value) => {
    const floatValue = parseFloat(value) || 0;
    return Math.round(floatValue * info.denominationMultiplier, 0);
  };
  return info;
};

/**
 * @deprecated Use <Money> or useCurrencyFormatter() instead
 */
const useCurrencyDisplayInfo = (currency) => {
  const appConfig = useContext(AppConfigContext);

  return getCurrencyDisplayInfo(appConfig, currency);
};

export default useCurrencyDisplayInfo;
