import { gql } from "@apollo/client";
import { useQuery } from "context/graphqlContext";
import { READ_PERMISSIONS } from "context/permissions";

const getIntegratorToolsUsers = gql`
  query GetIntegratorToolsUsers($integrator: String!) {
    integrator(id: $integrator) {
      toolsUsers {
        accountId
        integrator
        fullName
        username
        permissions {
          scopes
          groups {
            direct
            inherited
          }
        }
      }
    }
  }
`;

const useIntegratorToolsUsers = (integrator) => {
  return useQuery(getIntegratorToolsUsers, {
    permissions: [READ_PERMISSIONS],
    variables: {
      integrator,
    },
  });
};

export default useIntegratorToolsUsers;
