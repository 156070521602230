import Filters from "components/Filter/Filters.js";
import IntegratorSelect from "components/IntegratorSelect/IntegratorSelect";
import useAgentIsTiliaAgent from "context/useAgentIsTiliaAgent";
import { DateFilter } from "components/Filter/inputs/DateFilter.js";
import { useFilter } from "components/Filter/FilterContext";

function DashboardFilters() {
  const { filterParams, handleChangeFilter } = useFilter();
  const isTiliaAgent = useAgentIsTiliaAgent();
  const filterOptions = [
    ...(isTiliaAgent
      ? [
          {
            key: "integrator",
            Option: (
              <IntegratorSelect
                onChange={(integrator) => handleChangeFilter("integrator", integrator)}
                value={filterParams.integrator}
              />
            ),
          },
        ]
      : []),
    {
      key: "date",
      Option: (
        <DateFilter
          value={filterParams.date}
          onAddDate={(date) => handleChangeFilter("date", date)}
          optionExclusions={["today"]}
        />
      ),
    },
  ];
  return <Filters filterOptions={filterOptions} />;
}

export default DashboardFilters;
