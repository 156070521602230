import React, { useState } from "react";
import "styled-components/macro";
import { Route, Routes } from "react-router-dom";
import { READ_SETTINGS } from "context/permissions";
import { PageContent, PageTitle } from "components/Page";
import { theme } from "styles";
import FilterContextProvider from "components/Filter/FilterContext";
import { SettingsFilters } from "./SettingsFilters";
import { SettingsData } from "./SettingsData";
import { PermissionsCheck } from "components/PermissionsMessage";
import { Comparinator } from "./Comparinator";

export const Settings = () => (
  <Routes>
    <Route index element={<SingleSettings />} />
    <Route path="compare/:p1?/:p2?" element=<Comparinator /> />
  </Routes>
);

export const SingleSettings = () => {
  const [currentSettings, setCurrentSettings] = useState(null);

  return (
    <PermissionsCheck permissions={READ_SETTINGS}>
      <FilterContextProvider
        config={[
          {
            name: "integrator",
            default: "",
          },
          {
            name: "settingKey",
          },
          {
            name: "f",
          },
        ]}
      >
        <PageContent title={{ page: "Settings" }} opts={{ clear: true }}>
          <div
            css={`
              position: sticky;
              top: 61px;
              background: ${theme("colors.pageBg")};
            `}
          >
            <PageTitle>Settings</PageTitle>
            <SettingsFilters currentSettings={currentSettings} />
          </div>
          <SettingsData onLoad={setCurrentSettings} />
        </PageContent>
      </FilterContextProvider>
    </PermissionsCheck>
  );
};
