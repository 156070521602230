import { useFilter } from "components/Filter";
import { NoData } from "components/Grid";
import { GridLoading } from "components/Grid/utils";
import { PageContent } from "components/Page";
import Pager from "components/Pager";
import useConnectionPagination from "components/Pager/useConnectionPagination";
import useConnectionPaginationOptions from "components/Pager/useConnectionPaginationOptions";
import { Panel } from "components/Panel";
import { PermissionsMessage } from "components/PermissionsMessage";
import { useAccount } from "context/accountContext";
import styled from "styled-components/macro";
import GridHeader from "./components/GridHeader";
import GridRow from "./components/GridRow";
import useAccountTransactions from "./useAccountTransactions";

const INITIAL_SORT = "NEWEST";
const INITIAL_PAGE_SIZE = 25;

const Grid = styled.div`
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, max-content) 1fr repeat(4, max-content);
`;

const TransactionGrid = ({ edges, refetch }) => {
  if (edges.length < 1) {
    return <NoData showRefresh={false} />;
  }

  return (
    <Grid data-testid="grid">
      <GridHeader />
      {edges.map((edge) => (
        <GridRow key={edge.cursor} transaction={edge.node} cursor={edge.cursor} refetch={refetch} />
      ))}
    </Grid>
  );
};

export default function TransactionHistoryDisplay() {
  const { accountId } = useAccount();
  const { gqlFilterParams } = useFilter();

  const paginationOptions = useConnectionPaginationOptions(INITIAL_SORT, INITIAL_PAGE_SIZE);

  const { data, loading, permissionsFailed, refetch } = useAccountTransactions({
    accountId,
    filter: gqlFilterParams,
    ...paginationOptions,
  });

  const { setPageSize, changePage } = useConnectionPagination(data?.account?.transactions);

  if (permissionsFailed) {
    return (
      <PageContent>
        <PermissionsMessage />
      </PageContent>
    );
  }

  return (
    <Panel>
      {loading || !data ? (
        <GridLoading />
      ) : (
        <TransactionGrid edges={data.account.transactions.edges} refetch={refetch} />
      )}
      <Pager
        setPage={changePage}
        pageSize={paginationOptions.first || paginationOptions.last}
        updatePageSize={setPageSize}
        connection={data?.account?.transactions || {}}
      />
    </Panel>
  );
}
