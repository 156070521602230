import React from "react";
import * as R from "ramda";
import "styled-components/macro";
import { useAgent } from "context/agentContext";
import * as Permissions from "context/permissions";
import { SubNavRow, SubNavLinkItem } from "containers/Nav/SubNav";

export const AccountNav = () => {
  const { agent } = useAgent();
  // order here is the order displayed in the UI
  const config = [
    { to: "", name: "Details", resource: Permissions.DETAILS_RESOURCE, activeFn: R.equals },
    {
      to: "kyc",
      name: "KYC",
      requiredPermissions: [Permissions.READ_KYC_PIIS],
      resource: Permissions.KYC_DETAILS_RESOURCE,
    },
    { to: "watchlist", name: "Watchlist", resource: Permissions.WATCHLIST_RESOURCE },
    { to: "payment-methods", name: "Payment Methods", resource: Permissions.PAYMENTS_RESOURCE },
    {
      to: "transaction-history",
      name: "Transactions",
      resource: Permissions.TRANSACTIONS_RESOURCE,
    },
    {
      to: "permissions",
      name: "Permissions",
      requiredPermissions: [Permissions.READ_PERMISSIONS],
      resource: Permissions.PERMISSIONS_RESOURCE,
    },
    {
      to: "gdpr",
      name: "GDPR",
      requiredPermissions: [Permissions.READ_GDPR_DOCUMENTS],
      resource: Permissions.GDPR_RESOURCE,
    },
    {
      to: "wallet-ledger",
      name: "Wallet Ledger",
      requiredPermissions: [Permissions.READ_WALLETS],
    },
  ];
  return (
    <SubNavRow>
      <div>
        {R.pipe(
          R.filter((c) => agent.hasPermissions(c.requiredPermissions || [], c.resource)),
          R.map((c) => (
            <SubNavLinkItem
              compat={true}
              key={c.to}
              to={c.to}
              activeFn={c.activeFn}
              routeSet={R.pluck("to", config)}
            >
              {c.name}
            </SubNavLinkItem>
          ))
        )(config)}
      </div>
    </SubNavRow>
  );
};
