import * as R from "ramda";
import { isNilOrEmpty, exists } from "./index.js";
import slugify from "slugify";

export const getRealNameFromKYCPII = (data) => {
  const first = R.defaultTo("", data?.first);
  const last = R.defaultTo("", data?.last);
  return R.trim(`${first} ${last}`);
};

export const snakeToTitleCase = (input = "") =>
  input ? toTitleCase(input.replace(/_/g, " ")) : input;

export const toTitleCase = (input) => {
  return R.pipe(
    R.split(" "),
    R.map((s) => `${s.charAt(0).toUpperCase()}${s.substring(1)}`),
    R.join(" ")
  )(input);
};

export const capitalizeFirstLetter = (word) => {
  return R.toUpper(word[0]) + R.toLower(R.slice(1, Infinity, word));
};

export const formatSSN = (rawSSN) => {
  if (R.equals(4, R.length(rawSSN))) {
    return `***-**-${rawSSN}`;
  }
  if (!isNilOrEmpty(rawSSN)) {
    return rawSSN.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
  }
  return null;
};

export const slug = (s) => (exists(s) ? slugify(String(s), { lower: true }) : s);

export const formatEmptyData = (d) => (isNilOrEmpty(d) ? "--" : d);

export const stripSettingsPrefix = (raw) => (isNilOrEmpty(raw) ? raw : R.last(raw.split("::")));
