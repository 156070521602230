/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { gql, useQuery } from "@apollo/client";

const getSettlementStatusAndApprovalHistory = gql`
  query GetSettlementStatusAndApprovalHistory($settlementId: ID!, $integrator: NonEmptyString!) {
    settlement(settlementId: $settlementId, integrator: $integrator) {
      settlementId
      integrator
      statusHistory {
        startDatetime
        settlementStatusCode
        userAccount {
          accountId
        }
      }
      approvals {
        approvalDatetime
        userAccount {
          accountId
        }
        approvalType
      }
      settlementStatusCode
    }
  }
`;

const useSettlementStatusAndApprovalHistory = (settlementIdentifier) => {
  return useQuery(getSettlementStatusAndApprovalHistory, {
    errorPolicy: "all",
    skip: !settlementIdentifier,
    variables: {
      settlementId: settlementIdentifier?.settlementId,
      integrator: settlementIdentifier?.integrator,
    },
  });
};

export default useSettlementStatusAndApprovalHistory;
