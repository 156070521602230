import { Link } from "components/Link";
import * as R from "ramda";
import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { theme } from "styles";
import { Tooltip } from "components/Tooltip";
import { formatDate, formatTime, isNilOrEmpty, exists, isString } from "utils";
import { slug } from "utils/format";
import { CopyableText } from "components/Copyable";

const DataError = (props) => (
  <span
    css={`
      color: ${theme("colors.error")};
    `}
    {...props}
  />
);

const applyPath = (formatter, path, data) =>
  R.pipe(R.path(path), R.defaultTo("-"), formatter)(data);

const getFormattedDataValue = (data, path, formatter, route, copyable) => {
  // handle empty / null
  if (isNilOrEmpty(data)) return "-";

  // handle an error
  if (exists(data.error)) return <DataError>{data.error.message}</DataError>;

  // data could be string, object with path, or a custom component.
  //
  // lets assume the existence of a path means data is object and resolve to string via path+formatter
  // else we test for string (vs component) and apply the formatter
  // else we have a component (still calling the formatter... :thinking face)
  //
  // then we look for extra props to modify final render: route, copyable, etc
  //
  let formattedValue = exists(path) ? applyPath(formatter, path, data) : formatter(data);

  // handle special convenience cases
  // route link:
  if (exists(route)) {
    return <Link to={route}>{formattedValue}</Link>;
  }
  if (copyable) {
    return <CopyableText text={formattedValue} buttonSize="sm" />;
  }

  return formattedValue;
};

export const DataTitleRow = ({ title }) => <DataRow title={title} data=" " />;

export const DataRow = ({
  copyable = false,
  data,
  dataCSS,
  formatter = R.identity,
  path,
  permissions = true,
  testId,
  title,
  route,
  titleCSS = "",
  wrap = false,
  tooltip,
  children,
  alignItems = "center",
}) => {
  if (!permissions) return null;
  if (R.prop("permissions")(data) === false) return null;

  const dataOrChildren = R.defaultTo(children, data);
  let finalValue = getFormattedDataValue(dataOrChildren, path, formatter, route, copyable);

  const display = !isNilOrEmpty(tooltip) ? (
    <Tooltip data-effect="solid" data-place="top" data-testid="data-row-tooltip" data-tip={tooltip}>
      {title}
    </Tooltip>
  ) : (
    title
  );
  return (
    <div
      css={`
        display: grid;
        grid-template-columns: minmax(80px, 1fr) auto;
        align-items: ${alignItems};
        padding: 8px 0;
        border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
        min-height: 26px;
      `}
    >
      <div
        css={`
          white-space: nowrap;
          display: flex;
          align-items: flex-start;
          ${titleCSS};
        `}
      >
        {display}
      </div>
      <div
        data-testid={testId || (isString(title) && slug(title)) || null}
        css={`
          text-align: right;
          ${wrap || `white-space: nowrap`};
          padding: 2px 4px;
          display: flex;
          align-items: center;
          ${dataCSS};
        `}
      >
        {finalValue}
      </div>
    </div>
  );
};

const DateAndTimeContainer = styled.div`
  > div {
    margin: 0;
  }
`;

export const DateAndTime = ({ value }) => {
  return (
    <DateAndTimeContainer>
      <div>
        <span>{formatDate(value)}</span>
        <span style={{ color: "#999999" }}> at </span>
        <span>{formatTime(value)}</span>
      </div>
    </DateAndTimeContainer>
  );
};
