import { TransactionPartyRole } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the transaction party role.
 *
 * @param {TransactionPartyRole} role
 * @returns {JSX.Element}
 */
function PartyRoleName({ role }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (role) {
    case TransactionPartyRole.BUYER:
      return <>Buyer</>;
    case TransactionPartyRole.SELLER:
      return <>Seller</>;
    case TransactionPartyRole.PUBLISHER:
      return <>Publisher</>;
    case TransactionPartyRole.TILIA:
      return <>Tilia</>;
    case TransactionPartyRole.TAX:
      return <>Tax</>;
    default:
      return <>{role}</>;
  }
}

export default PartyRoleName;
