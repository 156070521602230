import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import * as R from "ramda";
import * as Yup from "yup";
import { WRITE_SETTINGS } from "context/permissions";
import { validationMessages as vm } from "utils/forms";
import { useFormModal } from "components/Modal";
import { OutlineButton } from "components/Button";
import { SettingsIcon } from "components/Icons";
import { TextField, NoteField } from "components/Forms";
import { useAgent } from "context/agentContext";
import { noop, isNilOrEmpty, exists } from "utils";
import { setSetting } from "api/toolsWeb";
import { Confirmation } from "./Confirmation";

const isJson = (v) => {
  try {
    JSON.parse(v);
    return true;
  } catch {
    return false;
  }
};

const testIdPrefix = "add-edit-setting-";

export const useAddEditSettingModal = () => {
  const { agent } = useAgent();
  const [FormModal, openModal] = useFormModal();

  const AddEditSettingModal = ({
    existingKeys = [],
    onSuccess = noop,
    prefix,
    settingsKey = "",
    settingsValue = "",
  }) => {
    const originalSettingsValue = settingsValue;
    if (!agent.hasPermissions([WRITE_SETTINGS])) {
      return <div data-testid={`${testIdPrefix}modal-no-permissions`}></div>;
    }
    const keyWithoutPrefix = settingsKey.substring(prefix.length);

    const editMode = exists(settingsKey) && !R.equals(settingsKey, prefix);
    const modalTitle = editMode ? "Edit Setting" : "Add Setting";

    const pre = (k) => `${prefix}${k}`;

    const handleSubmit = ({ settingsKey, settingsValue }) =>
      setSetting(pre(settingsKey), settingsValue, agent);

    return (
      <FormModal
        actionIcon={<SettingsIcon size="1rem" />}
        actionText="Save"
        disableTillEdit={true}
        formProps={{
          initialValues: {
            settingsKey: keyWithoutPrefix,
            settingsValue: settingsValue,
          },
          validationSchema: () =>
            Yup.object().shape({
              settingsKey: Yup.string()
                .trim()
                .test(
                  "noWhitespace",
                  "The key cannot contain whitespace",
                  R.complement(R.test(/\s/)),
                )
                .test("noStar", "The key cannot contain '*'", R.complement(R.test(/\*/)))
                .test("notExists", "This key already exists", (v) => {
                  const key = pre(v);
                  const valueIsKey = R.equals(key, settingsKey);
                  const valueInExisting = R.includes(key, existingKeys);
                  return valueIsKey || !valueInExisting;
                })
                .test("notEqualPrefix", `The key cannot be ${prefix}`, (v) => {
                  if (isNilOrEmpty(prefix)) return true;
                  return !R.equals(v, prefix);
                }),
              settingsValue: Yup.string()
                .required(vm.required)
                .test("updateJsonFlag", "The value must be valid JSON", (v) => isJson(v)),
            }),
        }}
        submitAction={handleSubmit}
        onSubmitActionSuccess={onSuccess}
        testIdPrefix={testIdPrefix}
        titleText={modalTitle}
        withConfirmation={true}
        renderConfirmation={(values) => {
          return (
            <Confirmation
              prefix={prefix}
              settingsKey={settingsKey}
              original={originalSettingsValue}
              values={values}
            />
          );
        }}
      >
        <div
          css={`
            min-height: 50vh;
            height: 100%;
            display: flex;
            flex-direction: column;
          `}
        >
          <TextField
            data-testid={`${testIdPrefix}key-field`}
            name="settingsKey"
            label="Key"
            focus={true}
            required
            readOnly={editMode}
          />
          <NoteField
            data-testid={`${testIdPrefix}value-field`}
            name="settingsValue"
            label="Value"
            required
            maxHeight
            placeholder="JSON only"
            focus
          />
        </div>
      </FormModal>
    );
  };

  const AddEditSettingButton = ({ label, ...props }) => {
    if (!agent.hasPermissions([WRITE_SETTINGS])) {
      return null;
    }
    return (
      <OutlineButton data-testid={`${testIdPrefix}button`} {...props} onClick={openModal}>
        {label}
      </OutlineButton>
    );
  };

  return [AddEditSettingModal, AddEditSettingButton];
};
