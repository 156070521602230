import React, { useMemo } from "react";
import { css } from "styled-components/macro";
import * as R from "ramda";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { theme } from "styles";
import { useAgent } from "context/agentContext";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import { SectionSpinner } from "components/Spinner";
import { Cell, SortingHeaderCell } from "components/Sorter";
import { getClientScopes } from "api/account";
import { useSorting, ASC } from "utils/useSorting";
import { useDeleteClientScopeModal } from "./DeleteClientScopeModal";
import { useAddClientScopesModal } from "./AddClientScopesModal";
import { noop } from "utils";

const addSource = (source, data) => R.map(R.assoc("source", source))(data);

const row = css`
  padding 8px 0;
  border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
`;

const grid = css`
  ${row}
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr;
`;

const ScopeRow = ({ data = {}, onChange = noop }) => {
  const [DeleteClientScopeModal, DeleteClientScopeButton] = useDeleteClientScopeModal();
  return (
    <div
      css={`
        ${grid};
        align-items: center;
      `}
    >
      <Cell>{data.scope_name}</Cell>
      <Cell>{data.token_type}</Cell>
      <Cell>{data.public.toString()}</Cell>
      <Cell>{data.source}</Cell>
      <Cell>{data.scope_id}</Cell>
      <Cell
        css={`
          display: flex;
          justify-content: flex-end;
        `}
      >
        {data.source === "client" ? (
          <>
            <DeleteClientScopeButton />
            <DeleteClientScopeModal onSuccess={onChange} data={data} />
          </>
        ) : null}
      </Cell>
    </div>
  );
};

const GridHeader = ({ sortingProps }) => {
  return (
    <>
      <SortingHeaderCell {...sortingProps} field="scope_name">
        Scope Name
      </SortingHeaderCell>
      <SortingHeaderCell {...sortingProps} field="token_type">
        Token Type
      </SortingHeaderCell>
      <SortingHeaderCell {...sortingProps} field="public">
        Is Public
      </SortingHeaderCell>
      <SortingHeaderCell {...sortingProps} field="source">
        Source
      </SortingHeaderCell>
      <SortingHeaderCell {...sortingProps} field="scope_id">
        Scope Id
      </SortingHeaderCell>
    </>
  );
};

const useSortedData = (source, query) => {
  const { sorter, sortingProps } = useSorting("scope_name", { initialDirection: ASC });
  const sortedData = useMemo(() => {
    return sorter([...addSource(source, query?.data?.payload || [])]);
  }, [query.data, sorter, source]);

  return { sortingProps, sortedData };
};

const useScopeQuery = (key) => {
  const { agent } = useAgent();
  const queryClient = useQueryClient();
  const clientKey = ["getClientScopes", key];

  const query = useQuery(clientKey, () => getClientScopes(key, agent));

  const refresh = () => queryClient.invalidateQueries(clientKey);

  return { query, refresh };
};

const GlobalScopes = () => {
  const { query } = useScopeQuery("global");
  const { sortingProps, sortedData } = useSortedData("global", query);

  if (query.isLoading) {
    return <SectionSpinner />;
  }
  if (query.isError) {
    return <div>Error</div>;
  }

  return (
    <Panel>
      <PanelTitle>Global Scopes</PanelTitle>
      <PanelContent>
        <div css={grid}>
          <GridHeader sortingProps={sortingProps} />
        </div>
        {sortedData.map((scope) => (
          <ScopeRow key={scope.scope_id} data={scope} />
        ))}
      </PanelContent>
    </Panel>
  );
};

const AssignedScopes = () => {
  const { clientId } = useParams();
  const { query, refresh } = useScopeQuery(clientId);
  const { sortingProps, sortedData } = useSortedData("client", query);

  const [AddClientScopesModal, AddClientScopesButton] = useAddClientScopesModal();

  if (query.isLoading) {
    return <SectionSpinner />;
  }
  if (query.isError) {
    return <div>Error</div>;
  }

  return (
    <Panel>
      <PanelTitle>Assigned Scopes</PanelTitle>
      <PanelContent>
        <div css={grid}>
          <GridHeader sortingProps={sortingProps} />
          <Cell
            right
            css={`
              display: flex;
              justify-content: flex-end;
            `}
          >
            <AddClientScopesButton label="Add" />
            <AddClientScopesModal
              clientId={clientId}
              onSuccess={refresh}
              clientScopes={sortedData}
            />
          </Cell>
        </div>
        {sortedData.map((scope) => (
          <ScopeRow key={scope.scope_id} data={scope} onChange={refresh} />
        ))}
      </PanelContent>
    </Panel>
  );
};

export const ClientScopes = () => {
  return (
    <>
      <AssignedScopes />
      <GlobalScopes />
    </>
  );
};
