import { GridItem, Stack } from "@chakra-ui/react";

function TransactionMain({ children }) {
  return (
    <GridItem as="main" colSpan={1}>
      <Stack spacing={{ base: 4, xl: 8 }}>{children}</Stack>
    </GridItem>
  );
}

export default TransactionMain;
