import React, { useRef, useEffect, useCallback, createContext, useContext } from "react";
import { isNilOrEmpty } from "utils";

// defines a schema for document titles:
// - root: the base title for the document
// - pageTitle: the title of the current page
// - sub1: the first sub-title
// - sub2: the second sub-title
//
// Components can set specific parts of the schema without
// knowing / affecting the rest of the schema.
// They can also clear the schema to start fresh.
//  - useTitle({ page: "Diagnostics", sub1: agent.username }, { clear: true });
//
// TODO allow arbitatry number of subs if needed
export const TitleContext = createContext();

export const TitleProvider = ({ root = "Tools", ...props }) => {
  const titleRef = useRef({ root });

  const setTitleParts = useCallback(
    (newParts, passedOpts) => {
      const opts = { touch: true, ...passedOpts };
      if (!opts.touch) return;
      if (isNilOrEmpty(newParts)) {
        console.debug("WARNING: useTitle called with empty parts"); // eslint-disable-line no-console
        titleRef.current = { root };
      } else if (opts.clear) {
        titleRef.current = { root: titleRef.current.root, ...newParts };
      } else {
        titleRef.current = { ...titleRef.current, ...newParts };
      }

      const formatTitle = (parts) => {
        const page = parts.page ? `: ${parts.page} ` : "";
        const sub1 = parts.sub1 ? `- ${parts.sub1} ` : "";
        const sub2 = parts.sub2 ? `- ${parts.sub2}` : "";
        return `${root}${page}${sub1}${sub2}`;
      };

      window.document.title = formatTitle(titleRef.current);
    },
    [titleRef, root]
  );

  return <TitleContext.Provider value={{ setTitleParts }} {...props}></TitleContext.Provider>;
};

export const useTitle = (titleParts, opts = {}) => {
  const context = useContext(TitleContext);
  useEffect(() => {
    context.setTitleParts(titleParts, opts);
  }, [context, titleParts, opts]);

  return null;
};
