import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars

const Required = () => (
  <span
    css={`
      margin-left: 4px;
    `}
    alt="Required field"
  >
    *
  </span>
);

export const Label = ({ children, required, ...props }) => {
  return (
    <label
      css={`
        display: block;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        user-select: none;
        padding-left: 8px;
        white-space: nowrap;
      `}
      {...props}
    >
      {children}
      {false && required && <Required />}
    </label>
  );
};
