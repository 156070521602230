import React, { useMemo } from "react";
import * as Yup from "yup";
import { useAccount } from "context/accountContext";
import { blockAccount } from "api/account";
import { ctxWithIntegrator } from "utils/auth";
import { BlockedIcon } from "components/Icons";
import { useFormModal } from "components/Modal";
import { PermissionsActionMessage } from "components/PermissionsMessage";
import { NoteField, SelectField } from "components/Forms";
import useBlockCapabilitiesForAccount from "./useBlockCapabilitiesForAccount";

// The copy for login block is now Account Block
// This change is only UI only
const capabilityOptions = [
  { value: "LOGIN", label: "Account Block: accessing the platform, payouts, and transactions" },
  { value: "PAYOUTS", label: "Payouts: requesting or executing a payout" },
  { value: "TRANSACTIONS", label: "Transactions: buying or selling items" },
];

const reasonOptions = [
  { value: "ACCOUNT_CLOSE", label: "Account closed" },
  { value: "COMPLIANCE", label: "Compliance" },
  // MERGE_ACCOUNT is not assignable by users; it is reserved for automated processing
];

const validationSchema = Yup.object().shape({
  capability: Yup.string().required("Capability is required"),
});

const useBlockAccountModal = () => {
  const [FormModal, openModal] = useFormModal();

  const BlockAccountModal = ({ account, onAccountBlocked }) => {
    const { agentContext } = useAccount();

    const blockCapabilities = useBlockCapabilitiesForAccount(account.account_id);

    const availableCapabilityOptions = useMemo(() => {
      return capabilityOptions.filter((option) => {
        return blockCapabilities.includes(option.value);
      });
    }, [blockCapabilities]);

    const handleSubmit = async ({ capability, reason, note }) => {
      const ctx = ctxWithIntegrator(account.integrator, agentContext);

      const result = await blockAccount(
        account.account_id,
        capability.toLowerCase(),
        reason.toLowerCase(),
        note,
        ctx
      );

      if (result.status !== 200) {
        return { status: "Failure", error: result.errors };
      }
      return { status: "Success" };
    };

    const handleSubmitActionSuccess = () => {
      if (onAccountBlocked) {
        onAccountBlocked(account);
      }
    };

    return (
      <FormModal
        formProps={{
          initialValues: {
            capability: "",
            reason: "",
            note: "",
          },
          validationSchema,
        }}
        actionIcon={<BlockedIcon size="1rem" />}
        actionText="Block"
        submitAction={availableCapabilityOptions.length > 0 ? handleSubmit : undefined}
        onSubmitActionSuccess={handleSubmitActionSuccess}
        titleText={`Block User: ${account.username}`}
      >
        {availableCapabilityOptions.length > 0 ? (
          <>
            <SelectField
              name="capability"
              label="Capability"
              required={true}
              options={availableCapabilityOptions}
            />
            <SelectField name="reason" label="Reason" required={true} options={reasonOptions} />
            <NoteField name="note" label="Note" placeholder="Why this block was placed…" />
          </>
        ) : (
          <PermissionsActionMessage />
        )}
      </FormModal>
    );
  };

  return [BlockAccountModal, openModal];
};

export default useBlockAccountModal;
