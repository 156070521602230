import React from "react";
import ReactTooltip from "react-tooltip";
import { useTheme } from "styles";

export const Tooltip = ({ children, ...props }) => {
  const { currentTheme } = useTheme();
  return (
    <span
      {...props}
      data-border="true"
      data-border-color={currentTheme.colors.button.outline.border}
    >
      {children}
      <ReactTooltip />
    </span>
  );
};
