import * as R from "ramda";
import { createAction } from "redux-actions";
import * as permissions from "context/permissions";
import * as piiApi from "api/pii";
import { A, S, debugReducer } from "../utils"; //eslint-disable-line no-unused-vars
import { isNilOrEmpty } from "utils";

export const kycStatus = {
  NONE: "None",
  NO_DATA: "No Data",
  PROCESSING: "Processing",
  MANUAL_REVIEW: "Manual Review",
  RE_VERIFY: "Re-Verify",
  CANCEL: "Cancel",
  SYSTEM_CANCELED: "System Canceled",
  ACCEPT: "Accept",
  DENY: "Deny",
};

export const mapKYCStatus = (rawStatus) => R.defaultTo(rawStatus, kycStatus[rawStatus]);
export const getFinalKYCStatus = (kycPII) => mapKYCStatus(kycPII?.data?.state);

const defaultArray = R.defaultTo([]);

const getDocFrontIdFromPayload = R.path(["payload", "kyc_pii", "document_front_id"]);
const getDocBackIdFromPayload = R.path(["payload", "kyc_pii", "document_back_id"]);
const getExtraFiles = R.path(["payload", "kyc_pii", "extra_files"]);
const compact = R.reject(isNilOrEmpty);

const extractFileIds = (res) => {
  return compact([
    getDocFrontIdFromPayload(res),
    getDocBackIdFromPayload(res),
    ...defaultArray(getExtraFiles(res)),
  ]);
};

const { postKYCFile, postSendKYCEmail, updateKYCPII } = piiApi;
export { postKYCFile, postSendKYCEmail, updateKYCPII };

export const isReady = (state) => {
  return state.kycPII.ready;
};

export const getKYC = async (dispatch, accountId, user) => {
  if (!user.hasPermissions([permissions.READ_KYCS], permissions.KYC_BASIC_RESOURCE)) {
    dispatch(actions.getKYCPermissionsFail());
    return;
  }
  try {
    dispatch(actions.getKYCInit(accountId));
    const { payload } = await piiApi.getKYC(accountId, user);
    dispatch(actions.getKYCSuccess(payload));
  } catch (err) {
    dispatch(actions.getKYCError(err));
  }
};

export const getKYCPII = async (dispatch, accountId, user) => {
  if (!user.hasPermissions([permissions.READ_KYC_PIIS], permissions.KYC_DETAILS_RESOURCE)) {
    dispatch(actions.getKYCPIIPermissionsFail());
    return;
  }
  try {
    dispatch(actions.getKYCPIIInit(accountId));
    const response = await piiApi.getKYCPII(accountId, user);
    dispatch(actions.getKYCPIISuccess(response.payload));
    getKYCFiles(dispatch, accountId, extractFileIds(response), user);
  } catch (err) {
    dispatch(actions.getKYCPIIError(err));
  }
};

export const getKYCFiles = (dispatch, accountId, fileList, user) => {
  fileList.map((f) => getKYCFile(dispatch, accountId, f, user));
};

export const getKYCFile = async (dispatch, accountId, fileId, user) => {
  if (!user.hasPermissions([permissions.READ_KYC_PIIS], permissions.KYC_DETAILS_RESOURCE)) {
    dispatch(actions.getKYCFilePermissionsFail());
    return;
  }
  try {
    dispatch(actions.getKYCFileInit({ accountId, fileId }));
    const { payload } = await piiApi.getKYCFile(accountId, fileId, user);
    dispatch(actions.getKYCFileSuccess(payload));
  } catch (error) {
    dispatch(actions.getKYCFileError({ fileId, error }));
  }
};

export const uploadKYCFileSuccess = (dispatch, fileData) => {
  dispatch(actions.getKYCFileSuccess(fileData.payload));
};

export const getKYCCollectionUri = async (dispatch, accountId, params, user) => {
  if (!user.hasPermissions([permissions.VERIFY_KYCS], permissions.KYC_DETAILS_RESOURCE)) {
    dispatch(actions.getKYCCollectionUriPermissionsFail());
    return;
  }
  try {
    dispatch(actions.getKYCCollectionUriInit(accountId));
    const { payload } = await piiApi.getKYCPIICollectionUri(accountId, params, user);
    dispatch(actions.getKYCCollectionUriSuccess(payload));
  } catch (err) {
    dispatch(actions.getKYCCollectionUriError(err));
  }
};

export const actions = {
  getKYCInit: createAction("@tools/kyc/getKYCInit"),
  getKYCSuccess: createAction("@tools/kyc/getKYCSuccess"),
  getKYCError: createAction("@tools/kyc/getKYCError"),
  getKYCPermissionsFail: createAction("@tools/kyc/getKYCPermissionsFail"),

  getKYCPIIInit: createAction("@tools/kyc/getKYCPIIInit"),
  getKYCPIISuccess: createAction("@tools/kyc/getKYCPIISuccess"),
  getKYCPIIError: createAction("@tools/kyc/getKYCPIIError"),
  getKYCPIIPermissionsFail: createAction("@tools/kyc/getKYCPIIPermissionsFail"),

  getKYCFileInit: createAction("@tools/kyc/getKYCFileInit"),
  getKYCFileSuccess: createAction("@tools/kyc/getKYCFileSuccess"),
  getKYCFileError: createAction("@tools/kyc/getKYCFileError"),
  getKYCFilesReset: createAction("@tools/kyc/getKYCFilesReset"),
  getKYCFilePermissionsFail: createAction("@tools/kyc/getKYCFilePermissionsFail"),

  getKYCCollectionUriInit: createAction("@tools/kyc/getKYCCollectionUriInit"),
  getKYCCollectionUriSuccess: createAction("@tools/kyc/getKYCCollectionUriSuccess"),
  getKYCCollectionUriError: createAction("@tools/kyc/getKYCCollectionUriError"),
  getKYCCollectionUriPermissionsFail: createAction("@tools/kyc/getKYCCollectionUriPermissionsFail"),
  getKYCCollectionUriReset: createAction("@tools/kyc/getKYCCollectionUriReset"),
};

export const initialState = {
  kyc: {
    accountId: null,
    data: null,
    error: null,
    permissions: true,
    ready: false,
  },
  kycPII: {
    accountId: null,
    data: null,
    error: null,
    permissions: true,
    ready: false,
  },
  kycFiles: {
    accountId: null,
    permissions: true,
    files: {},
  },
  kycCollectionUri: {
    accountId: null,
    data: null,
    error: null,
    permissions: true,
    ready: false,
  },
};

const getDocFrontId = R.path(["kycPII", "data", "kyc_pii", "document_front_id"]);
const getDocBackId = R.path(["kycPII", "data", "kyc_pii", "document_back_id"]);

const removeDocumentDataFromSuccessPayload = R.pipe(
  R.dissocPath(["kyc_pii", "document_front"]),
  R.dissocPath(["kyc_pii", "document_back"])
);

export const getKYCHandlers = {
  [actions.getKYCInit]: debugReducer((state, { payload }) => ({
    ...state,
    kyc: {
      ...state.kyc,
      accountId: payload,
      error: null,
      ready: false,
    },
  })),
  [actions.getKYCSuccess]: debugReducer((state, { payload }) => ({
    ...state,
    kyc: {
      ...state.kyc,
      data: payload,
      error: null,
      ready: true,
    },
  })),
  [actions.getKYCError]: debugReducer((state, { payload }) => ({
    ...state,
    kyc: {
      ...state.kyc,
      data: null,
      error: payload,
      ready: true,
    },
  })),

  [actions.getKYCPermissionsFail]: debugReducer((state) => ({
    ...state,
    kyc: {
      ...state.kyc,
      data: null,
      error: null,
      ready: true,
      permissions: false,
    },
  })),
};

export const getKYCPIIHandlers = {
  [actions.getKYCPIIInit]: debugReducer((state, { payload }) => ({
    ...state,
    kycPII: {
      ...state.kycPII,
      accountId: payload,
      error: null,
      ready: false,
    },
  })),
  [actions.getKYCPIISuccess]: debugReducer((state, { payload }) => ({
    ...state,
    kycPII: {
      ...state.kycPII,
      data: removeDocumentDataFromSuccessPayload(payload),
      error: null,
      ready: true,
    },
  })),
  [actions.getKYCPIIError]: debugReducer((state, { payload }) => ({
    ...state,
    kycPII: {
      ...state.kycPII,
      data: null,
      error: payload,
      ready: true,
    },
  })),

  [actions.getKYCPIIPermissionsFail]: debugReducer((state) => ({
    ...state,
    kycPII: {
      ...state.kycPII,
      data: null,
      error: null,
      ready: true,
      permissions: false,
    },
  })),
};

export const getKYCFilesHandlers = {
  [actions.getKYCFileInit]: debugReducer((state, { payload }) => ({
    ...state,
    kycFiles: {
      ...state.kycFiles,
      accountId: payload.accountId,
      files: {
        ...state.kycFiles.files,
        [payload.fileId]: {
          data: null,
          error: null,
          ready: false,
        },
      },
    },
  })),
  [actions.getKYCFileSuccess]: debugReducer((state, { payload }) => {
    // some files will have ids that match the kyc_pii docs
    const isDocFront = R.equals(payload.pii_id, getDocFrontId(state));
    const isDocBack = R.equals(payload.pii_id, getDocBackId(state));
    if (isDocFront) {
      return R.pipe(
        R.assocPath(["kycPII", "data", "kyc_pii", "document_front"], payload.file),
        R.dissocPath(["kycFiles", "files", payload.pii_id])
      )(state);
    }
    if (isDocBack) {
      return R.pipe(
        R.assocPath(["kycPII", "data", "kyc_pii", "document_back"], payload.file),
        R.dissocPath(["kycFiles", "files", payload.pii_id])
      )(state);
    }
    // extra file
    return R.assocPath(
      ["kycFiles", "files", payload.pii_id],
      { error: null, ready: true, data: payload },
      state
    );
  }),

  [actions.getKYCFileError]: debugReducer((state, { payload }) => {
    const isDocFront = R.equals(payload.fileId, getDocFrontId(state));
    const isDocBack = R.equals(payload.fileId, getDocBackId(state));
    if (isDocFront) {
      return R.pipe(
        R.assocPath(["kycPII", "data", "kyc_pii", "document_front_error"], payload.error),
        R.dissocPath(["kycFiles", "files", payload.fileId])
      )(state);
    }
    if (isDocBack) {
      return R.pipe(
        R.assocPath(["kycPII", "data", "kyc_pii", "document_back_error"], payload.error),
        R.dissocPath(["kycFiles", "files", payload.fileId])
      )(state);
    }
    // extra file
    return R.assocPath(
      ["kycFiles", "files", payload.fileId],
      { error: payload.error, ready: true, data: null },
      state
    );
  }),
  [actions.getKYCFilePermissionsFail]: debugReducer((state) => ({
    ...state,
    kycFiles: {
      ...state.kycFiles,
      permissions: false,
    },
  })),
  [actions.getKYCFilesReset]: debugReducer((state) => ({
    ...state,
    kycFiles: initialState.kycFiles,
  })),
};

export const getKYCCollectionUriHandlers = {
  [actions.getKYCCollectionUriInit]: debugReducer((state, { payload }) => ({
    ...state,
    kycCollectionUri: {
      ...state.kycCollectionUri,
      accountId: payload,
      error: null,
      ready: false,
    },
  })),
  [actions.getKYCCollectionUriSuccess]: debugReducer((state, { payload }) => ({
    ...state,
    kycCollectionUri: {
      ...state.kycCollectionUri,
      data: payload,
      error: null,
      ready: true,
    },
  })),
  [actions.getKYCCollectionUriError]: debugReducer((state, { payload }) => ({
    ...state,
    kycCollectionUri: {
      ...state.kycCollectionUri,
      data: null,
      error: payload,
      ready: true,
    },
  })),
  [actions.getKYCCollectionUriPermissionsFail]: debugReducer((state) => ({
    ...state,
    kycCollectionUri: {
      ...state.kycCollectionUri,
      data: null,
      error: null,
      ready: true,
      permissions: false,
    },
  })),
  [actions.getKYCCollectionUriReset]: debugReducer((state) => ({
    ...state,
    kycCollectionUri: initialState.kycCollectionUri,
  })),
};

export const handlers = {
  ...getKYCHandlers,
  ...getKYCPIIHandlers,
  ...getKYCFilesHandlers,
  ...getKYCCollectionUriHandlers,
};
