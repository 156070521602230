/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import { formatDateTime } from "utils";
import { Text } from "@chakra-ui/react";
import { LoadingIcon } from "components/Icons";
import { Modal } from "components/Modal";
import { DetailText } from "components/Text";
import useSettlement from "./useSettlement";
import SettlementAdjustmentsSummary from "./SettlementAdjustmentsSummary";
import SettlementApprovalsSummary from "./SettlementApprovalsSummary";
import SettlementBalanceSummary from "./SettlementBalanceSummary";
import SettlementDeductionsSummary from "./SettlementDeductionsSummary";
import SettlementRevenueSummary from "./SettlementRevenueSummary";

function SettlementDetailsModal({ settlementIdentifier, ...modalProps }) {
  const { loading, data } = useSettlement(settlementIdentifier);

  const settlement = data?.settlement;

  return (
    <Modal title="Settlement Details" isLoading={loading} {...modalProps}>
      {!settlement && loading && <LoadingIcon size={2} />}
      {!settlement && !loading && <p>Not found</p>}
      {settlement && (
        <>
          <Text mt={0}>
            {settlement.integrator}
            <br />
            <DetailText>
              {formatDateTime(settlement.periodStartDatetime)}
              {" – "}
              {formatDateTime(settlement.periodEndDatetime)}
            </DetailText>
          </Text>
          <SettlementRevenueSummary
            summary={settlement.summary}
            currencyCode={settlement.currencyCode}
          />
          <SettlementDeductionsSummary
            summary={settlement.summary}
            currencyCode={settlement.currencyCode}
          />
          <SettlementAdjustmentsSummary
            summary={settlement.summary}
            currencyCode={settlement.currencyCode}
            adjustments={settlement.adjustments}
          />
          <SettlementBalanceSummary
            summary={settlement.summary}
            currencyCode={settlement.currencyCode}
            balanceTransfer={settlement.balanceTransfer}
            payout={settlement.payout}
          />
          <SettlementApprovalsSummary
            approvals={settlement.approvals}
            statusHistory={settlement.statusHistory}
          />
        </>
      )}
    </Modal>
  );
}

export default SettlementDetailsModal;
