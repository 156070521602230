import React from "react";
import * as R from "ramda";
import GridProvider from "./GridProvider";
import { useNavigate } from "react-router-dom";
import { getFilterMap, setFilterMap } from "./utils";

const DEFAULT_CURRENT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 10;

const withGrid = (WrappedComponent, config) => (props) => {
  const [loaded, setLoaded] = React.useState(false);

  const navigate = useNavigate();

  // Hook ensures URL has all params before moving to Grid Provider where params and grid states work together
  React.useEffect(() => {
    if (!loaded) {
      const params = new URLSearchParams(window.location.search);

      // URL value takes first priority. Then component config, then default value
      const currentPage =
        parseInt(params.get("currentPage"), 10) || config.currentPage || DEFAULT_CURRENT_PAGE;
      const pageSize = parseInt(params.get("pageSize"), 10) || config.pageSize || DEFAULT_PAGE_SIZE;

      // optional URL params
      const sortField = params.get("sortField") || config.sortField;
      const sortDir = params.get("sortDir") || config.sortDir;

      let filterMap = getFilterMap(params);

      // URL gave empty filter map then use config
      if (R.isEmpty(filterMap)) {
        filterMap = config.filterMap;
      }

      // set URL params
      params.set("currentPage", currentPage);
      params.set("pageSize", pageSize);

      // set optional URL params
      sortField && params.set("sortField", sortField);
      sortDir && params.set("sortDir", sortDir);

      // set filter map to URL
      setFilterMap(params, filterMap);

      // since component is loading and it is it's first URL, we will replace it with properly updated URL params
      navigate({ search: params.toString() }, { replace: true });
      setLoaded(true);
    }
  }, [loaded, navigate]);

  if (!loaded) {
    return null;
  }
  return (
    <GridProvider {...config}>
      <WrappedComponent {...props} />
    </GridProvider>
  );
};

export default withGrid;
