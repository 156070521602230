import { createAction } from "redux-actions";
import { debugReducer } from "../utils"; //eslint-disable-line no-unused-vars
import * as accountApi from "api/account";

const { changeUsername, changeEmail } = accountApi;
export { changeUsername, changeEmail };

export const getUserInfo = async (dispatch, accountId, agent) => {
  try {
    dispatch(actions.getAccountUserInfoInit(accountId));
    const { payload: integratorPayload } = await accountApi.getAccountUserInfoAdmin(
      accountId,
      agent,
    );
    const context = {
      ...agent,
      integratorContext: { integrator: integratorPayload.integrator },
    };
    const { payload } = await accountApi.getAccountUserInfo(accountId, context);
    dispatch(actions.getAccountUserInfoSuccess(payload));
    return payload;
  } catch (err) {
    agent.processResponseError(err);
    dispatch(actions.getAccountUserInfoError(err));
  }
};

export const actions = {
  getAccountUserInfoInit: createAction("@tools/account/getAccountUserInfoInit"),
  getAccountUserInfoSuccess: createAction("@tools/account/getAccountUserInfoSuccess"),
  getAccountUserInfoError: createAction("@tools/account/getAccountUserInfoError"),
};

export const initialState = {
  details: {
    accountId: null,
    data: null,
    error: null,
    ready: false,
  },
};

export const handlers = {
  [actions.getAccountUserInfoInit]: (state, { payload }) => ({
    ...state,
    details: {
      ...state.details,
      accountId: payload,
      error: null,
    },
  }),
  [actions.getAccountUserInfoSuccess]: (state, { payload }) => ({
    ...state,
    details: {
      ...state.details,
      data: payload,
      error: null,
      ready: true,
    },
  }),
  [actions.getAccountUserInfoError]: (state, { payload }) => ({
    ...state,
    details: {
      ...state.details,
      data: null,
      error: payload,
      ready: true,
    },
  }),
};
