import React from "react";
import { useLocation } from "react-router-dom";
import { useModal, ModalFooter } from "components/Modal";
import { PrimaryButton } from "components/Button";
import { useAgent } from "context/agentContext";
import { useInterval } from "usehooks-ts";

const oneMinute = 60000;

export const useReloginModal = () => {
  const { agent } = useAgent();
  const [Modal, _openModal, closeModal] = useModal();

  // check for expiration on every location change
  const location = useLocation();

  const checkExpired = React.useCallback(() => {
    agent.debug("Checking for expiration:", agent.tokenExpired());
    if (agent.tokenExpired()) {
      agent.debug("modal here");
    }
  }, [agent]);

  React.useEffect(() => {
    checkExpired();
  }, [checkExpired, location]);

  useInterval(checkExpired, agent.tokenExpired() ? null : oneMinute);

  const ReloginModal = ({ onAccept }) => {
    return (
      <Modal titleText="Login Expired">
        <div>Your login is expired. You will be redirected to the login screen to continue.</div>
        <ModalFooter>
          <PrimaryButton
            data-testid="relogin-ok"
            onClick={() => {
              closeModal();
              onAccept();
            }}
          >
            OK
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    );
  };

  return [ReloginModal];
};
