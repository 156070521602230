import { Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { AccountLink } from "components/Account";
import { Money } from "components/Money";
import PartyRoleName from "./PartyRoleName";

function PartySummaryList({ parties }) {
  if (parties.length < 1) {
    return null;
  }
  return (
    <Table size="sm">
      <Tbody>
        {parties.map((party) => (
          <Tr key={`${party.role}-${party.account?.accountId || ""}-${party.currencyCode}`}>
            <Td>
              {party.account ? (
                <AccountLink account={party.account} newTab={true}>
                  {party.account.username || party.account.accountId}
                </AccountLink>
              ) : (
                <PartyRoleName role={party.role} />
              )}
            </Td>
            <Td isNumeric={true}>
              <Money value={party.amount} currency={party.currencyCode} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default PartySummaryList;
