/* istanbul ignore file */
// temporarily exclude from coverage while components are still in active development
import SettlementBalanceSummary from "./SettlementBalanceSummary";
import useLiveAdjustmentsTotal from "./useLiveAdjustmentsTotal";

/**
 * @param {SettlementSummary} summary
 * @param {string} currencyCode
 * @returns {JSX.Element}
 */
function SettlementBalanceSummaryWithLiveAdjustments({ summary, currencyCode }) {
  const liveAdjustmentsTotal = useLiveAdjustmentsTotal();

  const liveSummary = {
    ...summary,
    adjustmentsTotal: liveAdjustmentsTotal,
  };

  return <SettlementBalanceSummary summary={liveSummary} currencyCode={currencyCode} />;
}

export default SettlementBalanceSummaryWithLiveAdjustments;
