import { TransactionType } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the transaction type.
 *
 * @param {TransactionType} transactionType
 * @returns {JSX.Element}
 */
function TransactionTypeName({ transactionType }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (transactionType) {
    case TransactionType.PURCHASE:
      return <>Purchase</>;
    case TransactionType.ESCROW_PURCHASE:
      return <>Escrow Purchase</>;
    case TransactionType.REFUND:
      return <>Refund</>;
    case TransactionType.DEPOSIT:
      return <>Deposit</>;
    case TransactionType.PAYOUT:
      return <>Payout</>;
    case TransactionType.TOKEN_PURCHASE:
      return <>Token Purchase</>;
    case TransactionType.TOKEN_CONVERSION:
      return <>Token Conversion</>;
    case TransactionType.BALANCE_TRANSFER:
      return <>Balance Transfer</>;
    case TransactionType.MAKE_GOOD:
      return <>Make Good</>;
    default:
      return <>{transactionType}</>;
  }
}

export default TransactionTypeName;
