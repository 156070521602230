import React, { createContext, useContext, useCallback, useReducer } from "react";
import { createAction, handleActions } from "redux-actions";
import { useAgent } from "./agentContext";
import { debugReducer } from "./utils"; // eslint-disable-line no-unused-vars
import * as api from "api/pii.js";

const KYCContext = createContext();
const useKYC = () => useContext(KYCContext);

const actions = {
  lookupInit: createAction("@tools/kyc/lookupInit"),
  lookupSuccess: createAction("@tools/kyc/lookupSuccess"),
  lookupError: createAction("@tools/kyc/lookupError"),
};

const initialState = {
  applicationId: null,
  error: null,
  loading: false,
  data: null,
};

const reducer = handleActions(
  {
    [actions.lookupInit]: (state, { payload }) => ({
      ...state,
      loading: true,
      error: null,
      applicationId: payload,
    }),
    [actions.lookupSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      data: payload,
    }),
    [actions.lookupError]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      data: [],
    }),
  },
  initialState
);

const KYCProvider = (props) => {
  const { agent } = useAgent();
  const [state, dispatch] = useReducer(reducer, initialState);

  const lookupApplicationId = useCallback(
    async (applicationId, setSubmitting, setError) => {
      try {
        setSubmitting(true);
        dispatch(actions.lookupInit(applicationId));
        const res = await api.lookupApplicationId(applicationId, agent);
        dispatch(actions.lookupSuccess(res));
      } catch (err) {
        setError(err);
        dispatch(actions.lookupError(err));
      } finally {
        setSubmitting(false);
      }
    },
    [agent]
  );

  return <KYCContext.Provider value={{ state, lookupApplicationId }} {...props} />;
};

export { useKYC, KYCProvider };
