import { Box, Flex, HStack, useColorModeValue } from "@chakra-ui/react";
import { exists } from "utils";

function DetailList({ details }) {
  const gray600 = useColorModeValue("gray.600", "gray.200");
  const gray700 = useColorModeValue("gray.700", "gray.200");
  if (!exists(details)) {
    return null;
  }
  return (
    <Flex direction="column" gap={1}>
      {details.map((detail) => (
        <HStack as="dl" margin={0} gap={2} justifyContent="space-between" key={detail[0]}>
          <Box as="dt" size="sm" color={gray600}>
            {detail[0]}
          </Box>
          <Flex
            as="dd"
            overflowWrap="anywhere"
            color={gray700}
            size="sm"
            fontWeight={600}
            textAlign="right"
          >
            {detail[1]}
          </Flex>
        </HStack>
      ))}
    </Flex>
  );
}

export default DetailList;
