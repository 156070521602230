import { SectionError } from "components/Error";
import { FilterContextProvider } from "components/Filter";
import { PageContent, PageTitle } from "components/Page";
import { PermissionsMessage } from "components/PermissionsMessage";
import { FullPageSpinner } from "components/Spinner";
import { WRITE_WEBHOOKS } from "context/permissions";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import useAgentIntegrator from "context/useAgentIntegrator";
import useAgentIsTiliaAgent from "context/useAgentIsTiliaAgent";
import useScript from "react-script-hook";
import WebhookDisplay from "./WebhookDisplay";
import WebhookFilters from "./WebhookFilters";

export const Webhooks = () => {
  const hasPermission = useAgentHasPermissions([WRITE_WEBHOOKS]);
  const isTiliaAgent = useAgentIsTiliaAgent();
  const contextIntegrator = useAgentIntegrator();
  const [loading, error] = useScript({ src: "https://embedded.webhooks.io/js/embedded.js" });

  if (!hasPermission) {
    return <PermissionsMessage />;
  }
  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <PageContent title={{ page: "Webhooks" }} opts={{ clear: true }}>
      <PageTitle>Webhooks</PageTitle>
      {error ? (
        <SectionError />
      ) : (
        <>
          <FilterContextProvider
            config={[
              {
                name: "integrator",
                defaultValue: isTiliaAgent ? undefined : contextIntegrator.integratorId,
              },
            ]}
          >
            {isTiliaAgent && <WebhookFilters />}
            <WebhookDisplay />
          </FilterContextProvider>
        </>
      )}
    </PageContent>
  );
};
