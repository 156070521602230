import { Helmet as ReactHelment } from "react-helmet";
import { ROUTE_BASENAME, getEnvironment, ENVIRONMENT } from "utils/environment";

const faviconPaths = {
  [ENVIRONMENT.DEV]: `favicon-green.ico`,
  [ENVIRONMENT.STAGING_TEST]: `favicon-yellow.ico`,
  [ENVIRONMENT.STAGING]: `favicon-red.ico`,
  [ENVIRONMENT.PROD]: `favicon-blue.ico`,
};

const faviconPath = `${ROUTE_BASENAME}/${faviconPaths[getEnvironment()]}`;

export const Helmet = () => (
  <ReactHelment>
    <link rel="shortcut icon" href={faviconPath} type="image/x-icon" />
  </ReactHelment>
);
