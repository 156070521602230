import { SORT_DIRECTION } from "components/Grid/utils";
import { strings } from "./strings";
import { canChangeStatus } from "./utils";

export const STATUS = {
  tiliaOnHold: "TILIA-ONHOLD",
  providerOnHold: "PROVIDER-ONHOLD",
  escrowed: "FUNDS-IN-ESCROW",
  success: "SUCCESS",
  rejected: "SUPPORT-REJECTED",
  userCanceled: "USER-REVERSED",
  failed: "FAILED",
};
export const ACTIONS = {
  ON_HOLD: "on-hold",
  APPROVE: "approve",
  REJECT: "reject",
};
export const STATUS_VALUE_LABEL_MAP = {
  [Object.values(STATUS).join(",")]: strings.all,
  [STATUS.escrowed]: strings.pending_review,
  [STATUS.tiliaOnHold]: strings.on_hold,
  [STATUS.providerOnHold]: strings.provider_on_hold,
  [STATUS.success]: strings.approved,
  [STATUS.rejected]: strings.rejected,
  [STATUS.userCanceled]: strings.user_canceled,
  [STATUS.failed]: strings.failed,
};

export const STATUS_VALUE_COLOR_MAP = {
  [STATUS.escrowed]: "orange",
  [STATUS.tiliaOnHold]: "orange",
  [STATUS.providerOnHold]: "orange",
  [STATUS.success]: "green",
  [STATUS.rejected]: "red",
  [STATUS.userCanceled]: "red",
  [STATUS.failed]: "red",
};
export const FIELDS = {
  status: "status",
  integrator: "integrator",
  amount: "amount",
  account_id: "account_id",
  created: "created",
  payout_status_id: "payout_status_id",
  destination_payment_method: "destination_payment_method",
};
export const defaultGridProps = {
  keyField: FIELDS.payout_status_id, // Useful when selection is enabled
  sortDir: SORT_DIRECTION.DESC,
  sortField: FIELDS.created,
  canBeSelected: canChangeStatus, // Method called per record to determine if row can be selected
};
