import React from "react";
import * as R from "ramda";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { Desktop, theme } from "styles";
import { formatDateTime, isNilOrEmpty } from "utils";
import { useToggle } from "utils/useToggle";
import { Cell, SortingHeaderCell } from "components/Sorter";
import { Id } from "components/Copyable";

export const row = css`
  padding 8px 0;
  border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
`;

const header = css`
  ${row}
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 1fr;
`;

const DesktopTransaction = ({ data }) => {
  const [expanded, toggleExpanded] = useToggle();
  const thTestId = (field) => `transaction-history-${data.invoice_id}-${field}`;
  const liTestId = (id, field = "") => `th-line-item-${id}-${field}`;
  const pmTestId = (id, field = "") => `th-payment-method-${id}-${field}`;
  return (
    <div
      data-testid="transaction-history-row"
      css={`
        cursor: pointer;
        &:hover {
          background-color: ${theme("colors.dataTable.hoverBg")};
        }
        border: 1px solid ${expanded ? theme("colors.dataTable.rowBorder") : "transparent"};
        background-color: ${expanded ? theme("colors.dataTable.expandedBg") : "transparent"};
        border-radius: ${expanded ? "2px" : "0"};
        margin-top: ${expanded ? "8px" : "0"};
        margin-bottom: ${expanded ? "8px" : "0"};
      `}
    >
      <div
        css={`
          ${header}
          &:hover {
            background-color: ${theme("colors.dataTable.hoverBg")};
            color: ${theme("colors.dataTable.hoverFg")};
          }
        `}
        onClick={toggleExpanded}
      >
        <Cell data-testid={thTestId("invoice_id")}>
          <Id id={data.invoice_id} />
        </Cell>
        <Cell data-testid={thTestId("state")}>{data.state}</Cell>
        <Cell data-testid={`transaction-history-${data.invoice_id}-created`}>
          {formatDateTime(data.created)}
        </Cell>
        <Cell data-testid={`transaction-history-${data.invoice_id}-symbol_total`} right>
          {data.symbol_total}
        </Cell>
      </div>
      {expanded && (
        <>
          {data.line_items.map((li) => (
            <div
              css={`
                ${row}
                display: grid;
                grid-template-columns: 3fr 4fr 1fr;
              `}
              key={li.line_item_id}
            >
              <Cell data-testid={liTestId(li.line_item_id)}>LINE ITEM</Cell>
              <Cell data-testid={liTestId(li.line_item_id, "note")}>{li.note}</Cell>
              <Cell data-testid={liTestId(li.line_item_id, "symbol_amount")} right>
                {li.symbol_amount}
              </Cell>
            </div>
          ))}
          {data.payment_methods.map((pm) => (
            <div
              css={`
                ${row}
                display: grid;
                grid-template-columns: 3fr 4fr 1fr;
              `}
              key={pm.payment_method_id}
            >
              <Cell data-testid={pmTestId(pm.payment_method_id)}>PAYMENT METHOD</Cell>
              <Cell data-testid={pmTestId(pm.payment_method_id, "description")}>
                {" "}
                {pm.description}{" "}
              </Cell>
              <Cell data-testid={pmTestId(pm.payment_method_id, "symbol_amount")} right>
                {pm.symbol_amount}
              </Cell>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export const DesktopTransactions = ({ data, ...sortingProps }) => {
  return (
    <Desktop>
      <div
        css={`
          ${header}
        `}
      >
        <SortingHeaderCell {...sortingProps} field="invoice_id">
          Id
        </SortingHeaderCell>
        <SortingHeaderCell {...sortingProps} field="state">
          State
        </SortingHeaderCell>
        <SortingHeaderCell {...sortingProps} field="created">
          Created Date
        </SortingHeaderCell>
        <SortingHeaderCell {...sortingProps} field="symbol_total" right>
          Total Amount
        </SortingHeaderCell>
      </div>
      {isNilOrEmpty(data) ? (
        <div css={row}>No Data</div>
      ) : (
        R.map((t) => <DesktopTransaction key={t.invoice_id} data={t} />)(data)
      )}
    </Desktop>
  );
};
