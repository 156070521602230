import { TokenConversionStatusCode, TransactionType } from "@tilia-tools/core/transaction";
import { TransactionHeader } from "../Layout";
import TokenConversionStatusCodeName from "./TokenConversionStatusCodeName";

const statusColors = {
  [TokenConversionStatusCode.OPEN]: "orange",
  [TokenConversionStatusCode.PROCESSING]: "orange",
  [TokenConversionStatusCode.SUCCESS]: "green",
  [TokenConversionStatusCode.FAILED]: "red",
};

function TokenConversionHeader({ tokenConversion }) {
  return (
    <TransactionHeader
      title="Token Conversion"
      transactionType={TransactionType.TOKEN_CONVERSION}
      badge={{
        label: (
          <TokenConversionStatusCodeName
            tokenConversionStatusCode={tokenConversion.tokenConversionStatusCode}
          />
        ),
        color: statusColors[tokenConversion.tokenConversionStatusCode],
      }}
      copyableId={tokenConversion.tokenConversionId}
      date={tokenConversion.date}
    />
  );
}

export default TokenConversionHeader;
