import { Text } from "@chakra-ui/react";
import { DetailText } from "components/Text";
import AccountCard from "components/Transaction/AccountCard";
import {
  Section,
  TransactionColumnLeft,
  TransactionColumnRight,
  TransactionLayout,
  TransactionMain,
} from "components/Transaction/Layout";
import TokenConversionSummary from "./TokenConversionSummary";
import TokenConversionIDs from "./TokenConversionIDs";
import TokenConversionRecipient from "./TokenConversionRecipient";

function TokenConversionDetail({ tokenConversion }) {
  return (
    <TransactionLayout>
      <TransactionColumnLeft>
        {/* TODO: Add tags https://tiliajira.atlassian.net/browse/DEV-3135 */}
        {/* <Tags /> */}
        <Section title="Account Detail">
          <Text mt="0">
            <DetailText>{tokenConversion.integrator}</DetailText>
          </Text>
          <AccountCard accountId={tokenConversion.account.accountId} />
        </Section>
        <Section title="Recipients">
          <TokenConversionRecipient tokenConversion={tokenConversion} />
        </Section>
      </TransactionColumnLeft>
      <TransactionMain>
        <Section title="Summary">
          <TokenConversionSummary tokenConversion={tokenConversion} />
        </Section>
        <Section title="Token Conversion Details">
          <TokenConversionIDs invoiceId={tokenConversion.invoiceId} />
        </Section>
      </TransactionMain>
      <TransactionColumnRight />
    </TransactionLayout>
  );
}

export default TokenConversionDetail;
