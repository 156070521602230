import { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { DataRow } from "components/DataRow";
import GridContext from "components/Grid/GridContext";
import { AccountLink } from "components/Account";
import { useFormModal } from "components/Modal";
import { useAgent } from "context/agentContext";
import { READ_INVOICES, READ_INVOICE } from "context/permissions";
import React, { useContext, useState } from "react";
import { formatDateTime } from "utils";
import { ctxWithIntegrator } from "utils/auth";
import * as Yup from "yup";
import { STATUS_VALUE_LABEL_MAP } from "../constants";
import { strings } from "../strings";
import { formatPaymentMethod } from "../utils";
import { getInvoiceById } from "api/invoicing";

const usePayoutModal = (payout, action, descriptionFunc, onSubmit, disableActionButton) => {
  const { agent } = useAgent();
  const { refresh } = useContext(GridContext);
  const [FormModal, openModal] = useFormModal();
  const [invoiceData, setInvoiceData] = useState(null);
  const hasInvoiceScopes = agent.hasPermissions([READ_INVOICES, READ_INVOICE]);
  const onSelect = async () => {
    if (hasInvoiceScopes && payout.credit_invoice_id && !invoiceData) {
      // call api
      let invoiceResponse;
      try {
        invoiceResponse = await getInvoiceById(
          payout.credit_invoice_id,
          ctxWithIntegrator(payout.integrator, agent)
        );
      } catch {}
      setInvoiceData(invoiceResponse?.payload || null);
    }

    openModal();
  };

  const modalData = {
    payoutId: payout.payout_status_id,
    amount: "$" + payout.credit.amount / 100,
    feeAmount: "$" + payout.credit.fee_amount / 100,
    total: "$" + (payout.credit.amount + payout.credit.fee_amount) / 100,
    requestTime: payout.created,
    destPM: formatPaymentMethod(payout.credit?.destination_payment_method || {}),
    srcPM: formatPaymentMethod(payout.credit?.source_payment_method || {}),
  };

  const title = action[0].toUpperCase() + action.slice(1);
  const PayoutModal = () => {
    return (
      <FormModal
        actionText={title}
        formProps={{
          initialValues: modalData,
          validationSchema: () => Yup.object().shape({}),
        }}
        submitAction={() => onSubmit(agent)}
        onSubmitActionSuccess={refresh}
        testIdPrefix={`${action}-payout-`}
        titleText={`${title} ${strings.payout}`}
        disableActionButton={disableActionButton}
      >
        <div>
          <div>{descriptionFunc(modalData)}</div>
          <div
            css={`
              padding-top: 8px;
              & > div:last-child {
                border-bottom: 0;
              }
            `}
          >
            <DataRow title="User payout amount" data={modalData.amount} />
            <DataRow title="Tilia Fee" data={modalData.feeAmount} />
            <DataRow title="Payout Request Time" data={formatDateTime(modalData.requestTime)} />
            <DataRow
              title="Account ID"
              data={<AccountLink account={payout}>{payout.account_id}</AccountLink>}
            />
            <DataRow title="Payout ID" data={payout.payout_status_id} />
            <DataRow title="Source PMID" data={payout.credit?.destination_payment_method?.id} />
            <DataRow title="Destination PMID" data={payout.credit?.source_payment_method?.id} />
            {invoiceData?.failure_reason ? (
              <DataRow
                title="Failure Reason"
                wrap="white-space: normal"
                data={invoiceData.failure_reason}
              />
            ) : null}
          </div>
        </div>
      </FormModal>
    );
  };
  return { PayoutModal, onSelect };
};

export const useViewPayout = (payout) => {
  const descriptionFunc = () =>
    strings.view_message.format(
      STATUS_VALUE_LABEL_MAP[payout.status],
      formatDateTime(payout.updated)
    );
  const action = "view";
  const disableActionButton = true;
  const { PayoutModal, onSelect } = usePayoutModal(
    payout,
    action,
    descriptionFunc,
    null,
    disableActionButton
  );

  const viewPayoutMenuItem = {
    key: "view-payout",
    item: {
      disabled: false,
      label: strings.view,
      onSelect,
    },
    comp: <PayoutModal key="view-payout" />,
  };

  return { ViewPayoutModal: PayoutModal, viewPayoutMenuItem };
};
