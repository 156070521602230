import { useFormikContext } from "formik";
import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";

function FormError() {
  const { status } = useFormikContext();

  if (!status?.error) {
    return null;
  }

  return (
    <Alert status="error" my={3} width="auto" fontSize="initial" fontWeight="initial">
      <AlertIcon />
      <AlertDescription>{status.error}</AlertDescription>
    </Alert>
  );
}

export default FormError;
