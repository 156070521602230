import { Box } from "@chakra-ui/react";
import SectionHeading from "./SectionHeading";

function Section({ title, children }) {
  return (
    <Box as="section">
      {title && <SectionHeading>{title}</SectionHeading>}
      {children}
    </Box>
  );
}

export default Section;
