import React from "react";
import { Button, Text, useDisclosure } from "@chakra-ui/react";
import { FormModal } from "components/Modal";
import DeleteCancelButtonGroup from "components/Forms/Button/DeleteCancelButtonGroup";
import { deleteClient } from "api/account";
import { useAgent } from "context/agentContext";

export const DeleteClientModal = ({ isOpen, onClose, client, onDelete }) => {
  const { agent } = useAgent();

  const handleSubmit = async (_, { setStatus }) => {
    const result = await deleteClient(client, agent);

    if (result.status !== 200) {
      setStatus({ error: result.errors.join(" ") });
      return;
    }
    onDelete(client);
  };

  return (
    <FormModal
      formikProps={{
        initialValues: {},
        enableReinitialize: true,
        onSubmit: handleSubmit,
      }}
      actions={<DeleteCancelButtonGroup onCancel={onClose} />}
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Client"
    >
      <Text>Are you sure you want to delete "{client.app_name}"?</Text>
    </FormModal>
  );
};

export const DeleteClientModalButton = ({ client, onDelete }) => {
  const { isOpen, onClose, getButtonProps } = useDisclosure();

  return (
    <div>
      <Button type="button" colorScheme="red" size="sm" {...getButtonProps()}>
        Delete
      </Button>
      <DeleteClientModal
        client={client}
        isOpen={isOpen}
        onCancel={onClose}
        onClose={onClose}
        onDelete={onDelete}
      />
    </div>
  );
};
