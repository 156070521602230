import { debug } from "utils";
import { METHODS, assertOk, mapProxyFetch, serviceMap } from "./utils";

const nonceProxy = mapProxyFetch(serviceMap.NONCE_API);

export const generateGDPRDocumentNonce = async (payload, expirationSeconds, ctx) => {
  debug(ctx, "[generateGDPRDocumentNonce]", payload, expirationSeconds);
  const response = await nonceProxy(ctx, `/nonce`, METHODS.POST, {
    payload: JSON.stringify(payload),
    expire: expirationSeconds,
  });
  await assertOk(response);
  return response.json();
};
