import React from "react";
import { READ_WALLETS, withPermissions } from "context/permissions";
import { Panel } from "components/Panel";
import { PermissionsMessage } from "components/PermissionsMessage";
import { WalletTabs } from "./WalletTabs";
import { Route, Routes } from "react-router-dom";
import { useTitle } from "context/titleContext";

export const WalletLedger = withPermissions({ permissions: [READ_WALLETS] }, ({ permissions }) => {
  useTitle({ sub1: "Wallets" });
  if (!permissions.hasPermission) {
    return <PermissionsMessage />;
  }
  return (
    <Panel>
      <Routes>
        <Route path=":walletId?" element={<WalletTabs />} />
      </Routes>
    </Panel>
  );
});
