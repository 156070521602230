// NOTE: The names of most exported objects exactly match the equivalent constant strings in the
// Go services code -- DO NOT RENAME THEM!
//
// This helps to ensure this file, and any usages of these constants in our own code, appear in
// full-code searches for those strings, so that Tools isn't excluded from critical updates that
// modify the underlying functionality.

/**
 * @typedef {Object} InvoiceIdentifier
 * @property {string} invoiceId - The ID of the invoice.
 * @property {string} integrator - The publisher that the invoice belongs to.
 */

/**
 * Tag attached to an invoice or invoice line item.
 * @typedef {Object} InvoicingApiTag
 * @property {string} tag_id Tag TUID.
 * @property {string} tag Tag name.
 */

/**
 * Invoice types.
 * @enum {string}
 */
export const InvoicingApiInvoiceType = Object.freeze({
  BalanceTransferIT: "balance_transfer",
  CancelEscrowIT: "user_purchase_refund_escrow",
  CancelEscrowVirtualIT: "user_purchase_refund_escrow_virtual",
  CommitEscrowIT: "user_purchase_commit_escrow",
  CommitEscrowVirtualIT: "user_purchase_commit_escrow_virtual",
  DepositIT: "deposit",
  EscrowIT: "user_purchase_escrow",
  EscrowVirtualIT: "user_purchase_escrow_virtual",
  MakeGoodIT: "make_good",
  PayoutEscrowIT: "payout_escrow",
  PayoutExecuteIT: "payout_execute",
  PayoutReverseIT: "payout_reverse",
  RefundInvoiceVirtualIT: "refund_invoice_virtual",
  RefundIT: "refund_invoice",
  RefundLineItemInvoiceVirtualIT: "refund_line_item_invoice_virtual",
  RefundLineItemIT: "refund_line_item_invoice",
  TokenConvertIT: "token_convert",
  TokenPurchaseIT: "token_purchase",
  UserPurchaseIT: "user_purchase",
  UserPurchaseVirtualIT: "user_purchase_virtual",
});

/**
 * Invoice status codes (InvoiceState).
 * @enum {string}
 */
export const InvoicingApiInvoiceStatusCode = Object.freeze({
  Open: "OPEN",
  Processing: "PROCESSING",
  Success: "SUCCESS",
  Failed: "FAILED",
  SystemCancelled: "SYSTEM-CANCELLED",
});

/**
 * Invoice status codes representing a "pending" status.
 * @type {Set<InvoicingApiInvoiceStatusCode>}
 */
export const InvoicingApiInvoicePendingStatusCodes = new Set([
  InvoicingApiInvoiceStatusCode.Open,
  InvoicingApiInvoiceStatusCode.Processing,
]);

/**
 * Invoice status codes representing a "success" status.
 * @type {Set<InvoicingApiInvoiceStatusCode>}
 */
export const InvoicingApiInvoiceSuccessStatusCodes = new Set([
  InvoicingApiInvoiceStatusCode.Success,
]);

/**
 * Invoice status codes representing a "failed" status.
 * @type {Set<InvoicingApiInvoiceStatusCode>}
 */
export const InvoicingApiInvoiceFailedStatusCodes = new Set([InvoicingApiInvoiceStatusCode.Failed]);

/**
 * Invoice status codes representing a "canceled" status.
 * @type {Set<InvoicingApiInvoiceStatusCode>}
 */
export const InvoicingApiInvoiceCanceledStatusCodes = new Set([
  InvoicingApiInvoiceStatusCode.SystemCancelled,
]);
