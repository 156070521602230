import { Heading, useStyleConfig } from "@chakra-ui/react";
function SectionHeading({ children }) {
  const styles = useStyleConfig("SectionHeading");
  return (
    <Heading size="sm" sx={styles}>
      {children}
    </Heading>
  );
}

export default SectionHeading;
