import { Panel, PanelContent, PanelTitle } from "components/Panel";
import { PermissionsMessage } from "components/PermissionsMessage";
import { SectionSpinner } from "components/Spinner";
import { getAccountTransactions, useQuery } from "context/graphqlContext";
import { READ_INVOICES, TRANSACTIONS_RESOURCE } from "context/permissions";
import * as R from "ramda";
import React, { useMemo } from "react";
import { isNilOrEmpty } from "utils";
import { useSorting } from "utils/useSorting";
import { DesktopTransactions } from "./Desktop";
import { MobileTransactions } from "./Mobile";
import { TransactionHistoryV2 } from "./v2";
import useFeatureFlag from "utils/useFeatureFlag";
import { useTitle } from "context/titleContext";

const TransactionsWithData = ({ data = [] }) => {
  const { sorter, sortingProps } = useSorting("created");
  const sortedData = useMemo(() => sorter(data), [data, sorter]);

  return (
    <Panel>
      <PanelTitle>
        <div>
          {`Transaction History (${isNilOrEmpty(sortedData) ? "0" : R.keys(sortedData).length})`}
        </div>
      </PanelTitle>
      <PanelContent>
        <DesktopTransactions data={sortedData} {...sortingProps} />
        <MobileTransactions data={sortedData} {...sortingProps} />
      </PanelContent>
      <PanelTitle>
        <div />
      </PanelTitle>
    </Panel>
  );
};

export const TransactionHistoryV1 = () => {
  const { data, error, loading, permissionsFailed } = useQuery(getAccountTransactions, {
    permissions: READ_INVOICES,
    resource: TRANSACTIONS_RESOURCE,
  });

  if (loading) {
    return <SectionSpinner />;
  }
  if (permissionsFailed) {
    return <PermissionsMessage />;
  }

  return (
    <>
      {error ? (
        <p>Error Transaction History V1</p>
      ) : (
        <TransactionsWithData data={R.path(["account", "transactionHistory"], data)} />
      )}
    </>
  );
};

export const Transactions = () => {
  useTitle({ sub1: "Transactions" });
  const showTransactionHistoryV1 = useFeatureFlag("transaction_history_v1");
  return (
    <>
      <TransactionHistoryV2 />
      {showTransactionHistoryV1 && <TransactionHistoryV1 />}
    </>
  );
};
