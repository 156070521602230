import { useBasicActionModal } from "components/Modal";
import Pager from "components/Pager";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import GridContext from "./GridContext";
import { useGridStates } from "./useGridStates";
import { useNavigate, useLocation } from "react-router-dom";
import { setFilterMap } from "./utils";

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 500px;
`;

const GridProvider = ({
  initialData = null,
  children,
  keyField = "id",
  hidePager = false,
  canBeSelected = () => true,
  usesConnection = false,
  initialSortField,
  initialSortDir,
}) => {
  // @Variables

  const [data, setData] = useState(initialData);
  const [modalConfig, setModalConfig] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selection, setSelection] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [BAModal, BAopenModal, BAcloseModal] = useBasicActionModal();

  const [update, setUpdate] = useState(0);

  const { currentPage, pageSize, filterMap, sortField, sortDir } = useGridStates(
    setSelection,
    initialSortField,
    initialSortDir
  );

  const totalPages = Math.ceil(totalCount / pageSize);
  // @Utils
  const dataForSelection = data?.filter(canBeSelected);
  const isOutOfPage = useCallback(
    (record) => data.find((item) => item[keyField] === record[keyField]) === undefined,
    [data, keyField]
  );
  const isSelected = useCallback(
    (record) => selection.find((item) => item[keyField] === record[keyField]) !== undefined,
    [selection, keyField]
  );
  const isAllPageSelected =
    dataForSelection?.length > 0 &&
    dataForSelection?.filter((item) => !isSelected(item))?.length === 0;
  const pageSelection = dataForSelection?.filter((item) => isSelected(item));
  const openModal = useCallback(() => {
    setShowModal(true);
    BAopenModal();
  }, [setShowModal, BAopenModal]);
  const closeModal = useCallback(() => {
    setShowModal(false);
    BAcloseModal();
  }, [setShowModal, BAcloseModal]);

  const location = useLocation();
  const navigate = useNavigate();

  // @Events
  /**
   * @param {string} dir - sorting direction
   * Any sort change will cause selection to be cleared and page to be reset to 0
   */
  const setSortDir = useCallback(
    (dir) => {
      const params = new URLSearchParams(location.search);
      params.set("sortDir", dir);

      if (usesConnection) {
        if (params.has("last")) {
          params.set("first", params.get("last"));
          params.delete("last");
        }
        params.delete("after");
        params.delete("before");
      } else {
        params.set("currentPage", "0");
        setFilterMap(params, filterMap);
      }

      navigate({ search: params.toString() });
    },
    [filterMap, usesConnection, navigate, location.search]
  );

  /**
   * @param {string} field - sorting field
   * Any sort change will cause selection to be cleared and page to be reset to 0
   */
  const setSortField = useCallback(
    (field) => {
      const params = new URLSearchParams(location.search);
      params.set("sortField", field);

      if (usesConnection) {
        if (params.has("last")) {
          params.set("first", params.get("last"));
          params.delete("last");
        }
        params.delete("after");
        params.delete("before");
      } else {
        params.set("currentPage", "0");
        setFilterMap(params, filterMap);
      }

      navigate({ search: params.toString() });
    },
    [filterMap, usesConnection, navigate, location.search]
  );

  /**
   * @param {number} size - items per page
   * Page size change will cause page to be reset to 0
   */
  const updatePageSize = useCallback(
    (size) => {
      const params = new URLSearchParams(location.search);
      params.set("pageSize", size.toString());
      params.set("currentPage", "0");

      setFilterMap(params, filterMap);

      navigate({ search: params.toString() });
    },
    [filterMap, navigate, location.search]
  );

  const setPage = useCallback(
    (pageNum) => {
      const params = new URLSearchParams(location.search);

      params.set("currentPage", pageNum.toString());

      setFilterMap(params, filterMap);

      navigate({ search: params.toString() });
    },
    [filterMap, navigate, location.search]
  );

  /**
   * @param {string} field
   * @param {string|bool|number} value
   * Any filter change will cause page to be reset to 0 and selection to be cleared
   */
  const addUpdateFilter = useCallback(
    (field, value) => {
      const params = new URLSearchParams(location.search);

      params.set("currentPage", "0");

      const newFilterMap = { ...filterMap, [field]: value };

      setFilterMap(params, newFilterMap);

      navigate({ search: params.toString() });
    },
    [filterMap, navigate, location.search]
  );

  /**
   * @param {string} fieldToRemove
   * Any filter change will cause page to be reset to 0 and selection to be cleared
   */
  const removeFilter = useCallback(
    (fieldToRemove) => {
      const params = new URLSearchParams(location.search);

      params.set("currentPage", "0");

      setFilterMap(params, filterMap, fieldToRemove);

      navigate({ search: params.toString() });
    },
    [filterMap, navigate, location.search]
  );

  /**
   * @param {object} record
   * By "keyField" we can determine whether to select or deselect the row
   */
  const selectDeselect = useCallback(
    (record) => {
      if (isSelected(record)) {
        setSelection((selection) =>
          selection.filter((item) => item[keyField] !== record[keyField])
        );
      } else if (canBeSelected?.(record)) {
        setSelection((selection) => [...selection, record]);
      }
    },
    [canBeSelected, isSelected, keyField]
  );

  /**
   * All items in the current page will be selected (same as gmail select all)
   */
  const selectDeselectPage = useCallback(() => {
    if (isAllPageSelected) {
      setSelection((selection) => selection.filter(isOutOfPage));
    } else {
      setSelection((selection) => selection.concat(dataForSelection));
    }
  }, [dataForSelection, isAllPageSelected, isOutOfPage]);

  /**
   * All items in the current page will be deselected
   */
  const deselectPage = useCallback(() => {
    setSelection((selection) => selection.filter(isOutOfPage));
  }, [isOutOfPage]);

  /**
   * Use it when nor filter, nor sort, nor page size change was made.
   * For example, when a CRUD operation has been made and we need to
   * refetch data.
   */
  const refresh = useCallback(() => {
    setUpdate((update) => update + 1);
  }, []);

  // @DOM
  return (
    <GridContext.Provider
      value={{
        data,
        setData,
        // For Selection
        isSelected, // Per row
        selectDeselect, // Per row
        canBeSelected, // Per row
        deselectPage, // Per page
        selectDeselectPage, // All page
        isAllPageSelected, // All page
        pageSelection, // All page
        // For Sorting
        sortDir,
        sortField,
        setSortDir,
        setSortField,
        // For Filtering
        filterMap,
        addUpdateFilter,
        removeFilter,
        // For Pagination
        currentPage,
        pageSize,
        totalPages,
        totalCount,
        setPage,
        setTotalCount,
        updatePageSize,
        // Triggers
        update,
        refresh,

        // Modal
        setModalConfig,
        openModal,
        closeModal,
      }}
    >
      <Container>
        {children}
        {data !== null && !hidePager && (
          <Pager
            key="pager"
            setPage={setPage}
            pageSize={pageSize}
            totalPages={totalPages}
            currentPage={currentPage}
            updatePageSize={updatePageSize}
          />
        )}
        {showModal && (
          <BAModal {...modalConfig} testIdPrefix={`payout-grid-modal-`}>
            {modalConfig.content}
          </BAModal>
        )}
      </Container>
    </GridContext.Provider>
  );
};

export default GridProvider;
