import { PurchaseStatusCode } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the purchase status code.
 *
 * @param {PurchaseStatusCode} purchaseStatusCode
 * @returns {JSX.Element}
 */
function PurchaseStatusCodeName({ purchaseStatusCode }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (purchaseStatusCode) {
    case PurchaseStatusCode.OPEN:
      return <>Open</>;
    case PurchaseStatusCode.PROCESSING:
      return <>Processing</>;
    case PurchaseStatusCode.SUCCESS:
      return <>Success</>;
    case PurchaseStatusCode.FAILED:
      return <>Failed</>;
    case PurchaseStatusCode.SYSTEM_CANCELED:
      return <>System Canceled</>;
    default:
      return <>{purchaseStatusCode}</>;
  }
}

export default PurchaseStatusCodeName;
