import React, { useEffect } from "react";
import * as R from "ramda";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { Formik } from "formik";
import * as Yup from "yup";
import { mq } from "styles";
import { useKYC, KYCProvider } from "context/kycContext";
import { PageContent, PageTitle } from "components/Page";
import { Panel } from "components/Panel";
import { Text } from "components/Forms";
import { READ_KYCS, withPermissions, KYC_DETAILS_RESOURCE } from "context/permissions";
import { PermissionsMessage } from "components/PermissionsMessage";
import { validationMessages as vm } from "utils/forms";
import { Spinner } from "components/Spinner";

const getAccountId = R.path(["data", "payload", "account_id"]);

const KYCLookup = () => {
  const navigate = useNavigate();
  const { state, lookupApplicationId } = useKYC();
  const accountId = getAccountId(state);
  useEffect(() => {
    if (accountId) {
      navigate(`/account/${accountId}/kyc`);
      return () => null;
    }
  }, [navigate, accountId]);

  return (
    <Formik
      initialValues={{
        applicationId: "",
      }}
      validationSchema={Yup.object().shape({
        applicationId: Yup.string().required(vm.required),
      })}
      onSubmit={(values, actions) => {
        const mapError = (err) => {
          actions.setFieldError("applicationId", err.message);
        };
        lookupApplicationId(values.applicationId, actions.setSubmitting, mapError);
      }}
    >
      {({ values, touched, errors, handleBlur, handleChange, handleSubmit, isSubmitting }) => {
        const handleKeyPress = (e) => {
          if (e.key === "Enter") {
            if (values.applicationId.length > 0) {
              handleSubmit(e);
            }
          }
        };
        const handleFocus = (e) => e.target.select();
        return (
          <form onSubmit={handleSubmit}>
            <Text
              data-testid="kyc-applicationid-input"
              label="Application ID"
              name="applicationId"
              value={values.applicationId}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.applicationId}
              touched={touched.applicationId}
              onKeyPress={handleKeyPress}
              onFocus={handleFocus}
            />
            {isSubmitting && <Spinner />}
          </form>
        );
      }}
    </Formik>
  );
};

export const KYC = withPermissions(
  { permissions: [READ_KYCS], resource: KYC_DETAILS_RESOURCE },
  ({ permissions }) => (
    <KYCProvider>
      <PageContent title={{ page: "KYC" }} opts={{ clear: true }}>
        <PageTitle>KYC</PageTitle>
        <Panel>
          {permissions.hasPermission ? (
            <div
              css={`
                ${mq.greaterThan("mobile")`
            width: 75%;
          `};
              `}
            >
              <KYCLookup />
            </div>
          ) : (
            <PermissionsMessage />
          )}
        </Panel>
      </PageContent>
    </KYCProvider>
  )
);
