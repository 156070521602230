import useAgentHasPermissions from "context/useAgentHasPermissions";
import { READ_SETTLEMENTS, WRITE_SETTLEMENTS_PAY } from "context/permissions";
import { SettlementStatusCode } from "./settlementTypes";
import useCurrentSettlementStatus from "./useCurrentSettlementStatus";

/**
 * Returns true if the authenticated agent can mark the given settlement as paid.
 *
 * @param {SettlementIdentifier} [settlementIdentifier]
 * @returns {boolean}
 */
function useAgentCanMarkSettlementPaid(settlementIdentifier) {
  const hasPermission = useAgentHasPermissions([READ_SETTLEMENTS, WRITE_SETTLEMENTS_PAY]);
  const { loading, data, error } = useCurrentSettlementStatus(settlementIdentifier);

  if (!hasPermission || loading || error) {
    return false;
  }

  return data?.settlement?.settlementStatusCode === SettlementStatusCode.SETTLED;
}

export default useAgentCanMarkSettlementPaid;
