import React from "react";
import * as Yup from "yup";
import { useFormModal } from "components/Modal";
import { PasswordIcon } from "components/Icons";
import { useAgent } from "context/agentContext";
import { PasswordField, TextField } from "components/Forms/Text";
import { changePassword } from "api/account";

export const useChangePasswordModal = () => {
  const [FormModal, openModal] = useFormModal();

  const ChangePasswordModal = ({ onSuccess }) => {
    const { agent } = useAgent();
    const handleSubmit = async (values) => {
      return changePassword(
        agent.account_id,
        values.oldPassword,
        values.newPassword,
        values.newPasswordRepeat,
        agent
      );
    };
    return (
      <FormModal
        actionIcon={<PasswordIcon size="1rem" />}
        actionText="Change password"
        submitAction={handleSubmit}
        onSubmitActionSuccess={onSuccess}
        titleText="Change password"
        formProps={{
          initialValues: {
            username: agent.username,
            oldPassword: "",
            newPassword: "",
            newPasswordRepeat: "",
          },
          validationSchema: () =>
            Yup.object().shape({
              oldPassword: Yup.string().required("Current password is required"),
              newPassword: Yup.string().required("New password is required"),
              newPasswordRepeat: Yup.string()
                .oneOf([Yup.ref("newPassword"), null], "Passwords don't match")
                .required("Confirm password is required"),
            }),
        }}
      >
        <form>
          <TextField name="username" label="Username" readOnly autoComplete="username" />
          <PasswordField
            name="oldPassword"
            label="Current Password"
            autoComplete="current-password"
          />
          <PasswordField name="newPassword" label="New Password" autoComplete="new-password" />
          <PasswordField
            name="newPasswordRepeat"
            label="Confirm New Password"
            autoComplete="new-password"
          />
        </form>
      </FormModal>
    );
  };

  const changePasswordMenuItem = {
    label: "Change password...",
    icon: <PasswordIcon size="1rem" />,
    onSelect: openModal,
  };

  return [ChangePasswordModal, changePasswordMenuItem];
};
