import { PageContent, PageTitle } from "components/Page";
import { READ_DASHBOARD } from "context/permissions";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import DashboardDisplay from "./DashboardDisplay.js";
import DashboardFilters from "./DashboardFilters.js";
import FilterContextProvider from "components/Filter/FilterContext.js";
import useAgentIntegrator from "context/useAgentIntegrator";
import useAgentIsTiliaAgent from "context/useAgentIsTiliaAgent";

export const Home = () => {
  const hasPermission = useAgentHasPermissions([READ_DASHBOARD]);
  const isTiliaAgent = useAgentIsTiliaAgent();
  const contextIntegrator = useAgentIntegrator();
  const config = [
    {
      name: "integrator",
      defaultValue: isTiliaAgent ? undefined : contextIntegrator.integratorId,
    },
    { name: "date", type: "date", defaultValue: "last30days" },
  ];

  if (!hasPermission) {
    return (
      <PageContent>
        <PageTitle>Welcome</PageTitle>
      </PageContent>
    );
  }

  return (
    <PageContent>
      <PageTitle>Metrics Dashboard</PageTitle>
      <FilterContextProvider config={config}>
        <DashboardFilters />
        <DashboardDisplay />
      </FilterContextProvider>
    </PageContent>
  );
};
