import { Text, useStyleConfig } from "@chakra-ui/react";

function DetailText({ children }) {
  const styles = useStyleConfig("DetailText");
  return (
    <Text as="span" sx={styles}>
      {children}
    </Text>
  );
}

export default DetailText;
