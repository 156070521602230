import { IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { DotsVerticalIcon } from "components/Icons";
import { useWalletDepositModal } from "./useWalletDeposit";
import { useNavigate } from "react-router-dom";
import { useAccount } from "context/accountContext";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import { WRITE_DEPOSITS } from "context/permissions";
import { noop } from "utils";

export const WalletMenu = ({ wallet, onChange = noop }) => {
  const agentCanDeposit = useAgentHasPermissions([WRITE_DEPOSITS]);
  const account = useAccount();
  const navigate = useNavigate();
  const [DepositModal, openDepositModal] = useWalletDepositModal(wallet);

  const addDepositMenuItem = agentCanDeposit && wallet.currency === "USD";

  const menuItems = [];
  if (addDepositMenuItem) {
    menuItems.push({
      value: "Deposit...",
      onClick: openDepositModal,
    });
  }
  // always add the ledger link
  menuItems.push({
    value: "Ledger",
    onClick: () => navigate(`/account/${account.accountId}/wallet-ledger/${wallet.id}`),
  });

  const handleDepositComplete = () => {
    onChange();
  };

  return (
    <Menu>
      <MenuButton
        data-testid="card-menu-button"
        variant="ghost"
        cursor="pointer"
        bg={"transparent"}
        aria-label="Actions menu"
        as={IconButton}
        icon={<DotsVerticalIcon />}
      />
      <MenuList>
        {menuItems.map(({ value, ...props }) => (
          <MenuItem key={value} {...props}>
            {value}
          </MenuItem>
        ))}
      </MenuList>
      <DepositModal onComplete={handleDepositComplete} />
    </Menu>
  );
};
