import { GridItem, Stack } from "@chakra-ui/react";
import { ASIDE_STACK_GAP } from "./TransactionLayout";

function TransactionColumnRight({ children }) {
  return (
    <GridItem colSpan={{ base: "auto", lg: 2, xl: "auto" }}>
      <Stack gap={ASIDE_STACK_GAP}>{children}</Stack>
    </GridItem>
  );
}

export default TransactionColumnRight;
