import * as R from "ramda";
import { isNilOrEmpty } from "utils";

export const toRegionOptions = R.map((c) => ({ label: c.Name, value: c.ISO }));
export const toStateOptions = R.map((c) => ({ label: c.Name, value: c.Name }));

export const countryHasStates = (countryIso) =>
  R.pipe(R.find(R.propEq(countryIso, "ISO")), R.prop("States"), R.complement(R.isNil))(regions);

export const mapUpperName = R.map((e) => R.assoc("NAME", R.toUpper(R.prop("Name", e)), e));

export const getStatesForCountry = (countryISO) =>
  R.pipe(R.find(R.propEq(countryISO, "ISO")), R.defaultTo({}), R.prop("States"))(regions);

export const isValidState = (country, state) => {
  if (isNilOrEmpty(country)) return true;

  const states = getStatesForCountry(country);
  // country has no states => true
  if (isNilOrEmpty(states)) return true;
  // country has states, no state passed => false
  if (isNilOrEmpty(state)) return false;

  // true on match of ISO or name
  return R.either(
    R.any(R.propEq(R.toUpper(state), "ISO")),
    R.any(R.propEq(R.toUpper(state), "NAME"))
  )(states);
};

const getStateProp = (countryISO, stateStr, prop) => {
  if (isNilOrEmpty(countryISO) || isNilOrEmpty(stateStr)) return null;

  const states = getStatesForCountry(countryISO);
  if (isNilOrEmpty(states)) return null;

  const foundByISO = R.find(R.propEq(R.toUpper(stateStr), "ISO"))(states);
  if (!isNilOrEmpty(foundByISO)) {
    return R.prop(prop, foundByISO);
  }
  const foundByName = R.find(R.propEq(R.toUpper(stateStr), "NAME"))(states);
  if (!isNilOrEmpty(foundByName)) {
    return R.prop(prop, foundByName);
  }
  return null;
};

export const toStateISO = (countryISO, stateStr) => getStateProp(countryISO, stateStr, "ISO");
export const toStateName = (countryISO, stateStr) => getStateProp(countryISO, stateStr, "Name");

// ISOs in this list will float to the top of the country list, in the order specified
const pulled = ["US", "CA"];

const indexOf = (ISO) => R.findIndex(R.propEq(ISO, "ISO"));
const getEntry = (countries) => (iso) => countries[indexOf(iso)(countries)];
const getPulledEntries = (countries) => R.map(getEntry(countries))(pulled);

const notPulled = (c) => !R.includes(R.prop("ISO", c), pulled);
const sortRemainingEntries = R.pipe(R.filter(notPulled), R.sortBy(R.prop("Name")));

const sortCountries = (countries) =>
  getPulledEntries(countries).concat(sortRemainingEntries(countries));

export const regions = sortCountries([
  {
    Name: "United States",
    ISO: "US",
    PrimaryCurrency: "USD",
    States: mapUpperName([
      { Name: "Alaska", ISO: "AK" },
      { Name: "Alabama", ISO: "AL" },
      { Name: "Arkansas", ISO: "AR" },
      { Name: "Arizona", ISO: "AZ" },
      { Name: "California", ISO: "CA" },
      { Name: "Colorado", ISO: "CO" },
      { Name: "Connecticut", ISO: "CT" },
      { Name: "District of Columbia", ISO: "DC" },
      { Name: "Delaware", ISO: "DE" },
      { Name: "Florida", ISO: "FL" },
      //&State{Name: "Federated States of Micronesia", ISO: "FM"},
      { Name: "Georgia", ISO: "GA" },
      { Name: "Guam", ISO: "GU" },
      { Name: "Hawaii", ISO: "HI" },
      { Name: "Iowa", ISO: "IA" },
      { Name: "Idaho", ISO: "ID" },
      { Name: "Illinois", ISO: "IL" },
      { Name: "Indiana", ISO: "IN" },
      { Name: "Kansas", ISO: "KS" },
      { Name: "Kentucky", ISO: "KY" },
      { Name: "Louisiana", ISO: "LA" },
      { Name: "Massachusetts", ISO: "MA" },
      { Name: "Maryland", ISO: "MD" },
      { Name: "Maine", ISO: "ME" },
      { Name: "Michigan", ISO: "MI" },
      { Name: "Minnesota", ISO: "MN" },
      { Name: "Missouri", ISO: "MO" },
      { Name: "Mississippi", ISO: "MS" },
      { Name: "Montana", ISO: "MT" },
      { Name: "North Carolina", ISO: "NC" },
      { Name: "North Dakota", ISO: "ND" },
      { Name: "Nebraska", ISO: "NE" },
      { Name: "New Hampshire", ISO: "NH" },
      { Name: "New Jersey", ISO: "NJ" },
      { Name: "New Mexico", ISO: "NM" },
      { Name: "Nevada", ISO: "NV" },
      { Name: "New York", ISO: "NY" },
      { Name: "Ohio", ISO: "OH" },
      { Name: "Oklahoma", ISO: "OK" },
      { Name: "Oregon", ISO: "OR" },
      { Name: "Pennsylvania", ISO: "PA" },
      { Name: "Puerto Rico", ISO: "PR" },
      { Name: "Rhode Island", ISO: "RI" },
      { Name: "South Carolina", ISO: "SC" },
      { Name: "South Dakota", ISO: "SD" },
      { Name: "Tennessee", ISO: "TN" },
      { Name: "Texas", ISO: "TX" },
      { Name: "Utah", ISO: "UT" },
      { Name: "Virginia", ISO: "VA" },
      { Name: "Virgin Islands", ISO: "VI" },
      { Name: "Vermont", ISO: "VT" },
      { Name: "Washington", ISO: "WA" },
      { Name: "Wisconsin", ISO: "WI" },
      { Name: "West Virginia", ISO: "WV" },
      { Name: "Wyoming", ISO: "WY" },
      { Name: "Army Europe", ISO: "AE" },
      { Name: "Army Pacific", ISO: "AP" },
      { Name: "Army Americas", ISO: "AA" },
    ]),
  },
  {
    Name: "Canada",
    ISO: "CA",
    PrimaryCurrency: "CAD",
    States: mapUpperName([
      { Name: "Alberta", ISO: "AB" },
      { Name: "British Columbia", ISO: "BC" },
      { Name: "Manitoba", ISO: "MB" },
      { Name: "New Brunswick", ISO: "NB" },
      { Name: "Newfoundland", ISO: "NL" },
      { Name: "Northwest Territories", ISO: "NT" },
      { Name: "Nova Scotia", ISO: "NS" },
      { Name: "Nunavut", ISO: "NU" },
      { Name: "Ontario", ISO: "ON" },
      { Name: "Prince Edward Island", ISO: "PE" },
      { Name: "Quebec", ISO: "QC" },
      { Name: "Saskatchewan", ISO: "SK" },
      { Name: "Yukon Territory", ISO: "YT" },
    ]),
  },
  { ISO: "AD", Name: "Andorra", PrimaryCurrency: "EUR" },
  { ISO: "AE", Name: "United Arab Emirates", PrimaryCurrency: "USD" },
  { ISO: "AF", Name: "Afghanistan", PrimaryCurrency: "USD" },
  { ISO: "AI", Name: "Anguilla", PrimaryCurrency: "USD" },
  { ISO: "AL", Name: "Albania", PrimaryCurrency: "USD" },
  { ISO: "AM", Name: "Armenia", PrimaryCurrency: "USD" },
  { ISO: "AO", Name: "Angola", PrimaryCurrency: "USD" },
  { ISO: "AQ", Name: "Antarctica", PrimaryCurrency: "USD" },
  { ISO: "AR", Name: "Argentina", PrimaryCurrency: "USD" },
  { ISO: "AS", Name: "American Samoa", PrimaryCurrency: "USD" },
  { ISO: "AT", Name: "Austria", PrimaryCurrency: "EUR" },
  { ISO: "AU", Name: "Australia", PrimaryCurrency: "AUD" },
  { ISO: "AW", Name: "Aruba", PrimaryCurrency: "USD" },
  { ISO: "AZ", Name: "Azerbaidjan", PrimaryCurrency: "USD" },
  { ISO: "BA", Name: "Bosnia-Herzegovina", PrimaryCurrency: "USD" },
  { ISO: "BB", Name: "Barbados", PrimaryCurrency: "USD" },
  { ISO: "BD", Name: "Bangladesh", PrimaryCurrency: "USD" },
  { ISO: "BE", Name: "Belgium", PrimaryCurrency: "EUR" },
  { ISO: "BF", Name: "Burkina Faso", PrimaryCurrency: "EUR" },
  { ISO: "BG", Name: "Bulgaria", PrimaryCurrency: "USD" },
  { ISO: "BH", Name: "Bahrain", PrimaryCurrency: "USD" },
  { ISO: "BI", Name: "Burundi", PrimaryCurrency: "USD" },
  { ISO: "BJ", Name: "Benin", PrimaryCurrency: "EUR" },
  { ISO: "BM", Name: "Bermuda", PrimaryCurrency: "USD" },
  { ISO: "BN", Name: "Brunei Darussalam", PrimaryCurrency: "USD" },
  { ISO: "BO", Name: "Bolivia", PrimaryCurrency: "USD" },
  { ISO: "BQ", Name: "Bonaire", PrimaryCurrency: "USD" },
  { ISO: "BR", Name: "Brazil", PrimaryCurrency: "USD" },
  { ISO: "BS", Name: "Bahamas", PrimaryCurrency: "USD" },
  { ISO: "BT", Name: "Bhutan", PrimaryCurrency: "USD" },
  { ISO: "BV", Name: "Bouvet Island", PrimaryCurrency: "USD" },
  { ISO: "BW", Name: "Botswana", PrimaryCurrency: "USD" },
  { ISO: "BY", Name: "Belarus", PrimaryCurrency: "USD" },
  { ISO: "BZ", Name: "Belize", PrimaryCurrency: "USD" },
  { ISO: "CC", Name: "Cocos Islands", PrimaryCurrency: "USD" },
  { ISO: "CF", Name: "Central African Republic", PrimaryCurrency: "USD" },
  { ISO: "CG", Name: "Congo", PrimaryCurrency: "EUR" },
  { ISO: "CH", Name: "Switzerland", PrimaryCurrency: "USD" },
  { ISO: "CI", Name: "Cote d'Ivoire", PrimaryCurrency: "EUR" },
  { ISO: "CK", Name: "Cook Islands", PrimaryCurrency: "USD" },
  { ISO: "CL", Name: "Chile", PrimaryCurrency: "USD" },
  { ISO: "CM", Name: "Cameroon", PrimaryCurrency: "USD" },
  { ISO: "CN", Name: "China", PrimaryCurrency: "USD" },
  { ISO: "CO", Name: "Colombia", PrimaryCurrency: "USD" },
  { ISO: "CR", Name: "Costa Rica", PrimaryCurrency: "USD" },
  { ISO: "CZ", Name: "Czech Republic", PrimaryCurrency: "USD" },
  { ISO: "CU", Name: "Cuba", PrimaryCurrency: "USD" },
  { ISO: "CV", Name: "Cape Verde", PrimaryCurrency: "USD" },
  { ISO: "CX", Name: "Christmas Island", PrimaryCurrency: "AUD" },
  { ISO: "CY", Name: "Cyprus", PrimaryCurrency: "EUR" },
  { ISO: "DE", Name: "Germany", PrimaryCurrency: "EUR" },
  { ISO: "DJ", Name: "Djibouti", PrimaryCurrency: "USD" },
  { ISO: "DK", Name: "Denmark", PrimaryCurrency: "USD" },
  { ISO: "DO", Name: "Dominican Republic", PrimaryCurrency: "USD" },
  { ISO: "DZ", Name: "Algeria", PrimaryCurrency: "USD" },
  { ISO: "EC", Name: "Ecuador", PrimaryCurrency: "USD" },
  { ISO: "EE", Name: "Estonia", PrimaryCurrency: "EUR" },
  { ISO: "EG", Name: "Egypt", PrimaryCurrency: "USD" },
  { ISO: "EH", Name: "Western Sahara", PrimaryCurrency: "USD" },
  { ISO: "ES", Name: "Spain", PrimaryCurrency: "EUR" },
  { ISO: "ET", Name: "Ethiopia", PrimaryCurrency: "USD" },
  { ISO: "FI", Name: "Finland", PrimaryCurrency: "EUR" },
  { ISO: "FJ", Name: "Fiji", PrimaryCurrency: "USD" },
  { ISO: "FK", Name: "Falkland Islands", PrimaryCurrency: "USD" },
  { ISO: "FM", Name: "Micronesia", PrimaryCurrency: "USD" },
  { ISO: "FO", Name: "Faroe Islands", PrimaryCurrency: "USD" },
  { ISO: "FR", Name: "France", PrimaryCurrency: "EUR" },
  { ISO: "GA", Name: "Gabon", PrimaryCurrency: "EUR" },
  { ISO: "GD", Name: "Grenada", PrimaryCurrency: "USD" },
  { ISO: "GE", Name: "Georgia", PrimaryCurrency: "USD" },
  { ISO: "GY", Name: "Guyana", PrimaryCurrency: "USD" },
  { ISO: "GH", Name: "Ghana", PrimaryCurrency: "USD" },
  { ISO: "GI", Name: "Gibraltar", PrimaryCurrency: "USD" },
  { ISO: "GL", Name: "Greenland", PrimaryCurrency: "USD" },
  { ISO: "GM", Name: "Gambia", PrimaryCurrency: "USD" },
  { ISO: "GN", Name: "Guinea", PrimaryCurrency: "USD" },
  { ISO: "GP", Name: "Guadeloupe", PrimaryCurrency: "EUR" },
  { ISO: "GQ", Name: "Equatorial Guinea", PrimaryCurrency: "USD" },
  { ISO: "GR", Name: "Greece", PrimaryCurrency: "EUR" },
  { ISO: "GT", Name: "Guatemala", PrimaryCurrency: "USD" },
  { ISO: "GU", Name: "Guam", PrimaryCurrency: "USD" },
  { ISO: "GW", Name: "Guinea Bissau", PrimaryCurrency: "USD" },
  { ISO: "HK", Name: "Hong Kong", PrimaryCurrency: "USD" },
  { ISO: "HN", Name: "Honduras", PrimaryCurrency: "USD" },
  { ISO: "HR", Name: "Croatia", PrimaryCurrency: "EUR" },
  { ISO: "HT", Name: "Haiti", PrimaryCurrency: "USD" },
  { ISO: "HU", Name: "Hungary", PrimaryCurrency: "USD" },
  { ISO: "ID", Name: "Indonesia", PrimaryCurrency: "USD" },
  { ISO: "IE", Name: "Ireland", PrimaryCurrency: "EUR" },
  { ISO: "IL", Name: "Israel", PrimaryCurrency: "USD" },
  { ISO: "IN", Name: "India", PrimaryCurrency: "USD" },
  { ISO: "IQ", Name: "Iraq", PrimaryCurrency: "USD" },
  { ISO: "IR", Name: "Iran", PrimaryCurrency: "USD" },
  { ISO: "IS", Name: "Iceland", PrimaryCurrency: "USD" },
  { ISO: "IT", Name: "Italy", PrimaryCurrency: "EUR" },
  { ISO: "JM", Name: "Jamaica", PrimaryCurrency: "USD" },
  { ISO: "JO", Name: "Jordan", PrimaryCurrency: "USD" },
  { ISO: "JP", Name: "Japan", PrimaryCurrency: "JPY" },
  { ISO: "KE", Name: "Kenya", PrimaryCurrency: "USD" },
  { ISO: "KG", Name: "Kyrgyzstan", PrimaryCurrency: "USD" },
  { ISO: "KH", Name: "Cambodia", PrimaryCurrency: "USD" },
  { ISO: "KI", Name: "Kiribati", PrimaryCurrency: "USD" },
  { ISO: "KM", Name: "Comoros", PrimaryCurrency: "USD" },
  { ISO: "KP", Name: "Korea North", PrimaryCurrency: "USD" },
  { ISO: "KR", Name: "Korea South", PrimaryCurrency: "USD" },
  { ISO: "KW", Name: "Kuwait", PrimaryCurrency: "USD" },
  { ISO: "KY", Name: "Cayman Islands", PrimaryCurrency: "USD" },
  { ISO: "KZ", Name: "Kazakhstan", PrimaryCurrency: "USD" },
  { ISO: "LA", Name: "Laos", PrimaryCurrency: "USD" },
  { ISO: "LB", Name: "Lebanon", PrimaryCurrency: "USD" },
  { ISO: "LC", Name: "Saint Lucia", PrimaryCurrency: "USD" },
  { ISO: "LI", Name: "Liechtenstein", PrimaryCurrency: "USD" },
  { ISO: "LK", Name: "Sri Lanka", PrimaryCurrency: "USD" },
  { ISO: "LR", Name: "Liberia", PrimaryCurrency: "USD" },
  { ISO: "LS", Name: "Lesotho", PrimaryCurrency: "USD" },
  { ISO: "LT", Name: "Lithuania", PrimaryCurrency: "EUR" },
  { ISO: "LU", Name: "Luxembourg", PrimaryCurrency: "EUR" },
  { ISO: "LV", Name: "Latvia", PrimaryCurrency: "EUR" },
  { ISO: "LY", Name: "Libya", PrimaryCurrency: "EUR" },
  { ISO: "MA", Name: "Morocco", PrimaryCurrency: "USD" },
  { ISO: "MC", Name: "Monaco", PrimaryCurrency: "EUR" },
  { ISO: "MD", Name: "Moldova", PrimaryCurrency: "USD" },
  { ISO: "MG", Name: "Madagascar", PrimaryCurrency: "USD" },
  { ISO: "MH", Name: "Marshall Islands", PrimaryCurrency: "USD" },
  { ISO: "ML", Name: "Mali", PrimaryCurrency: "EUR" },
  { ISO: "MM", Name: "Myanmar", PrimaryCurrency: "USD" },
  { ISO: "MN", Name: "Mongolia", PrimaryCurrency: "USD" },
  { ISO: "MO", Name: "Macau", PrimaryCurrency: "USD" },
  { ISO: "MP", Name: "Northern Mariana Islands", PrimaryCurrency: "USD" },
  { ISO: "MQ", Name: "Martinique", PrimaryCurrency: "EUR" },
  { ISO: "MR", Name: "Mauritania", PrimaryCurrency: "USD" },
  { ISO: "MS", Name: "Montserrat", PrimaryCurrency: "USD" },
  { ISO: "MT", Name: "Malta", PrimaryCurrency: "EUR" },
  { ISO: "MU", Name: "Mauritius", PrimaryCurrency: "USD" },
  { ISO: "MV", Name: "Maldives", PrimaryCurrency: "USD" },
  { ISO: "MW", Name: "Malawi", PrimaryCurrency: "USD" },
  { ISO: "MX", Name: "Mexico", PrimaryCurrency: "USD" },
  { ISO: "MY", Name: "Malaysia", PrimaryCurrency: "USD" },
  { ISO: "MZ", Name: "Mozambique", PrimaryCurrency: "USD" },
  { ISO: "NA", Name: "Namibia", PrimaryCurrency: "USD" },
  { ISO: "NC", Name: "New Caledonia", PrimaryCurrency: "EUR" },
  { ISO: "NE", Name: "Niger", PrimaryCurrency: "USD" },
  { ISO: "NF", Name: "Norfolk Island", PrimaryCurrency: "AUD" },
  { ISO: "NG", Name: "Nigeria", PrimaryCurrency: "USD" },
  { ISO: "NI", Name: "Nicaragua", PrimaryCurrency: "USD" },
  { ISO: "NL", Name: "Netherlands", PrimaryCurrency: "EUR" },
  { ISO: "NO", Name: "Norway", PrimaryCurrency: "USD" },
  { ISO: "NP", Name: "Nepal", PrimaryCurrency: "USD" },
  { ISO: "NR", Name: "Nauru", PrimaryCurrency: "USD" },
  //&Country{ISO:"NULL",Name:	"Neutral Zone"},
  { ISO: "NU", Name: "Niue", PrimaryCurrency: "USD" },
  { ISO: "NZ", Name: "New Zealand", PrimaryCurrency: "USD" },
  { ISO: "OM", Name: "Oman", PrimaryCurrency: "USD" },
  { ISO: "PA", Name: "Panama", PrimaryCurrency: "USD" },
  { ISO: "PE", Name: "Peru", PrimaryCurrency: "USD" },
  { ISO: "PF", Name: "French Polynesia", PrimaryCurrency: "EUR" },
  { ISO: "PG", Name: "Papua New Guinea", PrimaryCurrency: "USD" },
  { ISO: "PH", Name: "Philippines", PrimaryCurrency: "USD" },
  { ISO: "PK", Name: "Pakistan", PrimaryCurrency: "USD" },
  { ISO: "PL", Name: "Poland", PrimaryCurrency: "USD" },
  { ISO: "PM", Name: "St. Pierre & Miquelon", PrimaryCurrency: "EUR" },
  { ISO: "PN", Name: "Pitcairn", PrimaryCurrency: "USD" },
  { ISO: "PR", Name: "Puerto Rico", PrimaryCurrency: "USD" },
  { ISO: "PT", Name: "Portugal", PrimaryCurrency: "EUR" },
  { ISO: "PW", Name: "Palau", PrimaryCurrency: "USD" },
  { ISO: "PY", Name: "Paraguay", PrimaryCurrency: "USD" },
  { ISO: "QA", Name: "Qatar", PrimaryCurrency: "USD" },
  { ISO: "RE", Name: "Reunion", PrimaryCurrency: "EUR" },
  { ISO: "RO", Name: "Romania", PrimaryCurrency: "USD" },
  { ISO: "RU", Name: "Russia", PrimaryCurrency: "USD" },
  { ISO: "RW", Name: "Rwanda", PrimaryCurrency: "USD" },
  { ISO: "SA", Name: "Saudi Arabia", PrimaryCurrency: "USD" },
  { ISO: "SB", Name: "Solomon Islands", PrimaryCurrency: "USD" },
  { ISO: "SC", Name: "Seychelles", PrimaryCurrency: "USD" },
  { ISO: "SD", Name: "Sudan", PrimaryCurrency: "USD" },
  { ISO: "SE", Name: "Sweden", PrimaryCurrency: "USD" },
  { ISO: "SG", Name: "Singapore", PrimaryCurrency: "USD" },
  { ISO: "SH", Name: "St. Helena", PrimaryCurrency: "USD" },
  { ISO: "SI", Name: "Slovenia", PrimaryCurrency: "EUR" },
  { ISO: "SJ", Name: "Svalbard & Jan Mayen Island", PrimaryCurrency: "USD" },
  { ISO: "SK", Name: "Slovakia", PrimaryCurrency: "EUR" },
  { ISO: "SL", Name: "Sierra Leone", PrimaryCurrency: "USD" },
  { ISO: "SM", Name: "San Marino", PrimaryCurrency: "EUR" },
  { ISO: "SN", Name: "Senegal", PrimaryCurrency: "EUR" },
  { ISO: "SO", Name: "Somalia", PrimaryCurrency: "USD" },
  { ISO: "SR", Name: "Suriname", PrimaryCurrency: "USD" },
  { ISO: "ST", Name: "Sao Tome and Principe", PrimaryCurrency: "USD" },
  //&Country{ISO:"NULL",Name:	"Soviet Union"},
  { ISO: "SV", Name: "El Salvador", PrimaryCurrency: "USD" },
  { ISO: "SY", Name: "Syria", PrimaryCurrency: "USD" },
  { ISO: "SZ", Name: "Swaziland", PrimaryCurrency: "USD" },
  { ISO: "TC", Name: "Turks & Caicos Islands", PrimaryCurrency: "USD" },
  { ISO: "TD", Name: "Chad", PrimaryCurrency: "EUR" },
  { ISO: "TF", Name: "French Southern Terr.", PrimaryCurrency: "EUR" },
  { ISO: "TG", Name: "Togo", PrimaryCurrency: "EUR" },
  { ISO: "TH", Name: "Thailand", PrimaryCurrency: "USD" },
  { ISO: "TJ", Name: "Tajikistan", PrimaryCurrency: "USD" },
  { ISO: "TK", Name: "Tokelau", PrimaryCurrency: "USD" },
  { ISO: "TM", Name: "Turkmenistan", PrimaryCurrency: "USD" },
  { ISO: "TN", Name: "Tunisia", PrimaryCurrency: "EUR" },
  { ISO: "TO", Name: "Tonga", PrimaryCurrency: "USD" },
  { ISO: "TL", Name: "East Timor", PrimaryCurrency: "USD" },
  { ISO: "TR", Name: "Turkey", PrimaryCurrency: "USD" },
  { ISO: "TT", Name: "Trinidad & Tobago", PrimaryCurrency: "USD" },
  { ISO: "TV", Name: "Tuvalu", PrimaryCurrency: "AUD" },
  { ISO: "TW", Name: "Taiwan", PrimaryCurrency: "USD" },
  { ISO: "TZ", Name: "Tanzania", PrimaryCurrency: "USD" },
  { ISO: "UA", Name: "Ukraine", PrimaryCurrency: "USD" },
  { ISO: "UG", Name: "Uganda", PrimaryCurrency: "USD" },
  { ISO: "GB", Name: "United Kingdom", PrimaryCurrency: "GBP" },
  { ISO: "UY", Name: "Uruguay", PrimaryCurrency: "USD" },
  { ISO: "UZ", Name: "Uzbekistan", PrimaryCurrency: "USD" },
  { ISO: "VA", Name: "Vatican City State", PrimaryCurrency: "EUR" },
  { ISO: "VC", Name: "St.Vincent & Grenadines", PrimaryCurrency: "USD" },
  { ISO: "VE", Name: "Venezuela", PrimaryCurrency: "USD" },
  { ISO: "VN", Name: "Vietnam", PrimaryCurrency: "USD" },
  { ISO: "VU", Name: "Vanuatu", PrimaryCurrency: "USD" },
  { ISO: "WF", Name: "Wallis and Futuna Islands", PrimaryCurrency: "USD" },
  { ISO: "WS", Name: "Samoa", PrimaryCurrency: "USD" },
  { ISO: "YE", Name: "Yemen", PrimaryCurrency: "USD" },
  { ISO: "YU", Name: "Yugoslavia", PrimaryCurrency: "USD" },
  { ISO: "ZA", Name: "South Africa", PrimaryCurrency: "USD" },
  { ISO: "ZM", Name: "Zambia", PrimaryCurrency: "USD" },
  { ISO: "CD", Name: "Democratic Republic of the Congo", PrimaryCurrency: "USD" },
  { ISO: "ZW", Name: "Zimbabwe", PrimaryCurrency: "USD" },
  { ISO: "AG", Name: "Antigua and Barbuda", PrimaryCurrency: "USD" },
  { ISO: "MK", Name: "Macedonia", PrimaryCurrency: "USD" },
  { ISO: "RS", Name: "Serbia", PrimaryCurrency: "USD" },
  { ISO: "ME", Name: "Montenegro", PrimaryCurrency: "EUR" },
  { ISO: "AX", Name: "Aland Islands", PrimaryCurrency: "EUR" },
  { ISO: "BL", Name: "Saint Bartelemey", PrimaryCurrency: "USD" },
  { ISO: "DM", Name: "Dominica", PrimaryCurrency: "USD" },
  { ISO: "ER", Name: "Eritrea", PrimaryCurrency: "USD" },
  { ISO: "GF", Name: "French Guiana", PrimaryCurrency: "EUR" },
  { ISO: "GG", Name: "Guernsey", PrimaryCurrency: "GBP" },
  { ISO: "GS", Name: "South Georgia and the South Sandwich Islands", PrimaryCurrency: "GBP" },
  { ISO: "HM", Name: "Heard Island and McDonald Islands", PrimaryCurrency: "USD" },
  { ISO: "IM", Name: "Isle of Man", PrimaryCurrency: "GBP" },
  { ISO: "IO", Name: "British Indian Ocean Territory", PrimaryCurrency: "USD" },
  { ISO: "JE", Name: "Jersey", PrimaryCurrency: "GBP" },
  { ISO: "KN", Name: "Saint Kitts and Nevis", PrimaryCurrency: "USD" },
  { ISO: "MF", Name: "Saint Martin", PrimaryCurrency: "USD" },
  { ISO: "PS", Name: "Palestinian Territory", PrimaryCurrency: "USD" },
  { ISO: "UM", Name: "United States Minor Outlying Islands", PrimaryCurrency: "USD" },
  { ISO: "VG", Name: "Virgin Islands, British", PrimaryCurrency: "USD" },
  { ISO: "VI", Name: "Virgin Islands, U.S.", PrimaryCurrency: "USD" },
  { ISO: "YT", Name: "Mayotte", PrimaryCurrency: "EUR" },
]);
