import { Details } from "./Details";
import { Update } from "./Update";
import { Route, Routes } from "react-router-dom";
import { useTitle } from "context/titleContext";

export const KYC = () => {
  useTitle({ sub1: "KYC" });
  return (
    <Routes>
      <Route>
        <Route index element={<Details />}></Route>
        <Route path="update" element={<Update />}></Route>
      </Route>
    </Routes>
  );
};
