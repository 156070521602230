import { Text } from "@chakra-ui/react";
import { formatDate, formatTime } from "utils";

function DateAndTime({ value }) {
  return (
    <>
      <Text fontWeight={700} as="span">
        {formatDate(value)}
      </Text>
      <Text as="span"> &nbsp;at&nbsp;</Text>
      <Text fontWeight={700} as="span">
        {formatTime(value)}
      </Text>
    </>
  );
}

export default DateAndTime;
