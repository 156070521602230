import { Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { WRITE_REFUNDS } from "context/permissions";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import { Money } from "components/Money";
import { DateAndTime } from "components/DataRow";
import { Container } from "./common";
import TransactionDetailsLink from "components/Transaction/TransactionDetailsLink";
import TokenPurchaseStatusCodeName from "components/Transaction/TokenPurchase/TokenPurchaseStatusCodeName";
import TransactionTypeName from "components/Transaction/TransactionTypeName";
import { TransactionHistoryActionMenu } from "../TransactionHistoryActionMenu";

function TokenPurchaseGridRow({ tokenPurchase, refetch }) {
  const hasWriteRefunds = useAgentHasPermissions([WRITE_REFUNDS]);

  const menuSettings = [];
  if (hasWriteRefunds) {
    menuSettings.push("REFUND");
  }

  return (
    <Container>
      <div>
        <DateAndTime value={tokenPurchase.date} />
        <TransactionDetailsLink transaction={tokenPurchase} />
      </div>

      <div>
        <div>
          <TransactionTypeName transactionType={tokenPurchase.type} />
        </div>
        <div>
          <TokenPurchaseStatusCodeName
            tokenPurchaseStatusCode={tokenPurchase.tokenPurchaseStatusCode}
          />
        </div>
      </div>

      <div>
        <Money value={tokenPurchase.tokenAmount} currency={tokenPurchase.tokenCurrencyCode} /> to{" "}
        {tokenPurchase.destinationWallet.description}
      </div>

      <div style={{ padding: "2px 12px" }}>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td isNumeric={true}>
                <Money value={tokenPurchase.amount} currency={tokenPurchase.currencyCode} />
              </Td>
              <Td>{tokenPurchase.payment?.paymentMethod.displayString || "---"}</Td>
            </Tr>
          </Tbody>
        </Table>
      </div>

      <div style={{ padding: "2px 12px", gridColumn: "5 / span 2" }}>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td>Token Purchase</Td>
              <Td isNumeric={true}>
                <Money
                  value={tokenPurchase.amountMinusFees}
                  currency={tokenPurchase.currencyCode}
                />
              </Td>
            </Tr>
            {tokenPurchase.integratorFeeAmount !== 0n && (
              <Tr>
                <Td>Publisher Fee</Td>
                <Td isNumeric={true}>
                  <Money
                    value={tokenPurchase.integratorFeeAmount}
                    currency={tokenPurchase.feeCurrencyCode}
                  />
                </Td>
              </Tr>
            )}
            {tokenPurchase.tiliaFeeAmount !== 0n && (
              <Tr>
                <Td>Tilia Fee</Td>
                <Td isNumeric={true}>
                  <Money
                    value={tokenPurchase.tiliaFeeAmount}
                    currency={tokenPurchase.feeCurrencyCode}
                  />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </div>

      <div>
        {menuSettings.length > 0 && (
          <TransactionHistoryActionMenu
            detailsData={{ transaction: tokenPurchase }}
            refetch={refetch}
            menuSettings={menuSettings}
          />
        )}
      </div>
    </Container>
  );
}

export default TokenPurchaseGridRow;
