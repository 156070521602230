import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAgent } from "context/agentContext";

export const Logout = () => {
  const navigate = useNavigate();
  const { logoutAgent } = useAgent();
  useEffect(() => {
    logoutAgent();
    navigate("/login");
  }, [navigate, logoutAgent]);
  return null;
};
