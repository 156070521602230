import { debug } from "utils";
import { METHODS, assertNoErrorString, mapProxyFetch, serviceMap } from "./utils";

const emailBouncelistProxy = mapProxyFetch(serviceMap.EMAIL_BOUNCELIST_API);

// no header required
export const unbounceEmail = async (email, ctx) => {
  debug(ctx, "[unbounceEmail]", email);
  const response = await emailBouncelistProxy(ctx, `/email/${email}`, METHODS.DELETE);
  await assertNoErrorString(response);
  return response.text();
};
