import { createAction } from "redux-actions";
import * as permissions from "context/permissions";
import * as R from "ramda";
import * as entityApi from "api/entity";
import { isNilOrEmpty } from "utils";
import { getAccountUserInfoAdmin } from "api/account";
import { debugReducer } from "../utils"; //eslint-disable-line no-unused-vars

export const getEntityById = entityApi.getEntityById;
export const uploadKYBFile = entityApi.uploadKYBFile;
export const updateEntity = entityApi.updateEntity;

export const entityAgentTypes = {
  AGENT: "Agent",
  BENEFICIAL_OWNER: "Beneficial Owner",
  CONTROL_PERSON: "Control Person",
};

export const entityAgentStatus = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const getKYBFiles = async (entityId, fileList, user) => {
  const responses = await Promise.all(
    fileList.map((file) => entityApi.getKYBFile(entityId, file, user))
  );
  return R.pluck("payload", responses);
};

const checkIfEntityTagExists = R.any(
  R.either(R.propEq("entity", "tag"), R.propEq("entity_agent", "tag"))
);

const preFlightChecks = (userInfo, integrator) => {
  if (userInfo.payload?.integrator !== integrator) {
    return {
      status: "Failure",
      message: "Account ID must be associated with the same integrator as Entity.",
      codes: [401],
    };
  }
  if (checkIfEntityTagExists(userInfo.payload.tags)) {
    return {
      status: "Failure",
      message: "Account ID is already associated with an entity.",
      codes: [401],
    };
  }
  return userInfo;
};

export const createEntityAgent = async (agentData, integrator, ctx) => {
  let response = null;
  if (!isNilOrEmpty(agentData?.account_id)) {
    let userInfo = await getAccountUserInfoAdmin(agentData?.account_id, ctx);
    userInfo = preFlightChecks(userInfo, integrator);
    if (userInfo.status !== "Success") {
      response = userInfo;
    }
  }
  if (isNilOrEmpty(response)) {
    response = await entityApi.createEntityAgent(agentData, ctx);
  }
  return response;
};

export const updateEntityAgent = async (agentData, integrator, ctx) => {
  let response = null;
  if (!isNilOrEmpty(agentData?.account_id)) {
    let userInfo = await getAccountUserInfoAdmin(agentData?.account_id, ctx);
    userInfo = preFlightChecks(userInfo, integrator);
    if (userInfo.status !== "Success") {
      response = userInfo;
    }
  }
  if (isNilOrEmpty(response)) {
    response = await entityApi.updateEntityAgent(agentData, ctx);
  }
  return response;
};

export const getEntity = async (dispatch, accountId, user) => {
  if (!user.hasPermissions([permissions.READ_KYB], permissions.ENTITY_BASIC_RESOURCE)) {
    dispatch(actions.getEntityPermissionsFail());
    return;
  }
  try {
    dispatch(actions.getEntityInit(accountId));
    const { payload } = await entityApi.getEntity(accountId, user);
    dispatch(actions.getEntitySuccess(payload));
  } catch (err) {
    dispatch(actions.getEntityError(err));
  }
};

export const createEntity = async (dispatch, entityData, accountData, user) => {
  if (!user.hasPermissions([permissions.WRITE_KYB], permissions.ENTITY_BASIC_RESOURCE)) {
    dispatch(actions.createEntityPermissionsFail());
    return;
  }
  const { account_id, integrator, username } = accountData;
  const entityObject = {
    account_id: account_id,
    integrator: integrator,
    ...entityData,
  };
  try {
    const response = await entityApi.createEntityAccount(account_id, entityObject, user);
    const { payload: entityAccount } = response;
    const agentObject = {
      entity_id: entityAccount.entity_id,
      agent_name: username,
      account_id: account_id,
      roles: ["CONTROL_PERSON"],
    };
    await entityApi.createEntityAgent(agentObject, user);
    dispatch(actions.createEntitySuccess(entityAccount));
    return response;
  } catch (err) {
    dispatch(actions.createEntityError(err));
    return err;
  }
};

export const actions = {
  getEntityInit: createAction("@tools/entity/getEntityInit"),
  getEntitySuccess: createAction("@tools/entity/getEntitySuccess"),
  getEntityError: createAction("@tools/entity/getEntityError"),
  getEntityPermissionsFail: createAction("@tools/entity/getEntityPermissionsFail"),
  createEntitySuccess: createAction("@tools/entity/createEntitySuccess"),
  createEntityError: createAction("@tools/entity/createEntityError"),
  createEntityPermissionsFail: createAction("@tools/entity/createEntityPermissionsFail"),
};

export const initialState = {
  entity: {
    entity_id: null,
    entity_name: null,
    data: null,
    error: null,
    permissions: true,
    ready: false,
  },
};

export const getEntityHandlers = {
  [actions.getEntityInit]: debugReducer((state, { payload }) => ({
    ...state,
    entity: {
      ...state.entity,
      entity_id: payload.entity_id,
      entity_name: payload.entity_name,
      entity_integrator: payload.integrator,
      error: null,
      ready: false,
    },
  })),
  [actions.getEntitySuccess]: debugReducer((state, { payload }) => ({
    ...state,
    entity: {
      ...state.entity,
      entity_id: payload.entity_id,
      entity_name: payload.entity_name,
      error: null,
      ready: true,
    },
  })),
  [actions.getEntityError]: debugReducer((state, { payload }) => ({
    ...state,
    entity: {
      ...state.entity,
      entity_id: null,
      entity_name: null,
      error: payload.error,
      ready: true,
    },
  })),
  [actions.getEntityPermissionsFail]: debugReducer((state) => ({
    ...state,
    entity: {
      ...state.entity,
      entity_id: null,
      entity_name: null,
      error: null,
      ready: true,
      permissions: false,
    },
  })),
  [actions.createEntitySuccess]: debugReducer((state, { payload }) => ({
    ...state,
    entity: {
      ...state.entity,
      entity_id: payload.entity_id,
      entity_name: payload.entity_name,
      error: null,
      ready: true,
    },
  })),
  [actions.createEntityError]: debugReducer((state, { payload }) => ({
    ...state,
    entity: {
      ...state.entity,
      entity_id: null,
      entity_name: null,
      error: payload.error,
      ready: true,
    },
  })),
  [actions.createEntityPermissionsFail]: debugReducer((state) => ({
    ...state,
    entity: {
      ...state.entity,
      entity_id: null,
      entity_name: null,
      error: null,
      ready: true,
      permissions: false,
    },
  })),
};

export const handlers = {
  ...getEntityHandlers,
};
