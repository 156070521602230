// NOTE: The names of most exported objects exactly match the equivalent constant strings in the
// Go services code -- DO NOT RENAME THEM!
//
// This helps to ensure this file, and any usages of these constants in our own code, appear in
// full-code searches for those strings, so that Tools isn't excluded from critical updates that
// modify the underlying functionality.

/**
 * @typedef {Object} TokenExchangeIdentifier
 * @property {string} tokenExchangeId - TUID of the token exchange.
 * @property {string} integrator - The publisher that the token exchange belongs to.
 */

/**
 * Token exchange status codes.
 * @enum {string}
 */
export const InvoicingApiTokenExchangeStatusCode = Object.freeze({
  TokenExchangeOpen: "OPEN",
  TokenExchangeProcessing: "PROCESSING",
  TokenExchangeSuccess: "SUCCESS",
  TokenExchangeFailed: "FAILED",
});

/**
 * Token exchange status codes representing a "pending" status.
 * @type {Set<InvoicingApiTokenExchangeStatusCode>}
 */
export const InvoicingApiTokenExchangePendingStatusCodes = new Set([
  InvoicingApiTokenExchangeStatusCode.TokenExchangeOpen,
  InvoicingApiTokenExchangeStatusCode.TokenExchangeProcessing,
]);

/**
 * Token exchange status codes representing a "success" status.
 * @type {Set<InvoicingApiTokenExchangeStatusCode>}
 */
export const InvoicingApiTokenExchangeSuccessStatusCodes = new Set([
  InvoicingApiTokenExchangeStatusCode.TokenExchangeSuccess,
]);

/**
 * Token exchange status codes representing a "failed" status.
 * @type {Set<InvoicingApiTokenExchangeStatusCode>}
 */
export const InvoicingApiTokenExchangeFailedStatusCodes = new Set([
  InvoicingApiTokenExchangeStatusCode.TokenExchangeFailed,
]);

/**
 * Token exchange status codes representing a "canceled" status.
 * @type {Set<InvoicingApiTokenExchangeStatusCode>}
 */
export const InvoicingApiTokenExchangeCanceledStatusCodes = new Set();

/**
 * Token exchange direction
 * @enum {string}
 */
export const InvoicingApiTokenExchangeDirection = Object.freeze({
  Purchase: "token_purchase",
  Conversion: "token_convert",
});

/**
 * Token exchange rate direction
 * @enum {string}
 */
export const InvoicingApiTokenExchangeRateDirection = Object.freeze({
  VirtualToUsd: "virtual_to_usd",
  UsdToVirtual: "usd_to_virtual",
});

/**
 * Transaction representing either the purchase of virtual tokens, or the exchange of virtual tokens to fiat currency.
 * @typedef {Object} InvoicingApiTokenExchange
 * @property {string} token_exchange_id Token exchange TUID.
 * @property {string} account_id Account ID of the user initiated the transaction.
 * @property {InvoicingApiTokenExchangeDirection} direction Direction of the exchange: purchase or conversion.
 * @property {string} invoice_id ID of the underlying invoice tracking the money movements.
 * @property {number} user_paid_amount Total amount of the "source" currency paid/exchanged.
 * @property {string} user_paid_currency Source currency code.
 * @property {number} user_received_amount Total amount of the "destination" currency received/exchanged.
 * @property {string} user_received_currency Destination currency code.
 * @property {number} exchange_rate Multiplier value from one unit of paid currency to one unit of received currency.
 * @property {InvoicingApiTokenExchangeRateDirection} exchange_rate_direction Direction of the exchange; redundant with "direction".
 * @property {number} tilia_fee_amount Tilia fee for processing the exchange.
 * @property {number} integrator_fee_amount Publisher fee for processing the exchange.
 * @property {string} fee_currency Currency code for assessed fees.
 * @property {string} destination_wallet_id Wallet ID that received the exchanged funds.
 * @property {InvoicingApiTokenExchangeStatusCode} status Current status of the token exchange.
 * @property {InvoicingApiTag[]} tags Array of tags attached to the token exchange.
 * @property {string} created RFC 3339 date/time string when the note was created
 * @property {string} updated RFC 3339 date/time string when the note was last updated
 */
