import { changePayoutStatus, finalizePayout } from "api/invoicing";
import { GridContext } from "components/Grid";
import { useAgent } from "context/agentContext";
import { useAlert } from "context/alertContext";
import { useContext } from "react";
import { ACTIONS } from "./constants";
import { strings } from "./strings";
import { ctxWithIntegrator } from "utils/auth";
import { exists } from "utils";

const useAction = () => {
  const { agent } = useAgent();
  const { closeModal, deselectPage, openModal, refresh, setModalConfig } = useContext(GridContext);
  const { fireAlert } = useAlert();

  const acceptableStatusCodes = [200, 202];

  const onSuccess = () => {
    deselectPage();
    closeModal();
    refresh();
  };
  const handleSubmit = async (payouts, type) => {
    const payoutPromises = payouts.map((payout) => {
      if (type === ACTIONS.ON_HOLD) {
        return changePayoutStatus(type, payout, ctxWithIntegrator(payout.integrator, agent));
      } else {
        return finalizePayout(type, payout, ctxWithIntegrator(payout.integrator, agent));
      }
    });
    const payoutResponses = await Promise.allSettled(payoutPromises);

    let successfulPayouts = [];
    let failedPayouts = [];

    payoutResponses.forEach((payoutResponse) => {
      // if any of the promises fail, push the error reason
      if (payoutResponse.status === "rejected") {
        failedPayouts.push({ errors: [payoutResponse.reasons] });
        return;
      }
      // handle resolved promises status codes
      const { status, errors } = payoutResponse.value;
      if (!acceptableStatusCodes.includes(status)) {
        failedPayouts.push({ errors });
        return;
      }
      successfulPayouts.push({ ...payoutResponse.value });
    });

    // if one payout in the list of successful payouts was placed on hold
    // we should fire the alert
    const payoutOnHold = successfulPayouts.some(
      ({ status, codes }) => status === 202 && codes.includes("payout_on_hold")
    );

    if (payoutOnHold) {
      fireAlert(
        "Payout is being reviewed by the provider and has been moved to the provider on hold state."
      );
    }

    if (exists(successfulPayouts)) {
      return { status: "Success" };
    } else {
      return { status: "Failure", error: failedPayouts[0].errors };
    }
  };

  const action = ({ payload, type }) => {
    const size = payload?.length;

    let titleText;
    let content;

    switch (type) {
      case ACTIONS.APPROVE:
        titleText = strings.approve_payout;
        content = strings.approve_confirmation.format(size, size > 1 ? "s" : "");
        break;
      case ACTIONS.ON_HOLD:
        titleText = strings.on_hold_payout;
        content = strings.on_hold_confirmation.format(size, size > 1 ? "s" : "");
        break;
      case ACTIONS.REJECT:
        titleText = strings.reject_payout;
        content = strings.reject_confirmation.format(size, size > 1 ? "s" : "");
        break;
      default:
      // no real default behavior was defined previously
    }

    setModalConfig({
      titleText,
      content,
      actionText: strings.confirm,
      submitAction: () => handleSubmit(payload, type),
      onSubmitActionSuccess: onSuccess,
    });

    openModal();
  };

  return action;
};

export default useAction;
