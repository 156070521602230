import { Card, CardBody, Table, TableContainer, Tbody, Td, Tfoot, Tr } from "@chakra-ui/react";
import { FormattedExchangeRate, Money } from "components/Money";

function TokenConversionSummary({ tokenConversion }) {
  return (
    <Card>
      <CardBody>
        <TableContainer>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Td>
                  Conversion of{" "}
                  <Money value={tokenConversion.amount} currency={tokenConversion.currencyCode} />{" "}
                  to{" "}
                  <Money
                    value={tokenConversion.convertedAmount}
                    currency={tokenConversion.convertedCurrencyCode}
                  />
                </Td>
                <Td isNumeric={true}>
                  <Money
                    value={tokenConversion.amountMinusFees}
                    currency={tokenConversion.currencyCode}
                  />
                </Td>
              </Tr>
              {tokenConversion.integratorFeeAmount !== 0n && (
                <Tr>
                  <Td>Publisher Fee</Td>
                  <Td isNumeric={true}>
                    <Money
                      value={tokenConversion.integratorFeeAmount}
                      currency={tokenConversion.feeCurrencyCode}
                    />
                  </Td>
                </Tr>
              )}
              {tokenConversion.tiliaFeeAmount !== 0n && (
                <Tr>
                  <Td>Tilia Fee</Td>
                  <Td isNumeric={true}>
                    <Money
                      value={tokenConversion.tiliaFeeAmount}
                      currency={tokenConversion.feeCurrencyCode}
                    />
                  </Td>
                </Tr>
              )}
            </Tbody>
            <Tfoot>
              <Tr>
                <Td colSpan={2}>
                  Exchange rate:{" "}
                  <FormattedExchangeRate
                    sourceCurrency={tokenConversion.currencyCode}
                    destinationCurrency={tokenConversion.convertedCurrencyCode}
                    exchangeRate={tokenConversion.exchangeRate}
                  />
                </Td>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
}

export default TokenConversionSummary;
