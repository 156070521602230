/* eslint-disable no-useless-escape */
import React from "react";
import styled, { css } from "styled-components/macro"; // eslint-disable-line no-unused-vars
import * as Yup from "yup";
import { useFormModal } from "components/Modal";
import { noop } from "utils";
import { prop } from "styles";
import { KYCIcon } from "components/Icons";
import { useAgent } from "context/agentContext";
import { WRITE_KYC_PIIS } from "context/permissions";
import { TextField, SelectField } from "components/Forms";
import { useAccount } from "context/accountContext";
import { toRegionOptions, regions } from "utils/regions";
import { formatSSN } from "utils/format";

const FormRowGrid = styled.div`
  display: grid;
  grid-template-columns: ${prop(
    "cols",
    css`
      ${({ children }) => `repeat(${children.length}, 1fr)`}
    `
  )};
  grid-gap: 8px;
`;

const testIdPrefix = "edit-kyc-data-";

export const useEditKYCDataModal = () => {
  const { agent } = useAgent();
  const account = useAccount();
  const [FormModal, openModal] = useFormModal();

  const EditKYCDataModal = ({ currentApplication, onSuccess = noop }) => {
    const submitAction = (values) => {
      return account.updateKYCPII(values);
    };
    if (!agent.hasPermissions([WRITE_KYC_PIIS])) {
      return <div data-testid={`${testIdPrefix}modal-no-permissions`}></div>;
    }

    return (
      <FormModal
        actionIcon={<KYCIcon size="1rem" />}
        actionText="Save"
        formProps={{
          initialValues: {
            first: currentApplication?.pii?.firstName,
            last: currentApplication?.pii?.lastName,
            street: currentApplication?.pii?.primaryAddress?.street,
            city: currentApplication?.pii?.primaryAddress?.city,
            state: currentApplication?.pii?.primaryAddress?.region,
            country: currentApplication?.pii?.primaryAddress?.country,
            zip: currentApplication?.pii?.primaryAddress?.postalCode,
            ssn: formatSSN(currentApplication?.pii?.ssn) || "",
            dob: currentApplication?.pii?.dateOfBirth,
          },
          // state is allowed to be updated to empty
          validationSchema: () =>
            Yup.object().shape({
              first: Yup.string().required("First Name is required"),
              last: Yup.string().required("Last Name is required"),
              street: Yup.string().required("Street Address is required"),
              city: Yup.string().required("City is required"),
              country: Yup.string().required("Country is required"),
              zip: Yup.string().required("Postal Code is required"),
              dob: Yup.string()
                .matches(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/, {
                  message: "Must be in YYYY-MM-DD format.",
                })
                .required("Date of Birth is required"),
            }),
        }}
        onSubmitActionSuccess={onSuccess}
        submitAction={submitAction}
        testIdPrefix={testIdPrefix}
        titleText="Edit KYC Data"
      >
        <FormRowGrid>
          <TextField name="first" label="First Name" />
          <TextField name="last" label="Last Name" />
        </FormRowGrid>
        <FormRowGrid>
          <TextField name="street" label="Street" />
        </FormRowGrid>
        <FormRowGrid>
          <TextField name="city" label="City" />
          <TextField name="state" label="State" />
        </FormRowGrid>
        <FormRowGrid>
          <SelectField
            name="country"
            label="Country"
            required
            options={toRegionOptions(regions)}
            maxHeightEntries={3}
          />
          <TextField name="zip" label="Postal Code" />
        </FormRowGrid>
        <FormRowGrid>
          <TextField name="ssn" label="SSN" showHide />
          <TextField name="dob" label="Date of Birth" />
        </FormRowGrid>
      </FormModal>
    );
  };

  const editKYCDataMenuItem = {
    icon: <KYCIcon size="1rem" />,
    label: `Edit KYC data...`,
    onSelect: openModal,
    "data-displayitem": agent.hasPermissions([WRITE_KYC_PIIS]),
  };

  return [EditKYCDataModal, editKYCDataMenuItem];
};
