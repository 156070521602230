import { TokenConversionStatusCode } from "@tilia-tools/core/transaction";

/**
 * Renders the localized human-readable name of the token conversion status code.
 *
 * @param {TokenConversionStatusCode} tokenConversionStatusCode
 * @returns {JSX.Element}
 */
function TokenConversionStatusCodeName({ tokenConversionStatusCode }) {
  // TODO: Replace hard-coded US-English strings with <FormattedMessage>
  switch (tokenConversionStatusCode) {
    case TokenConversionStatusCode.OPEN:
      return <>Open</>;
    case TokenConversionStatusCode.PROCESSING:
      return <>Processing</>;
    case TokenConversionStatusCode.SUCCESS:
      return <>Success</>;
    case TokenConversionStatusCode.FAILED:
      return <>Failed</>;
    default:
      return <>{tokenConversionStatusCode}</>;
  }
}

export default TokenConversionStatusCodeName;
