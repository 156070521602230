import { getAccountUserInfo } from "api/account";
import { getKYCPII } from "api/pii";
import { getPaymentMethod } from "api/payments";
import * as R from "ramda";
import { isNilOrEmpty } from "utils";
import { ctxWithIntegrator } from "utils/auth";
import { STATUS } from "./constants";
import { strings } from "./strings";

export const getSelectOptions = (integrators) =>
  R.pipe(
    R.keys,
    R.map((k) => ({ value: k, label: integrators[k].displayName })),
    R.sortBy((i) => R.toLower(R.prop("label", i))),
    R.prepend({ value: "", label: strings.all })
  )(integrators);

export const filterByIntegrator = (integrator) => (payouts) =>
  isNilOrEmpty(integrator) ? payouts : R.filter(R.propEq(integrator, "integrator"))(payouts);

export const canChangeStatus = (payout) =>
  payout.status === STATUS.escrowed || payout.status === STATUS.tiliaOnHold;
export const formatPaymentMethod = ({
  provider,
  provider_data,
  processing_currency,
  method_class,
  id,
}) => {
  provider = (provider || "").trim().toLowerCase();
  if (provider === "paypal") {
    return `[Paypal ${provider_data.paypal_email}]`;
  } else if (provider === "wallet") {
    return `[${processing_currency} ${method_class} Wallet]`;
  } else {
    return `[${provider} ${id}]`;
  }
};

export const enhancePayout = async (payout, agent) => {
  try {
    const agentWithIntegratorContext = ctxWithIntegrator(payout?.integrator, agent);
    const accountInfo = await getAccountUserInfo(payout.account_id, agent);
    const kycPII = await getKYCPII(payout.account_id, agentWithIntegratorContext);
    const sourcePaymentMethod = await getPaymentMethod(
      payout.credit.source_payment_method_id,
      agentWithIntegratorContext
    );
    const destinationPaymentMethod = await getPaymentMethod(
      payout.credit.destination_payment_method_id,
      agentWithIntegratorContext
    );

    return {
      ...payout,
      account: {
        kyc: kycPII?.payload,
        ...accountInfo?.payload,
      },
      credit: {
        ...payout.credit,
        source_payment_method: sourcePaymentMethod?.payload,
        destination_payment_method: destinationPaymentMethod?.payload,
      },
    };
  } catch (e) {
    return payout;
  }
};
