import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ConnectionPageNumber } from "components/Pager";

export const DEFAULT_PAGE_SIZE = "10";

const useConnectionPagination = (connection) => {
  const navigate = useNavigate();
  const location = useLocation();

  const setPageSize = useCallback(
    (pageSize) => {
      const params = new URLSearchParams(location.search);
      if (params.has("last")) {
        params.delete("first");
        params.set("last", pageSize.toString());
      } else {
        params.set("first", pageSize.toString());
      }
      navigate({ search: params.toString() });
    },
    [location.search, navigate]
  );

  const changePage = useCallback(
    (page) => {
      switch (page) {
        case ConnectionPageNumber.First:
        case ConnectionPageNumber.Previous:
        case ConnectionPageNumber.Next:
        case ConnectionPageNumber.Last:
          break;
        default:
          throw new Error(`Invalid page change value: ${page}`);
      }

      if (!connection) {
        return;
      }

      const params = new URLSearchParams(location.search);

      const first = params.has("first") ? parseInt(params.get("first"), 10) : 0;
      const last = params.has("last") ? parseInt(params.get("last"), 10) : 0;
      const pageSize = (first || last || DEFAULT_PAGE_SIZE).toString();

      switch (page) {
        case ConnectionPageNumber.First:
          params.set("first", pageSize);
          params.delete("after");
          params.delete("last");
          params.delete("before");
          navigate({ search: params.toString() });
          break;

        case ConnectionPageNumber.Previous:
          if (connection.pageInfo.hasPreviousPage) {
            params.delete("first");
            params.delete("after");
            params.set("last", pageSize);
            params.set("before", connection.edges[0].cursor);
            navigate({ search: params.toString() });
          }
          break;

        case ConnectionPageNumber.Next:
          if (connection.pageInfo.hasNextPage) {
            params.set("first", pageSize);
            params.set("after", connection.edges[connection.edges.length - 1].cursor);
            params.delete("last");
            params.delete("before");
            navigate({ search: params.toString() });
          }
          break;

        case ConnectionPageNumber.Last:
          params.delete("first");
          params.delete("after");
          params.set("last", pageSize);
          params.delete("before");
          navigate({ search: params.toString() });
          break;

        default:
        // impossible: validated above
      }
    },
    [location.search, connection, navigate]
  );

  const changeSort = useCallback(
    (sort) => {
      const params = new URLSearchParams(location.search);
      if (params.get("sort") === sort) {
        return;
      }
      params.set("sort", sort);
      if (params.has("last")) {
        params.set("first", params.get("last"));
        params.delete("last");
      }
      params.delete("before");
      params.delete("after");
      navigate({ search: params.toString() });
    },
    [location.search, navigate]
  );

  return {
    setPageSize,
    changePage,
    changeSort,
  };
};

export default useConnectionPagination;
