import { OutlineButton } from "components/Button";
import { LoadingIcon } from "components/Icons";
import React, { useContext } from "react";
import styled from "styled-components";
import { theme } from "styles";
import iconDownArrow from "../../assets/icons/down-arrow.svg";
import iconUpArrow from "../../assets/icons/up-arrow.svg";
import GridContext from "./GridContext";

const FILTER_PREFIX = "f_";

const NoDataContainer = styled.div`
  align-items: center;
  border: solid 1px ${theme("colors.dataTable.rowBorder")};
  border-radius: 4px;
  color: grey;
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  h1 {
    font-size: 4rem;
    margin: 0;
  }
  p {
    margin-top: 0px;
    margin-bottom: 30px;
  }
`;

const ColumnHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  color: grey;
  &[role="button"] {
    color: #4998e9;
    cursor: pointer;
  }
  > img {
    width: 8px;
    margin-left: 8px;
  }
  > .icon-placeholder {
    height: 8px;
    width: 8px;
    margin-left: 8px;
  }
`;

const Backdrop = styled.div`
  position: absolute;
  top: -1rem;
  bottom: -1rem;
  left: -1rem;
  right: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme("colors.mask")};
`;

export const SORT_DIRECTION = {
  ASC: "asc",
  DESC: "desc",
};

// take params and return all filters
export const getFilterMap = (params) => {
  const filterMap = {};
  for (const key of params.keys()) {
    if (key.indexOf(FILTER_PREFIX) !== -1) {
      filterMap[key.split(FILTER_PREFIX)[1]] = params.get(key);
    }
  }

  return filterMap;
};

// take aprams and filter map and update URL params
export const setFilterMap = (params, filterMap, fieldToRemove = null) => {
  const filterKeys = Object.keys(filterMap || {});

  filterKeys.forEach((filterKey) => {
    const filterValue = filterMap[filterKey];
    if (filterValue && filterKey !== fieldToRemove) {
      params.set(`${FILTER_PREFIX}${filterKey}`, filterValue);
    } else {
      params.delete(`${FILTER_PREFIX}${filterKey}`);
    }
  });
};

export const GridContainer = ({ children, isLoading }) => {
  if (isLoading) return <GridLoading />;
  return children;
};

// first pass at sorting might need updates late on
export const sortBy = (array, field, dir) => {
  let arrayForSort = [...array];
  arrayForSort.sort((a, b) => {
    const aVal = isNaN(a[field]) ? a[field] : Number(a[field]);
    const bVal = isNaN(b[field]) ? b[field] : Number(b[field]);
    if (dir === SORT_DIRECTION.ASC) {
      return aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
    } else {
      return aVal > bVal ? -1 : bVal > aVal ? 1 : 0;
    }
  });
  return arrayForSort;
};

export const GridLoading = () => (
  <Backdrop data-testid="grid-loading">
    <LoadingIcon size={2} />
  </Backdrop>
);

export const NoData = ({ showRefresh = true }) => {
  const { refresh } = useContext(GridContext);
  return (
    <NoDataContainer data-testid="grid-no-data">
      <h1>No Data</h1>
      {showRefresh && <OutlineButton onClick={refresh}>Refresh</OutlineButton>}
    </NoDataContainer>
  );
};

export const SelectAllCheckbox = (props) => {
  const { isAllPageSelected, selectDeselectPage } = useContext(GridContext);

  return (
    <input
      data-testid="select-all-checkbox"
      type="checkbox"
      checked={isAllPageSelected}
      onChange={selectDeselectPage}
      {...props}
    />
  );
};
export const RowCheckbox = ({ record, ...props }) => {
  const { canBeSelected, isSelected, selectDeselect } = useContext(GridContext);
  const handleCheckboxChange = () => selectDeselect(record);

  return (
    <input
      type="checkbox"
      checked={isSelected(record)}
      onChange={handleCheckboxChange}
      disabled={!canBeSelected(record)}
      {...props}
    />
  );
};

export const ColumnHeader = ({ children, field, sortable = false }) => {
  const { sortDir, sortField, setSortField, setSortDir } = useContext(GridContext);
  const isSorted = sortField === field;
  const isAsc = sortDir === SORT_DIRECTION.ASC;
  const handleClick = () => {
    if (isSorted) {
      setSortDir(isAsc ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC);
    } else {
      setSortDir(SORT_DIRECTION.DESC);
      setSortField(field);
    }
  };

  const renderIcon = () =>
    isAsc ? (
      <img src={iconDownArrow} alt="Ascending Sort" />
    ) : (
      <img src={iconUpArrow} alt="Descending Sort" />
    );

  if (!sortable) {
    return <ColumnHeaderContainer children={children} />;
  }

  return (
    <ColumnHeaderContainer role="button" onClick={handleClick}>
      {children}
      {isSorted ? renderIcon() : <div className="icon-placeholder" />}
    </ColumnHeaderContainer>
  );
};

// This component should be treated as a "base" component that is wrapped by
// a consuming component that provides a grid-template-rows css rule to give
// it a final shape.
//
// for example:
//
// import { GridRow } from 'components/Grid'
// const MyGridRow = styled(GridRow)`
//   grid-template-columns: repeat(4, 1fr);
// `;
//
export const GridRow = styled.div`
  padding: 8px;
  display: grid;
  border-bottom: 1px solid ${theme("colors.dataTable.rowBorder")};
  > * {
    padding: 8px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;
