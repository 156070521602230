import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { useNavigate, useLocation } from "react-router-dom";
import * as R from "ramda";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAgent } from "context/agentContext";
import { validationMessages as vm } from "utils/forms";
import { Error, TextField, PasswordField } from "components/Forms";
import { PrimaryButton, ButtonSpinner } from "components/Button";
import { isNilOrEmpty, getQueryString } from "utils";
import { login } from "api/toolsWeb";
import { ROUTE_BASENAME } from "utils/environment";

const errorMap = {
  404: "The account information entered did not match our records",
  401: "You are not authorized to access this application",
};

const mapResponseErrorToMessage = (err) => {
  return R.defaultTo(err.message, errorMap[err.message]);
};

const OrLine = styled.hr`
  border: 0;
  height: 1px;
  background: #ccc;
  width: 100%;
`;

const Divider = () => {
  return (
    <div
      css={`
        font-size: 0.8em;
        margin: 16px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      `}
    >
      <OrLine />
      <div
        css={`
          margin: 0 8px;
          color: #999;
        `}
      >
        OR
      </div>
      <OrLine />
    </div>
  );
};

// strip off any leading instances of ROUTE_BASENAME
const pathRegex = new RegExp(`^(${ROUTE_BASENAME})*(.*)`);
const normalizeBase = (input) => {
  return pathRegex.exec(input)[2];
};

const getFinalDestination = (location, hostname) => {
  // if location.qs.r is relative or for this domain:
  //  check for rootbase, add if needed, return
  // else, go to root

  const r = R.pipe(R.prop("search"), getQueryString, R.prop("r"))(location);
  if (isNilOrEmpty(r)) return "/";

  // build a URL
  // - if r is relative, hostname will be prepended
  // - if r contains a host, hostname will be ignored
  const url = new URL(r, hostname);
  if (R.startsWith(hostname, url.href)) {
    // strip hostname and route base, return everything else as a relative path
    const withoutHost = R.replace(hostname, "", url.toString());
    return normalizeBase(withoutHost);
  }
  return "/";
};

export const UsernamePasswordLoginForm = ({ integrator = "", index }) => {
  const { setAgent } = useAgent();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (values, actions) => {
    try {
      const result = await login(values.username, values.password, integrator);
      await setAgent(result.jwt);
      navigate(getFinalDestination(location, window.location.origin));
    } catch (err) {
      actions.setFieldError("general", mapResponseErrorToMessage(err));
    }
  };

  return (
    <div data-testid="username-password-login-form">
      {index > 0 && <Divider />}
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={() => {
          return Yup.object().shape({
            username: Yup.string().required(vm.required),
            password: Yup.string().required(vm.required),
          });
        }}
        onSubmit={handleLogin}
      >
        {({ handleSubmit, isSubmitting, errors, ..._rest }) => {
          return (
            <form>
              <TextField autoComplete="username" name="username" label="Username" focus={true} />
              <PasswordField name="password" label="Password" />
              <div
                css={`
                  display: flex;
                  padding-top: 8px;
                `}
              >
                <PrimaryButton
                  data-testid="login-button"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Login
                  {isSubmitting && <ButtonSpinner />}
                </PrimaryButton>
              </div>
              <div
                css={`
                  margin: 4px 0;
                  min-height: 5em;
                `}
              >
                <Error error={errors.general} touched={true} />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
