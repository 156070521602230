import React, { useCallback, useMemo, useState, useContext, useEffect } from "react";
import * as R from "ramda";
import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import { useAgent } from "context/agentContext";
import { getClientsByIntegrator } from "api/account";
import { MANAGE_APPS, withPermissions } from "context/permissions";
import { Link } from "components/Link";
import {
  ColumnHeader,
  GridContainer,
  GridContext,
  GridRow as GR,
  SORT_DIRECTION,
  withGrid,
} from "components/Grid";
import { ClientSecret } from "./common";
import { CopyableText } from "components/Copyable";
import { useIntegrator } from "context/integratorContext";
import { SectionError } from "components/Error";
import { isNilOrEmpty } from "utils";
import { DeleteClientModalButton } from "./DeleteClientModal";

const GridRow = styled(GR)`
  grid-template-columns: repeat(3, 1fr) min-content;
`;
const ClientRow = ({ client, onDelete }) => {
  return (
    <GridRow>
      <div>
        <Link to={`${client.client_id}`}>{client.app_name}</Link>
      </div>
      <div>
        <CopyableText text={client.client_id} />
      </div>
      <div>
        <ClientSecret secret={client.client_secret} />
      </div>
      <DeleteClientModalButton client={client} onDelete={onDelete} />
    </GridRow>
  );
};

const byTextProp = (prop) => R.compose(R.toLower, R.prop(prop));

const sortMap = {
  app_name: byTextProp("app_name"),
  client_id: byTextProp("client_id"),
};

const sortClients = (clients = [], sortDir, sortField) => {
  const sorted = R.sortBy(sortMap[sortField], clients);
  return sortDir === SORT_DIRECTION.ASC ? sorted : R.reverse(sorted);
};

const useSortedClients = (clients) => {
  const g = useContext(GridContext);
  return useMemo(
    () => sortClients(clients, g.sortDir, g.sortField),
    [g.sortDir, g.sortField, clients]
  );
};

const GridHeader = () => (
  <GridRow>
    <ColumnHeader field="app_name" sortable={true}>
      Application Name
    </ColumnHeader>
    <ColumnHeader field="client_id" sortable={true}>
      Client ID
    </ColumnHeader>
    <ColumnHeader field="client_secret" sortable={false}>
      Client Secret
    </ColumnHeader>
  </GridRow>
);

const ClientGrid = withGrid(
  ({ clients, isLoading, onDelete }) => {
    const sortedClients = useSortedClients(clients);
    return (
      <GridContainer isLoading={isLoading}>
        <GridHeader />
        {sortedClients.map((client) => (
          <ClientRow key={client.client_id} client={client} onDelete={onDelete} />
        ))}
      </GridContainer>
    );
  },
  { sortField: "app_name", sortDir: SORT_DIRECTION.ASC }
);

const ClientList = () => {
  const { publisherId: publisher } = useParams();
  const { agent } = useAgent();
  const [results, setResults] = useState(null);

  const fetchClients = useCallback(async () => {
    const response = await getClientsByIntegrator(publisher, agent);
    setResults(response?.payload?.clients);
  }, [agent, publisher]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  return results && <ClientGrid clients={results} isLoading={false} onDelete={fetchClients} />;
};

export const ClientsByPublisher = withPermissions({ permissions: MANAGE_APPS }, () => {
  const { publisherId } = useParams();

  // validate publisher
  const integrator = useIntegrator(publisherId);
  if (isNilOrEmpty(integrator)) {
    return <SectionError>Invalid publisher</SectionError>;
  }
  return <ClientList />;
});
