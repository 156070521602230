import { useState, useCallback, useEffect } from "react";

const initialState = {
  error: undefined,
  loading: true,
  data: undefined
};
export const useApi = apiFn => {
  const [state, setState] = useState(initialState);

  const invokeApi = useCallback(async () => {
    try {
      setState(initialState);
      const r = await apiFn();
      setState({ loading: false, error: undefined, data: r });
    } catch (error) {
      setState({ loading: false, error, data: undefined });
    }
  }, [apiFn]);

  useEffect(() => {
    const go = async () => invokeApi();
    go();
  }, [invokeApi]);

  return { ...state };
};
